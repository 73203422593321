import { BaseModel } from '../../_base/crud';

export class Role extends BaseModel {
	RolID: number;
    RolNombre: string;
    permissions: number[];
    isCoreRole: boolean = false;

    clear(): void {
		this.RolID = undefined;
		this.RolNombre = '';
        this.permissions = [];
        this.isCoreRole = false;
	}
}
