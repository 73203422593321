import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MyColInputComponent } from '../col-input/my-col-input.component';
import Utils from '../../../../../utils';
import { BroadcastMessage } from '../../../../../interfaces/message.interface';
import { MessengerService } from '../../../../../services/messenger.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'kt-precio-compra-venta',
	templateUrl: './precio-compra-venta.component.html',
	styleUrls: ['./precio-compra-venta.component.scss']
})
export class PrecioCompraVentaComponent implements OnInit, OnDestroy {
	public parent: any = this;

	private calculando: boolean = false;
	public _cantidad: number = 0;
	public _cabeza: number = 0;
	public _totalPesoTropa: number = 0;
	public _cantidadTotalVivo: number = 0;

	private modoCalculo: string = 'PorCabeza';

	private destroy$: Subject<void> = new Subject();

	@Input() public tabindex: number = -1;
	@Input() public required: boolean = true; // required
	@Input() public title: string = 'Precio Compra/Venta';

	@Input() set cantidad(value: number) {
		this._cantidad = value;

		setTimeout(() => {
			this.modoCalculo = "PorCabeza"
			this.actualizarPrecio();
		});
	}

	@Input() set cantidadTotalVivo(value: number) {
		this._cantidadTotalVivo = value;

		setTimeout(() => {
			this.actualizarPrecio();
		});
	}

	@ViewChild('Cabeza', { static: true }) public CabezaInput: MyColInputComponent;
	@ViewChild('Kilo', { static: true }) public KiloInput: MyColInputComponent;
	@ViewChild('Total', { static: true }) public TotalInput: MyColInputComponent;

	constructor(private cd: ChangeDetectorRef, private messengerService: MessengerService) { }

	ngOnInit() {
		this.messengerService.messageBroadcasted$.pipe(takeUntil(this.destroy$)).subscribe(
			(data: BroadcastMessage) => {
				if (data.Sender == 'PesoHaciendaComponent' && data.Target === 'PrecioCompraVentaComponent' && data.Reason == 'PesoCabezaChange') {
					this._cabeza = data.Payload.value;
					this.actualizarPrecio();
				}
			}
		);
	}

	ngOnDestroy() {
		this.destroy$.next();
	}

	public initializeControl(): void {
		this.CabezaInput.setValue(0);
		this.KiloInput.setValue(0);
		this.TotalInput.setValue(0);

		setTimeout(() => {
			this.modoCalculo = "Total"
			this.actualizarPrecio();
		});

		this.cd.detectChanges();
	}

	public getPorCabezaValue(): number {
		return Utils.checkNaNGetZero(this.CabezaInput.getValue());
	}

	public getTotalValue(): number {
		return Utils.checkNaNGetZero(this.TotalInput.getValue());
	}

	actualizarPrecio() {
		this.calculando = true;
		let cabezaKg: number = this.CabezaInput.getValue();

		if (this._totalPesoTropa > 0) {
			// si estamos en peso por tropa total, la cantidad es la que se ingresó como total, y no la que la va en la categoria que se está cargando
			this._cabeza = this._totalPesoTropa / this._cantidadTotalVivo;
		}

		let kilo: number = this.KiloInput.getValue();
		let total: number = this.TotalInput.getValue();

		switch (this.modoCalculo) {
			case 'PorCabeza':
				total = Utils.checkNaNGetZero(cabezaKg * this._cantidad);
				this.TotalInput.setValue(total);
				kilo = Utils.checkNaNGetZero(cabezaKg / this._cabeza);
				this.KiloInput.setValue(kilo);
				break;
			case 'PorKilo':
				total = Utils.checkNaNGetZero((kilo * this._cabeza) * this._cantidad);
				this.TotalInput.setValue(total);
				cabezaKg = Utils.checkNaNGetZero(total / this._cantidad);
				this.CabezaInput.setValue(cabezaKg);
				break;
			default:
				cabezaKg = Utils.checkNaNGetZero(total / this._cantidad);
				this.CabezaInput.setValue(cabezaKg);
				kilo = Utils.checkNaNGetZero((total / this._cantidad) / this._cabeza);
				this.KiloInput.setValue(kilo);
		}

		this.calculando = false;
	}

	onInputValueChange(newValue, labelName): void {
		if (!this.calculando) {
			setTimeout(() => {
				this.modoCalculo = labelName.replace(/\s+/g, '');
				this.actualizarPrecio();
			});
		}
	}
}
