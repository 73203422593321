import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import Utils from '../../../../../utils';
import { CorralDescarga } from '../../../../../models/corral-descarga';

@Component({
	selector: 'kt-carga-descarga-list',
	templateUrl: './carga-descarga-list.component.html',
	styleUrls: ['./carga-descarga-list.component.scss'],
    providers: []
})
export class CargaDescargaListComponent implements OnInit {
    public showGrid: boolean = false;
    public descargas: CorralDescarga[];
    @Input() buscando: boolean = false;

    @Input() public set Descargas(data: CorralDescarga[]) {
         this.descargas = data;

        if (this.descargas) {
            this.showGrid = true;
        }
        this.cd.detectChanges();
    }

    constructor(private cd: ChangeDetectorRef) { }

    ngOnInit() { }

	getExactitud(item: CorralDescarga) {
		return Utils.getCargaExactitud(item.CantidadPropuestaKg, item.CantidadRealKg);
    }
}
