<form class="kt-form col">
	<div class="form-group" [formGroup]="Form">
		<div class="kt-checkbox-list">
			<label *ngIf="!disabled">
				<input tabindex="{{tabindex}}" [checked]="clicked" kendoCheckBox (click)="onClick()" type="checkbox" #Checkbox formControlName="Checkbox"> {{labelName}}
			</label>
			<span style="font-weight: 400;" *ngIf="disabled">{{labelName}}</span>
		</div>
		<span *ngIf="hint" class="form-text text-muted">{{hint}}</span>
	</div>
</form>
