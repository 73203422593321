import { DTOInterface } from "../interfaces/dto.interface";
import { Guid } from "guid-typescript";

export class Notificacion implements DTOInterface { 
	NotificacionID:	Guid;
	FechaCreacion: Date;
	RolRef: number;
	CorralRef: number;
	NotificacionTexto: string;
	OrigenRolRef: number;
	OrigenUsuarioRef: number;

	constructor() {
		this.clear();
	}

	clear(): void {
		this.NotificacionID = Guid.createEmpty();
		this.FechaCreacion = new Date();
		this.RolRef = null;
		this.CorralRef = null;
		this.NotificacionTexto = '';
		this.OrigenRolRef = null;
		this.OrigenUsuarioRef = null;
	}
}
