import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { Tropa } from '../../../../../models/tropa';
import { TropaService } from '../../../../../services/tropa.service';
import { TropaComponent } from '../../tropa/tropa/tropa.component';
import { EditableForm, EditableFormList } from '../../common_controls/common-controls.interface';
import { Guid } from 'guid-typescript';
import { IngresoHaciendaComponent } from '../../common_controls';
import { TropaReporteComponent } from '../tropa-reporte/tropa-reporte.component';
import { IOpenInDialog, IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { MessengerService } from '../../../../../services/messenger.service';
import { Subscription } from 'rxjs';
import { BroadcastMessage } from '../../../../../interfaces/message.interface';
import { Router } from '@angular/router'; 
import { MatSnackBar } from '@angular/material/snack-bar'; 
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { getRoute, Routing } from '../../../themes/sigef/routing';
import { TropaCategoriaGanadoListComponent } from '../tropa-categoria-ganado-list';
import Utils from '../../../../../utils';

@Component({
	selector: 'kt-tropa-crud',
	templateUrl: './tropa-crud.component.html',
	styleUrls: ['./tropa-crud.component.scss'],
	providers: [TropaService]
})
export class TropaCrudComponent extends EditableForm<Tropa> implements OnInit, OnDestroy, IOpenInDialog {
    openInDialog(model: any, extras: any): void {
        throw new Error('Method not implemented.');
	}


	private e: any;
	private sub: Subscription;

	public edicion: boolean = false;
	public confirmacion: boolean = false;
	public puedeEditar: boolean = true;
	public readonly: boolean = false;  // boton de eliminar tropa es condicional a la cantidad de movimientos que tenga
	public isNewModel: boolean = true;

	saveResultDialogModel(model: any): void {

	}

	editResultDialog(formParent: IParentOpenInDialog): boolean {
		return false;
	}

	saveResultDialog(formParent: IParentOpenInDialog): boolean {
		if (formParent) {
			if (!this.runValidaciones()) {
				return false;
			}
			this.setModelPropsByControls();
			formParent.saveResultDialogModel(this.model);
		}
		return true;
	}

	@ViewChild(TropaReporteComponent, { static: true }) TropaReporte: TropaReporteComponent;
	@ViewChild(TropaComponent, { static: true }) private Tropa: TropaComponent;
	@ViewChild(IngresoHaciendaComponent, { static: true }) private IngresoHacienda: IngresoHaciendaComponent;
	@ViewChild(TropaCategoriaGanadoListComponent, { static: true }) private TropaCategoriaGanadoList: TropaCategoriaGanadoListComponent;

	constructor(cd: ChangeDetectorRef,
		private router: Router,
		tropaService: TropaService,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		quickPanelService: QuickPanelService) {
		super(cd, tropaService, snackBar, messengerService, quickPanelService);
	}

	ngOnInit() {		
		this.sub = this.getMessengerInstance().messageBroadcasted$.subscribe((data: BroadcastMessage) => {
			if (data.Reason === 'guardar_tropa_reporte') {
				//this.confirmacion = false; // no ocultar el reporte mientras se hace el redirect
				super.onSave(this.e, false);
			}
			if (data.Reason === 'cancelar_tropa_reporte') {
				this.confirmacion = false; // con solo ocultar el reporte ya volvemos al 'edicion'
			}
			if (data.Reason === 'set_readonly') {
				this.readonly = data.Payload;
				this.puedeEditar = !this.readonly || this.model.TropaID.isEmpty();
			} 
			this.cd.detectChanges();
		});
	}

	ngOnDestroy() {
		if (this.sub) { this.sub.unsubscribe(); }
	}

	onSave(e, repeat: boolean = false): void { 
		this.setModelPropsByControls();

		if (this.runValidaciones() == false) { return; }

		if (this.model && this.model.TropaID) {
			if (!(this.model.TropaID instanceof Guid)) {
				this.model.TropaID = Guid.parse(this.model.TropaID);
			}
		}

		if (this.model.TropaID.isEmpty()) {
			this.e = e;
			// si es nueva mostrar un reporte antes a modo de 'Confirmacion'
			let extras: any = {
				ClienteNombre: this.model.ClienteNombre || '',
				ProveedorNombre: this.model.ProveedorNombre || '',
				OrigenNombre: this.model.TropaOrigenNombre || '',
				ColorPrincipal: this.Tropa.Color.getColorValue()
			}

			this.TropaReporte.cargarTropa(this.model, extras);
			this.confirmacion = true;
			this.cd.detectChanges();

			let scrollToTop = window.setInterval(() => {
				let pos = window.pageYOffset;
				if (pos > 0) {
					window.scrollTo(0, pos - 20);
				} else {
					window.clearInterval(scrollToTop);
				}
			}, 16);

			return; 
		}
		else {
			// en la edicion guardar de una
			super.onSave(e, repeat); 
			this.IngresoHacienda.initializeControl();
		}
	}

	ngAfterViewInit() {
		this.edicion = false;

		this.Tropa.lstControls.forEach(c => this.lstControls.push(c)); 
		this.IngresoHacienda.initializeControl();

		//this.IngresoHacienda.DesbasteInput.setParent(this);
		//this.IngresoHacienda.PrecioInput.setParent(this);
		//this.IngresoHacienda.PesoInput.setParent(this);

		this.IngresoHacienda.pesoTropa = false;
		this.IngresoHacienda.precioTropa = false;
		this.IngresoHacienda.DesbasteInput.setValue(0);
		this.IngresoHacienda.PrecioInput.setValue(0);
		this.IngresoHacienda.PesoInput.setValue(0);

		this.TropaCategoriaGanadoList.IngresoHacienda = this.IngresoHacienda;
	}

	onInputValueChange(newValue, labelName): void {
		//console.log(newValue);
		//console.log(this.model.CategoriaGanados);
	}

	setModelPropsByControls(): void {
		let desbaste: number = this.IngresoHacienda.getDesbasteValue();
		let precio: number = this.IngresoHacienda.getPrecioValue();
		let peso: number = this.IngresoHacienda.getPesoValue();

		let cantidadCabezas: number = this.model.CategoriaGanados.reduce((cantidadCabezas, current) => cantidadCabezas + current.Cantidad, 0);

		if (cantidadCabezas > 0) {
			for (let cc of this.model.CategoriaGanados) {
				if (this.model.EsPesoPorTropa) {
					// repartir el peso en la tropa. El desbaste al ser % es igual para todos
					cc.DesbastePorc = desbaste;
					cc.PesoCabezaKg = peso / cantidadCabezas; 
				}
				if (this.model.EsPrecioPorTropa) {
					// repartir el precio
					cc.PrecioCompra = precio / cantidadCabezas;
				}
			}
		}

		this.isNewModel = !this.model.TropaID || this.model.TropaID.isEmpty();

		this.Tropa.setModelValues(this.model);
	} 

	getNewModel(): Tropa { return new Tropa(); };
	getKeyModel(obj: Tropa): Guid { return obj.TropaID; };

	vista: string = 'tropa'
	cambiarVista(nuevaVista: string): void {
		this.vista = nuevaVista;
	}

	runCustomValidaciones(): boolean {

		if (Utils.getDateWithTime(this.Tropa.Fecha.getFecha(), this.Tropa.Hora.getHora()) > new Date()) {
			this.MessageDialog.showAtencionDialog('La fecha y hora del movimiento no puede ser mayor a la actual.');
			return false;
		}

		if (this.model.TropaID.isEmpty() && this.model.CategoriaGanados.length == 0) {
			this.MessageDialog.showAtencionDialog('No se puede generar una tropa sin incluir ninguna Categoría de Ganado (Hacienda).');
			return false;
		}

		let transportesPasadosFecha: string[] = [];
		for (const transporte of this.model.Transportes) {
			if (transporte.Fecha > this.model.FechaIngreso) {
				transportesPasadosFecha.push(transporte.DTEProveedorTransporte);
			}
		}

		if (transportesPasadosFecha.length > 0) {
			let mensaje: string = '';

			if (transportesPasadosFecha.length === 1) {
				mensaje = `La fecha del transporte '${transportesPasadosFecha[0]}' debe ser igual o inferior a la fecha de ingreso de la tropa.`;
			} else {
				mensaje = `La fecha de los transportes '${transportesPasadosFecha.join(',')}' debe ser igual o inferior a la fecha de ingreso de la tropa.`;
			}

			this.MessageDialog.showAtencionDialog(mensaje);
			return false;
		}

		return true;
	}

	showTropa(tropaId: Guid) {
		this.edicion = true;
		this.getAndSetModel(tropaId);
	}

	setModel(obj: Tropa, parentCarga: EditableFormList<Tropa> = undefined) {
		this.IngresoHacienda.initializeControl();
		super.setModel(obj, parentCarga);

		this.IngresoHacienda.precioTropa = this.model.EsPrecioPorTropa;
		this.IngresoHacienda.pesoTropa = this.model.EsPesoPorTropa; 
		// en el caso de tener -tildado- precio o peso por tropa, hay que sumar los totales para que de
		// es lo inverso a guardar

		let cantidadCabezas: number = 0; // total
		let desbaste: number = 0; // el desbaste es global, pero podemos sacar un promedio por si alguno se modificó
		let precio: number = 0;
		let peso: number = 0;

		for (const cg of this.model.CategoriaGanados) {
			cantidadCabezas += cg.Cantidad;
			desbaste += cg.DesbastePorc;
			precio += (cg.PrecioCompra * cg.Cantidad);
			peso += cg.PesoCabezaKg * cg.Cantidad;
		}
		 
		desbaste = desbaste / this.model.CategoriaGanados.length;

		if (cantidadCabezas > 0) { 
			if (this.model.EsPrecioPorTropa) {
				this.IngresoHacienda.PrecioInput.setValue(precio);
			}

			if (this.model.EsPesoPorTropa) {
				this.IngresoHacienda.DesbasteInput.setValue(desbaste);
				this.IngresoHacienda.PesoInput.setValue(peso);
			}			
		}

		this.readonly = this.model.TropaID.isEmpty();
	}

	onCancel(e: any) {
		this.router.navigate([getRoute(Routing.TROPAS_SEGUIMIENTOS)]);
	}

	informSave(sucess: boolean) { 
		super.informSave(sucess);
		if (sucess) {
			this.router.navigate([getRoute(Routing.TROPAS_SEGUIMIENTOS)]);
		} else {
			this.confirmacion = false;
		}
	}

	onDeleteFinish(sucess: boolean) {
		super.onDeleteFinish(sucess);
		if (sucess) {
			this.router.navigate([getRoute(Routing.TROPAS_SEGUIMIENTOS)]);
		}
	}
}
