<div *ngIf="loading">
	<div class="kt-portlet mb-0">
		<div class="kt-portlet__body kt-portlet__body--fit p-3">
			<div class="kt-spinner kt-spinner--sm kt-spinner--success kt-spinner--left kt-spinner--input">
				&nbsp;&nbsp; <span class="ml-5">Cargando Reporte...</span>
			</div>
		</div>
	</div>
</div>

<div class="kt-portlet" *ngIf="!loading && venta">
	<div class="kt-portlet__body kt-portlet__body--fit">
		<div class="kt-invoice-2">
			<!-- Encabezado -->
			<div class="kt-invoice__head kt-invoice__head__bg">
				<div class="kt-invoice__container">
					<div class="kt-invoice__brand">
						<h1 class="kt-invoice__title">
							EGRESO DE HACIENDA POR VENTA
							<br /><span *ngIf="confirmacion" class="kt-invoice__title kt-font-bold kt-invoice__subtitle kt-font-danger">Por favor, confirme los datos de venta</span>
						</h1>
						<div href="#" class="kt-invoice__logo">
							<a href="#"><img class="sigef--reporte--logo" src="./assets/media/logos/logo-4-b.png"></a>
							<span class="kt-invoice__desc">
							</span>
						</div>
					</div>
					<div class="kt-invoice__title">DATOS GENERALES</div>
					<div class="kt-invoice__items" style="margin-top: 15px">
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Fecha y Hora de Egreso</span>
							<span class="kt-invoice__text">{{ venta.Fecha | parseDateHour }}</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Número de Transacción</span>
							<span class="kt-invoice__text">{{ venta.NroTransaccion == 0 ? '-' :  venta.NroTransaccion}}</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Comprador</span>
							<span class="kt-invoice__text">{{ comprador }}</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Destino</span>
							<span class="kt-invoice__text">{{ destino }}</span>
						</div>
					</div>
				</div>
			</div>

			<!-- Hacienda -->
			<div class="kt-invoice__body">
				<div class="kt-invoice__container">
					<div class="kt-invoice__title">HACIENDA &amp; FAENA</div>
					<div class="table-responsive">
						<table class="table" *ngFor="let cat of venta.Hacienda">
							<thead>
								<tr>
									<th class="text-left">Categoría</th>
									<th class="text-center">Cantidad</th>
									<th class="text-center">Tropa</th>
									<th class="text-center">Propietario</th>
									<th class="text-center">Días Corral</th>
									<th class="text-center">Precio Venta</th>
									<th class="faena text-center">DATOS FAENA</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td class="text-left">{{ cat.CategoriaGanadoNombre }}</td>
									<td class="kt-font-regular text-center">{{ cat.Cantidad | parseNumber : true }}</td>
									<td class="kt-font-regular text-center">{{ cat.TropaNombre }}</td>
									<td class="kt-font-regular text-center">{{ cat.ClienteNombre ? cat.ClienteNombre : 'Sin Propietario' }}</td>
									<td class="kt-font-regular text-center">{{ cat.FechaIngreso | diasEnCorral : venta.Fecha }}</td>
									<td class="kt-font-regular text-center">{{ cat.PrecioVenta | parseNumberMoney }}</td>
									<td class="p-0" *ngIf="cat.Faenas && cat.Faenas.length > 0">
										<table class="table faena-table">
											<thead>
												<tr>
													<th class="text-center faena faena-header">Cantidad</th>
													<th class="text-center faena faena-header">Kilos Vivos</th>
													<th class="text-center faena faena-header">Kilos Carne</th>
													<th class="text-center faena faena-header">Precio Faena</th>
													<th class="text-center faena faena-header">Rinde (%)</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let faena of cat.Faenas">
													<td class="kt-font-regular faena p-0 text-center">{{ faena.CantCabezas | parseNumber : true }}</td>
													<td class="kt-font-regular faena p-0 text-center">{{ faena.KilosVivo | parseNumberKg }}</td>
													<td class="kt-font-regular faena p-0 text-center">{{ faena.KilosCarne | parseNumberKg }}</td>
													<td class="kt-font-regular faena p-0 text-center">{{ faena.PrecioFaena | parseNumberMoney }}</td>
													<td class="kt-font-regular faena p-0 text-center">{{ (faena.KilosCarne / faena.KilosVivo) | parseNumberPorc }}</td>
												</tr>
											</tbody>
										</table>
									</td>
									<td colspan="5" class="text-left" *ngIf="cat.Faenas && cat.Faenas.length == 0">
										<span class="faena">No se han cargado datos de Faena</span>
									</td>
								</tr>
							</tbody>
						</table>
						<div class="kt-divider p-3"><span></span></div>
					</div>
				</div>
			</div>

			<!-- Transportes -->
			<div class="kt-invoice__body">
				<div class="kt-invoice__container">
					<div class="kt-invoice__title">TRANSPORTE</div>
					<div class="table-responsive">
						<table class="table">
							<thead>
								<tr>
									<th class="text-left">Fecha</th>
									<th class="text-left">Transportista</th>
									<th class="text-center">($) Flete</th>
									<th class="text-left">DTEs</th>
									<th class="text-center">Cantidad Cabezas</th>
								</tr>
							</thead>
							<tbody *ngIf="venta && venta.Transportes && venta.Transportes.length > 0">
								<tr *ngFor="let trans of venta.Transportes">
									<td class="">{{ trans.Fecha | parseDate }}</td>
									<td class="kt-font-regular text-left">{{ trans.DTEProveedorTransporte }}</td>
									<td class="kt-font-regular text-center">{{ trans.GastoFlete | parseNumberMoney }}</td>
									<td class="kt-font-regular text-left">
										<ul class="nobull" style="padding: 0">
											<li *ngFor="let dte of trans.DTEs">
												{{ dte.DTENro }}
											</li>
										</ul>
									</td>
									<td class="kt-font-regular text-center">
										<ul class="nobull" style="padding: 0">
											<li *ngFor="let dte of trans.DTEs">
												{{ dte.DTECantidad | parseNumber : true }}
											</li>
										</ul>
									</td>
								</tr>
							</tbody>
							<tbody *ngIf="venta && venta.Transportes && venta.Transportes.length == 0">
								<tr>
									<td colspan="8">
										<span style="font-weight: 300">No se han cargado datos de Transporte</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<!-- Rentabilidad -->
			<div class="kt-invoice__head">
				<div class="kt-invoice__container">
					<div class="kt-invoice__title">DATOS DE RENTABILIDAD</div>
					<div class="kt-invoice__items" style="margin-top: 15px">
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Gastos de Consumo</span>
							<span class="kt-invoice__text">{{ venta.GastosConsumo | parseNumberMoney }}</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Gastos de Sanidad</span>
							<span class="kt-invoice__text">{{ venta.GastosSanidad | parseNumberMoney }}</span>
						</div>
					</div>
				</div>
			</div>

			<!-- Acciones -->
			<div class="kt-invoice__actions">
				<div class="kt-invoice__container">
					<div *ngIf="!confirmacion">
						<button type="button" class="btn btn-sigef-secondary btn-bold" (click)="goBackHandler($event)">Volver</button>
						<button type="button" class="btn btn-sigef-secondary btn-bold ml-2" (click)="printHandler($event)">Imprimir</button>
					</div>

					<button type="button" *ngIf="!confirmacion" class="btn btn-sigef btn-bold ml-2" (click)="editHandler($event)">Editar</button>
					<button type="button" *ngIf="confirmacion" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light': guardando}" [disabled]="guardando" class="btn btn-sigef btn-bold" (click)="confirmHandler($event)">Confirmar</button>
				</div>
			</div>
		</div>
	</div>
</div>
