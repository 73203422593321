import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { EditableFormList } from '../../common_controls/common-controls.interface';
import { VentaTransporteDTE } from '../../../../../models/venta-transporte-dte';
import { IParentOpenInDialog, MyMessageDialogComponent } from '../../common_controls/message-dialog/my-message-dialog.component';
import { VentaTransporte } from '../../../../../models/venta-transporte';
import { VentaTransporteDteComponent } from '../venta-transporte-dte/venta-transporte-dte.component';

@Component({
  selector: 'kt-venta-transporte-dte-list',
  templateUrl: './venta-transporte-dte-list.component.html',
  styleUrls: ['./venta-transporte-dte-list.component.scss']
})
export class VentaTransporteDteListComponent extends EditableFormList<VentaTransporteDTE> implements OnInit, IParentOpenInDialog {

	@ViewChild(MyMessageDialogComponent, { static: true }) public MessageDialog: MyMessageDialogComponent;
	@Input() tabindex: number = 6000;

	private _ventaTransporte: VentaTransporte;
	@Input()
	set ventaTransporte(item: VentaTransporte) {
		this._ventaTransporte = item;
		this.datos = (this._ventaTransporte) ? this._ventaTransporte.DTEs : [];
		this.buscando = false;
		this.cd.detectChanges();
	}
	get tropaTransporte(): VentaTransporte { return this._ventaTransporte; }

	constructor(cd: ChangeDetectorRef) { super(cd, undefined) }

	ngOnInit() { }

	getKeyModel(obj: VentaTransporte): any { return obj.TransporteID; }

	public removeHandler({ dataItem }) { this.confirmDelete(dataItem); }

	isNewModel: boolean = false;
	public showABM({ dataItem }) {
		this.isNewModel = dataItem == undefined;
		let title = ((this.isNewModel) ? "Agregar" : "Editar") + " DTE"; 
		this.MessageDialog.showComponentDialog(title, VentaTransporteDteComponent, this, dataItem, {});
	}

	public saveResultDialogModel(model: VentaTransporteDTE) {
		if (this.isNewModel) {
			this._ventaTransporte.DTEs.push(model);
		}
		else {
			let item = this._ventaTransporte.DTEs.find(x => x.TransporteDTEID == model.TransporteDTEID);
			if (item) { Object.assign(item, model); }
		}
		this.cd.detectChanges();
	}
}

