import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http'; 
import { AlimentoAlmacen } from '../models/alimento-almacen';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable()
export class AlimentoAlmacenService extends GenericoService<AlimentoAlmacen> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'alimentoalmacen');
	}
	 
	getEditableList(): Observable<AlimentoAlmacen[]> {
		return this._httpClient.get<AlimentoAlmacen[]>(environment.apiUrl + `/${this._endpoint}/editable`, { headers: this._getHeaders });
	} 
}
