<div>
	<div class="row row-sigef">
		<my-col-datetimepicker [tabindex]="0" #FechaMovimiento [labelName]="'Fecha Movimiento'"></my-col-datetimepicker>
	</div>

	<div id="no-compra" class="pt-3 pb-3">
		<div class="row row-sigef">
			<my-col-dropdownlist #Alimento [parent]="component" [tabindex]="5" [key]="'Alimento'" [labelName]="'Producto'" [required]="true"></my-col-dropdownlist>
		</div>
		<div class="row row-sigef" [ngClass]="{'d-none': (movTipo != 2 && movTipo != 3 && movTipo != 4)}">
			<my-col-dropdownlist [tabindex]="6" [key]="'AlmacenOrigen'" [parent]="component" #AlmacenOrigen [labelName]="(edicion || (movTipo == 2 || movTipo == 4)) ? 'Almacen' : 'Origen'" [required]="true"></my-col-dropdownlist>
		</div>
		<div class="row row-sigef" [ngClass]="{'d-none': movTipo != 3 || edicion}">
			<my-col-dropdownlist [tabindex]="7" [key]="'AlmacenDestino'" [parent]="component" #AlmacenDestino [labelName]="'Destino'" [required]="true"></my-col-dropdownlist>
		</div>
		<div class="row row-sigef">
			<my-col-labelinfo [extraClass]="{'red-label': (stockActual < 0)}" [ngClass]="{'d-none': edicion}" #CantPropuesta [labelName]="cantidadActual"></my-col-labelinfo>
			<my-col-input [tabindex]="8" #CantReal [mask]="'#,# Kg'" [decimals]="0" [labelName]="movTipo == 4 ? 'Cantidad Real' : 'Cantidad Kg'" [allowCero]="false" [required]="true"></my-col-input>
		</div>
		<div *ngIf="mostrarAlertaOrigenDestino && !edicion" class="alert alert-warning m-0 p-0 pl-3 pr-0 text-center" style="width: 36.5rem;" role="alert">
			<div class="alert-text">
				La cantidad ingresada supera al máximo disponible en origen.
			</div>
		</div>
	</div>


	<my-row-expand class="row row-sigef mt-1" [tabindex]="10" [title]="Observaciones" [hint]="'Seleccione esta opción para incluir una aclaración adicional'" [labelName]="'Observaciones'" [expanded]="adicionalesVisible" (onExpand)="adicionalesVisible=$event"></my-row-expand>
	<div class="row row-sigef" [ngClass]="{'d-none': !adicionalesVisible}">
		<my-col-textarea [tabindex]="11" [labelName]="'Observaciones'" [rows]="2" [labelVisible]="false" class="w-100"></my-col-textarea>
	</div>

	<div class="kt-divider kt-divider-sigef"><span></span></div>

	<div class="ml-3 mr-3">
		<my-group-save-buttons [tabindex]="12" class="w-100" [saving]="saving" [targetName]="'Movimiento'"
							   (onSave)="onSave($event)" (onCancel)="onCancel($event)"></my-group-save-buttons>
	</div>
</div>
<my-message-dialog></my-message-dialog>
