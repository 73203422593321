<div class="p-2">
	<div *ngIf="!dietaAlimentoDashboard">
		<div class="kt-portlet mb-0">
			<div class="kt-portlet__body kt-portlet__body--fit p-3">
				<div class="kt-spinner kt-spinner--sm kt-spinner--success kt-spinner--left kt-spinner--input">
					&nbsp;&nbsp; <span class="ml-5">Cargando Movimiento...</span>
				</div>
			</div>
		</div>
	</div>

	<table class="table" *ngIf="dietaAlimentoDashboard">
		<tbody>
			<tr class="table-active">
				<th scope="row">Producto</th>
				<th class="text-right" scope="row">Precio MS</th>
				<th class="text-right" scope="row">Precio Tal Cual</th>

			</tr>
			<tr *ngFor="let alimento of dietaAlimentoDashboard; index as i">
				<td><span class="">{{ alimento.AlimentoNombre }}</span></td>
				<td class="text-right"><span>{{ alimento.PrecioMS | parseNumberMoney : true  }}</span></td>
				<td class="text-right"><span>{{ alimento.PrecioTalCual | parseNumberMoney : true  }}</span></td>
			</tr>
			<tr class="table-secondary">
				<th scope="row"><span class="">Total</span></th>
				<th class="text-right" scope="row"><span>{{ getTotalMS() | parseNumberMoney : true  }}</span></th>
				<th class="text-right" scope="row"><span>{{ getTotalTalCual() | parseNumberMoney : true  }}</span></th>
			</tr>
		</tbody>
	</table>

	<div class="ml-3 mr-3 pl-3 mt-3 row">
		<div class="row">
			<button tabindex="99999" class="btn btn-sigef mr-1" (click)="clickCancel($event)">Cerrar</button>
		</div>
	</div>
</div>
