<kendo-grid tabindex="{{tabindex}}"
			[data]="datos"
			(edit)="showABM($event)"
			(remove)="removeHandler($event)"
			(add)="showABM($event)"
			[loading]="buscando">
	<ng-template kendoGridToolbarTemplate>
		<button tabindex="{{tabindex + 1}}" kendoGridAddCommand class="add-btn btn-sigef">Agregar Transporte</button>
	</ng-template>
	<ng-template kendoGridNoRecordsTemplate>
		No hay datos de transporte para esta venta
	</ng-template>

	<kendo-grid-column title="Fecha">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{dataItem.Fecha | date: 'dd/MM/yyyy'}}</span>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column field="DTEProveedorTransporte" title="Transportista">
	</kendo-grid-column>
	<kendo-grid-column title="Gasto Flete">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{ dataItem.GastoFlete | parseNumberMoney }}</span>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-command-column class="k-grid-ignore-click" title="Acciones" width="120">
		<ng-template kendoGridCellTemplate>
			<button kendoGridEditCommand class="btn btn-sm"><i title="Editar" class="{{'Editar' | parseIconAccion }}"></i></button>
			<button kendoGridRemoveCommand class="btn btn-sm k-grid-ignore-click"><i title="Eliminar" class="{{'Eliminar' | parseIconAccion }}"></i></button>
		</ng-template>
	</kendo-grid-command-column>
</kendo-grid>
<my-message-dialog></my-message-dialog>
