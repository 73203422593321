import { NgModule } from '@angular/core'; 
import { PopupAnchorDirective } from './popup.anchor-target.directive';
import { InlineAnchorDirective } from './inline.anchor-target.directive';
import { SelectOnClickDirective } from './select-on-click.directive';
import { HashDirective } from './hash.directive';
import { FactorDirective } from './factor.directive';
import { ComponentHostDirective } from './component-host.directive'; 
import { KendoGridPersistentStateDirective } from './kendo-grid-persisent-state.directive';
import { AutofocusControlDirective } from './autofocus-control.directive';

@NgModule({
  imports: [],
	declarations: [PopupAnchorDirective, InlineAnchorDirective, SelectOnClickDirective, HashDirective, FactorDirective, ComponentHostDirective, KendoGridPersistentStateDirective, AutofocusControlDirective],
	exports: [PopupAnchorDirective, InlineAnchorDirective, SelectOnClickDirective, HashDirective, FactorDirective, ComponentHostDirective, KendoGridPersistentStateDirective, AutofocusControlDirective]
})
export class DirectivesModule { }
