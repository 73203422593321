import { Component, OnInit, Input, AfterViewInit, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { CommonControl } from '../common-controls.interface'; 
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';

@Component({
	selector: 'my-col-input',
	templateUrl: './my-col-input.component.html',
	styleUrls: ['./my-col-input.component.scss']
})
export class MyColInputComponent extends CommonControl implements OnInit, AfterViewInit {
	@Input() parent: any = undefined;
	@Input() mask: string = 'n0'; 
	@Input() placeholder: string = '';
	@Input() tooltipMessage: string = '';
	@Input() tooltipTitle: string = '';
	@Input() readonly: boolean = false;
	@Input() disabled: boolean = false;
	@Input() allowCero: boolean = true;
	@Input() type: number = 1; //1 = number; 2 = string
	@Input() extraClass: string = '';
	@Input() factor: any = 1; // VER /directives/factor.directive.ts
	@Input() horizontal: boolean = false;
	@Input() decimals: number = 2
	@Input() inputType: string = 'text'; // text | password
	@Input() divClass: string = 'col-sigef-width';
	@Input() hint: string = '';
	@Input() labelClass: string = '';

	@ViewChildren('controlStringInput') MyControlStrings: QueryList<ElementRef>;
	@ViewChildren('controlInput') MyControls: QueryList<NumericTextBoxComponent>;

	constructor() {
		super();
		this.Form = new UntypedFormGroup({
			'InputNumber': new UntypedFormControl(0),
			'InputString': new UntypedFormControl('')
		});
	}

	ngOnInit() {
		this.Form.reset();

		if (this.MyControlStrings) this.MyControlStrings.changes.subscribe((queryChanges: QueryList<ElementRef>) => { this.MyControlStrings = queryChanges; });
		if (this.MyControls) this.MyControls.changes.subscribe((queryChanges: QueryList<NumericTextBoxComponent>) => { this.MyControls = queryChanges; });

		if (this.type === 1) {
			this.Form.controls['InputNumber'].setValidators(this.getValidator());
		}
		else if (this.type == 2) {
			if (this.required) {
				this.Form.controls['InputString'].setValidators([Validators.required, Validators.minLength(1)]);
			}
		}
	}

	setValidators(list: ValidatorFn[], clearValidators: boolean = false) {
		if(clearValidators)
			this.getPrincipalControl().clearValidators();
		this.getPrincipalControl().setValidators(list);
		this.getPrincipalControl().updateValueAndValidity();
	}

	getValidator(maxValue: number = undefined): ValidatorFn[] {
		let lst: ValidatorFn[] = [];
		if (this.required && this.allowCero)
			lst.push(Validators.required);
		if (!this.allowCero)
			lst.push(this.validateNoZero);
		if (maxValue)
			lst.push(Validators.max(maxValue));
		return lst;
	}

	setMaxValue(value: number) {
		this.Form.controls['InputNumber'].setValidators(this.getValidator(value));
		this.Form.controls['InputNumber'].updateValueAndValidity();
	}

	getValue(): any {
		let v: any = undefined;

		if (this.type === 1)
			v = (+this.Form.value['InputNumber']); // / this.factor;
		if (this.type === 2)
			v = this.Form.value['InputString'];
		 
		return v;
	}

	setParent(parent: any): void {
		this.parent = parent;
	}

	keyDown(event: any): void {
		if (!this.parent || !this.parent.onInputValueChange) return;
		this.parent.onInputValueChange(event, this.labelName);
	}

	getPrincipalControl(): AbstractControl {
		let name = 'InputNumber';
		if (this.type === 2)
			name = 'InputString';
		return this.Form.controls[name];
	}

	setValue(value: any): void {
		if (this.type === 1)
			this.Form.patchValue({ InputNumber: value });
		if (this.type === 2)
			this.Form.patchValue({ InputString: value }); 
	}

	validateNoZero(c: UntypedFormControl): ValidationErrors | null {
		return (c.value && +c.value > 0) ? null : {
			validateNoZero: {
				valid: false
			}
		};
	}

	ngAfterViewInit(): void {
		if (this.focusOnInit) {
			this.focus();
		}
	}

	focus() {
		if (this.MyControls && this.MyControls.length === 1) this.MyControls.first.focus();
		if (this.MyControlStrings && this.MyControlStrings.length === 1) this.MyControlStrings.first.nativeElement.focus();
	}
}
