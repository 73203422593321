import { DTOInterface } from "../interfaces/dto.interface";
import { Guid } from "guid-typescript";

export class VentaTransporteDTE implements DTOInterface { 
	TransporteDTEID: Guid;
	TransporteRef: Guid;
    DTENro: string;
	DTECantidad: number;

	constructor() {
		this.clear();
	}

	clear(): void {
		this.TransporteDTEID = Guid.createEmpty();
		this.TransporteRef = Guid.createEmpty(); 
		this.DTENro = ''; 
		this.DTECantidad = 0;
	}
}
