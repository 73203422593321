// Angular
import { Component, OnInit, OnDestroy, ViewChild, ComponentFactoryResolver, AfterViewInit, ViewContainerRef } from '@angular/core';
// Layout
import { OffcanvasOptions, OffcanvasDirective } from '../../../../core/_base/layout';
import { MessengerService } from '../../../../../services/messenger.service';
import { Subscription } from 'rxjs';
import { ComponentHostDirective } from '../../../../../directives/component-host.directive';
import { BroadcastMessage, QUICKPANEL_CERRADO, QUICKPANEL_PACKAGE } from '../../../../../interfaces/message.interface';
import { OffCanvasInterface } from '../../../../../interfaces/off-canvas.interface';
import { ContainerService } from '../../../../../services/container.service';

@Component({
	selector: 'kt-quick-panel',
	templateUrl: './quick-panel.component.html',
	styleUrls: ['./quick-panel.component.scss']
})
export class QuickPanelComponent implements OnInit, OnDestroy, AfterViewInit {
	private sub: Subscription = undefined;
	private offCanvas: any = undefined;

	public loading: boolean = false;
	public title: string = '';

	// Public properties
	offcanvasOptions: OffcanvasOptions = {
		overlay: true,
		baseClass: 'kt-quick-panel',
		closeBy: 'kt_quick_panel_close_btn',
		toggleBy: 'kt_quick_panel_toggler_btn'
	};

	@ViewChild(ComponentHostDirective, { static: true }) componentHost: ComponentHostDirective;
	@ViewChild(OffcanvasDirective, { static: true }) quickPanel: OffcanvasDirective;

	constructor(private messengerService: MessengerService, private containerService: ContainerService, private componentFactoryResolver: ComponentFactoryResolver) {

	}

	ngAfterViewInit() {
		this.offCanvas = this.quickPanel.getOffcanvas();

		this.offCanvas.on('beforeHide', () => { this.messengerService.broadcastMessage(QUICKPANEL_CERRADO); });
	}

	ngOnInit() { 
		this.sub = this.messengerService.messageBroadcasted$.subscribe(
			(data: BroadcastMessage) => {
				if (data && data.Target === 'QuickPanel') { 
					 
					if (data.Reason === 'ShowLoadingStatus') {
						this.loading = true;
					}

					if (data.Reason === 'HideLoadingStatus') {
						this.loading = false;
					}

					if (data.Reason === 'HidePanel' && this.offCanvas) {
						this.offCanvas.hide(); 
					}

					if (data.Reason === 'CreateComponent' && this.offCanvas) {
						// set title
						this.title = data.Payload.Title;

						// inject component 
						const componentFactory = this.componentFactoryResolver.resolveComponentFactory(data.Payload.Component);

						const viewContainerRef = this.componentHost.viewContainerRef;
						viewContainerRef.clear();

						const componentRef = viewContainerRef.createComponent(componentFactory);

						this.containerService.store(componentRef, QUICKPANEL_PACKAGE);

						// pass data
						(<OffCanvasInterface>componentRef.instance).setOffCanvasData(data.Payload.DataItem, data.Payload.Extras);

						// show
						setTimeout(() => this.offCanvas.show(), 0); 
					}
				}
			}
		);
	} 

	ngOnDestroy() {
		if (this.sub) this.sub.unsubscribe();
	}
}
