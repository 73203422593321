// Routing
export enum Routing {
	DASHBOARD = 'dashboard',
	DASHBOARD_HOTELERO = 'dashboard/hotelero',

	// Administración
	ADMINISTRACION = 'administracion',
	ALMACENES = 'administracion/almacenes/listado',
	CLIMA = 'administracion/clima/listado',
	CORRALES = 'administracion/corrales/listado',
	DESTINOS_VENTAS = 'administracion/destinos-ventas/listado',
	DIETAS = 'administracion/dietas/listado',
	GANADO_CATEGORIAS = 'administracion/ganado/categorias/listado',
	MEDIDAS_UNIDADES = 'administracion/medidas-unidades/listado',
	MIXERS = 'administracion/mixers/listado',
	PRODUCTOS_ALIMENTICIOS = 'administracion/productos-alimenticios/listado',
	PRODUCTOS_SANITARIOS = 'administracion/sanitarios/listado',
	PRODUCTOS_SANITARIOS_CATEGORIAS = 'administracion/productos-sanitarios/categorias/listado',
	PROPIETARIOS = 'administracion/propietarios/listado',
	PROVEEDORES = 'administracion/proveedores/listado',
	USUARIOS = 'administracion/usuarios/listado',
	ZONAS = 'administracion/zonas/listado',

	// Alimentación
	ALIMENTACION_MENU = 'alimentacion',
	ALIMENTACION_CARGA = 'alimentacion/cargas/nueva-carga',
	ALIMENTACION_CARGAS = 'alimentacion/cargas/listado',
	ALIMENTACION_CONFIGURACION = 'alimentacion/configuracion',
	ALIMENTACION_DESCARGAS = 'alimentacion/descargas/listado',
	ALIMENTACION_MOVIMIENTO = 'alimentacion/movimientos/nuevo_movimiento',
	ALIMENTACION_MOVIMIENTOS = 'alimentacion/movimientos/listado',
	ALIMENTACION_STOCK = 'alimentacion/stock',

	TROPAS_MENU = 'tropas',
	TROPAS_CONSUMOS = 'tropas/consumo',
	TROPAS_SEGUIMIENTOS = 'tropas/seguimiento',
	TROPAS_ORIGENES = 'tropas/origenes',
	TROPAS_REPORTES = 'tropas/reportes',
	TROPAS_ARCHIVOS = 'tropas/archivos',
	TROPAS_RENTABILIDAD = 'tropas/rentabilidad',
	TROPAS_CIERRE = 'tropas/cierre',

	HACIENDA_MENU = 'hacienda',
	HACIENDA_OTROS = 'hacienda/otros',
	HACIENDA_CAMBIO_CORRALES = 'hacienda/cambio-corrales',

	HACIENDA_CONTROL_PESADA = 'hacienda/otros/control-pesadas',
	HACIENDA_INGRESO_PARICIONES = 'hacienda/otros/ingreso-pariciones',
	HACIENDA_RECUENTOS = 'hacienda/otros/recuentos',
	HACIENDA_OPERACION_INTERNA = 'hacienda/otros/operacion-internas',
	HACIENDA_CAMBIO_CATEGORIAS = 'hacienda/otros/cambio-categorias',

	HACIENDA_EGRESO_MUERTES = 'hacienda/egreso-muertes',
	HACIENDA_EGRESO_VENTAS = 'hacienda/egreso-ventas',
	HACIENDA_INGRESO_COMPRAS = 'hacienda/ingreso-compras',
	HACIENDA_VENTAS = 'hacienda/ventas/nueva-venta',
	HACIENDA_VENTAS_LISTADO = 'hacienda/ventas/listado',

	REPORTES_MENU = 'reportes',
	REPORTES_ALIMENTACION_CARGAS = 'reportes/alimentacion/cargas',
	REPORTES_ALIMENTACION_MOVIMIENTOS = 'reportes/alimentacion/movimientos',
	REPORTES_ALIMENTACION_STOCK = 'reportes/alimentacion/stocks',
	REPORTES_ALIMENTACION_CONSUMO = 'reportes/alimentacion/consumo',
	REPORTES_TROPA_MOVIMIENTOS = 'reportes/tropa/movimientos',

	PRODUCTOS_SANITARIOS_MENU = 'productos-sanitarios',
	PRODUCTOS_SANITARIOS_MOVIMIENTOS = 'productos-sanitarios/movimientos/listado',
	PRODUCTOS_SANITARIOS_MOVIMIENTO = 'productos-sanitarios/movimientos/nuevo-movimiento',
	PRODUCTOS_SANITARIOS_PLANES = 'productos-sanitarios/planes',
	PRODUCTOS_SANITARIOS_STOCK = 'productos-sanitarios/stocks',
	PRODUCTOS_SANITARIOS_REGISTRO_APLICACION = 'productos-sanitarios/registro-aplicaciones',
	PRODUCTOS_SANITARIOS_MOVIMIENTO_STOCK = 'productos-sanitarios/movimientos/stock',

	AYUDA = 'ayuda'
}

export function getRoute(route: Routing, prefix: boolean = true, suffix: boolean = false): string {
	let currentUser = JSON.parse(localStorage.getItem('currentUser'));
	let p: string = ""
	let empresa: string = 'sigef';

	if (currentUser && currentUser.Empresa)
		empresa = currentUser.Empresa.toLowerCase();

	if (prefix)
		p = `/${empresa}/`;
	else
		p = '/';

	return `${p}${route}${(suffix ? '/' : '')}`;
}

export function getPermission(route: Routing): string[] {

	switch (route)
	{

		case Routing.ADMINISTRACION:
			return ['Administrador'];
		case Routing.ALMACENES:
			return ['Administrador'];
		case Routing.ALIMENTACION_CARGA:
			return ['Administrador', 'Mixero'];
		case Routing.ALIMENTACION_CARGAS:
			return ['Administrador', 'Mixero'];
		case Routing.ALIMENTACION_CONFIGURACION:
			return ['Administrador'];
		case Routing.ALIMENTACION_DESCARGAS:
			return ['Administrador', 'Mixero'];
		case Routing.ALIMENTACION_MENU:
			return['Administrador', 'Mixero'];
		case Routing.ALIMENTACION_MOVIMIENTO:
			return['Administrador'];
		case Routing.ALIMENTACION_MOVIMIENTOS:
			return['Administrador'];
		case Routing.ALIMENTACION_STOCK:
			return ['Administrador'];
		case Routing.CLIMA:
			return ['Administrador'];
		case Routing.CORRALES:
			return ['Administrador'];
		case Routing.DASHBOARD:
			return ['Administrador', 'Nutricionista'];
		case Routing.DASHBOARD_HOTELERO:
			return ['Administrador', 'Hotelero'];
		case Routing.DESTINOS_VENTAS:
			return ['Administrador'];
		case Routing.DIETAS:
			return ['Administrador'];
		case Routing.GANADO_CATEGORIAS:
			return ['Administrador'];
		case Routing.HACIENDA_CAMBIO_CATEGORIAS:
			return['Administrador'];
		case Routing.HACIENDA_CAMBIO_CORRALES:
			return['Administrador'];
		case Routing.HACIENDA_CONTROL_PESADA:
			return['Administrador'];
		case Routing.HACIENDA_EGRESO_MUERTES:
			return ['Administrador'];
		case Routing.HACIENDA_EGRESO_VENTAS:
			return ['Administrador'];
		case Routing.HACIENDA_INGRESO_COMPRAS:
			return['Administrador'];
		case Routing.HACIENDA_INGRESO_PARICIONES:
			return['Administrador'];
		case Routing.HACIENDA_MENU:
			return['Administrador'];
		case Routing.HACIENDA_OPERACION_INTERNA:
			return['Administrador']
		case Routing.HACIENDA_OTROS:
			return['Administrador'];
		case Routing.HACIENDA_RECUENTOS:
			return ['Administrador'];
		case Routing.HACIENDA_VENTAS:
			return ['Administrador'];
		case Routing.HACIENDA_VENTAS_LISTADO:
			return ['Administrador'];
		case Routing.MEDIDAS_UNIDADES:
			return ['Administrador'];
		case Routing.MIXERS:
			return ['Administrador'];
		case Routing.PRODUCTOS_ALIMENTICIOS:
			return ['Administrador'];
		case Routing.PRODUCTOS_SANITARIOS:
			return ['Administrador'];
		case Routing.PRODUCTOS_SANITARIOS_CATEGORIAS:
			return ['Administrador'];
		case Routing.PRODUCTOS_SANITARIOS_MENU:
			return['Administrador', 'Veterinario'];
		case Routing.PRODUCTOS_SANITARIOS_MOVIMIENTO:
			return['Administrador', 'Veterinario'];
		case Routing.PRODUCTOS_SANITARIOS_MOVIMIENTOS:
			return['Administrador', 'Veterinario'];
		case Routing.PRODUCTOS_SANITARIOS_PLANES:
			return['Administrador', 'Veterinario'];
		case Routing.PRODUCTOS_SANITARIOS_REGISTRO_APLICACION:
			return['Administrador', 'Veterinario'];
		case Routing.PRODUCTOS_SANITARIOS_STOCK:
			return ['Administrador', 'Veterinario'];
		case Routing.PRODUCTOS_SANITARIOS_MOVIMIENTO_STOCK:
			return ['Administrador', 'Veterinario'];
		case Routing.PROPIETARIOS:
			return ['Administrador'];
		case Routing.PROVEEDORES:
			return ['Administrador'];
		case Routing.REPORTES_ALIMENTACION_CARGAS:
			return ['Administrador'];
		case Routing.REPORTES_ALIMENTACION_CONSUMO:
			return ['Administrador'];
		case Routing.REPORTES_ALIMENTACION_MOVIMIENTOS:
			return ['Administrador'];
		case Routing.REPORTES_ALIMENTACION_STOCK:
			return ['Administrador'];
		case Routing.REPORTES_MENU:
			return ['Administrador'];
		case Routing.REPORTES_TROPA_MOVIMIENTOS:
			return ['Administrador'];
		case Routing.TROPAS_ARCHIVOS:
			return ['Administrador'];
		case Routing.TROPAS_CIERRE:
			return ['Administrador'];
		case Routing.TROPAS_CONSUMOS:
			return ['Administrador', 'Nutricionista'];
		case Routing.TROPAS_MENU:
			return ['Administrador', 'Nutricionista'];
		case Routing.TROPAS_ORIGENES:
			return ['Administrador'];
		case Routing.TROPAS_RENTABILIDAD:
			return ['Administrador', 'Nutricionista', 'Hotelero'];
		case Routing.TROPAS_REPORTES:
			return ['Administrador', 'Nutricionista', 'Hotelero'];
		case Routing.TROPAS_SEGUIMIENTOS:
			return ['Administrador', 'Nutricionista'];
		case Routing.USUARIOS:
			return ['Administrador'];
		case Routing.ZONAS:
			return ['Administrador'];

		default:
			return[];
	}

}
