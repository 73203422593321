// Angular
import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
// Services
import { Dictionary } from '@ngrx/entity';
import * as _ from 'lodash';
import { CorralService } from '../../../../../services/corral.service';
import { CorralConsumoGraficoInterface } from '../../../../../interfaces/corral-consumo-grafico.interface';
import { CorralDescargaGraficoInterface } from '../../../../../interfaces/corral-descarga-grafico.interface';
import { MovTropaDashboard } from '../../../../../models/mov-tropa-dashboard';
import { DietaService } from '../../../../../services/dieta.service';
import { MyColDropDownListComponent, MyColButtonComponent } from '../../../partials/common_controls';
import Utils from '../../../../../utils';

@Component({
  selector: 'kt-tropa-consumo',
  templateUrl: './tropa-consumo.component.html',
	styleUrls: ['./tropa-consumo.component.scss'],
	providers: [CorralService, DietaService]
})
export class TropaConsumoComponent implements OnInit {
	public consumos: Dictionary<CorralConsumoGraficoInterface[]> = {};
	public descargas: Dictionary<CorralDescargaGraficoInterface[]> = {};
	public movimientos: MovTropaDashboard[] = [];
	public buscando: boolean; 
	public diasPreviosCantidad: number = 15;

	public vista: string = 'consumo';
	public tablero: string = 'general';

	private fechaDesde: Date;
	private fechaHasta: Date;

	@ViewChild('Dieta', { static: true }) Dieta: MyColDropDownListComponent;
	@ViewChild('Hotelero', { static: true }) Hotelero: MyColDropDownListComponent;
	@ViewChild(MyColButtonComponent, { static: true }) BtnBuscar: MyColButtonComponent;

	@Input() public mostrarDescarga: boolean = true;
	@Input() public cliente: number = 0;

	constructor(private corralService: CorralService,
		private dietaService: DietaService,
		private cd: ChangeDetectorRef) { }

	ngOnInit() {
		this.Dieta.initializeControl(this.dietaService, this.cd, 'Todas las Dietas');

		this.preset(15);
		this.cd.detectChanges();
	}

	recargar(): void {
		switch (this.vista) {
			case 'consumo':
				this.cargarConsumo();
				break;
			case 'descarga':
				this.cargarDescargas();
				break;
			default:
				break;
		}
	}

	cargarDescargas(): void {
		this.buscando = true;
		this.descargas = {};

		let fechaDesde = Utils.getStartOfDay(this.fechaDesde);
		let fechaHasta = Utils.getEndOfDay(this.fechaHasta);
		let dieta = this.Dieta.getValue() | 0;

		this.corralService.getDescargaGraficoList(fechaDesde, fechaHasta, dieta).subscribe(data => {
			this.descargas = _.groupBy(data, function (descarga) {
				return descarga.CorralNumero;
			});
		}, error => { }, () => {
			this.buscando = false;
			this.cd.detectChanges();
		});
	}

	cargarConsumo(): void {
		this.buscando = true;
		this.consumos = {};

		let fechaDesde = Utils.getStartOfDay(this.fechaDesde);
		let fechaHasta = Utils.getEndOfDay(this.fechaHasta);
		let dieta = this.Dieta.getValue() | 0;

		this.corralService.getConsumoGraficoList(fechaDesde, fechaHasta, dieta, this.cliente).subscribe(data => {
			this.consumos = _.groupBy(data, function (consumo) {
				return consumo.CorralNumero;
			});
		}, error => { }, () => {
			this.buscando = false;
			this.cd.detectChanges();
		});
	}

	cambiarVista(nuevaVista: string): void {
		this.vista = nuevaVista;
		this.recargar(); // mantener las fechas al cambiar entre vistas
	}

	cambiarTablero(nuevoTablero: string): void {
		this.tablero = nuevoTablero;
	}

	preset(dias: number): void {
		this.diasPreviosCantidad = dias;
		var d = new Date();

		this.fechaHasta = new Date(d);
		d.setDate(d.getDate() - dias);
		this.fechaDesde = new Date(d);

		this.recargar();
	}
}
