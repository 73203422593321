<kendo-grid *ngIf="showGrid" [kendoGridBinding]="descargas"
			[sortable]="true"
			[navigable]="true"
			[loading]="buscando">
	<kendo-grid-messages noRecords="No hay descargas realizadas de la carga">
	</kendo-grid-messages>

	<kendo-grid-column title="Fecha" width="130">
		<ng-template kendoGridCellTemplate let-dataItem>
			{{dataItem.FechaDescarga | parseDateHour }}
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column title="Mixero" field="UsuarioNombre" width="150">
	</kendo-grid-column>
	<kendo-grid-column title="Corral" field="CorralNombre" width="150">
	</kendo-grid-column>
	<kendo-grid-column title="Remanente" width="110">
		<ng-template kendoGridCellTemplate let-dataItem>
			{{dataItem.Remanente | parseNumberKg }}
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column title="Propuesto" width="110">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span *ngIf="!dataItem.CargaWeb">{{dataItem.CantidadPropuestaKg | parseNumberKg }}</span>
			<span *ngIf="dataItem.CargaWeb"><i>---</i></span>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column title="Descargado" width="110">
		<ng-template kendoGridCellTemplate let-dataItem>
			{{dataItem.CantidadRealKg | parseNumberKg }}
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column title="Exactitud" width="75">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span *ngIf="!dataItem.CargaWeb">{{getExactitud(dataItem) | parseNumberPorc : true }}</span>
			<span *ngIf="dataItem.CargaWeb"><i>---</i></span>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column title="Observaciones" field="Observaciones" width="150">
	</kendo-grid-column>
</kendo-grid>

