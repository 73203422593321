import { Pipe, PipeTransform } from '@angular/core';
import Utils from '../utils';

@Pipe({
    name: 'parseNumberPorc'
})
export class ParseNumberPorcPipe implements PipeTransform {

	transform(e: any, noDecimals: boolean = false): string {
		return Utils.formatNumberDecimal(Utils.checkNaNGetZero(e) * 100, noDecimals) + " %"; // Este pipe ahora recibe números entre 0 y 1. 
    }

}
