import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MessengerService } from '../../../../../services/messenger.service';
import { InfodiariaComponent } from '../../../partials/infodiaria/infodiaria/infodiaria.component';
import { InfoDiariaService } from '../../../../../services/info-diaria.service';
import { InfoDiaria } from '../../../../../models/info-diaria';
import { ParsePrecipitacionesPipe } from '../../../../../pipes/parse-precipitaciones.pipe';
import { Subject } from 'rxjs';
import { BroadcastMessage } from '../../../../../interfaces/message.interface';
import { takeUntil } from 'rxjs/operators';
import { Guid } from 'guid-typescript';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import Utils from '../../../../../utils';


@Component({
	selector: 'seleccion-infodiaria',
	templateUrl: './seleccion-infodiaria.component.html',
	styleUrls: ['./seleccion-infodiaria.component.scss'],
	providers: [InfoDiariaService]
})
export class SeleccionInfodiariaComponent implements OnInit, OnDestroy {
	private destroy$: Subject<void> = new Subject();

	observaciones: string = "- Sin observaciones -";
	precipitaciones: any = "- Sin precipitaciones -";
	private model: InfoDiaria = undefined;
	private infoFecha: Date = new Date();

	constructor(private messengerService: MessengerService, private quickPanelService: QuickPanelService, private cd: ChangeDetectorRef, private infoDiariaService: InfoDiariaService) { }

	ngOnInit() {
		this.messengerService.messageBroadcasted$.pipe(takeUntil(this.destroy$)).subscribe((data: BroadcastMessage) => {
			if (data.Target === 'SeleccionInfodiariaComponent' && data.Sender === 'InfoDiariaComponent') {
				this.getInfoDiaria((data.Payload as unknown as InfoDiaria).FechaInfoDiaria);
			}
		});
	}
	
	ngOnDestroy() {
		this.destroy$.next();
	}

	setData(): void {
		this.observaciones = this.model.ObservacionesAlimentacion === '' ? '- Sin observaciones -' : this.model.ObservacionesAlimentacion;
		this.precipitaciones = this.model.Precipitaciones === 0 ? '- Sin precipitaciones -' : new ParsePrecipitacionesPipe().transform(this.model.Precipitaciones);
		this.cd.detectChanges();
	}

	public getInfoDiaria(fecha: Date) {
		this.infoFecha = Utils.addDays(fecha, 0)
		this.infoFecha.setHours(10);

		//this.buscando = true;
		this.infoDiariaService.getByFecha(this.infoFecha).subscribe(
			data => {
				this.model = data;
				
				if (Guid.parse(this.model.InfoDiariaID.toString()).isEmpty()) {
					this.model.FechaInfoDiaria = this.infoFecha;
				}
				this.setData();
			},
			error => {

			},
			() => {
				//this.buscando = false;
			}
		);
	}

	public showInfoDiaria() {
		let title = `${Guid.parse(this.model.InfoDiariaID.toString()).isEmpty() ? 'Agregar' : 'Editar'} Información Diaria`;
		this.quickPanelService.createOffCanvasComponent(title, InfodiariaComponent, this.model);
	}
}
