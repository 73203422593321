<div [ngClass]="extraClass">
	<div class="form-group" [formGroup]="Form" style="width: 95px;">
		<label class="pb-1"  *ngIf="labelVisible">{{ labelName }}</label>
		<kendo-timepicker (valueChange)="onValueChange($event)"
						  [format]="'HH:mm'"
						  tabindex="{{tabindex}}"
						  placeholder="HH:MM"
						  #timeInput
						  formControlName="Time"
						  class="w-100">
		</kendo-timepicker>
		<kt-inline-server-validation [ngClass]="{'d-none': !showValidation}" [input]="timeInput" [formGroup]="Form" [field]="'Time'"></kt-inline-server-validation>
	</div>
</div>
