<kendo-grid [kendoGridBinding]="datos"
			kendoGridPersistentState gridName="GridTropaCorral"
			style="width: auto" 
			[sortable]="true"
			[navigable]="true"
			[resizable]="true"
			[reorderable]="true"
			[columnMenu]="true"
			[loading]="buscando">
	<kendo-grid-messages noRecords="No hay corrales para mostrar"></kendo-grid-messages>


	<kendo-grid-column title="Corral" width="200" field="CorralNombre" [includeInChooser]="false">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>
				<span><strong>{{dataItem.CorralNumero | parseCorralNumero}}</strong> ({{dataItem.CorralNombre}})</span>
			</span>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column field="DietaNombre" title="Dieta" width="90" [columnMenu]="false">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>
				{{dataItem.DietaNombre}}
			</span>
		</ng-template>
	</kendo-grid-column> 

	<kendo-grid-column class="text-left" title="Hacienda" width="100" [columnMenu]="false">
		<ng-template kendoGridCellTemplate let-dataItem>
			<h6>Cabezas: {{ getCabezasPorTropaEnCorral(dataItem)}}</h6>
			<ng-container>
				<span style="font-size: 0.9rem">{{getHaciendaPorTropaEnCorral(dataItem)}}</span>
			</ng-container>
		</ng-template> 
	</kendo-grid-column>

	<kendo-grid-column class="text-right" [filterable]="false" field="KgEstimado" title="Peso x Cab." width="90" [columnMenu]="false">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{ dataItem.KgEstimado | parseNumberKg }}</span>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column title="MS (Kg x Cabeza)" width="95" field="MetaDiaria" [columnMenu]="false">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{ dataItem.MetaDiaria | parseNumberKg : false : false }}</span>
		</ng-template>
	</kendo-grid-column>
</kendo-grid>
<my-message-dialog></my-message-dialog>


