import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { EditableFormList } from '../../common_controls/common-controls.interface';
import { IParentOpenInDialog, MyMessageDialogComponent } from '../../common_controls/message-dialog/my-message-dialog.component'; 
import { VentaTransporteComponent } from '../venta-transporte/venta-transporte.component';
import { VentaTransporte } from '../../../../../models/venta-transporte'; 
import { Venta } from '../../../../../models/Venta';

@Component({
  selector: 'kt-venta-transporte-list',
  templateUrl: './venta-transporte-list.component.html',
  styleUrls: ['./venta-transporte-list.component.scss']
})
export class VentaTransporteListComponent extends EditableFormList<VentaTransporte> implements OnInit, IParentOpenInDialog {

	@ViewChild(MyMessageDialogComponent, { static: true }) public MessageDialog: MyMessageDialogComponent;	 
	@Input() public tabindex: number = 0;

	private _venta: Venta;
	@Input()
	set venta(item: Venta) {
		this._venta = item;
		this.datos = (this._venta) ? this._venta.Transportes : [];
		this.buscando = false;
		this.cd.detectChanges();
	}
	get venta(): Venta { return this._venta; }

	constructor(cd: ChangeDetectorRef) { super(cd, undefined) }

	ngOnInit() { }

	getKeyModel(obj: VentaTransporte): any { return obj.TransporteID; }

	public removeHandler({ dataItem }) { this.confirmDelete(dataItem); }

	isNewModel: boolean = false;
	public showABM({ dataItem }) {
		this.isNewModel = dataItem == undefined;
		let title = ((this.isNewModel) ? "Agregar" : "Editar") + " Transporte"; 
		this.MessageDialog.showComponentDialog(title, VentaTransporteComponent, this, dataItem, { });
	}

	public saveResultDialogModel(model: VentaTransporte) {
		if (this.isNewModel) {
			this._venta.Transportes.push(model);
		}
		else {
			let item = this._venta.Transportes.find(x => x.TransporteID == model.TransporteID);
			if (item) { Object.assign(item, model); }
		}
		this.cd.detectChanges();
	}
}
