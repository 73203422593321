import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { DietaService } from '../../../../../services/dieta.service';
import { ClienteService } from '../../../../../services/cliente.service';
import { TropaService } from '../../../../../services/tropa.service'; 
import { MyColDropDownListComponent, MyColMultiselectCorralComponent } from '../../common_controls';
import { Guid } from 'guid-typescript';
import { CorralService } from '../../../../../services/corral.service';
import { CategoriaGanadoService } from '../../../../../services/categoria-ganado.service';

@Component({
	selector: 'kt-corral-filtro',
	templateUrl: './corral-filtro.component.html',
	styleUrls: ['./corral-filtro.component.scss'],
	providers: [
		DietaService,
		ClienteService,
		TropaService, 
		CorralService,
		CategoriaGanadoService
	]
})
export class CorralFiltroComponent implements OnInit {
	@Input() buscando: boolean = false;
	@Input() parent: any = undefined;
	@Input() tabindex: number = -1;

	@Input() ocultarDieta: boolean = false;
	@Input() ocultarPropietario: boolean = false;
	@Input() ocultarTropa: boolean = false;
	@Input() ocultarCorral: boolean = false;
	@Input() ocultarCategoria: boolean = true;

	@ViewChild('Dieta', { static: true }) Dieta: MyColDropDownListComponent;
	@ViewChild('Hotelero', { static: true }) Hotelero: MyColDropDownListComponent;
	@ViewChild('Tropa', { static: true }) Tropa: MyColDropDownListComponent; 
	@ViewChild('Corrales', { static: true }) Corrales: MyColMultiselectCorralComponent;
	@ViewChild('Categoria', { static: true }) Categoria: MyColDropDownListComponent;

	constructor(private dietaService: DietaService,
		private clienteService: ClienteService,
		private tropaService: TropaService,
		private corralService: CorralService,
		private categoriaGanadoService: CategoriaGanadoService,
		private cd: ChangeDetectorRef) { }

	ngOnInit() {
		this.Hotelero.initializeControl(this.clienteService, this.cd, 'Todos los Propietarios');
		this.Dieta.initializeControl(this.dietaService, this.cd, 'Todas las Dietas');
		this.Tropa.initializeControl(this.tropaService, this.cd, 'Todas las Tropas', Guid.createEmpty());
		this.Categoria.initializeControl(this.categoriaGanadoService, this.cd, 'Todas las Categorías');
		this.Corrales.initializeControlByCorral(this.corralService);
	}

	buscar(): void {
		if (this.parent && this.parent.buscar)
			this.parent.buscar();
	}
}
