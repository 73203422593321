<div class="col">
	<div class="form-group" [ngClass]="extraClass" [formGroup]="Form">
		<label *ngIf="labelVisible" class="pb-1">{{labelName}}</label>
		<div *ngIf="filterable">
			<kendo-dropdownlist formControlName="ItemRef"
								[defaultItem]="DefaultValue"
								tabindex="{{tabindex}}"
								[data]="Data"
								[valuePrimitive]="true"
								[textField]="'Value'"
								[valueField]="'Key'"
								class="w-100 control-sigef-height"
								(valueChange)="onValueChange($event)"
								#dropFilterInput
								[kendoDropDownFilter]="filterSettings"
								(filterChange)="filterText = $event">
				<ng-template kendoDropDownListNoDataTemplate>
					<div>
						<button *ngIf="agregarItemVisible() && filterText" [disabled]="agregandoItemAsync" class="add-btn" kendoButton (click)="addNewItem()">Agregar '{{filterText}}'</button>
					</div>
				</ng-template>
			</kendo-dropdownlist>
			<kt-inline-server-validation [ngClass]="{'d-none': !showValidation}" [input]="dropFilterInput" [formGroup]="Form" [field]="'ItemRef'"></kt-inline-server-validation>
		</div>
		<div *ngIf="!filterable">
			<kendo-dropdownlist formControlName="ItemRef"
								[defaultItem]="DefaultValue"
								tabindex="{{tabindex}}"
								[data]="Data"
								[valuePrimitive]="true"
								[textField]="'Value'"
								[valueField]="'Key'"
								class="w-100 control-sigef-height"
								(valueChange)="onValueChange($event)"
								#dropInput>
			</kendo-dropdownlist>
			<kt-inline-server-validation [ngClass]="{'d-none': !showValidation}" [input]="dropInput" [formGroup]="Form" [field]="'ItemRef'"></kt-inline-server-validation>
		</div>
	</div>
	<span *ngIf="hint" class="form-text text-muted">{{hint}}</span>
</div>
