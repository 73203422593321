import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DraggableCorral } from '../../../../../models/draggable-corral';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
	selector: 'my-group-corral',
	templateUrl: './my-group-corral.component.html',
	styleUrls: ['./my-group-corral.component.scss']
})
export class MyGroupCorralComponent implements OnInit {
	@Input() corrales: DraggableCorral[] = undefined;
	@Input() allowDelete: boolean = false;
	@Input() draggable: boolean = true;

	@Output() onDrop: EventEmitter<any> = new EventEmitter();
	@Output() onDelete: EventEmitter<any> = new EventEmitter(); 

	constructor() { }
	ngOnInit() { }

	drop(e, corral) { this.onDrop.emit(new EventDrop(e, corral)); }
	delete(e) { this.onDelete.emit(e); }
}

export class EventDrop {
	event: CdkDragDrop<string[]>
	corral: DraggableCorral

	constructor(e, c) { this.event = e; this.corral = c;}
}
