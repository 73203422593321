<kendo-popup [anchor]="input"
			 *ngIf="submitted && (formGroup.get(column.field).errors?.serverError || formGroup.get(column.field).errors)"
			 popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg">

	<span class="fas fa-exclamation-circle"></span>
	<span *ngIf="submitted && formGroup.get(column.field).errors?.serverError">&nbsp;{{ formGroup.get(column.field).errors?.serverError }}</span>
	<span *ngIf="submitted && formGroup.get(column.field).errors?.required">&nbsp;{{'CRUD.COMMON.REQUIRED_FIELD' | translate}}</span>
	<span *ngIf="submitted && formGroup.get(column.field).errors?.min">&nbsp;{{'AUTH.VALIDATION.MIN' | translate}} {{formGroup.get(column.field).errors?.min.min}}</span>
	<span *ngIf="submitted && formGroup.get(column.field).errors?.max">&nbsp;{{'AUTH.VALIDATION.MAX' | translate}} {{formGroup.get(column.field).errors?.max.max}}</span>
	<span *ngIf="submitted && formGroup.get(column.field).errors?.minlength">&nbsp;Caracteres mínimos: {{ formGroup.get(column.field).errors?.minlength.requiredLength }}</span>
	<span *ngIf="submitted && formGroup.get(column.field).errors?.validateNoSpaces">&nbsp;No puede contener espacios</span>
	<span *ngIf="submitted && formGroup.get(column.field).errors && formGroup.get(column.field).errors['Mask error']">&nbsp;{{'AUTH.VALIDATION.INVALID_MASK' | translate}}</span>
</kendo-popup>
