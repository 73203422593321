import { Pipe, PipeTransform } from '@angular/core'; 

@Pipe({
    name: 'parseCorralNumero'
})
export class ParseCorralNumeroPipe implements PipeTransform {

	transform(e: any): string {
		return e;
    }

}
