import { MovProductoSanitario } from "./mov-producto-sanitario";
import { Guid } from "guid-typescript";
import { DTOInterface } from "../interfaces/dto.interface";
import { PlanSanitario } from "./plan-sanitario";

 
export class AplicacionSanitaria implements DTOInterface {
	Grupo: Guid;
	Observaciones: string;
	Aplicaciones: MovProductoSanitario[] = [];
	Planes: PlanSanitario[] = [];

	constructor() {
		this.clear();
	}

	clear(): void {
		this.Grupo = Guid.create();
		this.Observaciones = '';
		this.Aplicaciones = [];
		this.Planes = [];
	}
}
