<div>
	<div class="row-sigef" [ngClass]="{'d-none': mostrarPeso }">
		<my-col-input [tabindex]="8999" #CantidadVivo [labelName]="CantidadTropaLabel" [decimals]="0" [required]="false" [allowCero]="false"></my-col-input>

		<div *ngIf="!cantidadVivo" class="alert alert-warning mr-4 p-2" style="margin: 0; margin-left: 1.2rem;" role="alert">
			<div class="alert-text">
				Cuando selecciona <strong>Peso Total</strong> de la tropa, es necesario ingresar la cantidad total de animales de la tropa.
			</div>
		</div>
	</div>

	<div [ngClass]="{'d-none': !modelIsNew }" class="row-sigef">
		<my-col-dropdownlist [tabindex]="9000" [parent]="componente" [labelName]="'Categoría de Hacienda'" [required]="true"></my-col-dropdownlist>
	</div>

	<div class="row-sigef">
		<div [ngClass]="{'d-none': !modelIsNew }">
			<my-col-input-search [tabindex]="9001" [soloConCabezas]="false" [ngClass]="{'d-none': !modelIsNew }" [labelName]="'Número de Corral'" [required]="true" [parent]="component"></my-col-input-search>
		</div>
		<div [ngClass]="{'d-none': !modelIsNew }">
			<my-col-input [tabindex]="9002" #Cantidad [labelName]="CantidadCategoriaLabel" [decimals]="0" [required]="true" [allowCero]="false"></my-col-input>
		</div>
	</div>

	<div class="row-sigef" [ngClass]="{'d-none': !mostrarPeso || !modelIsNew }">
		<my-row-expand [tabindex]="9002" [disabled]="true" [expanded]="true" [title]="'Peso'" [hint]="''" [labelName]="'Peso'" (onExpand)="mostrarPeso=$event"></my-row-expand>

		<kt-peso-hacienda [tabindex]="9003" [required]="false" [pesar]="mostrarPeso" [cantidad]="cantidad" class="w-100"></kt-peso-hacienda>
	</div>

	<div class="row-sigef" [ngClass]="{'d-none': !mostrarPrecio || !modelIsNew }">
		<my-row-expand [tabindex]="9010" [disabled]="true" [expanded]="true" [title]="'Precio'" [hint]="''" [labelName]="'Precio'" (onExpand)="mostrarPrecio=$event"></my-row-expand>

		<kt-precio-compra-venta [tabindex]="9011" [required]="false" [title]="'Precio'" [cantidad]="cantidad" class="w-100"></kt-precio-compra-venta>
	</div>

	<div class="row-sigef">
		<my-col-input [tabindex]="9020" [mask]="'0.00 Kg'" #AumentoDiario [labelName]="'Aumento Diario Estimado'" [required]="false"></my-col-input>

		<div *ngIf="categoriaRepetida" class="alert alert-warning mr-4 p-2" style="margin-left: 1.2rem;" role="alert">
			<div class="alert-text">
				El aumento diario estimado es por categoría de hacienda, con lo cual lo que se ingrese se actualizará en las categorías de la tropa que coincidan con esta
			</div>
		</div>
	</div>

	<div class="pt-4" [ngClass]="{'d-none': !modelIsNew }">
		<my-row-expand [tabindex]="9021" [title]="Observaciones" [hint]="'Seleccione esta opción para incluir una aclaración adicional'" [labelName]="'Observaciones'" [expanded]="adicionalesVisible" (onExpand)="adicionalesVisible=$event"></my-row-expand>
		<div class="row-sigef" [ngClass]="{'d-none': !adicionalesVisible}">
			<my-col-textarea [tabindex]="9022" [labelName]="'Observaciones'" [rows]="2" [labelVisible]="false" class="w-100"></my-col-textarea>
		</div>
	</div>

	<div class="ml-3 mr-3 pl-3 mt-3 row" [ngClass]="{'d-none': openedInDialog}">
		<my-group-save-buttons [tabindex]="9023" class="w-100" [saving]="saving" [targetName]="'Ganado'"
							   (onSave)="onGanadoSave($event)" (onCancel)="onGanadoCancel($event)"></my-group-save-buttons>
	</div>
</div>
<my-message-dialog></my-message-dialog>
