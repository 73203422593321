import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'my-col-labelinfo',
    templateUrl: './my-col-labelinfo.component.html',
    styleUrls: ['./my-col-labelinfo.component.scss']
})
export class MyColLabelInfoComponent implements OnInit {
	@Input() tooltipMessage: string = '';
	@Input() tooltipTitle: string = '';
    @Input() labelName: string = '';
	@Input() infoText: string = '';
	@Input() visible: boolean = true;
	@Input() extraClass: string = '';

    constructor() { }
    ngOnInit() { }
}
