// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, Login } from '../../../../core/auth';
import { AutenticacionService } from '../../../../../services/autenticacion.service';
import { ApiResponse } from '../../../../../interfaces/api-response.interface';
import Utils from '../../../../../utils';
import { MyColInputComponent } from '../../../partials/common_controls';
import { debug } from 'console';
import { LoginADA } from '../../../../../models/login-ada';
import { Usuario } from '../../../../../models/usuario';

@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],

	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
	// Public params
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];

	@ViewChild('Usuario', { static: true }) UsuarioInput: MyColInputComponent;
	@ViewChild('Password', { static: true }) PasswordInput: MyColInputComponent;

	private unsubscribe: Subject<any>;
	private returnUrl: any;

	public recoverAvailable: boolean = true;
	public oauthAvailable: boolean = false;
	public labelName: string = '<span>Contraseña</span><span class="kt-link kt-login__link-forgot float-right font-weight-light kt-forgot-link"><a href="/auth/forgot-password">Olvidé mi contraseña</a>';

	constructor(
		private router: Router,
		private auth: AutenticacionService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute
	) {
		this.unsubscribe = new Subject();
	}

	ngAfterViewInit(): void { }

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params['returnUrl'] || '/';
			let cuenta = params['cuenta'];
			let provider = params['provider'];
			let token = params['token'];
			let tokenResult = params['tokenResult'];
			if (tokenResult)
				this.authNoticeService.setNotice(tokenResult, 'danger');
			else if (cuenta) {
				if (token == undefined)
					this.authNoticeService.setNotice("Token inexistente", 'danger');
				else if (provider == undefined)
					this.authNoticeService.setNotice("Provider inexistente", 'danger');
				else
					this.loginProvider(cuenta, token, provider);
			}
		});

		this.UsuarioInput.focus();
	}

	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	login() {
		this.authNoticeService.setNotice('', 'info');
		let valid: boolean = true;

		const authData = {
			Cuenta: this.UsuarioInput.getValue(),
			Password: this.PasswordInput.getValue(),
		};

		if (!this.UsuarioInput.isValid()) {
			this.UsuarioInput.showError(true);
			valid = false;
		}

		if (!this.PasswordInput.isValid()) {
			this.PasswordInput.showError(true);
			valid = false;
		}

		if (!valid) {
			return;
		}

		this.loading = true;
		this.auth
			.loginADA(authData.Cuenta, authData.Password)
			.pipe(
				tap(data => {
					this.processLoginData(data);
				}),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.cdr.markForCheck();
				})
			)
			.subscribe();
	}

	loginProvider(cuenta: string, token: string, provider: string) {
		this.loading = true;
		this.auth
			.loginProvider(cuenta, token, provider)
			.pipe(
				tap(data => {
					this.processLoginData(data);
				}),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.cdr.markForCheck();
				})
			)
			.subscribe();
	}

	processLoginData(data: Usuario) {
		if (Utils.checkAPIResponse(data, undefined, '')) {
			if (data) {
				if (data.LoginToken) {
					let loginADA = new LoginADA(data.LoginToken, data.Empresas);
					this.router.navigate(['/auth/select-empresa'], { queryParams: { loginADA: JSON.stringify(loginADA), returnUrl: this.returnUrl }, skipLocationChange: true });
				}
				else {
					localStorage.setItem('currentUser', JSON.stringify(data));
					localStorage.setItem('empresa', data.Empresa);
					this.store.dispatch(new Login({ authToken: data.AccessToken }));
					this.router.navigateByUrl(this.returnUrl == '/' ? '/auth/redirect' : this.returnUrl).then((r) => this.loading = false);
				}
				this.loading = false;
			}
		}
		else {
			let apiResponse = (data as unknown as ApiResponse);
			this.authNoticeService.setNotice(apiResponse.StatusDescription, 'danger');
			this.loading = false;
		}
	}
}
