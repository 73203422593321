import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';
import { MixerCarga } from '../models/mixer-carga';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { Guid } from 'guid-typescript';
import { MixerCargaFechaMixero } from '../models/mixer-carga-fecha-mixero';

@Injectable()
export class MixerCargaService extends GenericoService<MixerCarga> {
    constructor(private _httpClient: HttpClient) {
        super(_httpClient, 'mixercarga');
    }

	saveNewCarga(_obj: MixerCarga): Observable<any> {
		return this._httpClient.put<any>(environment.apiUrl + `/${this._endpoint}/saveNew`, _obj, { headers: this._postHeaders });
	}
	getFechaMixeroCargas(mixeroID: number, fechaCarga: Date): Observable<MixerCargaFechaMixero[]> {
        return this._httpClient.post<any>(environment.apiUrl + `/${this._endpoint}/fechamixero`, { UsuarioID: mixeroID, FechaCarga: fechaCarga }, { headers: this._postHeaders });
    }
	getInfo(mixerCargaID: Guid): Observable<MixerCarga> {
        return this._httpClient.post<MixerCarga>(environment.apiUrl + `/${this._endpoint}/info`, { MixerCargaID: mixerCargaID }, { headers: this._postHeaders });
    }
	getReportCarga(fechaDesde: Date, fechaHasta: Date, clienteRef: number, usuarioRef: number, dietaRef: number, corralRef: number, tropaRef: Guid, tipoReporte: number): Observable<MixerCarga[]> {
        return this._httpClient.post<any>(environment.apiUrl + `/${this._endpoint}/cargaReport`, {
            FechaDesde: fechaDesde, FechaHasta: fechaHasta, ClienteRef: clienteRef, UsuarioRef: usuarioRef, DietaRef: dietaRef,
            CorralRef: corralRef, tropaRef: tropaRef, tipoReporte: tipoReporte
        }, { headers: this._postHeaders });
    }
}
