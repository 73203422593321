<kt-portlet>
	<div class="kt-portlet__head">
		<div class="kt-portlet__head-label">
			<h3 *ngIf="modo === 'encierre'" class="kt-portlet__head-title">
				Operación Interna <small>Filtre los corrales y genere una operación formando una tropa nueva</small>
			</h3>
			<h3 *ngIf="modo === 'venta'" class="kt-portlet__head-title">
				Venta <small>Filtre los corrales y genere una venta de hacienda</small>
			</h3>
		</div>
	</div>
	<kt-portlet-body>
		<div cdkDropListGroup>
			<div class="row-sigef drop-zone pb-3">
				<div class="encierre-corral w-100 mb-2" style="">
					<my-group-corral-tropa [corral]="operacionInterna" (onDrop)="drop($event, true)"
										   [allowDelete]="true" (onDelete)="quitar($event)"></my-group-corral-tropa>
				</div>

				<div class="row-sigef" style="padding-top: 3px;">
					<button class="btn btn-sigef" (click)="generar()" [disabled]="operacionInterna.items.length == 0 || buscando" kendoButton>Guardar {{ modo === 'encierre' ? 'Operación Interna' : 'Venta' }}</button>
				</div>
			</div>

			<div class="kt-divider kt-divider-sigef"><span></span></div>

			<kt-corral-filtro [parent]="component" [buscando]="buscando"></kt-corral-filtro>

			<div class="mt-3" *ngIf="datos && datos.length > 0">
				<my-group-corral [corrales]="datos" (onDrop)="notDrop($event)"></my-group-corral>
			</div>

		</div>
	</kt-portlet-body>
</kt-portlet>
<my-message-dialog></my-message-dialog>
