import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MyColDateTimePickerComponent, MyColTimePickerComponent, MyColInputComponent, MyMessageDialogComponent } from '../../common_controls';
import { MixerCargaAlimento } from '../../../../../models/mixer-carga-alimento';
import { AlimentacionCargaComponent } from '../../../pages/alimentacion/alimentacion-carga/alimentacion-carga.component';
import Utils from '../../../../../utils';
import { EditableValidationForm } from '../../common_controls/common-controls.interface';
import { IParentConfirmResultMessage } from '../../common_controls/message-dialog/my-message-dialog.component';
import { ParseNumberKgPipe } from '../../../../../pipes/parse-number-kg.pipe';

@Component({
	selector: 'kt-carga-dieta-alimento',
	templateUrl: './carga-dieta-alimento.component.html',
	styleUrls: ['./carga-dieta-alimento.component.scss'],
	providers: []
})
export class CargaDietaAlimentoComponent extends EditableValidationForm<MixerCargaAlimento> implements OnInit, IParentConfirmResultMessage {
	parent: AlimentacionCargaComponent = undefined;
	saving: boolean = false;

	@ViewChild(MyColTimePickerComponent, { static: true }) Hora: MyColTimePickerComponent;
	@ViewChild('KgCargado', { static: true }) KgCargado: MyColInputComponent;
	Mascara: string = "#,# Kg";
	UnidadMedida: string = "Kg";
	KgPropuesto: number = 0;
	Exactitud: number = 0;
	AlimentoDescripcion: string = "";
	AlertaCapacidad: boolean = false;

	constructor(cd: ChangeDetectorRef) { super(cd); }

    onConfirmResultMessageDialog(id: string, ok: boolean): void {
		if (id === 'Hora') {
			setTimeout(() => {
				this.Hora.focus();
			}, 0);
		}

		if (id === 'KgCargado') {
			setTimeout(() => {
				this.KgCargado.focus();
			}, 0);
		}
    }

	ngOnInit() {
		this.lstControls = [this.Hora, this.KgCargado];
	}

	public setModel(alimento: MixerCargaAlimento, parentCarga: AlimentacionCargaComponent) {
		this.parent = parentCarga;
		this.loadModelAndSetProperties(alimento, (!alimento || alimento.MovAlimentoID.isEmpty()));
		this.Hora.focus();
	}

	onInputValueChange(newValue: any, labelName: string) {
		this.setExactitud(this.KgPropuesto, newValue);
		this.cd.detectChanges();
	}

	aperturaEdit(): boolean { return !this.parent || this.parent.aperturaEdit(); }

	nextStep(proximo: boolean) {
		this.saving = true;
		if (proximo && !this.runValidaciones()) {
			this.saving = false;
			return;
		}
		if (!this.aperturaEdit() || proximo) {
			this.model.FechaMovimiento = Utils.getDateWithTime(this.model.FechaMovimiento, this.Hora.getHora());
			this.model.CantidadRealKg = this.KgCargado.getValue();
		}
		this.parent.procesarAlimento(proximo);
		this.saving = false;
	}

	//Helpers UI
	nextButtonText() {
		if (this.aperturaEdit())
			return "Guardar";
		else if (this.parent.procesandoUltimoAlimento())
			return "Finalizar carga";
		else
			return "Prox. Producto >>>"
	}

	backButtonText() { return (this.aperturaEdit()) ? "Cancelar" : "Atrás"; }

	getExactitudColor() { return Utils.getCargaExactitudClassColor(this.Exactitud); }

	//Overrides
	setModelControlValues() {
		this.AlimentoDescripcion = this.model.AlimentoNombre;
		if (!this.parent.aperturaEdit() && this.parent.actualAlimentoIndex !== 0)
			this.Hora.setHora(this.parent.model.Alimentos[this.parent.actualAlimentoIndex - 1].FechaMovimiento);
		else
			this.Hora.setHora(this.model.FechaMovimiento);
		this.KgCargado.setValue(this.model.CantidadRealKg);
		this.KgPropuesto = this.model.CantidadPropuestaKg;
		this.setExactitud(this.KgPropuesto, this.KgCargado.getValue());

		if (this.model.Agua == false) {
			this.Mascara = "#,# Kg";
			this.UnidadMedida = "Kg";
		} else {
			this.Mascara = "#,# Lts";
			this.UnidadMedida = "Lts";
		}
	}

	setExactitud(kgPropuesto: number, kgCargado: number) {
		this.Exactitud = (kgPropuesto === 0 || !kgCargado) ? 0 : kgCargado / kgPropuesto;
	}

	runCustomValidaciones() {
		let carga = this.parent.model;
		let fInicial = carga.FechaCarga;
		let fAlimento = Utils.getDateWithTime(this.model.FechaMovimiento, this.Hora.getHora());
		if (Utils.dateIsLessThanDate(fAlimento, fInicial)) {
			this.MessageDialog.showAtencionDialog(`La hora de carga del alimento (${Utils.getStringHour(fAlimento)}) no puede ser menor a la hora inicial de carga (${Utils.getStringHour(fInicial)})`, 'Hora', this);
			return false;
		}

		let sum = 0
		for (const ali of carga.Alimentos) {
			if (ali.MovAlimentoID === this.model.MovAlimentoID)
				sum += this.KgCargado.getValue();
			else
				sum += +(ali.CantidadRealKg);
		}
		if (sum > carga.CapacidadKg && !this.AlertaCapacidad) {
			this.MessageDialog.showAtencionDialog(`La cantidad total cargada (${new ParseNumberKgPipe().transform(sum)}) supera la capacidad máxima del mixer (${new ParseNumberKgPipe().transform(carga.CapacidadKg)}).`, 'KgCargado', this);
			this.AlertaCapacidad = true;
		}
		return true;
	}
}
