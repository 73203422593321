import { DTOInterface } from "../interfaces/dto.interface";
import { Guid } from "guid-typescript";

export class InfoDiaria implements DTOInterface { 
	InfoDiariaID: Guid;
	FechaInfoDiaria: Date;
	ObservacionesAlimentacion: string;
	ObservacionesSanidad: string;
	Precipitaciones: number;
	FechaCreacion: Date

	constructor() {
		this.clear();
	}

	clear(): void {
		this.InfoDiariaID = Guid.createEmpty();
		this.FechaInfoDiaria = new Date();
		this.ObservacionesAlimentacion ='';
		this.ObservacionesSanidad = '' ;
		this.Precipitaciones = 0;
		this.FechaCreacion = new Date();
	}
}
