import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http'; 
import { AplicacionSanitaria } from '../models/aplicacion-sanitaria';

@Injectable()
export class AplicacionSanitariaService extends GenericoService<AplicacionSanitaria> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'aplicacionsanitaria');
	} 
}
