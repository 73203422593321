<div [title]="blockedMessage" class="input-group k-grid-ignore-click mx-auto" [ngStyle]="{'width': width, 'max-width': maxWidth, 'min-width': minWidth}">
	<div class="input-group-prepend">
		<button [disabled]="blocked" (click)="clickDown()" style="min-width: 2.5rem" class="btn btn-decrement btn-sigef" type="button">
			<strong>-</strong>
		</button>
	</div>

	<div [ngClass]="{'kt-spinner kt-spinner--sm kt-spinner--success': blocked}" style="z-index: 1000; left: 20%;"></div>
	<input [disabled]="blocked" ktSelectOnClick #input type="text" (keydown)="onEnter($event)" (keyup)="clickChange($event)" (focusout)="onEnter($event)" style="text-align: center;" class="form-control" placeholder="">

	<div class="input-group-append">
		<button [disabled]="blocked" (click)="clickUp()" style="min-width: 2.5rem" class="btn btn-increment btn-sigef" type="button">
			<strong>+</strong>
		</button>
	</div>
</div>
