import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MyColDropDownListComponent } from '../../common_controls';
import { DietaService } from '../../../../../services/dieta.service'; 
import { NameValueList } from '../../../../../interfaces/nvl.interface';
import { TropaSeguimientoCorralListComponent } from '../../tropa/tropa-seguimiento-corral-list/tropa-seguimiento-corral-list.component'; 
import { OffCanvasInterface } from '../../../../../interfaces/off-canvas.interface';
import { MessengerService } from '../../../../../services/messenger.service';
import { QuickPanelService } from '../../../../../services/quick-panel.service';

@Component({
  selector: 'kt-cambiar-dieta',
  templateUrl: './cambiar-dieta.component.html',
	styleUrls: ['./cambiar-dieta.component.scss'],
	providers: [DietaService]
})
export class CambiarDietaComponent implements OnInit, AfterViewInit, OffCanvasInterface {
	private dietaSeleccionada: NameValueList = undefined;
	private parent: TropaSeguimientoCorralListComponent = undefined;
	public component: CambiarDietaComponent = this;
	public saving: boolean = false;

	@ViewChild('Dieta', { static: true }) Dieta: MyColDropDownListComponent;

	onDietaSave(e: any): boolean {
		if (!this.dietaSeleccionada || this.dietaSeleccionada.Key === 0) {
			return false;
		} 

		this.saving = true;
		this.parent.saveResultDialogModel(this.dietaSeleccionada);
		this.saving = false;

		return true;
	}

	onDietaCancel(e: any): void {
		this.quickPanelService.hideQuickPanel();
	}

	constructor(private dietaService: DietaService, private messengerService: MessengerService, private cd: ChangeDetectorRef, private quickPanelService: QuickPanelService) { }

    setOffCanvasData(data: any, extras: any): void {
		setTimeout(() => {
			this.parent = extras.Parent;
			this.dietaSeleccionada = { Key: extras.DietaRef, Value: '' };
			this.Dieta.setValue(this.dietaSeleccionada.Key);
			this.Dieta.focus();
		}, 0);
	}

	ngAfterViewInit(): void {
		this.Dieta.focus();
    }

	ngOnInit() { 
		this.Dieta.initializeControl(this.dietaService, this.cd, "Seleccione una dieta");
	}

	onDropDownValueChanged(item, key) {
		this.dietaSeleccionada = item;
	}
}
