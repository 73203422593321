import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BroadcastMessage } from '../../../../../interfaces/message.interface';
import { ParseNumberKgPipe } from '../../../../../pipes/parse-number-kg.pipe';
import { ParseNumberPorcPipe } from '../../../../../pipes/parse-number-porc.pipe';
import { MessengerService } from '../../../../../services/messenger.service';
import Utils from '../../../../../utils';

@Component({
	selector: 'my-group-up-down-value',
	templateUrl: './my-group-up-down-value.component.html',
	styleUrls: ['./my-group-up-down-value.component.scss']
})
export class MyGroupUpDownValueComponent implements OnInit {
	@Input() dataItem: any = undefined;
	@Input() value: number = 0;
	@Input() changeStep: number = 0.25;
	@Input() minValue: number = 0;
	@Input() maxValue: number;
	@Input() parseType: string = '';
	@Input() storageName: string = '';
	@Input() precision: number = 2;

	@Input() width: string = '80%';
	@Input() maxWidth: string = '175px';
	@Input() minWidth: string = '150px';

	@Input() blockInputOnChange: boolean = false;

	@Output() onChange: EventEmitter<any> = new EventEmitter();

	destroy$: Subject<void> = new Subject();

	@ViewChild('input', { static: true }) input: ElementRef;

	private valor: number;

	id: string;
	blocked: boolean = false;
	blockedMessage: string = '';

	constructor(private messengerService: MessengerService
	) { }
	ngOnInit() {
		this.parseValue();
		this.id = "#" + Math.random() * 111;
		this.messengerService.messageBroadcasted$.pipe(takeUntil(this.destroy$)).subscribe((data: BroadcastMessage) => {
			if (data.Sender === 'TropaSeguimientoCorralListComponent' && data.Target == 'MyGroupUpDownValueComponent' && data.Reason === 'UpdateValue') {
				if (data.Payload.CorralRef == this.dataItem.CorralID) {
					this.value = data.Payload.Value;
					this.checkMin();
					this.checkMax();
					this.parseValue();
				}
			}
		});
	}

	ngOnDestroy() {
		this.destroy$.next();
	}

	clickDown(): void {
		this.value = Number((this.value - this.changeStep).toPrecision(this.precision));
		this.checkMin();
		this.changeValue();
	}

	clickUp(): void {
		this.value = Number((this.value + this.changeStep).toPrecision(this.precision));
		this.checkMax();
		this.changeValue();
	}

	setBlockedStatus(isBlocked: boolean): void {
		if (this.blockInputOnChange) {
			this.blocked = isBlocked;
		}

		if (this.blocked) {
			this.blockedMessage = 'Existe una solicitud en progreso, por favor espere...';
		} else {
			this.blockedMessage = '';
		}
	}

	onEnter(event: any): boolean {
		if (this.valor) {
			if (event.key === 'Enter' || event.type == "focusout") {
				this.value = this.parseType === "Porc" ? this.valor/100 : this.valor;
				this.checkMin();
				this.checkMax();
				this.changeValue();
			}
		}
		return true;
	}

	clickChange(event: any): boolean {
		this.valor = (+event.target.value.replace(/,/g, '.'));
		return true;
	}

	changeValue(emitEvent: boolean = true) {
		if (emitEvent)
			this.onChange.emit({ Item: this.dataItem, Valor: this.value });

		if (this.storageName !== '')
			localStorage.setItem(this.storageName, this.value.toString());

		this.parseValue();
	}

	public parseValue(): string {
		let parsedValue;

		if (this.parseType === "Porc")
			parsedValue = new ParseNumberPorcPipe().transform(this.value, true);
		else if (this.parseType === "Kg")
			parsedValue = new ParseNumberKgPipe().transform(this.value, false, false);
		else
			parsedValue = this.value;

		this.input.nativeElement.value = parsedValue;
		return parsedValue;
	}

	private checkMin() {
		if (Utils.checkNaNGetZero(this.value) <= this.minValue)
			this.value = this.minValue;
	}

	private checkMax() {
		if (this.maxValue && Utils.checkNaNGetZero(this.value) >= this.maxValue)
			this.value = this.maxValue;
	}

}
