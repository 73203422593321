import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';
import { Usuario } from '../models/usuario'; 
import { NameValueList } from '../interfaces/nvl.interface';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class UsuarioService extends GenericoService<Usuario> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'usuario');
    }

    getLookupByRole(role: number): Observable<NameValueList[]> {
        return this._httpClient.get<NameValueList[]>(environment.apiUrl + `/${this._endpoint}/lookupByRole/${role}`, { headers: this._getHeaders });
	}

	getListByRole(role: number): Observable<Usuario[]> {
		return this._httpClient.get<Usuario[]>(environment.apiUrl + `/${this._endpoint}/listByRole/${role}`, { headers: this._getHeaders });
	}
}
