import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'; 
import { BroadcastMessage } from '../interfaces/message.interface';
import { OffCanvasInterface } from '../interfaces/off-canvas.interface';

@Injectable()
export class MessengerService { 
	constructor() { } 

	// Observable string sources
	private subject = new Subject<any>();
	
	// Observable string streams
	messageBroadcasted$ = this.subject.asObservable(); 

	// Broadcasst a single payload
	broadcastPayload(reason: any, payload: any) {
		this.broadcast("", "", reason, payload);
	}

	// Broadcast a single subject 
	broadcastMessage(subject: any) { 
        this.subject.next(subject);
	}

	// Broadcast a Message Obj
	broadcastObject(object: BroadcastMessage) {
		this.subject.next(object);
	}

	// Broadcast a Message Obj from parameters
	broadcast(sender: any, target: any, reason: any, payload: any) {
		let mw: BroadcastMessage = new BroadcastMessage();

		mw.Payload = payload;
		mw.Reason = reason;
		mw.Sender = sender;
		mw.Target = target;

		this.broadcastObject(mw);
	}
}
