<div [ngClass]="containerClass">
	<div class="col w-100">
		<div class="row">
			<div>
				<div class="form-group">
					<button style="margin-top: 27px !important" [disabled]="!mostrarAgregar || !valido" class="btn btn-success" (click)="emitirAgregar()">
						<i title="Agregar Nueva Aplicación" class="{{'Agregar' | parseIconAccion }} pr-1" style="color: white !important;"></i>
					</button>
				</div>
			</div>

			<my-col-datetimepicker selectFirstInput #Fecha [parent]="component" [labelName]="'Fecha'"></my-col-datetimepicker>
			<my-col-dropdownlist selectFirstInput #Producto [required]="true" [key]="'Producto'" [labelName]="'Producto'" [parent]="component"></my-col-dropdownlist>
			<my-col-input #Cantidad class="um-input" [hint]="hint" [mask]="'#'" [decimals]="0" [labelName]="cantidadLabel" [allowCero]="false" [required]="true"></my-col-input>

			<div>
				<div class="form-group">
					<button style="margin-top: 27px !important; height: 31px" [disabled]="!mostrarBorrar" class="btn" (click)="emitirBorrar()">
						<i title="Eliminar Aplicación" class="{{'Eliminar' | parseIconAccion }} pr-1"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
