import { DTOInterface } from "../interfaces/dto.interface";

export class UsuarioRol implements DTOInterface { 
    RolRef: number;
	RolNombre: string;

    constructor(rolRef: number, rolNombre: string) {
        this.clear();
        this.RolRef = rolRef;
        this.RolNombre = rolNombre;
	}

	clear(): void {
		this.RolRef = -1;
		this.RolNombre = '';
	}
}
