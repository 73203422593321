// Angular
import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
// RxJS
import { Observable, Subject } from 'rxjs';
// NGRX
import { Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { Logout, AuthNoticeService } from '../../../../../core/auth';
import { Usuario } from '../../../../../../models/usuario';
import { AutenticacionService } from '../../../../../../services/autenticacion.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import Utils, { AuthenticationProvider, Roles } from '../../../../../../utils';
import { MessengerService } from '../../../../../../services/messenger.service';
import { takeUntil } from 'rxjs/operators';
import { BroadcastMessage } from '../../../../../../interfaces/message.interface';
import { MenuAsideService, MenuConfigService } from '../../../../../core/_base/layout';
import { MenuConfig } from '../../../../../core/_config/sigef/menu.config';
import { QuickPanelService } from '../../../../../../services/quick-panel.service';
import { UsuarioComponent } from '../../../usuario/usuario/usuario.component';

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
	providers: [AutenticacionService]
})
export class UserProfileComponent implements OnInit, OnDestroy {
	destroy$: Subject<void> = new Subject();

	// Public properties
	user: Usuario;

	@Input() showAvatar: boolean = true;
	@Input() showHi: boolean = false;
	@Input() showBadge: boolean = false;

	constructor(private store: Store<AppState>,
		private _router: Router,
		private auth: AutenticacionService,
		private cd: ChangeDetectorRef,
		private authNoticeService: AuthNoticeService,
		private messengerService: MessengerService,
		private translate: TranslateService,
		private menuAside: MenuAsideService,
		private quickPanelService: QuickPanelService ) {
	}

	ngOnInit(): void {
		this.loadUserData();
		this.loadUserMenu();

		this.messengerService.messageBroadcasted$.pipe(takeUntil(this.destroy$)).subscribe((data: BroadcastMessage) => {
			if (data.Sender === 'UsuarioComponent' && this.user) {
				if (data.Reason === 'UsuarioImagenPerfil') {
					if (data.Payload.UsuarioID == this.user.UsuarioID) {
						//this.user.ImagenPerfil = data.Payload.Base64;
						this.loadUserData();
						//this.cd.detectChanges();
					}
				}
			}
		});
	}

	ngOnDestroy() {
		this.destroy$.next();
	}

	loadUserMenu(): void {
		this.menuAside.loadMenu();
	}

	loadUserData(): void {
		this.auth.getUserByToken().subscribe(data => {
			if (data.UsuarioID === 0 || !localStorage.getItem("empresa")) {
				this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.EXPIRED'), 'info');
				this.logout();
			} else {
				this.user = data;
				this.loadUserMenu();
				this.cd.detectChanges();
			}
		}, error => {
			this.user = undefined;
			this.authNoticeService.setNotice('La sesión no es válida.', 'info');
			this.logout();
		});
	}

	logout() {
	    this.store.dispatch(new Logout());
		this.auth.logout();
		this.authNoticeService.setNotice('Has cerrado la sesión', 'info');
		this._router.navigate(['/auth/login']);
	}

	editarPerfil() {		
		this.quickPanelService.createOffCanvasComponent('Editar Usuario', UsuarioComponent, this.user, { PuedeEditar: this.auth.tieneRole(Roles[Roles.Administrador])});
	}

}
