import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { CommonControl } from '../common-controls.interface';

@Component({
	selector: 'my-col-textarea',
	templateUrl: './my-col-textarea.component.html',
	styleUrls: ['./my-col-textarea.component.scss']
})
export class MyColTextAreaComponent extends CommonControl implements OnInit {
	@Input() rows: number = 3;
	@Input() cols: number = 30;

	@ViewChild('controlInput', { static: true }) MyControl: ElementRef;

	constructor() {
		super();
		this.Form = new UntypedFormGroup({
			'Text': new UntypedFormControl('')
		});
	}

	ngOnInit() {
		this.Form.reset();
		if (this.required) {
			this.Form.controls['Text'].setValidators([Validators.required]);
		}
	}

	focus() {
		this.MyControl.nativeElement.focus();
	}

	getText(): string {
		return this.Form.value['Text'];
	}

	setText(text: string): void {
		this.Form.patchValue({ Text: text });
	}

	getPrincipalControl(): AbstractControl {
		return this.Form.controls['Text'];
	}
}
