import { UsuarioRol } from "./usuario-rol";
import { UsuarioZona } from "./usuario-zona";
import { DTOInterface } from "../interfaces/dto.interface";
import { UsuarioEmpresa } from "./usuario-empresa";

export class Usuario implements DTOInterface {
	UsuarioID: number;
	UsuarioNombre: string;
	UsuarioApellido: string;
	Roles: UsuarioRol[];
	Zonas: UsuarioZona[];
	Empresas: UsuarioEmpresa[];
	Cuenta: string;
	Email: string;
	AccessToken: string;
	RefreshToken: string;
	LoginToken: string
	VencimientoAccessToken: Date;
	ImagenPerfil: string | ArrayBuffer;
	ClienteRef: number;
	ClienteNombre: string;
	UsuarioRoles: string;
	UsuarioZonas: string;
	Activo: boolean;
	TodasLasZonas: boolean;
	AuthenticationProviderRef: number;

	Password: string; // solo para el dto (en texto para crear o resetear el pass)
	Empresa: string;

	constructor() {
		this.clear();
	}

	clear(): void {
		this.UsuarioID = -1;
		this.UsuarioNombre = undefined;
		this.UsuarioApellido = undefined;
		this.Roles = [];
		this.Zonas = [];
		this.Cuenta = undefined;
		this.AccessToken = undefined;
		this.RefreshToken = undefined;
		this.VencimientoAccessToken = null;
		this.Activo = true;
		this.Email = undefined;
		this.ImagenPerfil = undefined;
		this.ClienteRef = -1;
		this.ClienteNombre = undefined;
		this.UsuarioRoles = undefined;
		this.UsuarioZonas = undefined;
		this.TodasLasZonas = false;
		this.AuthenticationProviderRef = null;

		this.Password = '';
	}
}
