import { Component, OnInit, Input, ChangeDetectorRef, ViewRef, ViewChild, AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { NameValueList, NVLService } from '../../../../../interfaces/nvl.interface';
import { CommonControl } from '../common-controls.interface';
import { DropDownFilterSettings, DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Component({
	selector: 'my-col-dropdownlist',
	templateUrl: './my-col-dropdownlist.component.html',
	styleUrls: ['./my-col-dropdownlist.component.scss']
})
export class MyColDropDownListComponent extends CommonControl implements OnInit, AfterViewInit {
	@Input() keyType: number = 1 //1 = Number; 2 = Guid
	@Input() extraClass: string = 'col-sigef-width';
	@Input() hint: string = '';

	@ViewChildren('dropFilterInput') MyDropFilterInputs: QueryList<DropDownListComponent>;
	@ViewChildren('dropInput') MyDropInputs: QueryList<DropDownListComponent>;

	Data: NameValueList[] = [];
	DefaultValue: NameValueList;
	defaultSet: boolean = false;

	@Input() filterable: boolean = false;
	filterSettings: DropDownFilterSettings = {
		caseSensitive: false,
		operator: 'contains',

	};
	filterText: string = "";
	agregandoItemAsync: boolean;

	constructor() {
		super();
		this.Form = new UntypedFormGroup({
			'ItemRef': new UntypedFormControl('')
		});
	}

    ngAfterViewInit(): void {
		if (this.focusOnInit) {
			this.focus();
		}
    }

	focus() {
		if (this.MyDropFilterInputs && this.MyDropFilterInputs.length === 1) this.MyDropFilterInputs.first.focus();
		if (this.MyDropInputs && this.MyDropInputs.length === 1) this.MyDropInputs.first.focus();
	}

	ngOnInit() {
		this.Form.reset();

		if (this.MyDropFilterInputs) this.MyDropFilterInputs.changes.subscribe((queryChanges: QueryList<DropDownListComponent>) => { this.MyDropFilterInputs = queryChanges; });
		if (this.MyDropInputs) this.MyDropInputs.changes.subscribe((queryChanges: QueryList<DropDownListComponent>) => { this.MyDropInputs = queryChanges; });

		if (this.required) {
			if (this.keyType === 1)
				this.Form.controls['ItemRef'].setValidators([this.validateSelectionDropDownInt]);
			if (this.keyType === 2)
				this.Form.controls['ItemRef'].setValidators([this.validateSelectionDropDownGuid]);
		}
	}

	initializeCustomControlLookUp(lookupList: Observable<NameValueList[]>, cd: ChangeDetectorRef, defaultValue: string = '', defaultKey: any = 0) {
		if (!this.defaultSet) {
			this.setDefaultValue(defaultKey, defaultValue);
			this.defaultSet = true;
		}
		if (lookupList)
			lookupList.subscribe(data => { this.setData(data); }, error => { }, () => { this.onDataSet(this.key); this.manuallyDetectChanges(cd); });
	}


	initializeControl(service: NVLService, cd: ChangeDetectorRef, defaultValue: string = '', defaultKey: any = 0) {
		if (!this.defaultSet) {
			this.setDefaultValue(defaultKey, defaultValue);
			this.defaultSet = true;
		}
		if (service)
			this.getAndSetData(service, cd);
	}

	getAndSetData(service: NVLService, cd: ChangeDetectorRef) {
		service.getLookup().subscribe(data => { this.setData(data); }, error => { }, () => { this.onDataSet(this.key); this.manuallyDetectChanges(cd); });
	}

	createDataset(list: any[], valueMember: string, keyMember: string): NameValueList[] { 
		let aux: NameValueList[] = [];
		for (const t of list) {
			aux.push({ Value: t[keyMember], Key: this.valueOrGuidToString(t[valueMember]) });
		}
		return aux;
	}
	 
	setData(list: any[], valueMember: string = 'Key', keyMember: string = 'Value') {		
		this.Data = this.createDataset(list, valueMember, keyMember);
	}

	setDefaultValue(key: any, value: string) {
		this.DefaultValue = { Value: value, Key: this.valueOrGuidToString(key) };
	}

	getValue(): any {
		return this.valueOrStringToGuid(this.Form.value['ItemRef']);
	}

	getItem(): NameValueList {
		let id = this.getValue();
		return this.Data.find(o => o.Key === id);
	}

	setValue(value: any): void {
		this.Form.patchValue({ ItemRef: this.valueOrGuidToString(value) });
	}

	onDataSet(key: any) {
		if (!this.parent || !this.parent.onDataSet) return;
		this.parent.onDataSet(key);
	}

	onValueChange(e: any) {
		if (!this.parent || !this.parent.onDropDownValueChanged) return;
		let item: NameValueList = undefined;
		if (e === this.DefaultValue.Key)
			item = this.DefaultValue;
		else
			item = this.Data.find(o => o.Key === e);
		if (item)
			this.parent.onDropDownValueChanged(item, this.key);
	}

	validateSelectionDropDownInt(c: UntypedFormControl): ValidationErrors | null {
		return (c.value && +c.value > 0) ? null : {
			validateSelectionDropDown: {
				valid: false
			}
		};
	}

	validateSelectionDropDownGuid(c: UntypedFormControl): ValidationErrors | null {
		return (c.value && !Guid.parse(c.value).isEmpty()) ? null : {
			validateSelectionDropDown: {
				valid: false
			}
		};
	}

	agregarItemVisible(): boolean {
		return this.parent && this.parent.agregarDropDownListItem;
	}

	addNewItem(): void {
		if (this.agregarItemVisible()) {
			this.agregandoItemAsync = true;
			this.parent.agregarDropDownListItem(this.filterText, this.key);
			this.agregandoItemAsync = false;
		}
	}

	generateAndAddNewObject(obj: any, funcGetObjId: ((any) => any), service: NVLService, cd: ChangeDetectorRef) {
		service.create(obj).subscribe(data => {
			let id = funcGetObjId(data);
			service.getLookup().subscribe(data => {
				this.setData(data);
				this.setValue(id);
			}, error => { }, () => { this.manuallyDetectChanges(cd); });
		}, error => { });
	}

	generateAndAddNewObjectCustom(obj: any, funcGetObjId: ((any) => any), service: NVLService, lookupList: Observable<NameValueList[]>, cd: ChangeDetectorRef) {
		service.create(obj).subscribe(data => {
			let id = funcGetObjId(data);
			lookupList.subscribe(data => {
				this.setData(data);
				this.setValue(id);
			}, error => { }, () => { this.manuallyDetectChanges(cd); });
		}, error => { });
	}

	valueOrGuidToString(value: any): any {
		if (!value) return value;
		if (value instanceof Guid)
			return value.toString();
		else
			return value;
	}

	valueOrStringToGuid(value: any): any {
		if (!value) return value;
		if (Guid.isGuid(value))
			return Guid.parse(value);
		else
			return value;
	}

	manuallyDetectChanges(cd: ChangeDetectorRef): void {
		if (cd && !((cd as ViewRef).destroyed)) {
			cd.detectChanges();
		}
	}
}
