import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { EditableForm, EditableFormList } from '../../common_controls/common-controls.interface';
import { Subscription } from 'rxjs';
import { Dieta } from '../../../../../models/dieta';
import { IOpenInDialog, IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { Router } from '@angular/router';
import { MessengerService } from '../../../../../services/messenger.service';
import { DietaService } from '../../../../../services/dieta.service';
import { BroadcastMessage } from '../../../../../interfaces/message.interface';
import { DietaComponent } from '../dieta/dieta.component';
import { DietaOrdenComponent } from '../dieta-orden/dieta-orden.component';
import { DietaAlimentoComponent } from '../dieta-alimento/dieta-alimento.component';
import { LayoutUtilsService } from '../../../../core/_base/crud';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuickPanelService } from '../../../../../services/quick-panel.service';

@Component({
	selector: 'kt-dieta-crud',
	templateUrl: './dieta-crud.component.html',
	styleUrls: ['./dieta-crud.component.scss'],
	providers: [DietaService]
})
export class DietaCrudComponent extends EditableForm<Dieta> implements OnInit, OnDestroy, IOpenInDialog {
	openInDialog(model: any, extras: any): void {
		throw new Error("Method not implemented.");
	}

	private e: any;
	private sub: Subscription;

	public edicion: boolean = false;

	public readonly: boolean = false;

	saveResultDialogModel(model: any): void {
		
	}

	editResultDialog(formParent: IParentOpenInDialog): boolean {
		return false;
	}

	saveResultDialog(formParent: IParentOpenInDialog): boolean {
		if (formParent) {
			if (!this.runValidaciones()) {
				return false;
			}
			this.setModelPropsByControls();
			formParent.saveResultDialogModel(this.model);
		}
		return true;
	}
	 
	@ViewChild(DietaComponent, { static: true }) private Dieta: DietaComponent;
	@ViewChild(DietaOrdenComponent, { static: true }) private DietaOrden: DietaOrdenComponent;
	@ViewChild(DietaAlimentoComponent, { static: true }) private DietaAlimento: DietaAlimentoComponent;

	constructor(cd: ChangeDetectorRef,
		private router: Router,
		messengerService: MessengerService,
		dietaService: DietaService,
		snackBar: MatSnackBar,
		quickPanelService: QuickPanelService) {
		super(cd, dietaService, snackBar, messengerService, quickPanelService);
	}

	ngOnInit() {
		this.sub = this.getMessengerInstance().messageBroadcasted$.subscribe((data: BroadcastMessage) => {
			this.cd.detectChanges();
		});
	}

	ngOnDestroy() {
		if (this.sub) { this.sub.unsubscribe(); }
	}

	onSave(e, repeat: boolean = false): void {
		super.onSave(e, repeat);
	}

	onCancel(e): void {
		this.Dieta.dieta = undefined;

		super.onCancel(e);
	}

	ngAfterViewInit() {
		this.edicion = false;

		this.Dieta.lstControls.forEach(c => this.lstControls.push(c));
	}

	onInputValueChange(newValue, labelName): void {

	}

	setModelPropsByControls(): void {
		this.Dieta.setModelValues(this.model);
		this.DietaOrden.dieta = this.model;
		this.DietaAlimento.dieta = this.model;	
		this.readonly = this.model.DietaID <= 0;
	}

	getNewModel(): Dieta { return new Dieta(); };
	getKeyModel(obj: Dieta): number { return obj.DietaID; };

	vista: string = "datos"
	cambiarVista(nuevaVista: string): void {
		this.vista = nuevaVista;
	}

	setModel(obj: Dieta, parentCarga: EditableFormList<Dieta> = undefined) {
		super.setModel(obj, parentCarga);

		this.setModelPropsByControls();
	}
}
