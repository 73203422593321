import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NameValueList } from '../../../../../interfaces/nvl.interface';

@Component({
    selector: 'my-col-autocomplete',
    templateUrl: './my-col-autocomplete.component.html',
    styleUrls: ['./my-col-autocomplete.component.scss']
})
export class MyColAutocompleteComponent implements OnInit {
    @Input() parent: any = undefined;
    @Input() labelName: string = '';
    @Input() labelVisible: boolean = true;
    @Input() required: boolean = false;

    Data: NameValueList[] = [];
    Placeholder: string = "";

    public AutoCompleteForm: UntypedFormGroup = new UntypedFormGroup({
        'ItemRef': new UntypedFormControl(0)
    });

    constructor() { }
    ngOnInit() {
        this.AutoCompleteForm.reset();
        if (this.required) {
            this.AutoCompleteForm.controls['ItemRef'].setValidators([Validators.required, Validators.min(1)]);
        }
    }

    setData(value: NameValueList[]) {
        this.Data = value;
    }

    setPlaceholder(value: string) {
        this.Placeholder = value
    }

    getValue(): number {
        return this.AutoCompleteForm.value['ItemRef'].Key;
    }

    getItem(): NameValueList {
        let id = this.getValue();
        return this.Data.find(o => o.Key === id);
    }

    setValue(value: number): void {
        if (value < 0)
            this.AutoCompleteForm.reset();
        else
			this.AutoCompleteForm.patchValue({
				ItemRef: { Key: value, Value: '' } });
    }

    isValid(): boolean {
        return this.AutoCompleteForm.valid;
    }

    onValueChange(e: any) {
        if (!e || !this.parent || !this.parent.onAutoCompleteValueChanged) return;
        let item = this.Data.find(o => o.Key === e.Key);
        if (item)
            this.parent.onAutoCompleteValueChanged(item, this.labelName);
    }
}
