import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';
import { TropaOrigen } from '../models/tropa-origen';
import { Injectable } from '@angular/core';

@Injectable()
export class TropaOrigenService extends GenericoService<TropaOrigen> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'tropaorigen');
	}
}
