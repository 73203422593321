import { ChangeDetectorRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs-compat/operator/takeUntil';
import { finalize, tap } from 'rxjs/operators';
import { ApiResponse } from '../../../../../interfaces/api-response.interface';
import { LoginADA } from '../../../../../models/login-ada';
import { AutenticacionService } from '../../../../../services/autenticacion.service';
import Utils from '../../../../../utils';
import { AuthNoticeService, Login } from '../../../../core/auth';
import { AppState } from '../../../../core/reducers';

@Component({
  selector: 'kt-select-empresa',
  templateUrl: './select-empresa.component.html',
  styleUrls: ['./select-empresa.component.scss']
})
export class SelectEmpresaComponent implements OnInit {

	loading = false;
	loginData: LoginADA = undefined;
	returnUrl = '';

	constructor(
		private route: ActivatedRoute,
		private auth: AutenticacionService,
		private router: Router,
		private store: Store<AppState>,
		private authNoticeService: AuthNoticeService,
		private cdr: ChangeDetectorRef
	) { }

	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			this.loginData = JSON.parse(params['loginADA']);
			this.returnUrl = params['returnUrl'];
		});
	}

	login(empresa: string) {
		this.loading = true;
		this.auth
			.autenticar(this.loginData.LoginToken, empresa)
			.pipe(
				tap(data => {
					if (Utils.checkAPIResponse(data, undefined, '')) {
						if (data) {
							localStorage.setItem('currentUser', JSON.stringify(data));
							localStorage.setItem('empresa', empresa);
							this.store.dispatch(new Login({ authToken: data.AccessToken }));
							this.router.navigateByUrl(this.returnUrl == '/' ? '/auth/redirect' : this.returnUrl).then((r) => this.loading = false);
							
							this.loading = false;
						}
					}
					else {
						let apiResponse = (data as unknown as ApiResponse);
						this.authNoticeService.setNotice(apiResponse.StatusDescription, 'danger');
						this.loading = false;
					}
				}),
				finalize(() => {
					this.cdr.markForCheck();
				})
			)
			.subscribe();		
	}
}
