import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { MyColTextAreaComponent, MyColColorPickerComponent, MyColInputComponent, MyColSwitchComponent } from '../../common_controls';
import { ValidationControlInterface } from '../../common_controls/common-controls.interface';
import { Dieta } from '../../../../../models/dieta'; 
import { SwitchComponent } from '@progress/kendo-angular-inputs';

@Component({
	selector: 'kt-dieta',
	templateUrl: './dieta.component.html',
	styleUrls: ['./dieta.component.scss']
})
export class DietaComponent implements OnInit {
	component: DietaComponent = this;
	 
	@ViewChild('DietaNombre', { static: true }) DietaNombre: MyColInputComponent;
	@ViewChild('Color', { static: true }) Color: MyColColorPickerComponent; 
	@ViewChild('DietaDescripcion', { static: true }) DietaDescripcion: MyColTextAreaComponent;
	@ViewChild('ActivaSwitch', { static: true }) ActivaSwitch: MyColSwitchComponent;

	lstControls: ValidationControlInterface[];

	@Input() readonly: boolean = false;

	public model: Dieta;

	public _dieta: Dieta;
	@Input() set dieta(model: Dieta) {
		this._dieta = model;
		if (this._dieta)
			this.resetModel();
	}

	constructor(private cd: ChangeDetectorRef) {
		this._dieta = undefined;
	}

	ngOnInit() {
		this.lstControls = [this.DietaNombre, this.Color, this.DietaDescripcion, this.ActivaSwitch]; 
	}

	resetModel() {
		this.DietaNombre.setValue(this._dieta.DietaNombre);
		this.Color.setValue(this._dieta.ColorRef); 
		this.DietaDescripcion.setText(this._dieta.DietaDescripcion);
		this.ActivaSwitch.setValue(this._dieta.Activo);
	}

	setModelValues(model: Dieta) {
		model.DietaNombre = this.DietaNombre.getValue();
		model.ColorRef = this.Color.getColor();
		model.DietaDescripcion = this.DietaDescripcion.getText();
		model.Activo = this.ActivaSwitch.getValue();;

		this.model = model;
	} 	 
}
