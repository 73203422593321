import { Component, OnInit, AfterViewInit, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { Usuario } from '../../../../../models/usuario';
import { EditableForm } from '../../common_controls/common-controls.interface';
import { OffCanvasInterface } from '../../../../../interfaces/off-canvas.interface';
import { UsuarioService } from '../../../../../services/usuario.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessengerService } from '../../../../../services/messenger.service';
import { IOpenInDialog, IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { MyColInputComponent, MyColSwitchComponent, MyColMultiselectComponent, MyColDropDownListComponent, AvatarPhotoComponent, MyColCheckboxComponent } from '../../common_controls';
import { Validators, FormControl, ValidationErrors } from '@angular/forms';
import { RolService } from '../../../../../services/rol.service';
import { Rol } from '../../../../../models/rol';
import { UsuarioRol } from '../../../../../models/usuario-rol';
import Utils, { AuthenticationProvider } from '../../../../../utils';
import { ClienteService } from '../../../../../services/cliente.service';
import { Cliente } from '../../../../../models/cliente';
import { ZonaService } from '../../../../../services/zona.service';
import { Zona } from '../../../../../models/zona';
import { UsuarioZona } from '../../../../../models/usuario-zona';
import { QuickPanelService } from '../../../../../services/quick-panel.service';

const MAX_FILE_SIZE: number = 256000; // 256Kb

@Component({
	selector: 'kt-usuario',
	templateUrl: './usuario.component.html',
	styleUrls: ['./usuario.component.scss'],
	providers: [UsuarioService, RolService, ClienteService, ZonaService]
})
export class UsuarioComponent extends EditableForm<Usuario> implements AfterViewInit, OnInit, IOpenInDialog, OnDestroy, OffCanvasInterface {
	@ViewChild('Avatar', { static: true }) Avatar: AvatarPhotoComponent;

	@ViewChild('Activo', { static: true }) Activo: MyColSwitchComponent;

	@ViewChild('Cuenta', { static: true }) Cuenta: MyColInputComponent;
	@ViewChild('Password', { static: true }) Password: MyColInputComponent;

	@ViewChild('Nombre', { static: true }) Nombre: MyColInputComponent;
	@ViewChild('Apellido', { static: true }) Apellido: MyColInputComponent;

	@ViewChild('Email', { static: true }) Email: MyColInputComponent;
	@ViewChild('Roles', { static: true }) Roles: MyColMultiselectComponent;
	@ViewChild('Zonas', { static: true }) Zonas: MyColMultiselectComponent;
	@ViewChild('TodasLasZonas', { static: true }) TodasLasZonas: MyColCheckboxComponent;

	@ViewChild('Cliente', { static: true }) Cliente: MyColDropDownListComponent;

	public componente: UsuarioComponent = this;
	public placeholder: string = '';
	public roles: Rol[] = [];
	public zonas: Zona[] = [];
	public imagenSrc: any = undefined;
	public puedeEditar: boolean = true;

	constructor(usuarioService: UsuarioService,
		cd: ChangeDetectorRef,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		private clienteService: ClienteService,
		private rolService: RolService,
		private zonaServie: ZonaService,
		quickPanelService: QuickPanelService) {
		super(cd, usuarioService, snackBar, messengerService, quickPanelService);
		this.getQuickPanelInstance().setQuickPanelLoadingStatus(true);
	}

	saveResultDialog(formParent: IParentOpenInDialog): boolean {
		return false;
	}

	editResultDialog(formParent: IParentOpenInDialog): boolean {
		return false;
	}

	setOffCanvasData(data: any, extras: any): void {
		setTimeout(() => {
			this.isNewModel = !data;
			this.lstControls = [this.Cuenta, this.Nombre, this.Apellido, this.Email, this.Email, this.Roles, this.Zonas, this.Password];

			if (extras)
				this.puedeEditar = extras.PuedeEditar;

			if (!this.isNewModel) {
				this.placeholder = 'Dejar en blanco para no cambiar';
				this.imagenSrc = data.ImagenPerfil;

				this.Avatar.photoUrl = this.imagenSrc;
				this.Avatar.name = `${data.UsuarioNombre} ${data.UsuarioApellido}`;

				this.Avatar.refresh();
			}

			this.addCustomValidators();
			this.setModel(data);

			if (!this.hasProfilePic() && this.model) {
				this.manuallyDetectChanges();
			}
		}, 0);
	}

	ngOnInit() {
		this.Cliente.initializeControl(this.clienteService, this.cd, 'Seleccione un Propietario');
		this.cargarRoles();
		this.cargarZonas();
	}

	ngOnDestroy() {

	}

	ngAfterViewInit() {
		this.Nombre.setParent(this);
		this.Apellido.setParent(this);

		this.getQuickPanelInstance().setQuickPanelLoadingStatus(false);
	}

	onUsuarioSave(e: any) {
		if (!this.runValidaciones())
			return false;
		super.onSave(e, false);
	}

	runCustomValidaciones(): boolean {
		let roles: any = this.Roles.getValue();

		if (!roles || roles.length === 0) {
			this.MessageDialog.showAtencionDialog('Debe seleccionar al menos un rol para el usuario');
			return false;
		}

		return true;
	}

	onCreateUpdateFinish(sucess: boolean) {
		if (sucess) {
			this.getMessengerInstance().broadcast('UsuarioComponent', undefined, 'UsuarioImagenPerfil', { UsuarioID: this.model.UsuarioID, Base64: this.model.ImagenPerfil });
			this.getMessengerInstance().broadcast(undefined, undefined, 'Usuario', this.model);
			this.getQuickPanelInstance().hideQuickPanel();
		}

		super.onCreateUpdateFinish(sucess);
	}

	onUsuarioCancel(e: any) {
		this.getQuickPanelInstance().hideQuickPanel();
	}

	getNewModel(): Usuario { return new Usuario(); };

	getKeyModel(model: Usuario): any {
		return model.UsuarioID;
	};

	setModelPropsByControls(): void {
		this.model.ImagenPerfil = this.imagenSrc;

		this.model.Activo = this.Activo.getValue();
		this.model.Cuenta = this.Cuenta.getValue();

		if (this.Password.getValue()) {
			this.model.Password = this.Password.getValue();
		}

		this.model.UsuarioNombre = this.Nombre.getValue();
		this.model.UsuarioApellido = this.Apellido.getValue();

		this.model.Email = this.Email.getValue();
		this.model.Roles = this.crearUsuarioRolCollection(this.Roles.getValue());
		this.model.Zonas = this.crearUsuarioZonaCollection(this.Zonas.getValue());
		this.model.TodasLasZonas = this.TodasLasZonas.getValue();

		this.model.ClienteRef = this.Cliente.getValue();

		if (this.Cliente && this.Cliente.getItem()) { this.model.ClienteNombre = this.Cliente.getItem().Value; }
	}

	agregarDropDownListItem(text: string, key: string) {
		if (key == 'Hotelero') {
			let cliente: Cliente = new Cliente();
			cliente.ClienteNombre = text;
			this.Cliente.generateAndAddNewObject(cliente, (c) => c.ClienteID, this.clienteService, this.cd);
		}
	}

	setModelControlValues() {
		if (this.model) {
			this.Activo.setValue(this.model.Activo);

			this.Cuenta.setValue(this.model.Cuenta);
			// password no

			this.Nombre.setValue(this.model.UsuarioNombre);
			this.Apellido.setValue(this.model.UsuarioApellido);

			this.Email.setValue(this.model.Email);
			this.Roles.setValue(this.normalizarRoles(this.model.Roles));
			this.Zonas.setValue(this.normalizarZonas(this.model.Zonas));
			this.TodasLasZonas.setValue(this.model.TodasLasZonas);

			this.Cliente.setValue(this.model.ClienteRef); 

			this.Avatar.photoUrl = this.model.ImagenPerfil == null ? '' : this.model.ImagenPerfil.toString();
			this.Avatar.name = `${this.model.UsuarioNombre} ${this.model.UsuarioApellido}`;
			this.Avatar.refresh();
		}
	}

	openInDialog(model: Usuario, extras: any) {
		this.setModel(model);
	}

	addCustomValidators(): void {
		if (this.isNewModel) {
			this.Password.setValidators([Validators.required, Validators.minLength(8), Utils.validateNoSpaces, Utils.validateLetterChar, Utils.validateNumberChar, Utils.validateSpecialChar]);
		} else {
			this.Password.setValidators([Validators.minLength(8), Utils.validateNoSpaces, Utils.validateLetterChar, Utils.validateNumberChar, Utils.validateSpecialChar]);
		}
		this.Email.setValidators([Validators.required, Validators.email]);
		this.Cuenta.setValidators([Validators.required, Utils.validateNoSpaces, Validators.minLength(4)]);
		this.Nombre.setValidators([Validators.required, Validators.minLength(4)]);
		this.Apellido.setValidators([Validators.required, Validators.minLength(4)]);
	}

	cargarRoles(): void {
		this.rolService.getList().subscribe(data => {
			this.roles = data;
		}, error => {
			Utils.showSnackBarErrorMessage(this.snackBar, 'Error al cargar los roles');
		}, () => {
			this.cd.detectChanges();
		});
	}

	cargarZonas(): void {
		this.zonaServie.getList().subscribe(data => {
			this.zonas = data;
		}, error => {
			Utils.showSnackBarErrorMessage(this.snackBar, 'Error al cargar las zonas');
		}, () => {
			this.cd.detectChanges();
		});
	}

	normalizarRoles(roles: UsuarioRol[]): any[] {
		let _roles: any[] = [];
		for (const r of roles) {
			_roles.push({ RolID: r.RolRef, RolNombre: r.RolNombre });
		}
		return _roles;
	}

	normalizarZonas(zonas: UsuarioZona[]): any[] {
		let _zonas: any[] = [];
		for (const z of zonas) {
			_zonas.push({ ZonaID: z.ZonaRef, ZonaNombre: z.ZonaNombre });
		}
		return _zonas;
	}

	crearUsuarioRolCollection(roles: any[]): UsuarioRol[] {
		let _roles: UsuarioRol[] = [];
		for (const r of roles) {
			_roles.push(new UsuarioRol(r.RolID, r.RolNombre));
		}
		return _roles;
	}

	crearUsuarioZonaCollection(zonas: any[]): UsuarioZona[] {
		let _zonas: UsuarioZona[] = [];
		for (const z of zonas) {
			_zonas.push(new UsuarioZona(z.ZonaID, z.ZonaNombre));
		}
		return _zonas;
	}
	
	cambiarFoto(fileInput: any): void {
		if (!this.Avatar.eliminando)
			fileInput.click();
		else {
			this.imagenSrc = '';
			this.Avatar.eliminando = false;		
		}
	}

	onInputValueChange(event, labelName): void {
		if (!this.model) {
			return;
		}

		if (labelName === 'Nombre' || labelName === 'Apellido') {
			if (!this.hasProfilePic()) { 
				this.Avatar.name = `${this.Nombre.getValue()} ${this.Apellido.getValue()}`;
				this.Avatar.refresh();
				this.manuallyDetectChanges();
			}
		}
	}

	hasProfilePic(): boolean {
		if (!this.model) return false;
		if (!this.model.ImagenPerfil) return false;
		return this.model.ImagenPerfil.toString().startsWith('data:');
	}

	fotoSeleccionada(fileInput: any) {
		if (fileInput.target.files && fileInput.target.files[0]) {
			var file = fileInput.target.files[0];

			if (file.size > MAX_FILE_SIZE) {
				this.MessageDialog.showAtencionDialog('La imagen seleccionada supera el máximo permitido (256 Kb)');
			} else {
				const reader = new FileReader();

				reader.readAsDataURL(file);

				reader.onload = () => {
					this.imagenSrc = reader.result;
					this.Avatar.photoUrl = this.imagenSrc;
					this.Avatar.refresh();
					this.cd.detectChanges();
				};
			}
		}
	}

	cuentaVinculada(): boolean {
		return this.model != null && (this.model.AuthenticationProviderRef != null && this.model.AuthenticationProviderRef != 0);		
	}

	desvincularCuenta(): void {
		this.MessageDialog.showDialog('Por favor confirme', 'La cuenta se desvinculará y podrá volver a utilizar la contraseña de SIGEF. ¿Continuar?',
			['SI', 'NO'], 'DesvincularCuenta', this);
	}

	providerVinculado(toLowerCase: boolean): string {
		let provider = AuthenticationProvider[this.model.AuthenticationProviderRef];
		if (toLowerCase)
			provider = provider.toLowerCase();
		return provider;
	}

	onConfirmResultMessageDialog(id: string, ok: boolean) {
		if (ok && id === 'DesvincularCuenta') {
			this.model.AuthenticationProviderRef = null;
		}
		this.cd.detectChanges();		
	}
}
