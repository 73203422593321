<div>
	<div class="row row-sigef" [ngClass]="{'d-none': movTipo == 1}">
		<my-col-datetimepicker [tabindex]="0" #FechaMovimiento [labelName]="'Fecha Movimiento'"></my-col-datetimepicker>
	</div>

	<div id="no-compra" [ngClass]="{'d-none': movTipo == 1}" class="pt-3 pb-3">
		<div class="row row-sigef">
			<my-col-dropdownlist #Producto [parent]="component" [tabindex]="5" [key]="'Producto'" [labelName]="'Producto'" [required]="true"></my-col-dropdownlist>
		</div>
		<div class="row row-sigef">
			<my-col-labelinfo [extraClass]="{'red-label': (stockActual < 0)}" [ngClass]="{'d-none': edicion}" #CantPropuesta [labelName]="'Cantidad Actual'"></my-col-labelinfo>
			<my-col-input [tabindex]="7" #Cantidad [mask]="'#,#'" [decimals]="0" [labelName]="cantidadReal" [allowCero]="false" [required]="true"></my-col-input>
		</div> 
	</div>

	<my-row-expand class="row row-sigef mt-1" [tabindex]="10" [title]="Observaciones" [hint]="'Seleccione esta opción para incluir una aclaración adicional'" [labelName]="'Observaciones'" [expanded]="adicionalesVisible" (onExpand)="adicionalesVisible=$event"></my-row-expand>
	<div class="row row-sigef" [ngClass]="{'d-none': !adicionalesVisible}">
		<my-col-textarea [tabindex]="11" [labelName]="'Observaciones'" [rows]="2" [labelVisible]="false" class="w-100"></my-col-textarea>
	</div>

	<div class="kt-divider kt-divider-sigef"><span></span></div>

	<div class="ml-3 mr-3">
		<my-group-save-buttons [tabindex]="12" class="w-100" [saving]="saving" [targetName]="'Movimiento'"
							   (onSave)="onSave($event)" (onCancel)="onCancel($event)"></my-group-save-buttons>
	</div>
</div>
<my-message-dialog></my-message-dialog>
