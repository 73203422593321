import { DTOInterface } from "../interfaces/dto.interface";
import { Guid } from "guid-typescript";

export class MovAlimento implements DTOInterface {
	MovAlimentoID: Guid;
    MovAlimentoTipoRef: number;
    AlimentoRef: number;
    FechaMovimiento: Date;
    FechaCreacion: Date;
    CantidadPropuestaKg: number;
    CantidadRealKg: number;
	MixerCargaRef: Guid;
    CompraRef: number;
    Precio: number;
	Observaciones: string;
	Grupo: Guid;
	AlimentoAlmacenRef: number;

	AlimentoNombre: string;
    MovAlimentoTipoNombre: string;
	MultiplicaPor: number;
	ProveedorRef: number;
    FechaCompra: Date;
    RemitoNro: string;
    ProveedorNombre: string;
	ProveedorTipoNombre: string;
	AlimentoAlmacenNombre: string; 
	AlmacenDestinoRef: number;

    constructor() {
        this.clear();
    }

    clear(): void {
		this.MovAlimentoID = Guid.createEmpty();
        this.MovAlimentoTipoRef = 0;
        this.AlimentoRef = 0;
        this.FechaMovimiento = new Date();
        this.FechaCreacion = new Date();
        this.CantidadPropuestaKg = 0;
        this.CantidadRealKg = 0;
		this.MixerCargaRef = Guid.createEmpty();
        this.CompraRef = -1;
        this.Precio = 0;

        this.MovAlimentoTipoNombre = '';
		this.MultiplicaPor = 0;
		this.ProveedorRef = 0;
        this.FechaCompra = new Date();
		this.RemitoNro = '';
        this.ProveedorNombre = '';
        this.ProveedorTipoNombre = '';
	}
}

