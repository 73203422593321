import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';
import { Dieta } from '../models/dieta';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { NameValueList } from '../interfaces/nvl.interface';
import { DietaDashboard } from '../models/dieta-dashboard';
import { DietaAlimentoDashboard } from '../models/dieta-alimento-dashboard';

@Injectable()
export class DietaService extends GenericoService<Dieta> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'dieta');
	}

	getInvalidObjects(): Observable<NameValueList[]> {
		return this._httpClient.get<NameValueList[]>(`${environment.apiUrl}/${this._endpoint}/invalidas`, { headers: this._getHeaders });
	}
	getLookupByCarga(): Observable<NameValueList[]> {
		return this._httpClient.get<NameValueList[]>(`${environment.apiUrl}/${this._endpoint}/lookupByCarga`, { headers: this._getHeaders });
	}
	getDashboardList(): Observable<DietaDashboard[]> {
		return this._httpClient.get<DietaDashboard[]>(`${environment.apiUrl}/${this._endpoint}/dashboard`, { headers: this._getHeaders });
	}
	getDietaAlimentoDashboardList(dietaID: number): Observable<DietaAlimentoDashboard[]> {
		return this._httpClient.get<DietaAlimentoDashboard[]>(`${environment.apiUrl}/${this._endpoint}/dietaAlimentoDashboardList/${dietaID}`, { headers: this._getHeaders });
	}
}

