import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';
import { Comprador } from '../models/comprador';

@Injectable()
export class CompradorService extends GenericoService<Comprador> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'comprador');
	}
}
