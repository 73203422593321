import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { OffCanvasInterface } from '../../../../../interfaces/off-canvas.interface';
import { Notificacion } from '../../../../../models/notificacion';
import Utils from '../../../../../utils';
import { EditableForm, EditableFormList } from '../../common_controls/common-controls.interface';
import { NotificacionService } from '../../../../../services/notificacion.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessengerService } from '../../../../../services/messenger.service';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { NotificacionComponent } from '../../notificacion';
import { CorralInfo } from '../../../../../models/corral-info';
import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';
import { BroadcastMessage } from '../../../../../interfaces/message.interface';
import { NgxRolesService, NgxRole } from 'ngx-permissions';

@Component({
	selector: 'kt-tropa-corral-notificacion',
	templateUrl: './tropa-corral-notificacion.component.html',
	styleUrls: ['./tropa-corral-notificacion.component.scss'],
	providers: [NotificacionService]

})
export class TropaCorralNotificacionComponent extends EditableFormList<Notificacion> implements OnInit {
    notificacionesData: Notificacion[] = undefined;
	edicion: boolean = false;
	tieneModificaciones: boolean = false;
	admin: NgxRole = undefined;
	sub: Subscription;

	@Input() tabindex: number = 500;

	@ViewChild(NotificacionComponent, { static: true }) Notificacion: NotificacionComponent;

	public parent: TropaCorralNotificacionComponent = this;

	constructor(cd: ChangeDetectorRef,
		private quickPanelService: QuickPanelService,
		private roleService: NgxRolesService,
		private messengerService: MessengerService,
	) { super(cd, undefined) }

	ngOnInit() {
		this.admin = this.roleService.getRole('Administrador');
		this.sub = this.messengerService.messageBroadcasted$.subscribe((message: BroadcastMessage) => {
			if (message.Sender === 'NotificacionComponent' && message.Target === 'TropaCorralNotificacionComponent') {
				if (message.Reason === 'EliminarDataItem') { this.eliminarNotificacion(message.Payload); }
			}
		});
	}

	ngOnDestroy() {
		if (this.sub) { this.sub.unsubscribe(); }
	}

	setOffCanvasData(data: CorralInfo, extras: any): void {
		setTimeout(() => {
			this.mostrarEditor(false);
			this.notificacionesData = data.Notificaciones;
			this.Notificacion.corralID = data.CorralID;
			this.cd.detectChanges();
		}, 0);
	}

	setEditor(model: Notificacion, e: any, elimina: boolean): void {
		this.Notificacion.setModel(model, this.parent); //model = undefined, nuevo item
		this.mostrarEditor(true);

		if (elimina) { this.Notificacion.onDelete(e); }
	}

	guardar(e: any): void {
		this.Notificacion.onSave(e);
		this.mostrarEditor(false);
	}

	cancelar(e: any): void {
		if (this.edicion)
			this.mostrarEditor(false);
		else
			this.quickPanelService.hideQuickPanel();
	}

	onUpdatePost(obj: Notificacion): void {
		this.mostrarEditor(false);
	}

	onCreatePost(obj: Notificacion): void {
		this.notificacionesData.push(obj);
		this.mostrarEditor(false);
		this.tieneModificaciones = true;
	}

	eliminarNotificacion(obj: Notificacion): void {
		let index = this.notificacionesData.findIndex(r => r.NotificacionID == obj.NotificacionID);
		if (index > -1) {
			this.notificacionesData.splice(index, 1);
			this.tieneModificaciones = true;
		}
		this.mostrarEditor(false);
	}

	mostrarEditor(value: boolean): void {
		this.edicion = value;
		this.cd.detectChanges();
	}

	esImpar(v: number): boolean {
		return !Utils.checkIsEven(v);
	}
}
