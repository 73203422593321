import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, OnDestroy, AfterViewInit } from '@angular/core'; 
import { MovTropaFaena } from '../../../../../models/mov-tropa-faena';
import { MessengerService } from '../../../../../services/messenger.service';
import { FaenaComponent } from '../faena/faena.component';
import { Guid } from 'guid-typescript';
import { MyMessageDialogComponent } from '../../common_controls';
import { IParentConfirmResultMessage } from '../../common_controls/message-dialog/my-message-dialog.component';
import { Subscription } from 'rxjs';
import Utils from '../../../../../utils';
import { GridComponent } from '@progress/kendo-angular-grid';
import { QuickPanelService } from '../../../../../services/quick-panel.service';

@Component({
	selector: 'kt-faena-list',
	templateUrl: './faena-list.component.html',
	styleUrls: ['./faena-list.component.scss']
})
export class FaenaListComponent implements OnInit, OnDestroy, AfterViewInit, IParentConfirmResultMessage {
	@Input() public datos: MovTropaFaena[] = [];
	@Input() public movTropaRef: Guid = undefined;
	@Input() public cantidad: number = 0;
	@Input() public tabindex: number = 120000;

	@ViewChild(GridComponent, { static: true }) public Grid: GridComponent;
	@ViewChild(MyMessageDialogComponent, { static: true }) public MessageDialog: MyMessageDialogComponent;

	public cargando: boolean = false;

	public totalCantCabezas: number = 0;
	public totalKilosVivo: number = 0;
	public totalKilosCarne: number = 0;
	public totalPrecioFaena: number = 0;
	public totalRindePorc: number = 0;
	public totalPrecio: number = 0;

	private sub: Subscription = undefined;

	constructor(private cd: ChangeDetectorRef, private messengerService: MessengerService, private quickPanelService: QuickPanelService) { }
    
	ngOnInit() {
		this.sub = this.messengerService.messageBroadcasted$.subscribe(
			data => {
				if (data.Reason === 'MovTropaFaena') {
					this.totales();
				}
			}
		);

		this.totales();
		this.cd.detectChanges();
	}

	ngAfterViewInit() {
		
	}

	ngOnDestroy() {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}

	totales(): void {		
		this.totalCantCabezas = 0;
		this.totalKilosVivo = 0;
		this.totalKilosCarne = 0;
		this.totalPrecioFaena = 0;
		this.totalRindePorc = 0;
		this.totalPrecio = 0;

		for (const d of this.datos) {
			this.totalCantCabezas += d.CantCabezas;
			this.totalKilosVivo += d.KilosVivo;
			this.totalKilosCarne += d.KilosCarne;
			this.totalPrecioFaena += d.PrecioFaena; 
			this.totalRindePorc += (d.KilosCarne / d.KilosVivo);
			this.totalPrecio += (d.PrecioFaena * d.KilosCarne); 
		}

		this.totalRindePorc /= this.datos.length;

		this.totalRindePorc = Utils.checkNaNGetZero(this.totalRindePorc);

		if (this.totalCantCabezas > this.cantidad) {
			this.MessageDialog.showAtencionDialog('El total de cabezas con datos de faena no puede superar el total de cabezas vendidas.');
		}

		this.cd.detectChanges();
	}

	public initializeControl(): void {
		this.cd.detectChanges();
	}

	showABM(dataItem: any, isNew: boolean = false): void {
		this.quickPanelService.createOffCanvasComponent((isNew ? 'Nuevo Datos de Faena' : 'Editar Datos de Faena'), FaenaComponent, dataItem, { MovTropaRef: this.movTropaRef });
	}
	 
	public deletedItem: MovTropaFaena = undefined;
	confirmDelete(item: MovTropaFaena) { 
		this.deletedItem = item;
		this.MessageDialog.showDialog('Por favor confirme', 'Se eliminará al recurso en forma definitiva. Está seguro que desea continuar ?',
			['SI', 'NO'], 'DeleteModel', this);
	}

	onConfirmResultMessageDialog(id: string, ok: boolean): void {
		if (ok && id === 'DeleteModel' && this.deletedItem) {
			this.datos.splice(this.datos.indexOf(this.deletedItem), 1); 
			this.messengerService.broadcast(undefined, undefined, 'DeleteMovTropaFaena', undefined);
			this.totales();
		}
	}
}
