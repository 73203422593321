import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CommonControl } from '../common-controls.interface';

@Component({
	selector: 'my-col-checkbox',
	templateUrl: './my-col-checkbox.component.html',
	styleUrls: ['./my-col-checkbox.component.scss']
})
export class MyColCheckboxComponent extends CommonControl implements OnInit {
	@Input() title: string = '';
	@Input() hint: string = '';
	@Input() labelName: string = '';
	@Input() clicked: boolean = false;
	@Input() disabled: boolean = false;
	@Input() tabindex: number = -1;

	@Output() onClicked = new EventEmitter();

	constructor(private cd: ChangeDetectorRef) {
		super();
		this.Form = new UntypedFormGroup({
			'Checkbox': new UntypedFormControl(undefined),
		});
	}
	ngOnInit() { }

    onClick(): void {
		this.clicked = !this.clicked;
		this.onClicked.emit(this.clicked); 
	}

	setValue(value: any): void {
		this.Form.patchValue({ Checkbox: value });
	}

	getValue(): any {
		return this.Form.value['Checkbox'];
	}

}

