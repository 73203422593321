import { DTOInterface } from "../interfaces/dto.interface";
import { Guid } from "guid-typescript";
import { MovTropaFaena } from "./mov-tropa-faena";

export class MovTropa implements DTOInterface {
	MovTropaID: Guid;
	MovTropaTipoRef: number;
	TropaCategoriaGanadoRef: Guid;
	CorralRef: number;
	FechaMovimiento: Date;
	Cantidad: number;
	Observaciones: string;
	DesbastePorc: number;
	PesoCabezaKg: number;
	PrecioVenta: number;
	FechaCreacion: Date;
	VentaRef: Guid;
	TropaNombre: string;
	CodigoTropa: number;
	NroTransaccion: number;

	MovTropaTipoNombre: string;
	AumentoDiarioEstKg: number;
	CorralNumero: number;
	CorralNombre: string;
	MetaDiaria: number;
	CategoriaGanadoNombre: string;
	CategoriaGanadoAbrev: string;
	DietaNombre: string;
	MultiplicaPor: number;

	GenerarPesada: boolean;
	Grupo: Guid;
	ClienteNombre: string;
	IncluyePesada: boolean;
	EsUltimoMovimientoTropa: boolean;
	TropaRef: Guid;

	FechaIngreso: Date;

	Faenas: MovTropaFaena[];

	CorralNombreParicion: string;
	CorralNumeroParicion: string;
	CategoriaGanadoNombreParicion: string;
	CategoriaGanadoAbrevParicion: string;

	constructor() {
		this.clear();
	}

	clear(): void {
		this.MovTropaID = Guid.createEmpty();
		this.MovTropaTipoRef = 0;
		this.TropaCategoriaGanadoRef = Guid.createEmpty();
		this.CorralRef = 0;
		this.FechaMovimiento = new Date();
		this.Cantidad = undefined;
		this.Observaciones = undefined;
		this.DesbastePorc = undefined;
		this.PesoCabezaKg = undefined;
		this.PrecioVenta = undefined;
		this.FechaCreacion = new Date();
		this.VentaRef = undefined;
		this.TropaNombre = '';
		this.CodigoTropa = -1;

		this.MovTropaTipoNombre = '';
		this.AumentoDiarioEstKg = 0;
		this.CorralNumero = 0;
		this.CorralNombre = '';
		this.MetaDiaria = 0;
		this.CategoriaGanadoNombre = '';
		this.DietaNombre = '';
		this.MultiplicaPor = 0;

		this.GenerarPesada = false;
		this.Grupo = undefined;
		this.IncluyePesada = false;
		this.EsUltimoMovimientoTropa = false;

		this.FechaIngreso = undefined;

		this.Faenas = [];
	}
}
