<div [ngClass]="{'d-none': confirmacion }">
	<div class="">
		<div class="col w-100">
			<div class="row row-full-height">
				<div class="kt-portlet kt-portlet--mobile">
					<div class="kt-portlet__head">
						<div class="kt-portlet__head-label">
							<h3 class="kt-portlet__head-title">
								<i class="fas fa-info-circle"></i>&nbsp; Datos de la Venta
							</h3>
						</div>
					</div>
					<div class="kt-portlet__body">
						<div>
							<kt-venta [tabindex]="4000" [venta]="model"></kt-venta>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="">
		<div class="col w-100">
			<div class="row row-full-height">
				<div class="kt-portlet kt-portlet--mobile">
					<div class="kt-portlet__head">
						<div class="kt-portlet__head-label">
							<h3 class="kt-portlet__head-title">
								<i class="fas fa-shapes"></i>&nbsp; Hacienda
								<small *ngIf="!confirmacion">Ingrese los datos de faena expandiendo la categoría</small>
							</h3> 
						</div>
					</div>
					<div class="kt-portlet__body">
						<div>
							<kt-venta-categoria-ganado-list [tabindex]="4200" [venta]="model" [datos]="hacienda"></kt-venta-categoria-ganado-list>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="">
		<div class="col w-100">
			<div class="row row-full-height">
				<div class="kt-portlet kt-portlet--mobile">
					<div class="kt-portlet__head">
						<div class="kt-portlet__head-label">
							<h3 class="kt-portlet__head-title">
								<i class="fas fa-truck-loading"></i>&nbsp; Transporte
							</h3>
						</div>
					</div>
					<div class="kt-portlet__body">
						<div>
							<kt-venta-transporte-list [tabindex]="4400" [venta]="model"></kt-venta-transporte-list>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="">
		<div class="col w-100">
			<div class="row row-full-height">
				<div class="kt-portlet kt-portlet--mobile">
					<div class="kt-portlet__head">
						<div class="kt-portlet__head-label">
							<h3 class="kt-portlet__head-title">
								<i class="fas fa-plus-circle"></i>&nbsp; Datos de rentabilidad
							</h3>
						</div>
					</div>
					<div class="kt-portlet__body">
						<div>
							<kt-venta-rentabilidad [tabindex]="4450" [venta]="model"></kt-venta-rentabilidad>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="">
		<div class="col w-100">
			<div class="row row-full-height">
				<div class="kt-portlet kt-portlet--mobile">
					<div class="kt-portlet__body">
						<div>
							<my-group-save-buttons [tabindex]="4600" class="w-100" [saving]="saving" [deleting]="deleting" [allowDelete]="false" [targetName]="'Venta'"
												   (onSave)="onSave($event)" (onCancel)="onCancel($event)" (onDelete)="onDelete($event)"></my-group-save-buttons>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> 
</div> 

<div [ngClass]="{'d-none': !confirmacion }" class="mr-lg-5 ml-lg-5 mr-md-5 ml-md-5">
<kt-venta-reporte></kt-venta-reporte>
</div>

<my-message-dialog></my-message-dialog>
