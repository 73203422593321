import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import Utils from '../../../../../utils'; 
import { CommonControl } from '../common-controls.interface';
import { SelectionRange } from '@progress/kendo-angular-dateinputs';


@Component({
  selector: 'my-col-daterange',
  templateUrl: './my-col-daterange.component.html',
  styleUrls: ['./my-col-daterange.component.scss']
})
export class MyColDateRangeComponent extends CommonControl implements OnInit {

	@Input() labelDesde: string = 'Desde';
	@Input() labelHasta: string = 'Hasta';
	@Input() range: SelectionRange = { start: Utils.addDays(new Date(), -15), end: new Date() };

	constructor() {
		super();
		this.Form = new UntypedFormGroup({'FechaDesde': new UntypedFormControl('', Validators.required) ,
								   'FechaHasta': new UntypedFormControl('', Validators.required) });
	}

	ngOnInit() {
		this.setRango(this.range.start, this.range.end);
	}

	setRango(desde: Date, hasta: Date) {
		this.range.start = desde;
		this.range.end = hasta;
		this.Form.patchValue({ FechaDesde: this.range.start, FechaHasta: this.range.end});
	}

	setRangoDesdeHastaHoy(cantDias: number) {
		cantDias = cantDias < 0 ? cantDias : cantDias * -1; 
		this.setRango(Utils.addDays(new Date(), cantDias), new Date());
	}

	getDesde() {
		return this.Form.value['FechaDesde'];
	}

	getHasta() {
		return this.Form.value['FechaHasta'];
	}

}
