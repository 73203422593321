// Spain
export const locale = {
	lang: 'es',
	data: {
		CLOSE: 'Cerrar',
		TRANSLATOR: {
			SELECT: 'Elige tu idioma',
		},
		MENU: {
			NEW: 'nuevo',
			ACTIONS: 'Comportamiento',
			CREATE_POST: 'Crear nueva publicación',
			PAGES: 'Pages',
			FEATURES: 'Caracteristicas',
			APPS: 'Aplicaciones',
			DASHBOARD: 'Tablero de Control',
			CUSTOMERS: 'Hoteleros',
			FOODS: 'Productos Alimenticios',
			ADMIN: 'Administración',
			SUPPLIERS: 'Proveedores',
			CORRALS: 'Corrales',
			MIXERS: 'Mixers',
            DIETS: 'Dietas',
			SHIFTS: 'Turnos de Trabajo',
			ZONES: 'Zona',
			PSCATEGORIES: 'Categorías de Productos Sanitarios',
			USERS: 'Usuarios',
			CCATEGORIES: 'Categorias de Ganado',
			SANITARYPRODUCTS: 'Productos Sanitarios',
			MEASUREUNITS: 'Unidades de Medida',
			TRACETROOPS: {
				SELF: 'Seguimiento de Tropas',
				DAILY: 'Tropas & Corrales',
				ACTIVE: 'Reporte de Consumos',
				SALES: 'Ventas',
				LASTMOV: 'Últimos Movimientos',
				ARCHIVE: 'Tropas Históricas',
				REPORT: 'Cierre',
			},
			DESTINATIONTROOP: 'Destino de Tropas',
			SOURCETROOP: 'Origen de Tropas',
			MOVEMENTSTROOPS: {
				SELF: 'Movimiento de Tropas',
				IN: 'Ingreso',
				OUT: 'Egreso',
				SWAP: 'Cambio de Corral',
				WEIGHING: 'Control de Pesadas',
                FIELD_CONFINEMENT: 'Operación Interna',
                INTERNAL_OPERATION: 'Operación Interna',
				COUNT: 'Ajuste',
				CHANGE: 'Cambio de Categoría'
            },
            FEEDING: {
                SELF: 'Alimentación',
                STOCK: 'Stock',
                PURCHASES: 'Movimientos',
                LOADS: 'Cargas',
                UNLOADS: 'Descargas',
                LOADS_UNLOADS: 'Cargas & Descargas',
                ROUND_PLANNING: 'Planificación de Rondas',
                LOADS_DETAILS: 'Detalles',
				UNLOADS_DETAILS: 'Detalles',
				LASTMOV: 'Últimos Movimientos',
				NEW_MOV: 'Nuevo Movimiento'
            },
            SANITY: {
                SELF: 'Sanidad',
                STOCK: 'Stock',
                PURCHASES: 'Movimientos',
				PLANS: 'Plan Sanitario',
				NEW_MOV: 'Nuevo Movimiento',
				RECORDS: 'Registro de Aplicaciones'
            },
            REPORTS: 'Reportes',
            WEATHER: 'Clima'
		},
		AUTH: {
			GENERAL: {
				OR: 'O',
				SUBMIT_BUTTON: 'Enviar',
				NO_ACCOUNT: 'No tienes una cuenta?',
				SIGNUP_BUTTON: 'Regístrate',
				FORGOT_BUTTON: 'Se me olvidó la contraseña',
				BACK_BUTTON: 'Volver',
				PRIVACY: 'Privacidad',
				LEGAL: 'Legal',
				CONTACT: 'Contacto',
			},
			LOGIN: {
				TITLE: 'Iniciar Sesión',
				BUTTON: 'Iniciar Sesión',
				OR: 'También puede ingresar con'
			},
			FORGOT: {
				TITLE: '¿Contraseña olvidada?',
				DESC: 'Ingresá tu correo electrónico, te enviaremos un enlace para que recuperes el acceso a tu cuenta',
				STEP: 'Se envió un email con un enlace para recuperar la contraseña. Este enlace estará vigente durante las próximas 2 horas',
				SUCCESS: 'Ya puedes iniciar sesión con tu nueva contraseña'
			},
			REGISTER: {
				TITLE: 'Registrarse',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered.'
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Nombre Completo',
				PASSWORD: 'Contraseña',
				NEW_PASSWORD: 'Nueva Contraseña',
				CONFIRM_PASSWORD: 'Confirme la nueva Contraseña',
				USERNAME: 'Usuario',
				COMPANY: 'Código de empresa'
			},
			VALIDATION: {
				INVALID: '{{name}} no es válido',
				REQUIRED: '{{name}} es obligatorio',
                MIN: 'El número no puede ser menor a: ',
                MAX: 'El número no puede ser mayor a: ',
                MIN_LENGTH: '{{name}} debe contener al menos {{min}} caracteres',
				AGREEMENT_REQUIRED: 'Aceptar los términos y condiciones es necesario',
				NOT_FOUND: 'El {{name}} solicitado no se encontró',
				INVALID_LOGIN: 'La información para inicio de sesión no es correcta',
				REQUIRED_FIELD: 'Campo obligatorio',
				MIN_LENGTH_FIELD: 'Caracteres mínimos:',
				MAX_LENGTH_FIELD: 'Caracteres máximos:',
				INVALID_FIELD: 'El campo no es válido',
				EXPIRED: 'Su sesión ha caducado. O ha iniciado sesión desde otro dispositivo o se ha agotado el tiempo de espera.',
				INVALID_MASK: 'Complete la máscara requerida'
			}
		},
		CRUD: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'in all fields',
				NO_RECORDS: 'No hay registros para mostrar. Agregue uno nuevo utilizando el botón en la barra superior',
				REQUIRED_FIELD: 'Este campo es obligatorio'
			},
			CUSTOMERS: {
				SELF: 'Propietarios',
                LIST: 'Lista de Propietarios',
                NEW: 'Nuevo Propietario',
			},
			FOODS: {
				SELF: 'Productos Alimenticios',
				LIST: 'Lista de Productos Alimenticios',
				NEW: 'Nuevo Producto Alimenticio',
			},
			SUPPLIERS: {
				SELF: 'Proveedores',
				LIST: 'Lista de Proveedores',
				NEW: 'Nuevo Proveedor',
			},
			CORRALS: {
				SELF: 'Corrales',
				LIST: 'Lista de Corrales',
				NEW: 'Nuevo Corral',
			},
			MIXERS: {
				SELF: 'Mixers',
				LIST: 'Lista de Mixers',
				NEW: 'Nuevo Mixer',
            },
            SHIFTS: {
                SELF: 'Turnos de Trabajo',
                LIST: 'Lista de Turnos de Trabajo',
                NEW: 'Nuevo Turno de Trabajo',
			},
			ZONES: {
				SELF: 'Zonas',
				LIST: 'Lista de Zonas',
				NEW: 'Nueva Zona',
			},
			DIETS: {
				SELF: 'Dietas',
				LIST: 'Lista de Dietas',
				NEW: 'Nueva Dieta',
			},
			MEASUREUNITS: {
				SELF: 'Unidades de Medida',
				LIST: 'Lista de Unidades de Medida',
				NEW: 'Nueva Unidad de Medida'
			},
			PSCATEGORIES: {
				SELF: 'Categorías de Productos Sanitarios',
				LIST: 'Lista de Categorías de Productos Sanitarios',
				NEW: 'Nueva Categoria'
			},
			USERS: {
				SELF: 'Usuarios',
				LIST: 'Lista de Usuarios',
				NEW: 'Nuevo Usuario'
			},
			CCATEGORIES: {
				SELF: 'Categorías de Ganado',
				LIST: 'Lista de Categorías de Ganado',
				NEW: 'Nueva Categoría'
			},
			SANITARYPRODUCTS: {
				SELF: 'Productos Sanitarios',
				LIST: 'Lista de Productos Sanitarios',
				NEW: 'Nuevo Producto Sanitario'
			},
			TROOPSOURCES: {
				SELF: 'Orígenes de Tropa',
				LIST: 'Lista de Orígenes de Tropa',
				NEW: 'Nuevo Origen de Tropa'
			},
			TROOPDESTINATIONS: {
				SELF: 'Destinos de Tropa',
				LIST: 'Lista de Destinos de Tropa',
				NEW: 'Nuevo Destino de Tropa'
            },
			ROUNDPLANNING: {
                SELF: 'Planificaciones de Rondas',
                LIST: 'Lista de Planificaciones de Rondas',
				NEW: 'Nueva Planificación de Ronda'
            },
            WEATHER: {
                SELF: 'Informe de Clima',
                LIST: 'Informes de Clima',
                NEW: 'Nuevo Informe de Clima'
			},
			WAREHOUSES: {
				SELF: 'Almacenes de Productos',
				LIST: 'Lista de Almacenes de Productos',
				NEW: 'Nuevo Almacen de Productos'
			}
		}
	}
};
