import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { EditableForm, EditableFormList } from '../../common_controls/common-controls.interface';
import { Venta } from '../../../../../models/Venta';
import { IOpenInDialog, IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { Subscription } from 'rxjs';
import { VentaComponent } from '../venta/venta.component'; 
import { MessengerService } from '../../../../../services/messenger.service'; 
import { Guid } from 'guid-typescript'; 
import { VentaService } from '../../../../../services/venta.service';
import { VentaReporteComponent } from '../venta-reporte/venta-reporte.component';
import { MovTropaService } from '../../../../../services/mov-tropa.service';
import { MovTropa } from '../../../../../models/mov-tropa';
import Utils, { MovTropaTipo } from '../../../../../utils';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { getRoute, Routing } from '../../../themes/sigef/routing';
import { VentaRentabilidadComponent } from '../venta-rentabilidad/venta-rentabilidad.component';

@Component({
	selector: 'kt-venta-crud',
	templateUrl: './venta-crud.component.html',
	styleUrls: ['./venta-crud.component.scss'],
	providers: [VentaService, MovTropaService]
})
export class VentaCrudComponent extends EditableForm<Venta> implements OnInit, OnDestroy, IOpenInDialog {
	openInDialog(model: any, extras: any): void {
		throw new Error('Method not implemented.');
	}
	
	private e: any;
	private sub: Subscription;

	public confirmacion: boolean = false;
	public hacienda: MovTropa[];

	@Input() set datos(model: any) {
		if (model && model.items) {
			this.hacienda = this.movTropaMapper(model.items);
			this.model.Hacienda = this.hacienda;
			this.cd.detectChanges();
		}
	}

	saveResultDialogModel(model: any): void {

	}

	editResultDialog(formParent: IParentOpenInDialog): boolean {
		return false;
	}

	saveResultDialog(formParent: IParentOpenInDialog): boolean {
		if (formParent) {
			if (!this.runValidaciones()) {
				return false;
			}
			this.setModelPropsByControls();
			formParent.saveResultDialogModel(this.model);
		}
		return true;
	} 
 
	@ViewChild(VentaComponent, { static: true }) private Venta: VentaComponent; 
	@ViewChild(VentaReporteComponent, { static: true }) VentaReporte: VentaReporteComponent;
	@ViewChild(VentaRentabilidadComponent, { static: true }) private VentaRentabilidad: VentaRentabilidadComponent; 

	constructor(cd: ChangeDetectorRef,
		private router: Router,
		private movTropaService: MovTropaService,
		messengerService: MessengerService,
		ventaService: VentaService,
		snackBar: MatSnackBar,
		quickPanelService: QuickPanelService) {
		super(cd, ventaService, snackBar, messengerService, quickPanelService);
	}

	ngOnInit() {
		 

		this.sub = this.getMessengerInstance().messageBroadcasted$.subscribe(data => {
			if (data.Reason && data.Reason === 'guardar_venta_reporte') {
				this.confirmacion = false;
				super.onSave(this.e, false);
			}
			if (data.Reason && data.Reason === 'cancelar_venta_reporte') {
				this.confirmacion = false; // con solo ocultar el reporte ya volvemos al abm
			}
			if (data === 'MOV_SAVED' || data === 'Refresh') { 
				this.showVenta(this.model.VentaID);
			}
			this.cd.detectChanges();
		});
	}

	movTropaMapper(items: any[]): MovTropa[] {
		let hacienda: MovTropa[] = [];

		let grupo: Guid = Guid.create();

		for (const h of items) {
			let m: MovTropa = new MovTropa();

			m.AumentoDiarioEstKg = h.tropa.AumentoDiarioEstKg;
			m.Cantidad = h.cambioModel.Cantidad;
			m.CategoriaGanadoNombre = h.tropa.CategoriaGanadoNombre;
			m.CorralNombre = ''; // no viene
			m.CorralNumero = 0; // no viene
			m.CorralRef = h.tropa.CorralID;
			m.DesbastePorc = h.cambioModel.DesbastePorc;
			m.DietaNombre = h.cambioModel.DietaOrigen;
			m.FechaMovimiento = h.cambioModel.Fecha;
			m.FechaCreacion = new Date();
			m.MetaDiaria = 0; // no viene
			m.MovTropaID = Guid.create();
			m.MovTropaTipoRef = MovTropaTipo.Venta;
			m.MovTropaTipoNombre = 'Venta';
			m.MultiplicaPor = -1;
			m.Observaciones = h.cambioModel.Observaciones;
			m.PesoCabezaKg = h.cambioModel.PesoCabezaKg;
			m.PrecioVenta = h.cambioModel.PrecioVenta;
			m.TropaCategoriaGanadoRef = Guid.parse(h.tropa.TropaCategoriaGanadoRef.value);
			m.Grupo = grupo;
			m.FechaIngreso = h.tropa.FechaIngreso; // para calcular los DiasCorral
			m.TropaNombre = h.tropa.TropaNombre;
			m.ClienteNombre = h.tropa.Hotelero;

			hacienda.push(m);
		}

		return hacienda;
	}

	ngOnDestroy() {
		if (this.sub) { this.sub.unsubscribe(); }
	}

	onSave(e, repeat: boolean = false): void {
		this.setModelPropsByControls();

		if (this.runValidaciones() == false) { return; }

		if (this.model.VentaID.isEmpty()) {
			this.e = e;
			// si es nueva mostrar un reporte antes a modo de 'Confirmacion'
			let extras: any = {
				CompradorNombre: this.model.CompradorNombre || '',
				VentaDestinoNombre: this.model.VentaDestinoNombre || '',
				Confirmacion: true
			}

			this.VentaReporte.cargarVenta(this.model, extras);
			this.confirmacion = true;
			this.cd.detectChanges();
		}
		else {
			// en la edicion guardar de una
			super.onSave(e, repeat);
		}
	}

	onCancel(e): void {
		if (this.isNewModel) {
			this.MessageDialog.showDialog('Por favor confirme', 'Esta acción cancelará la venta. ¿Está seguro que desea continuar?',
				['SI', 'NO'], 'CancelVenta', this);
		} else {
			super.onCancel(e);
		}
	}

	onConfirmResultMessageDialog(id: string, ok: boolean) {
		if (ok && id === 'CancelVenta') {
			this.router.navigate([getRoute(Routing.HACIENDA_VENTAS_LISTADO)]);
		}
	}

	ngAfterViewInit() { 
		this.Venta.lstControls.forEach(c => this.lstControls.push(c));
		this.VentaRentabilidad.lstControls.forEach(c => this.lstControls.push(c));
	}

	onInputValueChange(newValue, labelName): void {
		//console.log(newValue);
		//console.log(this.model.CategoriaGanados);
	}

	setModelPropsByControls(): void { 
		this.Venta.setModelValues(this.model);
		this.VentaRentabilidad.setModelValues(this.model);
		this.updateModelFechaMovimiento();
	}

	getNewModel(): Venta { return new Venta(); };
	getKeyModel(obj: Venta): Guid { return obj.VentaID; };

	vista: string = 'venta';
	cambiarVista(nuevaVista: string): void {
		this.vista = nuevaVista;
	}

	runCustomValidaciones(): boolean {
		if (Utils.getDateWithTime(this.Venta.Fecha.getFecha(), this.Venta.Hora.getHora()) > new Date()) {
			this.MessageDialog.showAtencionDialog('La fecha y hora del movimiento no puede ser mayor a la actual.');
			return false;
		}
		return true;
	}

	showVenta(ventaId: Guid) {
		this.getAndSetModel(ventaId);
	}

	setModel(obj: Venta, parentCarga: EditableFormList<Venta> = undefined) { 
		super.setModel(obj, parentCarga);

		if (obj && obj.VentaID) { 
			this.hacienda = obj.Hacienda;
		}	
	}

	updateModelFechaMovimiento() {
		for (let u of this.model.Hacienda) {
			u.FechaMovimiento = this.model.Fecha;			
		}		
	};
}

