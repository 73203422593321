<kendo-grid tabindex="{{tabindex}}"
			[sortable]="true"
			[navigable]="true"
			[data]="gridView"
			[loading]="buscando"
			[groupable]="false"
			[group]="groups"
			#grid
			class="mov-list"
			[isDetailExpanded]="expandedRow"
			[resizable]="true"
			(edit)="showABM($event)"
			(remove)="removeHandler($event)">
	<kendo-grid-messages groupPanelEmpty="Arrastre una cabecera de columna aquí para agrupar"
						 noRecords="No hay movimientos para los filtros seleccionados">
	</kendo-grid-messages>
	<!--<kendo-grid-column title="Fecha" width="90">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{dataItem.FechaMovimiento | parseDateHour }}</span>
		</ng-template>
	</kendo-grid-column>-->
	<kendo-grid-column title="Tropa" *ngIf="mostrarTropaCol" width="140">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{dataItem.CodigoTropa | parseCodigoTropa}} ({{dataItem.TropaNombre}})</span>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column title="Propietario" *ngIf="mostrarClienteCol" width="80">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{dataItem.ClienteNombre ? dataItem.ClienteNombre : 'Sin Propietario'}}</span>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column title="Tipo Movimiento" width="130">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span class="{{dataItem.MultiplicaPor | parseIconMovimiento }}">&nbsp;&nbsp;</span>
			<a class="kt-link" title="Ver resumen de movimiento" href="javascript:;" (click)="verMovimiento(dataItem)">
				<span>{{dataItem.MovTropaTipoNombre}}</span>
			</a>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column field="CategoriaGanadoNombre" title="Categoría" width="60"></kendo-grid-column>
	<kendo-grid-column title="Corral" width="100">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{dataItem.CorralNumero | parseCorralNumero}} ({{dataItem.CorralNombre}})</span>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column class="text-right" title="Cant. Cab." width="50">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{ dataItem.Cantidad | parseNumber : true  }} cab.</span>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column class="text-right" title="Desbaste" width="50">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span *ngIf="mostrarPeso(dataItem)">{{ dataItem.DesbastePorc | parseNumberPorc }}</span>
			<span *ngIf="!mostrarPeso(dataItem)">--</span>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column class="text-right" title="Peso Cab." width="50">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span *ngIf="mostrarPeso(dataItem)">{{ dataItem.PesoCabezaKg - (dataItem.PesoCabezaKg * dataItem.DesbastePorc) | parseNumberKg }}</span>
			<span *ngIf="!mostrarPeso(dataItem)">--</span>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column field="Grupo" [hidden]="true">
		<ng-template kendoGridGroupHeaderTemplate let-group="group" let-aggregates="aggregates">
			<span title="Estas operaciones se hicieron en conjunto">
				{{getGroupTitle(group.value)}}
			</span>
		</ng-template>
	</kendo-grid-column>

	<ng-template kendoGridDetailTemplate let-dataItem let-rowIndex [kendoGridDetailTemplateShowIf]="showIfHasObs">
		<div *ngIf="dataItem.Observaciones">
			Observaciones: <span>{{dataItem.Observaciones}}</span>
		</div>
	</ng-template>

	<kendo-grid-command-column *ngIf="!readonly" class="k-grid-ignore-click" title="Acciones" width="65">
		<ng-template kendoGridCellTemplate let-dataItem>
			<kt-grid-actions [eliminar]="ultimoEnGrupo(dataItem)"
							 [editar]="false"></kt-grid-actions>
		</ng-template>
	</kendo-grid-command-column>
</kendo-grid>
<my-message-dialog></my-message-dialog>
