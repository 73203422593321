import { DTOInterface } from "../interfaces/dto.interface";
import { DietaAlimento } from "./dieta-alimento";
import { DietaPlanificacionDescarga } from "./dieta-planificacion-descarga";

export class Dieta implements DTOInterface { 
	DietaID: number;
	DietaNombre: string;
	DietaDescripcion: string;
	Activo: boolean;
	ColorRef: number;
	ColorPrincipal: string;
	ColorFuente: string;
	ColorFondo: string;

	Alimentos: DietaAlimento[];
	PlanificacionDescargas: DietaPlanificacionDescarga[];

	private defaultDescarga: DietaPlanificacionDescarga;

	constructor() {
		this.clear();

		this.defaultDescarga.Orden = 1;
		this.defaultDescarga.Porcentaje = 100;

		this.PlanificacionDescargas.push(this.defaultDescarga);
	}

	clear(): void {
		this.DietaID = -1;
		this.DietaNombre = '';
		this.DietaDescripcion = '';
		this.Activo = true;
		this.ColorRef = -1;
		this.ColorPrincipal = undefined;
		this.ColorFuente = undefined;
		this.ColorFondo = undefined;

		this.Alimentos = [];
		this.PlanificacionDescargas = [];

		this.defaultDescarga = new DietaPlanificacionDescarga();
	}
}
