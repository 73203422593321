import { Component, OnInit } from '@angular/core';
import { Tropa } from '../../../../../models/tropa'; 
import { MessengerService } from '../../../../../services/messenger.service';
import Utils, { Operacion } from '../../../../../utils';

@Component({
	selector: 'kt-tropa-reporte',
	templateUrl: './tropa-reporte.component.html',
	styleUrls: ['./tropa-reporte.component.scss']
})
export class TropaReporteComponent implements OnInit {
	public tropa: Tropa; 

	public cliente: string = '';
	public proveedor: string = '';
	public origen: string = '';
	public color: string = '';

	public totalCabezas: number = 0;
	public totalCompra: number = 0;
	public totalPeso: number = 0;
	public totalPesoNeto: number = 0;
	public desbaste: number = 0;

	public guardando: boolean = false;

	public loading: boolean = true;

	cargarTropa(model: any, extras: any): void { 
		this.tropa = model;

		this.cliente = extras.ClienteNombre ? extras.ClienteNombre : '';
		this.proveedor = extras.ProveedorNombre ? extras.ProveedorNombre : '';
		this.origen = extras.OrigenNombre ? extras.OrigenNombre : '';
		this.color = extras.ColorPrincipal ? extras.ColorPrincipal : '#FFF';

		this.totalCabezas = 0;
		this.totalCompra = 0;
		this.totalPeso = 0;
		this.totalPesoNeto = 0;
		this.desbaste = 0;

		for (const cc of this.tropa.CategoriaGanados) {
			let peso = this.operacionRedondeada(cc.Cantidad, cc.PesoCabezaKg, Operacion.Multiplicacion);
			this.totalPeso += peso;
			this.totalPesoNeto += this.operacionRedondeada(cc.Cantidad, (Math.round(cc.PesoCabezaKg) - (Math.round(cc.PesoCabezaKg) * cc.DesbastePorc)), Operacion.Multiplicacion);
			this.totalCabezas += cc.Cantidad; 
			this.totalCompra += (parseFloat(cc.PrecioCompra.toFixed(2)) * cc.Cantidad);
			this.desbaste += cc.DesbastePorc;
		}

		this.desbaste /= this.tropa.CategoriaGanados.length;

		this.guardando = false;
		this.loading = false;
	}

	constructor(private messengerService: MessengerService) { }

	ngOnInit() {
		this.loading = true;
	}

	confirmHandler(e): void {
		this.guardando = true;
		this.messengerService.broadcast('TropaReporteComponent', 'TropaCrud', 'guardar_tropa_reporte', {});
	}

	printHandler(e): void {
		window.print();
	}

	editHandler(e): void {
		this.messengerService.broadcast('TropaReporteComponent', 'TropaCrud', 'cancelar_tropa_reporte', {});
	}

	operacionRedondeada(t1: number, t2: number, op: Operacion): number {
		return Utils.operacionRedondeada(t1, t2, op);
	}

	round(t1: number): number {
		return Math.round(t1);
	}
}
