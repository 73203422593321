import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'; 
import { CommonControl } from '../common-controls.interface';
import Utils from '../../../../../utils';
import { TimePickerComponent } from '@progress/kendo-angular-dateinputs';

@Component({
	selector: 'my-col-timepicker',
	templateUrl: './my-col-timepicker.component.html',
	styleUrls: ['./my-col-timepicker.component.scss']
})
export class MyColTimePickerComponent extends CommonControl implements OnInit, AfterViewInit {
	@Input() labelVisible: boolean = true;
	@Input() extraClass: string = 'col';

	@ViewChild('timeInput', { static: true }) MyControl: TimePickerComponent;

	constructor() {
		super();
		this.Form = new UntypedFormGroup({
			'Time': new UntypedFormControl(undefined, Validators.required)
		});
	}

	ngOnInit() {
		this.Form.patchValue({ Time: new Date() });
	}

	ngAfterViewInit(): void {
		if (this.focusOnInit) {
			this.focus();
		}
	} 

	clearHora() {
		this.Form.patchValue({ Time: undefined });
	}

	setHora(value: Date) {
		if (Utils.isDateValid(value)) {
			this.Form.patchValue({ Time: new Date(value) });
		} else {
			this.Form.patchValue({ Time: new Date() });
		}
	}

	getHora(): Date {
		return this.Form.value['Time'];
	}

	onValueChange(e: any) {
		if (!this.parent || !this.parent.onTimeValueChanged) return;
		this.parent.onTimeValueChanged(e, this.labelName);
	}

	focus() {
		this.MyControl.focus();
	}
}
