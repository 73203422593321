import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Dieta } from '../../../../../models/dieta';
import { AlimentoService } from '../../../../../services/alimento.service';
import { NameValueListExtend } from '../../../../../interfaces/nvl.interface';
import { DataBindingDirective, GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Alimento } from '../../../../../models/alimento';
import { DietaAlimento } from '../../../../../models/dieta-alimento';
import { MyMessageDialogComponent } from '../../common_controls';
import { IParentConfirmResultMessage } from '../../common_controls/message-dialog/my-message-dialog.component';
import Utils from '../../../../../utils';
import { debug } from 'console';
import { utils } from 'protractor';

const createFormGroup = dataItem => new UntypedFormGroup({
	'AlimentoRef': new UntypedFormControl(dataItem.AlimentoRef, Validators.compose([Validators.required])),
	'AlimentoPorcMS': new UntypedFormControl(dataItem.AlimentoPorcMS, Validators.compose([Validators.required])),
	'Orden': new UntypedFormControl(dataItem.Orden, Validators.compose([Validators.required]))
});

@Component({
	selector: 'kt-dieta-alimento',
	templateUrl: './dieta-alimento.component.html',
	styleUrls: ['./dieta-alimento.component.scss'],
	providers: [AlimentoService]
})
export class DietaAlimentoComponent implements OnInit, IParentConfirmResultMessage {
	public gridData: any[] = [];

	@ViewChild(GridComponent, { static: true }) private grid;
	@ViewChild(DataBindingDirective, { static: true }) dataBinding: DataBindingDirective;
	@ViewChild(MyMessageDialogComponent, { static: true }) public MessageDialog: MyMessageDialogComponent;

	public alimentos: NameValueListExtend[] = [];
	public alimentosData: Alimento[] = [];
	public ingredientes: Alimento[] = [];

	public formGroup: UntypedFormGroup;
	public editedRowIndex: number;
	public onAddRow: boolean;
	public dataItem: DietaAlimento;
	public deletedItem: DietaAlimento;

	public totalMateriaSeca: number;
	public totalMS: number;
	public totalGasto: number;
	public totalMCal: number;
	public totalProteina: number;
	public totalCalcio: number;
	public totalFosforo: number;
	public totalFDN: number;
	public totalIncTalCual: number;
	public totalTalCual: number;

	public vistaDetallada: boolean;

	private _dieta: Dieta;

	private STEP_ALIMENTO: number = 0.0025;

	@Input() set dieta(dieta: Dieta) {
		this.gridData = [];
		this.alimentos = [];
		this.alimentosData = [];
		this._dieta = dieta;
		if (this._dieta) {
			this.cargarLista();
		}
	}

	resetModel(): void {

	}

	handleAlimentoFilter(value) {
		this.ingredientes = this.alimentosData.filter((s) => s.AlimentoNombre.toLowerCase().indexOf(value.toLowerCase()) !== -1);
	}

	constructor(private alimentoService: AlimentoService, private cd: ChangeDetectorRef) { }

	ngOnInit() {
		this.vistaDetallada = true;
		this.totalMateriaSeca = 0;
	}

	cargarLista(): void {
		if (!this._dieta) { return; }

		this.grid.loading = true;
		this.gridData = [];
		this.alimentoService.getList().subscribe(
			data => {
				this.alimentosData = data;

				// armar un lookup con el listado de dietas para no hacer otro req
				this.ingredientes = [];
				for (const a of data) {
					if (a.Activo) {
						this.ingredientes.push(a);
					}
				}

				let _data: any[] = this._dieta.Alimentos;

				this.gridData = _data;
				this.dataBinding.skip = 0;
				this.grid.loading = false;
			},
			error => {
				this.MessageDialog.showExceptionDialog("No se pudo obtener alimentos de la dieta.", error);
			},
			() => {
				this.totalIncTalCual = this.calculaTotales();
				this.resetGrid();
				this.cd.detectChanges();
			}
		);
	}

	alimento(id: number): any {
		return this.alimentosData.find(x => x.AlimentoID === id);
	}

	onKeydown(sender: any, e: any) {
		if (e.key === 'Escape') {
			e.preventDefault();
			this.closeEditor();
			return;
		}

		if (e.key === 'Enter') {
			e.preventDefault();
			this.saveHandler({ sender: sender, rowIndex: this.editedRowIndex, formGroup: this.formGroup, isNew: this.onAddRow });
			return;
		}
	}

	calculaTotales(): number {
		this.totalTalCual = 0;
		this.totalIncTalCual = 0;
		this.totalMS = 0;
		this.totalGasto = 0;
		this.totalMCal = 0;
		this.totalProteina = 0;
		this.totalCalcio = 0;
		this.totalFosforo = 0;
		this.totalFDN = 0;

		if (this.alimentosData) {
			for (const d of this.gridData) {
				var a = this.alimentosData.find(x => x.AlimentoID === d.AlimentoRef);
				if (a) {
					if (a.Agua) {
						this.totalTalCual += (+d.AlimentoPorcMS);
					}
					else {
						this.totalTalCual += (+d.AlimentoPorcMS / +a.MateriaSecaPorc);
						this.totalMS += (+d.AlimentoPorcMS);
					}

					this.totalGasto += (+this.gasto(a.AlimentoID, d.AlimentoPorcMS));
					this.totalMCal += (+this.mcal(a.AlimentoID, d.AlimentoPorcMS));
					this.totalProteina += (+this.proteina(a.AlimentoID, d.AlimentoPorcMS));
					this.totalCalcio += (+this.calcio(a.AlimentoID, d.AlimentoPorcMS));
					this.totalFosforo += (+this.fosforo(a.AlimentoID, d.AlimentoPorcMS));
					this.totalFDN += (+this.fdn(a.AlimentoID, d.AlimentoPorcMS));
				}
			}
		}

		//this.totalMateriaSeca = parseFloat(((this.totalMS / this.totalTalCual) * 100).toFixed(0));
		this.totalMateriaSeca = this.totalMS / this.totalTalCual;

		// calcular inc.Talcual por alimento ahora que se tiene el total
		for (const d of this.gridData) {
			var a = this.alimentosData.find(x => x.AlimentoID === d.AlimentoRef);
			if (a) {
				if (a.Agua) {
					this.totalIncTalCual += d.AlimentoPorcMS / this.totalTalCual;
				} else {
					this.totalIncTalCual += (d.AlimentoPorcMS / a.MateriaSecaPorc) / this.totalTalCual;
				}
			}
		}
		return this.totalTalCual;
	}

	gasto(id: number, porc: number): number {
		var a = this.alimentosData.find(x => x.AlimentoID === id);
		if (a) {
			if (a.MateriaSecaPorc != 0) {
				return (a.PrecioXkg * porc) / a.MateriaSecaPorc;
			}
		}

		return 0;
	}

	talcual(id: number, porc: number): number {
		let tc;

		var a = this.alimentosData.find(x => x.AlimentoID === id);

		if (!a)
			return;

		if (a.Agua) {
			tc = porc / 1;
		} else {
			tc = porc / a.MateriaSecaPorc;
		}

		let ttc = (tc / this.totalTalCual) * 100;
		return Utils.checkNaNGetZero(ttc);
	}

	mcal(id: number, porc: number): number {
		var a = this.alimentosData.find(x => x.AlimentoID === id);
		if (a) {
			return a.MegaCalXkgMS * porc
		}

		return 0;
	}

	proteina(id: number, porc: number): number {
		var a = this.alimentosData.find(x => x.AlimentoID === id);
		if (a) {
			return a.ProtPorcXkgMS * porc
		}

		return 0;
	}

	calcio(id: number, porc: number): number {
		var a = this.alimentosData.find(x => x.AlimentoID === id);
		if (a) {
			return a.CalcioPorcXkgMS * porc
		}

		return 0;
	}

	fosforo(id: number, porc: number): number {
		var a = this.alimentosData.find(x => x.AlimentoID === id);
		if (a) {
			return a.FosforoPorcXkgMS * porc
		}

		return 0;
	}

	fdn(id: number, porc: number): number {
		var a = this.alimentosData.find(x => x.AlimentoID === id);
		if (a) {
			return a.FDNPorcXkgMS * porc
		}

		return 0;
	}

	onPorcChange(event: any, dataItem: any): boolean {
		let validateInput: boolean = false;

		validateInput = (event.key == "Enter" || event.type == "focusout");

		if (validateInput) {
			dataItem.AlimentoPorcMS = (Utils.parseDecimal(event.target.value) / 100);

			if (dataItem.AlimentoPorcMS == NaN)
				dataItem.AlmentoPorcMS = 0;

			this.dataItem = dataItem;
			this.actualizar();
		}

		return true;
	}

	cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
		if (this.onAddRow) { return; }

		this.closeEditor(sender);
		this.formGroup = createFormGroup(dataItem);
		this.editedRowIndex = rowIndex;
		sender.editCell(rowIndex, columnIndex, this.formGroup);
		this.cd.detectChanges();
	}

	closeEditor(grid = this.grid, rowIndex = this.editedRowIndex) {
		grid.closeRow(rowIndex);
		this.editedRowIndex = undefined;
		this.formGroup = undefined;
		this.dataItem = undefined;
		this.deletedItem = undefined;
		this.onAddRow = false;
		this.cd.detectChanges();
	}

	addHandler({ sender, formGroup }) {
		sender = sender || this.grid;

		this.closeEditor(sender);

		let proximoOrdenNumero = 0;
		if (this.gridData) {
			let r = this.gridData.filter(o => o.Agua == false);
			if (r.length > 0) {
				proximoOrdenNumero = Math.max.apply(Math, r.map(function (o) { return o.Orden; })) || 1;
			}
		}
		proximoOrdenNumero++;

		this.formGroup = formGroup || createFormGroup({
			'AlimentoRef': 0,
			'AlimentoPorcMS': 0,
			'Orden': proximoOrdenNumero,
			'Agua': false
		});

		sender.addRow(this.formGroup);
		this.onAddRow = true;

		this.cd.detectChanges();
	}

	cancelHandler({ sender, rowIndex }) {
		this.closeEditor(sender, rowIndex);
	}

	saveHandler({ sender, rowIndex, formGroup, isNew }) {
		if (formGroup.valid) {
			if (isNew) {
				this.create({ sender, rowIndex, formGroup, isNew });
			} else {
				this.update({ sender, rowIndex, formGroup, isNew });
			}
		} else {
			sender.editRow(rowIndex);
			this.cd.detectChanges();
		}
	}

	actualizar(): void {
		if (this.dataItem) {
			let ms: number = +(this.dataItem.AlimentoPorcMS);
			if (ms > 1) { this.dataItem.AlimentoPorcMS = 1; }
			if (ms < 0) { this.dataItem.AlimentoPorcMS = 0; }

			this.dataItem.AlimentoPorcMS = parseFloat((+this.dataItem.AlimentoPorcMS).toFixed(4));
		}

		this.resetGrid();
	}

	resetGrid(): void {
		this.closeEditor();

		this.grid.loading = true;

		this.gridData.sort(function (a, b) {
			// ordenar por Orden
			if (a.Orden > b.Orden) return 1;
			if (a.Orden < b.Orden) return -1;

			// y si es igual, ordenar por Nombre de Alimento
			if (a.AlimentoNombre > b.AlimentoNombre) return -1;
			if (a.AlimentoNombre < b.AlimentoNombre) return 1;

		});

		this.calculaTotales();
		this.grid.loading = false;

		this.cd.detectChanges();
	}

	getAlimentoEstado(alimentoID: number): boolean {
		let alimento = this.alimentosData.find(e => e.AlimentoID == alimentoID);

		if (alimento) {
			return alimento.Activo;
		}

		return false;
	}

	checkDuplicado(edit: boolean, data: DietaAlimento): boolean {
		// contar las ocurrencias de alimentoref, si es nuevo tiene que haber 0, si es edicion, 1
		let c: number = this._dieta.Alimentos.filter(a => a.AlimentoRef === data.AlimentoRef).length;

		if (edit && c === 1) { return true; }

		return c > 0;
	}

	update({ sender, rowIndex, formGroup, isNew }): void {
		if (this.checkDuplicado(true, formGroup.value)) {
			this.MessageDialog.showAtencionDialog("Este producto ya ha sido agregado. Sólo se modificará el número de Orden.");
			this.dataItem.Orden = formGroup.value['Orden'];
		} else {
			this.dataItem.Activo = this.getAlimentoEstado(formGroup.value.AlimentoRef);
			this.assignValues(this.dataItem, formGroup.value);
		}
		this.actualizar();
	}

	create({ sender, rowIndex, formGroup, isNew }): void {
		if (this.checkDuplicado(false, formGroup.value)) {
			this.MessageDialog.showAtencionDialog("Este producto ya ha sido agregado!");
		} else {
			this.dataItem = new DietaAlimento();
			this.dataItem.Activo = true;
			this.dataItem.Agua = this.esAgua(formGroup.value);
			this.assignValues(this.dataItem, formGroup.value);
			this._dieta.Alimentos.push(this.dataItem);
		}
		this.actualizar();
	}

	esAgua(data: DietaAlimento): boolean {
		let alimento = this.alimentosData.find(e => e.AlimentoID == data.AlimentoRef);

		if (alimento && alimento.Agua) {
			return true;
		}

		return false;
	}

	menosMS(dataItem: any): void {
		this.dataItem = dataItem;
		this.dataItem.AlimentoPorcMS = +this.dataItem.AlimentoPorcMS;
		this.dataItem.AlimentoPorcMS -= (+this.STEP_ALIMENTO);
		this.actualizar();
	}

	masMS(dataItem: any): void {
		this.dataItem = dataItem;
		this.dataItem.AlimentoPorcMS = +this.dataItem.AlimentoPorcMS;
		this.dataItem.AlimentoPorcMS += (+this.STEP_ALIMENTO);
		this.actualizar();
	}

	editHandler({ sender, colIndex, rowIndex, dataItem }) {
		this.closeEditor(sender);

		this.dataItem = dataItem;
		this.formGroup = createFormGroup(dataItem);

		this.editedRowIndex = rowIndex;

		sender.editRow(rowIndex, this.formGroup);

		setTimeout(() => {
			colIndex = colIndex || 0;

			let element: HTMLElement = (<HTMLElement>document.querySelector(`.k-grid-edit-row > td:nth-child(${colIndex + 1}) input`));

			if (element) {
				element.focus();
			}
		});
	}

	editClick({ dataItem, rowIndex, columnIndex }: any): void {
		this.editHandler({
			dataItem: dataItem,
			rowIndex: rowIndex,
			colIndex: columnIndex,
			sender: this.grid
		});
	}

	removeHandler({ sender, dataItem }) {
		sender.cancelCell();

		// quitar de la lista
		this.deletedItem = dataItem;
		this.MessageDialog.showDialog("Por favor confirme", "Esta acción eliminará el registro. ¿Está seguro que desea continuar?",
			["SI", "NO"], "DeleteModel", this);
	}

	onConfirmResultMessageDialog(id: string, ok: boolean): void {
		if (ok) {
			if (id === "DeleteModel") {
				let alimento = this.gridData.find(r => r.AlimentoRef === this.deletedItem.AlimentoRef);
				this.gridData.splice(this.gridData.indexOf(alimento), 1);
			}
		}

		this.resetGrid();
	}

	assignValues(target: any, source: any): void {
		Object.assign(target, source);
	}

	rowCallback = (context: RowClassArgs) => {
		return { inactiva: !context.dataItem.Activo };
	}

}
