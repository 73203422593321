<div class="p-3">
	<div class="row row-sigef">
		<my-col-dropdownlist #Producto [key]="'Producto'" [parent]="componente" [tabindex]="tabindex" [labelName]="'Producto'" [required]="true"></my-col-dropdownlist>
	</div>

	<div class="row row-sigef">
		<my-col-dropdownlist #FormatoCompra [key]="'Formato'" [parent]="componente" [tabindex]="tabindex + 1" [labelName]="'Formato de Compra'" [required]="true"></my-col-dropdownlist>
	</div>

	<div class="row row-sigef">
		<my-col-input #Cantidad [tabindex]="tabindex + 2" #CantReal [hint]="hint" [mask]="'#'" [decimals]="0" [labelName]="'Cantidad'" [allowCero]="false" [required]="true"></my-col-input>
	</div> 

	<div class="row row-sigef">
		<my-col-input #Precio [tabindex]="tabindex + 3" [mask]="'c2'" [parent]="componente" [labelName]="'Precio Total'" [required]="true" [allowCero]="false"></my-col-input>
	</div>

	<my-row-expand class="row row-sigef pt-3" [tabindex]="tabindex + 4" [title]="Observaciones" [hint]="'Seleccione esta opción para incluir una aclaración adicional'" [labelName]="'Observaciones'" [expanded]="adicionalesVisible" (onExpand)="adicionalesVisible=$event"></my-row-expand>
	<div class="row" [ngClass]="{'d-none': !adicionalesVisible}">
		<my-col-textarea [tabindex]="tabindex + 5" [labelName]="'Observaciones'" [rows]="2" [labelVisible]="false" class="w-100"></my-col-textarea>
	</div>

	<div class="ml-1 mr-3 pl-3 mt-3 row" [ngClass]="{'d-none': openedInDialog}">
		<my-group-save-buttons [tabindex]="tabindex + 6" class="w-100" [saving]="saving" [targetName]="'Producto'"
							   (onSave)="onProductoSave($event)" (onCancel)="onProductoCancel($event)"></my-group-save-buttons>
	</div>
</div>
<my-message-dialog></my-message-dialog>
