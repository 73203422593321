import { Component, ChangeDetectorRef } from '@angular/core'; 
import { HelpersTropaMovimiento } from '../helpers-tropa';
import { TropaService } from '../../../../../services/tropa.service';
import { MovTropaService } from '../../../../../services/mov-tropa.service';
import { CorralService } from '../../../../../services/corral.service';
import { CategoriaGanadoService } from '../../../../../services/categoria-ganado.service'; 
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessengerService } from '../../../../../services/messenger.service'; 
import { QuickPanelService } from '../../../../../services/quick-panel.service';

@Component({
	selector: 'kt-tropa-movimiento-popup',
	templateUrl: './tropa-movimiento-popup.component.html',
	styleUrls: ['./tropa-movimiento-popup.component.scss'],
	providers: [CorralService, TropaService, MovTropaService, CategoriaGanadoService]
})
export class TropaMovimientoPopupComponent extends HelpersTropaMovimiento {

	constructor(corralService: CorralService,
		tropaService: TropaService,
		movTropaService: MovTropaService,
		categoriaGanadoService: CategoriaGanadoService,
		cd: ChangeDetectorRef,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		quickPanelService: QuickPanelService) {
		super(corralService, tropaService, movTropaService, categoriaGanadoService, cd, snackBar, messengerService, quickPanelService);
	}
}
