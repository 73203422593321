import { DTOInterface } from "../interfaces/dto.interface";
import { Guid } from "guid-typescript";

export class MovTropaCambio implements DTOInterface {   
	CategoriaGanadoRef: number;
	TropaCategoriaGanadoRef: Guid;
	TropaRef: Guid;
	CorralRef: number;
	FechaMovimiento: Date;
	Cantidad: number;
	Observaciones: string; 
	FechaCreacion: Date;
	PesoCabezaKg: number;
	DesbastePorc: number;

	GenerarPesada: boolean;

	constructor() {
		this.clear();
	}

	clear(): void {
		this.CategoriaGanadoRef = undefined;
		this.TropaCategoriaGanadoRef = undefined;
		this.TropaRef = undefined;
		this.CorralRef = -1;
		this.FechaMovimiento = new Date();
		this.Cantidad = 0;
		this.Observaciones = '';
		this.FechaCreacion = new Date();

		this.GenerarPesada = false;
	}
}
