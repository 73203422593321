import { Injectable } from '@angular/core'; 
import { ApiResponse } from './interfaces/api-response.interface';
import { MyMessageDialogComponent } from './app/views/partials/common_controls'; 
import { NameValueListExtend } from './interfaces/nvl.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { UsuarioEmpresa } from './models/usuario-empresa';

// Enum de tablas no parametrizables
export enum Roles {
	Administrador = 1,
	Mixero,
	Hotelero,
	Nutricionista,
	Paisano,
	Veterinario
}

export enum ProveedorTipo {
	ProductosAlimenticios = 1,
	ProductosSanitarios,
	Hacienda
}

export enum MovProductoSanitarioTipo {
	EntradaCompra = 1,
	EntradaAjuste,
	SalidaAjuste,
	SalidaUso = 4
}

export enum MovProductoSanitarioTipoAgrupador {
	Compra = 1,
	Ajuste,
	Uso
}

export const PATIO_COMIDAS: number = 1;

export enum MovAlimentoTipo {
	EntradaCompra = 1,
	EntradaCosecha,
	EntradaTraslado,
	EntradaAjuste,
	SalidaConsumo = 5,
	SalidaTraslado,
	SalidaAjuste
}

export enum MovAlimentoTipoAgrupador {
	Compra = 1,
	Cosecha,
	Traslado,
	Ajuste,
	Consumo
}

// Estos Tipo de Movimiento son los que luego se dividen (o no) en Positivo/Negativo, manteniendo una lista única.
// Los métodos de buscar Movimientos por Tipo, deben usar este ID, ya que al buscar, e.j. Cambio Corral, se buscará por los + y -
export enum MovTipo {
	Compra = 1,
	Paricion,
	Ajuste,
	OperacionInterna,
	Venta,
	Muerte = 6,
	CambioCorral,
	Pesada,
	CambioCategoria = 9
}

// Este ID de TropaTipo es el que se debe persistir realmente en la base
export enum MovTropaTipo {
	Compra = 1,
	Paricion,
	AjustePositivo,
	OperacionInternaPositiva = 5,
	Venta,
	Muerte = 7,
	AjusteNegativo = 9,
	OperacionInternaNegativa,
	CambioCorralPositivo = 12,
	CambioCorralNegativo,
	Pesada = 14,
	CambioCategoriaPositivo = 15,
	CambioCategoriaNegativo = 16
};

export const enum Fecha {
	Desde = "Desde",
	Hasta = "Hasta"
}

export const enum Operacion {
	Suma = 1,
	Resta,
	Division,
	Multiplicacion
}

export enum AuthenticationProvider {
	SIGEF = 0,
	Google = 1,
	Facebook = 2
}

@Injectable()
export default class Utils {
	static fechaMenorA(fecha1: Date, fecha2: Date = new Date()): boolean {
		return ((fecha1.getFullYear() <= fecha2.getFullYear()) && (fecha1.getMonth() <= fecha2.getMonth()) && (fecha1.getDate() < fecha2.getDate()));
	}

	static fechaSonIguales(fecha1: Date, fecha2: Date = new Date()): boolean {
		return ((fecha1.getFullYear() === fecha2.getFullYear()) && (fecha1.getMonth() === fecha2.getMonth()) && (fecha1.getDate() === fecha2.getDate()));
	}

	static nameValueListToIntegerList(lst: NameValueListExtend[]): number[] {
		let n: number[] = [];
		for (const itm of lst) {
			n.push(itm.Key);
		}
		return n;
	}

	static operacionRedondeada(t1: number, t2: number, op: Operacion): number {
		if (t2 === 0 && op === Operacion.Division) { return 0; }

		switch (op) {
			case Operacion.Multiplicacion:
				return Utils.checkNaNGetZero(Math.round((Math.round(t1) * Math.round(t2))));
			case Operacion.Division:
				return Utils.checkNaNGetZero(Math.round((Math.round(t1) / Math.round(t2))));
			case Operacion.Suma:
				return Utils.checkNaNGetZero(Math.round((Math.round(t1) + Math.round(t2))));
			case Operacion.Resta:
				return Utils.checkNaNGetZero(Math.round((Math.round(t1) - Math.round(t2))));
		}

		return 0;
	}

	static checkDataPortalErrorMessage(error: any): string {
		if (!error) {
			return '';
		}

		if (typeof error === 'string' || error instanceof String) {
			if (error.toLowerCase().indexOf('dataportal.') > -1) {
				return error.match(/\(([^)]+)\)/)[1];
			}
		}

		return error;
	}

	static getStartOfDay(date: Date = new Date(), calcOffset: boolean = true): Date {
		let d = new Date(date);
		d.setHours(calcOffset ? ((d.getTimezoneOffset() / 60) * -1) : 0);
		d.setMinutes(0);
		d.setSeconds(0);

		return d;
	}

	static getEndOfDay(date: Date = new Date(), calcOffset: boolean = true): Date {
		let d = new Date(date);
		d.setHours(calcOffset ? ((d.getTimezoneOffset() / 60) * -1) : 23);
		d.setMinutes(59);
		d.setSeconds(59);

		return d;
	}

	static addDays(currentDate: Date, days: number): Date {
		let date = new Date(currentDate);
		date.setDate(date.getDate() + days);
		return date;
	}

	static getDatesList(startDate: Date, stopDate: Date): Date[] {
		let dateArray: Date[] = [];
		let currentDate: Date = startDate;

		while (currentDate <= stopDate) {
			dateArray.push(currentDate);
			currentDate = Utils.addDays(currentDate, 1);
	   }

		return dateArray;
	}

	static hashCode(str) {
		var hash = 0;
		for (var i = 0; i < str.length; i++) {
			hash = str.charCodeAt(i) + ((hash << 5) - hash);
		}
		return hash;
	}

	static converStringTimeToDate(value: any): Date {
		let hours: number = (+value.substring(0, 2));
		let minutes: number = (+value.substring(3, 5));

		return new Date(1990, 1, 1, hours, minutes, 0);
	}

	static checkNaNGetZero(v: number): number {
		return v === undefined || isNaN(v) || !isFinite(v) ? 0 : v;
	}

	static checkIsEven(v: number): boolean {
		return v % 2 === 0 ? true : false;
	}

	static corralDays(e: any, hasta: any = new Date()): number {
		let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
		let firstDate: any = new Date(e);
		let secondDate: any = new Date(hasta);

		return (Math.round(Math.abs((firstDate - secondDate) / oneDay)) + 1); // incluir dia de ingreso
	}

	static dateToLocale(value: Date, includeSeconds: boolean = true): string {
		return this.getStringFullDateHour(value, includeSeconds);
	}

	static isDateSQLValid(value: any): boolean {
		let v: Date = new Date(value);

		if (v.getFullYear() > 1800 && v.getFullYear() < 2999) {
			return true;
		}

		return false;
	}

	static isDateValid(value: any): boolean {
		value = new Date(value);

		if (isNaN(value.valueOf()) == false) {
			return true;
		}

		return false;
	}

	static stringDateToDate(value: string): Date {
		value = value.replace(/-/g, "");

		let year: number = (+value.substring(0, 4));
		let month: number = (+value.substring(4, 6));
		let day: number = (+value.substring(6, 8));

		return new Date(year, month - 1, day);
	}

	static dateToDDMM(value: Date): string {
		return `${value.getDate().toString().padStart(2, '0')}/${(value.getMonth() + 1).toString().padStart(2, '0')}`;
	}

	static DateToString(value: any): string {
		return this.checkDateFormat(value).toISOString().slice(0, 10).replace(/-/g, "");
	}

	static getDateWithTime(date: any, hour: any): Date {
		let d = this.checkDateFormat(date);
		let h = this.checkDateFormat(hour);
		return new Date(d.getFullYear(), d.getMonth(), d.getDate(), h.getHours(), h.getMinutes(), 0);
	}

	static getDateTimeMinValue(): Date {
		//.Net DateTime.MinValue
		return new Date("0001-01-01T00:00:00");
	}

	static getDateTimeMaxValue(): Date {
		//.Net DateTime.MaxValue
		return new Date("9999-12-31T23:59:59");
	}

	static dateIsLessThanDate(date1: Date, date2: Date): boolean {
		return this.checkDateFormat(date1).valueOf() < this.checkDateFormat(date2).valueOf();
	}

	static getStringFullDateHour(dt: any, includeSeconds: boolean = true): string {
		let aux = this.checkDateFormat(dt);
		let res = `${
			aux.getDate().toString().padStart(2, '0')}/${
			(aux.getMonth() + 1).toString().padStart(2, '0')}/${
			aux.getFullYear().toString().padStart(4, '0')} ${
			aux.getHours().toString().padStart(2, '0')}:${
			aux.getMinutes().toString().padStart(2, '0')}`;

		if (includeSeconds) {
			res += `:${aux.getSeconds().toString().padStart(2, '0')}`;
		}

		return res;
	}

	static getStringHour(d: any) {
		let hour = this.checkDateFormat(d); //por si me llega dato tipo string
		if (!hour) return undefined;
		let hours = hour.getHours().toString().padStart(2, '0');
		let minutes = hour.getMinutes().toString().padStart(2, '0');
		//let seconds = Utils.PadLeft(d.getSeconds());
		return hours + ":" + minutes; // + ":" + seconds;
	}

	static checkDateFormat(d: any): Date {
		if (!d) return undefined;
		return new Date(d);
	}

	static cast<T>(item: any, type: new () => T): any {
		let aux: T = new type();
		Object.assign(aux, <T>item);
		return aux;
	}

	static getLocalStorageErrorMsg(error: any): any {
		var e = localStorage.getItem("interceptor_error");
		if (e) {
			if (error) {
				error = `${error} - ${e}`;
			}
			else {
				error = e;
			}
			localStorage.removeItem("interceptor_error")
		}
		else {
			e = error;
		}
		return e;
	}

	static checkAPIResponse(data: any, messageDialog: MyMessageDialogComponent, msgError: string): boolean {
		if (data.StatusCode) {
			data = (data as ApiResponse);
			if (data.StatusCode == 200 || data.StatusDescription === undefined) {
				return true;
			} else {
				if (messageDialog)
					messageDialog.showExceptionDialog(msgError, data);

				return false;
			}
		}
		else {
			return true;
		}
	}

	static parseDecimal(e: any) {
		if (!e) return 0;

		let n = e.replace(" ", "").replace("%", "").replace(",", ".");
		return Utils.checkNaNGetZero(n);
	}

	static formatNumberDecimal(e: any, trucateDecimals: boolean = false) {
		// para la cultura argentina (es-AR) no existen los separadores de miles.
		e = this.checkNaNGetZero(e);

		let minDec = 2;
		let maxDec = 2;

		if (trucateDecimals) {
			minDec = 0;
			maxDec = 0;
		}

		return e.toLocaleString('de-DE', { minimumFractionDigits: minDec, maximumFractionDigits: maxDec });
	}

	static getCargaExactitudClassColor(value: number) {
		//variable("")
		if (value > 1.1 || value < 0.9)
			return "sigef-font-red-color";
		else
			return "sigef-font-green-color";
	}

	static getCargaExactitud(propuesto: number, real: number) {
		return (!propuesto || propuesto === 0 || !real) ? 0 : real / propuesto;
	}

	static showSnackBarSucessMessage(snackBar: MatSnackBar, message: string) {
		snackBar.open(message, '', {
			duration: 3000,
			panelClass: ['green-snackbar']
		});
	}

	static showSnackBarErrorMessage(snackBar: MatSnackBar, message: string) {
		snackBar.open(message, '', {
			duration: 3000,
			panelClass: ['red-snackbar']
		});
	}

	static showSnackBarInfoMessage(snackBar: MatSnackBar, message: string) {
		snackBar.open(message, '', {
			duration: 3000,
			panelClass: ['blue-snackbar']
		});
	}

	static validateNoSpaces(c: UntypedFormControl): ValidationErrors | null {
		return (!c.value || (c.value && !(c.value.includes(' ')))) ? null : { validateNoSpaces: { valid: false } };
	}

	static validateLowerChar(c: UntypedFormControl): ValidationErrors | null {
		var re = new RegExp('^(?=.*[a-z])');
		return (!c.value || re.test(c.value)) ? null : { validateLowerChar: { valid: false } };
	}

	static validateUppderChar(c: UntypedFormControl): ValidationErrors | null {
		var re = new RegExp('^(?=.*[A-Z])');
		return (!c.value || re.test(c.value)) ? null : { validateUppderChar: { valid: false } };
	}

	static validateNumberChar(c: UntypedFormControl): ValidationErrors | null {
		var re = new RegExp('^(?=.*[0-9])');
		return (!c.value || re.test(c.value)) ? null : { validateNumberChar: { valid: false } };
	}

	static validateLetterChar(c: UntypedFormControl): ValidationErrors | null {
		var re = new RegExp('^(?=.*[a-zA-Z])');
		return (!c.value || re.test(c.value)) ? null : { validateLetterChar: { valid: false } };
	}

	static validateSpecialChar(c: UntypedFormControl): ValidationErrors | null {
		var re = new RegExp('^(?=.*[!@#$%^&*])');
		return (!c.value || re.test(c.value)) ? null : { validateSpecialChar: { valid: false } };
	}

	static getCurrentEmpresa(): UsuarioEmpresa {
		let currentUser = JSON.parse(localStorage.getItem('currentUser'));
		let currentEmpresa = currentUser.Empresa.toLowerCase();

		for (let x = 0; x < currentUser.Empresas.length; x++) {
			let emp = currentUser.Empresas[x];
			if (emp.EmpresaCodigo.toLowerCase() == currentEmpresa)
				return emp;
		}
	}
}
