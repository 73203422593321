import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DraggableCorral } from '../../../../../models/draggable-corral';

@Component({
	selector: 'my-group-corral-tropa',
	templateUrl: './my-group-corral-tropa.component.html',
	styleUrls: ['./my-group-corral-tropa.component.scss']
})
export class MyGroupCorralTropaComponent implements OnInit {
	@Input() corral: DraggableCorral = undefined;
	@Input() allowDelete: boolean = false;
	@Input() draggable: boolean = true;

	@Output() onDrop: EventEmitter<any> = new EventEmitter();
	@Output() onDelete: EventEmitter<any> = new EventEmitter(); 

	constructor() { }
	ngOnInit() { }

	drop(e) { this.onDrop.emit(e); }
	delete(e) { this.onDelete.emit(e); }
}
