import { Login } from "../app/core/auth";
import { DTOInterface } from "../interfaces/dto.interface";
import { UsuarioEmpresa } from "./usuario-empresa";

export class LoginADA implements DTOInterface {
	LoginToken: string;
	Empresas: UsuarioEmpresa[];

	constructor(loginToken: string, empresas: UsuarioEmpresa[]) {
		this.clear();
		this.LoginToken = loginToken;
		this.Empresas = empresas;
	}

	clear(): void {
		this.LoginToken = '';		
		this.Empresas = [];
	}
}
