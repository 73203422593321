import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AutenticacionService } from '../../../../../services/autenticacion.service';
import { Roles } from '../../../../../utils';
import { getRoute, Routing } from '../../../themes/sigef/routing';

@Component({
  selector: 'kt-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

	constructor(private auth: AutenticacionService,
		private router: Router	) { }

	ngOnInit() {

		let route = '/error/403';

		if (this.auth.tieneRole(Roles[Roles.Administrador]) || this.auth.tieneRole(Roles[Roles.Nutricionista]))
			route = getRoute(Routing.DASHBOARD);
		else if (this.auth.tieneRole(Roles[Roles.Veterinario]))
			route = getRoute(Routing.PRODUCTOS_SANITARIOS_PLANES);
		else if (this.auth.tieneRole(Roles[Roles.Hotelero]))
			route = getRoute(Routing.DASHBOARD_HOTELERO);
		else if (this.auth.tieneRole(Roles[Roles.Mixero]))
			route = getRoute(Routing.ALIMENTACION_CARGAS);

		this.router.navigateByUrl(route);
  }
}
