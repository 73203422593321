<div class="col">
	<div class="form-group">
		<label class="pb-1 d-block">{{ labelName }}<label class=" m-0 font-weight-bold">:&nbsp;{{userNameSelected}}</label></label>
		<kendo-buttongroup [selection]="'single'">
			<button *ngFor="let user of mixeros"
					kendoButton
					[toggleable]="true"
					class="btn-mixero"
					(selectedChange)="selectedChange($event, user)">
				<kt-avatar-photo class="image-icon" alt="Imagen de Perfil" [title]="user.UsuarioNombre + ' ' + user.UsuarioApellido" [photoUrl]="user.ImagenPerfil" [name]="user.UsuarioNombre + ' ' + user.UsuarioApellido"></kt-avatar-photo>
			</button>
		</kendo-buttongroup>
	</div>
</div>
