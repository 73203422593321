<kendo-grid [data]="productoSanitario.Formatos">
	<ng-template kendoGridNoRecordsTemplate>
		No hay datos de factores de venta para este producto sanitario
	</ng-template>

	<kendo-grid-column title="Formato de Venta">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{ dataItem.ProductoSanitarioFormatoNombre }}</span>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column title="{{descripcionColFactorDeConversion()}}" width="400">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{ dataItem.FactorConversionUM | parseNumberCustom : false : true : '' : productoSanitario.UnidadMedidaAbrev }}</span>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column title="Activo" width="70">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{dataItem.Activo ? 'Si' : 'No'}}</span>
		</ng-template>
	</kendo-grid-column>

</kendo-grid>
