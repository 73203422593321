import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kt-avatar-photo',
  templateUrl: './avatar-photo.component.html',
  styleUrls: ['./avatar-photo.component.scss']
})
export class AvatarPhotoComponent implements OnInit {

	@Input()
	public photoUrl: string;

	@Input()
	public name: string;

	@Input()
	public puedeEliminar: boolean = false;

	public showInitials = false;
	public initials: string;
	public circleColor: string;
	public eliminando: boolean = false;

	private colors = [
		"#E65722",
		"#008B8B",
		"#2E343B",
		"#CF000F",
		"#1BA39C",
		"#D2527F",
		"#B11030",
		"#E63022",
		"#AA00AA",
		"#708080"
	];

	ngOnInit() {
		this.refresh();
	}

	public refresh(): void {
		this.showInitials = false;
		this.circleColor = undefined;

		if (!this.photoUrl) {
			this.showInitials = true;
			this.createInititals();

			const randomIndex = Math.floor(Math.random() * Math.floor(this.colors.length));
			this.circleColor = this.colors[randomIndex];
		}
	}

	private createInititals(): void {
		let initials = "";

		if (!this.name) {
			this.name = "SIGEF";
		}

		for (let i = 0; i < this.name.length; i++) {
			if (this.name.charAt(i) === ' ') {
				continue;
			}

			if (this.name.charAt(i) === this.name.charAt(i).toUpperCase()) {
				initials += this.name.charAt(i);

				if (initials.length == 2) {
					break;
				}
			}
		}

		this.initials = initials;
	}

	public eliminar(e): void {
		this.photoUrl = '';
		this.eliminando = true;
		this.refresh();
	}

}
