import { TropaCategoriaGanado } from "./tropa-categoria-ganado";
import { TropaTransporte } from "./tropa-transporte"
import { DTOInterface } from "../interfaces/dto.interface";
import { Guid } from "guid-typescript";

export class Tropa implements DTOInterface { 
	TropaID: Guid;
	CodigoTropa: number;
	TropaNombre: string;
	FechaIngreso: Date;
	TropaOrigenRef: number;
	ClienteRef: number;
	ProveedorRef: number;
	CantMuertosArribo: number;
	MarcaIdentificatoria: string; 
	FechaCreacion: Date;
	GastoComercializacion: number;
	PrecioCompra: number;
	ColorRef: number;
	ColorPrincipal: string;
	ColorFuente: string;
	ColorFondo: string;
	EsPrecioPorTropa: boolean;
	EsPesoPorTropa: boolean;
	TropaOrigenNombre: string;	 
	ClienteNombre: string;
	ProveedorNombre: string;
	NumeroCuit: string;
	CostoHoteleria: number;
	MovTropaParicionRef: Guid;

	CategoriaGanados: TropaCategoriaGanado[];
	Transportes: TropaTransporte[];

	constructor() {
		this.clear();
	}

	clear(): void { 
		this.TropaID = Guid.createEmpty();
		this.CodigoTropa = -1;
		this.TropaNombre = '';
        this.FechaIngreso = new Date();
		this.TropaOrigenRef = null;
		this.ClienteRef = null;
		this.ProveedorRef = null;
		this.CantMuertosArribo = 0;
		this.MarcaIdentificatoria = ''; 
        this.FechaCreacion = new Date();
		this.GastoComercializacion = 0;
		this.PrecioCompra = 0;
		this.ColorRef = -1;
		this.ColorPrincipal = undefined;
		this.ColorFuente = undefined;
		this.ColorFondo = undefined;
		this.EsPrecioPorTropa = false; 
		this.EsPesoPorTropa = false;
		this.TropaOrigenNombre = '';
		this.ClienteNombre = '';
		this.ProveedorNombre = '',
		this.NumeroCuit = '';
		this.CostoHoteleria = 0;

		this.CategoriaGanados = [];
		this.Transportes = [];
	}
}
