<div class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1" id="kt_login">
	<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
		<div class="kt-grid kt-grid--hor kt-login__aside"></div>

		<div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper sigef-login__wrapper">
			<router-outlet></router-outlet>
		</div>
	</div>


	<div class="kt-footer--login">
		<div class="kt-footer--logo">
			<img src="../../../../assets/media/login/login_footer.png" />
		</div>
		<div class="kt-footer--info pull-right">&copy; 2022 SIGEF - Software Thinking</div>
	</div>
</div>
