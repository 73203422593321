<kendo-grid tabindex="{{tabindex}}" [data]="datos" #grid [loading]="cargando">
	<ng-template kendoGridToolbarTemplate>
		<button tabindex="{{tabindex + 1}}" (click)="showABM(undefined, true)" class="add-btn btn-sigef" kendoGridAddCommand>Agregar Faena</button>
	</ng-template>

	<kendo-grid-messages noRecords="No hay datos de faena para la venta de esta categoría de ganado">
	</kendo-grid-messages>

	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" title="Cabezas" width="100">
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span>{{ dataItem.CantCabezas }}</span>
			</div>
		</ng-template> 
		<ng-template kendoGridFooterTemplate let-column="column">
			<div align="center">
				<span class="footer-item">{{ totalCantCabezas }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" title="Kilos vivos" width="100"> 
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span>{{  dataItem.KilosVivo | parseNumberKg }}</span>
			</div>
		</ng-template>
		<ng-template kendoGridFooterTemplate let-column="column">
			<div align="center">
				<span class="footer-item">{{ totalKilosVivo | parseNumberKg }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" title="Kilos carne" width="100"> 
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span>{{  dataItem.KilosCarne | parseNumberKg }}</span>
			</div>
		</ng-template>
		<ng-template kendoGridFooterTemplate let-column="column">
			<div align="center">
				<span class="footer-item">{{ totalKilosCarne | parseNumberKg }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" title="Precio x Kg" width="100">
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span>{{  dataItem.PrecioFaena | parseNumberMoney }}</span>
			</div>
		</ng-template> 
	</kendo-grid-column>

	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" title="Total" width="100">
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span>{{ (dataItem.KilosCarne * dataItem.PrecioFaena) | parseNumberMoney }}</span>
			</div>
		</ng-template> 
		<ng-template kendoGridFooterTemplate let-column="column">
			<div align="center">
				<span class="footer-item">{{ totalPrecio | parseNumberMoney }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" field="RindePorc" title="Rinde (%)" width="100"> 
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span>{{ (dataItem.KilosCarne / dataItem.KilosVivo) | parseNumberPorc }}</span>
			</div>
		</ng-template> 
	</kendo-grid-column>

	<kendo-grid-command-column class="k-grid-ignore-click" title="Acciones" width="120">
		<ng-template kendoGridCellTemplate let-dataItem="dataItem">
			<button (click)="showABM(dataItem)" class="btn btn-sm"><i title="Editar" class="{{'Editar' | parseIconAccion }}"></i></button>
			<button (click)="confirmDelete(dataItem)" class="btn btn-sm k-grid-ignore-click"><i title="Eliminar" class="{{'Eliminar' | parseIconAccion }}"></i></button>
		</ng-template>
	</kendo-grid-command-column>
</kendo-grid>

<my-message-dialog></my-message-dialog>
