// Angular
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, LOCALE_ID, Injectable } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
// NgBootstrap
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap'; 
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Env
import { environment } from '../environments/environment';
// Hammer JS
import 'hammerjs';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// State
import { metaReducers, reducers } from './core/reducers';
// Copmponents
import { AppComponent } from './app.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
// Partials
import { PartialsModule } from './views/partials/partials.module';
// Layout Services
import { LayoutConfigService, LayoutRefService, MenuAsideService, MenuConfigService, MenuHorizontalService, PageConfigService, SplashScreenService, SubheaderService,
	KtDialogService } from './core/_base/layout';
// Auth
import { AuthModule } from './views/pages/auth/auth.module';
// CRUD
import { LayoutUtilsService } from './core/_base/crud';
// Config
import { LayoutConfig } from './core/_config/sigef/layout.config';
import { AutenticacionService } from '../services/autenticacion.service';
import { JwtInterceptor } from '../interceptors/jwt.interceptor';
import { ErrorInterceptor } from '../interceptors/error.interceptor'; 
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { NgbDateCustomParserFormatter } from '../formatters/ngb-date-custom-parser-formatter';
import { MessengerService } from '../services/messenger.service';
// Charts
import { ChartsModule } from '@progress/kendo-angular-charts';
import { GaugesModule } from '@progress/kendo-angular-gauges'; 
import { GuidInterceptor } from '../interceptors/guid.interceptor';
import { UtcInterceptor } from '../interceptors/utc.interceptor';
// i18n
import { registerLocaleData } from '@angular/common';
import { IntlModule } from '@progress/kendo-angular-intl'; 
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import localeEs from '@angular/common/locales/es-AR';
import '@progress/kendo-angular-intl/locales/es-AR/all';
import { ContainerService } from '../services/container.service';
import { MessageService } from '@progress/kendo-angular-l10n';
import { IntlService } from '../services/intl.services';
import { QuickPanelService } from '../services/quick-panel.service'; 
// NgxPermissions 
import { NgxPermissionsModule } from 'ngx-permissions'
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { ContextMenuModule, MenuModule } from '@progress/kendo-angular-menu';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons'; 
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { LabelModule } from '@progress/kendo-angular-label'; 
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
 
registerLocaleData(localeEs, 'es', 'es-AR');

// custom configuration Hammerjs
@Injectable()
export class HammerConfig extends HammerGestureConfig {
	overrides = <any>{
		// I will only use the swap gesture so 
		// I will deactivate the others to avoid overlaps
		'pinch': { enable: false },
		'rotate': { enable: false }
	}
} 

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelSpeed: 0.5,
	swipeEasing: true,
	minScrollbarLength: 40,
	maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
	// initialize app by loading default demo layout config
	return () => {
		if (appConfig.getConfig() === null) {
			appConfig.loadConfigs(new LayoutConfig().configs);
		}
	};
} 

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		NgxPermissionsModule.forRoot(),
		PartialsModule,
		CoreModule,
		OverlayModule,
		StoreModule.forRoot(reducers, {metaReducers}),
		EffectsModule.forRoot([]),
		StoreRouterConnectingModule.forRoot({stateKey: 'router'}),
		StoreDevtoolsModule.instrument(),
		AuthModule.forRoot(),
		NgbModule,
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		InlineSVGModule.forRoot(), 
		DialogsModule,
		ChartsModule,
		GaugesModule,
		SchedulerModule,
		IntlModule,
		DateInputsModule,
		ContextMenuModule,
		PDFExportModule,
		GridModule,
		ButtonsModule,
		DropDownsModule,
		ExcelExportModule,
		InputsModule,
		MenuModule,
		PopupModule,
		TreeViewModule,
		LabelModule,
		HammerModule
	],
    exports: [
        NgbModule,
		ChartsModule,
		GaugesModule,
		SchedulerModule,
		ContextMenuModule
    ],
	providers: [
		{
			provide: LOCALE_ID,
			useValue: 'es-AR'
		},
		LayoutConfigService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		KtDialogService,
		AutenticacionService,
		SplashScreenService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: HammerConfig
		},
		{
			// layout config initializer
			provide: APP_INITIALIZER,
			useFactory: initializeLayoutConfig,
			deps: [LayoutConfigService], multi: true
		}, 
		// template services
		SubheaderService,
		MessengerService,
		QuickPanelService,
		ContainerService,
		MenuHorizontalService,
		MenuAsideService,
		LayoutUtilsService, 
		AutenticacionService,   
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: GuidInterceptor, multi: true },		
		{ provide: HTTP_INTERCEPTORS, useClass: UtcInterceptor, multi: true },		
		{ provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
		{ provide: MessageService, useClass: IntlService }
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
