import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { TropaCategoriaGanado } from '../../../../../models/tropa-categoria-ganado';
import { Tropa } from '../../../../../models/tropa';
import { MyMessageDialogComponent, MyColInputComponent, IngresoHaciendaComponent } from '../../common_controls';
import { TropaCategoriaGanadoComponent } from '../tropa-categoria-ganado/tropa-categoria-ganado.component';
import { IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { EditableFormList } from '../../common_controls/common-controls.interface';
import { BroadcastMessage } from '../../../../../interfaces/message.interface';
import { MessengerService } from '../../../../../services/messenger.service';
import { Subscription } from 'rxjs';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import Utils, { Operacion } from '../../../../../utils';

@Component({
	selector: 'kt-tropa-categoria-ganado-list',
	templateUrl: './tropa-categoria-ganado-list.component.html',
	styleUrls: ['./tropa-categoria-ganado-list.component.scss']
})
export class TropaCategoriaGanadoListComponent extends EditableFormList<TropaCategoriaGanado> implements OnInit, OnDestroy, IParentOpenInDialog {
	showAllColumns: boolean = true;

	public mostrarColPrecio: boolean = true;
	public mostrarColPeso: boolean = true;

	public totalCantidad: number = 0;
	public totalPrecio: number = 0;
	public totalPesoVivo: number = 0;
	public totalPesoNeto: number = 0;

	public IngresoHacienda: IngresoHaciendaComponent;

	private totalHaciendaViva: number = 0;

	private sub: Subscription;

	@Input() tabindex: number = -1;
	@Input() edicion: boolean = false;
	@Input() puedeEditar: boolean = true;

	@ViewChild(MyMessageDialogComponent, { static: true }) public MessageDialog: MyMessageDialogComponent;
	@ViewChild('Muertos', { static: true }) Muertos: MyColInputComponent;

	private _tropa: Tropa;
	@Input()
	set tropa(item: Tropa) {
		this._tropa = item;
		if (this._tropa) {
			this.datos = this._tropa.CategoriaGanados;
			this.Muertos.setValue(this._tropa.CantMuertosArribo);
			this.showAllColumns = this._tropa.TropaID.isEmpty();
		} else {
			this.datos = [];
			this.Muertos.setValue(0);
			this.showAllColumns = true;
		}
		this.totales();
		this.buscando = false;
		this.cd.detectChanges();
	}
	get tropa(): Tropa { return this._tropa; }

	constructor(cd: ChangeDetectorRef, private messengerService: MessengerService, private quickPanelService: QuickPanelService) { super(cd, undefined) }

	totales(): void {
		this.totalCantidad = 0;
		this.totalPrecio = 0;
		this.totalPesoVivo = 0;
		this.totalPesoNeto = 0;

		for (const i of this.datos) {
			this.totalCantidad += i.Cantidad;
			this.totalPrecio += (i.PrecioCompra * i.Cantidad);
			this.totalPesoVivo += Utils.operacionRedondeada(i.PesoCabezaKg, i.Cantidad, Operacion.Multiplicacion);
			this.totalPesoNeto += Utils.operacionRedondeada(i.Cantidad, (Math.round(i.PesoCabezaKg) - (Math.round(i.PesoCabezaKg) * i.DesbastePorc)), Operacion.Multiplicacion)
		}

		if (this.totalHaciendaViva > 0) {
			if (this.totalCantidad > this.totalHaciendaViva) {
				this.MessageDialog.showAtencionDialog(`La cantidad total de hacienda ingresada (${this.totalCantidad}) supera al máximo definido de animales en la tropa (${this.totalHaciendaViva}). Edite o elimine una categoría.`);
			}
		}

		this.cd.detectChanges();
	}

	ngOnInit() {
		this.sub = this.messengerService.messageBroadcasted$.subscribe((data: BroadcastMessage) => {
			if (data) {
				if (data.Sender === 'IngresoHaciendaComponent' && data.Target === 'TropaCrudComponent') {
					let value = data.Payload.value;

					if (data.Reason === 'precio') {
						this.mostrarColPrecio = !value;
						this.tropa.EsPrecioPorTropa = value;
					}
					if (data.Reason === 'peso') {
						this.totalHaciendaViva = 0;
						this.mostrarColPeso = !value;
						this.tropa.EsPesoPorTropa = value;
					}
				}
				if (data.Reason === 'TropaCategoriaGanado') {
					this.saveResultDialogModel(data.Payload);
				}
				if (data.Reason === 'CantidadAnimalesVivo') {
					this.totalHaciendaViva = data.Payload;
				}
			}
		});

		this.Muertos.setParent(this);
	}

	onInputValueChange(newValue, labelName): void {
		if (labelName === 'Cantidad de Muertos al Arribo') {
			this.tropa.CantMuertosArribo = newValue;
		}
	}

	ngOnDestroy() {
		if (this.sub) { this.sub.unsubscribe(); }
	}

	getKeyModel(obj: TropaCategoriaGanado): any { return obj.TropaCategoriaGanadoID; }

	public removeHandler({ dataItem }) { this.confirmDelete(dataItem); }

	isNewModel: boolean = false;
	public showABM({ dataItem }) {
		this.isNewModel = dataItem == undefined;

		let title = 'Agregar Categoría';

		if (!this.isNewModel) {
			title = 'Editar Categoría: ' + dataItem.CategoriaGanadoNombre;
		}

		this.quickPanelService.createOffCanvasComponent(title, TropaCategoriaGanadoComponent, dataItem, { ingresoHacienda: this.IngresoHacienda, precio: this.mostrarColPrecio, peso: this.mostrarColPeso, cantidadVivo: this.totalHaciendaViva, data: (this.tropa ? this.tropa.CategoriaGanados : []) });
	}

	onDeletePost() {
		super.onDeletePost();
		this.totales();
	}

	onUpdatePost(e: TropaCategoriaGanado) {
		super.onUpdatePost(e);
		this.totales();
	}

	public saveResultDialogModel(model: TropaCategoriaGanado) {
		if (this.isNewModel) {
			if (this.tropa.CategoriaGanados.find(t => t.CategoriaGanadoRef === model.CategoriaGanadoRef && t.CorralRef === model.CorralRef)) {
				this.MessageDialog.showAtencionDialog('Ya existe esta misma categoría para el mismo corral. Edite o elimine el registro existente.')
				return;
			}
			this.tropa.CategoriaGanados.push(model);
			this.showAllColumns = true;
		}
		else {
			let item = this.tropa.CategoriaGanados.find(x => x.TropaCategoriaGanadoID == model.TropaCategoriaGanadoID);
			if (item) { Object.assign(item, model); }

			// asignar igual ADE a misma categoria sin importar el corral
			for (let cg of this.tropa.CategoriaGanados) {
				if (cg.CategoriaGanadoRef === model.CategoriaGanadoRef) {
					cg.AumentoDiarioEstKg = model.AumentoDiarioEstKg;
				}
			}
		}

		this.totales();
		this.cd.detectChanges();
	}

	round(t1: number): number {
		return Math.round(t1);
	}
}
