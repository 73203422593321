import { DTOInterface } from "../interfaces/dto.interface";

export class UsuarioZona implements DTOInterface { 
    ZonaRef: number;
	ZonaNombre: string;

    constructor(zonaRef: number, zonaNombre: string) {
        this.clear();
        this.ZonaRef = zonaRef;
        this.ZonaNombre = zonaNombre;
	}

	clear(): void {
		this.ZonaRef = -1;
		this.ZonaNombre = '';
	}
}
