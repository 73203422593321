import { Injectable, Inject } from '@angular/core'; 
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment'; 
import { Observable } from 'rxjs';
import { NameValueList, NVLService } from '../interfaces/nvl.interface';
import { ApiResponse } from '../interfaces/api-response.interface';
import { Guid } from 'guid-typescript';

export abstract class GenericoService<T> implements NVLService {
	protected _getHeaders: HttpHeaders;
	protected _postHeaders: HttpHeaders;
	protected _endpoint: string;

	constructor(private http: HttpClient, endpoint: string) {
		this._endpoint = endpoint;

		this._getHeaders = new HttpHeaders();
		this._postHeaders = new HttpHeaders();
		this._postHeaders = this._postHeaders.set('Content-Type', 'application/json');
	}

	getList(): Observable<T[]> { 
		return this.http.get<T[]>(environment.apiUrl + `/${this._endpoint}`, { headers: this._getHeaders });
	}

	getLookup(): Observable<NameValueList[]> {
		return this.http.get<NameValueList[]>(environment.apiUrl + `/${this._endpoint}/lookup`, { headers: this._getHeaders });
	}

	getById(_id: any): Observable<T> {
		return this.http.get<T>(environment.apiUrl + `/${this._endpoint}/` + this.getId(_id), { headers: this._getHeaders });
	}

	create(_obj: T): Observable<any> {
		return this.http.post<any>(environment.apiUrl + `/${this._endpoint}`, _obj, { headers: this._postHeaders });
	}

	update(_id: any, _obj: T): Observable<ApiResponse> {
		return this.http.put<ApiResponse>(environment.apiUrl + `/${this._endpoint}/` + this.getId(_id), _obj, { headers: this._postHeaders });
    }

	updateAndGetData(_id: any, _obj: T): Observable<any> {
		return this.http.put<any>(environment.apiUrl + `/${this._endpoint}/` + this.getId(_id), _obj, { headers: this._postHeaders });
    }

	delete(_id: any): Observable<ApiResponse> {
		return this.http.delete<ApiResponse>(environment.apiUrl + `/${this._endpoint}/` + this.getId(_id), { headers: this._postHeaders });
	}

	private getId(_id): any {
		if (_id instanceof Guid)
			return _id.toString();
		else
			return _id;
	}
}
