<div>
	<div class="row col sigef-form-dark-color sigef-font-size">
		<label class="font-weight-bold">Producto {{parent?.actualAlimentoIndex + 1}} de {{parent?.model?.Alimentos.length}}:&nbsp;&nbsp;</label>
		<label>{{AlimentoDescripcion}}</label>
	</div>
	<div class="row">
		<my-col-timepicker selectFistInput [focusOnInit]="true" [tabindex]="200" [labelName]="'Hora'"></my-col-timepicker>
	</div>
	<div class="row">
		<div class="col-auto form-group">
			<label class="d-block">Propuesto</label>
			<label class="font-weight-bold d-block mt-3">{{KgPropuesto |  parseNumberCustom : false : true : '' : UnidadMedida }}</label>
		</div>
		<my-col-input [tabindex]="201" #KgCargado [extraClass]="'kgCargaInput'" class="kgCargaInput" [mask]="Mascara" [decimals]="0" [labelName]="'Cargado'" [required]="true" [parent]="component"></my-col-input>
		<div class="col-auto form-group ml-3">
			<label class="d-block">Exactitud</label>
			<label class="font-weight-bold d-block mt-3" [ngClass]="getExactitudColor()">{{ Exactitud | parseNumberPorc : true }}</label>
		</div>
	</div>
	<div class="kt-divider kt-divider-sigef"><span></span></div>
	<div class="ml-3">
		<my-group-save-buttons [tabindex]="202" class="w-100" [saving]="saving" [allowDelete]="false"
							   [saveButtonText]="nextButtonText()" [cancelButtonText]="backButtonText()"
							   (onSave)="nextStep(true)" (onCancel)="nextStep(false)"></my-group-save-buttons>
	</div>
</div>
<my-message-dialog></my-message-dialog>
