<div cdkDropList [cdkDropListDisabled]="!draggable"
	 [cdkDropListData]="corral.items"
	 class="kanban-item"
	 [ngClass]="{'kanban-item-encierre': allowDelete, 'upload-box-icon': (allowDelete && corral && corral.items.length == 0), 'default-cursor': !draggable }"
	 cdkDropListSortingDisabled
	 (cdkDropListDropped)="drop($event)">
	<div [ngStyle]="{'border-left-color': item.tropa.ColorPrincipal}" class="corral-box" *ngFor="let item of corral.items" cdkDrag [cdkDragDisabled]="!draggable" [ngClass]="{'default-cursor': !draggable}" >
		<div class="corral-custom-placeholder" *cdkDragPlaceholder></div>
		<p class="mb-1">
			<span><strong>{{item.tropa.TropaNombre}}</strong></span>
			<small *ngIf="allowDelete" (click)="delete(item)" class="text-danger point">&nbsp; (quitar)</small>
			<br />
			<small class="text-muted">{{item.tropa.CategoriaGanadoNombre}} ({{ item.tropa.FechaIngreso | diasEnCorral }})</small>
			<br />
			<small class="text-muted">{{item.tropa.Hotelero ? item.tropa.Hotelero : 'Sin Propietario'}} - {{item.tropa.PesoCabezaKg | parseNumberKg }} por cabeza.</small>
		</p>
		<small class="text--cantidad"> {{ item.tropa.Cantidad }}</small>
	</div>
</div>
