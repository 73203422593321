import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import Utils from '../../../../../utils';
import { CommonControl } from '../common-controls.interface';
import { DateTimePickerComponent } from '@progress/kendo-angular-dateinputs';

@Component({
	selector: 'my-col-datetimepicker',
	templateUrl: './my-col-datetimepicker.component.html',
	styleUrls: ['./my-col-datetimepicker.component.scss']
})
export class MyColDateTimePickerComponent extends CommonControl implements OnInit {

	@Input() defaultDate: Date = new Date();
	@Input() minDate: Date = new Date(this.defaultDate.getFullYear() - 10, this.defaultDate.getMonth(), this.defaultDate.getDate(),0,0,0);
	@Input() maxDate: Date = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth(), this.defaultDate.getDate(), 23, 59, 59);

	@ViewChild('MyControl', { static: true }) MyControl: DateTimePickerComponent;

	constructor() {
		super();
		this.Form = new UntypedFormGroup({
			'Fecha': new UntypedFormControl('', Validators.required)
		});
	}

	ngOnInit() {
		this.setFecha(this.defaultDate);
	}

	focus() {
		this.MyControl.focus();
	}

	getPrincipalControl(): AbstractControl {
		return this.Form.controls['Fecha'];
	}

	setFecha(value: Date) {
		if (Utils.isDateValid(value))
			this.defaultDate = value;
		this.Form.patchValue({ Fecha: this.defaultDate });
	}

	getFecha(): Date {
		return this.Form.value['Fecha'];
	}

	getFechaFormatString(): string {
		return Utils.DateToString(this.getFecha());
	}

	valueChange(e: any) {
		if (!this.parent || !this.parent.onDateChange) return;
		this.parent.onDateChange();
	}

	onBlur() {
		if (Utils.dateIsLessThanDate(this.maxDate, this.getFecha()))
			this.setFecha(this.maxDate);
	}
}
