import { DTOInterface } from "../interfaces/dto.interface";
import { Guid } from "guid-typescript";

export class MovTropaFaena implements DTOInterface {
	MovTropaFaenaID: Guid; 
	MovTropaRef: Guid;
	CantCabezas: number;
	KilosVivo: number;
	KilosCarne: number;
	PrecioFaena: number;

	constructor() {
		this.clear();
	}

	clear(): void {
		this.MovTropaFaenaID = undefined;
		this.MovTropaRef = undefined;
		this.CantCabezas = 0;
		this.KilosVivo = 0;
		this.KilosCarne = 0;
		this.PrecioFaena = 0;
	}
}
