<kendo-grid [data]="alimento.Almacenes">
	<ng-template kendoGridNoRecordsTemplate>
		No hay datos de almacenes para este producto
	</ng-template>

	<kendo-grid-column title="Almacen">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{ dataItem.AlimentoAlmacenNombre }}</span>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column field="StockActual" title="Stock Actual">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span [ngClass]="(dataItem.StockActual < dataItem.StockMinimo) ? 'col-semaforo-rojo':'col-semaforo-verde'">&nbsp;{{ dataItem.StockActual | parseNumberCustom : true : true : '' : dataItem.UnidadMedidaAbrev}}</span>
		</ng-template>
	</kendo-grid-column>

</kendo-grid>
