import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';  
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { InfoDiaria } from '../models/info-diaria';

@Injectable()
export class InfoDiariaService extends GenericoService<InfoDiaria> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'infoDiaria');
	} 

	getByFecha(fecha: Date): Observable<InfoDiaria> {
		return this._httpClient.post<InfoDiaria>(environment.apiUrl + `/${this._endpoint}/fecha`, { FechaInfoDiaria: fecha }, { headers: this._getHeaders });
	}
}
