import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import _ from 'lodash';
import { Dictionary } from 'lodash';
import { ReportConsumo } from '../../../../../models/report-consumo';

@Component({
  selector: 'kt-consumo-alimento-list',
  templateUrl: './consumo-alimento-list.component.html',
  styleUrls: ['./consumo-alimento-list.component.scss']
})
export class ConsumoAlimentoListComponent implements OnInit {
	public showGrid: boolean = false;
	public consumo: ReportConsumo[];
	@Input() buscando: boolean = false;

	@Input() public set data(data: ReportConsumo[]) {
		this.consumo = [];

		let agrupadoPorAlimento: Dictionary<ReportConsumo[]> = _.groupBy(data, function (consumo) {
			return consumo.AlimentoNombre;
		});

		for (let key in agrupadoPorAlimento) {
			let value: ReportConsumo[] = agrupadoPorAlimento[key];

			let total: number = value.map(item => item.Valor).reduce((a, b) => a + b);
			let rc: ReportConsumo = new ReportConsumo();
			rc.AlimentoNombre = value[0].AlimentoNombre;
			rc.Valor = total / value.length;
			rc.Total = total;
			this.consumo.push(rc);
		}

		if (this.consumo) {
			this.showGrid = true;
		}
		this.cd.detectChanges();
	}

	constructor(private cd: ChangeDetectorRef) { }

	ngOnInit() { }
}
