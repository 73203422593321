<div class="form-group row-sigef col" [formGroup]="Form">
	<div class="col">
		<div class="form-group">
			<label class="pb-1">{{ labelDesde }}</label>
			<div>
				<kendo-datepicker formControlName="FechaDesde" [format]="'dd/MM/yyyy'"></kendo-datepicker>
			</div>
		</div>
	</div>

	<div class="col">
		<div class="form-group">
			<label class="pb-1">{{ labelHasta }}</label>
			<div>
				<kendo-datepicker formControlName="FechaHasta" [format]="'dd/MM/yyyy'"></kendo-datepicker>
			</div>
		</div>
	</div>
</div> 
