import { DTOInterface } from "../interfaces/dto.interface";

export class TropaOrigen implements DTOInterface { 
	TropaOrigenID: number;
	TropaOrigenNombre: string;
	Activo: boolean;

	constructor() {
		this.clear();
	}

	clear(): void {
		this.TropaOrigenID = -1;
		this.TropaOrigenNombre = '';
		this.Activo = true;
	}
}
