import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'kt-inline-server-validation',
  templateUrl: './inline-server-validation.component.html',
  styleUrls: ['./inline-server-validation.component.scss']
})
export class InlineServerValidationComponent implements OnInit {

	@Input() formGroup: UntypedFormGroup;
	@Input() field: any;
	@Input() input: any;

	constructor() { }

	ngOnInit() { }
}
