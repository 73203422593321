<div>
	<div class="row row-sigef">
		<my-col-labelinfo [infoText]="codigo" [labelName]="'Código'" [tooltipTitle]="'Código'"
						  [tooltipMessage]="'El código de la tropa se genera automáticamente al guardar.'">
		</my-col-labelinfo>	
		<my-col-input [tabindex]="tabindex" selectFirstInput #Nombre [type]="2" [labelName]="'Nombre'" [required]="true"></my-col-input>
		<my-col-colorpicker [tabindex]="tabindex + 1" [palette]="'tropa'" #Color [labelName]="'Color'" [tooltipTitle]="'Color'"
							[tooltipMessage]="'El color se utiliza para una rápida identificación de la tropa.'">
		</my-col-colorpicker>
	</div>
	<div class="row row-sigef pt-3" [ngClass]="{'d-none': !isnew }">
		<my-col-datetimepicker [tabindex]="tabindex + 2" [labelName]="'Fecha Ingreso'" [required]="true"></my-col-datetimepicker>
		<my-col-timepicker [tabindex]="tabindex + 3" [labelName]="'Hora Ingreso'" [required]="true"></my-col-timepicker>
	</div>
	<div class="row row-sigef pt-3" [ngClass]="{'d-none': !isnew }">
		<my-col-dropdownlist [tabindex]="tabindex + 4" #Cliente [labelName]="'Propietario'" [filterable]="true" [required]="true" [parent]="component" [key]="'Hotelero'"></my-col-dropdownlist>
		<my-col-dropdownlist [tabindex]="tabindex + 5" #Proveedor [labelName]="'Proveedor'" [filterable]="true" [parent]="component" [key]="'Proveedor'"></my-col-dropdownlist>
		<my-col-dropdownlist [tabindex]="tabindex + 6" #OrigenTropa [labelName]="'Origen de la Tropa'" [filterable]="true" [parent]="component" [key]="'OrigenTropa'"></my-col-dropdownlist>
	</div>
	<div class="row row-sigef pt-3">
		<my-col-input [tabindex]="tabindex + 7" #Marca [type]="2" [labelName]="'Marca Identificatoria'"></my-col-input>
		<my-col-input [tabindex]="tabindex + 8" #Gastos [labelName]="'Gastos de Comercialización (Total de Tropa)'" [mask]="'c2'"></my-col-input>
	</div>
</div>
