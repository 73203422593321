import { Injectable } from '@angular/core';
import { Package } from '../interfaces/package.interface';
import { Guid } from 'guid-typescript';
import { QUICKPANEL_PACKAGE } from '../interfaces/message.interface';

@Injectable()
export class ContainerService { 
	// Listado de paquetes almacenados (sólo en memoria)
	private packages: Package[];

	constructor() { this.packages = []; }

	// Genera un nuevo paquete con un Guid nuevo y el contenido pasado por parámetro
	public store(content: any, packageID: Guid = undefined): Guid {
		if (packageID && packageID.equals(QUICKPANEL_PACKAGE)) {
			 this.retrieve(QUICKPANEL_PACKAGE);
		}

		let p: Package = new Package(); 
		p.Content = content;
		return this.save(p, packageID);
	}

	// Hace un POP del paquete 
	public retrieve(id: Guid, deleteItem: boolean = true): Package {
		let p: Package = this.packages.find(p => p.PackageID.toString() === id.toString());
		if (p && deleteItem) { this.remove(p) };
		return p;
	}

	// Agrega el paquete a la lista
	private save(pack: Package, packageID: Guid = undefined): Guid {
		pack.PackageID = packageID || Guid.create();
		this.packages.push(pack);
		return pack.PackageID;
	}

	// Elimina un paquete de la lista
	private remove(pack: Package): void {
		const index = this.packages.indexOf(pack, 0);

		if (index > -1) {
			this.packages.splice(index, 1);
		}
	} 
}
