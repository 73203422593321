import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'my-col-button',
    templateUrl: './my-col-button.component.html',
    styleUrls: ['./my-col-button.component.scss']
})
export class MyColButtonComponent implements OnInit {
    @Input() labelVisible: boolean = true;
    @Input() buscando: boolean = false;
    @Input() enabled: boolean = true;
	@Input() textoNormal: string = 'Buscar';
	@Input() textoProceso: string = 'Buscando...';
	@Input() extraClass: string = 'col';
	@Input() btnClass: string = 'success';
	@Input() tabindex: number = -1;

    constructor() { }
    ngOnInit() { }
}
