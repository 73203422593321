import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { OffCanvasInterface } from '../../../../../interfaces/off-canvas.interface';
import { EditableForm } from '../../common_controls/common-controls.interface';
import { MovTropaFaena } from '../../../../../models/mov-tropa-faena';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessengerService } from '../../../../../services/messenger.service';
import { MyColInputComponent, MyColLabelInfoComponent } from '../../common_controls';
import { Guid } from 'guid-typescript';
import { ParseNumberPorcPipe } from '../../../../../pipes/parse-number-porc.pipe';
import { QuickPanelService } from '../../../../../services/quick-panel.service';

@Component({
	selector: 'kt-faena',
	templateUrl: './faena.component.html',
	styleUrls: ['./faena.component.scss']
})
export class FaenaComponent extends EditableForm<MovTropaFaena> implements AfterViewInit, OnInit, OffCanvasInterface {

	public modelIsNew: boolean = false;
	public componente: FaenaComponent = this;

	private movTropaRef: Guid = undefined;

	@ViewChild('Cantidad', { static: true }) CantidadInput: MyColInputComponent;
	@ViewChild('KilosCarne', { static: true }) KilosCarneInput: MyColInputComponent;
	@ViewChild('KilosVivo', { static: true }) KilosVivoInput: MyColInputComponent;
	@ViewChild('PrecioFaena', { static: true }) PrecioFaenaInput: MyColInputComponent;
	@ViewChild('RindePorc', { static: true }) RindePorcLabel: MyColLabelInfoComponent;
	@Input() tabindex: number = 130000;

	constructor(cd: ChangeDetectorRef,
		snackBar: MatSnackBar,
		messengerService: MessengerService, quickPanelService: QuickPanelService) {
		super(cd, undefined, snackBar, messengerService, quickPanelService);
	}

    ngAfterViewInit(): void {
		this.CantidadInput.setParent(this);

		this.KilosCarneInput.setParent(this);
		this.KilosVivoInput.setParent(this);

		this.CantidadInput.setValue(0);
		this.KilosCarneInput.setValue(0);
		this.KilosVivoInput.setValue(0);
		this.PrecioFaenaInput.setValue(0);
		this.RindePorcLabel.infoText = new ParseNumberPorcPipe().transform(0);
    }

	setOffCanvasData(data: any, extras: any): void { 
		setTimeout(() => { 
			this.cd.detectChanges();

			this.movTropaRef = extras.MovTropaRef;

			this.modelIsNew = (!data || data.MovTropaFaenaID.isEmpty());

			this.lstControls = [this.CantidadInput, this.KilosCarneInput, this.KilosVivoInput, this.PrecioFaenaInput];
			
			this.setModel(data);
		}, 0);
	}

	getNewModel(): MovTropaFaena { return new MovTropaFaena(); };

	setModelControlValues() {
		if (this.model) {
			this.CantidadInput.setValue(this.model.CantCabezas);
			this.KilosCarneInput.setValue(this.model.KilosCarne);
			this.KilosVivoInput.setValue(this.model.KilosVivo);
			this.PrecioFaenaInput.setValue(this.model.PrecioFaena); 
		}
	}

	ngOnInit() {

	}

	onInputValueChange(event, labelName): void {
		if (labelName === 'Kilos Vivos' || labelName === 'Kilos Carne') {
			setTimeout(() => {
				this.RindePorcLabel.infoText = new ParseNumberPorcPipe().transform(this.KilosCarneInput.getValue() / this.KilosVivoInput.getValue());
				this.cd.detectChanges();
			}, 0);
		}
	}

	onFaenaSave(e: any) {
		if (!this.runValidaciones()) {
			return false;
		}

		this.model.CantCabezas = this.CantidadInput.getValue(); 
		this.model.KilosCarne = this.KilosCarneInput.getValue();
		this.model.KilosVivo = this.KilosVivoInput.getValue();
		this.model.PrecioFaena = this.PrecioFaenaInput.getValue(); 
		this.model.MovTropaRef = this.movTropaRef;

		this.getMessengerInstance().broadcast(undefined, undefined, 'MovTropaFaena', this.model);
		this.getQuickPanelInstance().hideQuickPanel();
	}

	onFaenaCancel(e: any) {
		this.getQuickPanelInstance().hideQuickPanel();
	}
}
