<div class="col" style="min-width: 320px; width: 320px;">
	<div class="form-group" [formGroup]="Form">
		<label class="pb-1" *ngIf="labelVisible">{{labelName}}</label>
		<div class="input-group">
			<kendo-multiselect #controlInput
							   tabindex="{{tabindex}}"
							   [kendoDropDownFilter]="filterSettings"
							   style="width: 100%"
							   formControlName="Input"
							   [itemDisabled]="itemDisabled"
							   [data]="data"
							   [textField]="textField"
							   [valueField]="valueField"
							   [autoClose]="false"
							   (valueChange)="valueChange($event)"
							   (removeTag)="removeTag($event)">
				<ng-template kendoMultiSelectTagTemplate let-dataItem>
					<span class="template">{{ getTagTemplate(dataItem) }}</span>
				</ng-template>
				<ng-template kendoMultiSelectItemTemplate let-dataItem>
					<input type="checkbox" class="k-checkbox" [checked]="isItemSelected(dataItem.Descripcion)">
					<label class="k-checkbox-label">{{ dataItem.Descripcion }}</label>
				</ng-template>
			</kendo-multiselect>
		</div>
		<kt-inline-server-validation [ngClass]="{'d-none': !showValidation}" [input]="controlInput" [formGroup]="Form" [field]="'Description'"></kt-inline-server-validation>
	</div>
	<span *ngIf="hint" class="form-text text-muted">{{hint}}</span>
</div>
