import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { OffCanvasInterface } from '../../../../../interfaces/off-canvas.interface';
import { DietaAlimentoDashboard } from '../../../../../models/dieta-alimento-dashboard';
import { DietaService } from '../../../../../services/dieta.service';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { IOpenInDialog, IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';

@Component({
	selector: 'kt-dieta-alimento-dashboard',
	templateUrl: './dieta-alimento-dashboard.component.html',
	styleUrls: ['./dieta-alimento-dashboard.component.scss'],
	providers: [DietaService]

})
export class DietaAlimentoDashboardComponent implements AfterViewInit, OnInit, IOpenInDialog, OffCanvasInterface {

	public dietaAlimentoDashboard: DietaAlimentoDashboard[] = [];

	constructor(private cd: ChangeDetectorRef, private quickPanelService: QuickPanelService, private dietaService: DietaService) { }

	ngOnInit() {
	}

	ngAfterViewInit(): void {
	}

	openInDialog(model: any, extras: any): void {		
	}

	saveResultDialog(formParent: IParentOpenInDialog): boolean {
		return true;
	}

	editResultDialog(formParent: IParentOpenInDialog): boolean {
		return true;
	}

	setOffCanvasData(data: any, extras: any): void {
		let dietaID = data;
		setTimeout(() => {
			this.quickPanelService.setQuickPanelLoadingStatus(true);

			this.dietaService.getDietaAlimentoDashboardList(dietaID).subscribe(
				data => {
					this.dietaAlimentoDashboard = data;
				},
				error => {

				},
				() => {
					this.finalizarBusqueda();
				}
			);
		}, 0);
	}

	finalizarBusqueda(): void {
		this.quickPanelService.setQuickPanelLoadingStatus(false);
		this.cd.detectChanges();
	}

	clickCancel(e: any): void {
		this.quickPanelService.hideQuickPanel();
	}

	public getTotalMS(): number {
		var sum = this.dietaAlimentoDashboard.reduce(function (total, currentValue) {
			return total + currentValue.PrecioMS;
		}, 0);
		return sum;
	}

	public getTotalTalCual(): number {
		var sum = this.dietaAlimentoDashboard.reduce(function (total, currentValue) {
			return total + currentValue.PrecioTalCual;
		}, 0);
		return sum;
	}

}
