import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http'; 
import { Zona } from '../models/zona';

@Injectable()
export class ZonaService extends GenericoService<Zona> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'zona');
	}
}
