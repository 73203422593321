<div class="p-2">
	<div *ngIf="!movTropa">
		<div class="kt-portlet mb-0">
			<div class="kt-portlet__body kt-portlet__body--fit p-3">
				<div class="kt-spinner kt-spinner--sm kt-spinner--success kt-spinner--left kt-spinner--input">
					&nbsp;&nbsp; <span class="ml-5">Cargando Movimiento...</span>
				</div>
			</div>
		</div>
	</div>

	<table class="table" *ngIf="movTropa">
		<tbody>
			<tr class="table-active">
				<th scope="row">{{ movTropa.MovTropaTipoNombre }}</th>
				<td></td>
			</tr>
			<tr>
				<th scope="row">Tipo Movimiento</th>
				<td>
					<span class="{{ movTropa.MultiplicaPor | parseIconMovimiento }}">&nbsp;&nbsp;</span>
					{{ movTropa.MultiplicaPor >= 1 ? 'Entrada' : (movTropa.MultiplicaPor <= -1 ? 'Salida' : 'Ajuste') }}
				</td>
			</tr>
			<tr class="table-secondary">
				<th scope="row">Fecha y Hora</th>
				<td>{{ movTropa.FechaMovimiento | parseDateHour }}</td>
			</tr>
			<tr>
				<th scope="row">Corral</th>
				<td>{{ movTropa.CorralNumero | parseCorralNumero}} ({{ movTropa.CorralNombre }})</td>
			</tr>
			<tr class="table-secondary">
				<th scope="row">Tropa</th>
				<td>{{ movTropa.CodigoTropa  | parseCodigoTropa}} - {{ movTropa.TropaNombre }}. <a href="javascript:;" (click)="navegarReporteTropa()" class="kt-link text-primary">Ir al Reporte.</a></td>
			</tr>
			<tr>
				<th scope="row">Propietario</th>
				<td>{{ movTropa.ClienteNombre ? movTropa.ClienteNombre : 'Sin Propietario' }}</td>
			</tr>
			<tr class="table-secondary">
				<th scope="row">Cabezas</th>
				<td>{{ movTropa.Cantidad | parseNumber : true }} ({{ movTropa.CategoriaGanadoNombre }}) <span *ngIf="esParicion()">[Madre: {{ movTropa.CategoriaGanadoNombreParicion }}]</span></td>
			</tr>
			<tr *ngIf="esParicion()">
				<th scope="row">Parición</th>
				<td>{{ movTropa.CorralNumeroParicion | parseCorralNumero }} ({{ movTropa.CorralNombreParicion }})</td>
			</tr>
			<tr *ngIf="esCambioCorral() || esOperacionInterna() || esVenta() || esCompra() || esParicion() || esPesada()" class="table-secondary">
				<th scope="row">Peso Bruto/Neto</th>
				<td>{{ movTropa.PesoCabezaKg | parseNumberKg }}/{{ movTropa.PesoCabezaKg - (movTropa.PesoCabezaKg * movTropa.DesbastePorc) | parseNumberKg }}</td>
			</tr>
			<tr *ngIf="esCambioCorral() || esOperacionInterna() || esVenta() || esParicion() || esCompra() || esPesada()">
				<th scope="row">Desbaste</th>
				<td>{{ movTropa.DesbastePorc | parseNumberPorc }}</td>
			</tr>
			<tr *ngIf="esCambioCorral() || esOperacionInterna() || esVenta()" class="table-secondary">
				<th scope="row">Incluye Mov. de Pesada</th>
				<td>{{ movTropa.IncluyePesada ? 'Sí' : 'No' }}</td>
			</tr>
			<tr *ngIf="esVenta() || esOperacionInterna() || esParicion() || esCompra()" [ngClass]="{'table-secondary': !(esCambioCorral() || esOperacionInterna() || esVenta())}">
				<th scope="row">Precio</th>
				<td>{{ movTropa.PrecioVenta | parseNumberMoney }}</td>
			</tr>
			<tr *ngIf="esVenta()" class="table-secondary">
				<th scope="row">Venta</th>
				<td>
					<span *ngIf="!movTropa.VentaRef">-</span>
					<a *ngIf="movTropa.VentaRef" href="javascript:;" (click)="navegarReporteVenta()" class="kt-link text-primary">Ir al Reporte.</a>
				</td>
			</tr>
			<tr>
				<th scope="row">Observaciones</th>
				<td>{{ movTropa.Observaciones ? movTropa.Observaciones : '-' }}</td>
			</tr>
		</tbody>
	</table>

	<div class="ml-3 mr-3 pl-3 mt-3 row">
		<div class="row">
			<button tabindex="99999" class="btn btn-sigef mr-1" (click)="clickCancel($event)">Cerrar</button>
		</div>
	</div>
</div>
