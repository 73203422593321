import { DTOInterface } from "../interfaces/dto.interface";
import { Guid } from "guid-typescript";

export class TropaCategoriaGanado implements DTOInterface {
	TropaCategoriaGanadoID: Guid;
	CategoriaGanadoRef: number;
	AumentoDiarioEstKg: number;
	CategoriaGanadoNombre: string;
	CorralRef: number;
	FechaMovimiento: Date;
	Cantidad: number;
	Observaciones: string;
	DesbastePorc: number;
	PesoCabezaKg: number;
	FechaCreacion: Date; 
	TropaRef: Guid;
	PrecioCompra: number;
	CorralNumero: number;
	CorralNombre: string;

	constructor() {
		this.clear();
	}

	clear(): void {
		this.TropaCategoriaGanadoID = Guid.createEmpty();
		this.CategoriaGanadoRef = 0;
		this.AumentoDiarioEstKg = 0;
		this.CategoriaGanadoNombre = '';
		this.CorralRef = 0;
		this.FechaMovimiento = new Date();
		this.Cantidad = 0;
		this.Observaciones = '';
		this.DesbastePorc = 0;
		this.PesoCabezaKg = 0;
		this.FechaCreacion = new Date();
		this.TropaRef = Guid.createEmpty();
		this.PrecioCompra = 0;
		this.CorralNumero = 0;
		this.CorralNombre = '';
	}
}
