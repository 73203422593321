<div class="col">
	<div class="form-group" [formGroup]="Form">
		<label *ngIf="!onlyRow" class="d-block pb-1 kt-hidden-mobile">&nbsp;</label>
		<div class="input-group">
			<kendo-switch tabindex="{{tabindex}}" class="switch-sigef" [onLabel]="'Sí'" [offLabel]="'No'" formControlName="Value"></kendo-switch>
			<label class="pl-2 label-switch">{{ labelName }}</label>
			<label *ngIf="hasInfo" class="pl-2 label-switch" ngbPopover={{tooltipMessage}} popoverTitle={{tooltipTitle}} container="body"
				   triggers="mouseenter:mouseleave"><i class="fa fa-info-circle"></i></label>
		</div>
	</div>
</div>
