import { Component, OnInit, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MyColDropDownListComponent, MyColDateTimePickerComponent, MyColInputComponent } from '../../common_controls';
import { ProductoSanitarioService } from '../../../../../services/producto-sanitario.service';
import { MessengerService } from '../../../../../services/messenger.service';
import { MovProductoSanitario } from '../../../../../models/mov-producto-sanitario';
import { PlanSanitario } from '../../../../../models/plan-sanitario';
import Utils from '../../../../../utils';

@Component({
	selector: 'kt-movproductosanitario-aplicacion-row',
	templateUrl: './movproductosanitario-aplicacion-row.component.html',
	styleUrls: ['./movproductosanitario-aplicacion-row.component.scss'],
	providers: [ProductoSanitarioService]
})
export class MovproductosanitarioAplicacionRowComponent implements OnInit {
	@ViewChild('Producto', { static: true }) Producto: MyColDropDownListComponent;
	@ViewChild('Fecha', { static: true }) Fecha: MyColDateTimePickerComponent;
	@ViewChild('Cantidad', { static: true }) Cantidad: MyColInputComponent;

	public component: MovproductosanitarioAplicacionRowComponent = this;

	public valido: boolean = false;
	public mostrarBorrar: boolean = true;
	public mostrarAgregar: boolean = true;
	public rowId: any = undefined;
	public cantidadLabel: string = 'Cantidad por cabeza';
	public containerClass: string = '';
	public hint: string = '';
	public modo: string = '';
	private _data: AplicacionRow = undefined;
	public dataBaseId: any = undefined;

	constructor(private productoSanitarioService: ProductoSanitarioService,
		private messengerService: MessengerService,
		private productoService: ProductoSanitarioService,
		private cd: ChangeDetectorRef) { }

	ngOnInit() {
		this.Producto.initializeControl(this.productoSanitarioService, this.cd, 'Seleccione un producto...');

		this.Cantidad.setValue(0);
		this.Cantidad.setParent(this);		
	}

	setProductoRepetido(): void {
		this.Producto.getPrincipalControl().setErrors({ duplicado: true });
		this.valido = false;
	}

	validar() {
		this.Fecha.showError(true);
		this.Cantidad.showError(true);
		this.Producto.showError(true);

		this.valido = (this.Fecha.isValid() && this.Cantidad.isValid() && this.Producto.isValid());
	}

	onDropDownValueChanged(item, key): void {
		if (key === 'Producto') {
			this.cargarUnidadMedida();
		}
	}

	onDataSet(key) {
		this.setData(this._data);
	}

	setData(value: AplicacionRow = undefined) {
		this._data = value;

		if (value) {
			this.dataBaseId = this._data.Id;
			this.Fecha.setFecha(this._data.Fecha);
			this.Producto.setValue(this._data.ProductoSanitarioRef);
			this.Cantidad.setValue(this._data.Cantidad);

			this.cargarUnidadMedida();
			this.validar();

			this.cd.detectChanges();
		}
	}

	private cargarUnidadMedida() {
		let pId = this.Producto.getValue() || 0;

		if (pId > 0) {
			this.productoService.getById(this.Producto.getValue()).subscribe(
				data => {
					this.cantidadLabel = `Cantidad (${data.UnidadMedidaAbrev}) por cabeza`;
					//this.Cantidad.setValue(0);
					this.valido = true;
				},
				error => {

				},
				() => {
					this.cd.detectChanges();
				});
		} else {
			this.cantidadLabel = 'Cantidad por cabeza';
			this.valido = false;
		}

		this.cd.detectChanges();
	} 

	setId() {
		this.rowId = "#" + Math.random() * 123;
	}

	emitirBorrar() {
		this.messengerService.broadcast('AplicacionRow', undefined, 'eliminar', this.rowId);
	}

	emitirAgregar() {
		this.messengerService.broadcast('AplicacionRow', undefined, 'agregar', 0);
	}
}

export class AplicacionRow {
	Id: any;
	Fecha: Date;
	Cantidad: number;
	ProductoSanitarioRef: number;

	constructor(id: any, fecha: any, productoSanitarioRef: number, cantidad: number) {
		this.Id = id;
		this.Fecha = Utils.stringDateToDate(fecha) || new Date();
		this.Fecha.setHours(6);
		this.ProductoSanitarioRef = productoSanitarioRef || 0;
		this.Cantidad = cantidad || 0;
	}
}
