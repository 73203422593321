import { Input, ViewChild, ChangeDetectorRef, Output, EventEmitter, ViewRef, Directive } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { MyMessageDialogComponent, IParentConfirmResultMessage } from './message-dialog/my-message-dialog.component';
import { GenericoService } from '../../../../services/generico.service';
import Utils from '../../../../utils';
import { Guid } from 'guid-typescript';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessengerService } from '../../../../services/messenger.service';
import { QuickPanelService } from '../../../../services/quick-panel.service';

export interface EditableFormListInterface<T> {
	getKeyModel(T): any;
}

export interface EditableFormInterface<T> {
	getKeyModel(T): any;
	getNewModel(): T;
}

export interface ValidationControlInterface {
	isValid(): boolean;
	showError(value: boolean): void;
	resetForm(): void;
	getPrincipalControl(): AbstractControl;
}

@Directive()
export abstract class EditableValidationForm<T> {
	component: EditableValidationForm<T> = this;
	onSetProperties: boolean = false;
	isNewModel: boolean = false;
	model: T;

	@ViewChild(MyMessageDialogComponent, { static: true }) public MessageDialog: MyMessageDialogComponent;
	lstControls: ValidationControlInterface[] = [];

	constructor(protected cd: ChangeDetectorRef) { }

	resetModel(): void {
		this.loadModelAndSetProperties(undefined, true);
	}

	loadModelAndSetProperties(obj: T, isNew: boolean) {
		this.model = obj;
		this.isNewModel = isNew;

		// Set control values
		this.resetValidaciones();
	}

	manuallyDetectChanges(): void {
		if (this.cd && !((this.cd as ViewRef).destroyed)) {
			this.cd.detectChanges();
		}
	}

	resetValidaciones() {
		this.onSetProperties = true;
		this.setModelControlValues();
		this.lstControls.forEach(c => c.resetForm());
		this.onSetProperties = false;
		this.manuallyDetectChanges();
	}

	runValidaciones() {
		this.lstControls.forEach(c => c.showError(true));
		this.manuallyDetectChanges();

		if (!this.lstControls.every(c => c.isValid())) {
			this.MessageDialog.showFormularioInvalido();
			this.manuallyDetectChanges();
			return false;
		}
		if (!this.runCustomValidaciones())
			return false
		return true;
	}

	onCancel(e): void {
		e.preventDefault();
		this.resetModel();
	}

	//Metodos overrides
	runCustomValidaciones(): boolean { return true; }
	setModelControlValues(): void { }
}

@Directive()
export abstract class EditableForm<T> extends EditableValidationForm<T> implements EditableFormInterface<T>, IParentConfirmResultMessage {
	parent: EditableFormList<T> = undefined;
	adicionalesVisible: boolean = false;
	saving: boolean = false;
	deleting: boolean = false;
	openedInDialog: boolean = false;

	@Output() cancel: EventEmitter<any> = new EventEmitter();
	@Output() delete: EventEmitter<T> = new EventEmitter();
	@Output() save: EventEmitter<T> = new EventEmitter();

	constructor(cd: ChangeDetectorRef,
		protected modelServices: GenericoService<T>,
		protected snackBar: MatSnackBar,
		private messengerService: MessengerService,
		private quickPanelService: QuickPanelService) {
		super(cd);
	}

	getMessengerInstance(): MessengerService {
		return this.messengerService;
	}

	getQuickPanelInstance(): QuickPanelService {
		return this.quickPanelService;
	}

	getAndSetModel(id: any, parentCarga: EditableFormList<T> = undefined, extras: any = undefined) {
		this.modelServices.getById(id).subscribe(
			data => {
				if (Utils.checkAPIResponse(data, this.MessageDialog, 'No se pudo obtener el recurso')) {
					this.setModel(data, parentCarga);
					this.setExtras(extras);
				}
			},
			error => {
				this.MessageDialog.showExceptionDialog('No se pudo obtener el recurso', error);
			},
			() => {
				this.manuallyDetectChanges();
			}
		);
	}

	setModel(obj: T, parentCarga: EditableFormList<T> = undefined) {
		this.saving = false;
		this.deleting = false;
		this.parent = parentCarga;
		this.onInitSetModel();
		let itm = (!obj) ? this.getNewModel() : obj;
		this.loadModelAndSetProperties(itm, this.getDefaulOrKeyModel(obj) == undefined);
	}

	onSave(e, repeat: boolean = false): void {
		if (e) {
			e.preventDefault();
		}

		this.saving = true;
		if (!this.runValidaciones()) {
			this.saving = false;
			return;
		}
		this.setModelPropsByControls();

		this.saveModel();

		if (this.parent) {
			let cont = this.isNewModel && repeat;
			this.parent.onEditModel = cont;
			if (cont)
				this.setModel(undefined, this.parent);
		}
	}

	saveModel() {
		if (!this.model) return;
		let onCreateUpdateFinish = (sucess: boolean) => { this.onCreateUpdateFinish(sucess); };
		if (this.getDefaulOrKeyModel(this.model)) {
			let onUpdatePost = (this.parent) ? (obj: T) => { this.parent.onUpdatePost(obj); } : (T) => { };
			SaveDataHelpers.UpdateObject(this.modelServices, this.getDefaulOrKeyModel(this.model), this.model, this.MessageDialog,
				onUpdatePost, onCreateUpdateFinish);
		} else {
			let onCreatePost = (this.parent) ? (T) => { this.parent.onCreatePost(T); } : () => { };
			SaveDataHelpers.AddObject(this.modelServices, this.model, this.MessageDialog,
				onCreatePost, onCreateUpdateFinish);
		}
	}

	onCreateUpdateFinish(sucess: boolean) {
		if (!this.openedInDialog) {
			this.informSave(sucess);
			this.manuallyDetectChanges();
		}
	}

	informSave(sucess: boolean, closeOnSave: boolean = true) {
		this.saving = false;
		let okMsg = 'La información se guardó exitosamente';
		let errMsg = 'Error al guardar';

		if (sucess) {
			Utils.showSnackBarSucessMessage(this.snackBar, okMsg);
			if (closeOnSave) {
				this.quickPanelService.hideQuickPanel();
			}
			this.messengerService.broadcastMessage('Refresh');
			this.resetValidaciones();
			this.save.emit();
		} else {
			Utils.showSnackBarErrorMessage(this.snackBar, errMsg);
		}
	}

	onConfirmResultMessageDialog(id: string, ok: boolean) {
		if (this.model && id === 'DeleteModel') {
			let onDeleteFinish = (sucess: boolean) => { this.onDeleteFinish(sucess); };
			if (ok)
				SaveDataHelpers.DeleteObject(this.modelServices, this.getDefaulOrKeyModel(this.model), this.MessageDialog,
					undefined, onDeleteFinish);
			else
				this.informDelete(false);
		}
	}

	onDeleteFinish(sucess: boolean) {
		if (!this.openedInDialog) {
			this.model = undefined;
			this.informDelete(sucess);
			this.manuallyDetectChanges();
		}
	}

	onDelete(e): void {
		e.preventDefault();
		if (!this.model) return;
		this.deleting = true;
		this.MessageDialog.showDialog('Por favor confirme', 'Se eliminará al recurso en forma definitiva. ¿Está seguro que desea continuar?',
			['SI', 'NO'], 'DeleteModel', this);
	}

	informDelete(sucess: boolean) {
		this.deleting = false;
		if (sucess) {
			this.quickPanelService.hideQuickPanel();
			this.messengerService.broadcastMessage('Refresh');
			this.delete.emit();
		}
	}

	onCancel(e): void {
		e.preventDefault();
		this.quickPanelService.hideQuickPanel();
		this.messengerService.broadcastMessage('Refresh');
		this.cancel.emit();
		if (this.parent)
			this.parent.onEditModel = false;
	}

	getDefaulOrKeyModel(obj: T) {
		if (!obj)
			return undefined;
		else {
			let id = this.getKeyModel(obj);
			if (id instanceof Guid)
				return (id.isEmpty()) ? undefined : id;
			else
				return (id <= 0) ? undefined : id;
		}
	}

	manuallyDetectChanges(): void {
		if (this.cd && !((this.cd as ViewRef).destroyed)) {
			this.cd.detectChanges();
		}
	}

	//Metodos overrides
	onInitSetModel(): void { }
	getKeyModel(T): any { };
	getNewModel(): T { return undefined; };
	setModelPropsByControls(): void { }
	setExtras(extras: any): void { };
}

@Directive()
export abstract class EditableFormList<T> implements EditableFormListInterface<T>, IParentConfirmResultMessage {
	@ViewChild(MyMessageDialogComponent, { static: true }) public MessageDialog: MyMessageDialogComponent;

	public busqueda: boolean = false; // si ha hecho una busqueda
	public buscando: boolean = false; // si esta haciendo un r a la api
	public datos: T[] = [];

	onEditModel: boolean = false;
	ModelForm: EditableForm<T>;

	constructor(public cd: ChangeDetectorRef, protected modelServices: GenericoService<T>) { }

	initilizeForm(modelForm: EditableForm<T>) {
		this.ModelForm = modelForm;
		this.buscando = false;
		this.busqueda = false;
	}

	buscar(): void {
		if (this.buscando)
			return;
		this.buscando = true;
		this.datos = [];

		this.loadList();
	}

	finalizarBusqueda() {
		this.buscando = false;
		this.busqueda = true;
		this.manuallyDetectChanges();
	}

	manuallyDetectChanges(): void {
		if (this.cd && !((this.cd as ViewRef).destroyed)) {
			this.cd.detectChanges();
		}
	}

	addUpdateModel(item: T, useSameObj: boolean = true): void {
		if (!this.checkAddUpdate(item)) return;
		this.onEditModel = true;
		if (useSameObj)
			this.ModelForm.setModel(item, this)
		else
			this.ModelForm.getAndSetModel(this.getDefaulOrKeyModel(item), this);
	}

	saveModel(model: T, onCreateUpdateFinish: (sucess: boolean) => void = undefined) {
		if (this.getDefaulOrKeyModel(model)) {
			let onUpdatePost = (obj: T) => { this.onUpdatePost(obj); };
			SaveDataHelpers.UpdateObject(this.modelServices, this.getDefaulOrKeyModel(model), model, this.MessageDialog,
				onUpdatePost, onCreateUpdateFinish);
		} else {
			let onCreatePost = (T) => { this.onCreatePost(T); };
			SaveDataHelpers.AddObject(this.modelServices, model, this.MessageDialog,
				onCreatePost, onCreateUpdateFinish);
		}
	}

	public deletedItem: T = undefined;
	confirmDelete(item: T) {
		this.deletedItem = item;
		this.MessageDialog.showDialog('Por favor confirme', 'Se eliminará al recurso en forma definitiva. ¿Está seguro que desea continuar?',
			['SI', 'NO'], 'DeleteModel', this);
	}

	onConfirmResultMessageDialog(id: string, ok: boolean) {
		if (ok && id === 'DeleteModel' && this.deletedItem) {
			if (this.modelServices)
				SaveDataHelpers.DeleteObject(this.modelServices, this.getDefaulOrKeyModel(this.deletedItem), this.MessageDialog, () => { this.onDeletePost() },
					(sucess: boolean) => {
						this.deletedItem = undefined;
						this.manuallyDetectChanges();
					});
			else
				this.onDeletePost();
		}
	}
	getDefaulOrKeyModel(obj: T) {
		if (!obj)
			return undefined;
		else {
			let id = this.getKeyModel(obj);
			if (id instanceof Guid)
				return (id.isEmpty()) ? undefined : id;
			else
				return (id <= 0) ? undefined : id;
		}
	}

	//Metodos overrides
	onCreatePost(T): void {
		this.buscar();
	}
	onUpdatePost(T): void {
		this.buscar();
	}
	onDeletePost(): void {
		let index = this.datos.findIndex(o => this.getDefaulOrKeyModel(o) === this.getDefaulOrKeyModel(this.deletedItem));
		if (index > -1) this.datos.splice(index, 1);
	}
	loadList(): void { }
	getKeyModel(T): any { return undefined; }
	checkAddUpdate(T): boolean { return true; }
}

@Directive()
export abstract class CommonControl implements ValidationControlInterface {
	@Input() parent: any = undefined;
	@Input() labelName: string = '';
	@Input() labelVisible: boolean = true;
	@Input() required: boolean = false;
	@Input() key: string = '';
	@Input() focusOnInit: boolean = false;
	@Input() tabindex: number = -1;
	@Input() disabled: boolean = false; // un input disabled no toma foco

	public showValidation: boolean = false;
	public Form: UntypedFormGroup;

	resetForm(): void {
		this.showValidation = false;
		this.Form.markAsPristine();
		this.Form.markAsUntouched();
	}
	isValid(): boolean {
		return this.Form.valid;
	}
	showError(value: boolean): void {
		this.showValidation = value;
	}
	enableControl(enable: boolean) {
		//UN CONTROL DISABLE ES SIEMPRE INVALIDO ! TENER EN CUENTA
		if (enable)
			this.getPrincipalControl().enable();
		else
			this.getPrincipalControl().disable();
	}
	getPrincipalControl(): AbstractControl {
		return this.Form.controls['ItemRef'];
	}

	focus() {}
}

export class SaveDataHelpers {

	static AddObject<T>(service: GenericoService<T>, obj: T, messageDialog: MyMessageDialogComponent,
		onCreatePost: (T) => void, onFinalize: (sucess: boolean) => void): void {
		service.create(obj).subscribe(
			data => {
				if (Utils.checkAPIResponse(data, messageDialog, 'No se pudo crear el recurso.')) {
					obj = data;
					if (onCreatePost) onCreatePost(data);
					if (onFinalize) onFinalize(true);
				}
				else
					if (onFinalize) onFinalize(false);
			},
			error => {
				error = Utils.getLocalStorageErrorMsg(error);
				messageDialog.showExceptionDialog('No se pudo crear el recurso.', Utils.checkDataPortalErrorMessage(error));
				if (onFinalize) onFinalize(false);
			},
			() => { }
		);
	}

	static UpdateObject<T>(service: GenericoService<T>, key: any, obj: T, messageDialog: MyMessageDialogComponent,
		onUpdatePost: (T) => void, onFinalize: (sucess: boolean) => void): void {
		service.update(key, obj).subscribe(
			data => {
				if (Utils.checkAPIResponse(data, messageDialog, 'No se pudo actualizar el recurso.')) {
					if (onUpdatePost) onUpdatePost(data as unknown as T);
					if (onFinalize) onFinalize(true);
				}
				else
					if (onFinalize) onFinalize(false);
			}, error => {
				error = Utils.getLocalStorageErrorMsg(error);
				messageDialog.showExceptionDialog('No se pudo actualizar el recurso.', Utils.checkDataPortalErrorMessage(error));
				if (onFinalize) onFinalize(false);
			},
			() => { }
		);
	}

	static DeleteObject<T>(service: GenericoService<T>, key: any, messageDialog: MyMessageDialogComponent,
		onDeletePost: () => void, onFinalize: (sucess: boolean) => void): void {
		service.delete(key).subscribe(
			data => {
				if (Utils.checkAPIResponse(data, messageDialog, 'No se pudo eliminar el recurso.')) {
					if (onDeletePost) onDeletePost();
					if (onFinalize) onFinalize(true);
				}
				else
					if (onFinalize) onFinalize(false);
			},
			error => {
				error = Utils.getLocalStorageErrorMsg(error);
				messageDialog.showExceptionDialog('No se pudo eliminar el recurso.', error);
				if (onFinalize) onFinalize(false);
			},
			() => { }
		);
	}
}
