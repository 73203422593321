import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
	selector: '[selectFirstInput]'
})
export class AutofocusControlDirective { 
	constructor(private _eRef: ElementRef, private _renderer: Renderer2) { }

	private _getInputElement(nativeElement: any): any {
		let input;

		// whitelist de controles que pueden tomar foco
		if (nativeElement && nativeElement.localName === 'input') return nativeElement;
		if (nativeElement && nativeElement.localName === 'kendo-dropdownlist') return nativeElement;

		if (!nativeElement || !nativeElement.children) return undefined;

		[].slice.call(nativeElement.children).every(c => {
			//console.log(c);
			input = this._getInputElement(c);
			if (input) return false; // break
			return true; // continue!
		});

		return input;
	}

	ngAfterViewInit() {
		let formChildren = [].slice.call(this._eRef.nativeElement.children);

		formChildren.every(child => { 
			let input = this._getInputElement(child);

			if (input) { 
				input.focus();
				return false; // break!
			}

			return true; // continue!
		});
	}
}
