<kendo-grid tabindex="{{tabindex}}" [data]="datos" #grid
			[loading]="buscando2">

	<ng-template kendoGridNoRecordsTemplate>
		No hay hacienda para la venta
	</ng-template>

	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" field="CategoriaGanadoNombre" title="Categoría" width="100">
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span class="footer-item">{{ dataItem.CategoriaGanadoNombre }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" field="Cantidad" title="Cantidad" width="80">
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span class="footer-item">{{ dataItem.Cantidad }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" title="Precio Venta" width="80">
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span>{{ dataItem.PrecioVenta | parseNumberMoney }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" title="Peso Bruto" width="80">
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span>{{ dataItem.PesoCabezaKg | parseNumberKg }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" title="Desbaste" width="80">
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span>{{ dataItem.DesbastePorc | parseNumberPorc }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" title="Peso Neto" width="80">
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span>{{ dataItem.PesoCabezaKg - (dataItem.PesoCabezaKg * dataItem.DesbastePorc) | parseNumberKg }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>

	<div *kendoGridDetailTemplate="let dataItem">
		<kt-faena-list [movTropaRef]="dataItem.MovTropaID" [cantidad]="dataItem.Cantidad" [datos]="dataItem.Faenas"></kt-faena-list>
	</div>
</kendo-grid>
<my-message-dialog></my-message-dialog>
