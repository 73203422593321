import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { ProductoSanitario } from '../../../../../models/producto-sanitario';
import { EditableFormList, EditableForm } from '../../common_controls/common-controls.interface';
import { MessengerService } from '../../../../../services/messenger.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { ProductoSanitarioService } from '../../../../../services/producto-sanitario.service';
import { ProductoSanitarioComponent } from '../producto-sanitario/producto-sanitario.component';
import { ProductoSanitarioFormatoListComponent } from '../producto-sanitario-formato-list/producto-sanitario-formato-list.component';



@Component({
	selector: 'kt-producto-sanitario-crud',
	templateUrl: './producto-sanitario-crud.component.html',
	styleUrls: ['./producto-sanitario-crud.component.scss']
})
export class ProductoSanitarioCrudComponent extends EditableForm<ProductoSanitario> implements OnInit {

	public readonly: boolean = false;

	@ViewChild(ProductoSanitarioComponent, { static: true }) private ProductoSanitario: ProductoSanitarioComponent;
	@ViewChild(ProductoSanitarioFormatoListComponent, { static: true }) private ProductoSanitarioFormatoList: ProductoSanitarioFormatoListComponent;

	constructor(cd: ChangeDetectorRef,
		productoSanitarioService: ProductoSanitarioService,
		messengerService: MessengerService,
		snackBar: MatSnackBar,
		quickPanelService: QuickPanelService) {
		super(cd, productoSanitarioService, snackBar, messengerService, quickPanelService);
	}

	ngOnInit() {
	}

	ngAfterViewInit() {
		this.ProductoSanitario.lstControls.forEach(c => this.lstControls.push(c));
	}

	onCancel(e): void {
		this.ProductoSanitario.productoSanitario = undefined;
		super.onCancel(e);
	}

	setModelPropsByControls(): void {
		this.ProductoSanitario.setModelValues(this.model);
		this.readonly = this.model.ProductoSanitarioID <= 0;
	}

	getNewModel(): ProductoSanitario { return new ProductoSanitario(); };
	getKeyModel(obj: ProductoSanitario): number { return obj.ProductoSanitarioID; };

	setModel(obj: ProductoSanitario, parentCarga: EditableFormList<ProductoSanitario> = undefined) {
		super.setModel(obj, parentCarga);
		this.setModelPropsByControls();
	}
}
