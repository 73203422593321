import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'kt-grid-actions',
	templateUrl: './grid-actions.component.html',
	styleUrls: ['./grid-actions.component.scss']
})
export class GridActionsComponent implements OnInit { 
	@Input() editar: boolean = true; //false oculta el botón
	@Input() eliminar: boolean = true;
	@Input() guardar: boolean = true;
	@Input() cancelar: boolean = true;

	constructor() { }
	ngOnInit() { }

}
