<div class="container">
	<div class="row login__row">
		<div class="col-12 mx-auto">
			<div class="card card-signin my-5">
				<div class="card-body">
					<div class="kt-login__title"></div>
					<div>
						<div>
							<label class="kt-sigef-login--label redirect-text">Redirigiendo...</label>
						</div>
						<div>
							<div class="k-loading-image o-40 redirect-loading"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
