<kt-portlet-header [ngClass]="{'footer': layout == 'footer'}"  class="kt-portlet__head--lg no-print">
	<ng-container ktPortletTitle>
		<h3 *ngIf="!hideHeader" class="kt-portlet__head-title">
			<span>{{title()}}</span>
		</h3>
	</ng-container>

	<ng-container ktPortletTools>
		<ul class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold" role="tablist">
			<li *ngIf="showGoBack" class="nav-item">
				<a class="sigef nav-link pointer" role="tab" (click)="clickGoBack($event)">
					<i class="fa fa-caret-square-left"></i>&nbsp; Volver
				</a>
			</li>
			<li *ngIf="showPrint" class="nav-item">
				<a class="sigef nav-link active pointer" role="tab" (click)="clickPrint($event)">
					<i class="fa fa-print"></i>&nbsp; Imprimir
				</a>
			</li>
			<li *ngIf="showAdd" class="nav-item">
				<a class="sigef nav-link active pointer" role="tab" (click)="clickAdd($event)">
					<i class="fa fa-plus"></i>&nbsp; Nuevo
				</a>
			</li>
			<li *ngIf="showRefresh" class="nav-item">
				<a class="sigef nav-link active pointer" role="tab" (click)="clickRefresh($event)">
					<i class="fa fa-sync"></i>&nbsp; Refrescar
				</a>
			</li>
			<li *ngIf="showEdit" class="nav-item">
				<a class="sigef nav-link active pointer" role="tab" (click)="clickEdit($event)">
					<i class="fa fa-edit"></i>&nbsp; Editar
				</a>
			</li>
			<li *ngIf="showConfirm" class="nav-item">
				<a class="sigef nav-link active pointer" role="tab" (click)="clickConfirm($event)">
					<i class="fa fa-check-double"></i>&nbsp; Confirmar
				</a>
			</li>
			<li *ngIf="showCancel" class="nav-item">
				<a class="danger nav-link active pointer" role="tab" (click)="clickCancel($event)">
					<i class="fa fa-times"></i>&nbsp; Cancelar
				</a>
			</li>
		</ul>
	</ng-container> 
</kt-portlet-header> 
