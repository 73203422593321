<div #corralContainer style="overflow-x: auto">
	<kendo-grid #grid kendoGridPersistentState gridName="GridCorral"
				style="width: auto"
				[kendoGridBinding]="datos"
				[sortable]="true"
				[navigable]="true"
				[resizable]="true"
				[reorderable]="true"
				class="corral"
				[columnMenu]="true"
				(keydown)="onKeydown(grid, $event)"
				[loading]="buscando">
		<kendo-grid-messages noRecords="No hay corrales para mostrar"></kendo-grid-messages>

		<!--<ng-template kendoGridToolbarTemplate>
			<button type="button" kendoGridExcelCommand icon="file-excel">Exportar Excel</button>
		</ng-template>-->

		<kendo-grid-column [reorderable]="false" field="CorralNombre" title="Corral" width="200" [includeInChooser]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<span>
					<span><strong>{{dataItem.CorralNumero | parseCorralNumero}}</strong> ({{dataItem.CorralNombre}})</span>
				</span>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column class="text-left" title="Hacienda" field="Cabezas" width="120" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<h6>Cabezas: {{dataItem.Cabezas}}</h6>
				<ng-container>
					<span style="font-size: 0.9rem">{{dataItem.Resumen}}</span>
				</ng-container>
			</ng-template>
			<ng-template kendoGridFooterTemplate let-column="column">
				<span class="text-left">{{ totalCabezas }} cab.</span>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column class="text-right" field="CapacidadOcupacional" title="% Ocup." width="70" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<span>{{  dataItem.Cabezas / dataItem.CapacidadOcupacional | parseNumberPorc }}</span>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column class="text-right"  field="UltPesada" title="Ult. Pesada" width="100" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<span>{{  dataItem.UltPesada | parseDate }}</span>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column title="Dieta" field="DietaNombre" width="90" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<a href="javascript:;" title="Modificar dieta" (click)="cambiarDieta(dataItem)" class="kt-link">
					{{dataItem.DietaNombre}}
				</a>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column title="MS (Kg x Cab)" field="MetaDiaria" [width]="180" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<my-group-up-down-value #metaUpDown [blockInputOnChange]="true" [dataItem]="dataItem" [value]="dataItem.MetaDiaria" [parseType]="'Kg'" [maxValue]="999.99" [precision]="4" (onChange)="cambiarMS($event, metaUpDown)"></my-group-up-down-value>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column class="text-right" [filterable]="false" field="KgEstimado" title="Peso x Cab." width="100" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<span>{{ dataItem.KgEstimado | parseNumberKg }}</span>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column class="text-right" title="CPC" field="CPC" width="70" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<span>{{ dataItem.CPC | parseNumberKg : false : false }}</span>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column class="text-right" title="GdPV" field="GdPV" width="70" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<span>{{ dataItem.GdPV | parseNumberKg : false : false }}</span>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column class="text-right" title="% P.V." field="PesoVivoPorc" width="70" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<span>{{ dataItem.PesoVivoPorc | parseNumberPorc }}</span>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column [class]="'text-right k-grid-custom-column'" field="Meta" title="Objetivo" width="95" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<span class="font-weight-bold">{{ dataItem.Meta | parseNumberKg }}</span>
			</ng-template>
			<ng-template kendoGridFooterTemplate let-column="column">
				<div align="right">
					<span class="">{{ totalMeta | parseNumberKg }}</span>
				</div>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column [class]="'text-right k-grid-custom-column'" title="Desc." field="Descargado" width="95" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem style="background-color:yellow">
				<span class="font-weight-bold sigef-font-primary-color">{{ dataItem.Descargado | parseNumberKg }}</span>
			</ng-template>
			<ng-template kendoGridFooterTemplate let-column="column">
				<div align="right">
					<span class="sigef-font-primary-color">{{ totalDescargado | parseNumberKg }}</span>
				</div>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column [class]="'text-right k-grid-custom-column'" title="Falt." field="Meta" width="95" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<span class="font-weight-bold sigef-font-red-color">{{ ((dataItem.Meta -  dataItem.Descargado - dataItem.Remanente) <= 0 ? 0 : (dataItem.Meta - dataItem.Descargado - dataItem.Remanente)) | parseNumberKg }}</span>
			</ng-template>
			<ng-template kendoGridFooterTemplate let-column="column">
				<div align="right">
					<span class="sigef-font-red-color">{{ ((totalMeta - totalDescargado - totalRemanente ) <= 0 ? 0 : (totalMeta - totalDescargado - totalRemanente)) | parseNumberKg }}</span>
				</div>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column [class]="'text-right k-grid-custom-column'" title="Rem." width="95" field="Remanente" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<span class="font-weight-bold">{{ dataItem.Remanente == null ? '- Kg' : (dataItem.Remanente | parseNumberKg) }}</span>
			</ng-template>
			<ng-template kendoGridFooterTemplate let-column="column">
				<div align="right">
					<span class="">{{ totalRemanente == null ? '- Kg' : (totalRemanente | parseNumberKg) }}</span>
				</div>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-command-column class="k-grid-ignore-click no-padding" title="" width="55" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
				<button (click)="verConsumo(dataItem)" class="btn btn-sm"><i title="Ver Consumo" class="{{ 'Consumo' | parseIconAccion }}"></i></button>
				<button (click)="verNotificaciones(dataItem)" class="btn btn-sm"><i title="Notificaciones" class="{{ 'Notificacion' | parseIconAccion }}"></i><span class="badge badge-primary">{{ totalNotificaciones(dataItem) }}</span></button>
			</ng-template>
		</kendo-grid-command-column>

		<div *kendoGridDetailTemplate="let dataItem">
			<kt-corral-tropa [corral]="dataItem" [parent]="component" [cliente]="hotelero"></kt-corral-tropa>
		</div>

		<!--<kendo-grid-excel fileName="Corrales.xlsx"></kendo-grid-excel>-->
	</kendo-grid>
</div>

<span class="sigef--grid--note">
	<i class="fas fa-info-circle"></i>&nbsp; Sólo se muestran corrales con tropas asignadas
</span>

<my-message-dialog></my-message-dialog>
