import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { EditableFormList } from '../../common_controls/common-controls.interface';
import { TropaTransporteDTE } from '../../../../../models/tropa-transporte-dte';
import { IParentOpenInDialog, MyMessageDialogComponent } from '../../common_controls/message-dialog/my-message-dialog.component';
import { TropaTransporte } from '../../../../../models/tropa-transporte';
import { TropaTransporteDteComponent } from '../tropa-transporte-dte/tropa-transporte-dte.component';

@Component({
  selector: 'kt-tropa-transporte-dte-list',
  templateUrl: './tropa-transporte-dte-list.component.html',
  styleUrls: ['./tropa-transporte-dte-list.component.scss']
})
export class TropaTransporteDteListComponent extends EditableFormList<TropaTransporteDTE> implements OnInit, IParentOpenInDialog {

	@ViewChild(MyMessageDialogComponent, { static: true }) public MessageDialog: MyMessageDialogComponent;

	@Input() readonly: boolean = false;
	@Input() tabindex: number = -1;

	private _tropaTransporte: TropaTransporte;
	@Input()
	set tropaTransporte(item: TropaTransporte) {
		this._tropaTransporte = item;
		this.datos = (this._tropaTransporte) ? this._tropaTransporte.DTEs : [];
		this.buscando = false;
		this.cd.detectChanges();
	}
	get tropaTransporte(): TropaTransporte { return this._tropaTransporte; }

	constructor(cd: ChangeDetectorRef) { super(cd, undefined) }

	ngOnInit() { }

	getKeyModel(obj: TropaTransporte): any { return obj.TransporteID; }

	public removeHandler({ dataItem }) { this.confirmDelete(dataItem); }

	isNewModel: boolean = false;
	public showABM({ dataItem }) {
		this.isNewModel = dataItem == undefined;
		let title = ((this.isNewModel) ? "Agregar" : "Editar") + " DTE";
		if (this.readonly) { title = 'Ver DTE'; }
		this.MessageDialog.showComponentDialog(title, TropaTransporteDteComponent, this, dataItem, {});
	}

	public saveResultDialogModel(model: TropaTransporteDTE) {
		if (this.isNewModel) {
			this._tropaTransporte.DTEs.push(model);
		}
		else {
			let item = this._tropaTransporte.DTEs.find(x => x.TransporteDTEID == model.TransporteDTEID);
			if (item) { Object.assign(item, model); }
		}
		this.cd.detectChanges();
	}
}
