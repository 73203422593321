import { Component, OnInit, Input } from '@angular/core';
import { Venta } from '../../../../../models/Venta';
import { MessengerService } from '../../../../../services/messenger.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Routing, getRoute } from '../../../themes/sigef/routing';

@Component({
	selector: 'kt-venta-reporte',
	templateUrl: './venta-reporte.component.html',
	styleUrls: ['./venta-reporte.component.scss']
})
export class VentaReporteComponent implements OnInit {
	public venta: Venta;

	public comprador: string = '';
	public destino: string = ''; 

	public totalCabezas: number = 0;
	public totalCompra: number = 0;
	public totalPeso: number = 0;

	@Input() confirmacion: boolean = false;

	public loading: boolean = true;

	cargarVenta(model: any, extras: any): void {
		this.venta = model;

		if (extras) {
			this.comprador = extras.CompradorNombre || '';
			this.destino = extras.VentaDestinoNombre || '';
			this.confirmacion = extras.Confirmacion || false;
		}
		 
		this.loading = false;
	}

	constructor(private router: Router,
		private _location: Location,
		private messengerService: MessengerService) { }

	ngOnInit() {
		this.loading = true;
	}

	confirmHandler(e): void {
		this.messengerService.broadcast('VentaReporteComponent', 'VentaCrud', 'guardar_venta_reporte', {});
	}

	printHandler(e): void {
		window.print();
	}

	goBackHandler(e): void { 
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([getRoute(Routing.HACIENDA_VENTAS_LISTADO)]));
	}

	cancelHandler(e): void {
		this.messengerService.broadcast('VentaReporteComponent', 'VentaCrud', 'cancelar_venta_reporte', {});
	}

	editHandler(e): void {
		this.messengerService.broadcast('VentaReporteComponent', 'VentaCrud', 'editar_venta_reporte', {});
	}
}
