import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { EditableForm } from '../../common_controls/common-controls.interface';
import { MyColDateTimePickerComponent, MyColTextAreaComponent, MyColInputComponent, MyColTimePickerComponent, PesoHaciendaComponent, PrecioCompraVentaComponent, MyColCheckboxComponent } from '../../common_controls';
import Utils from '../../../../../utils';
import { IOpenInDialog, IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { InputCambioCorralModel } from '../../../../../models/draggable-corral';
import { Guid } from 'guid-typescript';  
import { LayoutUtilsService } from '../../../../core/_base/crud';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessengerService } from '../../../../../services/messenger.service';
import { OffCanvasInterface } from '../../../../../interfaces/off-canvas.interface';
import { QuickPanelService } from '../../../../../services/quick-panel.service';

@Component({
	selector: 'kt-tropa-cambio-corral',
	templateUrl: './tropa-cambio-corral.component.html',
	styleUrls: ['./tropa-cambio-corral.component.scss'] 
})
export class TropaCambioCorralComponent extends EditableForm<InputCambioCorralModel> implements OnInit, IOpenInDialog, OffCanvasInterface {
	component: TropaCambioCorralComponent = this;

	public esEncierreVenta: boolean = false;
	public tropaCategoriaGanadoRef: Guid = undefined;

	public pesadaVisible: boolean = false;
	public precioVisible: boolean = this.esEncierreVenta;
	public corralDestinoVacio: boolean = false;

	private bCambiarDieta: boolean = true;

	@ViewChild(MyColDateTimePickerComponent, { static: true }) Fecha: MyColDateTimePickerComponent;
	@ViewChild(MyColTimePickerComponent, { static: true }) Hora: MyColTimePickerComponent;
	@ViewChild('Cantidad', { static: true }) Cantidad: MyColInputComponent; 
	@ViewChild(MyColTextAreaComponent, { static: true }) Observaciones: MyColTextAreaComponent;
	@ViewChild(PesoHaciendaComponent, { static: true }) PesoHacienda: PesoHaciendaComponent;
	@ViewChild(PrecioCompraVentaComponent, { static: true }) PrecioCompraVenta: PrecioCompraVentaComponent;
	@ViewChild(MyColCheckboxComponent, { static: true }) CambiarDieta: MyColCheckboxComponent;

	cantidad: number = 0;
	dietasDistintas(): boolean { return this.model && this.model.DietaDestino && this.model.DietaOrigen && this.model.DietaOrigen !== this.model.DietaDestino; }
	getCorralOrigen(): number { return this.model ? this.model.CorralOrigenRef : undefined }

	constructor(cd: ChangeDetectorRef,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		quickPanelService: QuickPanelService) {
		super(cd, undefined, snackBar, messengerService, quickPanelService);
	}

	ngOnInit() {
		this.lstControls = [this.Fecha, this.Hora, this.Observaciones];
		this.CambiarDieta.clicked = true;
		this.PesoHacienda.initializeControl();
		this.PrecioCompraVenta.initializeControl();
	}

	onClicked(event: any) { 
		this.bCambiarDieta = !this.CambiarDieta.getValue(); // el checkbox es normalmente false 
	}

	setModelControlValues() { 
		this.Fecha.setFecha(new Date(this.model.Fecha));
		this.Hora.setHora(this.model.Fecha);
		this.Observaciones.setText(this.model.Observaciones);
		this.PrecioCompraVenta.TotalInput.setValue(this.model.PrecioVenta);
		this.PesoHacienda.generarPesada = false;
		this.CambiarDieta.setValue(true);

		if (this.model.CambioCorralTipo === 'Tropa') {
			this.Cantidad.setValue(this.model.Cantidad);
			this.PesoHacienda.cantidad = this.model.Cantidad;
			this.PrecioCompraVenta.cantidad = this.model.Cantidad;
			if (this.model.MaxCantidad) {
				this.Cantidad.setMaxValue(this.model.MaxCantidad);
				this.PesoHacienda.cantidad = this.model.MaxCantidad;
				this.PrecioCompraVenta.cantidad = this.model.MaxCantidad;
			}
		}
	}

	setModelPropsByControls() { 
		this.model.Fecha = Utils.getDateWithTime(this.Fecha.getFecha(), this.Hora.getHora());
		this.model.Observaciones = this.Observaciones.getText();
		this.model.CambiarDieta = this.bCambiarDieta && this.corralDestinoVacio && this.dietasDistintas();

		if (this.model.CambioCorralTipo === 'Tropa') {
			this.model.Cantidad = this.Cantidad.getValue();
			this.model.DesbastePorc = this.PesoHacienda.getDesbasteValue();
			this.model.PesoCabezaKg = this.PesoHacienda.getPorCabezaValue(); 
			this.model.GenerarPesada = this.PesoHacienda.generarPesada;
			this.model.PrecioVenta = this.PrecioCompraVenta.getTotalValue();
		}
	}

	setOffCanvasData(data: any, extras: any): void {
		setTimeout(() => {
			if (extras) {
				this.esEncierreVenta = extras.EsEncierreVenta;
				this.tropaCategoriaGanadoRef = extras.TropaCategoriaGanadoRef ? Guid.parse(extras.TropaCategoriaGanadoRef) : undefined; 
				this.corralDestinoVacio = extras.CorralVacio;
			}

			this.setModel(data);

			if (this.esEncierreVenta) {
				this.Cantidad.focus();
			} else {
				this.Fecha.focus();
			}

			if (this.model.CambioCorralTipo === 'Tropa')
				this.lstControls.push(this.Cantidad);
			this.cd.detectChanges();
		}, 0); // esperar un tick
	}

	openInDialog(model: InputCambioCorralModel, extras: any) {
		// lo dejo para tener compatiblidad con mostrar en un dialogo
		this.openedInDialog = true;
		if (extras) {
			this.esEncierreVenta = extras.EsEncierreVenta;
			this.tropaCategoriaGanadoRef = Guid.parse(extras.TropaCategoriaGanadoRef) || undefined;
		}
		this.setModel(model);
	}

	onCambioSave(e: any) {
		if (!this.runValidaciones()) {
			return false;
		} 
		this.setModelPropsByControls(); 
		this.getMessengerInstance().broadcast(undefined, undefined, 'TropaCambioCorralModel', this.model);
		this.getQuickPanelInstance().hideQuickPanel();
	}

	onCambioCancel(e: any) {
		this.getQuickPanelInstance().hideQuickPanel();
	}

	getNewModel(): InputCambioCorralModel { return new InputCambioCorralModel(); };

	editResultDialog(formParent: IParentOpenInDialog): boolean {
		return false;
	}

	saveResultDialog(formParent: IParentOpenInDialog): boolean {
		if (formParent) {
			if (!this.runValidaciones()) {
				return false;
			}
			this.setModelPropsByControls();
			formParent.saveResultDialogModel(this.model);
		}
		return true;
	}
	 
	onInputValueChange(item: any, labelName: string) {
		this.cantidad = this.Cantidad.getValue();
	}

	validarPesada($event: any) {
		this.pesadaVisible = $event;

		// agregar o quitar la validación del campo de peso cabeza dependiendo de si el usuario tilda o no la opción
		if (this.pesadaVisible) {
			this.lstControls.push(this.PesoHacienda.CabezaInput)
		} else {
			this.lstControls.splice(this.lstControls.indexOf(this.PesoHacienda.CabezaInput), 1);
		}
	}

	runCustomValidaciones() {
		if (Utils.getDateWithTime(this.Fecha.getFecha(), this.Hora.getHora()) > new Date()) {
			this.MessageDialog.showAtencionDialog('La fecha y hora del movimiento no puede ser mayor a la actual.');
			return false;
		}

		return true;
	}
}
