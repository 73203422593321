import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http'; 
import { CategoriaGanado } from '../models/categoria-ganado';

@Injectable()
export class CategoriaGanadoService extends GenericoService<CategoriaGanado> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'categoriaganado');
	}


}
