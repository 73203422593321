<div>
	<div class="row">
		<my-col-datetimepicker selectFirstInput [focusOnInit]="true" [tabindex]="tabindex" [labelName]="'Fecha Venta'" [required]="true"></my-col-datetimepicker>
		<my-col-timepicker [tabindex]="tabindex + 1" [labelName]="'Hora Venta'" [required]="true"></my-col-timepicker>
	</div>
	<div class="row pt-1">
		<my-col-dropdownlist [tabindex]="tabindex + 2" #Comprador [labelName]="'Comprador'" [required]="false" [filterable]="true" [parent]="component" [key]="'Comprador'"></my-col-dropdownlist>
		<my-col-dropdownlist [tabindex]="tabindex + 3" #VentaDestino [labelName]="'Destino'" [required]="false" [filterable]="true" [parent]="component" [key]="'VentaDestino'"></my-col-dropdownlist>
	</div> 
</div>
