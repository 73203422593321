import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'parseIconAccion'
})
export class ParseIconAccionPipe implements PipeTransform {

	transform(e: any, ...args: any[]): string {
		if (e === 'Editar')
			return 'fas fa-edit pointer k-text-primary';
		else if (e === 'Eliminar')
			return 'fas fa-trash pointer k-text-error';
		else if (e === 'Confirmar')
			return 'fas fa-check kt-font-success';
		else if (e === 'Agregar')
			return 'fas fa-plus kt-font-success';
		else if (e === 'Ver')
			return 'fas fa-eye pointer kt-font-info';
		else if (e === 'Consumo')
			return 'fas fa-chart-area pointer k-text-primary';
		else if (e === 'Cancelar')
			return 'fas fa-times';
		else if (e === 'Reporte')
			return 'fas fa-file-alt pointer k-text-primary';
		else if (e === 'Aplicar')
			return 'fas fa-syringe pointer k-text-info'
		else if (e === "Notificacion")
			return "fas fa-pencil-alt";
		else if (e === 'Refrescar')
			return "fas fa-redo-alt";
	}

}
