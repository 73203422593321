import { ChangeDetectorRef, QueryList, OnInit, OnDestroy, Input, ViewChildren, ViewChild, AfterViewInit, Directive } from '@angular/core';
import { CorralService } from '../../../../services/corral.service';
import { MyColDropDownListComponent, MyMessageDialogComponent, MyColDateTimePickerComponent, MyColInputSearchComponent, MyColTimePickerComponent, MyColInputComponent, MyColTextAreaComponent, PesoHaciendaComponent, PrecioCompraVentaComponent, MyRowExpandComponent } from '../common_controls';
import { NameValueList } from '../../../../interfaces/nvl.interface';
import { DietaService } from '../../../../services/dieta.service';
import { MessengerService } from '../../../../services/messenger.service';
import { CorralInfo } from '../../../../models/corral-info';
import { Subscription, Observable } from 'rxjs';
import { EditableFormList, EditableForm } from '../common_controls/common-controls.interface';
import { TropaDashboard } from '../../../../models/tropa-dashboard';
import { Guid } from 'guid-typescript';
import { MovTropa } from '../../../../models/mov-tropa';
import { IOpenInDialog, IParentOpenInDialog } from '../common_controls/message-dialog/my-message-dialog.component';
import { PesoEstimadoInterface } from '../../../../interfaces/peso-estimado.interface';
import { TropaService } from '../../../../services/tropa.service';
import { MovTropaService } from '../../../../services/mov-tropa.service';
import { EstimadoTropaCategoria } from '../../../../models/estimado-tropa-categoria';
import Utils, { MovTropaTipo } from '../../../../utils';
import { CategoriaGanadoService } from '../../../../services/categoria-ganado.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OffCanvasInterface } from '../../../../interfaces/off-canvas.interface';
import { QuickPanelService } from '../../../../services/quick-panel.service';

@Directive()
export abstract class HelpersTropaMovimiento extends EditableForm<MovTropa> implements OnInit, AfterViewInit, OnDestroy, IOpenInDialog, OffCanvasInterface {
	@Input() public tipoMovimiento: number;
	@Input() public movimientoNombre: string;
	@Input() public esIngreso: boolean;
	@Input() public esRecuento: boolean = false;
	@Input() public esParicion: boolean = false;
	@Input() public esMuerte: boolean = false;
	@Input() public esCambio: boolean = false;
	@Input() public esPesada: boolean = false;

	public filtroExpandido: boolean = true;
	public datosExpandido: boolean = false;

	public pesadaVisible: boolean = true;
	public precioVisible: boolean = true;

	public precioTitle: string = 'Precio';
	public labelCabezas: string = 'Cantidad de Cabezas';

	public tropaCategoriaGanadoRef: Guid;

	public esVenta: boolean = false;

	private onObservacionesExpandSub: Subscription = undefined;
	private onPesadaExpandSub: Subscription = undefined;
	private onPrecioExpandSub: Subscription = undefined;

	@ViewChild(MyColDateTimePickerComponent, { static: true }) Fecha: MyColDateTimePickerComponent;
	@ViewChild(MyColTimePickerComponent, { static: true }) Hora: MyColTimePickerComponent;
	@ViewChild('Corral', { static: true }) Corral: MyColInputSearchComponent;
	@ViewChild('CorralDestino', { static: true }) CorralDestino: MyColInputSearchComponent;
	@ViewChild('Tropa', { static: true }) Tropa: MyColDropDownListComponent;
	@ViewChild('CategoriaGanado', { static: true }) CategoriaGanado: MyColDropDownListComponent;
	@ViewChild('CategoriaGanadoCambio', { static: true }) CategoriaGanadoCambio: MyColDropDownListComponent;
	@ViewChild('Cantidad', { static: true }) Cantidad: MyColInputComponent;
	CantidadActual: number = 0;
	@ViewChild(MyColTextAreaComponent, { static: true }) Observaciones: MyColTextAreaComponent;
	@ViewChild(PesoHaciendaComponent, { static: true }) PesoHacienda: PesoHaciendaComponent;
	@ViewChild(PrecioCompraVentaComponent, { static: true }) PrecioHacienda: PrecioCompraVentaComponent;
	@ViewChild('AumentoDiario', { static: true }) AumentoDiario: MyColInputComponent;
	@ViewChild('ObservacionesExpand', { static: true }) ObservacionesExpand: MyRowExpandComponent;
	@ViewChild('PesadaExpand', { static: true }) PesadaExpand: MyRowExpandComponent;
	@ViewChild('PrecioExpand', { static: true }) PrecioExpand: MyRowExpandComponent;

	tropaSeleccionada: NameValueList = undefined;
	corralSeleccionado: NameValueList = undefined;
	hacienda: PesoEstimadoInterface[] = [];
	haciendaSeleccionada: PesoEstimadoInterface = undefined;

	cantidad: number = 0;

	cabezasLabelNombre(): string { return (this.esRecuento) ? 'Cabezas (Recuento)' : 'Cabezas'; }

	guardarButtonName(): string { return 'Guadar ' + this.movimientoNombre || '' }

	constructor(private corralService: CorralService,
		private tropaService: TropaService,
		movTropaService: MovTropaService,
		private categoriaGanadoService: CategoriaGanadoService,
		cd: ChangeDetectorRef,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		quickPanelService: QuickPanelService) {
		super(cd, movTropaService, snackBar, messengerService, quickPanelService);
		this.getQuickPanelInstance().setQuickPanelLoadingStatus(true);
	}

    ngAfterViewInit(): void {
		this.getQuickPanelInstance().setQuickPanelLoadingStatus(false);
    }

	setOffCanvasData(data: any, extras: any): void {
		this.getAndSetModel(data.MovTropaID, undefined, extras);
	}

	setExtras(extras: any): void {
		if (extras) {
			this.esVenta = extras.EsVenta || false;
			this.model.VentaRef = undefined;

			if (extras.VentaRef) {
				this.model.VentaRef = Guid.parse(extras.VentaRef) || undefined;
			}
		}
		this.setLstControls();
	}

	ngOnDestroy() {
		if (this.onObservacionesExpandSub) { this.onObservacionesExpandSub.unsubscribe(); }
		if (this.onPesadaExpandSub) { this.onPesadaExpandSub.unsubscribe(); }
		if (this.onPrecioExpandSub) { this.onPrecioExpandSub.unsubscribe(); }
	}

	ngOnInit() {
		this.setLstControls();

		this.Corral.initializeControlByCorral(this.corralService);
		this.CorralDestino.initializeControlByCorral(this.corralService);
		this.Tropa.initializeControl(undefined, this.cd, 'Seleccione una Tropa', Guid.createEmpty());
		this.CategoriaGanado.initializeControl(undefined, this.cd, 'Seleccione una Categoría', Guid.createEmpty());
		this.CategoriaGanadoCambio.initializeControl(this.categoriaGanadoService, this.cd, 'Seleccione una Categoría', 0);
		this.PesoHacienda.initializeControl();
		this.PrecioHacienda.initializeControl();
		this.Cantidad.setParent(this);

		// defaults
		this.precioVisible = true;
		this.pesadaVisible = true;
		this.labelCabezas = 'Cantidad de Cabezas';
		this.precioTitle = 'Precio';

		// Indicar que componentes validar segun tipo
		if (this.esRecuento || this.esMuerte) {
			this.precioVisible = false;
			this.pesadaVisible = false;
		}

		if (this.esPesada) {
			this.precioVisible = false;
			this.labelCabezas = 'Cabezas Pesadas';
		}

		if (this.esParicion) {
			this.precioTitle = 'Precio Estimado';
		}

		if (this.esCambio) {
			this.precioVisible = false;
			this.pesadaVisible = false;
			this.PesoHacienda.generarPesada = false;
		}

		if (this.esParicion) {
			this.precioVisible = false;
			this.pesadaVisible = false;
		}

		this.cargarHacienda();

		this.resetModel();
		this.save.subscribe(o => {
			this.resetModel();
			this.getMessengerInstance().broadcastMessage('MOV_SAVED');
		});

		this.onObservacionesExpandSub = this.ObservacionesExpand.onExpand.subscribe(expanded => {
			if (!expanded) {
				this.Observaciones.setText('');
			}
		});

		this.onPesadaExpandSub = this.PesadaExpand.onExpand.subscribe(expanded => {
			this.PesoHacienda.generarPesada = expanded;
		});

		this.onPrecioExpandSub = this.PrecioExpand.onExpand.subscribe(expanded => {
			if (!expanded) {
				this.PrecioHacienda.CabezaInput.setValue(0);
			}
		});
	}

	cargarHacienda(): void {
		this.tropaService.getPesoEstimado(new EstimadoTropaCategoria()).subscribe(
			data => {
				this.hacienda = data;
			},
			error => {

			},
			() => {
				this.cd.detectChanges();
			}
		);
	}

	setLstControls(): void {
		this.lstControls.forEach(c => c.showError(false));

		this.lstControls = [this.Fecha, this.Hora, this.Corral, this.Cantidad, this.Observaciones];

		if (this.isNewModel) {
			this.lstControls.push(this.Tropa);
			this.lstControls.push(this.CategoriaGanado);
		}

		if (this.esCambio || this.esParicion) {
			this.lstControls.push(this.CategoriaGanadoCambio);
		}

		if (this.esParicion) {
			this.lstControls.push(this.AumentoDiario, this.CorralDestino);
		}

		if (this.precioVisible && this.isNewModel) {
			this.lstControls.push(this.PrecioHacienda.CabezaInput);
		}

		if (this.pesadaVisible && this.isNewModel) {
			this.lstControls.push(this.PesoHacienda.CabezaInput);
			this.lstControls.push(this.PesoHacienda.DesbasteInput);
		}
	}

	onSave(e, repeat: boolean = false): void {
		this.setLstControls();
		super.onSave(e, repeat);
	}

	getKeyModel(obj: MovTropa): Guid { return obj.MovTropaID; };

	setModelControlValues() {
		if (!this.model) {
			this.tropaSeleccionada = undefined;
			this.corralSeleccionado = undefined;
			this.Tropa.setValue(Guid.createEmpty());
			this.cargarTropas([]);
			this.cargarCategoriaGanado([]);
			this.Corral.setValue(0);
			this.CorralDestino.setValue(0);
			this.AumentoDiario.setValue(0);
			this.Fecha.setFecha(new Date());
			this.Hora.setHora(new Date());
			this.CantidadActual = 0;
			this.Cantidad.setValue(0);
			this.PesoHacienda.CabezaInput.setValue(0);
			this.PesoHacienda.DesbasteInput.setValue(0);
			this.PesoHacienda.generarPesada = false;
			this.PrecioHacienda.CabezaInput.setValue(0);
			this.PrecioHacienda.TotalInput.setValue(0);
			this.Observaciones.setText(undefined);
		}
		else {
			this.Corral.setValue(this.model.CorralRef);
			this.Fecha.setFecha(new Date(this.model.FechaMovimiento));
			this.Hora.setHora(this.model.FechaMovimiento);
			this.Cantidad.setValue(Utils.checkNaNGetZero(this.model.Cantidad));
			this.cantidad = Utils.checkNaNGetZero(this.model.Cantidad);
			this.cd.detectChanges();
			this.PesoHacienda.CabezaInput.setValue(this.model.PesoCabezaKg);
			this.PesoHacienda.DesbasteInput.setValue(this.model.DesbastePorc);
			this.PesoHacienda.generarPesada = this.model.IncluyePesada;
			this.PesoHacienda.actualizarPesoFinal(false);
			this.PrecioHacienda.CabezaInput.setValue(this.model.PrecioVenta);
			this.PrecioHacienda.actualizarPrecio();
			this.Observaciones.setText(this.model.Observaciones);

			this.adicionalesVisible = (this.model.Observaciones && this.model.Observaciones.length > 0);
		}
	}

	setModelPropsByControls() {
		if (this.isNewModel) {
			this.model = new MovTropa();
			this.model.MovTropaTipoRef = this.tipoMovimiento;
			this.model.TropaCategoriaGanadoRef = this.CategoriaGanado.getValue();
		}
		this.model.CorralRef = this.Corral.getItem().Key;
		this.model.FechaMovimiento = Utils.getDateWithTime(this.Fecha.getFecha(), this.Hora.getHora());
		this.model.Cantidad = this.Cantidad.getValue();
		this.model.DesbastePorc = this.PesoHacienda.getDesbasteValue();
		this.model.PesoCabezaKg = this.PesoHacienda.getPorCabezaValue();
		this.model.Observaciones = this.Observaciones.getText();
		this.model.PrecioVenta = this.PrecioHacienda.getPorCabezaValue();
		if (this.esRecuento && this.haciendaSeleccionada) {
			let cantidad = (+this.model.Cantidad) - this.haciendaSeleccionada.Cabezas;
			this.model.MovTropaTipoRef = (cantidad < 0 ? MovTropaTipo.AjusteNegativo : MovTropaTipo.AjustePositivo);
			this.model.Cantidad = Math.abs(cantidad);
		}
		this.model.GenerarPesada = this.PesoHacienda.generarPesada;
	}

	onInputValueChange(newValue, labelName): void {
		if (labelName.indexOf('Cabezas') > -1) {
			this.cantidad = newValue;
		}
	}

	onInputSearchValueChange(newValue, labelName, itemSelected): void {
		if (itemSelected && itemSelected.Key && labelName === 'Corral') { // si hay algo seleccionado y es un corral valido
			this.Tropa.setValue(Guid.createEmpty());
			this.corralSeleccionado = itemSelected;
			this.tropaSeleccionada = undefined;
			this.CategoriaGanado.setValue(Guid.createEmpty());
			this.haciendaSeleccionada = undefined;
			let listado: PesoEstimadoInterface[] = [];
			Object.assign(listado, this.hacienda);
			this.cargarTropas(listado.filter(r => r.CorralRef === itemSelected.Key));
		}
	}

	onDropDownValueChanged(item: NameValueList, key: string) {
		if (this.onSetProperties) return;
		if (key === 'Categoria') {
			if (this.hacienda) {
				let detalle = this.hacienda.find(r => r.CorralRef === this.corralSeleccionado.Key && r.TropaCategoriaGanadoID.equals(Guid.parse(item.Key)));
				this.haciendaSeleccionada = detalle;
				let cantidad = (detalle) ? detalle.Cabezas : 0;
				this.CantidadActual = Utils.checkNaNGetZero(cantidad);
				if (!this.esParicion && !this.esRecuento) {
					// paricion y recuento (ajuste) no tiene limite
					this.Cantidad.setMaxValue(this.CantidadActual);
				}
				this.cd.detectChanges();
			}
		}
		else if (key === 'NuevaCategoria') {
			//this.CategoriaGanadoCambio.setValue(item.Key);
		}
		else if (key === 'Tropa') {
			this.tropaSeleccionada = item;
			this.CategoriaGanado.setValue(Guid.createEmpty());
			let id = this.tropaSeleccionada.Key;

			if (!Guid.parse(id).isEmpty()) {
				let listado: PesoEstimadoInterface[] = [];
				id = Guid.parse(id);
				Object.assign(listado, this.hacienda);
				this.cargarCategoriaGanado(listado.filter(r => r.TropaID.equals(id)));
			}
		}
	}

	cargarTropas(data: any[]) {
		data = [...new Set(data.map(obj => obj.TropaNombre))].map(item => { return data.find(obj => obj.TropaNombre === item) })
		data.sort((a, b) => (a.TropaNombre > b.TropaNombre) ? 1 : -1);

		this.Tropa.setData(data, 'TropaID', 'TropaNombre');
		if (data.length === 0) {
			this.Tropa.setValue(Guid.createEmpty());
			this.Tropa.enableControl(false);
			this.CategoriaGanado.enableControl(false);
		}
		else {
			this.Tropa.enableControl(true);
			this.cargarCategoriaGanado([]);
		}
	}

	cargarCategoriaGanado(data: any[]) {
		/* filtrar las categorias EN el corral seleccionado, sino se pueden repetir las categorias */
		data = data.filter(r => r.CorralRef === this.Corral.getItem().Key);
		data.sort((a, b) => (a.CategoriaGanadoNombre > b.CategoriaGanadoNombre) ? 1 : -1);

		this.CategoriaGanado.setData(data, 'TropaCategoriaGanadoID', 'CategoriaGanadoNombre');

		if (data.length === 0) {
			this.CategoriaGanado.setValue(Guid.createEmpty());
			this.CategoriaGanado.enableControl(false);
		}
		else {
			this.CategoriaGanado.enableControl(true);
		}
	}

	openInDialog(model: MovTropa, extras: any) {
		this.openedInDialog = true;
		this.setModel(model);
		if (extras) {
			this.esVenta = extras.EsVenta || false;
			this.model.VentaRef = undefined;

			if (extras.VentaRef) {
				this.model.VentaRef = Guid.parse(extras.VentaRef) || undefined;
			}
		}
		this.setLstControls();
	}

	editResultDialog(formParent: IParentOpenInDialog): boolean {
		return false;
	}

	saveResultDialog(formParent: IParentOpenInDialog): boolean {
		if (formParent) {
			if (!this.runValidaciones()) {
				return false;
			}
			this.setModelPropsByControls();
			formParent.saveResultDialogModel(this.model);
		}
		return true;
	}
}

@Directive()
export abstract class HelpersTropaEditDietaMS extends EditableFormList<CorralInfo> implements OnInit, OnDestroy  {
	@Input() tropa: TropaDashboard = undefined;
	tropaId(): Guid {
		return (this.tropa) ? this.tropa.TropaID : Guid.createEmpty();
	}

	mostrarDietaDropdown: boolean = false;
	corralID: number = 0;
	dietasLookUp: NameValueList[] = [];
	totalCabezas: number = 0;
	totalMeta: number = 0;
	totalDescargado: number = 0;
	totalRemanente: number = 0;

	MessageDialog: MyMessageDialogComponent;
	@ViewChildren(MyColDropDownListComponent) Dietas: QueryList<MyColDropDownListComponent>;
	DietaSeleccionada: MyColDropDownListComponent = undefined;

	messengerSubscription: Subscription;

	constructor(private messengerService: MessengerService,
		private dietaService: DietaService,
		public corralService: CorralService,
		cd: ChangeDetectorRef,
		private quickPanelService: QuickPanelService) {
		super(cd, undefined)
	}

	ngOnInit() {
		this.messengerSubscription = this.messengerService.messageBroadcasted$.subscribe(r => {
			if (r.type) {
				if (r.type === 'msCorral') {
					for (const corral of this.datos) {
						if (corral.CorralID === r.CorralID && r.TropaID != this.tropaId()) {
							corral.MetaDiaria = r.MS;
						}
					}
					this.cd.detectChanges();
				}
				else if (r.type == 'dietaCorral') {
					for (const corral of this.datos) {
						if (corral.CorralID === r.CorralID && r.TropaID != this.tropaId()) {
							corral.DietaRef = r.DietaRef;
						}
					}
					this.cd.detectChanges();
				}
			}
		});
		this.buscar();
	}

	getMessengerInstance(): MessengerService {
		return this.messengerService;
	}

	getQuickPanelInstance(): QuickPanelService {
		return this.quickPanelService;
	}

	getHaciendaPorTropaEnCorral(dataItem: any): string[] {
		let hacienda: string[] = [];

		for (const d of this.datos) {
			if (d.CorralID === dataItem.CorralID) {
			for (const t of d.Tropas) {
					if (t.TropaRef.equals(this.tropa.TropaID)) {
					hacienda.push(` ${t.CategoriaGanadoAbrev}: ${t.Cantidad}`)
				 }
				}
			}
		}

		return hacienda;
	}

	getCabezasPorTropaEnCorral(dataItem: any): number {
		let totalCabezas: number = 0;

		for (const d of this.datos) {
			if (d.CorralID === dataItem.CorralID) {
				for (const t of d.Tropas) {
					if (t.TropaRef.equals(this.tropa.TropaID)) { totalCabezas += t.Cantidad; }
				}
			}
		}

		return totalCabezas;
	}

	loadList(): void {
		this.getInfoList().subscribe(
			(data: CorralInfo[]) => {
				this.datos = [];
				for (const d of data) {
					if (d.Activo && d.Tropas.length > 0) {
						this.datos.push(d);
					}
				}
			},
			error => { },
			() => {
				this.actualizarSumatorias();
				this.finalizarBusqueda();
			}
		);
	}

	actualizarSumatorias() {
		this.totalCabezas = 0;
		this.totalMeta = 0;
		this.totalDescargado = 0;
		this.totalRemanente = 0;
		for (const d of this.datos) {
			if (d) {
				this.totalCabezas += d.Cabezas;
				this.totalMeta += d.Meta;
				this.totalDescargado += d.Descargado;
				this.totalRemanente += d.Remanente;
			}
		}
	}

	getInfoList(): Observable<CorralInfo[]> {
		return this.corralService.getInfoByTropa(this.tropa.TropaID);
	}

	ngOnDestroy() {
		if (this.messengerSubscription) {
			this.messengerSubscription.unsubscribe();
		}
	}

	cambiarMS({ Item, Valor }, control): void {
		Item.MetaDiaria = Valor;
		if (!Item.MetaDiaria)
			Item.MetaDiaria = 0;

		if (control && control.blockInputOnChange) {
			this.messengerService.broadcastMessage({
				Reason: 'blockUIControl',
				Target: control.id
			});
		}

		this.corralService.updateMS(Item.CorralID, Item.MetaDiaria).subscribe(data => {
			this.messengerService.broadcastMessage({
				type: 'msCorral',
				CorralID: Item.CorralID,
				MS: Item.MetaDiaria,
				TropaID: this.tropaId()
			});

			if (control && control.blockInputOnChange) {
				this.messengerService.broadcastMessage({
					Reason: 'releaseUIControl',
					Target: control.id
				});
			}
		}, error => { });
	}

	abrirDieta(item: CorralInfo): void {
		if (this.dietasLookUp.length == 0) {
			this.dietaService.getLookup().subscribe(
				data => {
					this.dietasLookUp = data;
					this.initializeDietaControl(item);
				},
				error => { }, () => { }
			);
		}
		else
			this.initializeDietaControl(item);
	}

	initializeDietaControl(item: CorralInfo) {
		this.mostrarDietaDropdown = true;
		this.corralID = item.CorralID;
		this.DietaSeleccionada = this.Dietas.find(d => +d.key === this.corralID);;
		if (this.DietaSeleccionada) {
			this.DietaSeleccionada.setDefaultValue(0, 'Seleccione una Dieta')
			this.DietaSeleccionada.setData(this.dietasLookUp);
			this.DietaSeleccionada.setValue(item.DietaRef);
		}
	}

	actualizarDieta(item: CorralInfo): void {
		if (!this.DietaSeleccionada) return;
		let dieta = this.DietaSeleccionada.getValue();
		if (dieta === item.DietaRef) return;
		if (dieta === 0) {
			this.MessageDialog.showAtencionDialog('Debe seleccionar una dieta obligatoriamente.')
			return;
		}
		this.corralService.getById(item.CorralID).subscribe(dataCorral => {
			if (dataCorral) {
				dataCorral.DietaRef = dieta;
				this.corralService.update(item.CorralID, dataCorral).subscribe(data => {
					this.messengerService.broadcastMessage({
						type: 'dietaCorral',
						CorralID: dataCorral.CorralID,
						DietaRef: dataCorral.DietaRef,
						TropaID: this.tropaId()
					});
					this.mostrarDietaDropdown = false;
					this.corralID = 0;
					this.buscar();
				});
			}
		});
	}
}
