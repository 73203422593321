<div class="container">
	<div class="row login__row">
		<div class="col-12 mx-auto">
			<div class="card card-signin my-5">
				<div class="card-body">
					<div class="kt-login__title">
						<h3 class="forgot-password forgot-password-title">{{ 'AUTH.FORGOT.TITLE' | translate }}</h3>
					</div>
					<form class="form-signin">
						<h6 class="forgot-password forgot-password-desc">{{ 'AUTH.FORGOT.DESC' | translate }}</h6>

						<div class="form-label-group">
							<my-col-input [tabindex]="2" #Email [type]="2" [labelName]="'AUTH.INPUT.EMAIL' | translate" [required]="true" [divClass]="''" [labelClass]="'kt-sigef-login--label'"></my-col-input>
						</div>

						<div class="text-center pr-3 pt-2" tabindex="-1">
							<button routerLink="/auth/login" [disabled]="loading" id="kt_login_signup_cancel" class="btn btn-sigef-secondary btn-sigef-login mx-2 ">{{ 'AUTH.GENERAL.BACK_BUTTON' | translate }}</button>
							<button (click)="submit()" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}" id="kt_login_signin_submit" class="btn btn-sigef btn-sigef-login mx-2">{{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
