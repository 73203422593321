import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { TropaDashboard } from '../../../../../models/tropa-dashboard';
import { TropaService } from '../../../../../services/tropa.service'; 
import { EditableFormList } from '../../common_controls/common-controls.interface';
import { IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { BroadcastMessage } from '../../../../../interfaces/message.interface';
import { MessengerService } from '../../../../../services/messenger.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { getRoute, Routing } from '../../../themes/sigef/routing';
import { TropasSeguimientoComponent } from '../../../pages';

@Component({
	selector: 'kt-tropa-seguimiento-list',
	templateUrl: './tropa-seguimiento-list.component.html',
	styleUrls: ['./tropa-seguimiento-list.component.scss'],
	providers: [TropaService]
})
export class TropaSeguimientoListComponent extends EditableFormList<TropaDashboard> implements OnInit, OnDestroy, IParentOpenInDialog  {
    saveResultDialogModel(model: any): void {
      // throw new Error("Method not implemented.");
	}

	public hotelero: number = 0;
	public alimentacion: number = 0;
	private sub: Subscription;
	public totalCabezas: number = 0;

	@Input() public set cliente(data: any) {
		this.hotelero = data ? (typeof (data) === 'number' ? data : data.Key) : 0;
		this.buscar();
	}

	@Input() public set dieta(data: any) {
		this.alimentacion = data ? (typeof (data) === 'number' ? data : data.Key) : 0;
		this.buscar();
	}

	constructor(private router: Router, private tropaService: TropaService, private messengerService: MessengerService, cd: ChangeDetectorRef) { super(cd, undefined) }

	ngOnInit() {
		this.sub = this.messengerService.messageBroadcasted$.subscribe((data: BroadcastMessage) => {
			if (data.Reason === "editar_tropa") {
				this.parent.verTropa(data.Payload.TropaID);
			}
		});
	}

	ngOnDestroy() {
		if (this.sub) { this.sub.unsubscribe(); }
	} 

	loadList(): void { 
		this.tropaService.getDashboardList(this.hotelero, this.alimentacion).subscribe(
			data => {
				this.datos = data;
				this.totalCabezas = data.reduce((sum, current) => sum + current.Cabezas, 0);
			},
			error => { },
			() => {
				this.finalizarBusqueda();
			}
		);
	}

	parent: TropasSeguimientoComponent;
	setParent(form: TropasSeguimientoComponent) {
		this.parent = form;
	}

	abrirTropa(item: any): void {
		this.router.navigate([getRoute(Routing.TROPAS_REPORTES, true, true), item.TropaID.toString()]);
	}
}
