import { DTOInterface } from "../interfaces/dto.interface";
import { Guid } from "guid-typescript";

export class PlanSanitario implements DTOInterface { 
	PlanSanitarioID: number;
	FechaPlan: Date;
	TropaCategoriaGanadoRef: Guid;
	ProductoSanitarioRef: number;
	CantidadUMCab: number;
	CantidadCab: number;
	Grupo: Guid;

	ProductoSanitarioNombre: string;
	UnidadMedidaNombre: string;
	UnidadMedidaAbrev: string;
	TropaRef: Guid;
	CodigoTropa: number;
	TropaNombre: string;
	CategoriaGanadoNombre: string;
	ColorRef: number;
	ColorPrincipal: string;
	ColorFuente: string;
	ColorFondo: string;
	Propietario: string;
	CorralID: number;
	CorralNumero: number;
	CorralNombre: string;
	CabezasActuales: number;
	Aplicado: boolean;

	constructor() {
		this.clear();
	}

	clear(): void {
		this.PlanSanitarioID = -1;
		this.FechaPlan = new Date();
		this.TropaCategoriaGanadoRef = undefined;
		this.ProductoSanitarioRef = undefined;
		this.CantidadUMCab = 0;
		this.CantidadCab = 0;
		this.Grupo = undefined;
	}
}
