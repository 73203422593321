import { DTOInterface } from "../interfaces/dto.interface";

export class ReportConsumo implements DTOInterface {
	Fecha: Date;
	AlimentoNombre: string;
	Valor: number;
	Total: number;

	constructor() {
		this.clear();
	}

	clear(): void {
		this.Fecha = undefined;
		this.AlimentoNombre = '';
		this.Valor = 0;
		this.Total = 0;
	}
}
