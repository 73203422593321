<div *ngIf="!loading && chartCount == 0" class="mb-3" role="alert">
	<kt-message-info [text]="'No existen corrales con los filtros seleccionados'"></kt-message-info>
</div>

<div *ngIf="!loading && chartData">
	<div *ngFor="let corral of chartData | keyvalue : originalOrder" class="col-md-12 grafico-separador">
		<kendo-chart>
			<kendo-chart-tooltip format="{0}"></kendo-chart-tooltip>
			<kendo-chart-legend position="bottom"></kendo-chart-legend>
			<kendo-chart-title font="12pt" align="left" [text]="titulo(corral.value)"></kendo-chart-title>

			<kendo-chart-value-axis>
				<kendo-chart-value-axis-item *ngFor="let item of valueAxis"
											 [name]="item.name"
											 [title]="{ text: item.title }"
											 [min]="item.min"
											 [max]="item.max"
											 [majorUnit]="item.majorUnit"
											 [color]="item.color"
											 [visible]="item.visible">
				</kendo-chart-value-axis-item>
			</kendo-chart-value-axis>

			<kendo-chart-category-axis>
				<kendo-chart-category-axis-item [maxDivisions]="1"
												[categories]="etiquetas(corral.value)"
												[title]="{ text: 'Días' }"
												[labels]="{ rotation: 'auto' }"
												[axisCrossingValue]="[0, 1e6]">
				</kendo-chart-category-axis-item>
			</kendo-chart-category-axis>

			<kendo-chart-series>

				<kendo-chart-series-item name="Consumo MS por Cabeza (Kg)"
										 type="line"
										 [data]="dataConsumidoPorCabeza(corral.value)"
										 [color]="'#34A853'"
										 axis="kilogramos"
										 [style]="'smooth'">
					<kendo-chart-series-item-tooltip>
						<ng-template let-value="value">
							<strong>Consumo:</strong> {{ value | parseNumberKg : false : false }}
						</ng-template>
					</kendo-chart-series-item-tooltip>
				</kendo-chart-series-item>

				<kendo-chart-series-item name="Temperatura"
										 type="rangeArea"
										 [data]="corral.value"
										 fromField="TemperaturaMinima"
										 toField="TemperaturaMaxima"
										 [color]="'#fd7300'"
										 axis="temperatura"
										 [line]="{'style': 'smooth'}">
					<kendo-chart-series-item-tooltip>
						<ng-template let-value="value">
							<strong>Máxima:</strong> {{ value.to }} °C<br /><strong>Mínima:</strong> {{ value.from }} °C
						</ng-template>
					</kendo-chart-series-item-tooltip>

				</kendo-chart-series-item>

				<kendo-chart-series-item width='0'
										 name="Precipitaciones"
										 type="line"
										 [data]="dataPrecipitaciones(corral.value)"
										 [color]="'#0085ff'"
										 [highlight]="highlight"
										 axis="default"
										 [markers]="markers('precipitaciones')">
					<kendo-chart-series-item-tooltip>
						<ng-template let-category="category">
							<strong>Precipitaciones:</strong> {{ tooltip(category, corral.value, 'Precipitaciones') | parsePrecipitaciones }}
						</ng-template>
</kendo-chart-series-item-tooltip>
				</kendo-chart-series-item>

				<kendo-chart-series-item width='0'
										 name="Ingresos"
										 type="line"
										 [data]="dataMovimientos(corral.value, 'ingresos')"
										 [highlight]="highlight"
										 [markers]="markers('ingresos')"
										 axis="default"
										 [color]="'#256940'">
					<kendo-chart-series-item-tooltip>
						<ng-template let-category="category">
							<div [innerHTML]="tooltipMovimientos(category, corral.value, 'ingresos')"></div>
						</ng-template>
					</kendo-chart-series-item-tooltip>
				</kendo-chart-series-item>

				<kendo-chart-series-item width='0'
										 name="Egresos"
										 type="line"
										 [data]="dataMovimientos(corral.value, 'egresos')"
										 [highlight]="highlight"
										 [markers]="markers('egresos')"
										 axis="default"
										 [color]="'#fd0026'">
					<kendo-chart-series-item-tooltip>
						<ng-template let-category="category">
							<div [innerHTML]="tooltipMovimientos(category, corral.value, 'egresos')"></div>
						</ng-template>
					</kendo-chart-series-item-tooltip>
				</kendo-chart-series-item>

				<kendo-chart-series-item width='0'
										 name="Observaciones"
										 type="line"
										 [data]="dataObservaciones(corral.value)"
										 [highlight]="highlight"
										 [markers]="markers('observacionesInfoDiaria')"
										 axis="default"
										 [color]="'#4d4742'">
					<kendo-chart-series-item-tooltip>
						<ng-template let-category="category">
							<span *ngIf="tooltip(category, corral.value, 'Observaciones') !==''"><strong>Información de descarga:</strong> {{ tooltip(category, corral.value, 'Observaciones') }}</span>
							<br *ngIf="tooltip(category, corral.value, 'ObservacionesAlimentacion') !=='' && tooltip(category, corral.value, 'Observaciones') !==''">
							<span *ngIf="tooltip(category, corral.value, 'ObservacionesAlimentacion') !==''"><strong>Información diaria:</strong> {{ tooltip(category, corral.value, 'ObservacionesAlimentacion') }}</span>
						</ng-template>
					</kendo-chart-series-item-tooltip>
				</kendo-chart-series-item>

			</kendo-chart-series>

		</kendo-chart>
	</div>
</div>
