<div [ngClass]="{'d-none': corrales.length > 0}" class="row mt-1 mr-0 ml-0">
	<kt-message-info [text]="'No hay datos con los filtros seleccionados'"></kt-message-info>	
</div>

<div class="ml-0 mr-0 row" [ngClass]="{'d-none': corrales.length === 0}">
	<div class="kanban-board mt-2" *ngFor="let corral of corrales">
		<header class="kanban-board-header corral-box " [ngStyle]="{'background-color': corral.corral.DietaColor}">
			<p class="kanban-title-board mb-1" [ngStyle]="{'color': corral.corral.DietaColorFuente}">
				{{corral.corral.CorralNumero | parseCorralNumero}}: {{corral.corral.CorralNombre}}
				<br />
				<span class="kanban-sub-title-board">
					{{corral.corral.DietaNombre}} - {{corral.corral.MetaDiaria | parseNumberKgMS }}
				</span>
			</p>
			<small class="text--cantidad" [ngStyle]="{'color': corral.corral.DietaColorFuente}">{{corral.total()}}</small>
		</header>

		<main class="kanban-drag">
			<my-group-corral-tropa [draggable]="draggable" [corral]="corral" (onDrop)="drop($event, corral.corral)" [allowDelete]="allowDelete" (onDelete)="delete($event)"></my-group-corral-tropa>
		</main>

		<footer></footer>
	</div>
</div>
