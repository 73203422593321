// Angular
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

@Component({
	selector: 'kt-alert',
	templateUrl: './alert.component.html'
})
export class AlertComponent implements OnInit {
	// Public properties
    @Input() type: string = '';
    @Input() duration: number = 0;
    @Input() icon: string = '';
	@Input() showCloseButton: boolean = true;
	@Output() close = new EventEmitter<boolean>();
    alertShowing: boolean = true; 

	//constructor(private cd: ChangeDetectorRef) {

	//}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
    ngOnInit() { 
        switch (this.type) {
            case 'primary':
            case 'info':
                this.icon = 'info-circle';
				break;
			case 'warn':
			case 'warning':
                this.icon = 'exclamation-circle';
                break;
            case 'accent':
                this.icon = 'question-circle';
                break;
            case 'success':
                this.icon = 'check-circle';
                break;
            default:
                this.icon = 'exclamation-circle';
                break;
        }

		if (this.duration > 0) {
            setTimeout(() => {
                this.closeAlert();
            }, this.duration);
		}
	}

	/**
	 * close alert
	 */
	closeAlert() { 
		this.alertShowing = false;
		this.close.emit(); 
	}
}
