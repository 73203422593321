import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'kt-popup-server-validation',
  templateUrl: './popup-server-validation.component.html',
  styleUrls: ['./popup-server-validation.component.scss']
})
export class PopupServerValidationComponent implements OnInit {

	@Input() formGroup: UntypedFormGroup;
	@Input() column: any;
	@Input() input: any;
	@Input() submitted: boolean;
	
	constructor() { }
	
	ngOnInit() { }

}
