import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';
import { Cliente } from '../models/cliente';

@Injectable()
export class ClienteService extends GenericoService<Cliente> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'cliente');
	}
}
