import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';
import { UnidadMedida } from '../models/unidad-medida';


@Injectable()
export class UnidadMedidaService extends GenericoService<UnidadMedida> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'unidadmedida');
	}
}
