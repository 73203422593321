import { Component, OnInit, ChangeDetectorRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NameValueList } from '../../../../../interfaces/nvl.interface';
import { MovProductoSanitario } from '../../../../../models/mov-producto-sanitario';
import { ProductoSanitarioService } from '../../../../../services/producto-sanitario.service';
import { MovProductoSanitarioTipoService } from '../../../../../services/mov-producto-sanitario-tipo.service';
import { ProveedorService } from '../../../../../services/proveedor.service';
import { MovProductoSanitarioService } from '../../../../../services/mov-producto-sanitario.service'; 
import { MyColDropDownListComponent, MyColInputComponent, MyColTextAreaComponent, MyMessageDialogComponent, MyColDateTimePickerComponent, MyColTimePickerComponent, MyColLabelInfoComponent } from '../../common_controls';
import Utils, { MovProductoSanitarioTipo, MovProductoSanitarioTipoAgrupador } from '../../../../../utils';
import { EditableForm } from '../../common_controls/common-controls.interface';
import { Guid } from 'guid-typescript';
import { LayoutUtilsService } from '../../../../core/_base/crud';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessengerService } from '../../../../../services/messenger.service';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { ParseNumberPipe } from '../../../../../pipes/parse-number.pipe';
import { ProductoSanitario } from '../../../../../models/producto-sanitario';

@Component({
    selector: 'kt-movproductosanitario',
    templateUrl: './movproductosanitario.component.html',
    styleUrls: ['./movproductosanitario.component.scss'],
	providers: [ProductoSanitarioService, MovProductoSanitarioService, MovProductoSanitarioTipoService, ProveedorService]
})
export class MovProductoSanitarioComponent extends EditableForm<MovProductoSanitario> implements OnInit {

	@Input() set agrupador(value: MovProductoSanitarioTipoAgrupador) {
		this.movTipo = value;
		this.FechaMovimiento.focus();
		this.setLstControls();
		this.onInitSetModel();
		this.setStockActualInfoText();
	}

	public cantidadReal: string = 'Cantidad';
	public stockActual: number = 0;
	public movTipo: MovProductoSanitarioTipoAgrupador = 0;
	public component: MovProductoSanitarioComponent = this;

	private producto: ProductoSanitario = undefined;

	@Input() edicion: boolean = false;
	@Output() onClose: EventEmitter<any> = new EventEmitter(); 

	@ViewChild('FechaMovimiento', { static: true }) FechaMovimiento: MyColDateTimePickerComponent;
	@ViewChild('Producto', { static: true }) Producto: MyColDropDownListComponent;
	@ViewChild('Cantidad', { static: true }) Cantidad: MyColInputComponent;
	@ViewChild('CantPropuesta', { static: true }) CantPropuesta: MyColLabelInfoComponent;
	@ViewChild(MyColTextAreaComponent, { static: true }) Observaciones: MyColTextAreaComponent; 

	constructor(private productoSanitarioService: ProductoSanitarioService,
		private movProductoSanitarioTipoService: MovProductoSanitarioTipoService,
		movProductoSanitarioService: MovProductoSanitarioService,
		cd: ChangeDetectorRef,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		quickPanelService: QuickPanelService) {
		super(cd, movProductoSanitarioService, snackBar, messengerService, quickPanelService);
	}

	ngOnInit() {
		this.Producto.initializeControl(this.productoSanitarioService, this.cd, 'Seleccione un Producto...');

		super.setModel(this.model);
	}

	getKeyModel(obj: MovProductoSanitario): Guid { return obj.MovProductoSanitarioID; };
	getNewModel(): MovProductoSanitario { return new MovProductoSanitario(); };

	onInitSetModel() {
		this.adicionalesVisible = false;
		this.CantPropuesta.infoText = new ParseNumberPipe().transform(0, true);
		super.onInitSetModel();
	}

	runCustomValidaciones() { 
		return true;
	}

	onCancel($event: any) {
		this.resetValidaciones();
		this.resetModel();
		this.Producto.setValue(0);
		this.stockActual = 0;
		this.setStockActualInfoText();
		super.setModel(undefined);
		this.adicionalesVisible = false;
		this.onClose.emit($event);
	}

	onSave(e, repeat: boolean = false): void {
		this.setLstControls();
		super.onSave(e, repeat);
	}

	informSave(sucess: boolean) {
		if (sucess) {
			this.onCancel('sucess');
		}

		super.informSave(sucess);
	}

	setModelControlValues() {
		if (this.model) {
			this.FechaMovimiento.setFecha(new Date(this.model.FechaMovimiento));
			this.Producto.setValue(this.model.ProductoSanitarioRef);
			this.Cantidad.setValue(this.model.CantidadUM);	
			this.Observaciones.setText(this.model.Observaciones);
			if (this.model.ProductoSanitarioRef > 0) {
				this.getStock(this.model.ProductoSanitarioRef);
			}
		}
	}

	setModelPropsByControls() {
		if (!this.model) { this.model = this.getNewModel() };
		this.model.FechaMovimiento = this.FechaMovimiento.getFecha();
		this.model.ProductoSanitarioRef = this.Producto.getValue(); 
		this.model.CantidadUM = this.Cantidad.getValue();
		this.model.Observaciones = this.Observaciones.getText();
		this.model.ProductoSanitarioFormatoRef = undefined;
		
		this.model.FechaCompra = undefined; 
		this.model.ProveedorRef = undefined;
		this.model.CompraRef = undefined;
		this.model.RemitoNro = undefined;
		this.model.CorralRef = undefined;
		this.model.PlanSanitarioRef = undefined;
		this.model.TropaCategoriaGanadoRef = undefined;
		this.model.CantidadCab = 0;

		if (this.movTipo === MovProductoSanitarioTipoAgrupador.Ajuste) {		
			if (this.stockActual < this.Cantidad.getValue()) {
				this.model.MovProductoSanitarioTipoRef = MovProductoSanitarioTipo.EntradaAjuste;
				this.model.CantidadUM = (this.Cantidad.getValue() - this.stockActual);
			} else {
				this.model.MovProductoSanitarioTipoRef = MovProductoSanitarioTipo.SalidaAjuste;
				this.model.CantidadUM = (this.stockActual - this.Cantidad.getValue());
			}
		}

		if (this.movTipo === MovProductoSanitarioTipoAgrupador.Uso) {
			this.model.MovProductoSanitarioTipoRef = MovProductoSanitarioTipo.SalidaUso;
		}
	}

	onDropDownValueChanged(item: NameValueList, key: string) {
		if (key === 'Producto') {
			if (item.Key > 0) {
				this.getStock(item.Key);
				this.getProducto(item.Key);
			}
		}
	}

	getProducto(productoID): void {
		this.productoSanitarioService.getById(productoID).subscribe(
			data => {
				this.producto = data;
			},
			error => {

			},
			() => {
				this.cantidadReal = `Cantidad (${this.producto.UnidadMedidaNombre})`;
				this.setStockActualInfoText(); 
			}
		);
	}

	getStock(productoID): void {
		this.productoSanitarioService.getStock(productoID).subscribe(
			data => {
				this.stockActual = data;
			},
			error => {
				this.stockActual = 0;
				Utils.showSnackBarErrorMessage(this.snackBar, 'No se puede mostrar el stock actual del producto seleccionado');
			},
			() => { 
				this.setStockActualInfoText();
			}
		)
	}

	setStockActualInfoText(): void {
		let infoText = '-';
		
		if (this.Producto.getValue() > 0) {
			infoText = new ParseNumberPipe().transform(this.stockActual, true);
			if (this.producto) {
				infoText += ` ${this.producto.UnidadMedidaAbrev}`;
			}
		}

		this.CantPropuesta.infoText = infoText;
		this.cd.detectChanges();
	}

	setLstControls() {
		this.lstControls = [this.FechaMovimiento, this.Producto, this.Cantidad, this.Observaciones];		 
	}
}
