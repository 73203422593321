<kt-portlet>
	<div class="kt-portlet__head"  [ngClass]="{'d-none': cliente!==0}">
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">
				Tropas Históricas
			</h3>
		</div>
	</div>
	<kt-portlet-body>
		<div class="row-sigef">
			<my-col-daterange #rangoFecha></my-col-daterange>
			<my-col-dropdownlist [ngClass]="{'d-none': cliente!==0}" [extraClass]="'mov-dropdown'" #Propietario [labelName]="'Propietario'"></my-col-dropdownlist>

			<my-col-button [buscando]="buscando" (click)="buscar()"></my-col-button>
		</div>
		<div class="kt-divider kt-divider-sigef"><span></span></div>

		<div class="p-3">
			<div style="overflow-x: auto">
				<kendo-grid [kendoGridBinding]="datos" kendoGridPersistentState gridName="GridTropaHistorica"
							style="width: auto"
							[sortable]="true"
							[navigable]="true"
							[resizable]="true"
							[reorderable]="true"
							[columnMenu]="true"
							[loading]="buscando">
					<kendo-grid-messages noRecords="No hay tropas históricas para los filtros seleccionados">
					</kendo-grid-messages>

					<kendo-grid-column class="no-padding" [filterable]="false" title="Tropa" field="TropaNombre" width="240" [includeInChooser]="false">
						<ng-template kendoGridCellTemplate let-dataItem>
							<div class="ribbon-content" style="height:100%; width:100%; position:relative;" [style.borderColor]="dataItem.ColorPrincipal">
								<div class="h-100">
									<a class="kt-link center-div" title="Ver reporte detallado" href="javascript:;" (click)="abrirTropa(dataItem)">
										<span>{{dataItem.CodigoTropa | parseCodigoTropa}} - {{dataItem.TropaNombre}} </span>
									</a>
								</div>
							</div>
						</ng-template>
					</kendo-grid-column>

					<kendo-grid-column *ngxPermissionsExcept="['Hotelero']" field="ClienteNombre" title="Propietario" width="100" [columnMenu]="false">
					</kendo-grid-column>

					<kendo-grid-column title="Fecha de Ingreso" width="100" field="FechaIngreso" [columnMenu]="false">
						<ng-template kendoGridCellTemplate let-dataItem>
							<span>{{dataItem.FechaIngreso | parseDate}}</span>
						</ng-template>
					</kendo-grid-column>

					<kendo-grid-column title="Fecha de Cierre" width="100" field="FechaCierre" [columnMenu]="false">
						<ng-template kendoGridCellTemplate let-dataItem>
							<span>{{dataItem.FechaCierre | parseDate}}</span>
						</ng-template>
					</kendo-grid-column>

					<kendo-grid-column class="text-left" title="Hacienda" field="Cabezas" width="120" [columnMenu]="false">
						<ng-template kendoGridCellTemplate let-dataItem>
							<h6>Cabezas: {{dataItem.Cabezas}}</h6>
							<ng-container>
								<span style="font-size: 0.9rem">{{dataItem.Resumen}}</span>
							</ng-container>
						</ng-template>
						<ng-template kendoGridFooterTemplate let-column="column">
							<span class="text-left">{{ totalCabezas }} cab.</span>
						</ng-template>
					</kendo-grid-column>

					<kendo-grid-column class="text-right" title="Cant. de Muertes" field="CantidadMuertes" width="100" [columnMenu]="false">
						<ng-template kendoGridCellTemplate let-dataItem>
							<span>{{dataItem.CantidadMuertes + dataItem.CantMuertosArribo | parseNumber : true }}</span>
						</ng-template>
					</kendo-grid-column>

					<kendo-grid-command-column class="k-grid-ignore-click no-padding" title="" width="30" [columnMenu]="false">
						<ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
							<button (click)="verReporteResumido(dataItem)" class="btn btn-sm"><i title="Ver reporte resumido" class="{{ 'Reporte' | parseIconAccion }}"></i></button>
						</ng-template>
					</kendo-grid-command-column>
				</kendo-grid>
			</div>
		</div>
	</kt-portlet-body>
</kt-portlet>

<my-message-dialog></my-message-dialog>
