import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'parsePrecipitaciones'
})
export class ParsePrecipitacionesPipe implements PipeTransform {

	transform(e: number): any {
			return `${e} mm`;
	}
} 
