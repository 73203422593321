import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http'; 
import { ProductoSanitario } from '../models/producto-sanitario'; 
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class ProductoSanitarioService extends GenericoService<ProductoSanitario> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'productosanitario');
	}

	getStock(productoID): Observable<number> {
		return this._httpClient.get<number>(environment.apiUrl + `/${this._endpoint}/stock/${productoID}`, { headers: this._getHeaders });
	}
}
