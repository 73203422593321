<div>
	<div class="pt-4">
		<my-row-expand [disabled]="true" #ObservacionesAlimentacionExpand [title]="'Observaciones'" [hint]="'Ingrese el detalle de algún imprevisto que implique un desvío en las descargas del día (por ejemplo, rotura de mixer, ausencia de personal, etc)'" [labelName]="'Observaciones'" [expanded]="adicionalesVisible" (onExpand)="adicionalesVisible=$event"></my-row-expand>
		<div class="row-sigef" [ngClass]="{'d-none': !adicionalesVisible}">
			<my-col-textarea selectFirstInput [focusOnInit]="true" [tabindex]="tabindex" #ObservacionesAlimentacion [required]="true" [labelName]="'Observaciones'" [rows]="2" [labelVisible]="false" class="w-100"></my-col-textarea>
		</div>
	</div>
	<div class="row-sigef">
		<my-col-input [tabindex]="tabindex + 1" [mask]="'# mm'" #Precipitaciones [labelName]="'Precipitaciones'" [decimals]="0"></my-col-input>
	</div>

	<div class="ml-3 mr-3 pl-3 mt-3 row" [ngClass]="{'d-none': openedInDialog}">
		<my-group-save-buttons [tabindex]="tabindex + 2" class="w-100" [saving]="saving"
							   (onSave)="onInfoDiariaSave($event)" (onCancel)="onInfoDiariaCancel($event)"></my-group-save-buttons>
	</div>
</div>
<my-message-dialog></my-message-dialog>
