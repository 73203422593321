import { Component, OnInit, Input, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MyMessageDialogComponent } from '../../common_controls';
import { EditableFormList } from '../../common_controls/common-controls.interface';
import { IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { Compra } from '../../../../../models/compra';
import { MovAlimento } from '../../../../../models/mov-alimento';
import { MessengerService } from '../../../../../services/messenger.service';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { Subject } from 'rxjs';
import { BroadcastMessage } from '../../../../../interfaces/message.interface';
import { MovalimentoCompraAlimentoComponent } from '../movalimento-compra-alimento/movalimento-compra-alimento.component';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'kt-movalimento-compra-alimento-list',
	templateUrl: './movalimento-compra-alimento-list.component.html',
	styleUrls: ['./movalimento-compra-alimento-list.component.scss']
})
export class MovalimentoCompraAlimentoListComponent extends EditableFormList<Compra> implements OnInit, OnDestroy, IParentOpenInDialog {
	private destroy$: Subject<void> = new Subject();
	private deletedMovItem: MovAlimento = undefined;
	private _compra: Compra;

	public totalPrecio: number = 0;
	public totalCantidad: number = 0;	
	public movimientos: MovAlimento[] = [];


	@ViewChild(MyMessageDialogComponent, { static: true }) public MessageDialog: MyMessageDialogComponent;

	@Input() tabindex: number = -1;
	@Input()
	set compra(item: Compra) {
		this._compra = item;
		if (this._compra) {
			this.movimientos = this._compra.MovAlimentos;
		} else {
			this.movimientos = [];
		}
		this.totales();
		this.buscando = false;
		this.cd.detectChanges();
	}
	get compra(): Compra { return this._compra; }

	constructor(cd: ChangeDetectorRef, private messengerService: MessengerService, private quickPanelService: QuickPanelService) { super(cd, undefined) }

	totales(): void {
		this.totalCantidad = 0;
		this.totalPrecio = 0;

		for (const i of this.movimientos) {
			this.totalCantidad += i.CantidadRealKg;
			this.totalPrecio += i.Precio;
		}

		this.cd.detectChanges();
	}

	ngOnInit() {
		this.messengerService.messageBroadcasted$.pipe(takeUntil(this.destroy$)).subscribe((data: BroadcastMessage) => {
			if (data) {
				if (data.Reason === 'MovAlimentoCompraAlimento') {
					this.saveResultDialogModel(data.Payload);
				}
			}
		});
	}

	onInputValueChange(newValue, labelName): void { }

	ngOnDestroy() {
		this.destroy$.next();
	}

	getKeyModel(obj: Compra): any { return obj.CompraID; }

	public removeHandler({ dataItem }) { this.confirmDelete(dataItem); }

	isNewModel: boolean = false;
	public showABM({ dataItem }) {
		this.isNewModel = dataItem == undefined;

		let title = 'Agregar Producto';

		if (!this.isNewModel) {
			title = 'Editar Producto: ' + dataItem.AlimentoNombre;
		}

		this.quickPanelService.createOffCanvasComponent(title, MovalimentoCompraAlimentoComponent, dataItem);
	}

	onDeletePost() {
		super.onDeletePost();
		this.totales();
	}

	onUpdatePost(e: Compra) {
		super.onUpdatePost(e);
		this.totales();
	}
	 
	confirmDelete(item: any) {
		this.deletedMovItem = item;
		this.MessageDialog.showDialog('Por favor confirme', 'Se eliminará al recurso en forma definitiva. ¿Está seguro que desea continuar?',
			['SI', 'NO'], 'DeleteModel', this);
	}

	onConfirmResultMessageDialog(id: string, ok: boolean): void { 
		if (ok && id === 'DeleteModel' && this.deletedMovItem) {
			this.movimientos.splice(this.movimientos.indexOf(this.deletedMovItem), 1);
			this.messengerService.broadcast(undefined, undefined, 'DeleteMovAlimentoCompra', undefined);
			this.totales();
		}
	}

	public saveResultDialogModel(model: MovAlimento) {
		if (this.isNewModel) {
			if (this._compra.MovAlimentos.find(t => t.AlimentoRef === model.AlimentoRef && t.AlimentoAlmacenRef === model.AlimentoAlmacenRef)) {
				this.MessageDialog.showAtencionDialog('Ya existe este producto para este almacen. Edite o elimine el registro existente.')
				return;
			}
	
			this._compra.MovAlimentos.push(model);
		}
		else {
			let item = this._compra.MovAlimentos.find(x => x.AlimentoRef == model.AlimentoRef && x.AlimentoAlmacenRef === model.AlimentoAlmacenRef);
			if (item) { Object.assign(item, model); }
		}

		this.totales();
		this.cd.detectChanges();
	}

	round(t1: number): number {
		return Math.round(t1);
	}
}
