import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http'; 
import { CorralDescarga } from '../models/corral-descarga'; 
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { Guid } from 'guid-typescript';
import { DescargaDashboard } from '../models/descarga-dashboard';

@Injectable()
export class CorralDescargaService extends GenericoService<CorralDescarga> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'corraldescarga');
    }

	getDescargasMixer(mixerCargaID: Guid): Observable<CorralDescarga[]> {
        return this._httpClient.get<CorralDescarga[]>(environment.apiUrl + `/${this._endpoint}/descargas/${mixerCargaID.toString()}`, { headers: this._getHeaders });
	}

	getDescargaDashboardList(dias: number, desvio: number): Observable<DescargaDashboard[]> {
		return this._httpClient.get<DescargaDashboard[]>(`${environment.apiUrl}/${this._endpoint}/descargaDashboard/${dias}/${desvio}`, { headers: this._getHeaders });
	}
}
