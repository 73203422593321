<kendo-grid *ngIf="showGrid" [kendoGridBinding]="cargas"
			[sortable]="true"
			[navigable]="true"
			[loading]="buscando">
	<kendo-grid-messages noRecords="No hay cargas para los filtros seleccionados">
	</kendo-grid-messages>

	<kendo-grid-column title="Fecha" width="130">
		<ng-template kendoGridCellTemplate let-dataItem>
			{{dataItem.FechaCarga | parseDateHour }}
		</ng-template>
		<ng-template kendoGridFooterTemplate let-column="column">
			<strong>Totales</strong>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column title="Preparador" field="UsuarioNombre" width="150">
	</kendo-grid-column>
	<kendo-grid-column title="Mixer" field="MixerNombre" width="150">
	</kendo-grid-column>
	<kendo-grid-column title="Dieta" field="DietaNombre" width="150">
	</kendo-grid-column>
	<kendo-grid-column title="Propuesto" width="110">
		<ng-template kendoGridCellTemplate let-dataItem>
			{{dataItem.CantidadPropuestaKg | parseNumberKg }}
		</ng-template>
		<ng-template kendoGridFooterTemplate let-column="column">
			<strong>{{ totalPropuesto | parseNumberKg }}</strong>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column title="Cargado" width="110">
		<ng-template kendoGridCellTemplate let-dataItem>
			{{dataItem.CantidadRealKg | parseNumberKg }}
		</ng-template>
		<ng-template kendoGridFooterTemplate let-column="column">
			<strong>{{ totalCargado | parseNumberKg }}</strong>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column title="Exactitud" width="75">
		<ng-template kendoGridCellTemplate let-dataItem>
			{{getExactitud(dataItem) | parseNumberPorc : true }}
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column *ngIf="tipoReporte === 2"  title="Descargado" width="120">
		<ng-template kendoGridCellTemplate let-dataItem>
			{{dataItem.CantidadDescargada | parseNumberKg }}
		</ng-template>
		<ng-template kendoGridFooterTemplate let-column="column">
			<strong>{{ totalDescargado | parseNumberKg }}</strong>
		</ng-template>
	</kendo-grid-column>

	<div *kendoGridDetailTemplate="let dataItem">
		<kt-carga-alimento-list *ngIf="tipoReporte === 1" [Alimentos]="dataItem.Alimentos" [buscando]="buscando"></kt-carga-alimento-list>
		<kt-carga-descarga-list *ngIf="tipoReporte === 2" [Descargas]="dataItem.Descargas" [buscando]="buscando"></kt-carga-descarga-list>
	</div>
</kendo-grid>
