<kendo-grid tabindex="{{tabindex}}"
			[data]="datos"
			(edit)="showABM($event)"
			(remove)="removeHandler($event)"
			(add)="showABM($event)"
			[loading]="buscando"
			style="width: 620px">
	<ng-template kendoGridToolbarTemplate>
		<button tabindex="{{tabindex + 1}}" kendoGridAddCommand class="add-btn btn-sigef">
			Agregar Documento de Tránsito Electrónico (DTE)
		</button>
	</ng-template>
	<ng-template kendoGridNoRecordsTemplate>
		No hay DTEs para esta Venta
	</ng-template>

	<kendo-grid-column title="Número" width="100">
		<ng-template kendoGridCellTemplate let-dataItem="dataItem">
			<span>{{ dataItem.DTENro }}</span>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column title="Cantidad" width="100">
		<ng-template kendoGridCellTemplate let-dataItem="dataItem">
			<span>{{ dataItem.DTECantidad | parseNumber : true }}</span>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-command-column class="k-grid-ignore-click" title="Acciones" width="120">
		<ng-template kendoGridCellTemplate>
			<button kendoGridEditCommand class="btn btn-sm"><i title="Editar" class="{{'Editar' | parseIconAccion }}"></i></button>
			<button kendoGridRemoveCommand class="btn btn-sm k-grid-ignore-click"><i title="Eliminar" class="{{'Eliminar' | parseIconAccion }}"></i></button>
		</ng-template>
	</kendo-grid-command-column>
</kendo-grid>

<my-message-dialog></my-message-dialog>
