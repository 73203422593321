<div class="col w-100">
	<div class="row row-full-height">
		<div class="kt-portlet kt-portlet--mobile">
			<div class="kt-portlet__head">
				<div class="kt-portlet__head-label">
					<h3 class="kt-portlet__head-title">
						<i class="fas fas fa-info-circle"></i>&nbsp; Datos de Producto Sanitario
					</h3>
				</div>
			</div>
			<div class="kt-portlet__body">
				<kt-producto-sanitario [productoSanitario]="model"></kt-producto-sanitario>
			</div>
		</div>
	</div>
</div>

<div class="col w-100">
	<div class="row row-full-height">
		<div class="kt-portlet kt-portlet--mobile">
			<div class="kt-portlet__head">
				<div class="kt-portlet__head-label">
					<h3 class="kt-portlet__head-title">
						<i class="fas fas fa-calculator"></i>&nbsp; Formatos de Venta
					</h3>
				</div>
			</div>
			<div class="kt-portlet__body">
				<kt-producto-sanitario-formato-list [productoSanitario]="model"></kt-producto-sanitario-formato-list>
			</div>
		</div>
	</div>
</div>

<div class="col w-100">
	<div class="row row-full-height">
		<div class="kt-portlet kt-portlet--mobile">
			<div class="kt-portlet__body">
				<div class="row mr-2 ml-2">
					<my-group-save-buttons class="w-100" [saving]="saving" [deleting]="deleting" [allowDelete]="!readonly" [targetName]="'Producto Sanitario'"
										   (onSave)="onSave($event)" (onCancel)="onCancel($event)" (onDelete)="onDelete($event)"></my-group-save-buttons>
				</div>
			</div>
		</div>
	</div>
</div>

<my-message-dialog></my-message-dialog>
