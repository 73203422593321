<div class="col" style="min-width: 320px; width: 320px;">
	<div class="form-group" [formGroup]="Form">
		<label class="pb-1" *ngIf="labelVisible">{{labelName}}</label>
		<div class="input-group">
			<kendo-combobox #controlInput
							tabindex="{{tabindex}}"
							[data]="corrales"
							formControlName="Input"
							[filterable]="true"
							(filterChange)="handleCorralFilter($event)"
							(valueChange)="valueChange($event)"
							[placeholder]="placeholder"
							textField="Descripcion"
							valueField="Key"
							style="width: 100%"
							[clearButton]="false"
							[suggest]="true"
							[valuePrimitive]="true">
			</kendo-combobox>
		</div>
		<kt-inline-server-validation [ngClass]="{'d-none': !showValidation}" [input]="controlInput" [formGroup]="Form" [field]="'Description'"></kt-inline-server-validation>
		<span *ngIf="hint" class="form-text text-muted">{{hint}}</span>
	</div>
</div>
