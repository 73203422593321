import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { ProductoSanitario } from '../../../../../models/producto-sanitario';
import { MyColSwitchComponent, MyColInputComponent, MyColDropDownListComponent } from '../../common_controls';
import { CategoriaPSService } from '../../../../../services/categoria-ps.service';
import { UnidadMedidaService } from '../../../../../services/unidad-medida.service';
import { NameValueList } from '../../../../../interfaces/nvl.interface';
import { MessengerService } from '../../../../../services/messenger.service';
import { ValidationControlInterface } from '../../common_controls/common-controls.interface';

@Component({
	selector: 'kt-producto-sanitario',
	templateUrl: './producto-sanitario.component.html',
	styleUrls: ['./producto-sanitario.component.scss'],
	providers: [UnidadMedidaService]
})
export class ProductoSanitarioComponent implements OnInit {

	@ViewChild('ProductoSanitarioNombre', { static: true }) ProductoSanitarioNombre: MyColInputComponent;
	@ViewChild('Activo', { static: true }) Activo: MyColSwitchComponent;
	@ViewChild('StockMinimo', { static: true }) StockMinimo: MyColInputComponent;
	@ViewChild('CategoriaPS', { static: true }) CategoriaPS: MyColDropDownListComponent;
	@ViewChild('UnidadMedida', { static: true }) UnidadMedida: MyColDropDownListComponent;

	@Input() public tabindex: number = 100;

	lstControls: ValidationControlInterface[];

	public component: ProductoSanitarioComponent = this;
	public model: ProductoSanitario;

	public _productoSanitario: ProductoSanitario;
	@Input() set productoSanitario(model: ProductoSanitario) {
		this._productoSanitario = model;
		if (this._productoSanitario) {
			this.resetModel();
			this.sendUnidadMedida(model.UnidadMedidaRef);
			setTimeout(() => {
				this.ProductoSanitarioNombre.focus();
			}, 0);

		}
	}

	constructor(private cd: ChangeDetectorRef,
		private categoriaPSService: CategoriaPSService,
		private unidadMedidaService: UnidadMedidaService,
		private messengerService: MessengerService
	) { }

	ngOnInit() {
		this.lstControls = [this.ProductoSanitarioNombre, this.StockMinimo, this.CategoriaPS, this.UnidadMedida];
		this.CategoriaPS.initializeControl(this.categoriaPSService, this.cd, "Seleccione una Categoría");
		this.UnidadMedida.initializeControl(this.unidadMedidaService, this.cd, "Seleccione una Unidad de Medida");
	}

	resetModel() {
		this.ProductoSanitarioNombre.setValue(this._productoSanitario.ProductoSanitarioNombre);
		this.CategoriaPS.setValue(this._productoSanitario.CategoriaPSRef);
		this.Activo.setValue(this._productoSanitario.Activo);
		this.StockMinimo.setValue(this._productoSanitario.StockMinimo);
		this.UnidadMedida.setValue(this._productoSanitario.UnidadMedidaRef);
	}

	setModelValues(model: ProductoSanitario) {
		model.ProductoSanitarioNombre = this.ProductoSanitarioNombre.getValue();
		model.CategoriaPSRef = this.CategoriaPS.getValue();
		model.Activo = this.Activo.getValue();
		model.StockMinimo = this.StockMinimo.getValue();
		model.UnidadMedidaRef = this.UnidadMedida.getValue();

		this.model = model;
	}

	onDropDownValueChanged(item: NameValueList, key: string) {
		if (key === 'UnidadMedida') {
			this.sendUnidadMedida(item.Key);
		}
	}

	sendUnidadMedida(key: number): void {
		if (key > 0) {
			this.unidadMedidaService.getById(key).subscribe(data => {
				this.messengerService.broadcast('ProductoSanitarioComponent', 'ProductoSanitarioListComponent', 'UnidadMedida', { unidadMedida: data });
			}, error => { }, () => { });
		}
		else {
			this.messengerService.broadcast('ProductoSanitarioComponent', 'ProductoSanitarioListComponent', 'UnidadMedida', { unidadMedida: undefined });
		}

	}
}
