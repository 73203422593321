<div *ngIf="loading">
	<div class="kt-portlet mb-0">
		<div class="kt-portlet__body kt-portlet__body--fit p-3">
			<div class="kt-spinner kt-spinner--sm kt-spinner--success kt-spinner--left kt-spinner--input">
				&nbsp;&nbsp; <span class="ml-5">Cargando Reporte...</span>
			</div>
		</div>
	</div>
</div>

<div class="kt-portlet" *ngIf="!loading && tropa">
	<div class="kt-portlet__body kt-portlet__body--fit">
		<div class="kt-invoice-2">

			<!-- Encabezado -->
			<div class="kt-invoice__head kt-invoice__head__bg">
				<div class="kt-invoice__container">
					<div class="kt-invoice__brand">
						<h1 class="kt-invoice__title">
							INGRESO DE TROPA POR COMPRA
							<br /><span class="kt-invoice__title kt-font-bold kt-invoice__subtitle kt-font-danger">Por favor, confirme los Datos de la Compra antes de guardar</span>
						</h1>
						<div href="#" class="kt-invoice__logo">
							<a href="#"><img class="sigef--reporte--logo" src="./assets/media/logos/logo-4-b.png"></a>
							<span class="kt-invoice__desc">
							</span>
						</div>
					</div>

					<div class="alert alert-warning" role="alert">
						<div class="alert-text text-center">
							Los datos generales de la tropa serán editables hasta que se realice un nuevo movimiento de hacienda
						</div>
					</div>

					<div class="kt-invoice__title">DATOS GENERALES</div>
					<div class="kt-invoice__items" style="margin-top: 15px">
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Propietario</span>
							<span class="kt-invoice__text">{{ cliente ? cliente : 'Sin Propietario' }}</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Código de Tropa</span>
							<span class="kt-invoice__text kt-font-bold kt-font-danger">(Se asignará al confirmar la tropa)</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Nombre de Tropa</span>
							<span class="kt-invoice__text">{{ tropa.TropaNombre }}</span>
						</div>
					</div>
					<div class="kt-invoice__items">
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Fecha y Hora de Ingreso</span>
							<span class="kt-invoice__text">{{ tropa.FechaIngreso | parseDateHour }}</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Proveedor</span>
							<span class="kt-invoice__text">{{ proveedor ? proveedor : 'Sin Proveedor' }}</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Origen</span>
							<span class="kt-invoice__text">{{ origen ? origen : 'Sin Origen' }}</span>
						</div>
					</div>
					<div class="kt-invoice__items">
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Marca Identificatoria</span>
							<span class="kt-invoice__text">{{ tropa.MarcaIdentificatoria ? tropa.MarcaIdentificatoria : 'Sin Marca Identificatoria' }}</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Gastos de Comercialización (Total de Tropa)</span>
							<span class="kt-invoice__text">{{ tropa.GastoComercializacion | parseNumberMoney }}</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Color Identificatorio en SIGEF</span>
							<span class="kt-invoice__text" style="max-width: 2rem; border: 1px solid black;" [ngStyle]="{'background-color': color}">&nbsp;</span>
						</div>
					</div>
					<div class="kt-invoice__items">
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Precio total de compra</span>
							<span class="kt-invoice__text">{{ totalCompra | parseNumberMoney }}</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Peso total</span>
							<span class="kt-invoice__text">Bruto: {{ totalPeso | parseNumberKg }} (Neto: {{ totalPesoNeto | parseNumberKg }})</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Cantidad de Muertos al Arribo</span>
							<span class="kt-invoice__text">{{ tropa.CantMuertosArribo | parseNumber : true }}</span>
						</div>
					</div>
				</div>
			</div>

			<!-- Hacienda -->
			<div class="kt-invoice__body">
				<div class="kt-invoice__container">
					<div class="kt-invoice__title">HACIENDA</div>
					<div class="table-responsive">
						<table class="table">
							<thead>
								<tr>
									<th class="text-left">Categoría</th>
									<th class="text-center">Cantidad</th>
									<th class="text-center">Precio Compra</th>
									<th class="text-center">Peso Bruto</th>
									<th class="text-center">Desbaste</th>
									<th class="text-center">Peso Neto</th>
									<th class="text-center">Aumento Diario Est.</th>
									<th class="text-right">Corral Destino</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let cat of tropa.CategoriaGanados">
									<td class="text-left">{{ cat.CategoriaGanadoNombre }}</td>
									<td class="kt-font-regular text-center">{{ cat.Cantidad | parseNumber : true }}</td>
									<td class="kt-font-regular text-center">{{ cat.PrecioCompra | parseNumberMoney }}</td>
									<td class="kt-font-regular text-center">{{ cat.PesoCabezaKg | parseNumberKg }}</td>
									<td class="kt-font-regular text-center">{{ cat.DesbastePorc | parseNumberPorc }}</td>
									<td class="kt-font-regular text-center">{{ (round(cat.PesoCabezaKg) - (round(cat.PesoCabezaKg) * cat.DesbastePorc)) | parseNumberKg }}</td>
									<td class="kt-font-regular text-center">{{ cat.AumentoDiarioEstKg | parseNumberKg : false : false  }}</td>
									<td class="kt-font-regular text-right">{{ cat.CorralNombre }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<!-- Transportes -->
			<div class="kt-invoice__body">
				<div class="kt-invoice__container">
					<div class="kt-invoice__title">TRANSPORTE</div>
					<div class="table-responsive">
						<table class="table">
							<thead>
								<tr>
									<th class="text-left">Fecha</th>
									<th class="text-left">Transportista</th>
									<th class="text-center">($) Flete</th>
									<th class="text-left">DTEs</th>
									<th class="text-center">Cantidad Cabezas</th>
								</tr>
							</thead>
							<tbody *ngIf="tropa && tropa.Transportes && tropa.Transportes.length > 0">
								<tr *ngFor="let trans of tropa.Transportes">
									<td class="">{{ trans.Fecha | parseDate }}</td>
									<td class="kt-font-regular text-left">{{ trans.DTEProveedorTransporte }}</td>
									<td class="kt-font-regular text-center">{{ trans.GastoFlete | parseNumberMoney }}</td>
									<td class="kt-font-regular text-left">
										<ul class="nobull" style="padding: 0">
											<li *ngFor="let dte of trans.DTEs">
												{{ dte.DTENro }}
											</li>
										</ul>
									</td>
									<td class="kt-font-regular text-center">
										<ul class="nobull" style="padding: 0">
											<li *ngFor="let dte of trans.DTEs">
												{{ dte.DTECantidad | parseNumber : true }}
											</li>
										</ul>
									</td>
								</tr>
							</tbody>
							<tbody *ngIf="tropa && tropa.Transportes && tropa.Transportes.length == 0">
								<tr>
									<td colspan="8"> 
										<span style="font-weight: 300">No se han cargado datos de Transporte</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<!-- Acciones -->
			<div class="kt-invoice__actions">
				<div class="kt-invoice__container">
					<button type="button" class="btn btn-sigef-secondary btn-bold" (click)="editHandler($event)">Editar</button>
					<button type="button" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light': guardando}" [disabled]="guardando" class="btn btn-sigef btn-bold" (click)="confirmHandler($event)">Confirmar</button>
				</div>
			</div>
		</div>
	</div>
</div>
