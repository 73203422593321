import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';
import { Tropa } from '../models/tropa';
import { TropaDashboard } from '../models/tropa-dashboard';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { TropaHaciendaDetalle } from '../models/tropa-hacienda-detalle';
import { TropaConsumoInterface } from '../interfaces/tropa-consumo.interface';
import { Guid } from 'guid-typescript';
import { PesoEstimadoInterface } from '../interfaces/peso-estimado.interface';
import { EstimadoTropaCategoria } from '../models/estimado-tropa-categoria';
import { TropaHistorica } from '../models/tropa-historica';
import { TropaCierre } from '../models/tropa-cierre';
import { TropaRentabilidadCabecera, TropaRentabilidadCompra, TropaRentabilidadEconomica, TropaRentabilidadProductiva, TropaRentabilidadVenta } from '../models/tropa-rentabilidad';
import { NameValueList } from '../interfaces/nvl.interface';

@Injectable()
export class TropaService extends GenericoService<Tropa> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'tropa');
	}

    getDashboardList(clienteID: number, dietaID: number): Observable<TropaDashboard[]> {
        return this._httpClient.get<TropaDashboard[]>(environment.apiUrl + `/${this._endpoint}/dashboard/${clienteID}/${dietaID}`, { headers: this._getHeaders });
    }

	getHaciendaDetalleList(tropaID: Guid): Observable<TropaHaciendaDetalle[]> {
        return this._httpClient.get<TropaHaciendaDetalle[]>(environment.apiUrl + `/${this._endpoint}/hacienda/${tropaID.toString()}`, { headers: this._getHeaders });
    }

    getConsumoList(fechaDesde: Date, fechaHasta: Date): Observable<TropaConsumoInterface[]> {
        return this._httpClient.post<TropaConsumoInterface[]>(environment.apiUrl + `/${this._endpoint}/consumo`, { FechaDesde: fechaDesde, FechaHasta: fechaHasta }, { headers: this._postHeaders });
	}

	getPesoEstimado(filtro: EstimadoTropaCategoria): Observable<PesoEstimadoInterface[]> {
		return this._httpClient.post<PesoEstimadoInterface[]>(environment.apiUrl + `/${this._endpoint}/estimado`, { TropaRef: filtro.TropaRef, TropaCategoriaGanadoRef: filtro.TropaCategoriaGanadoRef }, { headers: this._postHeaders });
	}

	getHistoricaList(clienteID: number, fechaDesde: Date, fechaHasta: Date): Observable<TropaHistorica[]> {
		return this._httpClient.post<TropaHistorica[]>(environment.apiUrl + `/${this._endpoint}/historica/`, { ClienteID: clienteID, FechaDesde: fechaDesde, FechaHasta: fechaHasta }, { headers: this._postHeaders });
	}

	getUltimaFechaCierre(): Observable<Date> {
		return this._httpClient.get<Date>(environment.apiUrl + `/${this._endpoint}/utlimo_cierre`, { headers: this._getHeaders });
	}

	getRentabilidadCabecera(tropaID: Guid): Observable<TropaRentabilidadCabecera> {
		return this._httpClient.get<TropaRentabilidadCabecera>(environment.apiUrl + `/${this._endpoint}/rentabilidadCabecera/${tropaID}`, { headers: this._getHeaders });
	}

	getRentabilidadCompra(tropaID: Guid): Observable<TropaRentabilidadCompra[]> {
		return this._httpClient.get<TropaRentabilidadCompra[]>(environment.apiUrl + `/${this._endpoint}/rentabilidadCompra/${tropaID}`, { headers: this._getHeaders });
	}

	getRentabilidadVenta(tropaID: Guid): Observable<TropaRentabilidadVenta[]> {
		return this._httpClient.get<TropaRentabilidadVenta[]>(environment.apiUrl + `/${this._endpoint}/rentabilidadVenta/${tropaID}`, { headers: this._getHeaders });
	}

	getRentabilidadEconomica(tropaID: Guid): Observable<TropaRentabilidadEconomica> {
		return this._httpClient.get<TropaRentabilidadEconomica>(environment.apiUrl + `/${this._endpoint}/rentabilidadEconomica/${tropaID}`, { headers: this._getHeaders });
	}

	getRentabilidadProductiva(tropaID: Guid): Observable<TropaRentabilidadProductiva> {
		return this._httpClient.get<TropaRentabilidadProductiva>(environment.apiUrl + `/${this._endpoint}/rentabilidadProductiva/${tropaID}`, { headers: this._getHeaders });
	}

	getLookupByCliente(clienteId: number): Observable<NameValueList[]> {
		return this._httpClient.get<NameValueList[]>(environment.apiUrl + `/${this._endpoint}/lookupByCliente/${clienteId}`, { headers: this._getHeaders });
	}

	getLiquidacionList(clienteId: number, tropaId: Guid, desde: Date, hasta: Date): Observable<TropaCierre[]> {
		return this._httpClient.post<TropaCierre[]>(environment.apiUrl + `/${this._endpoint}/liquidacion`, { ClienteID: clienteId, TropaID: tropaId, FechaDesde: desde, FechaHasta: hasta }, { headers: this._postHeaders });
	}
}
