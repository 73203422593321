<div class="row-sigef">
	<my-col-input [tabindex]="tabindex" selectFirstInput #NotificacionTexto [type]="2" [labelName]="'Notificación'" [required]="true"></my-col-input>
</div>
<div class="row-sigef">
	<my-col-datetimepicker [tabindex]="tabindex + 1" #Fecha [labelName]="'Fecha'" [required]="true"></my-col-datetimepicker>
</div>
<div class="row-sigef">
	<my-col-dropdownlist [tabindex]="tabindex + 2" #Rol [labelName]="'Rol'" [filterable]="true" [required]="true"></my-col-dropdownlist>
</div>

<my-message-dialog></my-message-dialog>

