<kendo-grid *ngIf="showGrid" [kendoGridBinding]="consumo"
			[sortable]="true"
			[navigable]="true"
			[loading]="buscando">
	<kendo-grid-messages noRecords="No hay consumo para los filtros seleccionados">
	</kendo-grid-messages>

	<kendo-grid-column title="Alimento" field="AlimentoNombre" width="80">
	</kendo-grid-column>
	<kendo-grid-column title="Promedio" width="40">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span *ngIf="dataItem.AlimentoNombre == 'Agua'">{{dataItem.Valor | parseNumberCustom : false : true : '' : 'Lts' }}</span>
			<span *ngIf="dataItem.AlimentoNombre != 'Agua'">{{dataItem.Valor | parseNumberKg }}</span>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column title="Total" width="40">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span *ngIf="dataItem.AlimentoNombre == 'Agua'">{{dataItem.Total | parseNumberCustom : false : true : '' : 'Lts' }}</span>
			<span *ngIf="dataItem.AlimentoNombre != 'Agua'">{{dataItem.Total | parseNumberKg }}</span>
		</ng-template>
	</kendo-grid-column>
</kendo-grid>
