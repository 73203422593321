import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'my-group-save-buttons',
	templateUrl: './my-group-save-buttons.component.html',
	styleUrls: ['./my-group-save-buttons.component.scss']
})
export class MyGroupSaveButtonsComponent implements OnInit {
	@Input() allowDelete: boolean = false;
	@Input() allowCancel: boolean = true;
	@Input() saving: boolean = false;
	@Input() deleting: boolean = false;
	@Input() targetName: string = '';
	@Input() saveButtonText: string = '';
	@Input() cancelButtonText: string = 'Cancelar';
	@Input() tabindex: number = -1;

	@Output() onSave: EventEmitter<any> = new EventEmitter();
	@Output() onCancel: EventEmitter<any> = new EventEmitter();
	@Output() onDelete: EventEmitter<any> = new EventEmitter();

	constructor() { }
	ngOnInit() { }

	saveText(): string {
		if (this.saveButtonText !== "")
			return this.saveButtonText;
		if (this.saving)
			return `Guardando`;
		else
			return `Guardar`;
	}

	deleteText(): string {
		if (this.deleting)
			return `Eliminando`;
		else
			return `Eliminar`;
	}

	disable(): boolean {
		return this.saving || this.deleting;
	}

	clickSave(e) {
		this.saving = true;
		this.onSave.emit(e);
		setTimeout(() => { this.saving = false; }, 300);
	}

	clickCancel(e) {
		this.onCancel.emit(e);
	}

	clickDelete(e) {
		this.deleting = true;
		this.onDelete.emit(e);
		setTimeout(() => { this.deleting = false; }, 300);
	}
}
