import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';
import { Notificacion } from '../models/notificacion';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { NotificacionDashboard } from '../models/notificacion-dashboard';

@Injectable()
export class NotificacionService extends GenericoService<Notificacion> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'notificacion');
	}

	getNotificacionEntranteDashboardList(dias: number): Observable<NotificacionDashboard[]> {
		return this._httpClient.get<NotificacionDashboard[]>(`${environment.apiUrl}/${this._endpoint}/notificacionEntranteDashboard/${dias}`, { headers: this._getHeaders });
	}

	getNotificacionSalienteDashboardList(dias: number): Observable<NotificacionDashboard[]> {
		return this._httpClient.get<NotificacionDashboard[]>(`${environment.apiUrl}/${this._endpoint}/notificacionSalienteDashboard/${dias}`, { headers: this._getHeaders });
	}
}
