import { Component, OnInit, ChangeDetectorRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NameValueList } from '../../../../../interfaces/nvl.interface';
import { MovAlimento } from '../../../../../models/mov-alimento';
import { AlimentoService } from '../../../../../services/alimento.service';
import { MovAlimentoTipoService } from '../../../../../services/mov-alimento-tipo.service'; 
import { MovAlimentoService } from '../../../../../services/mov-alimento.service';
import { MyColDropDownListComponent, MyColInputComponent, MyColTextAreaComponent, MyColDateTimePickerComponent, MyColLabelInfoComponent } from '../../common_controls';
import { EditableForm } from '../../common_controls/common-controls.interface'
import { Guid } from 'guid-typescript';
import Utils, { MovAlimentoTipoAgrupador, MovAlimentoTipo, PATIO_COMIDAS } from '../../../../../utils'; 
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessengerService } from '../../../../../services/messenger.service';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { AlimentoAlmacenService } from '../../../../../services/alimento-almacen.service'; 
import { ParseNumberKgPipe } from '../../../../../pipes/parse-number-kg.pipe';
import { ParseNumberPipe } from '../../../../../pipes/parse-number.pipe'; 

@Component({
	selector: 'kt-movalimento',
	templateUrl: './movalimento.component.html',
	styleUrls: ['./movalimento.component.scss'],
	providers: [AlimentoService, MovAlimentoTipoService, MovAlimentoService, AlimentoAlmacenService]
})
export class MovAlimentoComponent extends EditableForm<MovAlimento> implements OnInit {

	@Input() set agrupador(value: MovAlimentoTipoAgrupador) {
		this.movTipo = value;
		this.FechaMovimiento.focus();
		if (this.movTipo === MovAlimentoTipoAgrupador.Cosecha || this.movTipo === MovAlimentoTipoAgrupador.Ajuste) {
			this.AlmacenOrigen.DefaultValue = { Key: 0, Value: 'Seleccione Almacen ...' };
		} else {
			this.AlmacenOrigen.DefaultValue = { Key: 0, Value: 'Seleccione Almacen de Origen...' };
		}
		if (this.movTipo === MovAlimentoTipoAgrupador.Traslado) {
			this.cantidadActual = 'Cantidad (Origen)';
		} else {
			this.cantidadActual = 'Cantidad Actual';
		}

		this.setLstControls();
		this.onInitSetModel();
		this.setStockActualInfoText();
	}

	public cantidadActual: string = 'Cantidad Actual';
	public stockActual: number = 0; 
	public movTipo: MovAlimentoTipoAgrupador = 0;
	public component: MovAlimentoComponent = this;
	public mostrarAlertaOrigenDestino: boolean = false;

	@Input() edicion: boolean = false;
	@Output() onClose: EventEmitter<any> = new EventEmitter();

	@ViewChild('FechaMovimiento', { static: true }) FechaMovimiento: MyColDateTimePickerComponent;
	@ViewChild('Alimento', { static: true }) Alimento: MyColDropDownListComponent;
	@ViewChild('CantPropuesta', { static: true }) CantPropuesta: MyColLabelInfoComponent;
	@ViewChild('CantReal', { static: true }) CantReal: MyColInputComponent;
	@ViewChild(MyColTextAreaComponent, { static: true }) Observaciones: MyColTextAreaComponent;
	@ViewChild('AlmacenOrigen', { static: true }) AlmacenOrigen: MyColDropDownListComponent;
	@ViewChild('AlmacenDestino', { static: true }) AlmacenDestino: MyColDropDownListComponent;

	constructor(private alimentoService: AlimentoService,
		private alimentoAlmacenService: AlimentoAlmacenService,
		movAlimentoService: MovAlimentoService,
		cd: ChangeDetectorRef,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		quickPanelService: QuickPanelService) {
		super(cd, movAlimentoService, snackBar, messengerService, quickPanelService);
	}

	ngOnInit() {
		this.Alimento.initializeControl(this.alimentoService, this.cd, 'Seleccione un Producto...');

		this.AlmacenOrigen.initializeCustomControlLookUp(this.alimentoAlmacenService.getLookup(), this.cd, (this.edicion ? 'Cambiar Almacen...' : 'Seleccione Almacen de Origen...'));
		this.AlmacenDestino.initializeCustomControlLookUp(this.alimentoAlmacenService.getLookup(), this.cd, 'Seleccione Almacen de Destino...');

		this.mostrarAlertaOrigenDestino = false;

		super.setModel(this.model);
	}

	getKeyModel(obj: MovAlimento): Guid { return obj.MovAlimentoID; };
	getNewModel(): MovAlimento { return new MovAlimento(); };

	onInitSetModel() {
		this.CantReal.parent = this;
		this.adicionalesVisible = false;
		this.CantPropuesta.infoText = new ParseNumberPipe().transform(0, false);
		super.onInitSetModel();
	}

	onInputValueChange(event, labelName): void { 
		if (this.movTipo === MovAlimentoTipoAgrupador.Traslado) {
			this.mostrarAlertaOrigenDestino = (this.CantReal.getValue() > this.stockActual);
		} else {
			this.mostrarAlertaOrigenDestino = false;
		} 
	}

	runCustomValidaciones() {
		if (this.movTipo === MovAlimentoTipoAgrupador.Ajuste) {
			if (this.stockActual === this.CantReal.getValue()) {
				this.MessageDialog.showAtencionDialog('Las cantidades son iguales. No se puede realizar el ajuste.');
				return false;
			}
		}

		if (this.movTipo === MovAlimentoTipoAgrupador.Traslado) {
			if (this.AlmacenOrigen.getValue() === this.AlmacenDestino.getValue()) {
				this.MessageDialog.showAtencionDialog('El almacén de origen y destino no puede ser el mismo.');
				return false;
			}
		}

		return true;
	}

	onCancel($event: any) {
		this.resetValidaciones();
		this.resetModel();
		this.Alimento.setValue(0);
		this.AlmacenOrigen.setValue(0);
		this.AlmacenDestino.setValue(0);
		this.stockActual = 0;
		this.setStockActualInfoText();
		super.setModel(undefined);
		this.adicionalesVisible = false;
		this.onClose.emit($event);
	}

	onSave(e, repeat: boolean = false): void {
		this.setLstControls(); 
		super.onSave(e, repeat); 
	}

	informSave(sucess: boolean) {
		if (sucess) {
			this.onCancel('sucess');
		}

		super.informSave(sucess);
	}
	
	setModelControlValues() {
		if (this.model) {
			this.FechaMovimiento.setFecha(new Date(this.model.FechaMovimiento)); 
			this.Alimento.setValue(this.model.AlimentoRef);
			this.CantReal.setValue(this.model.CantidadRealKg);
			this.AlmacenOrigen.setValue(this.model.AlimentoAlmacenRef);
			//this.CantPropuesta.setValue(this.model.CantidadPropuestaKg); 
			this.Observaciones.setText(this.model.Observaciones);
		}
	}

	setModelPropsByControls() {
		if (!this.model) { this.model = this.getNewModel() };
		this.model.FechaMovimiento = this.FechaMovimiento.getFecha();
		this.model.AlimentoRef = this.Alimento.getValue();
		//this.model.MovAlimentoTipoRef = 0;
		this.model.CantidadRealKg = this.CantReal.getValue();
		this.model.CantidadPropuestaKg = (this.movTipo === MovAlimentoTipoAgrupador.Ajuste) ? this.stockActual : 0; //guardams el actual al momento del ajuste
		this.model.Observaciones = this.Observaciones.getText();

		this.model.AlimentoAlmacenRef = PATIO_COMIDAS;
		this.model.FechaCompra = undefined;
		this.model.MixerCargaRef = undefined;
		this.model.ProveedorRef = undefined;
		this.model.CompraRef = undefined;
		this.model.RemitoNro = undefined;

		if (this.movTipo === MovAlimentoTipoAgrupador.Ajuste) {
			if (this.stockActual < this.CantReal.getValue()) {
				this.model.MovAlimentoTipoRef = MovAlimentoTipo.EntradaAjuste;
				this.model.CantidadRealKg = (this.CantReal.getValue() - this.stockActual);
			} else {
				this.model.MovAlimentoTipoRef = MovAlimentoTipo.SalidaAjuste;
				this.model.CantidadRealKg = (this.stockActual - this.CantReal.getValue());
			}
		}

		if (this.movTipo === MovAlimentoTipoAgrupador.Cosecha) {
			this.model.MovAlimentoTipoRef = MovAlimentoTipo.EntradaCosecha;
			this.model.AlimentoAlmacenRef = this.AlmacenOrigen.getValue();
		}
		 
		if (this.movTipo === MovAlimentoTipoAgrupador.Traslado) { // cuando sea Salida por Traslado, la query corre 2 inserts usando este campo como destino
			this.model.MovAlimentoTipoRef = MovAlimentoTipo.SalidaTraslado; 
			this.model.AlimentoAlmacenRef = this.AlmacenOrigen.getValue();
			this.model.AlmacenDestinoRef = this.AlmacenDestino.getValue() || 0; 
		}
	}

	onDropDownValueChanged(item: NameValueList, key: string) {
		if (key === 'Alimento' || key === 'AlmacenOrigen') {
			this.getStock();
		}
	} 

	getStock(): void {
		let alimentoID: number = this.Alimento.getValue() || 0;
		let almacenID: number = this.AlmacenOrigen.getValue() || 0;

		if (alimentoID > 0 && almacenID > 0) {
			this.alimentoService.getStock(alimentoID, almacenID).subscribe(
				data => {
					this.stockActual = data; 
				},
				error => {
					this.stockActual = 0;
					Utils.showSnackBarErrorMessage(this.snackBar, 'No se puede mostrar el stock actual del producto seleccionado');
				},
				() => {
					this.setStockActualInfoText();
				}
			);
		} 
	}

	setStockActualInfoText(): void {
		let infoText = '-';

		if (this.Alimento.getValue() > 0) {
			infoText = new ParseNumberKgPipe().transform(this.stockActual, true); 
		}

		this.CantPropuesta.infoText = infoText;
		this.cd.detectChanges();
	}

	setLstControls() { 
		this.lstControls = [this.FechaMovimiento, this.Alimento, this.CantReal, this.Observaciones];

		if (this.movTipo === MovAlimentoTipoAgrupador.Traslado) {
			this.lstControls.push(this.AlmacenOrigen);

			if (!this.edicion) {
				this.lstControls.push(this.AlmacenDestino);
			}
		}

		if (this.movTipo === MovAlimentoTipoAgrupador.Ajuste || this.movTipo === MovAlimentoTipoAgrupador.Cosecha) {
			this.lstControls.push(this.AlmacenOrigen);
		}
	}
}
