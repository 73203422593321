import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http'; 
import { Injectable } from '@angular/core';
import { VentaDestino } from '../models/venta-destino';

@Injectable()
export class VentaDestinoService extends GenericoService<VentaDestino> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'ventadestino');
	}
}
