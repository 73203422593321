import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { CorralConsumoGraficoInterface } from '../../../../../interfaces/corral-consumo-grafico.interface';
import { Dictionary } from '@ngrx/entity';
import { IOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { CorralService } from '../../../../../services/corral.service';
import _ from 'lodash';
import Utils from '../../../../../utils';
import { ParseNumberKgPipe } from '../../../../../pipes/parse-number-kg.pipe';
import { geometry, Image } from '@progress/kendo-drawing';
import { KeyValue } from '@angular/common';

@Component({
	selector: 'kt-consumo-chart',
	templateUrl: './consumo-chart.component.html',
	styleUrls: ['./consumo-chart.component.scss'],
	providers: [CorralService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsumoChartComponent implements OnInit, IOpenInDialog {
	public chartCount: number = 0;
	public loading: boolean = false;

	openInDialog(model: any, extras: any): void {
		if (model && model.CorralID) {
			this.cargarConsumo(model.CorralID);
		}
	}
	saveResultDialog(parent: import("../../common_controls/message-dialog/my-message-dialog.component").IParentOpenInDialog): boolean {
		return true;
	}
	editResultDialog(parent: import("../../common_controls/message-dialog/my-message-dialog.component").IParentOpenInDialog): boolean {
		return true;
	}

	public chartData: Dictionary<CorralConsumoGraficoInterface[]> = {};

	@Input() public set consumos(data: Dictionary<CorralConsumoGraficoInterface[]>) {
		this.loading = true;
		this.cd.reattach();

		this.chartData = data;
		this.chartCount = Object.keys(this.chartData).length;
		this.loading = false;

		this.cd.detectChanges();
		this.cd.detach();
	}

	// Preserve original property order
	originalOrder = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => {
		return 0;
	}

	constructor(private cd: ChangeDetectorRef, private corralService: CorralService) { }

	ngOnInit() {

	}

	private cargarConsumo(corralRef: number): void {
		let hoy: Date = new Date();
		let fechaHasta: Date = hoy;
		let fechaDesde: Date = new Date(new Date().setDate(new Date().getDate() - 30));
		let dieta = 0;

		this.corralService.getConsumoGraficoList(fechaDesde, fechaHasta, dieta, 0).subscribe(data => {
			let co = _.groupBy(data, function (consumo) {
				return consumo.CorralRef;
			});

			this.consumos = _.pick(co, corralRef); // solo mostrar los corrales de esta tropa
		}, error => {

		},
			() => {
				this.cd.detectChanges();
			});
	}

	dataConsumidoPorCabeza(items: any[]): number[] {
		return items.map(f => f.ConsumidoPorCabeza);
	}

	//NOTA: A los gráficos de Observaciones, Precipitaciones, Egresos e Ingresos se les hardcodea el valor para que estén todos
	//los íconos a la misma altura ya que de momento no deben estar representando a sus valores reales. Luego en el tooltip se muestra el valor real.

	dataObservaciones(items: any[]): number[] {
		return items.map(f => f.Observaciones.replace(/(?:\\[rn])+/g,'') === '' && f.ObservacionesAlimentacion === '' ? null : 7); /*Ver Nota*/
	}

	dataPrecipitaciones(items: any[]): number[] {
		return items.map(f => f.Precipitaciones > 0 ? 23 : null);  /*Ver Nota*/
	}

	dataMovimientos(items: any[], tipo: string): number[] {
		let result: number[] = [];
		let multiplicaPor: number = (tipo === 'ingresos') ? 1 : -1;
		items.forEach(function (item) {
			let sum = item.Movimientos.filter(o => o.MultiplicaPor === multiplicaPor).reduce((sum, current) => sum + current.Cantidad, 0);
			result.push(sum === 0 ? null : multiplicaPor === 1 ? 18 : 13);  /*Ver Nota*/

		});
		return result;
	}

	etiquetas(items: any[]): string[] {
		return items.map(f => Utils.dateToDDMM(new Date(f.Fecha)));
	}

	titulo(items: any[]): string {
		if (!items || items.length === 0) {
			return 'N/A';
		}

		return `Corral ${items[0].CorralNumero} (${items[0].CorralNombre}) - ${items[0].DietaNombre}`;
	}

	valueAxis: any[] = [
		{
			name: "kilogramos",
			title: "kilogramos",
			color: "#34A853",
			min: 0,
			step: 1,
			majorUnit: 1
		},
		{
			name: "temperatura",
			title: "temperatura (°C)",
			color: "#fd7300"
		},
		{
			name: "default",
			visible: false,
			max: 100,
			min: 0
		}
	]

	tooltip(category, points, tipo: string): string {
		let item = points.find(p => Utils.dateToDDMM(new Date(p.Fecha)) === category);
		if (item) {
			return item[tipo];
		}
	}

	tooltipMovimientos(category, points, tipo: string): string {
		let item = points.find(p => Utils.dateToDDMM(new Date(p.Fecha)) === category);
		let result: string = ``;
		let multiplicaPor: number = (tipo === 'ingresos') ? 1 : -1;

		if (item && item.Movimientos.length > 0) {
			item.Movimientos.forEach(function (mov) {
				if (mov.MultiplicaPor === multiplicaPor)
					result += `${(result === "") ? "" : "<br />"}<strong>${mov.MovTipoNombre}:</strong> ${mov.Cantidad}`;
			})

		}
		return result;
	}

	markers(tipo: string): any {
		let marker = this.marker(tipo);
		return {
			visual: (e) => {
				var src = `/assets/media/icons/fa/${marker.icono}.svg`;
				e.rect.size = marker.size;
				if (marker.origin) {
					e.rect.origin.x = e.rect.origin.x + marker.origin.x;
					e.rect.origin.y = e.rect.origin.y + marker.origin.y;
				}
				var image = new Image(src, e.rect);
				return image;
			}
		}
	}

	marker(tipo: string): any {
		switch (tipo) {
			case 'ingresos':
				return { icono: 'chevron-up-solid', size: { height: 16, width: 16 }, origin: { x: -4, y: 0 } };
			case 'egresos':
				return { icono: 'chevron-down-solid', size: { height: 16, width: 16 }, origin: { x: -4, y: 0 } };
			case 'precipitaciones':
				return { icono: 'tint-solid', size: { height: 14, width: 12 }, origin: { x: -2, y: 0 }};
			case 'observacionesInfoDiaria':
				return { icono: 'info-circle-solid', size: { height: 17, width: 17 }, origin: { x: -4.25, y: 0 } };
		}
	}

	highlight = {
		toggle: function (e) {
			e.preventDefault();
			var visual = e.visual;
			var transform = null;
			if (e.show) {
				var center = visual.rect().center();
				transform = geometry.transform().scale(1.5, 1.5, center);
			}
			visual.transform(transform);
		}
	};
}
