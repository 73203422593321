import { Component, OnInit, Input, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MyMessageDialogComponent } from '../../common_controls';
import { EditableFormList } from '../../common_controls/common-controls.interface';
import { IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { Compra } from '../../../../../models/compra'; 
import { MessengerService } from '../../../../../services/messenger.service';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { Subject } from 'rxjs';
import { BroadcastMessage } from '../../../../../interfaces/message.interface'; 
import { takeUntil } from 'rxjs/operators';
import { MovProductoSanitario } from '../../../../../models/mov-producto-sanitario';
import { MovProductoSanitarioCompraComponent } from '../movproductosanitario-compra/movproductosanitario-compra.component';
import { MovProductoSanitarioCompraProductoComponent } from '../movproductosanitario-compra-producto/movproductosanitario-compra-producto.component';

@Component({
	selector: 'kt-movproductosanitario-compra-producto-list',
	templateUrl: './movproductosanitario-compra-producto-list.component.html',
	styleUrls: ['./movproductosanitario-compra-producto-list.component.scss']
})
export class MovProductoSanitarioCompraProductoListComponent extends EditableFormList<Compra> implements OnInit, OnDestroy, IParentOpenInDialog {
	private destroy$: Subject<void> = new Subject();
	private deletedMovItem: MovProductoSanitario = undefined;
	private _compra: Compra;

	public totalPrecio: number = 0; 
	public movimientos: MovProductoSanitario[] = []; 

	@ViewChild(MyMessageDialogComponent, { static: true }) public MessageDialog: MyMessageDialogComponent;

	@Input() tabindex: number = -1;
	@Input()
	set compra(item: Compra) {
		this._compra = item;
		if (this._compra) {
			this.movimientos = this._compra.MovProductoSanitarios;
		} else {
			this.movimientos = [];
		}
		this.totales();
		this.buscando = false;
		this.cd.detectChanges();
	}
	get compra(): Compra { return this._compra; }

	constructor(cd: ChangeDetectorRef, private messengerService: MessengerService, private quickPanelService: QuickPanelService) { super(cd, undefined) }

	totales(): void { 
		this.totalPrecio = 0;

		for (const i of this.movimientos) { 
			this.totalPrecio += i.PrecioTotal;
		}

		this.cd.detectChanges();
	}

	ngOnInit() {
		this.messengerService.messageBroadcasted$.pipe(takeUntil(this.destroy$)).subscribe((data: BroadcastMessage) => {
			if (data) {
				if (data.Reason === 'MovProductoSanitarioCompraProducto') {
					this.saveResultDialogModel(data.Payload);
				}
			}
		});
	}

	onInputValueChange(newValue, labelName): void { }

	ngOnDestroy() {
		this.destroy$.next();
	}

	getKeyModel(obj: Compra): any { return obj.CompraID; }

	public removeHandler({ dataItem }) { this.confirmDelete(dataItem); }

	isNewModel: boolean = false;
	public showABM({ dataItem }) {
		this.isNewModel = dataItem == undefined;

		let title = 'Agregar Producto';

		if (!this.isNewModel) {
			title = 'Editar Producto: ' + dataItem.ProductoSanitarioNombre;
		}

		this.quickPanelService.createOffCanvasComponent(title, MovProductoSanitarioCompraProductoComponent, dataItem);
	}

	onDeletePost() {
		super.onDeletePost();
		this.totales();
	}

	onUpdatePost(e: Compra) {
		super.onUpdatePost(e);
		this.totales();
	}
	 
	confirmDelete(item: any) {
		this.deletedMovItem = item;
		this.MessageDialog.showDialog('Por favor confirme', 'Se eliminará al recurso en forma definitiva. ¿Está seguro que desea continuar?',
			['SI', 'NO'], 'DeleteModel', this);
	}

	onConfirmResultMessageDialog(id: string, ok: boolean): void { 
		if (ok && id === 'DeleteModel' && this.deletedMovItem) {
			this.movimientos.splice(this.movimientos.indexOf(this.deletedMovItem), 1);
			this.messengerService.broadcast(undefined, undefined, 'DeleteMovProductoSanitarioCompra', undefined);
			this.totales();
		}
	}

	public saveResultDialogModel(model: MovProductoSanitario) {
		if (this.isNewModel) {
			if (this._compra.MovProductoSanitarios.find(t => t.ProductoSanitarioRef === model.ProductoSanitarioRef)) {
				this.MessageDialog.showAtencionDialog('Ya existe este producto. Edite o elimine el registro existente.')
				return;
			}
	
			this._compra.MovProductoSanitarios.push(model);
		}
		else {
			let item = this._compra.MovProductoSanitarios.find(x => x.ProductoSanitarioRef == model.ProductoSanitarioRef);
			if (item) { Object.assign(item, model); }
		}

		this.totales();
		this.cd.detectChanges();
	}

	round(t1: number): number {
		return Math.round(t1);
	}
}
