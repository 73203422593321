<kendo-grid *ngIf="showGrid" [kendoGridBinding]="alimentos"
			[sortable]="true"
			[navigable]="true"
			[loading]="buscando">
	<kendo-grid-messages noRecords="No hay alimentos dentro de la carga">
	</kendo-grid-messages>

	<kendo-grid-column title="Fecha" width="130">
		<ng-template kendoGridCellTemplate let-dataItem>
			{{dataItem.FechaMovimiento | parseDateHour }}
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column title="Alimento" width="150">
		<ng-template kendoGridCellTemplate let-dataItem>
			{{dataItem.AlimentoNombre }} ({{dataItem.MateriaSecaPorc | parseNumberPorc}})
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column title="Propuesto" width="110">
		<ng-template kendoGridCellTemplate let-dataItem>
			{{dataItem.CantidadPropuestaKg | parseNumberKg }}
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column title="Cargado" width="110">
		<ng-template kendoGridCellTemplate let-dataItem>
			{{dataItem.CantidadRealKg | parseNumberKg }}
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column title="Exactitud" width="75">
		<ng-template kendoGridCellTemplate let-dataItem>
			{{getExactitud(dataItem) | parseNumberPorc : true }}
		</ng-template>
	</kendo-grid-column>
</kendo-grid>

