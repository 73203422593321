import { DTOInterface } from '../interfaces/dto.interface';
import { Guid } from 'guid-typescript';
import { VentaTransporte } from './venta-transporte'; 
import { MovTropa } from './mov-tropa';

export class Venta implements DTOInterface { 
	VentaID: Guid; 
	Fecha: Date;
	CompradorRef: number;
	VentaDestinoRef: number; 

	CompradorNombre: string;
	VentaDestinoNombre: string;

	SinDatos: boolean;
	Resumen: string;
	Tropas: string;
	Cabezas: number;
	NroTransaccion: number;

	Hacienda: MovTropa[];
	Transportes: VentaTransporte[];

	GastosConsumo: number;
	GastosSanidad: number;

	constructor() {
		this.clear();
	}

	clear(): void { 
		this.VentaID = Guid.createEmpty();
		this.Fecha = new Date();
		this.CompradorRef = -1;
		this.VentaDestinoRef = -1;

		this.CompradorNombre = '';
		this.VentaDestinoNombre = '';

		this.SinDatos = false;
		this.Resumen = '';
		this.Tropas = '';
		this.Cabezas = 0;

		this.Hacienda = [];
		this.Transportes = [];
	}
}
