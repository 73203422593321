import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { MovAlimento } from '../models/mov-alimento';
import { CargaDashboard } from '../models/carga-dashboard';
import { ConsumoDashboard } from '../models/consumo-dashboard';
import { ReportConsumo } from '../models/report-consumo';
import { Guid } from 'guid-typescript';


@Injectable()
export class MovAlimentoService extends GenericoService<MovAlimento> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'movalimento');
	}

	// Override del List
	getTableList(alimentoRef: number, fechaDesde: Date, fechaHasta: Date, movAlimentoTipoRef: number): Observable<MovAlimento[]> {
		return this._httpClient.post<MovAlimento[]>(environment.apiUrl + `/${this._endpoint}/movimientos`, { AlimentoRef: alimentoRef, FechaDesde: fechaDesde, FechaHasta: fechaHasta, MovAlimentoTipoRef: movAlimentoTipoRef }, { headers: this._postHeaders });
    }

    getStock(alimentoRef: number, almacenRef: number): Observable<any[]> {
		return this._httpClient.get<any[]>(environment.apiUrl + `/${this._endpoint}/stock/${alimentoRef}/${almacenRef}`, { headers: this._getHeaders });
	}

	getCargaDashboardList(dias: number, desvio: number): Observable<CargaDashboard[]> {
		return this._httpClient.get<CargaDashboard[]>(`${environment.apiUrl}/${this._endpoint}/cargaDashboard/${dias}/${desvio}`, { headers: this._getHeaders });
	}

	getReportConsumo(fechaDesde: Date, fechaHasta: Date, clienteRef: number, dietaRef: number, corralRef: number): Observable<ReportConsumo[]> {
		return this._httpClient.post<any>(environment.apiUrl + `/${this._endpoint}/consumoReport`, {
			FechaDesde: fechaDesde, FechaHasta: fechaHasta, ClienteRef: clienteRef, DietaRef: dietaRef, CorralRef: corralRef
		}, { headers: this._postHeaders });
	}
}
