import { DTOInterface } from '../interfaces/dto.interface';
import { MovAlimento } from './mov-alimento';
import { MovProductoSanitario } from './mov-producto-sanitario';

export class Compra implements DTOInterface { 
	CompraID: number;
	ProveedorRef: number;
	FechaCompra: Date;
	RemitoNro: string;
	MovAlimentos: MovAlimento[];
	MovProductoSanitarios: MovProductoSanitario[];

	ProveedorNombre: string; 

	constructor() {
		this.clear();
	}

	clear(): void {
		this.CompraID = -1;
		this.ProveedorRef = -1;
		this.FechaCompra = new Date();
		this.RemitoNro = '';
		this.MovAlimentos = [];
		this.MovProductoSanitarios = [];
		
		this.ProveedorNombre = '';
	}
}
