import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Tropa } from '../../../../../models/tropa';
import { TropaService } from '../../../../../services/tropa.service';
import { MovTropaService } from '../../../../../services/mov-tropa.service';
import { MovTropa } from '../../../../../models/mov-tropa';
import { TropaHaciendaDetalle } from '../../../../../models/tropa-hacienda-detalle';
import Utils, { Operacion, MovTropaTipo, Roles } from '../../../../../utils';
import { MessengerService } from '../../../../../services/messenger.service';
import { CorralConsumoGraficoInterface } from '../../../../../interfaces/corral-consumo-grafico.interface';
import { Dictionary } from '@ngrx/entity';
import { CorralService } from '../../../../../services/corral.service';
import _ from 'lodash';
import { Guid } from 'guid-typescript';
import { Router } from '@angular/router';
import { Venta } from '../../../../../models/Venta';
import { VentaService } from '../../../../../services/venta.service';
import { Location } from '@angular/common';
import { TropaMovimientoReadonlyComponent } from '../tropa-movimiento-readonly/tropa-movimiento-readonly.component';
import { ParseCodigoTropaPipe } from '../../../../../pipes/parse-codigo-tropa.pipe';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { AutenticacionService } from '../../../../../services/autenticacion.service';

@Component({
	selector: 'kt-tropa-reporte-edicion',
	templateUrl: './tropa-reporte-edicion.component.html',
	styleUrls: ['./tropa-reporte-edicion.component.scss'],
	providers: [TropaService, MovTropaService, CorralService, VentaService, AutenticacionService]
})
export class TropaReporteEdicionComponent implements OnInit {
	public tropa: Tropa = undefined;
	public movimientos: MovTropa[] = [];
	public paricion: MovTropa = undefined;
	public detalles: TropaHaciendaDetalle[] = [];
	public ventas: Venta[] = [];

	public generacion: Date = new Date();

	public totalCabezas: number = 0;
	public totalCompra: number = 0;
	public totalPeso: number = 0;
	public totalPesoCompra: number = 0;
	public desbaste: number = 0;
	public desbasteCompra: number = 0;
	public totalPesoCompraNeto: number = 0;

	public adicionalesVisible: boolean = false;

	public consumos: Dictionary<CorralConsumoGraficoInterface[]> = {};

	public datosCompraExpandidos: boolean = false;
	public datosVentaExpandidos: boolean = false;
	public loading: boolean = true;

	public editarBoton: string = 'Editar';
	public puedeEditar: boolean = true;

	cargarTropa(tropaId: Guid): void {
		this.loading = true;

		this.tropaService.getById(tropaId).subscribe(
			data => {
				this.tropa = data;

				this.totalPesoCompra = 0;
				this.desbasteCompra = 0;
				this.totalCompra = 0;
				this.totalPesoCompraNeto = 0;

				if (this.tropa) {
					for (const cc of this.tropa.CategoriaGanados) {
						let peso = this.operacionRedondeada(cc.Cantidad, cc.PesoCabezaKg, Operacion.Multiplicacion);
						this.totalPesoCompra += peso;
						this.totalPesoCompraNeto += this.operacionRedondeada(cc.Cantidad, (Math.round(cc.PesoCabezaKg) - (Math.round(cc.PesoCabezaKg) * cc.DesbastePorc)), Operacion.Multiplicacion);
						this.desbasteCompra += cc.DesbastePorc;
						this.totalCompra += (cc.Cantidad * parseFloat(cc.PrecioCompra.toFixed(2)));
					}

					this.desbasteCompra /= this.tropa.CategoriaGanados.length;
					this.cargarDetalle();
				}
				else {
					this.loading = false;
					this.cd.detectChanges();
				}

			},
			error => {

			},
			() => {
				//this.cd.detectChanges();
			}
		);
	}

	cargarDetalle(): void {
		this.tropaService.getHaciendaDetalleList(this.tropa.TropaID).subscribe(
			data => {
				this.detalles = data;
				this.puedeEditar = (data.length > 0 && this.auth.tieneRole(Roles[Roles.Administrador]));

				this.totalCabezas = 0;
				this.totalPeso = 0;
				this.desbaste = 0;

				for (const cc of this.detalles) {
					this.totalCabezas += cc.Cantidad;
					this.desbaste += cc.PromedioDesbaste;
					this.totalPeso += this.operacionRedondeada(cc.Cantidad, cc.PromedioPesoCabezaKg, Operacion.Multiplicacion); // ya viene Peso Neto
				}

				this.cargarMovimientos();
			},
			error => {

			},
			() => {
				//this.cd.detectChanges()
			}
		);
	}

	cargarMovimientos(): void {
		this.movTropaService.getTableListByTropa(this.tropa.TropaID).subscribe(
			data => {
				this.movimientos = data;
				if (data.some(d => d.MovTropaTipoRef != MovTropaTipo.Compra)) {
					this.editarBoton = 'Editar Datos & Aumento Estimado';
				}
				this.cargarConsumo();
			},
			error => {

			},
			() => {
				//this.cd.detectChanges();
			}
		);

		this.ventaService.getVentaListByTropa(this.tropa.TropaID).subscribe(
			data => {
				this.ventas = data;
			});
	}

	cargarConsumo(): void {
		this.consumos = {};

		let hoy: Date = new Date();
		let fechaHasta: Date = hoy;
		let fechaDesde: Date = new Date(new Date().setDate(new Date().getDate() - 30));
		let fechaIngreso: Date = new Date(this.tropa.FechaIngreso);
		let dieta = 0;

		if (fechaDesde < fechaIngreso) fechaDesde = fechaIngreso;

		this.corralService.getConsumoGraficoList(fechaDesde, fechaHasta, dieta, 0).subscribe(data => {
			let co = _.groupBy(data, function (consumo) {
				return consumo.CorralNumero;
			});

			const corrales = this.detalles.map(a => a.CorralNumero);
			this.consumos = _.pick(co, corrales); // solo mostrar los corrales de esta tropa

			this.cargarParicion();
		}, error => {

		},
			() => {
				this.loading = false;
				this.cd.detectChanges();
			});
	}

	cargarParicion(): void {
		if (this.tropa && this.tropa.MovTropaParicionRef) {
			this.movTropaService.getById(this.tropa.MovTropaParicionRef).subscribe(data => {
				this.paricion = data;
			}, error => {

			},
				() => {
					this.loading = false;
					this.cd.detectChanges();
				});
		} else {
			this.loading = false;
			this.cd.detectChanges();
		}
	}

	verMovimiento(dataItem): void {
		this.quickPanelService.createOffCanvasComponent('Ver Movimiento', TropaMovimientoReadonlyComponent, dataItem); 
	}

	constructor(private tropaService: TropaService,
		private _location: Location,
		private router: Router, 
		private corralService: CorralService,
		private messengerService: MessengerService,
		private quickPanelService: QuickPanelService,
		private movTropaService: MovTropaService,
		private ventaService: VentaService,
		private cd: ChangeDetectorRef,
		private auth: AutenticacionService) { }

	ngOnInit() {
		this.loading = true;
	}

	printHandler(e): void {
		window.print();
	}

	goBackHandler(e): void {
		// Volver a la ruta de donde se vino, simulando un history.back() de js pero en app
		this._location.back(); 
	}

	editHandler(e): void {
		this.messengerService.broadcast('TropaReporteEdicionComponent', 'TropaCrud', 'editar_tropa_reporte', {});
	}

	refreshHandler(e): void {
		this.messengerService.broadcast('TropaReporteEdicionComponent', 'TropaCrud', 'recargar_tropa_reporte', {});
	}

	operacionRedondeada(t1: number, t2: number, op: Operacion): number {
		return Utils.operacionRedondeada(t1, t2, op);
	}

	round(t1: number): number {
		return Math.round(t1);
	}
}
