import { Pipe, PipeTransform } from '@angular/core';
import Utils from '../utils';

@Pipe({
    name: 'parseNumberKgMS'
})
export class ParseNumberKgMSPipe implements PipeTransform {

	transform(e: number, rounded: boolean = false, noDecimals: boolean = false): any {
		if (rounded) {
			e = Math.round(e);
		}

		return `${Utils.formatNumberDecimal(e, noDecimals)} KgMS`;
	} 

} 
