import { Pipe, PipeTransform } from '@angular/core';
import Utils from '../utils';

@Pipe({
  name: 'diasEnCorral'
})
export class DiasEnCorralPipe implements PipeTransform {

	transform(e: any, hasta: any = new Date()): string { 
		let dias = Utils.corralDays(e, hasta);
		return dias === 1 ? '1 día' : `${dias} días`;
	}

}
