import { Tropa } from "./tropa";
import { MovTropaCorral } from "./mov-tropa-corral";

export class CorralEncierre {
	Tropa: Tropa;
	Movimientos: MovTropaCorral[];

	constructor() {
		this.Tropa = new Tropa();
		this.Tropa.CantMuertosArribo = 0;
		this.Tropa.ClienteRef = undefined;
		this.Tropa.FechaCreacion = new Date();
		this.Tropa.GastoComercializacion = 0;
		this.Tropa.MarcaIdentificatoria = '';
		this.Tropa.PrecioCompra = 0;
		this.Tropa.ProveedorRef = undefined;
		this.Tropa.TropaOrigenRef = undefined;
		this.Movimientos = [];
	}
}
