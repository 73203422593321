import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CorralService } from '../../../../../services/corral.service';
import { EditableForm } from '../../common_controls/common-controls.interface';
import { IOpenInDialog, IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { MyColDateTimePickerComponent, MyColTimePickerComponent, MyColInputSearchComponent, MyColTextAreaComponent, MyColInputComponent, MyColDropDownListComponent, PrecioCompraVentaComponent } from '../../common_controls';
import Utils from '../../../../../utils';
import { ClienteService } from '../../../../../services/cliente.service';
import { Guid } from 'guid-typescript';
import { LayoutUtilsService } from '../../../../core/_base/crud';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessengerService } from '../../../../../services/messenger.service';
import { OffCanvasInterface } from '../../../../../interfaces/off-canvas.interface';
import { QuickPanelService } from '../../../../../services/quick-panel.service';

@Component({
	selector: 'kt-tropa-encierre',
	templateUrl: './tropa-encierre.component.html',
	styleUrls: ['./tropa-encierre.component.scss'],
	providers: [CorralService, ClienteService]
})
export class TropaEncierreComponent extends EditableForm<InputTropaEncierreModel> implements OnInit, IOpenInDialog, OffCanvasInterface {
	public component: any = this;

	@ViewChild(MyColDateTimePickerComponent, { static: true }) Fecha: MyColDateTimePickerComponent;
	@ViewChild(MyColTimePickerComponent, { static: true }) Hora: MyColTimePickerComponent;
	@ViewChild('Nombre', { static: true }) Nombre: MyColInputComponent;
	@ViewChild('AumentoDiario', { static: true }) AumentoDiario: MyColInputComponent;
	@ViewChild(MyColInputSearchComponent, { static: true }) Corral: MyColInputSearchComponent;
	@ViewChild(MyColDropDownListComponent, { static: true }) Hotelero: MyColDropDownListComponent; 

	constructor(private corralService: CorralService,
		cd: ChangeDetectorRef,
		private clienteService: ClienteService,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		quickPanelService: QuickPanelService) {
		super(cd, undefined, snackBar, messengerService, quickPanelService);
	}

    setOffCanvasData(data: any, extras: any): void {
		setTimeout(() => {
			this.setModel(data);
		}, 0);
    }

	ngOnInit() {
		this.lstControls = [this.Hotelero, this.Fecha, this.Hora, this.Nombre, this.AumentoDiario];
		this.Corral.initializeControlByCorral(this.corralService);
		this.Hotelero.initializeControl(this.clienteService, this.cd, 'Seleccione una opción');

		this.Hotelero.focus();
	}

	setModelControlValues() { 
		this.Fecha.setFecha(new Date(this.model.Fecha));
		this.Hora.setHora(this.model.Fecha);
		this.Hotelero.setValue(this.model.ClienteRef);
		this.Nombre.setValue(this.model.TropaNombre);
		this.AumentoDiario.setValue(this.model.AumentoDiario | 0);
		this.Corral.setValue(this.model.CorralRef);
	}

	setModelPropsByControls() {
		this.model = this.getNewModel();
		this.model.Fecha = Utils.getDateWithTime(this.Fecha.getFecha(), this.Hora.getHora());
		this.model.ClienteRef = this.Hotelero.getValue();
		this.model.TropaNombre = this.Nombre.getValue(); 
		this.model.AumentoDiario = this.AumentoDiario.getValue(); 
		this.model.CorralRef = this.Corral.getItem() ? this.Corral.getItem().Key : undefined; 
		this.model.PrecioVenta = 0; 
	}

	getNewModel(): InputTropaEncierreModel { return new InputTropaEncierreModel(); };

	openInDialog(model: InputTropaEncierreModel, extras: any) {
		this.openedInDialog = true;
		this.setModel(model);
	}

	editResultDialog(formParent: IParentOpenInDialog): boolean {
		return false;
	}

	saveResultDialog(formParent: IParentOpenInDialog): boolean {
		if (formParent) {
			if (!this.runValidaciones()) {
				return false;
			}
			this.setModelPropsByControls();
			formParent.saveResultDialogModel(this.model);
		}
		return true;
	} 

	onEncierreSave(e: any) {
		if (!this.runValidaciones()) {
			return false;
		}
		this.setModelPropsByControls();
		this.getMessengerInstance().broadcast(undefined, undefined, 'TropaEncierreModel', this.model);
		this.getQuickPanelInstance().hideQuickPanel();
	}

	onEncierreCancel(e: any) {
		this.getQuickPanelInstance().hideQuickPanel();
	}

	onInputValueChange(item: any, labelName: string) {
		// 
	}

	runCustomValidaciones() {
		if (Utils.getDateWithTime(this.Fecha.getFecha(), this.Hora.getHora()) > new Date()) {
			this.MessageDialog.showAtencionDialog('La fecha y hora del movimiento no puede ser mayor a la actual.');
			return false;
		}

		return true;
	}
}

export class InputTropaEncierreModel {
	Fecha: Date = new Date();
	CorralRef: number = 0;
	Cantidad: number = 0;
	ClienteRef: number;
	PrecioVenta: number;
	TropaNombre: string;
	AumentoDiario: number;
	Observaciones: string;
}

