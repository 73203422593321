<div>
	<div *ngIf="dietasDistintas()" class="alert alert-warning mb-0 ml-3 mr-3" role="alert">
		<div class="alert-text">
			La dieta de origen ({{model.DietaOrigen}})  es distinta a la de destino ({{model.DietaDestino}})
		</div>
	</div>

	<div [ngClass]="{'d-none': !dietasDistintas() || !corralDestinoVacio}" class="cambiar-dieta ml-3 mr-3 mt-2 pt-2" role="alert">
		<div class="alert-text">
			<my-col-checkbox #CambiarDieta [labelName]="'Modificar dieta del corral destino'" (onClicked)="onClicked($event)"></my-col-checkbox>
		</div>
	</div>

	<div class="row-sigef mb-3">
		<my-col-datetimepicker [tabindex]="5000" selectFirstInput [ngClass]="{'d-none': esEncierreVenta}" [labelName]="'Fecha'"></my-col-datetimepicker>
		<my-col-timepicker [tabindex]="5001" [ngClass]="{'d-none': esEncierreVenta}" [labelName]="'Hora'"></my-col-timepicker>
		<my-col-input [ngClass]="{'d-none': model?.CambioCorralTipo === 'Corral'}" [tabindex]="5002" #Cantidad style="width: 10rem" [labelName]="'Cabezas'" [decimals]="0" [required]="true" [allowCero]="false" [parent]="component"></my-col-input>
	</div>

	<my-row-expand [ngClass]="{'d-none': model?.CambioCorralTipo === 'Corral'}" [tabindex]="5003" [title]="'Generar Pesada'" [hint]="'Seleccione esta opción para generar un nuevo movimiento de pesada'" [labelName]="'Generar Pesada'" [expanded]="pesadaVisible" (onExpand)="validarPesada($event)"></my-row-expand>
	<div class="w-100" [ngClass]="{'d-none': !pesadaVisible}">
		<kt-peso-hacienda [tabindex]="5004" [corral]="getCorralOrigen()" [pesar]="pesadaVisible" [title]="'Nuevo Movimiento de Pesada'" [tropaCategoriaGanado]="tropaCategoriaGanadoRef" [cantidad]="cantidad" class="w-100"></kt-peso-hacienda>
	</div>

	<my-row-expand [tabindex]="5007" [disabled]="true" [ngClass]="{'d-none': !esEncierreVenta || model?.CambioCorralTipo === 'Corral'}" [title]="'Precio de Venta'" [hint]="''" [labelName]="'Precio de Venta'" [expanded]="true" (onExpand)="precioVisible=$event"></my-row-expand>
	<div class="w-100" [ngClass]="{'d-none': !esEncierreVenta}">
		<kt-precio-compra-venta [tabindex]="5008" [cantidad]="cantidad" class=""></kt-precio-compra-venta>
	</div>


	<my-row-expand [tabindex]="5011" [title]="Observaciones" [hint]="'Seleccione esta opción para incluir una aclaración adicional'" [labelName]="'Observaciones'" [expanded]="adicionalesVisible" (onExpand)="adicionalesVisible=$event"></my-row-expand>
	<div class="" [ngClass]="{'d-none': !adicionalesVisible}">
		<my-col-textarea [tabindex]="5012" [labelName]="'Observaciones'" [rows]="2" [labelVisible]="false" class="w-100"></my-col-textarea>
	</div>

	<div class="ml-3 mr-3 pl-3 row" [ngClass]="{'d-none': openedInDialog}">
		<my-group-save-buttons [tabindex]="5013" class="w-100" [saving]="saving" [targetName]="'Cambio'"
							   (onSave)="onCambioSave($event)" (onCancel)="onCambioCancel($event)"></my-group-save-buttons>
	</div>
</div>
<my-message-dialog></my-message-dialog>
