import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map'
import decode from 'jwt-decode';

import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { Usuario } from '../models/usuario';
import { Permission, Role } from '../app/core/auth';
import { catchError } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../app/core/_base/crud';
import { LoginADA } from '../models/login-ada';
//import { Usuario } from 'src/models/Usuario';

@Directive()
@Injectable()
export class AutenticacionService {

	@Output() evento = new EventEmitter<any>();

	constructor(private http: HttpClient, private _router: Router) { }

	loginADA(cuenta: string, password: string): Observable<Usuario> {
		return this.http.post<Usuario>(environment.apiUrl + '/usuario/login', { Cuenta: cuenta, Password: password });
	}

	loginProvider(cuenta: string, token: string, provider: string): Observable<Usuario> {
		return this.http.post<Usuario>(environment.apiUrl + '/usuario/loginProvider', { Cuenta: cuenta, Token: token, Provider: provider });
	}

	autenticar(loginToken: string, empresa: string): Observable<Usuario> {
		return this.http.post<Usuario>(environment.apiUrl + '/usuario/autenticar', { LoginToken: loginToken, Empresa: empresa });
	}

	register(usuario: Usuario): Observable<Usuario> {
		return this.http.post<Usuario>(environment.apiUrl + '/usuario/registrar', { usuario });
	}

	logout() {
		localStorage.removeItem('currentUser');
		this.evento.emit('logout');
	}

	relogin(email) {
		localStorage.removeItem('currentUser');
		this.evento.emit('logout');
		this._router.navigate(['/auth/login'], { queryParams: { editado: true, email: email } });
	}

	modificarDatos(usuario: any): void {
		let currentUser = JSON.parse(localStorage.getItem('currentUser'));
		currentUser.Nombre = usuario.Nombre;
		currentUser.Email = usuario.Email;
		localStorage.setItem('currentUser', JSON.stringify(currentUser));
	}

	resetPassword(token: string, password: string) {
		return this.http.post(environment.apiUrl + '/usuario/resetPassword/', { TokenRecuperacion: token, Password: password });
	}

	requestPassword(email: string): Observable<any> {
		return this.http.post<any>(environment.apiUrl + '/usuario/requestPassword/', { Cuenta: email });
	}

	estaLogeado(): boolean {
		let currentUser = JSON.parse(localStorage.getItem('currentUser'));
		return (currentUser && (currentUser as Usuario).AccessToken != undefined);
	}

	tieneRole(role: string): boolean {
		let currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if (currentUser) {
			let currentRole = decode((currentUser as Usuario).AccessToken).role;
			return (typeof (currentRole) === "string") ? currentRole == role : currentRole.some(o => o === role);
		}
		return false;
	}

	getUsuario(): any {
		let currentUser = JSON.parse(localStorage.getItem('currentUser'));
		if (currentUser) {
			return currentUser;
		} else {
			return null;
		}
	}

	getUserByToken(): Observable<Usuario> {
		let user: Usuario = this.getUsuario();
		if (user) {
			if (!user.Empresa) {
				return throwError(new Usuario());
			}
			const userToken = (user as Usuario).AccessToken;
			const httpHeaders = new HttpHeaders();
			return this.http.post<Usuario>(environment.apiUrl + '/usuario/byToken/', { AccessToken: userToken } ,{ headers: httpHeaders });
		}

		return throwError(new Usuario());
	}

	// Permission
    getAllPermissions(): Observable<Permission[]> {
		return this.http.get<Permission[]>(environment.apiUrl + '/permiso');
    }

    getRolePermissions(roleId: number): Observable<Permission[]> {
		return this.http.get<Permission[]>(environment.apiUrl + '/permiso/getRolePermission?=' + roleId);
    }

    // Roles
    getAllRoles(): Observable<Role[]> {
		return this.http.get<Role[]>(environment.apiUrl + '/rol');
    }

    getRoleById(roleId: number): Observable<Role> {
		return this.http.get<Role>(environment.apiUrl + '/rol' + `/${roleId}`);
	}

    // CREATE =>  POST: add a new role to the server
	createRole(role: Role): Observable<Role> {
		// Note: Add headers if needed (tokens/bearer)
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.post<Role>(environment.apiUrl + '/rol', role, { headers: httpHeaders});
	}

    // UPDATE => PUT: update the role on the server
	updateRole(role: Role): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.put(environment.apiUrl + '/rol', role, { headers: httpHeaders });
	}

	// DELETE => delete the role from the server
	deleteRole(roleId: number): Observable<Role> {
		const url = `${environment.apiUrl}/rol/${roleId}`;
		return this.http.delete<Role>(url);
	}

    // Check Role Before deletion
    isRoleAssignedToUsers(roleId: number): Observable<boolean> {
		return this.http.get<boolean>(environment.apiUrl + '/rol/checkIsRollAssignedToUser?roleId=' + roleId);
    }

    findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        // This code imitates server calls
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.post<QueryResultsModel>(environment.apiUrl + '/rol/findRoles', queryParams, { headers: httpHeaders});
	}


    // DELETE => delete the user from the server
	deleteUser(userId: number) {
		const url = `${environment.apiUrl}/usuario/${userId}`;
		return this.http.delete(url);
    }

    // UPDATE => PUT: update the user on the server
	updateUser(_user: Usuario): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.put(environment.apiUrl + '/usuario' + _user.UsuarioID, _user, { headers: httpHeaders });
	}

    // CREATE =>  POST: add a new user to the server
	createUser(user: Usuario): Observable<Usuario> {
    	const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.post<Usuario>(environment.apiUrl + '/usuario', user, { headers: httpHeaders});
	}

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.post<QueryResultsModel>(environment.apiUrl + '/usuario/findUsers', queryParams, { headers: httpHeaders});
	}

	// Desvincular cuenta
	desvincularCuenta(userId: number): Observable<Usuario> {
		return this.http.get<Usuario>(`${environment.apiUrl}/usuario/desvincularCuenta/${userId}`);
	}


	private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            // console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
