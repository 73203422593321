import { Pipe, PipeTransform } from '@angular/core';
import Utils from '../utils';

@Pipe({
    name: 'parseNumberCustom'
})
export class ParseNumberCustomPipe implements PipeTransform {

	transform(e: number, rounded: boolean = true, noDecimals: boolean = true, prefijo: string = '', sufijo: string = ''): any {
		if (rounded) {
			e = Math.round(e);
		}

		return `${prefijo === '' ? '' : prefijo + ' '}${Utils.formatNumberDecimal(e, noDecimals)}${sufijo === '' ? '' : ' ' + sufijo}`;
	}
} 
