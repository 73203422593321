import { NgModule } from '@angular/core';
import { ParseDatePipe } from './parse-date.pipe';
import { ParseHourPipe } from './parse-hour.pipe';
import { ParseDateHourPipe } from './parse-dateHour.pipe';
import { ParseNumberKgPipe } from './parse-number-kg.pipe';
import { ParseNumberPorcPipe } from './parse-number-porc.pipe';
import { ParseIconMovimientoPipe } from './parse-icon-movimiento.pipe';
import { ParseIconAccionPipe } from './parse-icon-accion.pipe';
import { DiasEnCorralPipe } from './dias-en-corral.pipe';
import { ParseNumberMoneyPipe } from './parse-number-money.pipe';
import { ParseNumberPipe } from './parse-number.pipe';
import { ParseNumberKgMSPipe } from './parse-number-kg-ms.pipe';
import { ParseCorralNumeroPipe } from './parse-corral-numero.pipe';
import { ParseCodigoTropaPipe } from './parse-codigo-tropa.pipe';
import { ParsePrecipitacionesPipe } from './parse-precipitaciones.pipe';
import { ParseNumberCustomPipe } from './parse-number-custom.pipe';
import { ParseDateMinValuePipe } from './parse-date-min-value.pipe';

// Pipes 

@NgModule({
	imports: [
		// dep modules
	],
	declarations: [
        ParseDatePipe,
        ParseHourPipe,
		ParseDateHourPipe,
		ParseNumberPipe,
		ParseNumberKgPipe,
		ParseNumberKgMSPipe,
		ParseNumberMoneyPipe,
		ParseNumberPorcPipe,
		ParseIconMovimientoPipe,
		ParseIconAccionPipe,
		DiasEnCorralPipe,
		ParseCorralNumeroPipe,
		ParseCodigoTropaPipe,
		ParsePrecipitacionesPipe,
		ParseNumberCustomPipe,
		ParseDateMinValuePipe
	],
	exports: [
        ParseDatePipe,
		ParseHourPipe,
		ParseNumberPipe,
        ParseDateHourPipe,
		ParseNumberKgPipe,
		ParseNumberKgMSPipe,
		ParseNumberMoneyPipe,
		ParseNumberPorcPipe,
		ParseIconMovimientoPipe,
		ParseIconAccionPipe,
		DiasEnCorralPipe,
		ParseCorralNumeroPipe,
		ParseCodigoTropaPipe,
		ParsePrecipitacionesPipe,
		ParseNumberCustomPipe,
		ParseDateMinValuePipe
	]
})
export class PipesModule { }
