import { Pipe, PipeTransform } from '@angular/core';
import Utils from '../utils';

@Pipe({
    name: 'parseHour'
})
export class ParseHourPipe implements PipeTransform {

    transform(e: any, ...args: any[]): string {
        return Utils.getStringHour(e);
    }
}
