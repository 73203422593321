import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { EditableForm, ValidationControlInterface } from '../../common_controls/common-controls.interface';
import { Notificacion } from '../../../../../models/notificacion';
import { NotificacionService } from '../../../../../services/notificacion.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessengerService } from '../../../../../services/messenger.service';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { MyColDateTimePickerComponent, MyColDropDownListComponent, MyColInputComponent } from '../../common_controls';
import { RolService } from '../../../../../services/rol.service';
import { Guid } from 'guid-typescript';
import Utils from '../../../../../utils';
import { Usuario } from '../../../../../models/usuario';
import { AutenticacionService } from '../../../../../services/autenticacion.service';

@Component({
  selector: 'kt-notificacion',
  templateUrl: './notificacion.component.html',
	styleUrls: ['./notificacion.component.scss'],
	providers: [RolService, NotificacionService]
})
export class NotificacionComponent extends EditableForm<Notificacion> implements OnInit {

	@ViewChild('NotificacionTexto', { static: true }) NotificacionTexto: MyColInputComponent;
	@ViewChild('Fecha', { static: true }) Fecha: MyColDateTimePickerComponent;
	@ViewChild('Rol', { static: true }) Rol: MyColDropDownListComponent;

	@Input() tabindex: number = 1000;

	lstControls: ValidationControlInterface[]; 

	corralID: number = 0;
	user: Usuario;

	constructor(private notificacionService: NotificacionService,
		private auth: AutenticacionService,
		cd: ChangeDetectorRef,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		quickPanelService: QuickPanelService,
		private rolService: RolService) {
		super(cd, notificacionService, snackBar, messengerService, quickPanelService);
	}

	ngOnInit() {
		this.loadUserData();

		this.lstControls = [this.NotificacionTexto, this.Fecha, this.Rol];
		this.Rol.initializeCustomControlLookUp(this.rolService.getLookupByRecibeNotifAdmin(true), this.cd, 'Seleccione un Rol');
		this.Fecha.minDate = new Date(); 
		this.Fecha.maxDate = Utils.getDateTimeMaxValue(); 
		this.setModel(undefined);
	}

	onInitSetModel(): void { }

	getKeyModel(obj: Notificacion): Guid {
		return obj.NotificacionID;
	}

	getNewModel(): Notificacion {
		return new Notificacion();
	}

	setModelPropsByControls(): void {
		this.model.CorralRef = this.corralID;
		this.model.NotificacionTexto = this.NotificacionTexto.getValue();
		this.model.FechaCreacion = this.Fecha.getFecha();
		if (this.Rol && this.Rol.getItem()) { this.model.RolRef = this.Rol.getItem().Key; }
		this.model.OrigenRolRef = this.user.Roles.some(f => f.RolRef === 1) ? 1 : this.user.Roles[0].RolRef;
		this.model.OrigenUsuarioRef = this.user.UsuarioID;
	}

	setModelControlValues() {
		if (this.model) {
			this.NotificacionTexto.setValue(this.model.NotificacionTexto);
			this.Fecha.setFecha(new Date(this.model.FechaCreacion));
			this.Rol.setValue(this.model.RolRef);
		}
		setTimeout(() => { this.NotificacionTexto.focus(); }, 100);
	}

	onCreateUpdateFinish(sucess: boolean) {
		if (!this.openedInDialog) {
			this.informSave(sucess, false);
			this.manuallyDetectChanges();
		}
	}

	onDeleteFinish(sucess: boolean) {
		if (sucess) {
			this.getMessengerInstance().broadcast('NotificacionComponent', 'TropaCorralNotificacionComponent', 'EliminarDataItem', this.model);
		}
	}

	loadUserData(): void {
		this.auth.getUserByToken().subscribe(data => {
			if (data.UsuarioID !== 0) {
				this.user = data;
				this.cd.detectChanges();
			}
		}, error => {
			this.user = undefined;
		});
	}
}
