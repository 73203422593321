// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivateChild } from '@angular/router';
// RxJS
import { Observable, of } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Module reducers and selectors
import { AppState} from '../../../core/reducers/';
import { currentUser, currentUserRoleIds,  } from '../_selectors/auth.selectors';
import { find } from 'lodash';
import { Role } from '../_models/role.model';
import { Usuario } from '../../../../models/usuario';

@Injectable()
export class ModuleGuard implements CanActivateChild {
	constructor(private store: Store<AppState>, private router: Router) { }


	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const expectedRole = childRoute.data.roles;

		if (!expectedRole || expectedRole.length === 0 || expectedRole === '*') {
			return of(true);
		}

        return this.store
            .pipe(
				select(currentUser),
				filter(u => u),
				map((usuario: Usuario) => { 
					const _perm = find(usuario.Roles, (elem: Role) => {
						return expectedRole.some(e => e.toLocaleLowerCase() === elem.RolNombre.toLocaleLowerCase());
					});
                    return _perm ? true : false;
                }),
                tap(hasAccess => {
                    if (!hasAccess) {
                        this.router.navigateByUrl('/error/403');
                    }
                })
            );
    }
}
