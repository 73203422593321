<div>
	<div class="row-sigef">
		<my-col-input #ProductoSanitarioFormatoNombre selectFirstInput [tabindex]="tabindex" [type]="2" [labelName]="'Nombre'" [required]="true" [hint]="'Ej: Bidon 2,5 lts, Caja de 100 dosis'"></my-col-input>
		<my-col-switch #Activo [tabindex]="tabindex + 1" [labelName]="'Activo'"></my-col-switch>
	</div>

	<div class="row-sigef">
		<my-col-input #FactorConversionUM [tabindex]="tabindex + 2" [labelName]="unidadMedidaNombre" [decimals]="0" [allowCero]="false" [required]="true" [hint]="'Ej: 2500 (para 2,5 lts), 100 (para 1 caja de 100 dosis)'"></my-col-input>
	</div>
</div>

<div class="ml-3 mr-3 pl-3 mt-3 row" [ngClass]="{'d-none': openedInDialog}">
	<my-group-save-buttons [tabindex]="tabindex + 2" class="w-100" [saving]="saving"
						   (onSave)="onFormatoSave($event)" (onCancel)="onFormatoCancel($event)"></my-group-save-buttons>
</div>
<my-message-dialog></my-message-dialog>
