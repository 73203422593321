import { Corral } from "./corral";
import { CorralInfoTropa } from "./corral-info-tropa";

export class DraggableCorral {
	public items: DraggableItem[];
	public esEncierre: boolean;
	public dias: number;
	public hotelero: string;
	public dieta: string;
	public pesoPromedio: number;
	public nombre: string;
	public corral: Corral;

	constructor() {
		this.items = [];
	}

	total(): number {
		let c: number = 0;

		for (const d of this.items) {
			c += d.tropa.Cantidad;
		}

		return c;
	}
}

export class DraggableItem {
	public tropa: CorralInfoTropa;
	DesbastePorcAnt: number;
	PesoCabezaKgAnt: number;
	public cambioModel: InputCambioCorralModel;
	public containerOrigen: any;
	public containerDestino: any;

	constructor(_t: CorralInfoTropa) {
		this.tropa = _t;
		this.DesbastePorcAnt = this.tropa.DesbastePorc;
		this.PesoCabezaKgAnt = this.tropa.PesoCabezaKg;
	}
}

export class InputCambioCorralModel {
	Fecha: Date = new Date();
	Cantidad: number = undefined;
	PesoCabezaKg: number = undefined;
	DesbastePorc: number = undefined;
	Observaciones: string = undefined;
	PrecioVenta: number = undefined;
	GenerarPesada: boolean = false;
	CorralOrigenRef: number = undefined;
	CorralDestinoRef: number = undefined;

	MaxCantidad: number = undefined;
	DietaOrigen: string = "";
	DietaDestino: string = "";
	CambiarDieta: boolean = false;

	CambioCorralTipo: string = "Tropa";
}
