import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http'; 
import { CategoriaPS } from '../models/categoria-ps';

@Injectable()
export class CategoriaPSService extends GenericoService<CategoriaPS> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'categoriaps');
	}
}
