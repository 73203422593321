import { DTOInterface } from "../interfaces/dto.interface";
import { Guid } from "guid-typescript";

export class MovTropaCorral implements DTOInterface { 
	TropaCategoriaGanadoOrigenRef: Guid;
	TropaCategoriaGanadoDestinoRef: Guid;
	CategoriaGanadoRef: number;
	FechaMovimiento: Date;
	Cantidad: number;
	Observaciones: string;
	DesbastePorc: number;
	PesoCabezaKg: number;
	FechaCreacion: Date;
	CorralOrigenRef: number;
    CorralDestinoRef: number; 
	PrecioVenta: number;
	GenerarPesada: boolean;

	EsCambioCorralCompleto: boolean;
	CambiarDieta: boolean;
	AumentoDiarioEstKg: number;

	constructor() {
		this.clear();
	}

	clear(): void { 
		this.TropaCategoriaGanadoOrigenRef = Guid.createEmpty();
		this.TropaCategoriaGanadoDestinoRef = Guid.createEmpty();
		this.CategoriaGanadoRef = 0;
        this.FechaMovimiento = new Date();
		this.Cantidad = 0;
		this.Observaciones = '';
		this.DesbastePorc = 0;
		this.PesoCabezaKg = 0;
        this.FechaCreacion = new Date();
		this.CorralOrigenRef = -1;
        this.CorralDestinoRef = -1; 
		this.PrecioVenta = 0;
		this.GenerarPesada = false;

		this.EsCambioCorralCompleto = false;
		this.CambiarDieta = false;
		this.AumentoDiarioEstKg = 0;
	}
}
