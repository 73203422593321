import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseIconMovimiento'
})
export class ParseIconMovimientoPipe implements PipeTransform {

  transform(e: any, ...args: any[]): string {
	  if (e > 0)
		  return "fas fa-caret-square-up movimiento--positivo";
	  else if (e < 0)
		  return "fas fa-caret-square-down movimiento--negativo";
	  else
		  return  "fas fa-caret-square-right"
  }

}
