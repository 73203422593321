import { DTOInterface } from "../interfaces/dto.interface";

export class VentaDestino implements DTOInterface { 
	VentaDestinoID: number;
	VentaDestinoNombre: string;
	Activo: boolean;

	constructor() {
		this.clear();
	}

	clear(): void {
		this.VentaDestinoID = -1;
		this.VentaDestinoNombre = '';
		this.Activo = true;
	}
}
