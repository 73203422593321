import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MyColInputComponent } from '../../../partials';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AutenticacionService } from '../../../../../services/autenticacion.service';
import { AuthNoticeService, Login } from '../../../../core/auth';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { tap, finalize, takeUntil } from 'rxjs/operators';
import Utils from '../../../../../utils';
import { ApiResponse } from '../../../../../interfaces/api-response.interface';
import { Validators, FormControl, ValidationErrors, AbstractControl } from '@angular/forms';

@Component({
	selector: 'kt-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {
	@ViewChild('Password', { static: true }) PasswordInput: MyColInputComponent;
	@ViewChild('ConfirmPassword', { static: true }) ConfirmPasswordInput: MyColInputComponent;

	loading = false;
	errors: any = [];

	private unsubscribe: Subject<any>;

	private token: any;
	params$: any;
	params: ParamMap;

	public parent: any = this;

	constructor(
		private router: Router,
		private auth: AutenticacionService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute
	) {
		this.unsubscribe = new Subject();
	}

    ngAfterViewInit(): void { }

	ngOnInit(): void {
		this.params$ = this.route.paramMap.subscribe(params => {
			this.params = params;
			this.token = this.params.get('token');
		});
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	submit() {
		this.addCustomValidators();
		this.authNoticeService.setNotice('', 'info');
		let valid: boolean = true;

		const authData = {
			Password: this.PasswordInput.getValue(),
			ConfirmPassword: this.ConfirmPasswordInput.getValue(),
		};

		if (!this.PasswordInput.isValid()) {
			this.PasswordInput.showError(true);
			valid = false;
		}

		if (!this.ConfirmPasswordInput.isValid()) {
			this.ConfirmPasswordInput.showError(true);
			valid = false;
		}

		if (!valid) {
			return;
		}

		this.loading = true;

		this.auth
			.resetPassword(this.token, authData.Password)
			.pipe(
				tap(response => {
					if (Utils.checkAPIResponse(response, undefined, '')) {
						this.authNoticeService.setNotice(this.translate.instant('AUTH.FORGOT.SUCCESS'), 'success');
					} else {
						let apiResponse = (response as unknown as ApiResponse);
						this.authNoticeService.setNotice(apiResponse.StatusDescription, 'danger');
					}
					this.router.navigateByUrl('/auth/login');
				}
				),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			)
			.subscribe();
	}

	onInputValueChange(newValue, labelName): void {
		this.addCustomValidators();
	}

	addCustomValidators(): void {
		this.ConfirmPasswordInput.setValidators([Validators.required, this.validateConfirmPassword(this.PasswordInput.getValue()), Validators.minLength(8), Utils.validateNoSpaces, Utils.validateLetterChar, Utils.validateNumberChar, Utils.validateSpecialChar],true);
	}

	validateConfirmPassword(matchTo: string): (AbstractControl) => ValidationErrors | null {
		return (confirmar: AbstractControl): ValidationErrors | null => {
			return !!confirmar && !!confirmar.value && confirmar.value === matchTo ? null : {
				validateConfirmPassword: {
					valid: false
				}
			};
		}
	}	
}
