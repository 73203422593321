import { Component, Input, ChangeDetectorRef, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { CorralInfo } from '../../../../../models/corral-info';
import { CorralService } from '../../../../../services/corral.service';
import { DietaService } from '../../../../../services/dieta.service';
import { HelpersTropaEditDietaMS } from '../helpers-tropa';
import { Observable, Subject } from 'rxjs';
import { MessengerService } from '../../../../../services/messenger.service';
import { ConsumoChartComponent } from '../../charts';
import { CambiarDietaComponent } from '../../corral/cambiar-dieta/cambiar-dieta.component';
import { IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { Corral } from '../../../../../models/corral';
import { SignalRService, SignalRMessage } from '../../../../../services/signalR.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { TropasSeguimientoComponent } from '../../../pages';
import { TropaCorralNotificacionComponent } from '../tropa-corral-notificacion';
import { takeUntil } from 'rxjs/operators';
import { BroadcastMessage } from '../../../../../interfaces/message.interface';
import { MyGroupUpDownValueComponent } from '../../common_controls';
import { AfterViewInit } from '@angular/core';

@Component({
	selector: 'kt-tropa-seguimiento-corral-list',
	templateUrl: './tropa-seguimiento-corral-list.component.html',
	styleUrls: ['./tropa-seguimiento-corral-list.component.scss'],
	providers: [CorralService, DietaService, SignalRService]
})
export class TropaSeguimientoCorralListComponent extends HelpersTropaEditDietaMS implements IParentOpenInDialog, AfterViewInit {
	@ViewChild('corralContainer', { static: true }) corralContainer: ElementRef;

	saveResultDialogModel(model: any): void {
		this.corralService.updateDieta([this.corral.CorralID], model.Key).subscribe(data => {
			super.getQuickPanelInstance().hideQuickPanel();
		}, error => { }, () => { this.buscar(); });
	}

	@ViewChildren(MyGroupUpDownValueComponent) myGroupUpDownValueComponents: QueryList<MyGroupUpDownValueComponent> = undefined;
	component: TropaSeguimientoCorralListComponent = this;

	public corral: Corral;
	public hotelero: number = 0;
	public alimentacion: number = 0;
	public incluirTropasCerradas: boolean = false;

	private destroy$: Subject<void> = new Subject();

	@Input() consumo: boolean = false;

	@Input() public set cliente(data: any) {
		this.hotelero = data ? data.Key : 0;
		this.buscar();
	}

	@Input() public set dieta(data: any) {
		this.alimentacion = data ? data.Key : 0;
		this.buscar();
	}

	constructor(messengerService: MessengerService,
		dietaService: DietaService,
		corralService: CorralService,
		private signalRService: SignalRService,
		cd: ChangeDetectorRef,
	    quickPanelService: QuickPanelService) {
		super(messengerService, dietaService, corralService, cd, quickPanelService);
	}

	ngAfterViewInit() {
		this.myGroupUpDownValueComponents.changes
			.subscribe((queryChanges) => {
				this.myGroupUpDownValueComponents = queryChanges;
			});
	}

	ngOnInit() {
		this.subscribeSignalR();
		this.signalRService.connect();

		super.getMessengerInstance().messageBroadcasted$.pipe(takeUntil(this.destroy$)).subscribe((data: BroadcastMessage) => {
			if (data) {
				if (data.Reason == 'releaseUIControl')
					this.toggleUpDownButton(data.Target, false);
				if (data.Reason == 'blockUIControl')
					this.toggleUpDownButton(data.Target, true);
			}
		});
	}

	toggleUpDownButton(target, block) {
		let q = this.myGroupUpDownValueComponents;
		let dropDownComponent: MyGroupUpDownValueComponent = q.find(x => x.id == target);
		if (dropDownComponent) {
			//console.log(target, '=>', block);
			//console.log(dropDownComponent);
			dropDownComponent.setBlockedStatus(block);
		}
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.signalRService.disconnect();
	}

	onKeydown(grid: any, $event: any) {
		if ($event.key === 'Escape') {
			this.mostrarDietaDropdown = false;
		}
	}

	getInfoList(): Observable<CorralInfo[]> {
		return this.corralService.getInfoByClienteDieta(this.hotelero, this.alimentacion);
	}

	parent: TropasSeguimientoComponent;
	setParent(form: TropasSeguimientoComponent) {
		this.parent = form;
	}

	abrirTropa(item: any): void {
		this.parent.verTropa(item.TropaRef || 0);
	}

	cambiarDieta(item: any): void {
		this.corral = item;

		if (this.corral) {
			super.getQuickPanelInstance().createOffCanvasComponent('Modificar dieta de corral', CambiarDietaComponent, undefined, { CorralID: this.corral.CorralID, DietaRef: item.DietaRef, Parent: this.component });
		}
	}

	verConsumo(item: any): void {
		let corral = item.CorralID;

		if (corral) {
			this.MessageDialog.showComponentDialog('Gráfico de Consumo', ConsumoChartComponent, undefined, { CorralID: corral }, { Width: ((this.corralContainer.nativeElement.offsetWidth * 98) / 100), HideSaveButton: true, HideCancelButton: true });
		}
	}

	verNotificaciones(item: any): void {
		let corral = item.CorralID;

		if (corral) {
			this.getQuickPanelInstance().createOffCanvasComponent('Notificaciones', TropaCorralNotificacionComponent, item);
		}
	}

	totalNotificaciones(item: any): void {
		return item.Notificaciones.length > 0 ? item.Notificaciones.length: '';
	}

	subscribeSignalR(): void {
		this.signalRService.corralDescargaAddUpdate.subscribe((msg: SignalRMessage) => {
			this.refreshInfoCorral(msg.corralRef);
		});
		this.signalRService.corralDescargaDelete.subscribe((msg: SignalRMessage) => {
			this.refreshInfoCorral(msg.corralRef);
		});
		this.signalRService.corralRemanenteAddUpdate.subscribe((msg: SignalRMessage) => {
			this.refreshInfoCorral(msg.corralRef);
		});
		this.signalRService.corralRemanenteDelete.subscribe((msg: SignalRMessage) => {
			this.refreshInfoCorral(msg.corralRef);
		});
		this.signalRService.corralAddUpdate.subscribe((msg: SignalRMessage) => {
			this.refreshInfoCorral(msg.corralRef);
		});
		this.signalRService.movTropaAddUpdate.subscribe((msg: SignalRMessage) => {
			this.refreshInfoCorral(msg.corralRef);
		});
		this.signalRService.movTropaDelete.subscribe((msg: SignalRMessage) => {
			this.refreshInfoCorral(msg.corralRef);
		});
	}

	refreshInfoCorral(corralRef: number): void {
		let cIndex = this.datos.findIndex(r => r.CorralID == corralRef);
		if (cIndex > -1) {
			this.corralService.getInfoByCorral(corralRef).subscribe(data => {
				if (data.length > 0) {
					this.datos[cIndex] = data[0];
					this.actualizarSumatorias();
			    	this.getMessengerInstance().broadcast('TropaSeguimientoCorralListComponent', 'MyGroupUpDownValueComponent', 'UpdateValue', { Value: data[0].MetaDiaria, CorralRef: corralRef });
				}
			}, error => {
				//console.log('No se pudo obtener info del corral.', error);
			}, () => {
					this.cd.detectChanges();
			});
		}
	}
}
