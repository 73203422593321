import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { NameValueListExtend } from '../../../../../interfaces/nvl.interface';
import { CommonControl } from '../common-controls.interface';
import { CorralService } from '../../../../../services/corral.service';

@Component({
	selector: 'my-col-input-search',
	templateUrl: './my-col-input-search.component.html',
	styleUrls: ['./my-col-input-search.component.scss']
})
export class MyColInputSearchComponent extends CommonControl implements OnInit {
	@Input() aloneInRow: boolean = true;
	@Input() soloConCabezas: boolean = true;
	@Input() placeholder: string = 'Seleccione Corral';
	@Input() hint: string = '';

	keySet: number = undefined;
	itemSelected: NameValueListExtend = undefined;
	Data: NameValueListExtend[] = [];
	corrales: NameValueListExtend[] = [];
	firstTime: boolean = true;

	getClass(): string {
		if (this.aloneInRow)
			return 'col-sigef-min-width';
		else
			return 'col-sigef-width';
	}

	constructor(private cd: ChangeDetectorRef) {
		super();
		this.Form = new UntypedFormGroup({
			'Input': new UntypedFormControl(undefined),
			'Description': new UntypedFormControl(undefined)
		});
	}

	handleCorralFilter(value) {
		this.corrales = this.Data.filter((s) => (s.Descripcion.toLowerCase().indexOf(value.toLowerCase()) !== -1));
	}

	descripcionSelected(): string {
		return (this.itemSelected) ? this.itemSelected.Descripcion : this.getNoSelectedDescription();
	}

	getNoSelectedDescription(): string {
		return (this.firstTime ? 'Seleccione Corral' : 'Corral Inexistente');
	}

	getInputControl(): AbstractControl {
		return this.Form.controls['Input'];
	}
	getDescriptionControl(): AbstractControl {
		return this.Form.controls['Description'];
	}
	getPrincipalControl(): AbstractControl {
		return this.getInputControl();
	}

	ngOnInit() {
		this.Form.reset();
		if (this.required) {
			this.getInputControl().setValidators(Validators.required);
			this.getDescriptionControl().setValidators(Validators.required);
		}
		this.getInputControl().valueChanges.subscribe(val => {
			this.onValueChange(val);
		});
	}

	setData(value: NameValueListExtend[]) {
		this.Data = value;
		this.corrales = value;
	}

	getItem(): NameValueListExtend {
		return this.itemSelected;
	}

	setValue(key: number): void {
		this.keySet = key;
		this.itemSelected = this.Data.find(o => o.Key === key);

		if (this.itemSelected) {
			this.Form.patchValue({ Input: this.itemSelected.Key });
			this.Form.patchValue({ Description: this.itemSelected.Descripcion });
		}
		else {
			this.Form.patchValue({ Input: '' });
			this.Form.patchValue({ Description: '' });
		}
	}

	valueChange(e): void {
		// patch en la logica existente por si hay que cambiar el componente
		this.onValueChange(e);
	}

	onValueChange(newValue: any): void { 
		if (!this.Data) return;
		this.itemSelected = this.Data.find(o => o.Key === newValue);

		this.firstTime = false;
		if (this.itemSelected) 
			this.Form.patchValue({ Description: this.itemSelected.Descripcion });
		else 
			this.Form.patchValue({ Description: "" });

		if (!this.parent || !this.parent.onInputSearchValueChange) return;
		this.parent.onInputSearchValueChange(newValue, this.labelName, this.itemSelected);
	}

	initializeControlByCorral(corralService: CorralService) {
		corralService.getList().subscribe(data => {
			let lst: NameValueListExtend[] = [];
			for (const corral of data) {
				if (corral.Cabezas <= 0 && this.soloConCabezas) {
					continue;
				}

				let itm: NameValueListExtend = {
					Key: corral.CorralID,
					Value: corral.CorralNumero.toString(),
					Descripcion: `${corral.CorralNumero} - ${corral.CorralNombre}`
				}
				lst.push(itm);
			}
			this.setData(lst);
			if (this.keySet)
				this.setValue(this.keySet);
			this.cd.detectChanges();
		});
	}
}
