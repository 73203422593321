<div class="container">
	<div class="row login__row">
		<div class="col-12 mx-auto">
			<div class="card card-signin my-5">
				<div class="card-body">
					<div class="kt-login__title"></div>

					<kt-auth-notice></kt-auth-notice>

					<form class="form-signin">
						<div class="form-label-group">
							<my-col-input [tabindex]="2" #Usuario [type]="2" [labelName]="'AUTH.INPUT.EMAIL' | translate" [required]="true" [divClass]="''" [labelClass]="'kt-sigef-login--label'"></my-col-input>
						</div>
						<div class="form-label-group">
							<my-col-input [tabindex]="3" #Password [inputType]="'password'" [type]="2" [labelName]="labelName" [required]="true" [divClass]="''" [labelClass]="'kt-sigef-login--label'"></my-col-input>
						</div>
						<div class="text-center pr-3 pt-2" tabindex="-1">
							<button tabindex="6" (click)="login()" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}" id="kt_login_signin_submit" class="btn btn-sigef btn-sigef-login">Ingresar</button>
						</div>
					</form>
					<div class="login-provider text-center d-flex justify-content-between" tabindex="-1">
						<a href="/api/account/google-Login" class="btn btn-sigef-secondary btn-sm btn-bold "><span><img class="mr-2" src="./assets/media/login/google.svg"></span>Ingresar con Google</a>
						<a href="/api/account/facebook-Login" class="btn btn-sigef-secondary btn-sm btn-bold"><span><img class="mr-2" src="./assets/media/login/facebook.svg"></span>Ingresar con Facebook</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
