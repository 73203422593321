<div class="col">
	<div class="form-group" [formGroup]="Form">
		<label *ngIf="labelVisible" class="pb-1">{{labelName}}</label>
		<kendo-colorpicker [view]="'palette'"
						   tabindex="{{tabindex}}"
						   style="display: flex;"
						   [format]="'hex'"
						   [openDelay]="300" [closeDelay]="500" triggers="mouseenter:mouseleave"
						   ngbPopover={{tooltipMessage}}
						   popoverTitle={{tooltipTitle}} 
						   readonly
						   container="body"
						   formControlName="Color"
						   #Color
						   [paletteSettings]="settings"
						   [value]="color"
						   (valueChange)="setValue($event)">
		</kendo-colorpicker>
		<kt-inline-server-validation [ngClass]="{'d-none': !showValidation}" [input]="Color" [formGroup]="Form" [field]="'Color'"></kt-inline-server-validation>
	</div>
</div>
