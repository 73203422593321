import { MessageService } from '@progress/kendo-angular-l10n';
import { Injectable } from "@angular/core"; 

const messages = {
	'kendo.datetimepicker.dateTab': 'Fecha',
	'kendo.datetimepicker.dateTabLabel': 'Mostrar fecha',
	'kendo.datetimepicker.timeTab': 'Hora',
	'kendo.datetimepicker.timeTabLabel': 'Mostrar hora',
	'kendo.datetimepicker.toggle': 'Cambiar calendario',
	'kendo.datetimepicker.accept': 'Acceptar',
	'kendo.datetimepicker.acceptLabel': 'Acceptar',
	'kendo.datetimepicker.cancel': 'Cancelar',
	'kendo.datetimepicker.cancelLabel': 'Cancelar',
	'kendo.datetimepicker.now': 'AHORA',
	'kendo.datetimepicker.nowLabel': 'Ahora',
	'kendo.datetimepicker.today': 'HOY',

	'kendo.datepicker.today': 'Hoy',
	'kendo.datepicker.toggle': 'Mostrar/Ocultar Calendario',

	'kendo.calendar.today': 'Hoy',

	'kendo.autocomplete.noDataText': 'Sin Datos',
	'kendo.autocomplete.clearTitle': 'limpiar',

	'kendo.combobox.noDataText': 'Sin Datos',
	'kendo.combobox.clearTitle': 'limpiar',

	'kendo.dropdownlist.noDataText': 'Sin Datos',
	'kendo.dropdownlist.clearTitle': 'limpiar',

	'kendo.multiselect.noDataText': 'Sin Datos',
	'kendo.multiselect.clearTitle': 'limpiar',

	'kendo.grid.groupPanelEmpty': 'Arrastre el título de una columna y suéltelo aquí para agrupar por ese criterio',
	'kendo.grid.noRecords': 'No hay datos disponibles.',
	'kendo.grid.pagerFirstPage': 'Ir a la primera página',
	'kendo.grid.pagerPreviousPage': 'Ir a la página anterior',
	'kendo.grid.pagerNextPage': 'Ir a la página siguiente',
	'kendo.grid.pagerLastPage': 'Ir a la última página',
	'kendo.grid.pagerPage': 'Página',
	'kendo.grid.pagerOf': 'de',
	'kendo.grid.pagerItems': 'ítems',
	'kendo.grid.pagerItemsPerPage': 'ítems por página',
	'kendo.grid.detailCollapse': 'Colapsar detalles',
	'kendo.grid.detailExpand': 'Expandir detalles',
	'kendo.grid.filter': 'Filtro',
	'kendo.grid.filterEqOperator': 'Es igual a',
	'kendo.grid.filterNotEqOperator': 'No es igual a',
	'kendo.grid.filterIsNullOperator': 'Es nulo',
	'kendo.grid.filterIsNotNullOperator': 'No es nulo',
	'kendo.grid.filterIsEmptyOperator': 'Está vacío',
	'kendo.grid.filterIsNotEmptyOperator': 'No está vacío',
	'kendo.grid.filterStartsWithOperator': 'Comienza con',
	'kendo.grid.filterContainsOperator': 'Contiene',
	'kendo.grid.filterNotContainsOperator': 'No contiene',
	'kendo.grid.filterEndsWithOperator': 'Termina en',
	'kendo.grid.filterGteOperator': 'Es mayor o igual que',
	'kendo.grid.filterGtOperator': 'Es mayor que',
	'kendo.grid.filterLteOperator': 'Es menor o igual que',
	'kendo.grid.filterLtOperator': 'Es menor o igual que',
	'kendo.grid.filterIsTrue': 'Sí',
	'kendo.grid.filterIsFalse': 'No',
	'kendo.grid.filterBooleanAll': '(Todas)',
	'kendo.grid.filterAfterOrEqualOperator': 'Es posterior o igual a',
	'kendo.grid.filterAfterOperator': 'Es posterior',
	'kendo.grid.filterBeforeOperator': 'Es anterior',
	'kendo.grid.filterBeforeOrEqualOperator': 'Es anterior o igual a',
	'kendo.grid.filterFilterButton': 'Filtrar',
	'kendo.grid.filterClearButton': 'Limpiar filtros',
	'kendo.grid.filterAndLogic': 'Y',
	'kendo.grid.filterOrLogic': 'O',
	'kendo.grid.loading': 'Cargando',
	'kendo.grid.sort': 'Orden',
	'kendo.grid.columnMenu': 'Menú de Columnas',
	'kendo.grid.columns': 'Columnas',
	'kendo.grid.lock': 'Bloquear',
	'kendo.grid.unlock': 'Desbloquear',
	'kendo.grid.sortAscending': 'Orden Ascendente',
	'kendo.grid.sortDescending': 'Orden Descendente',
	'kendo.grid.columnsApply': 'Aplicar',
	'kendo.grid.columnsReset': 'Reiniciar',
	'kendo.grid.sortable': 'Ordenable',
	'kendo.grid.sortedAscending': 'Ordenado ascendentemente',
	'kendo.grid.sortedDescending': 'Ordenado descendentemente',
	'kendo.grid.sortedDefault': 'Sin Orden',

	'kendo.numerictextbox.increment': 'Incrementar valor',
	'kendo.numerictextbox.decrement': 'Disminuir valor',

	'kendo.timepicker.accept': 'Fijar',
	'kendo.timepicker.acceptLabel': 'Fijar',
	'kendo.timepicker.cancel': 'Cancelar',
	'kendo.timepicker.cancelLabel': 'Cancelar',
	'kendo.timepicker.now': 'Ahora',
	'kendo.timepicker.nowLabel': 'Ahora',
	'kendo.timepicker.toggle': 'Alternar lista de tiempo',

	'kendo.multiviewcalendar.today': 'HOY',

	'kendo.scheduler.today': 'HOY',
	'kendo.scheduler.previousTitle': 'Anterior',
	'kendo.scheduler.nextTitle': 'Siguiente',
	'kendo.scheduler.monthViewTitle': 'Mes',
	'kendo.scheduler.calendarToday': 'HOY',
	'kendo.scheduler.agendaViewTitle': 'Semana',
	'kendo.scheduler.eventHeader': 'Evento',
	'kendo.scheduler.dateHeader': 'Día',
};

@Injectable()
export class IntlService extends MessageService {
	public get(key: string): string {
		return messages[key];
	}
}
