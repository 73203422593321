import { Guid } from "guid-typescript";

export class EstimadoTropaCategoria {
	TropaRef: Guid;
	TropaCategoriaGanadoRef: Guid;

	constructor() {
		this.TropaRef = undefined;
		this.TropaCategoriaGanadoRef = undefined;
	}
}
