import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';
import { Venta } from '../models/Venta';
import { Observable } from 'rxjs';
import { Guid } from 'guid-typescript';
import { environment } from '../environments/environment';

@Injectable()
export class VentaService extends GenericoService<Venta> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'venta');
	}

	getVentaListByTropa(tropaID: Guid): Observable<Venta[]> {
		return this._httpClient.get<Venta[]>(environment.apiUrl + `/${this._endpoint}/tropa/${tropaID}`, { headers: this._getHeaders });
	}

	getFilteredList(clienteId: number, compradorId: number, tropaId: Guid, sinDatos: boolean, desde: Date, hasta: Date): Observable<Venta[]> {
		return this._httpClient.post<Venta[]>(environment.apiUrl + `/${this._endpoint}/listado`, { ClienteID: clienteId, CompradorID: compradorId, TropaID: tropaId, SinDatos: sinDatos, FechaDesde: desde, FechaHasta: hasta }, { headers: this._postHeaders });
	}
}
