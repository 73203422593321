<div class="row-sigef">
	<my-col-input selectFirstInput [focusOnInit]="true" [tabindex]="tabindex" #Cantidad [decimals]="0" [labelName]="'Cantidad Cabezas'" [required]="false" [allowCero]="false"></my-col-input>
</div>

<div class="row-sigef">
	<my-col-input [tabindex]="tabindex + 1" #KilosVivo [mask]="'#,# Kg'" [decimals]="0" [labelName]="'Kilos Vivos'" [required]="false" [allowCero]="true"></my-col-input>
</div>

<div class="row-sigef">
	<my-col-input [tabindex]="tabindex + 2" #KilosCarne [mask]="'#,# Kg'" [decimals]="0" [labelName]="'Kilos Carne'" [required]="false" [allowCero]="true"></my-col-input>
</div>

<div class="row-sigef">
	<my-col-input [tabindex]="tabindex + 3" #PrecioFaena [mask]="'c2'" [labelName]="'Precio x Kg'" [required]="false" [allowCero]="true"></my-col-input>
</div>

<div class="row-sigef">
	<my-col-labelinfo #RindePorc [labelName]="'Rinde (%)'" [extraClass]="'pl-3'"></my-col-labelinfo>
</div>

<div class="kt-separator kkt-separator--fit"></div>

<div class="ml-3 mr-3 pl-3 row">
	<my-group-save-buttons [tabindex]="tabindex + 4" class="w-100" [saving]="saving" [targetName]="''"
						   (onSave)="onFaenaSave($event)" (onCancel)="onFaenaCancel($event)"></my-group-save-buttons>
</div>

<my-message-dialog></my-message-dialog>
