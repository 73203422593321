import { DTOInterface } from "../interfaces/dto.interface";
import { ProductoSanitarioFormato } from "./producto-sanitario-formato";

export class ProductoSanitario implements DTOInterface { 
	ProductoSanitarioID: number;
	ProductoSanitarioNombre: string;
	UnidadMedidaRef: number;
	Activo: boolean;
	CategoriaPSRef: number;
	StockMinimo: number;

	UnidadMedidaNombre: string;
	UnidadMedidaAbrev: string;
	CategoriaPSNombre: string;

	Formatos: ProductoSanitarioFormato[];

	constructor() {
		this.clear();
	}

	clear(): void {
		this.ProductoSanitarioID = -1;
		this.ProductoSanitarioNombre = '';
		this.UnidadMedidaRef = 0;
		this.Activo = true;
		this.CategoriaPSRef = 0;
		this.StockMinimo = 0;
		this.UnidadMedidaNombre = '';
		this.CategoriaPSNombre = '';

		this.Formatos = [];
	}
}
