<div class="col w-100">
	<div class="row row-full-height">
		<div class="kt-portlet kt-portlet--mobile">
			<div class="kt-portlet__head">
				<div class="kt-portlet__head-label">
					<h3 class="kt-portlet__head-title">
						<i class="fas fas fa-info-circle"></i>&nbsp; Datos de Dieta
					</h3>
				</div>
			</div>
			<div class="kt-portlet__body">
				<kt-dieta [dieta]="model"></kt-dieta>
			</div>
		</div>
	</div>
</div>

<div class="col w-100">
	<div class="row row-full-height">
		<div class="kt-portlet kt-portlet--mobile">
			<div class="kt-portlet__head">
				<div class="kt-portlet__head-label">
					<h3 class="kt-portlet__head-title">
						<i class="fas fa-file-alt"></i>&nbsp; Alimentos
					</h3>
				</div>
			</div>
			<div class="kt-portlet__body">
				<div class="row">
					<kt-dieta-alimento [dieta]="model"></kt-dieta-alimento>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="col w-100">
	<div class="row row-full-height">
		<div class="kt-portlet kt-portlet--mobile">
			<div class="kt-portlet__head">
				<div class="kt-portlet__head-label">
					<h3 class="kt-portlet__head-title">
						<i class="fas fa-sort-numeric-down"></i>&nbsp; Orden de descargas
					</h3>
				</div>
			</div>
			<div class="kt-portlet__body">
				<div class="row">
					<kt-dieta-orden [dieta]="model"></kt-dieta-orden>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="col w-100">
	<div class="row row-full-height">
		<div class="kt-portlet kt-portlet--mobile">
			<div class="kt-portlet__body">
				<div class="row mr-2 ml-2">
					<my-group-save-buttons class="w-100" [saving]="saving" [deleting]="deleting" [allowDelete]="!readonly" [targetName]="'Dieta'"
										   (onSave)="onSave($event)" (onCancel)="onCancel($event)" (onDelete)="onDelete($event)"></my-group-save-buttons>
				</div>
			</div>
		</div>
	</div>
</div>



<my-message-dialog></my-message-dialog>
