<kendo-grid [kendoGridBinding]="datos"
			kendoGridPersistentState gridName="GridCorralTropa"
			style="width: auto"
			[sortable]="true"
			[navigable]="true"
			[resizable]="true"
			[reorderable]="true"
			[columnMenu]="true"
			[loading]="buscando">
	<kendo-grid-messages noRecords="No hay tropas activas">
	</kendo-grid-messages>

	<kendo-grid-column class="no-padding" title="Tropa" field="TropaNombre" width="130" [includeInChooser]="false">
		<ng-template kendoGridCellTemplate let-dataItem>
			<div class="ribbon-content" style="height:100%; width:100%; position:relative;" [style.borderColor]="dataItem.ColorPrincipal">
				<div class="h-100">
					<a class="kt-link center-div" title="Ver resumen detallado" href="javascript:;" (click)="abrirTropa(dataItem)">
						<span>{{dataItem.CodigoTropa | parseCodigoTropa}} - {{dataItem.TropaNombre}} </span>
					</a>
				</div>
			</div>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column field="Hotelero" title="Propietario" width="110" [columnMenu]="false"></kendo-grid-column>

	<kendo-grid-column title="Fecha Ingreso" width="120" field="FechaIngreso" [columnMenu]="false">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{dataItem.FechaIngreso | parseDate}}</span>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column class="text-right" title="Días Corral" field="FechaIngreso" width="100" [columnMenu]="false">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{dataItem.FechaIngreso | diasEnCorral }}</span>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column class="text-left" title="Hacienda" width="100" [columnMenu]="false">
		<ng-template kendoGridCellTemplate let-dataItem>
			<!--<h6>Cabezas: {{ getCabezasPorTropaEnCorral(dataItem)}}</h6>-->
			<ng-container>
				<span style="font-size: 0.9rem">{{getHaciendaPorTropaEnCorral(dataItem)}}</span>
			</ng-container>
		</ng-template>
	</kendo-grid-column> 

	<kendo-grid-column class="text-right" [filterable]="false" title="Peso x Cab." field="PesoCabezaKg" width="105" [columnMenu]="false">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{ dataItem.PesoCabezaKg  | parseNumberKg }}</span>
		</ng-template>
	</kendo-grid-column>

</kendo-grid>

<my-message-dialog></my-message-dialog>
