
<div class="form-group form-group-marginless">
	<div class="form-group row">
		<div class="col-lg-5">
			<div>
				<label class="col-form-label col-form-label-title">{{titlePrecio}}</label>
			</div>
			<div>
				<div class="kt-radio-inline">
					<label tabindex="-1">
						<input [disabled]="edicion" type="radio" name="foo_categoria" value="categoria" tabindex="{{tabindex}}" kendoRadioButton (click)="onPrecioChange(false)" [checked]="!precioTropa" />
						<span tabindex="-1" class="pointer pl-3">Detallar por Categoría de Hacienda</span>
					</label>
				</div>

				<div class="kt-radio-inline">
					<label tabindex="-1">
						<input [disabled]="edicion" type="radio" name="foo_tropa" value="tropa" tabindex="{{tabindex + 1}}" kendoRadioButton (click)="onPrecioChange(true)" [checked]="precioTropa" />
						<span tabindex="-1" class="pointer pl-3">Total de la Tropa</span>
					</label>
					<my-col-input [disabled]="!precioTropa || edicion" [tabindex]="tabindex + 2" [mask]="'c2'" [labelVisible]="false" [readonly]="!precioTropa || edicion" #Precio class="hacienda-input" [parent]="parent" [labelName]="'Total'" [required]="precioTropa" [allowCero]="!precioTropa" [extraClass]="'precioTotalInput'"></my-col-input>
				</div> 
			</div>
		</div>
		<div class="col-lg-7">
			<div>
				<label class="col-form-label col-form-label-title">{{titlePeso}}</label>
			</div>
			<div>
				<div class="kt-radio-inline">
					<label tabindex="-1">
						<input [disabled]="edicion" type="radio" name="bar_categoria" value="categoria" tabindex="{{tabindex + 3}}" kendoRadioButton (click)="onPesoChange(false)" [checked]="!pesoTropa" />
						<span tabindex="-1" class="pointer pl-3">Detallar por Categoría de Hacienda</span>
					</label>
				</div>
				 
				<div class="kt-radio-inline">
					<label tabindex="-1">
						<input [disabled]="edicion" type="radio" name="bar_tropa" value="tropa" tabindex="{{tabindex + 4}}" kendoRadioButton (click)="onPesoChange(true)" [checked]="pesoTropa" />
						<span tabindex="-1" class="pointer pl-3">Total de la Tropa</span>
					</label>
					<my-col-input [disabled]="!pesoTropa || edicion" [tabindex]="tabindex + 5" [mask]="'#,# Kg'" [decimals]="0" [labelVisible]="false" [readonly]="!pesoTropa || edicion" #Peso class="hacienda-input" [parent]="_parent" [labelName]="'Total'" [required]="pesoTropa" [allowCero]="!pesoTropa"></my-col-input>
					<label tabindex="-1">
						Desbaste
					</label>
					<my-col-input [disabled]="!pesoTropa || edicion" [tabindex]="tabindex + 6" [mask]="'p2'" [decimals]="4" [factor]="'p'" [labelVisible]="false" [readonly]="!pesoTropa || edicion" #Desbaste class="desbaste-input" [parent]="_parent" [extraClass]="'desbaste-input'" [labelName]="'% Desbaste'" [required]="pesoTropa" [allowCero]="true"></my-col-input>
					<label tabindex="-1" class="col d-inline ml-2">Neto</label>
					<span tabindex="-1" class="font-weight-bold sigef--grid--note" #Neto></span>
				</div> 
			</div>
		</div>
	</div>
</div>
