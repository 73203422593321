<div *ngIf="formGroup.get(field).errors?.serverError || formGroup.get(field).errors">
	<div class="text-danger font-weight-bolder">
		<span class="fas fa-exclamation-circle"></span>
		<span *ngIf="formGroup.get(field).errors?.serverError">&nbsp;{{ formGroup.get(field).errors?.serverError }}<br /></span>
		<span *ngIf="formGroup.get(field).errors?.required">&nbsp;{{'CRUD.COMMON.REQUIRED_FIELD' | translate}}<br /></span>
		<span *ngIf="formGroup.get(field).errors?.min">&nbsp;{{'AUTH.VALIDATION.MIN' | translate}} {{formGroup.get(field).errors?.min.min}}<br /></span>
		<span *ngIf="formGroup.get(field).errors?.max">&nbsp;{{'AUTH.VALIDATION.MAX' | translate}} {{formGroup.get(field).errors?.max.max}}<br /></span>
		<span *ngIf="formGroup.get(field).errors?.minlength">&nbsp;Caracteres mínimos: {{ formGroup.get(field).errors?.minlength.requiredLength }}<br /></span>
		<span *ngIf="formGroup.get(field).errors?.email">&nbsp;{{'AUTH.VALIDATION.INVALID_FIELD' | translate}}<br /></span>
		<span *ngIf="formGroup.get(field).errors?.inexistente">&nbsp;Dato inexistente<br /></span>
		<span *ngIf="formGroup.get(field).errors?.validateNoZero">&nbsp;El valor debe ser mayor a cero.<br /></span>
		<span *ngIf="formGroup.get(field).errors?.validateSelectionDropDown">&nbsp;{{'CRUD.COMMON.REQUIRED_FIELD' | translate}}.<br /></span>
		<span *ngIf="formGroup.get(field).errors?.validateNoSpaces">&nbsp;No puede contener espacios<br /></span>
		<span *ngIf="formGroup.get(field).errors?.validateLowerChar">&nbsp;Debe contener al menos una letra minúscula<br /></span>
		<span *ngIf="formGroup.get(field).errors?.validateUppderChar">&nbsp;Debe contener al menos una letra mayúscula<br /></span>
		<span *ngIf="formGroup.get(field).errors?.validateNumberChar">&nbsp;Debe contener al menos un caracter numérico<br /></span>
		<span *ngIf="formGroup.get(field).errors?.validateLetterChar">&nbsp;Debe contener al menos una letra<br /></span>
		<span *ngIf="formGroup.get(field).errors?.validateSpecialChar">&nbsp;Debe contener al menos una caracter especial<br /></span>
		<span *ngIf="formGroup.get(field).errors?.validateConfirmPassword">&nbsp;Las contraseñas deben coincidir<br /></span>
		<span *ngIf="formGroup.get(field).errors?.duplicado">&nbsp;Esta opción ya ha sido seleccionada<br /></span>
		<span *ngIf="formGroup.get(field).errors && formGroup.get(field).errors['Mask error']">&nbsp;{{'AUTH.VALIDATION.INVALID_MASK' | translate}}<br /></span>
	</div>
</div>
