<div style="overflow-x: auto">
	<kendo-grid [kendoGridBinding]="datos" kendoGridPersistentState gridName="GridTropa"
				style="width: auto" 
				[sortable]="true"
				[navigable]="true"
				[resizable]="true"
				[reorderable]="true"
				class="tropa"
				[columnMenu]="true"
				[loading]="buscando">
		<kendo-grid-messages noRecords="No hay tropas activas para los filtros seleccionados">
		</kendo-grid-messages>

		<!--<ng-template kendoGridToolbarTemplate>
			<button type="button" kendoGridExcelCommand icon="file-excel">Exportar Excel</button>
		</ng-template>-->

		<kendo-grid-column class="no-padding" [filterable]="false" title="Tropa" field="TropaNombre" width="240" [includeInChooser]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<div class="ribbon-content" style="height:100%; width:100%; position:relative;" [style.borderColor]="dataItem.ColorPrincipal">
					<div class="h-100">
						<a class="kt-link center-div" title="Ver resumen detallado" href="javascript:;" (click)="abrirTropa(dataItem)">
							<span>{{dataItem.CodigoTropa | parseCodigoTropa}} - {{dataItem.TropaNombre}} </span>
						</a>
					</div>
				</div>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column *ngxPermissionsExcept="['Hotelero']" field="ClienteNombre" title="Propietario" width="100" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<span>{{dataItem.ClienteNombre ? dataItem.ClienteNombre : 'SIN PROPIETARIO' }}</span>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column title="Fecha de Ingreso" width="100" field="FechaIngreso" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<span>{{dataItem.FechaIngreso | parseDate}}</span>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column class="text-left" title="Hacienda" field="Cabezas" width="120" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<h6>Cabezas: {{dataItem.Cabezas}}</h6>
				<ng-container>
					<span style="font-size: 0.9rem">{{dataItem.Resumen}}</span>
				</ng-container>
			</ng-template>
			<ng-template kendoGridFooterTemplate let-column="column">
				<span class="text-left">{{ totalCabezas }} cab.</span>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column class="text-right" title="Días Corral" field="FechaIngreso" width="100" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<span>{{dataItem.FechaIngreso | diasEnCorral }}</span>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column field="Dietas" title="Dietas" width="100" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<ul class="d-table-row nobull">
					<li *ngFor="let d of dataItem.Dietas.split(',')">
						{{d}}
					</li>
				</ul>
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column class="text-right" [filterable]="false" field="KgEstimado" title="Peso x Cab." width="90" [columnMenu]="false">
			<ng-template kendoGridCellTemplate let-dataItem>
				<span>{{ dataItem.KgEstimado | parseNumberKg }}</span>
			</ng-template>
		</kendo-grid-column> 

		<!--<kendo-grid-excel fileName="Tropas.xlsx"></kendo-grid-excel>-->

		<div *kendoGridDetailTemplate="let dataItem">
			<kt-tropa-corral [tropa]="dataItem"></kt-tropa-corral>
		</div>
	</kendo-grid>
</div>

<my-message-dialog></my-message-dialog>
