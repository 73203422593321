import { Component, OnInit, Input, ChangeDetectorRef, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { Tropa } from '../../../../../models/tropa';
import { MovTropaService } from '../../../../../services/mov-tropa.service';
import { MovTropa } from '../../../../../models/mov-tropa';
import { EditableFormList } from '../../common_controls/common-controls.interface';
import { IParentOpenInDialog, MyMessageDialogComponent } from '../../common_controls/message-dialog/my-message-dialog.component';
import { MessengerService } from '../../../../../services/messenger.service';
import Utils, { MovTropaTipo, MovTipo } from '../../../../../utils';
import { TropaMovimientoPopupComponent } from '../tropa-movimiento-popup/tropa-movimiento-popup.component';
import { GroupDescriptor, SortDescriptor, groupBy, GroupResult } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid'; 
import { Subscription } from 'rxjs'; 
import { TropaMovimientoReadonlyComponent } from '../tropa-movimiento-readonly/tropa-movimiento-readonly.component';
import { QuickPanelService } from '../../../../../services/quick-panel.service';

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'kt-tropa-movimiento-list',
	templateUrl: './tropa-movimiento-list.component.html',
	styleUrls: ['./tropa-movimiento-list.component.scss'],
	providers: [MovTropaService]
})
export class TropaMovimientoListComponent extends EditableFormList<MovTropa> implements OnInit, OnDestroy, IParentOpenInDialog {

	@ViewChild(MyMessageDialogComponent, { static: true }) public MessageDialog: MyMessageDialogComponent;
	@ViewChild(GridComponent, { static: true }) public grid: GridComponent;

	private sub: Subscription = undefined;
	private obsTracker: ObsRow[] = [];

	public readonly: boolean = false;

	@Input() public tabindex: number = -1;
	@Input() public mostrarTropaCol: boolean = false;
	@Input() public orden: string = 'asc';
	@Input() public reporte: boolean = false;
	@Input() public nroTransaccion: number = 0; // el 0 funciona como 'Todos'
	@Input() public tipoSeleccionado: number = 0; // el 0 funciona como 'Todos'
	@Input() public clienteSeleccionado: number = 0; // el 0 funciona como 'Todos'
	@Input() public fDesde: Date = undefined;
	@Input() public fHasta: Date = undefined;

	public mostrarClienteCol: boolean = true;

	private _tropa: Tropa;
	@Input()
	set tropa(item: Tropa) {
		this._tropa = item; 
		this.buscar();
		this.cd.detectChanges();
	}
	get tropa(): Tropa { return this._tropa; }

	public groups: GroupDescriptor[] = [{ field: 'Grupo' }]; 
	public gridView: MovTropa[] | GroupResult[];

	public sort: SortDescriptor[] = [{ field: 'FechaMovimiento', dir: 'desc' }];

	constructor(private movTropaService: MovTropaService, private messengerService: MessengerService, cd: ChangeDetectorRef, private quickPanelService: QuickPanelService) {
		super(cd, movTropaService); 
	}

	ngOnInit() {
		this.initilizeForm(undefined);

		this.sub = this.messengerService.messageBroadcasted$.subscribe(data => {
			if (data === 'Refresh') {
				this.buscar();
			}
		});
	}

	ngOnDestroy() {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}

	loadList() {
		if (!this._tropa) return;

		this.groups = [{ field: 'Grupo', dir: 'desc' }];

		this.mostrarClienteCol = (this.clienteSeleccionado === 0 || (this._tropa.TropaID && !this._tropa.TropaID.isEmpty()));
		this.obsTracker = []; 

		if (this._tropa.TropaID && this._tropa.TropaID.isEmpty()) {
			// está viniendo desde el edit/view de tropa. Acá si o si tiene que tener un id sino que no busque nada.
			this.finalizarBusqueda();
			return;
		} else {
			if (!this._tropa.TropaID) {
				var diff = Math.abs(this.fDesde.valueOf() - this.fHasta.valueOf());
				if (Math.ceil(diff / (1000 * 3600 * 24)) > 7) {
					this.MessageDialog.showAtencionDialog("El rango máximo de búsqueda para 'Todas las Tropas' no puede ser mayor a 7 días.");
					return;
				};
			}
		}

		this.movTropaService.getTableReporteList(this._tropa.TropaID, this.fDesde, this.fHasta, this.tipoSeleccionado, this.clienteSeleccionado, this.nroTransaccion).subscribe(data => {
			this.datos = data;

			if (!this.reporte && this._tropa.TropaID) {
				// ocultar el boton de eliminar si hubo movimientos != a Compra (con uno alcanza)
				this.readonly = data.some(d => d.MovTropaTipoRef != MovTropaTipo.Compra); 
				this.messengerService.broadcastPayload('set_readonly', this.readonly);
			}

			this.gridView = groupBy(data, [{ field: 'Grupo' }]); //process(data, { group: this.groups, sort: this.sort });
		}, error => {
			this.MessageDialog.showExceptionDialog('No se pudo obtener la lista de movimientos.', error);
		}, () => {
			this.finalizarBusqueda();
		});
	}

	getKeyModel(obj: MovTropa): any { return obj.MovTropaID; }

	public removeHandler({ dataItem }) { this.confirmDelete(dataItem); }

	public showABM({ dataItem }) {
		this.quickPanelService.createOffCanvasComponent('Editar Movimiento', TropaMovimientoPopupComponent, dataItem, { TropaRef: this.tropa.TropaID }); 
	}

	verMovimiento(dataItem): void {
		this.quickPanelService.createOffCanvasComponent('Ver Movimiento', TropaMovimientoReadonlyComponent, dataItem);
	}

	public saveResultDialogModel(model: MovTropa) {
		this.saveModel(model);
	}

	onUpdatePost(): void {
		this.MessageDialog.showAtencionDialog('Movimiento actualizado correctamente.')
		this.messengerService.broadcastMessage('refresh_list');
		this.buscar();
	}

	onDeletePost(): void {
		super.onDeletePost();
		this.messengerService.broadcastMessage('refresh_list');
	}

	getGroupTitle(group: any): string {
		let dato = this.datos.find(r => r.Grupo.equals(group.value));
		if (dato) {
			return `${Utils.dateToLocale(dato.FechaMovimiento, false)} - ${dato.MovTropaTipoNombre} (nro. trans. ${dato.NroTransaccion == 0 ? '-' : dato.NroTransaccion})`;
		}

		return '';
	}

	ultimoEnGrupo(item: MovTropa): boolean {
		let itemsEnGrupo: MovTropa[] = this.datos.filter(r => r.Grupo.equals(item.Grupo));
		if (itemsEnGrupo.some(r => r.EsUltimoMovimientoTropa)) {
			let ultimoItem = itemsEnGrupo.pop();
			if (ultimoItem)
				return ultimoItem.MovTropaID.equals(item.MovTropaID);
			else
				return true;
		}
		return false;
	}

	public showIfHasObs(dataItem: any, index: number): boolean { 
		if (!this.obsTracker) { this.obsTracker = []; }

		// el primero se muestra, la segunda no. Segunda = index + 1 e igual observacion
		if (this.obsTracker.find(r => r.Index === (index + 1) && r.Obs === dataItem.Observaciones)) {			
			return false;
		}

		if (dataItem.Observaciones) {
			this.obsTracker.push(new ObsRow(index, dataItem.Observaciones));
			return true;
		}

		return false;
	}

	public expandedRow(): boolean {
		return true;
	}

	public mostrarPeso(item: MovTropa): boolean {
		if (item.MovTropaTipoRef == MovTropaTipo.Compra || item.MovTropaTipoRef == MovTropaTipo.OperacionInternaPositiva || item.MovTropaTipoRef == MovTropaTipo.Pesada) {
			return true;
		}

		return false;
	}
}

export class ObsRow {
	Index: number;
	Obs: string;

	constructor(index: number, obs: string) {
		this.Index = index;
		this.Obs = obs;
	}
}
