// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthNoticeService } from '../../../../core/auth';
import { AutenticacionService } from '../../../../../services/autenticacion.service';
import { MyColInputComponent } from '../../../partials';
import Utils from '../../../../../utils';
import { ApiResponse } from '../../../../../interfaces/api-response.interface';

@Component({
	selector: 'kt-forgot-password',
	templateUrl: './forgot-password.component.html',
	encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('Email', { static: true }) EmailInput: MyColInputComponent;

	loading = false;
	errors: any = [];

	private unsubscribe: Subject<any>;

	constructor(
		private auth: AutenticacionService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private fb: UntypedFormBuilder,
		private cdr: ChangeDetectorRef
	) {
		this.unsubscribe = new Subject();
	}

    ngAfterViewInit(): void { }

	ngOnInit() { }

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	submit() {

		this.authNoticeService.setNotice('', 'info');
		let valid: boolean = true;

		const authData = {
			Email: this.EmailInput.getValue(),
		};

		if (!this.EmailInput.isValid()) {
			this.EmailInput.showError(true);
			valid = false;
		}

		if (!valid) {
			return;
		}

		this.loading = true;

		this.auth
			.requestPassword(authData.Email)
			.pipe(
				tap(response => {
					if (Utils.checkAPIResponse(response, undefined, '')) {
						this.authNoticeService.setNotice(this.translate.instant('AUTH.FORGOT.STEP'), 'success');
					} else {
						let apiResponse = (response as unknown as ApiResponse);
						this.authNoticeService.setNotice(apiResponse.StatusDescription, 'danger');
					}
					this.router.navigateByUrl('/auth/login');
				}),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			).subscribe();
	}
}
