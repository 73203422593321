<div *ngIf="!loading && chartCount == 0" class="mb-3" role="alert">
	<kt-message-info [text]="'No existen corrales con los filtros seleccionados'"></kt-message-info>
</div>

<div *ngIf="!loading && chartData">
	<div *ngFor="let corral of chartData | keyvalue : originalOrder" class="col-md-12 grafico-separador">
		<kendo-chart>
			<kendo-chart-tooltip format="{0}"></kendo-chart-tooltip>
			<kendo-chart-legend position="custom" [offsetX]="40" [offsetY]="50"></kendo-chart-legend>
			<kendo-chart-title font="12pt" align="left" [text]="titulo(corral.value)"></kendo-chart-title>

			<kendo-chart-category-axis>
				<kendo-chart-category-axis-item [notes]="notas(corral)" [maxDivisions]="1" [categories]="etiquetas(corral.value)"
												[title]="{ text: 'Días' }"
												[labels]="{ rotation: 'auto' }">
				</kendo-chart-category-axis-item>
			</kendo-chart-category-axis>

			<kendo-chart-series>
				<kendo-chart-series-item name="Descargado (Kg)" [color]="'#34A853'" [style]="'smooth'" type="line" [data]="descargado(corral.value)">
					<kendo-chart-series-item-tooltip>
						<ng-template let-value="value" let-category="category" let-notes="notes">
							<div [innerHTML]="tooltip(category, value, corral.value)"></div>
						</ng-template>
					</kendo-chart-series-item-tooltip>
				</kendo-chart-series-item>
				<kendo-chart-series-item name="Remanente (Kg)" [color]="'#4285F4'" [style]="'smooth'" type="line" [data]="remanente(corral.value)">
					<kendo-chart-series-item-tooltip>
						<ng-template let-value="value">
							<strong>Remanente:</strong> {{ value | parseNumberKg }}
						</ng-template>
					</kendo-chart-series-item-tooltip>
				</kendo-chart-series-item>
			</kendo-chart-series>
		</kendo-chart>
	</div>
</div>
