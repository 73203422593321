<div class="col">
	<div class="form-group col-sigef-width" [formGroup]="AutoCompleteForm">
		<label *ngIf="labelVisible" class="pb-1">{{labelName}}</label>
		<kendo-combobox formControlName="ItemRef"
						[data]="Data"
						[placeholder]="Placeholder"
						[textField]="'Value'"
						[valueField]="'Key'"
						class="w-100 control-sigef-height"
						[allowCustom]="false"
						(valueChange)="onValueChange($event)"
						#autoInput>
		</kendo-combobox>
		<kt-inline-server-validation [input]="autoInput" [formGroup]="AutoCompleteForm" [field]="'ItemRef'"></kt-inline-server-validation>
	</div>
</div>


