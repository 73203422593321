
<div class="col w-100">
	<div class="row row-full-height">
		<div class="kt-portlet kt-portlet--mobile">
			<div class="kt-portlet__head">
				<div class="kt-portlet__head-label">
					<h3 class="kt-portlet__head-title">
						<span *ngIf="modo == 'aplicacion'">
							<span *ngIf="!edicion && !aplicar">Selección de hacienda <small>Filtre las tropas a las cuales se aplicó el producto sanitario</small></span>
							<span *ngIf="edicion || aplicar">Selección de hacienda <small>Tropas a las cuales se aplicó el producto sanitario</small></span>
						</span>
						<span *ngIf="modo == 'plan'">
							<span *ngIf="!edicion && !aplicar">Selección de hacienda <small>Filtre las tropas a las cuales se le aplicará el producto sanitario</small></span>
							<span *ngIf="edicion || aplicar">Selección de hacienda <small>Tropas a las cuales se le aplicará el producto sanitario</small></span>
						</span>
					</h3>
				</div>
			</div>
			<div class="kt-portlet__body">

				<div [ngClass]="{'d-none': !planAplicado}">
					<div class="alert alert-success mb-3" role="alert">
						<div class="alert-text">
							Este Plan Sanitario ya fue aplicado.
						</div>
					</div>
				</div>

				<div [ngClass]="{'d-none': planAplicado}">
					<kt-corral-filtro [ngClass]="{'d-none': edicion || aplicar}" selectFirstInput [ocultarDieta]="true" [ocultarCorral]="modo === 'plan'" [ocultarCategoria]="true" [ocultarPropietario]="false" [tabindex]="0" [parent]="component" [buscando]="buscando"></kt-corral-filtro>

					<div [ngClass]="{'d-none': gridData.length == 0}" class="row row-sigef p-3 pt-2">
						<p [ngClass]="{'d-none': edicion || aplicar}" class="mb-0 sigef--grid--note">
							Seleccione las tropas y categorías a las cuales se aplicaron los productos sanitarios y la cantidad de cabezas
						</p>
						<kendo-grid #grid
									[kendoGridBinding]="gridData"
									[sortable]="true"
									[navigable]="true"
									[selectedKeys]="selectedKeys"
									[selectable]="{checkboxOnly: true}"
									class="tropa"
									[kendoGridSelectBy]="'RowID'"
									(selectedKeysChange)="selectedKeysChange($event)"
									[loading]="cargando">

							<ng-template kendoGridNoRecordsTemplate>
								No hay hacienda para los filtros seleccionados
							</ng-template>

							<!-- Checkbox Col  -->
							<kendo-grid-checkbox-column *ngIf="!edicion && !aplicar" showSelectAll="true" [width]="10"></kendo-grid-checkbox-column>

							<!-- Tropa Col -->
							<kendo-grid-column class="no-padding" title="Tropa" width="100">
								<ng-template kendoGridCellTemplate let-dataItem>
									<div class="ribbon-content" style="height:100%; width:100%; position:relative;" [style.borderColor]="dataItem.ColorPrincipal">
										<div class="center-div">
											<span>{{dataItem.CodigoTropa | parseCodigoTropa}} - {{dataItem.TropaNombre}} </span>
										</div>
									</div>
								</ng-template>
							</kendo-grid-column>

							<!-- Propietario Col -->
							<kendo-grid-column title="Propietario" width="50">
								<ng-template kendoGridCellTemplate let-dataItem>
									<span>{{dataItem.Propietario}}</span>
								</ng-template>
							</kendo-grid-column>

							<!-- Categoría Col -->
							<kendo-grid-column [filterable]="false" title="Categoría" width="50">
								<ng-template kendoGridCellTemplate let-dataItem>
									<span>{{dataItem.CategoriaGanado}}</span>
								</ng-template>
							</kendo-grid-column>

							<!-- Corral Col -->
							<kendo-grid-column *ngIf="modo === 'aplicacion' || aplicar" title="Corral" width="50">
								<ng-template kendoGridCellTemplate let-dataItem>
									<span>
										<span><strong>{{dataItem.CorralNumero | parseCorralNumero}}</strong> ({{dataItem.CorralNombre}})</span>
									</span>
								</ng-template>
							</kendo-grid-column>

							<!-- Cabezas Col -->
							<kendo-grid-column title="Cabezas {{ aplicar ? 'Plan.' : '' }}" width="50">
								<ng-template kendoGridCellTemplate let-dataItem>
									<span>{{dataItem.Cabezas | parseNumber : true}} cab.</span>
								</ng-template>
								<ng-template class="text-center" kendoGridFooterTemplate let-column="column">
									<div align="center">
										<span class="footer-item">Total</span>
									</div>
								</ng-template>
							</kendo-grid-column>

							<!-- Cabezas Aplicadas Col -->
							<kendo-grid-column title="Cabezas Aplicadas" width="50">
								<ng-template kendoGridCellTemplate let-dataItem>
									<kendo-numerictextbox [disabled]="puedeEditarCabezas(dataItem.RowID)" class="w-100" (valueChange)="onCabezaAplicadaChange($event, dataItem.RowID, input)" [value]="dataItem.CabezasAplicadas" [decimals]="0" [format]="'n0'" #input [spinners]="false"></kendo-numerictextbox>
								</ng-template>
								<ng-template class="text-center" kendoGridFooterTemplate let-column="column">
									<div align="center">
										<span *ngIf="aplicar" class="footer-item">{{ totalCabezasAplicadas | parseNumber : true }} cab de {{ totalCabezasAplicables }}</span>
										<span *ngIf="!aplicar" class="footer-item">{{ totalCabezasAplicadas | parseNumber : true }} cab.</span>
									</div>
								</ng-template>
							</kendo-grid-column>
						</kendo-grid>

						<div class="row pl-3 pt-3" [ngClass]="{'d-none': guardadoPosible}">
							<button class="btn btn-sigef-secondary mr-1" (click)="cancelar()">Volver</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<div class="col w-100" [ngClass]="{'d-none': !guardadoPosible}">
	<div class="row row-full-height">
		<div class="kt-portlet kt-portlet--mobile">
			<div class="kt-portlet__head">
				<div class="kt-portlet__head-label">
					<h3 class="kt-portlet__head-title">
						Aplicación de producto <small>Selección de producto y las cantidades aplicadas</small>
					</h3>
				</div>
			</div>
			<div class="kt-portlet__body">
				<div>
					<ng-template #Aplicaciones></ng-template>

					<div class="pt-2" [ngClass]="{'d-none': this.modo === 'plan' && !aplicar}">
						<my-row-expand class="row row-sigef pt-3" [tabindex]="tabindex + 5" [title]="Observaciones" [hint]="'Seleccione esta opción para incluir una aclaración adicional'" [labelName]="'Observaciones'" [expanded]="adicionalesVisible" (onExpand)="adicionalesVisible=$event"></my-row-expand>
						<div class="row" [ngClass]="{'d-none': !adicionalesVisible}">
							<my-col-textarea #Observaciones [tabindex]="tabindex + 6" [labelName]="'Observaciones'" [rows]="2" [labelVisible]="false" class="w-100"></my-col-textarea>
						</div>
					</div> 

					<div class="row p-3">
						<button class="btn btn-sigef mr-1" *ngIf="modo == 'aplicacion'" [disabled]="!guardadoPosible" (click)="guardarAplicacion()">Guardar</button>
						<button class="btn btn-sigef mr-1" *ngIf="modo == 'plan' && !aplicar" [disabled]="!guardadoPosible" (click)="guardarPlan()">Guardar</button>
						<button class="btn btn-sigef mr-1" *ngIf="modo == 'plan' && aplicar" [disabled]="!guardadoPosible" (click)="aplicarPlan()">Aplicar</button>

						<button class="btn btn-sigef-secondary mr-1" (click)="cancelar()">Cancelar</button>

						<button class="btn btn-outline-danger position-absolute" *ngIf="edicion && !aplicar" style="right: 2rem" (click)="eliminar()"><i class="fas fa-exclamation-triangle"></i>&nbsp;Eliminar</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<my-message-dialog></my-message-dialog>
