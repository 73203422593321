<kendo-grid [data]="movimientos"
			(edit)="showABM($event)"
			(remove)="removeHandler($event)"
			(add)="showABM($event)"
			[loading]="buscando">
	<ng-template kendoGridToolbarTemplate>
		<button tabindex="{{tabindex}}" kendoGridAddCommand class="add-btn btn-sigef">
			Agregar Producto
		</button>
	</ng-template>
	<ng-template kendoGridNoRecordsTemplate>
		No hay productos para la compra
	</ng-template>

	<kendo-grid-column field="ProductoSanitarioNombre" title="Producto" width="100"></kendo-grid-column>

	<kendo-grid-column field="ProductoSanitarioFormatoNombre" title="Formato" width="100"></kendo-grid-column>

	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" field="Cantidad" title="Cantidad" width="80">
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span class="footer-item">{{ dataItem.CantidadFormatoCompra | parseNumber : true }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" field="CantidadUM" title="Cantidad UM" width="80">
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span class="footer-item">{{ dataItem.CantidadUM | parseNumber : true }} {{ dataItem.UnidadMedidaAbrev }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" title="Precio" width="80">
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span class="footer-item">{{ dataItem.PrecioTotal | parseNumberMoney }}</span>
			</div>
		</ng-template>
		<ng-template class="text-center" kendoGridFooterTemplate let-column="column">
			<div align="center">
				<span class="footer-item">{{ totalPrecio | parseNumberMoney }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column field="Observaciones" title="Observaciones" width="100"></kendo-grid-column>

	<kendo-grid-command-column class="k-grid-ignore-click" title="Acciones" width="120">
		<ng-template kendoGridCellTemplate>
			<button kendoGridEditCommand class="btn btn-sm"><i title="Editar" class="{{'Editar' | parseIconAccion }}"></i></button>
			<button kendoGridRemoveCommand class="btn btn-sm k-grid-ignore-click"><i title="Eliminar" class="{{'Eliminar' | parseIconAccion }}"></i></button>
		</ng-template>
	</kendo-grid-command-column>
</kendo-grid>

<my-message-dialog></my-message-dialog>
