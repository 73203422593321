<div>
	<div class="row">
		<my-col-dropdownlist [labelName]="'Mixero'" [required]="true"></my-col-dropdownlist>
	</div>
	<div class="row">
		<my-col-datetimepicker [labelName]="'Fecha'"></my-col-datetimepicker>
		<my-col-timepicker [labelName]="'Hora'"></my-col-timepicker>
	</div>
	<div class="row">
		<my-col-input-search [labelName]="'Corral'" [required]="true" [parent]="component"></my-col-input-search>
		<div class="col">
			<label class="d-block pb-1">&nbsp;</label>
			<button class="btn btn-sigef-secondary" (click)="remanenteVisible = !remanenteVisible">{{(remanenteVisible) ? "Borrar" : "Cargar"}} remanente</button>
		</div>
	</div>
	<div class="row">
		<my-col-input [ngClass]="{'d-none': !remanenteVisible}" #KgRemanente [extraClass]="'kgCargaInput'" [mask]="'#,# Kg'" [decimals]="0" [labelName]="'Remanente en comedero'" [parent]="component"></my-col-input>
	</div>
	<div class="row">
		<my-col-input #KgCargado [extraClass]="'kgCargaInput'" [mask]="'#,# Kg'" [decimals]="0" [labelName]="'Descargado'" [required]="true" [parent]="component"></my-col-input>
	</div>
	<my-row-expand class="row" [title]="Observaciones" [hint]="'Seleccione esta opción para incluir una aclaración adicional'" [labelName]="'Observaciones'" [expanded]="adicionalesVisible" (onExpand)="adicionalesVisible=$event"></my-row-expand>
	<div class="row" [ngClass]="{'d-none': !adicionalesVisible}">
		<my-col-textarea [labelName]="'Observaciones'" [rows]="2" [labelVisible]="false" class="w-100"></my-col-textarea>
	</div>
	<div class="kt-divider kt-divider-sigef"><span></span></div>
	<div class="row-sigef p-0">
		<div class="col-auto">
			<my-group-save-buttons [saving]="saving" [allowDelete]="false" [allowCancel]="false"
								   [saveButtonText]="continuarButtonText()"
								   (onSave)="onSave($event, true);"></my-group-save-buttons>

		</div>
		<div class="col">
			<button class="btn btn-sigef" [disabled]="saving" (click)="onFinalizarDescargas($event)">{{finalizarCustomButtonText()}}</button>
		</div>
	</div>
</div>
<my-message-dialog></my-message-dialog>
