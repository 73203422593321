import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { CommonControl } from '../common-controls.interface';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { NameValueListExtend } from '../../../../../interfaces/nvl.interface';
import { CorralService } from '../../../../../services/corral.service';

@Component({
  selector: 'my-col-multiselect-corral',
	templateUrl: './my-col-multiselect-corral.component.html',
	styleUrls: ['./my-col-multiselect-corral.component.scss'],
	providers: [CorralService]
})
export class MyColMultiselectCorralComponent extends CommonControl implements OnInit {
	@Input() placeholder: string = 'Seleccione los corrales';
	@Input() hint: string = '';
	@Input() soloConCabezas: boolean = false;

	public valueField: any = 'Key';
	public textField: any = 'Descripcion';
	public data: NameValueListExtend[] = [];
	public defaultItem: NameValueListExtend = { Value: '0', Key: 0, Descripcion: 'Todos los corrales' };

	@ViewChild("controlInput", { static: true }) public ControlInput: any;

	constructor(private cd: ChangeDetectorRef) {
		super();
		this.Form = new UntypedFormGroup({
			'Input': new UntypedFormControl(undefined),
			'Description': new UntypedFormControl(undefined)
		});
	}

	ngOnInit() {
		
	}

	initializeControlByCorral(corralService: CorralService) {
		corralService.getList().subscribe(data => {
			let lst: NameValueListExtend[] = [];
			for (const corral of data) {
				if (corral.Cabezas <= 0 && this.soloConCabezas) {
					continue;
				}

				let itm: NameValueListExtend = {
					Key: corral.CorralID,
					Value: corral.CorralNumero.toString(),
					Descripcion: `${corral.CorralNumero} - ${corral.CorralNombre}`
				}
				lst.push(itm);
			}
			this.pushDefaultItem();
			this.data = lst;
			this.cd.detectChanges();
		});
	}

	private pushDefaultItem(): void {
		this.setValue([this.defaultItem]);
	}

	public filterSettings: DropDownFilterSettings = {
		caseSensitive: false,
		operator: 'contains'
	};


	setValue(value: any): void {
		this.Form.patchValue({ Input: value });
	}

	getValue(): any {
		let values = this.Form.value['Input'];

		if (!values) {
			return [];
		}

		return values;
	}

	public reset(): void {
		this.ControlInput.reset();
		setTimeout(() => {
			this.pushDefaultItem();
		}, 0);
	}

	public valueChange(e: any): void {
		setTimeout(() => {
			let values = this.getValue();

			if (values.length == 0) {
				this.pushDefaultItem();
				return;
			}
			else {
				let quitarOpcionTodos = this.getValue().some(r => r.Key == 0);
				if (quitarOpcionTodos) {
					values.splice(0, 1);
					this.setValue(values);
				}
			}
		}, 0);
	}

	public removeTag(e: any): void {
		let values = this.getValue();

		if (!values || (values.length == 1 && values[0].Key == 0)) {
			// si está la opcion 'todos' no hacer nada.
			if (e) { e.preventDefault(); }
			return;
		}

		if (values.length == 1 && values[0].Key != 0) {
			// hay un solo item, y no es el de "todos"
			this.pushDefaultItem();
			if (e) { e.preventDefault(); }
			return;
		}
	}

	public getTagTemplate(dataItem: any): string {
		if (dataItem.Value == 0) {
			return this.defaultItem.Descripcion;
		}

		return dataItem.Value.toString();
	}

	public isItemSelected(itemText: string): boolean {
		return this.getValue().some(item => item.Descripcion === itemText);
	}

	public itemDisabled(itemArgs: { dataItem: any, index: number }): boolean {
		return (itemArgs.dataItem.value == 0);
	}
}
