import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { CorralDescargaGraficoInterface } from '../../../../../interfaces/corral-descarga-grafico.interface';
import Utils from '../../../../../utils';
import { ParseNumberKgPipe } from '../../../../../pipes/parse-number-kg.pipe';
import { KeyValue } from '@angular/common';

@Component({
    selector: 'kt-descarga-chart',
    templateUrl: './descarga-chart.component.html',
    styleUrls: ['./descarga-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DescargaChartComponent implements OnInit {
	public chartCount: number = 0;
	public loading: boolean = false;

    public chartData: Dictionary<CorralDescargaGraficoInterface[]> = {};

    public categoryNotes: any = {
        data: [],
        icon: {
            visible: false
        },
        line: {
            length: 260,
            color: 'rgba(0, 0, 0, 0)',
            dashType: 'solid'
        },
        label: {
            position: 'outside',
			font: '17px "Poppins", sans-serif',
            content: (args: any) => args.dataItem.label.text
        }
    };

    private notasCollection: any = {};

	@Input() public set descargas(data: Dictionary<CorralDescargaGraficoInterface[]>) {
		this.loading = true;
        this.cd.reattach();

        Object.keys(data).forEach(key => {
            let notes = [];

            let o = (data[key] as unknown as CorralDescargaGraficoInterface[]);
            let i: number = 0;

            for (const t of o) {

				if (t.Observaciones && t.Observaciones.replace(/(?:\\[rn])+/g, '').length > 0) {
                    this.agregaIcono(notes, i, '👁️‍🗨️'); // comentarios/observaciones
                }

                i++;
            }

            this.categoryNotes.data = notes;
            this.notasCollection[key] = { ...this.categoryNotes };
        });

		this.chartData = data;
		this.chartCount = Object.keys(this.chartData).length;
		this.loading = false;
        this.cd.detectChanges();
        this.cd.detach();
	}

	// Preserve original property order
	originalOrder = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => {
		return 0;
	}

    private agregaIcono(notes: any[], index: number, icono: any): any[] {
        let item = notes.find(item => item.value === index);

        if (!item) {
            notes.push({
                value: index,
                fa: icono,
                label: {
                    text: icono
                }
            });
        } else {
            if (String(item.label.text).indexOf(String.fromCharCode(icono)) === -1) {
                item.label.text += ' ' + icono;
            }
        }

        return notes;
    }

    constructor(private cd: ChangeDetectorRef) { }

    ngOnInit() {

    }

    notas(corral: any): void {
        return this.notasCollection[corral.key];
    }

    tooltip(category, value, points): string {
		let item = points.find(p => Utils.dateToDDMM(new Date(p.Fecha)) === category);
        let t: string;

		t = `<strong>Descargado:</strong> ${new ParseNumberKgPipe().transform(value)}`;

        if (item) {

            if (item.Observaciones) {
                t += '<br />';
                t += `<strong>Observaciones:</strong> <br /> ${String(item.Observaciones).replace(/(?:\\[rn])+/g, '<br />')}`;
            }
        }

        return t;
	}

	faltante(items: any[]): number[] {
		return items.map(f => f.Faltante);
	}

    descargado(items: any[]): number[] {
        return items.map(f => f.Descargado);
    }

    remanente(items: any[]): number[] {
        return items.map(f => f.Remanente);
    }

    meta(items: any[]): number[] {
        return items.map(f => f.Propuesto);
    }

	etiquetas(items: any[]): string[] {
		return items.map(f => Utils.dateToDDMM(new Date(f.Fecha)));
    }

    titulo(items: any[]): string {
        if (!items || items.length === 0) {
            return 'N/A';
		}

		return `Corral ${items[0].CorralNumero} (${items[0].CorralNombre}) - ${items[0].DietaNombre}`;
    }
}
