import { DTOInterface } from "../interfaces/dto.interface";

export class ProductoSanitarioFormato implements DTOInterface { 
	ProductoSanitarioFormatoID: number;
	ProductoSanitarioFormatoNombre: string;
    ProductoSanitarioRef: number;
	FactorConversionUM: number;
	Activo: boolean;

	constructor() {
		this.clear();
	}

	clear(): void {
		this.ProductoSanitarioFormatoID = -1;
		this.ProductoSanitarioFormatoNombre = '';
		this.ProductoSanitarioRef = 0;
		this.FactorConversionUM = 0;
		this.Activo = true;
	}
}
