// USA
export const locale = {
	lang: 'en',
	data: {
		CLOSE: 'Close',
		TRANSLATOR: {
			SELECT: 'Select your language',
		},
		MENU: {
			NEW: 'new',
			ACTIONS: 'Actions',
			CREATE_POST: 'Create New Post',
			PAGES: 'Pages',
			FEATURES: 'Features',
			APPS: 'Apps',
			DASHBOARD: 'Dashboard',
			CUSTOMERS: 'Customers',
			FOODS: 'Foods',
			ADMIN: 'Administration',
			SUPPLIERS: 'Suppliers',
			CORRALS: 'Corrals',
			MIXERS: 'Mixers',
            DIETS: 'Diets',
			SHIFTS: 'Working shifts',
			PSCATEGORIES: 'PS Categories',
			USERS: 'Users',
			CCATEGORIES: 'Cattle Categories',
			SANITARYPRODUCTS: 'Sanitary Products',
			MEASUREUNITS: 'Measure Units',
			TRACETROOPS: {
				SELF: 'Troops Tracing',
				DAILY: 'Daily',
				ACTIVE: 'Active Troops',
				SALES: 'Sales',
				LASTMOV: 'Lastest Movements',
				ARCHIVE: 'Troops\' Archive',
				REPORT: 'Report',
			},
			DESTINATIONTROOP: 'Troop Destination',
			SOURCETROOP: 'Troop Source',
			MOVEMENTSTROOPS: {
				SELF: 'Troops Movements',
				IN: 'Income',
				OUT: 'Egress',
				SWAP: 'Corral Change',
				WEIGHING: 'Weighing Control',
                FIELD_CONFINEMENT: 'Field Confinement',
                INTERNAL_OPERATION: 'Internal Operation',
				COUNT: 'Count',
				CHANGE: 'Category Change'
			},
			FEEDING: {
				SELF: 'Feeding',
				STOCK: 'Stock',
				PURCHASES: 'Movements',
				LOADS: 'Loads',
                UNLOADS: 'Unloads',
				LOADS_UNLOADS: 'Loads & Unloads',
                ROUND_PLANNING: 'Round Planning',
                LOADS_DETAILS: 'Details',
				UNLOADS_DETAILS: 'Details',
				LASTMOV: 'Lastest Movements',
                NEW_MOV: 'New Movement'
            },
            SANITY: {
                SELF: 'Sanity',
                STOCK: 'Stock',
                PURCHASES: 'Movements',
				PLANS: 'Plans',
				NEW_MOV: 'New Movement',
				RECORDS: 'Records'
            },
            REPORTS: 'Reports',
            WEATHER: 'Weather'
		},
		AUTH: {
			GENERAL: {
				OR: 'Or',
				SUBMIT_BUTTON: 'Submit',
				NO_ACCOUNT: 'Don\'t have an account?',
				SIGNUP_BUTTON: 'Sign Up',
				FORGOT_BUTTON: 'Forgot Password',
				BACK_BUTTON: 'Back',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
			},
			LOGIN: {
				TITLE: 'Login Account',
				BUTTON: 'Sign In',
				OR: 'You can also login with the following services'
			},
			FORGOT: {
				TITLE: 'Forgotten Password?',
				DESC: 'Enter your email to reset your password',
				SUCCESS: 'Your account has been successfully reset.'
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered.'
			},
			INPUT: {
				EMAIL: 'Email or Username',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirm Password',
				USERNAME: 'Username',
				COMPANY: 'Company code'
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login detail is incorrect',
				REQUIRED_FIELD: 'Required field',
				MIN_LENGTH_FIELD: 'Minimum field length:',
				MAX_LENGTH_FIELD: 'Maximum field length:',
				INVALID_FIELD: 'Field is not valid',
				EXPIRED: 'Your session has expired. You either had login from another device or it timed out.',
				INVALID_MASK: 'Fill the require mask'
			}
		},
		CRUD: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'in all fields',
				NO_RECORDS: 'No records to show. Add a new record using the button above.',
				REQUIRED_FIELD: 'This field is required.'
			},
			CUSTOMERS: {
				SELF: 'Customers',
				LIST: 'Customers list',
				NEW: 'New Customer',
			},
			FOODS: {
				SELF: 'Foods',
				LIST: 'Foods list',
				NEW: 'New Food',
			},
			SUPPLIERS: {
				SELF: 'Suppliers',
				LIST: 'Suppliers list',
				NEW: 'New Supplier',
			},
			CORRALS: {
				SELF: 'Corrals',
				LIST: 'Corrals list',
				NEW: 'New Corral',
            },
            MIXERS: {
                SELF: 'Mixers',
                LIST: 'Mixers list',
                NEW: 'New Mixer',
            },
            SHIFTS: {
                SELF: 'Working shifts',
                LIST: 'Working Shifts list',
                NEW: 'New Working Shift',
            },
			DIETS: {
				SELF: 'Diets',
				LIST: 'Diets list',
				NEW: 'New Diet',
			},
			MEASUREUNITS: {
				SELF: 'Measure Units',
				LIST: 'Measure units list',
				NEW: 'New Measure Unit'
			},
			PSCATEGORIES: {
				SELF: 'PS Categories',
				LIST: 'PS Categories list',
				NEW: 'New PS Category'
			},
			USERS: {
				SELF: 'Users',
				LIST: 'Users list',
				NEW: 'New User'
			},
			CCATEGORIES: {
				SELF: 'Cattle Categories',
				LIST: 'Cattle Categories list',
				NEW: 'New Cattle Category'
			},
			SANITARYPRODUCTS: {
				SELF: 'Sanitary Products',
				LIST: 'Sanitary Products list',
				NEW: 'New Sanitary Product'
			},
			TROOPSOURCES: {
				SELF: 'Troop Sources',
				LIST: 'Troop Sources list',
				NEW: 'New Troop Source'
			},
			TROOPDESTINATIONS: {
				SELF: 'Troop Destinations',
				LIST: 'Troop Destinations list',
				NEW: 'New Troop Destination'
            },
            ROUNDPLANNING: {
                SELF: 'Rounds Planning',
                LIST: 'Rounds Planning List',
                NEW: 'New Round Planning'
            },
            WEATHER: {
                SELF: 'Weather Report',
                LIST: 'Weather Reports',
                NEW: 'New WeatherReport'
			},
			WAREHOUSES: {
				SELF: 'Warehouse',
				LIST: 'Warehouses',
				NEW: 'New Warehouse'
			}
		}
	}
};
