import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Venta } from '../../../../../models/Venta';
import { MyColInputComponent } from '../../common_controls/col-input/my-col-input.component';
import { ValidationControlInterface } from '../../common_controls/common-controls.interface';

@Component({
  selector: 'kt-venta-rentabilidad',
  templateUrl: './venta-rentabilidad.component.html',
  styleUrls: ['./venta-rentabilidad.component.scss']
})
export class VentaRentabilidadComponent implements OnInit {

	@ViewChild('GastosConsumo', { static: true }) GastosConsumo: MyColInputComponent;
	@ViewChild('GastosSanidad', { static: true }) GastosSanidad: MyColInputComponent;

	lstControls: ValidationControlInterface[];

	@Input() tabindex: number = 97500;

	public _venta: Venta;
	@Input() set venta(model: Venta) {
		this._venta = model;
		if (this._venta)
			this.resetModel();
	}

	constructor() { }

	ngOnInit() {
		this.lstControls = [this.GastosConsumo, this.GastosSanidad]; 
	}

	resetModel() {
		this.GastosConsumo.setValue(this._venta.GastosConsumo);
		this.GastosSanidad.setValue(this._venta.GastosSanidad);		
	}

	setModelValues(model: Venta) {
		model.GastosConsumo = this.GastosConsumo.getValue() || 0;
		model.GastosSanidad = this.GastosSanidad.getValue() || 0;	
	}
}
