import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';  
import { PlanSanitario } from '../models/plan-sanitario';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { PlanSanitarioDashboard } from '../models/plan-sanitario-dashboard';

@Injectable()
export class PlanSanitarioService extends GenericoService<PlanSanitario> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'plansanitario');
	}  

	getList(grupo: Guid = undefined, agrupado: boolean = true, propietario: number = 0, tropa: Guid = undefined, categoria: number = 0): Observable<PlanSanitario[]> {
		return this._httpClient.post<PlanSanitario[]>(environment.apiUrl + `/${this._endpoint}/planes`, { Grupo: grupo, AgrupadoCorral: agrupado, ClienteRef: propietario, TropaRef: tropa, CategoriaGanadoRef: categoria} ,{ headers: this._postHeaders });
	}

	getPlanSanitarioDashboardList(dias: number): Observable<PlanSanitarioDashboard[]> {
		return this._httpClient.get<PlanSanitarioDashboard[]>(`${environment.apiUrl}/${this._endpoint}/planSanitarioDashboard/${dias}`, { headers: this._getHeaders });
	}
}
