import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { OffCanvasInterface } from '../../../../../interfaces/off-canvas.interface';
import { IOpenInDialog, IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { MessengerService } from '../../../../../services/messenger.service';
import { MovTropaService } from '../../../../../services/mov-tropa.service';
import { MovTropa } from '../../../../../models/mov-tropa';
import { Router } from '@angular/router';
import { MovTropaTipo } from '../../../../../utils';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { getRoute, Routing } from '../../../themes/sigef/routing';

@Component({
	selector: 'kt-tropa-movimiento-readonly',
	templateUrl: './tropa-movimiento-readonly.component.html',
	styleUrls: ['./tropa-movimiento-readonly.component.scss'],
	providers: [MovTropaService]
})
export class TropaMovimientoReadonlyComponent implements AfterViewInit, OnInit, IOpenInDialog, OffCanvasInterface {
	public movTropa: MovTropa = undefined;

	constructor(private messengerService: MessengerService, private router: Router, private movTropaService: MovTropaService, private cd: ChangeDetectorRef, private quickPanelService: QuickPanelService) {

	}

	openInDialog(model: any, extras: any): void {
		//
	}

	saveResultDialog(formParent: IParentOpenInDialog): boolean {
		return true;
	}

	editResultDialog(formParent: IParentOpenInDialog): boolean {
		return true;
	}

	setOffCanvasData(data: any, extras: any): void {
		setTimeout(() => {
			this.quickPanelService.setQuickPanelLoadingStatus(true);

			this.movTropaService.getById(data.MovTropaID).subscribe(
				data => {
					this.movTropa = data;
				},
				error => {

				},
				() => {
					this.finalizarBusqueda();
				}
			);
		}, 0);
	}

	finalizarBusqueda(): void {
		this.quickPanelService.setQuickPanelLoadingStatus(false);
		this.cd.detectChanges();
	}

	clickCancel(e: any): void {
		this.quickPanelService.hideQuickPanel();
	}

	navegarReporteVenta(): void {
		localStorage.setItem('ventaReporte', this.movTropa.VentaRef.toString());
		this.router.navigate([getRoute(Routing.HACIENDA_VENTAS_LISTADO)]); 
	}

	navegarReporteTropa(): void {
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([getRoute(Routing.TROPAS_REPORTES, true, true), this.movTropa.TropaRef.toString()])); 
	}

	ngAfterViewInit(): void {

	}

	ngOnInit() {

	}

	esPesada(): boolean {
		return (this.movTropa.MovTropaTipoRef == MovTropaTipo.Pesada);
	} 

	esParicion(): boolean {
		return (this.movTropa.MovTropaTipoRef == MovTropaTipo.Paricion);
	}

	esMuerte(): boolean {
		return (this.movTropa.MovTropaTipoRef == MovTropaTipo.Muerte);
	}

	esCambioCorral(): boolean {
		return (this.movTropa.MovTropaTipoRef == MovTropaTipo.CambioCorralNegativo || this.movTropa.MovTropaTipoRef == MovTropaTipo.CambioCorralPositivo);
	}

	esCambioCategoria(): boolean {
		return (this.movTropa.MovTropaTipoRef == MovTropaTipo.CambioCategoriaNegativo || this.movTropa.MovTropaTipoRef == MovTropaTipo.CambioCategoriaPositivo);
	}

	esCompra(): boolean {
		return (this.movTropa.MovTropaTipoRef == MovTropaTipo.Compra);
	}

	esOperacionInterna(): boolean {
		return (this.movTropa.MovTropaTipoRef == MovTropaTipo.OperacionInternaNegativa || this.movTropa.MovTropaTipoRef == MovTropaTipo.OperacionInternaPositiva);
	}

	esAjuste(): boolean {
		return (this.movTropa.MovTropaTipoRef == MovTropaTipo.AjusteNegativo || this.movTropa.MovTropaTipoRef == MovTropaTipo.AjustePositivo);
	}

	esVenta(): boolean {
		return (this.movTropa.MovTropaTipoRef == MovTropaTipo.Venta);
	}
}
