<div>
	<div class="row-sigef">
		<my-col-datetimepicker selectFirstInput [focusOnInit]="true" [tabindex]="tabindex" [labelName]="'Fecha'" [required]="true"></my-col-datetimepicker>
	</div>

	<div class="row-sigef">
		<my-col-input [tabindex]="tabindex + 1" #Transportista [type]="2" [labelName]="'Transportista'" [required]="true"></my-col-input>
		<my-col-input [tabindex]="tabindex + 2" #GastoFlete [labelName]="'Gasto Flete'" [mask]="'c2'"></my-col-input>
	</div>

	<div class="row-sigef dte-grid">
		<kt-venta-transporte-dte-list [tabindex]="tabindex + 3" [ventaTransporte]="model"></kt-venta-transporte-dte-list>
	</div>
</div>

<my-message-dialog></my-message-dialog>

