<div *ngIf="!horizontal" class="col" [ngClass]="extraClass">
	<div class="form-group" [ngClass]="divClass" [formGroup]="Form">
		<label class="pb-1" [ngClass]="labelClass" *ngIf="labelVisible" [innerHTML]="labelName"></label>
		<div *ngIf="type === 1">
			<kendo-numerictextbox (ngModelChange)="keyDown($event)" #controlInput [disabled]="disabled" [readonly]="readonly" tabindex="{{tabindex}}"
								  class="form-control input-sigef {{extraClass}}" formControlName='InputNumber'
								  [format]="mask" [spinners]="false" [factor]="factor" [required]="required" [decimals]="decimals"
								  [value]="value"></kendo-numerictextbox>
			<kt-inline-server-validation [ngClass]="{'d-none': !showValidation}" [input]="controlInput" [formGroup]="Form" [field]="'InputNumber'"></kt-inline-server-validation>
		</div>
		<div *ngIf="type === 2">
			<input *ngIf="type === 2" ktSelectOnClick #controlStringInput class="form-control input-sigef {{extraClass}}"
				   formControlName='InputString'
				   [readonly]="readonly"
				   [disabled]="disabled"
				   tabindex="{{tabindex}}"
				   (ngModelChange)="keyDown($event)"
				   placeholder={{placeholder}}
				   ngbPopover={{tooltipMessage}}
				   popoverTitle={{tooltipTitle}}
				   [required]="required"
				   container="body"
				   type="{{inputType}}"
				   triggers="mouseenter:mouseleave">
			<kt-inline-server-validation [ngClass]="{'d-none': !showValidation}" [input]="controlStringInput" [formGroup]="Form" [field]="'InputString'"></kt-inline-server-validation>
		</div>
		<span *ngIf="hint" class="form-text text-muted">{{hint}}</span>
	</div>
</div>

<div *ngIf="horizontal" class="form-group row-sigef col" [formGroup]="Form">
	<label class="col-form-label" style="width: 230px">{{labelName}}</label>
	<div *ngIf="type === 1">
		<kendo-numerictextbox style="width: 70px" (ngModelChange)="keyDown($event)" #controlInput [disabled]="disabled" [readonly]="readonly" tabindex="{{tabindex}}"
							  class="form-control input-sigef {{extraClass}}" formControlName='InputNumber'
							  [format]="mask" [spinners]="false" [factor]="factor" [required]="required"
							  [value]="value"></kendo-numerictextbox>
		<kt-inline-server-validation [ngClass]="{'d-none': !showValidation}" [input]="controlInput" [formGroup]="Form" [field]="'InputNumber'"></kt-inline-server-validation>
	</div>
	<div *ngIf="type === 2">
		<input *ngIf="type === 2" ktSelectOnClick #controlStringInput type="text" class="form-control input-sigef {{extraClass}}"
			   formControlName='InputString'
			   tabindex="{{tabindex}}"
			   [readonly]="readonly"
			   [disabled]="disabled"
			   (ngModelChange)="keyDown($event)"
			   placeholder={{placeholder}}
			   ngbPopover={{tooltipMessage}}
			   popoverTitle={{tooltipTitle}}
			   [required]="required"
			   container="body"
			   triggers="mouseenter:mouseleave"
			   style="width: 40px">
		<kt-inline-server-validation [ngClass]="{'d-none': !showValidation}" [input]="controlStringInput" [formGroup]="Form" [field]="'InputString'"></kt-inline-server-validation>
	</div>
	<span *ngIf="hint" class="form-text text-muted">{{hint}}</span>
</div>
