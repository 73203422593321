import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MyMessageDialogComponent } from '../../common_controls';
import { ProductoSanitarioFormatoComponent } from '../producto-sanitario-formato/producto-sanitario-formato.component';
import { IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { EditableFormList } from '../../common_controls/common-controls.interface';
import { ProductoSanitario } from '../../../../../models/producto-sanitario';
import { ProductoSanitarioFormato } from '../../../../../models/producto-sanitario-formato';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { Subscription } from 'rxjs';
import { MessengerService } from '../../../../../services/messenger.service';
import { BroadcastMessage } from '../../../../../interfaces/message.interface';
import { UnidadMedida } from '../../../../../models/unidad-medida';

@Component({
	selector: 'kt-producto-sanitario-formato-list',
	templateUrl: './producto-sanitario-formato-list.component.html',
	styleUrls: ['./producto-sanitario-formato-list.component.scss']
})
export class ProductoSanitarioFormatoListComponent extends EditableFormList<ProductoSanitarioFormato> implements OnInit, IParentOpenInDialog {

	@ViewChild(MyMessageDialogComponent, { static: true }) public MessageDialog: MyMessageDialogComponent;

	private _productoSanitario: ProductoSanitario;
	private sub: Subscription;
	private unidadMedida: UnidadMedida;
	public deletedItem: ProductoSanitarioFormato;


	@Input()
	set productoSanitario(item: ProductoSanitario) {
		this._productoSanitario = item;
		this.datos = (this._productoSanitario) ? this._productoSanitario.Formatos : [];
		this.buscando = false;
		this.cd.detectChanges();
	}
	get productoSanitario(): ProductoSanitario { return this._productoSanitario; }

	constructor(cd: ChangeDetectorRef,
		private quickPanelService: QuickPanelService,
		private messengerService: MessengerService,
	) { super(cd, undefined) }

	ngOnInit() {
		this.sub = this.messengerService.messageBroadcasted$.subscribe((message: BroadcastMessage) => {

			if (message.Target === 'ProductoSanitarioListComponent') {

				if (message.Sender === 'ProductoSanitarioComponent' && message.Reason === 'UnidadMedida') {
					this.unidadMedida = message.Payload.unidadMedida;
					this.cd.detectChanges();
				}

				if (message.Sender === 'ProductoSanitarioFormatoComponent' && message.Reason === 'ProductoSanitarioFormato')
					this.saveResultDialogModel(message.Payload);
			}
		});
	}

	getKeyModel(obj: ProductoSanitarioFormato): any { return obj.ProductoSanitarioFormatoID; }

	public removeHandler({ dataItem }) { this.confirmDelete(dataItem); }

	confirmDelete(item: any) {
		this.deletedItem = item;
		this.MessageDialog.showDialog('Por favor confirme', 'Se eliminará al recurso en forma definitiva. ¿Está seguro que desea continuar?',
			['SI', 'NO'], 'DeleteModel', this);
	}

	onConfirmResultMessageDialog(id: string, ok: boolean): void {
		if (ok && id === 'DeleteModel' && this.deletedItem) {
			this.datos.splice(this.datos.indexOf(this.deletedItem), 1);
		}
	}

	isNewModel: boolean = false;

	public showABM({ dataItem }) {
		this.isNewModel = dataItem == undefined;
		let title = `${dataItem == undefined ? 'Agregar' : 'Editar'} Formato de Venta`;
		this.quickPanelService.createOffCanvasComponent(title, ProductoSanitarioFormatoComponent, dataItem, { unidadMedidaNombre: this.unidadMedida ? this.unidadMedida.UnidadMedidaNombre : '' });
	}

	public saveResultDialogModel(model: ProductoSanitarioFormato) {
		if (this.isNewModel) {
			this._productoSanitario.Formatos.push(model);
		}
		else {
			let item = this._productoSanitario.Formatos.find(x => x.ProductoSanitarioFormatoID == model.ProductoSanitarioFormatoID);
			if (item) { Object.assign(item, model); }
		}
		this.cd.detectChanges();
	}

	descripcionColFactorDeConversion(): string {
		if (this.unidadMedida)
			return `Equivalente en ${this.unidadMedida.UnidadMedidaNombre}`;
		else
			return 'Equivalente';
	};
}

