 
<div class="kt-portlet" data-ktportlet="true" id="kt_portlet_tools_1">
    <div class="kt-portlet__head pointer" (click)="filtroExpandido = !filtroExpandido">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{movimientoNombre}} - Selección Categoría de Hacienda
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-group">
                <a href="javascript:;" data-ktportlet-tool="toggle" class="btn btn-sm btn-icon btn-clean btn-icon-md"><i class="la la-angle-{{filtroExpandido ? 'up' : 'down'}}"></i></a>
            </div>
        </div>
    </div>
    <div class="kt-portlet__body" [ngClass]="{'d-none': !filtroExpandido}">
        <div class="kt-portlet__content">
            <div class="row">
                <my-col-input-search selectFirstInput [tabindex]="600" #Corral [soloConCabezas]="isNewModel" [ngClass]="{'d-none': esVenta}" [labelName]="'Corral'" [required]="true" [parent]="component"></my-col-input-search>
                <my-col-dropdownlist #Tropa [tabindex]="601" [ngClass]="{'d-none': !isNewModel || (esVenta)}" [labelName]="'Tropa'" [required]="true" [key]="'Tropa'" [parent]="component" [keyType]="2"></my-col-dropdownlist>
                <my-col-dropdownlist #CategoriaGanado [tabindex]="602" [ngClass]="{'d-none': !isNewModel || (esVenta)}" [labelName]="'Categoría de Hacienda'" [required]="true" [key]="'Categoria'" [parent]="component" [keyType]="2"></my-col-dropdownlist>

                <my-col-button [tabindex]="603" [textoNormal]="'Seleccionar'" (click)="seleccionarHacienda()"></my-col-button>
            </div>
        </div>
    </div>
</div>

<div class="" [ngClass]="{'d-none': !datosExpandido}">
    <div class="col w-100">
        <div class="row row-full-height">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">
                            Datos del Movimiento
                        </h3>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <div class="row">
                        <div>
                            <div class="row-sigef" [ngClass]="{'d-none': esVenta}">
                                <my-col-datetimepicker [tabindex]="604" #FechaMovimientoDatetimePicker [labelName]="'Fecha Movimiento'"></my-col-datetimepicker>
                                <my-col-timepicker [tabindex]="605" [labelName]="'Hora'"></my-col-timepicker>
                            </div>

                            <div class="row-sigef" [ngClass]="{'d-none': esVenta}">
                                <my-col-dropdownlist [tabindex]="606" #CategoriaGanadoCambio [required]="true" [ngClass]="{'d-none': !esCambio && !esParicion}" [labelName]="esParicion ? 'Categoría Parida' : 'Nueva Categoría de Hacienda'" [key]="'NuevaCategoria'" [parent]="component" [keyType]="1"></my-col-dropdownlist>

                                <my-col-input [tabindex]="607" style="width: 13rem;" #Cantidad [readonly]="(esVenta)" [labelName]="labelCabezas" [required]="true" [allowCero]="false"></my-col-input>
                                <div [ngClass]="{'d-none': !isNewModel || (!esRecuento && !esCambio && !esPesada && !esMuerte)}" class="col-auto form-group">
                                    <label class="d-block">Cabezas (Actual)</label>
                                    <label class="font-weight-bold d-block mt-3">{{CantidadActual}}</label>
                                </div>
                            </div>

                            <div class="row-sigef" [ngClass]="{'d-none': !esParicion}">
                                <my-col-input-search [tabindex]="608" #CorralDestino [soloConCabezas]="false" [labelName]="'Corral Destino'" [required]="true" [parent]="component"></my-col-input-search>
                                <my-col-input [tabindex]="609" [mask]="'0.00 kg'" #AumentoDiario [labelName]="'Aumento Diario Estimado'" [allowCero]="false" [required]="true"></my-col-input>
                            </div>

                            <div class="pt-4" [ngClass]="{'d-none': (esRecuento || esVenta || tipoMovimiento == 7)}">
                                <my-row-expand [tabindex]="610" #PesadaExpand [disabled]="!esCambio && !esParicion" [expanded]="pesadaVisible" [title]="''" [hint]="''" [labelName]="esCambio ? 'Generar movimiento de pesada' : 'Peso'" (onExpand)="pesadaVisible=$event"></my-row-expand>

                                <div class="w-100" [ngClass]="{'d-none': !pesadaVisible}">
                                    <kt-peso-hacienda [tabindex]="611" [required]="pesadaVisible" [pesar]="true" [title]="'Nuevo Movimiento de Pesada'" [tropaCategoriaGanado]="tropaCategoriaGanadoRef" [corral]="getCorral()" [cantidad]="cantidad" class="w-100"></kt-peso-hacienda>
                                </div>
                            </div>

                            <div class="pt-4" [ngClass]="{'d-none': (esCambio || esRecuento || tipoMovimiento == 7 || esPesada)}">
                                <my-row-expand [tabindex]="620" #PrecioExpand [disabled]="!esParicion" [expanded]="precioVisible" [title]="esParicion ? 'Ingresar precio estimado' : 'Precio'" [hint]="''" [labelName]="precioTitle" (onExpand)="precioVisible=$event"></my-row-expand>

                                <div class="w-100" [ngClass]="{'d-none': !precioVisible}">
                                    <kt-precio-compra-venta [tabindex]="621" [required]="precioVisible" [cantidad]="cantidad" class="w-100"></kt-precio-compra-venta>
                                </div>
                            </div>

                            <div class="pt-4">
                                <my-row-expand [tabindex]="630" #ObservacionesExpand class="pt-2" [hint]="'Seleccione esta opción para incluir una aclaración adicional'" [title]="Observaciones" [labelName]="'Observaciones'" [expanded]="adicionalesVisible" (onExpand)="adicionalesVisible=$event"></my-row-expand>

                                <div class="" [ngClass]="{'d-none': !adicionalesVisible}">
                                    <my-col-textarea [tabindex]="631" [labelName]="'Observaciones'" [rows]="2" [labelVisible]="false" class="w-100"></my-col-textarea>
                                </div>
                            </div>

                            <div class="kt-separator kkt-separator--fit"></div>

                            <div class="ml-3 mr-3 pl-3 row">
                                <my-group-save-buttons [tabindex]="700" [ngClass]="{'d-none': !isNewModel || openedInDialog}" class="w-100" [saving]="saving" [targetName]="movimientoNombre"
                                                       (onSave)="onSave($event)" (onCancel)="onCancel($event)"></my-group-save-buttons>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 

<my-message-dialog></my-message-dialog>
