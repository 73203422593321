import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { EditableForm } from '../../common_controls/common-controls.interface';
import { IOpenInDialog, IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { MyColInputComponent } from '../../common_controls';
import { VentaTransporteDTE } from '../../../../../models/venta-transporte-dte';
import { LayoutUtilsService } from '../../../../core/_base/crud';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessengerService } from '../../../../../services/messenger.service';
import { QuickPanelService } from '../../../../../services/quick-panel.service';

@Component({
  selector: 'kt-venta-transporte-dte',
  templateUrl: './venta-transporte-dte.component.html',
  styleUrls: ['./venta-transporte-dte.component.scss']
})
export class VentaTransporteDteComponent extends EditableForm<VentaTransporteDTE> implements OnInit, IOpenInDialog {

	@ViewChild('DTENro', { static: true }) DTENro: MyColInputComponent;
	@ViewChild('DTECantidad', { static: true }) DTECantidad: MyColInputComponent;
	@Input() tabindex: number = 2000;

	constructor(cd: ChangeDetectorRef,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		quickPanelService: QuickPanelService) {
		super(cd, undefined, snackBar, messengerService, quickPanelService);
	}

	ngOnInit() {
		this.lstControls = [this.DTENro, this.DTECantidad];
	}

	getNewModel(): VentaTransporteDTE { return new VentaTransporteDTE(); };

	setModelControlValues() {
		if (this.model) {
			this.DTENro.setValue(this.model.DTENro);
			this.DTECantidad.setValue(this.model.DTECantidad);
		}

		this.DTENro.focus();
	}

	openInDialog(model: VentaTransporteDTE, extras: any) {
		this.openedInDialog = true;
		this.setModel(model);
	}

	editResultDialog(formParent: IParentOpenInDialog): boolean {
		return false;
	}

	saveResultDialog(formParent: IParentOpenInDialog): boolean {
		if (formParent) {
			if (!this.runValidaciones()) {
				return false;
			}
			this.model.DTENro = this.DTENro.getValue();
			this.model.DTECantidad = this.DTECantidad.getValue();
			formParent.saveResultDialogModel(this.model);
		}
		return true;
	}
}
