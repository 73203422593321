import { Component, ViewChild, ChangeDetectorRef, Input, AfterViewInit } from '@angular/core';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { HashDirective } from '../../../../../directives/hash.directive';
import Utils from '../../../../../utils';

@Component({
	selector: 'my-message-dialog',
	templateUrl: './my-message-dialog.component.html',
	styleUrls: ['./my-message-dialog.component.scss']
})

export class MyMessageDialogComponent {
	id: string
	@ViewChild(HashDirective, { static: true }) public HashDirective;

	public showSaveButton: boolean = true;
	public showCancelButton: boolean = true;
	public showEditButton: boolean = false;
	public showPrintButton: boolean = false;

	public saveButtonText: string = 'Guardar';
	public cancelButtonText: string = 'Cancelar';
	public editButtonText: string = 'Editar';
	public printButtonText: string = 'Imprimir';

	constructor(private dialogService: DialogService, private cd: ChangeDetectorRef) {
		this.id = "#" + Math.random() * 111;
	}

	ngOnInit() {

	}

	public showDialog(title: string, textMessage: string, actions: string[], id: string = "", parent: IParentConfirmResultMessage = undefined): void {
		const dialog: DialogRef = this.dialogService.open({
			appendTo: this.HashDirective.vcRef,
			title: title,
			content: textMessage,
			actions: this.getActions(actions)
		});
		dialog.result.subscribe((result) => {
			if (parent && id != '') {
				let ok = false;
				if (result instanceof MyDialogResult) {
					ok = result.primary;
				}
				parent.onConfirmResultMessageDialog(id, ok);
			}
		});
	}

	public showExceptionDialog(baseMessage: string, error: any): void {
		let msg = this.getErrorMessage(error);
		this.showDialog('Error', `${baseMessage} - Detalle técnico: ${Utils.checkDataPortalErrorMessage(msg)}`, ['OK']);
	}

	public showAtencionDialog(message: string, id: string = "", parent: IParentConfirmResultMessage = undefined): void {
		this.showDialog('Atención', message, ['OK'], id, parent);
	}

	public showFormularioInvalido(): void {
		this.showDialog('Atención', 'Corrija los errores y vuelva a intentarlo', ['OK']);
	}

	getErrorMessage(e: any) {
		if (!e)
			return 'Error Inesperado';
		if (e.StatusDescription)
			return e.StatusDescription;
		if (e.error && e.error.StatusDescription)
			return e.error.StatusDescription;
		if (!(typeof e === 'string' || e instanceof String)) {
			if (e.error.Errors && Object.keys(e.error.Errors).length > 0) {
				let validationErrors = e.error.Errors;

				if (e instanceof HttpErrorResponse) {
					if (e.status === 422) {
						let errorList: string[] = [];

						Object.keys(validationErrors).forEach(prop => {
							errorList.push(validationErrors[prop]);
						});
						return errorList.join('<br />');
					}
				}
			}
			if (e.error && e.error.message)
				return e.error.message;
			if (e.error)
				return 'Error inesperado';
		}
		return e;
	}

	getActions(list: string[]): any[] {
		let aux = [];
		let setPrimary = true;
		for (const a of list) {
			aux.push(new MyDialogResult(a, setPrimary));
			setPrimary = false;
		}
		return aux
	}

	private dialog: DialogRef;
	private component: IOpenInDialog;
	private formParent: IParentOpenInDialog;
	@ViewChild('dialogSaveCancelActions', { static: true }) public SaveCancelActions;
	showComponentDialog(title: string, component: any, formParent: IParentOpenInDialog, model: any, extras: any): void {
		if (extras) {
			if (extras.SaveButtonText) { this.saveButtonText = extras.SaveButtonText; }
			if (extras.CancelButtonText) { this.cancelButtonText = extras.CancelButtonText; }
			if (extras.EditButtonText) { this.editButtonText = extras.EditButtonText; }
			if (extras.PrintButtonText) { this.printButtonText = extras.PrintButtonText; }

			if (extras.HideCancelButton) { this.showCancelButton = false; }
			if (extras.HideSaveButton) { this.showSaveButton = false; }
			if (extras.ShowEditButton) { this.showEditButton = true; }
			if (extras.ShowPrintButton) { this.showPrintButton = true; }
		}

		this.cd.detectChanges();
		this.dialog = this.dialogService.open({
			appendTo: this.HashDirective.vcRef,
			title: title,
			content: component as any,
			actions: this.SaveCancelActions,
			width: (extras.Width || undefined),
			height: (extras.Height || undefined)
		});
		this.cd.detectChanges();
		this.component = this.dialog.content.instance;
		this.formParent = formParent;
		this.component.openInDialog(model, extras);
	}

	public onSave(e: Event): void {
		e.preventDefault();
		if (this.component && this.component.saveResultDialog) {
			if (this.component.saveResultDialog(this.formParent))
				this.dialog.close();
		}
	}
	public onCancel(e: Event): void {
		e.preventDefault();
		this.dialog.close();
	}

	public onEdit(e: Event): void {
		e.preventDefault();
		if (this.component && this.component.editResultDialog) {
			if (this.component.editResultDialog(this.formParent))
				this.dialog.close();
		}
	}

	public onPrint(e: Event): void {
		e.preventDefault();
		window.print();
	}
}

export interface IOpenInDialog {
	openInDialog(model: any, extras: any): void
	saveResultDialog(parent: IParentOpenInDialog): boolean
	editResultDialog(parent: IParentOpenInDialog): boolean
}

export interface IParentOpenInDialog {
	saveResultDialogModel(model: any): void
}

export interface IParentConfirmResultMessage {
	onConfirmResultMessageDialog(id: string, ok: boolean): void
}

export class MyDialogResult {
	text: string;
	primary: boolean;

	constructor(text: string, primary: boolean) {
		this.text = text;
		this.primary = primary;
	}
}
