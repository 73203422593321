import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';
import { Proveedor } from '../models/proveedor';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { NameValueList } from '../interfaces/nvl.interface';

@Injectable()
export class ProveedorService extends GenericoService<Proveedor> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'proveedor');
	}

	getLookupByTipo(tipoProveedorRef: number): Observable<NameValueList[]> {
		return this._httpClient.post<NameValueList[]>(environment.apiUrl + `/${this._endpoint}/tipo`, { ProveedorTipoRef: tipoProveedorRef }, { headers: this._postHeaders });
	}
}
