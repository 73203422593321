import { Component, ChangeDetectorRef, OnInit, Input, OnDestroy } from '@angular/core';
import { DraggableItem, DraggableCorral, InputCambioCorralModel } from '../../../../../models/draggable-corral';
import { CorralService } from '../../../../../services/corral.service';
import { MovTropaService } from '../../../../../services/mov-tropa.service';
import { HelpersDraggableCorral } from '../../../partials/corral/helpers-corrales';
import { TropaEncierreComponent } from '../../../partials/tropa';
import { InputTropaEncierreModel } from '../../../partials/tropa/tropa-encierre/tropa-encierre.component';
import { CorralEncierre } from '../../../../../models/corral-encierre';
import { MovTropaCorral } from '../../../../../models/mov-tropa-corral';
import Utils from '../../../../../utils';
import { IParentOpenInDialog } from '../../../partials/common_controls/message-dialog/my-message-dialog.component';
import { Router } from '@angular/router'; 
import { ContainerService } from '../../../../../services/container.service';
import { MessengerService } from '../../../../../services/messenger.service';
import { Subscription } from 'rxjs';
import { BroadcastMessage } from '../../../../../interfaces/message.interface';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { getRoute, Routing } from '../../../themes/sigef/routing';

@Component({
	selector: 'kt-hacienda-operacion-interna',
	templateUrl: './hacienda-operacion-interna.component.html',
	styleUrls: ['./hacienda-operacion-interna.component.scss'],
	providers: [CorralService, MovTropaService]
})
export class HaciendaOperacionInternaComponent extends HelpersDraggableCorral implements OnInit, OnDestroy, IParentOpenInDialog {   
	private cantidad: number = 0;
	private sub: Subscription = undefined;

	@Input() modo: string = 'encierre';

	ngOnInit() {
		this.sub = this.getMessengerInstance().messageBroadcasted$.subscribe(
			(data: BroadcastMessage) => {
				if (data) { 
					if (data.Reason === 'TropaCambioCorralModel' || data.Reason === 'TropaEncierreModel') {
						this.saveResultDialogModel(data.Payload);
					}
				}
			}
		);

		this.initilizeForm(undefined);
	}

	public operacionInterna: DraggableCorral = new DraggableCorral();

	constructor(private containerService: ContainerService,
		private router: Router,
		movTropaService: MovTropaService,
		corralService: CorralService,
		messengerService: MessengerService,
		cd: ChangeDetectorRef,
		quickPanelService: QuickPanelService) {
		super(corralService, movTropaService, cd, messengerService, quickPanelService);
	} 

	ngOnDestroy() {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}

	soloCorralesConTropas(): boolean { return true; }

	checkLoadList(): boolean {
		if (this.operacionInterna.items.length > 0) {
			this.MessageDialog.showAtencionDialog('No se puede refrescar la busqueda ya que tiene una operación en progreso.')
			return false;
		}
		return true;
	}

	public deletedTropa: DraggableItem = undefined;

	quitar(item: DraggableItem): void {
		this.deletedTropa = item;
		this.MessageDialog.showDialog('Por favor confirme', 'La tropa volverá al corral de origen. ¿Continuar?',
			['SI', 'NO'], 'DeleteTropa', this);
	}

	onConfirmResultMessageDialog(id: string, ok: boolean) {
		if (ok && id === 'DeleteTropa' && this.deletedTropa) {
			let j = -1;
			let ctrl = this;
			this.operacionInterna.items.forEach((item, index) => {
				if (item.tropa.CategoriaGanadoRef === item.tropa.CategoriaGanadoRef && ctrl.guidEquals(item.tropa.TropaRef, item.tropa.TropaRef)) {
					j = index;
				}
			});
			if (j >= 0) {
				let item = this.deletedTropa;
				item.tropa.DesbastePorc = item.DesbastePorcAnt;
				item.tropa.PesoCabezaKg = item.PesoCabezaKgAnt;
				let duplicado: boolean = false;
				item.containerOrigen.data.forEach(function (value, index) {
					//Tener en cuenta que con el casteo se pierde el Objeto Guid de la Tropa
					let obj = (value as unknown as DraggableItem);

					if (obj.tropa.CategoriaGanadoRef === item.tropa.CategoriaGanadoRef && ctrl.guidEquals(obj.tropa.TropaRef, item.tropa.TropaRef)) {
						obj.tropa.Cantidad += item.tropa.Cantidad;
						duplicado = true;
					}
				});
				if (!duplicado)
					item.containerOrigen.data.push({ tropa: item.tropa });
				this.operacionInterna.items.splice(j, 1);
			}
			this.cd.detectChanges();
		}
	}

	generar(): void { 
		if (this.modo === 'encierre') {
			this.generarEncierre();
		}
		if (this.modo === 'venta') {
			this.generarVenta();
		}
	}

	calcularCantidadTotal(): void {
		this.cantidad = 0;
		for (const i of this.operacionInterna.items) {
			this.cantidad = this.cantidad + i.tropa.Cantidad;
		}
	}

	generarEncierre(): void {
		this.calcularCantidadTotal();
		this.getQuickPanelInstance().createOffCanvasComponent('Operación Interna', TropaEncierreComponent, { Cantidad: this.cantidad });
	}

	generarVenta(): void {
		localStorage.setItem('ventaHacienda', (this.containerService.store(this.operacionInterna)).toString());
		this.router.navigate([getRoute(Routing.HACIENDA_VENTAS)]);
	}

	public saveResultDialogModel(model: any) {
		if (model instanceof InputCambioCorralModel)
			this.manejarCambioCorral(model); 
		else if (model instanceof InputTropaEncierreModel)
			this.manejarEncierreCampo(model);
	}

	saveMovimiento(model: InputCambioCorralModel, cantidadIngresada: number) {
		this.realizarMovimientoTropaCorral(model, cantidadIngresada, false);
	}

	manejarEncierreCampo(model: InputTropaEncierreModel) {
		let corralEncierre = new CorralEncierre();
		corralEncierre.Tropa.FechaIngreso = model.Fecha;
		corralEncierre.Tropa.CodigoTropa = -1;
		corralEncierre.Tropa.TropaNombre = model.TropaNombre || 'Nueva Tropa Sin Nombre';
		corralEncierre.Tropa.ClienteRef = model.ClienteRef;
		
		// Agregar Ganado y crear los movimientos de Salida/Entrada.
		for (const i of this.operacionInterna.items) {
			let movimiento: MovTropaCorral = new MovTropaCorral();
			movimiento.FechaCreacion = corralEncierre.Tropa.FechaCreacion;
			movimiento.FechaMovimiento = model.Fecha;
			if (model.CorralRef) { // Se seleccionamos un corral durante el finalizar encierre va ese, sino se respeta el que estaba
				movimiento.CorralDestinoRef = model.CorralRef;
			} else {
				movimiento.CorralDestinoRef = i.tropa.CorralID;
			}
			movimiento.Cantidad = i.tropa.Cantidad;
			movimiento.CorralOrigenRef = i.tropa.CorralID;
			movimiento.DesbastePorc = i.tropa.DesbastePorc;
			movimiento.PesoCabezaKg = i.tropa.PesoCabezaKg;
			movimiento.TropaCategoriaGanadoOrigenRef = this.getGuid(i.tropa.TropaCategoriaGanadoRef); //en el servidor se debe modificar el destino con el de la nueva tropa
			movimiento.CategoriaGanadoRef = i.tropa.CategoriaGanadoRef;
			movimiento.Observaciones = i.cambioModel.Observaciones;
			movimiento.PrecioVenta = i.cambioModel.PrecioVenta;
			movimiento.GenerarPesada = i.cambioModel.GenerarPesada;
			movimiento.AumentoDiarioEstKg = model.AumentoDiario;

			corralEncierre.Movimientos.push(movimiento); // esto crea en la base los 2 movimientos, negativo y positivo de la operacion
		}

		this.movTropaService.createEncierre(corralEncierre).subscribe(
			data => {
				if (Utils.checkAPIResponse(data, this.MessageDialog, 'No se pudo generar la operación.')) {
					this.operacionInterna = new DraggableCorral();
					this.buscar();
				}
				this.resetControls();
			},
			error => {
				this.MessageDialog.showExceptionDialog('No se pudo generar la operación.', error);
				this.resetControls();
			},
			() => {
				this.cd.detectChanges();
			});
	}
}
