import { DTOInterface } from "../interfaces/dto.interface";

export class Cliente implements DTOInterface { 
	ClienteID: number;
	ClienteNombre: string;
	CostoHoteleria: number;
	NumeroCuit: string;
	Telefono: string;
	Direccion: string;
	Email: string;
	Activo: boolean;

	constructor() {
		this.clear();
	}

	clear(): void {
		this.ClienteID = -1;
		this.ClienteNombre = '';
		this.CostoHoteleria = 0;
		this.NumeroCuit = '';
		this.Telefono = '';
		this.Direccion = '';
		this.Email = '';
		this.Activo = true;
	}
}
