import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AutenticacionService } from '../services/autenticacion.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private _authenticationService: AutenticacionService, private _router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(catchError(err => {
			if (err.status === 403) {
				localStorage.setItem("interceptor_error", "No tienes autorización para realizar esta acción");
			}

			if (err.status === 401) {
				this._authenticationService.logout();
				this._router.navigate(['/auth/login'], { queryParams: { error: 401 } });
			}

			if (err.status === 500) {
				this._router.navigate(['/error/500'], { queryParams: { error: 500 } });
			}

			if (err.status !== 422) {
				const error = (err.error ? err.error.message : err.statusText) || err.statusText;
				return throwError(error);
			} // si es 422 viene UnprocessableEntityObjectResult, es necesario el objeto con las reglas de csla rotas.

			return throwError(err);
		}));
    }
}
