import { DTOInterface } from "../interfaces/dto.interface";

export class Comprador implements DTOInterface { 
	CompradorID: number;
	CompradorNombre: string;

	constructor() {
		this.clear();
	}

	clear(): void {
		this.CompradorID = -1;
		this.CompradorNombre = '';
	}
}
