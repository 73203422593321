import { Component, ChangeDetectorRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HelpersTropaMovimiento } from '../helpers-tropa';
import { CorralService } from '../../../../../services/corral.service';
import { TropaService } from '../../../../../services/tropa.service';
import { MovTropaService } from '../../../../../services/mov-tropa.service';
import { CategoriaGanadoService } from '../../../../../services/categoria-ganado.service';
import Utils, { MovTropaTipo } from '../../../../../utils';
import { MovTropaCambio } from '../../../../../models/mov-tropa-cambio';
import { MovTropaParicion } from '../../../../../models/mov-tropa-paricion';
import { LayoutUtilsService } from '../../../../core/_base/crud';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { MessengerService } from '../../../../../services/messenger.service';
import { MyColDateTimePickerComponent } from '../../common_controls';
import { QuickPanelService } from '../../../../../services/quick-panel.service';

@Component({
	selector: 'kt-tropa-movimiento',
	templateUrl: './tropa-movimiento.component.html',
	styleUrls: ['./tropa-movimiento.component.scss'],
	providers: [CorralService, TropaService, MovTropaService, CategoriaGanadoService]
})
export class TropaMovimientoComponent extends HelpersTropaMovimiento implements OnInit, OnDestroy {
	private _movTropaService: MovTropaService;
	private _messengerService: MessengerService;
	private _sub: Subscription = undefined;

	@ViewChild('FechaMovimientoDatetimePicker', { static: true }) fechaMovimientoPicker: MyColDateTimePickerComponent;

	getCorral(): number { return (this.Corral.getItem()) ? this.Corral.getItem().Key : undefined; }

	constructor(corralService: CorralService,
		tropaService: TropaService,
		movTropaService: MovTropaService,
		categoriaGanadoService: CategoriaGanadoService,
		cd: ChangeDetectorRef,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		quickPanelService: QuickPanelService) {
		super(corralService, tropaService, movTropaService, categoriaGanadoService, cd, snackBar, messengerService, quickPanelService);

		this._messengerService = messengerService;
		this._movTropaService = movTropaService;
	}

	ngOnDestroy() {
		if (this._sub) {
			this._sub.unsubscribe();
		}
	}

	ngOnInit() {
		super.ngOnInit();

		this._messengerService.messageBroadcasted$.subscribe(
			data => {
				if (data === 'MOV_SAVED') {
					super.resetValidaciones();
					this.filtroExpandido = true;
					this.datosExpandido = false;
				}
			});
	}

	/* El boton de seleccionar debe verificar que se haya seleccionado los pasos anteriores */
	seleccionarHacienda(): void {
		this.filtroExpandido = true;
		this.datosExpandido = false;

		if (this.esVenta) {
			this.datosExpandido = true;
			return;
		}

		if (this.isNewModel && this.tropaSeleccionada === undefined) {
			this.MessageDialog.showAtencionDialog("Seleccione la tropa para hacer el movimiento.");
			this.datosExpandido = false;
			return;
		}

		if (this.haciendaSeleccionada === undefined) {
			this.MessageDialog.showAtencionDialog("Seleccione la categoría de hacienda para hacer el movimiento.");
			this.datosExpandido = false;
			return;
		}

		this.filtroExpandido = false;
		this.datosExpandido = true;

		setTimeout(() => {
			this.fechaMovimientoPicker.focus();
		}, 0);

		if (this.tipoMovimiento === MovTropaTipo.Paricion) {
			// marcar como defecto el corral de origen como destino
			this.CorralDestino.setValue(this.getCorral());
		} else {
			// para que traiga el peso estimado
			this.PesoHacienda.tropaCategoriaGanado = this.haciendaSeleccionada.TropaCategoriaGanadoID;
		}
	}

	onCancel($event: any) {
		this.resetValidaciones();
		this.resetModel();
		this.Corral.setValue(undefined);
		this.tropaSeleccionada = undefined;
		this.haciendaSeleccionada = undefined;
		this.cargarCategoriaGanado([]);
		this.cargarTropas([]);
		this.Cantidad.setValue(0);
		this.filtroExpandido = true;
		this.datosExpandido = false;
	}

	runCustomValidaciones() {
		if (Utils.getDateWithTime(this.Fecha.getFecha(), this.Hora.getHora()) > new Date()) {
			this.MessageDialog.showAtencionDialog('La fecha y hora del movimiento no puede ser mayor a la actual.');
			return false;
		}

		if (this.tipoMovimiento === MovTropaTipo.CambioCategoriaPositivo || this.tipoMovimiento === MovTropaTipo.CambioCategoriaNegativo) {
			if (this.haciendaSeleccionada.CategoriaGanadoNombre === this.CategoriaGanadoCambio.getItem().Value) {
				this.MessageDialog.showAtencionDialog('La categoría de origen y destino no puede ser la misma.');
				return false;
			}
		}

		return true;
	}

	onSave(e, repeat: boolean = false): void {
		if (this.tipoMovimiento === MovTropaTipo.Paricion || this.tipoMovimiento === MovTropaTipo.CambioCategoriaPositivo || this.tipoMovimiento === MovTropaTipo.CambioCategoriaNegativo) {
			// generar un movimiento distinto
			super.setLstControls();

			this.saving = true;
			if (!super.runValidaciones()) {
				this.saving = false;
				return;
			}

			switch (this.tipoMovimiento) {
				case MovTropaTipo.Paricion:
					this.saveParicion();
					break;
				case MovTropaTipo.CambioCategoriaNegativo:
				case MovTropaTipo.CambioCategoriaPositivo:
					this.saveCambioCategoria();
					break;
			}
		}
		else {
			super.onSave(e, repeat);
		}
	}

	saveParicion() {
		let paricion = new MovTropaParicion();

		paricion.TropaCategoriaGanadoRef = this.haciendaSeleccionada.TropaCategoriaGanadoID;
		paricion.TropaRef = this.Tropa.getValue();
		paricion.CategoriaGanadoRef = this.CategoriaGanadoCambio.getValue();
		if (this.precioVisible) {
			paricion.PrecioVenta = this.PrecioHacienda.getPorCabezaValue();
		} else {
			paricion.PrecioVenta = 0;
		}
		paricion.AumentoDiarioEstKg = this.AumentoDiario.getValue();
		paricion.CorralRef = this.getCorral();
		paricion.FechaMovimiento = Utils.getDateWithTime(this.Fecha.getFecha(), this.Hora.getHora());
		paricion.Cantidad = this.Cantidad.getValue();
		paricion.Observaciones = this.Observaciones.getText();
		paricion.CorralDestinoRef = this.CorralDestino.getItem().Key;
		if (this.pesadaVisible) {
			paricion.PesoCabezaKg = this.PesoHacienda.getPorCabezaValue();
			paricion.DesbastePorc = this.PesoHacienda.getDesbasteValue();
		} else {
			paricion.PesoCabezaKg = 0;
			paricion.DesbastePorc = 0;
		}

		this._movTropaService.createParicion(paricion).subscribe(
			data => {
				if (Utils.checkAPIResponse(data, this.MessageDialog, "Error al generar una nueva parición")) {
					super.informSave(true);
				} else {
					super.informSave(false);
				}
			}, error => {
				this.MessageDialog.showExceptionDialog("Error al generar una nueva parición", error);
				super.informSave(false);
			},
			() => {
				this.saving = false;
				this.onCancel(undefined);
			}
		);
	}

	saveCambioCategoria() {
		let cambioCategoria = new MovTropaCambio();

		cambioCategoria.Cantidad = this.Cantidad.getValue();
		cambioCategoria.CategoriaGanadoRef = this.CategoriaGanadoCambio.getValue();
		cambioCategoria.CorralRef = this.getCorral();
		cambioCategoria.FechaCreacion = new Date();
		cambioCategoria.FechaMovimiento = Utils.getDateWithTime(this.Fecha.getFecha(), this.Hora.getHora());
		cambioCategoria.Observaciones = this.Observaciones.getText();
		cambioCategoria.TropaCategoriaGanadoRef = this.haciendaSeleccionada.TropaCategoriaGanadoID;
		cambioCategoria.TropaRef = this.Tropa.getValue();
		cambioCategoria.GenerarPesada = this.PesoHacienda.generarPesada;
		cambioCategoria.PesoCabezaKg = this.PesoHacienda.getPorCabezaValue();
		cambioCategoria.DesbastePorc = this.PesoHacienda.getDesbasteValue();

		this._movTropaService.createCambio(cambioCategoria).subscribe(
			data => {
				if (Utils.checkAPIResponse(data, this.MessageDialog, "Error al cambiar la hacienda de categoría")) {
					super.informSave(true);
				} else {
					super.informSave(false);
				}
			}, error => {
				this.MessageDialog.showExceptionDialog("Error al cambiar la hacienda de categoría", error);
				super.informSave(false);
			},
			() => {
				this.saving = false;
				this.onCancel(undefined);
			}
		);
	}
}
