<div>
	<div class="row-sigef" [ngClass]="{'d-none': !puedeEditar}">
		<my-col-switch  #Activo [labelName]="'Usuario Activo'"></my-col-switch>
	</div>

	<div class="row-sigef">
		<div class="pl-3" style="width: 105px;">
			<div class="form-group">
				<label class="pb-1">Imagen</label>
				<div class="col-sigef-width">
					<kt-avatar-photo #Avatar class="pointer" (click)="cambiarFoto(file)" title="Cambiar Imagen de Perfil" alt="Pic" [puedeEliminar]="true"></kt-avatar-photo>
				</div>
			</div>
		</div>

		<div>
			<my-col-input [tabindex]="300" selectFirstInput [focusOnInit]="true" #Cuenta [type]="2" [divClass]="''" [labelName]="'Cuenta'" [required]="true" [readonly]="!puedeEditar"></my-col-input>
		</div>
	</div>

	<div class="row-sigef">
		<div [hidden]="cuentaVinculada()">
			<my-col-input [tabindex]="302" #Password [placeholder]="placeholder" [type]="2" [labelName]="'Contraseña'" [required]="isNewModel"></my-col-input>
		</div>
		<div *ngIf="cuentaVinculada()" class="col">
			<button [tabindex]="302" (click)="desvincularCuenta()" class="btn btn-sigef-secondary"><i class="fab fa-{{providerVinculado(true)}}"></i>Desvincular cuenta de {{providerVinculado()}}</button>
		</div>
	</div>

	<div class="row-sigef">
		<div>
			<my-col-input [tabindex]="303" #Nombre [type]="2" [labelName]="'Nombre'" [required]="true"></my-col-input>
		</div>
		<div>
			<my-col-input [tabindex]="304" #Apellido [type]="2" [labelName]="'Apellido'" [required]="true"></my-col-input>
		</div>
	</div>

	<div class="row-sigef">
		<div>
			<my-col-input [readonly]="cuentaVinculada() || !puedeEditar" [tabindex]="305" #Email [type]="2" [inputType]="'email'" [labelName]="'Email'" [required]="true"></my-col-input>
		</div>
		<div [ngClass]="{'d-none': !puedeEditar}">
			<my-col-multiselect [tabindex]="306" #Roles [textField]="'RolNombre'" [valueField]="'RolID'" [labelName]="'Roles'" [data]="roles"></my-col-multiselect>
		</div>
		<div [ngClass]="{'d-none': !puedeEditar}">
			<my-col-multiselect [tabindex]="307" #Zonas [textField]="'ZonaNombre'" [valueField]="'ZonaID'" [labelName]="'Zonas'" [data]="zonas"></my-col-multiselect>
		</div>
		<div [ngClass]="{'d-none': !puedeEditar}">
			<my-col-checkbox [tabindex]="308" #TodasLasZonas [labelName]="'Asignar nuevas zonas automáticamente'"></my-col-checkbox>
		</div>
	</div>

	<div class="row-sigef" [ngClass]="{'d-none': !puedeEditar}">
		<div>
			<my-col-dropdownlist [tabindex]="309" #Cliente [hint]="'Puede vincular un Usuario a un Propietario para que pueda acceder a SIGEF'" [labelName]="'Vincular a un Propietario'" [filterable]="true" [required]="false" [parent]="component" [key]="'Hotelero'"></my-col-dropdownlist>
		</div>
	</div>

	<div class="ml-3 mr-3 mt-3 pl-3 row" [ngClass]="{'d-none': openedInDialog}">
		<my-group-save-buttons [tabindex]="310" class="w-100" [saving]="saving" [targetName]="'Usuario'"
							   (onSave)="onUsuarioSave($event)" (onCancel)="onUsuarioCancel($event)"></my-group-save-buttons>
	</div>

	<input #file accept="image/*" hidden (click)="file.value = null" value="" onclick="this.value = null" (change)="fotoSeleccionada($event)" type="file" />
</div>
<my-message-dialog></my-message-dialog>
