<div>
	<div class="alert alert-warning mb-0 ml-3 mr-3" role="alert">
		<div class="alert-text">
			Se creará una <strong>nueva tropa</strong>, ingrese los datos básicos. Luego podrá completar el resto de la información desde <strong>Seguimiento de Tropas</strong>.
		</div>
	</div>

	<div class="row-sigef">
		<my-col-dropdownlist [tabindex]="6000" [focusOnInit]="true" selectFirstInput [parent]="component" [required]="'true'" [labelName]="'Propietario'"></my-col-dropdownlist>
	</div>

	<div class="row-sigef">
		<my-col-datetimepicker [tabindex]="6001" [labelName]="'Fecha'"></my-col-datetimepicker>
		<my-col-timepicker [tabindex]="6002" [labelName]="'Hora'"></my-col-timepicker>
	</div>

	<div class="row-sigef">
		<my-col-input [tabindex]="6003" #Nombre [type]="2" [labelName]="'Nombre de Tropa'" [required]="true"></my-col-input>
	</div>

	<div class="row-sigef">
		<my-col-input [tabindex]="6004" [mask]="'0.00 kg'" #AumentoDiario [labelName]="'Aumento Diario Estimado'" [allowCero]="false" [required]="true"></my-col-input>
	</div>

	<div class="row-sigef mb-3">
		<my-col-input-search [tabindex]="6005" [hint]="'En el caso de que no selecciones un corral destino, la hacienda permanecerá en los mismos corrales'" [labelName]="'Corral Destino'" [placeholder]="'Sin movimiento de corral'" [required]="false" [soloConCabezas]="false"></my-col-input-search>
	</div>

	<div class="ml-3 mr-3 pl-3 pl-2 pt-3 row" [ngClass]="{'d-none': openedInDialog}">
		<my-group-save-buttons [tabindex]="6005" class="w-100" [saving]="saving" [targetName]="'Operación'"
							   (onSave)="onEncierreSave($event)" (onCancel)="onEncierreCancel($event)"></my-group-save-buttons>
	</div>
</div>
<my-message-dialog></my-message-dialog>
