import { DTOInterface } from "../interfaces/dto.interface";

export class DietaAlimento implements DTOInterface {
	DietaAlimentoID: number;
	DietaRef: number;
	AlimentoRef: number;
	AlimentoPorcMS: number;
    Orden: number;

    AlimentoNombre: string;
    MateriaSecaPorc: number;
	PrecioXkg: number;
	Activo: boolean;
	Agua: boolean;

	constructor() {
		this.clear();
	}

	clear(): void {
		this.DietaAlimentoID = -1;
		this.DietaRef = -1;
		this.AlimentoRef = -1;
		this.AlimentoPorcMS = 0;
        this.Orden = 0;

        this.AlimentoNombre = '';
        this.MateriaSecaPorc = 0;
		this.PrecioXkg = 0;
		this.Activo = false;
		this.Agua = false;
	}
}
