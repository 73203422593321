import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[ktSelectOnClick]'
})
export class SelectOnClickDirective { 
   constructor(el: ElementRef) {
       el.nativeElement.addEventListener('click', this.onClick.bind(this));
   }  

    onClick(event) {
        event.target.select();
    }
}
