<!-- begin:: Content Head -->
<div class="kt-grid__item kt-subheader" [hidden]="subheaderService.disabled$ | async" id="kt_subheader">
	<div class="kt-subheader__main kt-container  kt-container--fluid ">  
		<div class="kt-container  kt-container--fluid ">
			<div class="kt-subheader__main"> 
				<span class="kt-subheader__separator kt-hidden"></span>
				
			</div> 
		</div>
	</div>
</div>
<!-- end:: Content Head -->
