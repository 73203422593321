<div>
	<div class="row-sigef" [ngClass]="{'avoid-clicks': readonly}">
		<my-col-datetimepicker selectFirstInput [focusOnInit]="true" [tabindex]="tabindex" [labelName]="'Fecha'" [required]="true"></my-col-datetimepicker>
	</div>

	<div class="row-sigef">
		<my-col-input [tabindex]="tabindex + 1" [readonly]="readonly" #Transportista [type]="2" [labelName]="'Transportista'" [required]="true"></my-col-input>
		<my-col-input [tabindex]="tabindex + 2" [readonly]="readonly" #GastoFlete [labelName]="'Gasto Flete'" [mask]="'c2'"></my-col-input>
	</div> 

	<div class="row-sigef dte-grid">
		<kt-tropa-transporte-dte-list [tabindex]="tabindex + 4" [readonly]="readonly" [tropaTransporte]="model"></kt-tropa-transporte-dte-list>
	</div>
</div>

<my-message-dialog></my-message-dialog>

