import { Directive, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, Input } from '@angular/core';
import { GridComponent, ColumnBase, DataBindingDirective } from '@progress/kendo-angular-grid';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { State, DataResult } from '@progress/kendo-data-query';

@Directive({
	selector: '[kendoGridPersistentState]'
})
export class KendoGridPersistentStateDirective extends DataBindingDirective implements OnInit, OnDestroy, AfterViewInit {
	destroy$: Subject<void> = new Subject();

	@Input() gridName: string = undefined;
	@Input() debug: boolean = false;

	private _grid: GridComponent = undefined;
	private _state: State = undefined;

	constructor(grid: GridComponent, private cd: ChangeDetectorRef) {
		super(grid);
		this._grid = grid;
	}

	ngOnInit(): void {
		if (!this._grid || !(this._grid instanceof GridComponent)) {
			throw "kendoGridComponent is required";
		}

		if (!this.gridName) {
			throw "kendoGridPersistentState requires [gridName] attribute";
		}

		super.ngOnInit();
	}

	ngAfterViewInit(): void { 
		
		this._grid.columnReorder.pipe(takeUntil(this.destroy$)).subscribe(s => {
			this.verbose("onColumnReorder");
			setTimeout(() => this.persistState(), 0);
		});

		this._grid.columnResize.pipe(takeUntil(this.destroy$)).subscribe(s => {
			this.verbose("onColumnResize");
			setTimeout(() => this.persistState(), 0);
		});

		this._grid.columnVisibilityChange.pipe(takeUntil(this.destroy$)).subscribe(s => {
			this.verbose("onColumnVisibilityChange");
			setTimeout(() => this.persistState(), 0); 
		});

		this._grid.dataStateChange.pipe(takeUntil(this.destroy$)).subscribe(s => {
			this._state = s; // guardar el state
			this.verbose("onDataStateChange");
			setTimeout(() => this.persistState(), 0);
		});

		this.restoreState();
	} 

	private verbose(msg: string): void {
		if (this.debug) {
			console.log(msg);
		}
	}

	private restoreState(): void {
		if (!this._grid || !this.gridName) { return; }

		let gridConfig: GridSettings = JSON.parse(localStorage.getItem(this.gridName));
		if (gridConfig) {

			if (gridConfig.state) {
				// recuperar el orden
				this.applyState(gridConfig.state);
				this._state = gridConfig.state;
			} 

			if (gridConfig.columnsConfig) {
				// recuperar la configuración de las columnas
				this._grid.columns.toArray().map((item: ColumnBase) => {
					let cc = gridConfig.columnsConfig.find(r => r.title === item.title);

					if (cc) {
						item.width = cc._width;
						item.orderIndex = cc.orderIndex;
						item.hidden = cc.hidden;
						item.columnMenu = cc.columnMenu;
						item.includeInChooser = cc.includeInChooser; 
					}
				});
			}

			this._grid.onDataChange();
			this.rebind();
			this.cd.detectChanges();

			this.verbose(`${this.gridName} state loaded!`);
		}
	}

	private persistState(): void {
		if (!this._grid || !this.gridName) { return; }

		const columns = this._grid.columns;

		const gridConfig = {
			state: this._state,
			columnsConfig: columns.toArray().map(item => {
				return Object.keys(item)
					.filter(
						propName => !propName.toLowerCase().includes('template')
					).reduce((acc, curr) => ({ ...acc, ...{ [curr]: item[curr] } }), <ColumnSettings>{});
			})
		}; 
		 
		let data: string = JSON.stringify(gridConfig);
		localStorage.setItem(this.gridName, data);

		this.verbose(`${this.gridName} state saved!`);

		this.cd.markForCheck();
		this.cd.detectChanges();

		this.notifyDataChange();

		this.rebind();
	}

	ngOnDestroy() {
		this.destroy$.next();
	}
}

export interface GridSettings {
	columnsConfig: ColumnSettings[];
	state: State;
	gridData?: DataResult;
}

export interface ColumnSettings {
	field: string;
	title?: string;
	filter?: 'string' | 'numeric' | 'date' | 'boolean';
	format?: string;
	width?: number;
	_width?: number;
	filterable: boolean;
	orderIndex: number;
	columnMenu: boolean;
	includeInChooser: boolean;
	hidden: boolean;
}
