<div class="w-100 col">
	<div class="col w-100 mb-2 p-1">
		<div [ngClass]="{'d-none': loading}" class="row">
			<my-col-input [tabindex]="tabindex" #Cabeza [mask]="'#,# Kg'" [decimals]="0" [extraClass]="'pesada-input'" class="pesada-input" [parent]="parent" [labelName]="'Por Cabeza'" [required]="required" [allowCero]="!required"></my-col-input>
			<my-col-input [tabindex]="tabindex + 1" #Total [mask]="'#,# Kg'" [decimals]="0" [extraClass]="'pesada-input'" class="pesada-input" [parent]="parent" [labelName]="'Total'" [required]="required" [allowCero]="!required"></my-col-input>
			<my-col-input [tabindex]="tabindex + 2" [factor]="'p'" [mask]="'p2'" [decimals]="4" #Desbaste [extraClass]="'pesada-input'" class="pesada-input" [parent]="parent" [labelName]="'% Desbaste'" [required]="required" [allowCero]="true"></my-col-input>
			<!--<my-col-input #Final class="hacienda-input" [readonly]="'true'" [labelName]="'Final por Cabeza'" [required]="true" [allowCero]="false"></my-col-input>-->
			<my-col-labelinfo #Final [labelName]="'Peso Neto Cab.'"></my-col-labelinfo>
		</div>
		<div [ngClass]="{'d-none': !loading}" class="row mt-3 k-i-loading"></div>
	</div>
</div>
