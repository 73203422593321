import { Pipe, PipeTransform } from '@angular/core';
import Utils from '../utils';

@Pipe({
    name: 'parseNumberKg'
})
export class ParseNumberKgPipe implements PipeTransform {

	transform(e: number, rounded: boolean = true, noDecimals: boolean = true): any {
		if (rounded) {
			e = Math.round(e);
		}

		return `${Utils.formatNumberDecimal(e, noDecimals)} Kg`;
	}
} 
