import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { OffCanvasInterface } from '../../../../../interfaces/off-canvas.interface';
import { MovAlimento } from '../../../../../models/mov-alimento';
import { EditableForm } from '../../common_controls/common-controls.interface';
import { IOpenInDialog, IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessengerService } from '../../../../../services/messenger.service';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { AlimentoService } from '../../../../../services/alimento.service';
import { MyColDropDownListComponent, MyColInputComponent, MyColTextAreaComponent } from '../../common_controls';
import { load } from '@progress/kendo-angular-intl';
import Utils, { PATIO_COMIDAS, MovAlimentoTipo } from '../../../../../utils';
import { AlimentoAlmacenService } from '../../../../../services/alimento-almacen.service';

@Component({
	selector: 'kt-movalimento-compra-alimento',
	templateUrl: './movalimento-compra-alimento.component.html',
	styleUrls: ['./movalimento-compra-alimento.component.scss'],
	providers: [AlimentoService, AlimentoAlmacenService]
})
export class MovalimentoCompraAlimentoComponent extends EditableForm<MovAlimento> implements AfterViewInit, OnInit, IOpenInDialog, OffCanvasInterface {

	modelIsNew: boolean = true;
	public componente: MovalimentoCompraAlimentoComponent = this;

	@Input() tabindex: number = 500;
	@ViewChild('Alimento', { static: true }) Alimento: MyColDropDownListComponent;
	@ViewChild('Cantidad', { static: true }) Cantidad: MyColInputComponent;
	@ViewChild('Precio', { static: true }) Precio: MyColInputComponent;
	@ViewChild('Almacen', { static: true }) Almacen: MyColDropDownListComponent;
	@ViewChild(MyColTextAreaComponent, { static: true }) Observaciones: MyColTextAreaComponent;

	constructor(
		private alimentoService: AlimentoService,
		private alimentoAlmacenService: AlimentoAlmacenService,
		cd: ChangeDetectorRef,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		quickPanelService: QuickPanelService) {
		super(cd, undefined, snackBar, messengerService, quickPanelService);
	}

	setOffCanvasData(data: any, extras: any): void {
		setTimeout(() => { 
			this.modelIsNew = (!data || data.MovAlimentoID.isEmpty());
			this.lstControls = [this.Alimento, this.Precio, this.Almacen, this.Cantidad, this.Observaciones];
			this.addCustomValidators();
			
			this.setModel(data);
		}, 0);
	}

	onProductoSave(e: any) {
		if (!this.runValidaciones()) {
			return false;
		}

		this.model.AlimentoNombre = this.Alimento.getItem().Value;
		this.model.AlimentoRef = this.Alimento.getValue();
		this.model.CantidadRealKg = this.Cantidad.getValue();
		this.model.Precio = this.Precio.getValue();
		this.model.Observaciones = this.Observaciones.getText();
		this.model.AlimentoAlmacenRef = this.Almacen.getValue();
		this.model.AlimentoAlmacenNombre = this.Almacen.getItem().Value;

		if (this.model.MovAlimentoID.isEmpty()) {
			// nuevo
			// lo setea CSLA en los Pre-Hooks
			this.model.Grupo = undefined;
			this.model.FechaMovimiento = this.model.FechaCompra;
			this.model.FechaCreacion = new Date();
			this.model.MovAlimentoTipoRef = MovAlimentoTipo.EntradaCompra;
		}

		this.getMessengerInstance().broadcast(undefined, undefined, 'MovAlimentoCompraAlimento', this.model);
		this.getQuickPanelInstance().hideQuickPanel();
	}

	onProductoCancel(e: any) {
		this.getQuickPanelInstance().hideQuickPanel();
	}

	ngOnInit() {
		this.Alimento.initializeCustomControlLookUp(this.alimentoService.getLookup(), this.cd, 'Seleccione un Producto...');
		this.Almacen.initializeCustomControlLookUp(this.alimentoAlmacenService.getLookup(), this.cd, 'Seleccione un Almacén...');
	}

	ngAfterViewInit() {

	}

	getNewModel(): MovAlimento { return new MovAlimento(); };

	onDropDownValueChanged(item, key): void {

	}

	onDataSet(key: any): void {
		if (key === 'Almacen') {
			if (this.Almacen.Data.length === 1) {
				// si tiene solo patio de comidas, elegir y bloquear
				this.Almacen.setValue(PATIO_COMIDAS);
			}
		}
	}

	setModelControlValues() { 
		if (this.model) {
			this.Alimento.setValue(this.model.AlimentoRef);
			this.Cantidad.setValue(this.model.CantidadRealKg);
			this.Precio.setValue(this.model.Precio);
			this.Almacen.setValue(this.model.AlimentoAlmacenRef);
			this.Observaciones.setText(this.model.Observaciones);
			this.adicionalesVisible = !((this.model.Observaciones || '') === '');
		}

		this.Alimento.focus();
	}

	onInputValueChange(newValue, labelName): void {

	}

	openInDialog(model: MovAlimento, extras: any) {
		this.openedInDialog = true; 
		this.setModel(model);
	}

	addCustomValidators(): void {

	}

	editResultDialog(formParent: IParentOpenInDialog): boolean {
		return false;
	}

	saveResultDialog(formParent: IParentOpenInDialog): boolean {
		if (formParent) {
			if (!this.runValidaciones()) {
				return false;
			}

			formParent.saveResultDialogModel(this.model);
		}
		return true;
	}
}
