import { DTOInterface } from "../interfaces/dto.interface";
import { Guid } from "guid-typescript";

export class MovProductoSanitario implements DTOInterface {
	MovProductoSanitarioID: Guid;
    MovProductoSanitarioTipoRef: number;
    ProductoSanitarioRef: number;
    FechaMovimiento: Date;
	FechaCreacion: Date;
	CantidadFormatoCompra: number;
    CantidadUM: number;
	CompraRef: number;
	ProductoSanitarioFormatoRef: number;
	PrecioTotal: number;
	TropaCategoriaGanadoRef: Guid;
	CorralRef: number;
	Observaciones: string;
	Grupo: Guid;
	PlanSanitarioRef: number;
	CantidadCab: number;

    MovProductoSanitarioTipoNombre: string;
	MultiplicaPor: number;
	ProveedorRef: number;
    FechaCompra: Date;
    RemitoNro: string;
    ProveedorNombre: string;
    ProveedorTipoNombre: string;
	ProductoSanitarioNombre: string;
	ProductoSanitarioFormatoNombre: string;
	FactorConversionUM: number;
	UnidadMedidaAbrev: string;
	Propietario: string;
	CabezasActuales: number;
	CategoriaGanadoNombre: string;
	CodigoTropa: number;
	ColorPrincipal: string;
	ColorFuente: string;
	CorralNombre: string;
	CorralNumero: number;
	TropaNombre: string;
	TropaRef: Guid;

    constructor() {
        this.clear();
    }

    clear(): void {
		this.MovProductoSanitarioID = Guid.createEmpty();
		this.MovProductoSanitarioTipoRef = -1;
		this.ProductoSanitarioRef = -1;
		this.FechaMovimiento = new Date();
		this.FechaCreacion = new Date();
		this.CantidadFormatoCompra = 0;
		this.CantidadUM = 0;
		this.CompraRef = undefined;
		this.ProductoSanitarioFormatoRef = undefined;
		this.PrecioTotal = 0;
		this.TropaCategoriaGanadoRef = undefined;
		this.CorralRef = undefined;
		this.Observaciones = '';
		this.Grupo = undefined;
		this.PlanSanitarioRef = undefined;
		this.CantidadCab = 0;

		this.MovProductoSanitarioTipoNombre = '';
		this.MultiplicaPor = 0;
		this.ProveedorRef = -1;
		this.FechaCompra = undefined;
		this.RemitoNro = '';
		this.ProveedorNombre = '';
		this.ProveedorTipoNombre = '';
		this.ProductoSanitarioNombre = '';
		this.Propietario = '';
    }
}

