<kendo-grid [data]="datos"
			(edit)="showABM($event)"
			(remove)="removeHandler($event)"
			(add)="showABM($event)"
			[loading]="buscando">
	<ng-template kendoGridToolbarTemplate>
		<button kendoGridAddCommand class="add-btn btn-sigef">Agregar Formato de Venta</button>
	</ng-template>
	<ng-template kendoGridNoRecordsTemplate>
		No hay datos de factores de venta para este producto sanitario
	</ng-template>

	<kendo-grid-column title="Formato de Venta">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{ dataItem.ProductoSanitarioFormatoNombre }}</span>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column title="{{descripcionColFactorDeConversion()}}">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{ dataItem.FactorConversionUM | parseNumberCustom : false : true : '' : unidadMedida ? unidadMedida.UnidadMedidaAbrev : '' }}</span>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column title="Activo" width="70">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span>{{dataItem.Activo ? 'Si' : 'No'}}</span>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-command-column class="k-grid-ignore-click" title="Acciones" width="120">
		<ng-template kendoGridCellTemplate>
			<button kendoGridEditCommand class="btn btn-sm"><i title="Editar" class="{{'Editar' | parseIconAccion }}"></i></button>
			<button kendoGridRemoveCommand class="btn btn-sm k-grid-ignore-click"><i title="Eliminar" class="{{'Eliminar' | parseIconAccion }}"></i></button>
		</ng-template>
	</kendo-grid-command-column>
</kendo-grid>
<my-message-dialog></my-message-dialog>
