// Angular
import { AfterViewInit, Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { filter } from 'rxjs/operators'; 
// HTML Class 
import { TranslateService } from '@ngx-translate/core';
import { SubheaderService } from '../../../../../core/_base/layout';

@Component({
	selector: 'kt-subheader1',
	templateUrl: './subheader1.component.html',
	styleUrls: ['./subheader1.component.scss']
})
export class Subheader1Component implements OnInit, OnDestroy, AfterViewInit {
	// Public properties
	currentRouteUrl: any = '';
	rootArrowEnabled: boolean;

	/**
	 * Component constructor
	 *
	 * @param subheaderService: SubheaderService
	 */
	constructor(public subheaderService: SubheaderService, private cd: ChangeDetectorRef, private translateService: TranslateService, private router: Router) { }

	redirectTo(uri) {
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
			this.router.navigate([uri]));
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() { 
		this.currentRouteUrl = this.router.url;
		this.router.events
			.pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
			.subscribe(event => {
				this.currentRouteUrl = this.router.url;
			});
	}

	/**
	 * After view init
	 */
	ngAfterViewInit(): void {

	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {

	}
}
