import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';   
import { Observable } from 'rxjs';
import { environment } from '../environments/environment'; 
import { MovProductoSanitario } from '../models/mov-producto-sanitario';
import { Guid } from 'guid-typescript';


@Injectable()
export class MovProductoSanitarioService extends GenericoService<MovProductoSanitario> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'movproductosanitario');
	}

	// Override del List
    getTableList(productoSanitarioRef: number, fechaDesde: Date, fechaHasta: Date, movProductoSanitarioTipoRef: number, grupo: Guid, clienteRef: number, tropaRef: Guid, categoriaGanadoRef: number): Observable<MovProductoSanitario[]> {
		return this._httpClient.post<MovProductoSanitario[]>(environment.apiUrl + `/${this._endpoint}/movimientos`, { ProductoSanitarioRef: productoSanitarioRef, FechaDesde: fechaDesde, FechaHasta: fechaHasta, MovProductoSanitarioTipoRef: movProductoSanitarioTipoRef, Grupo: grupo, ClienteRef: clienteRef, TropaRef: tropaRef, CategoriaGanadoRef: categoriaGanadoRef}, { headers: this._postHeaders });
	}

	getStock(productoSanitarioRef: number): Observable<any[]> {
		return this._httpClient.get<any[]>(environment.apiUrl + `/${this._endpoint}/stock/${productoSanitarioRef}`, { headers: this._getHeaders });
	}

	getMovStock(productoSanitarioRef: number): Observable<any[]> {
		return this._httpClient.get<any[]>(environment.apiUrl + `/${this._endpoint}/movstock/${productoSanitarioRef}`, { headers: this._getHeaders });
	}

	getListByGrupo(grupo: Guid): Observable<MovProductoSanitario[]> {
		return this.getTableList(undefined, undefined, undefined, undefined, grupo, 0, undefined, 0);
	}
}
