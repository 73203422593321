<div class="row-sigef" [ngClass]="{'d-none': esVenta}">
    <my-col-datetimepicker selectFistInput [focusOnInit]="true" [tabindex]="8000" [labelName]="'Fecha Movimiento'"></my-col-datetimepicker>
    <my-col-timepicker [labelName]="'Hora'" [tabindex]="8001"></my-col-timepicker>
</div>

<div class="row-sigef" [ngClass]="{'d-none': esVenta}">
    <my-col-input-search [tabindex]="8002" #Corral [soloConCabezas]="isNewModel" [labelName]="'Corral'" [required]="true" [parent]="component"></my-col-input-search>
</div>

<div class="row-sigef" [ngClass]="{'d-none': !isNewModel || esVenta}">
    <my-col-dropdownlist [tabindex]="8003" #Tropa [labelName]="'Tropa'" [required]="true" [key]="'Tropa'" [parent]="component" [keyType]="2"></my-col-dropdownlist>
    <my-col-dropdownlist [tabindex]="8004" #CategoriaGanado [labelName]="'Categoría de Hacienda'" [required]="true" [key]="'Categoria'" [parent]="component" [keyType]="2"></my-col-dropdownlist>
</div>

<div class="row-sigef" [ngClass]="{'d-none': esVenta}">
    <my-col-dropdownlist [tabindex]="8005" #CategoriaGanadoCambio [required]="false" [ngClass]="{'d-none': !esCambio}" [labelName]="'Nueva Categoría de Hacienda'" [key]="'NuevaCategoria'" [parent]="component" [keyType]="1"></my-col-dropdownlist>

    <my-col-input [tabindex]="8006" #Cantidad [readonly]="(esVenta)" [decimals]="0" [labelName]="labelCabezas" [required]="true" [allowCero]="false"></my-col-input>
    <div [ngClass]="{'d-none': !esRecuento || !isNewModel}" class="col-auto form-group">
        <label class="d-block">Cabezas (Actual)</label>
        <label class="font-weight-bold d-block mt-3">{{CantidadActual}}</label>
    </div>
</div>

<div class="row-sigef" [ngClass]="{'d-none': !esParicion}">
    <my-col-input-search [tabindex]="8007" #CorralDestino [soloConCabezas]="false" [labelName]="'Corral Destino'" [required]="false" [parent]="component"></my-col-input-search>
    <my-col-input [tabindex]="8008" [mask]="'0.00 kg'" #AumentoDiario [labelName]="'Aumento Diario Estimado'" [required]="false"></my-col-input>
</div>

<div class="pt-4" [ngClass]="{'d-none': (esRecuento || esParicion || esVenta || tipoMovimiento == 7)}">
    <my-row-expand [tabindex]="8009" #PesadaExpand [disabled]="true" [expanded]="true" [title]="''" [hint]="''" [labelName]="'Peso'" (onExpand)="pesadaVisible=$event"></my-row-expand>

    <div class="w-100">
        <kt-peso-hacienda [tabindex]="8010" [required]="false" [pesar]="true" [title]="'Nuevo Movimiento de Pesada'" [tropaCategoriaGanado]="tropaCategoriaGanadoRef" [cantidad]="cantidad" class="w-100"></kt-peso-hacienda>
    </div>
</div>

<div class="pt-4" [ngClass]="{'d-none': (esCambio || esRecuento || tipoMovimiento == 7 || esPesada)}">
    <my-row-expand [tabindex]="8020" #PrecioExpand [disabled]="true" [expanded]="true" [title]="'Precio'" [hint]="''" [labelName]="precioTitle" (onExpand)="precioVisible=$event"></my-row-expand>

    <div class="w-100">
        <kt-precio-compra-venta [tabindex]="8021" [required]="false" [cantidad]="cantidad" class="w-100"></kt-precio-compra-venta>
    </div>
</div>

<div class="pt-4">
    <my-row-expand [tabindex]="8022" #ObservacionesExpand class="pt-2" [hint]="'Seleccione esta opción para incluir una aclaración adicional'" [title]="Observaciones" [labelName]="'Observaciones'" [expanded]="adicionalesVisible" (onExpand)="adicionalesVisible=$event"></my-row-expand>

    <div class="" [ngClass]="{'d-none': !adicionalesVisible}">
        <my-col-textarea [tabindex]="8023" [labelName]="'Observaciones'" [rows]="2" [labelVisible]="false" class="w-100"></my-col-textarea>
    </div>
</div>

<div class="kt-separator kkt-separator--fit"></div>

<div class="ml-3 mr-3 pl-3 row">
    <my-group-save-buttons [tabindex]="8024" class="w-100" [saving]="saving" [targetName]="''"
                           (onSave)="onSave($event)" (onCancel)="onCancel($event)"></my-group-save-buttons>
</div> 

<my-message-dialog></my-message-dialog>
