import { Component, OnInit, ViewChild, ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, ViewContainerRef, Input, OnDestroy } from '@angular/core';
import { CorralFiltroComponent } from '../../corral';
import { MyColDropDownListComponent, MyColDateTimePickerComponent, MyColInputComponent, MyRowExpandComponent, MyMessageDialogComponent, MyColTextAreaComponent } from '../../common_controls';
import { ProductoSanitarioService } from '../../../../../services/producto-sanitario.service';
import { ProductoSanitario } from '../../../../../models/producto-sanitario';
import Utils, { MovProductoSanitarioTipo } from '../../../../../utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MovproductosanitarioAplicacionRowComponent, AplicacionRow } from '../movproductosanitario-aplicacion-row/movproductosanitario-aplicacion-row.component';
import { ComponentHostDirective } from '../../../../../directives/component-host.directive';
import { MessengerService } from '../../../../../services/messenger.service';
import { Subject } from 'rxjs';
import { BroadcastMessage, OBJETO_GUARDADO } from '../../../../../interfaces/message.interface';
import { takeUntil } from 'rxjs/operators';
import { Guid } from 'guid-typescript';
import { CorralService } from '../../../../../services/corral.service';
import { CorralInfo } from '../../../../../models/corral-info';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';
import { MovProductoSanitario } from '../../../../../models/mov-producto-sanitario';
import { MovProductoSanitarioService } from '../../../../../services/mov-producto-sanitario.service'; 
import { AplicacionSanitaria } from '../../../../../models/aplicacion-sanitaria';
import { AplicacionSanitariaService } from '../../../../../services/aplicacion-sanitaria.service';
import { PlanSanitarioService } from '../../../../../services/plan-sanitario.service';
import { PlanSanitario } from '../../../../../models/plan-sanitario';
import { Router } from '@angular/router';
// Routing
import { Routing, getRoute } from '../../../themes/sigef/routing';
import { IParentConfirmResultMessage } from '../../common_controls/message-dialog/my-message-dialog.component';

@Component({
	selector: 'kt-movproductosanitario-aplicacion',
	templateUrl: './movproductosanitario-aplicacion.component.html',
	styleUrls: ['./movproductosanitario-aplicacion.component.scss'],
	providers: [ProductoSanitarioService, CorralService, MovProductoSanitarioService, AplicacionSanitariaService, PlanSanitarioService]
})
export class MovproductosanitarioAplicacionComponent implements OnInit, OnDestroy, IParentConfirmResultMessage {
	private destroy$: Subject<void> = new Subject();

	public selectedKeys: number[] = [];
	public guardadoPosible: boolean = false;
	public gridData: RowTropa[] = [];
	public cargando: boolean = false;
	public guardando: boolean = false;
	public totalCabezasAplicadas: number = 0;
	public totalCabezasAplicables: number = 0;
	public buscando: boolean = false;
	public component: MovproductosanitarioAplicacionComponent = this;
	public adicionalesVisible: boolean = false;
	public rowsAplicacion: ComponentRef<MovproductosanitarioAplicacionRowComponent>[] = [];
	public edicionData: any = undefined;
	public planAplicado: boolean = false;

	private aplicacionSanitaria: AplicacionSanitaria = undefined;

	@ViewChild(MyMessageDialogComponent, { static: true }) MessageDialog: MyMessageDialogComponent;
	@ViewChild(CorralFiltroComponent, { static: true }) CorralFiltro: CorralFiltroComponent;
	@ViewChild('Observaciones', { static: true }) Observaciones: MyColTextAreaComponent;
	@ViewChild(MyRowExpandComponent, { static: true }) RowExpand: MyRowExpandComponent;
	@ViewChild('Aplicaciones', { static: true, read: ViewContainerRef }) Aplicaciones: ViewContainerRef;

	@Input() set itemId(value: Guid) {
		 // value es el Grupo con el que se van a buscar todos los movimientos que se dieron de alta al mismo tiempo (sea plan o no)
		if (!value || value.isEmpty()) {
			this.ngOnInit();
			return;
		}

		this.aplicacionSanitaria = new AplicacionSanitaria();
		this.aplicacionSanitaria.Grupo = value;

		this.buscando = true; 

		if (this.modo === 'plan') {
			this.cargarPlan(value);
		}
		if (this.modo === 'aplicacion') {
			this.cargarAplicacion(value);
		}
	};

	@Input() aplicar: boolean = false;
	@Input() edicion: boolean = false;
	@Input() tabindex: number = 1020;
	@Input() modo: string = 'aplicacion'; // aplicacion/plan

	constructor(private cd: ChangeDetectorRef,
		private messengerService: MessengerService,
		private componentFactoryResolver: ComponentFactoryResolver,
		private snackBar: MatSnackBar,
		private planSanitarioService: PlanSanitarioService,
		private productoSanitarioService: ProductoSanitarioService,
		private movProductoSanitarioService: MovProductoSanitarioService,
		private aplicacionSanitariaService: AplicacionSanitariaService,
		private router: Router,
		private corralService: CorralService) {

	} 

	ngOnDestroy() {
		this.destroy$.next();
	}

	ngOnInit() {
		this.gridData = [];
		this.selectedKeys = [];
		this.guardadoPosible = false;
		this.cargando = false;
		this.guardando = false;
		this.totalCabezasAplicadas = 0;
		this.totalCabezasAplicables = 0;
		this.buscando = false;
		this.adicionalesVisible = false;
		this.rowsAplicacion = [];
		this.edicionData = undefined;
		this.planAplicado = false;
		this.CorralFiltro.ngOnInit();
		this.aplicacionSanitaria = undefined;

		this.destroy$.next();
		this.destroy$ = new Subject<void>();

		this.messengerService.messageBroadcasted$.pipe(takeUntil(this.destroy$)).subscribe((data: BroadcastMessage) => {
			if (data.Sender === 'AplicacionRow') {
				if (data.Reason === 'eliminar') {
					this.eliminarAplicacion(data.Payload);
				}
				if (data.Reason === 'agregar') { 
					this.agregarAplicacion();
				}
			}
		});

		if (!this.edicion) this.resetAplicaciones();
	}

	buscar() {
		this.buscando = true;
		this.resetAplicaciones();
		this.totalCabezasAplicadas = 0;
		this.selectedKeys = [];
		this.guardadoPosible = false;

		let tropaID = this.CorralFiltro.Tropa.getValue() || Guid.createEmpty();
		let propietarioID = this.CorralFiltro.Hotelero.getValue() || 0;
		let corralesID = Utils.nameValueListToIntegerList(this.CorralFiltro.Corrales.getValue()) || [0]; // 0 son todos 
		this.gridData = [];

		this.cd.detectChanges(); 

		this.corralService.getInfo(0, propietarioID, tropaID, 0, 0, true, corralesID).subscribe((data: CorralInfo[]) => {
			for (const c of data) {
				if (c.Activo && c.Tropas.length > 0) {
					for (const t of c.Tropas) {
						let categoriaDuplicada: boolean = false;

						if (this.modo === 'plan') {
							// en el modo de Plan Sanitario, no se divide por corral.
							// Si ya se agregó el TropaCategoriaGanadoRef, sólo sumamos la cantidad de cabezas
							let tcg = this.gridData.find(e => e.TropaCategoriaGanadoRef.equals(t.TropaCategoriaGanadoRef))

							if (tcg) {
								tcg.Cabezas += t.Cabezas;
								categoriaDuplicada = true;
							}
						}

						if (categoriaDuplicada == false) {
							this.gridData.push({
								RowID: this.gridData.length,
								Cabezas: t.Cabezas,
								CabezasAplicadas: undefined,
								CategoriaGanado: t.CategoriaGanadoNombre,
								CorralNombre: c.CorralNombre, // Solo sirve para el MovProductoSanitario
								CorralNumero: c.CorralNumero, // Solo sirve para el MovProductoSanitario
								Propietario: t.Hotelero,
								TropaNombre: t.TropaNombre,
								CodigoTropa: t.CodigoTropa,
								ColorPrincipal: t.ColorPrincipal,
								TropaCategoriaGanadoRef: t.TropaCategoriaGanadoRef,
								CorralRef: c.CorralID // Solo sirve para el MovProductoSanitario
							});
						}
					}
				}
			}

			this.gridData.sort((a, b) => (a.CodigoTropa > b.CodigoTropa) ? 1 : ((b.CodigoTropa > a.CodigoTropa) ? -1 : 0)); 

		}, error => {
			this.MessageDialog.showExceptionDialog('No se pudieron obtener los corrales.', error);
		}, () => {  
			this.finalizarBusqueda();
		});
	}

	cargarPlan(grupo: Guid, forceAplicar: boolean = undefined) {
		let agrupado: boolean = this.aplicar;

		if (forceAplicar != undefined) {
			this.aplicar = true; 
			agrupado = false;

			this.aplicacionSanitaria = new AplicacionSanitaria();
			this.aplicacionSanitaria.Grupo = grupo;

			this.buscando = true; 
		}

		this.planSanitarioService.getList(grupo, agrupado).subscribe(
			data => { 
				//this.edicionData = data;
				//this.edicion = true; 
				this.aplicacionSanitaria.Planes = data;
				this.planAplicado = data.some(e => e.Aplicado === true);
				
				this.totalCabezasAplicables = 0;
				this.gridData = [];
				let cantidadProductos = new Set(data.map(e => e.ProductoSanitarioRef)).size;

				// hacienda
				for (const planSanitario of data) {
					// solo necesitamos 1 registro, se repite N veces (N = nro de productos seleccionados)
					if (this.aplicar) {
						if (this.gridData.find(e => e.CorralRef === planSanitario.CorralID && e.TropaCategoriaGanadoRef.equals(planSanitario.TropaCategoriaGanadoRef))) {
							continue;
						}
					} else {
						if (this.gridData.find(e => e.TropaCategoriaGanadoRef.equals(planSanitario.TropaCategoriaGanadoRef))) {
							continue;
						}
					}

					let hacienda: RowTropa = new RowTropa();
					let cabezasAplicadas: number = 0;
					

					hacienda.RowID = this.gridData.length;

					if (this.aplicar) {
						hacienda.Cabezas = Math.ceil((data.find(e => e.CorralID === planSanitario.CorralID && e.TropaCategoriaGanadoRef.equals(planSanitario.TropaCategoriaGanadoRef)).CabezasActuales));
						cabezasAplicadas = Math.ceil((data.find(e => e.CorralID === planSanitario.CorralID && e.TropaCategoriaGanadoRef.equals(planSanitario.TropaCategoriaGanadoRef)).CantidadCab));
					} else {
						hacienda.Cabezas = Math.ceil((data.filter(e => e.TropaCategoriaGanadoRef.equals(planSanitario.TropaCategoriaGanadoRef)).map(e => e.CabezasActuales).reduce((a, b) => a + b) / cantidadProductos));
						cabezasAplicadas = Math.ceil((data.filter(e => e.TropaCategoriaGanadoRef.equals(planSanitario.TropaCategoriaGanadoRef)).map(e => e.CantidadCab).reduce((a, b) => a + b) / cantidadProductos));
					}
					  
					if (this.aplicar) {
						hacienda.CabezasAplicadas = 0; // al momento de aplicar el usuario tiene que repartir las cabezas planificadas entre los corrales y hacienda actual
					} else {
						hacienda.CabezasAplicadas = cabezasAplicadas;
					}
					hacienda.CategoriaGanado = planSanitario.CategoriaGanadoNombre;
					hacienda.CodigoTropa = planSanitario.CodigoTropa;
					hacienda.ColorPrincipal = planSanitario.ColorPrincipal;
					hacienda.CorralNombre = planSanitario.CorralNombre;
					hacienda.CorralRef = planSanitario.CorralID;
					hacienda.CorralNumero = planSanitario.CorralNumero;
					hacienda.Propietario = planSanitario.Propietario;
					hacienda.TropaCategoriaGanadoRef = planSanitario.TropaCategoriaGanadoRef;
					hacienda.TropaNombre = planSanitario.TropaNombre; 

					this.gridData.push(hacienda);
				} 

				this.gridData.sort((a, b) => (a.CodigoTropa > b.CodigoTropa) ? 1 : ((b.CodigoTropa > a.CodigoTropa) ? -1 : 0)); 
				this.totalCabezasAplicables = this.gridData.map(e => e.Cabezas).reduce((a, b) => a + b);
			 
				// productos
				let productos: AplicacionRow[] = [];

				for (const productoSanitario of data) {
					if (productos.find(e => e.ProductoSanitarioRef === productoSanitario.ProductoSanitarioRef)) {
						// solo necesitamos 1 registro, se repite N veces (N = nro de productos seleccionados)
						continue;
					}
										
					let producto: AplicacionRow = new AplicacionRow(productoSanitario.PlanSanitarioID, productoSanitario.FechaPlan, productoSanitario.ProductoSanitarioRef, productoSanitario.CantidadUMCab);
					//this.agregarAplicacion(producto);
					productos.push(producto);
				}
				 
				this.resetAplicaciones(false);
				for (const producto of productos) {
					this.agregarAplicacion(producto);
				}
			},
			error => {
				Utils.showSnackBarErrorMessage(this.snackBar, 'No se pudo cargar el plan sanitario');
			},
			() => { 
				this.completarCabezasNuevas();
				this.finalizarCargaEdicion();
			}
		);
	}

	cargarAplicacion(grupo: Guid) { 
		this.movProductoSanitarioService.getListByGrupo(grupo).subscribe(
			data => {
				//this.edicionData = data;
				//this.edicion = true;
				this.aplicacionSanitaria.Aplicaciones = data;
				this.planAplicado = false;

				this.totalCabezasAplicables = 0;
				this.gridData = [];
				let cantidadProductos = new Set(data.map(e => e.ProductoSanitarioRef)).size;

				// hacienda
				for (const movProductoSanitario of data) {
					if (this.gridData.find(e => e.CorralRef === movProductoSanitario.CorralRef && e.TropaCategoriaGanadoRef.equals(movProductoSanitario.TropaCategoriaGanadoRef))) {
						// solo necesitamos 1 registro, se repite N veces (N = nro de productos seleccionados)
						continue;
					}

					let hacienda: RowTropa = new RowTropa(); 

					hacienda.RowID = this.gridData.length;
					hacienda.Cabezas = movProductoSanitario.CabezasActuales;
					hacienda.CabezasAplicadas = movProductoSanitario.CantidadCab;
					hacienda.CategoriaGanado = movProductoSanitario.CategoriaGanadoNombre;
					hacienda.CodigoTropa = movProductoSanitario.CodigoTropa;
					hacienda.ColorPrincipal = movProductoSanitario.ColorPrincipal;
					hacienda.CorralNombre = movProductoSanitario.CorralNombre;
					hacienda.CorralRef = movProductoSanitario.CorralRef;
					hacienda.CorralNumero = movProductoSanitario.CorralNumero;
					hacienda.Propietario = movProductoSanitario.Propietario;
					hacienda.TropaCategoriaGanadoRef = movProductoSanitario.TropaCategoriaGanadoRef;
					hacienda.TropaNombre = movProductoSanitario.TropaNombre;

					if (movProductoSanitario.Observaciones) {
						this.Observaciones.setText(movProductoSanitario.Observaciones);
						this.adicionalesVisible = true;
					}

					this.gridData.push(hacienda);
				}

				this.gridData.sort((a, b) => (a.CodigoTropa > b.CodigoTropa) ? 1 : ((b.CodigoTropa > a.CodigoTropa) ? -1 : 0)); 
				this.totalCabezasAplicables = this.gridData.map(e => e.Cabezas).reduce((a, b) => a + b);

				// productos
				let productos: AplicacionRow[] = [];

				for (const productoSanitario of data) {
					if (productos.find(e => e.ProductoSanitarioRef === productoSanitario.ProductoSanitarioRef)) {
						// solo necesitamos 1 registro, se repite N veces (N = nro de productos seleccionados)
						continue;
					}

					let producto: AplicacionRow = new AplicacionRow(productoSanitario.MovProductoSanitarioID, productoSanitario.FechaMovimiento, productoSanitario.ProductoSanitarioRef, productoSanitario.CantidadUM);
					//this.agregarAplicacion(producto);
					productos.push(producto);
				}

				this.resetAplicaciones(false);
				for (const producto of productos) {
					this.agregarAplicacion(producto);
				}
			},
			error => {
				Utils.showSnackBarErrorMessage(this.snackBar, 'No se pudo cargar el plan sanitario');
			},
			() => {
				this.completarCabezasNuevas();
				this.finalizarCargaEdicion();
			} 
		);
	}

	finalizarCargaEdicion() {
		if (this.aplicar || this.edicion) {
			this.selectedKeysChange(this.gridData.map(e => e.RowID));
		}
		else {
			this.selectedKeys = [];
			this.selectedKeys.push(0);
		}

		this.calcularTotalCabezas();

		this.finalizarBusqueda();
	}

	finalizarBusqueda(): void {
		this.buscando = false;
		this.cd.detectChanges();
	}

	agregarAplicacion(datos: AplicacionRow = undefined): void {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MovproductosanitarioAplicacionRowComponent);
	 
		for (const row of this.rowsAplicacion) {
			row.instance.mostrarBorrar = true;
			row.instance.mostrarAgregar = false;
		}

		let row = this.Aplicaciones.createComponent(componentFactory);

		row.instance.setId();
		row.instance.mostrarAgregar = true;
		row.instance.mostrarBorrar = false;

		if (this.modo === 'plan') {
			row.instance.dataBaseId = 0;
			if (!datos) { row.instance.Fecha.minDate = Utils.getStartOfDay(new Date()); }
			row.instance.Fecha.maxDate = datos ? new Date() : Utils.getDateTimeMaxValue();
		} else {
			row.instance.dataBaseId = Guid.createEmpty();
			//row.instance.Fecha.maxDate = Utils.getEndOfDay(new Date(), 23);
		}

		if (this.rowsAplicacion.length > 0) {
			row.instance.Fecha.setFecha(this.rowsAplicacion[this.rowsAplicacion.length - 1].instance.Fecha.getFecha());
			row.instance.mostrarBorrar = true;
			row.instance.containerClass = 'pt-4';
		}

		if (this.edicion || this.aplicar) { 
			row.instance.modo = this.modo;

			if (datos) {
				row.instance.setData(datos);
			}
		}

		this.rowsAplicacion.push(row); 
	}

	resetAplicaciones(agregarNuevo: boolean = true): void {
		this.Aplicaciones.clear();
		this.rowsAplicacion = [];

		if (agregarNuevo) this.agregarAplicacion(this.edicionData);
		this.cd.detectChanges();
	}

	eliminar(): void {
		this.MessageDialog.showDialog('Por favor confirme', 'Esta acción eliminará el registro y el grupo asociado. ¿Está seguro que desea continuar?',
			['SI', 'NO'], 'DeleteModel', this);
	}

	onConfirmResultMessageDialog(id: string, ok: boolean): void {
		if (ok && id === 'DeleteModel' && this.aplicacionSanitaria) {
			let observable = undefined;

			if (this.modo === 'plan') {
				observable = this.planSanitarioService.delete(this.aplicacionSanitaria.Planes[0].PlanSanitarioID); // Pasar un id cualquiera, borra todo el grupo
			}
			else {
				observable = this.movProductoSanitarioService.delete(this.aplicacionSanitaria.Aplicaciones[0].MovProductoSanitarioID); // Pasar un id cualquiera, borra todo el grupo
			} 
			
			if (observable) {
				observable.subscribe(
					data => {
						Utils.showSnackBarSucessMessage(this.snackBar, (this.modo === 'plan' ? 'Plan Sanitario eliminado!' : 'Aplicaciones eliminadas!'));
						this.cancelar();
					},
					error => {
						Utils.showSnackBarErrorMessage(this.snackBar, 'No se pudo eliminar el Plan Sanitario');
					},
					() => {
						this.cd.detectChanges();
					}
				);
			}
		}
	}

	eliminarAplicacion(row): void {
		let deletedRow: ComponentRef<MovproductosanitarioAplicacionRowComponent> = this.rowsAplicacion.find(r => r.instance.rowId === row);

		if (deletedRow) {
			this.rowsAplicacion.splice(this.rowsAplicacion.indexOf(deletedRow), 1);
			this.Aplicaciones.remove(this.Aplicaciones.indexOf(deletedRow.hostView));

			if (this.rowsAplicacion.length === 1) {
				this.rowsAplicacion[0].instance.mostrarAgregar = true;
				this.rowsAplicacion[0].instance.mostrarBorrar = false;
				this.rowsAplicacion[0].instance.containerClass = '';
			}

			Utils.showSnackBarInfoMessage(this.snackBar, 'Aplicación eliminada!');
		}
	}

	selectedKeysChange(rows: number[]) {
		this.selectedKeys = rows;
		this.completarCabezasNuevas();
	}

	completarCabezasNuevas(): void {
		for (const d of this.gridData) {
			if (this.selectedKeys.indexOf(d.RowID) > -1) {
				if (!d.CabezasAplicadas && !this.aplicar) {
					d.CabezasAplicadas = d.Cabezas;
				}
			}
		}
		this.calcularTotalCabezas();
	}

	toggleSeleccion(todos: boolean): void {
		if (todos) {
			this.selectedKeys = this.gridData.map(a => a.RowID);
		} else {
			this.selectedKeys = [];
		}

		this.completarCabezasNuevas();
	}

	onCabezaAplicadaChange(cabezas, rowID, handler: NumericTextBoxComponent): void { 
		setTimeout(() => {
			let row = this.gridData.find(r => r.RowID === rowID);

			if (row) {
				if (+cabezas > +row.Cabezas) {
					cabezas = +row.Cabezas;
				}

				row.CabezasAplicadas = +cabezas;
				handler.value = +cabezas;
			}

			this.calcularTotalCabezas();
		}, 10);
	}

	puedeEditarCabezas(rowID): boolean {
		return !(this.selectedKeys.indexOf(rowID) > -1);
	}

	calcularTotalCabezas(): void {
		this.totalCabezasAplicadas = 0;

		for (const d of this.gridData) {
			if (this.selectedKeys.indexOf(d.RowID) > -1) {
				if (d.CabezasAplicadas) {
					if (d.CabezasAplicadas > d.Cabezas) { d.CabezasAplicadas = d.Cabezas; }
					this.totalCabezasAplicadas += d.CabezasAplicadas;
				}
			}
		}

		this.guardadoPosible = this.planAplicado == false && this.selectedKeys && (this.selectedKeys.length > 0) && this.totalCabezasAplicadas > 0;
		this.cd.detectChanges();
	}

	runValidaciones(): boolean {
		let productosSeleccionados: number[] = [];

		for (const a of this.rowsAplicacion) {
			if (productosSeleccionados.indexOf(a.instance.Producto.getValue()) > -1) {
				a.instance.setProductoRepetido();
			} else {
				productosSeleccionados.push(a.instance.Producto.getValue());
			}

			a.instance.validar();
		}

		if (this.aplicar && this.totalCabezasAplicadas > this.totalCabezasAplicables) {
			this.MessageDialog.showAtencionDialog(`La aplicación del Plan Sanitario no puede superar ${this.totalCabezasAplicables} cabezas.`);
			return false;
		}

		let r: boolean = (this.rowsAplicacion.some(x => x.instance.valido == false) ? false : true);

		if (!r) {
			this.MessageDialog.showAtencionDialog('Existen errores en los productos cargados');
		}

		return r;
	}

	aplicarPlan() {
		if (!this.runValidaciones()) { 
			return;
		}

		// basicamente se guardar el Plan Sanitario, y se crea el MovProductoSanitario Correspondiente desde el SP.

		let planes: PlanSanitario[] = [];

		for (const i of this.selectedKeys) {
			let row = this.gridData.find(r => r.RowID === i);

			if (row) {
				for (const a of this.rowsAplicacion) {
					if (Utils.checkNaNGetZero(row.CabezasAplicadas) > 0) {
						let ps: PlanSanitario = new PlanSanitario();

						ps.PlanSanitarioID = a.instance.dataBaseId;
						ps.CantidadCab = row.CabezasAplicadas;
						ps.CantidadUMCab = a.instance.Cantidad.getValue();
						ps.FechaPlan = a.instance.Fecha.getFecha();
						ps.Grupo = this.aplicacionSanitaria.Grupo;
						ps.ProductoSanitarioRef = a.instance.Producto.getValue();
						ps.TropaCategoriaGanadoRef = row.TropaCategoriaGanadoRef;
						 
						ps.Aplicado = true; // sirve para hacer el update y que cree el movProductoSanitario
						ps.CorralID = row.CorralRef;

						planes.push(ps);
					}
				}
			}
		}

		this.aplicacionSanitaria.Observaciones = (this.RowExpand.expanded ? this.Observaciones.getText() : ''); // lo paso una sola vez a nivel de objeto, para no repetirlo en cada MovPS, eso lo hace CSLA.
		this.aplicacionSanitaria.Planes = planes;
		this.aplicacionSanitaria.Aplicaciones = [];

		this.guardarPlanAplicado();
	}

	cancelar() {
		if (this.modo === 'plan') {
			this.notificar(); 
		} else {
			this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([getRoute(Routing.PRODUCTOS_SANITARIOS_REGISTRO_APLICACION)]));			 
		}
	}

	guardarPlanAplicado() {
		this.guardando = true;

		this.aplicacionSanitariaService.update(this.aplicacionSanitaria.Grupo, this.aplicacionSanitaria).subscribe(
			data => {
				Utils.showSnackBarSucessMessage(this.snackBar, 'El Plan Sanitario fue aplicado con éxito');
				this.notificar(); 
			},
			error => {
				Utils.showSnackBarErrorMessage(this.snackBar, 'No se pudo guardar aplicar el Plan Sanitario');
			},
			() => {
				this.guardando = false;
				this.cd.detectChanges();
			}
		);
	}

	guardarPlan(): void {
		if (!this.runValidaciones()) {
			return;
		}

		let aplicacionSanitaria: AplicacionSanitaria = undefined;

		if (this.edicion) {
			aplicacionSanitaria = this.aplicacionSanitaria;
			aplicacionSanitaria.Planes = []; // se vuelve a crear con los mismos Id
		} else {
			aplicacionSanitaria = new AplicacionSanitaria();  
			aplicacionSanitaria.Grupo = Guid.create();
			aplicacionSanitaria.Planes = [];
		}
		 
		for (const i of this.selectedKeys) {
			let row = this.gridData.find(r => r.RowID === i);

			if (row) {
				for (const a of this.rowsAplicacion) {
					let ps: PlanSanitario = new PlanSanitario();

					ps.PlanSanitarioID = a.instance.dataBaseId;
					ps.CantidadCab = row.CabezasAplicadas;
					ps.CantidadUMCab = a.instance.Cantidad.getValue();
					ps.FechaPlan = a.instance.Fecha.getFecha();
					ps.Grupo = aplicacionSanitaria.Grupo;
					ps.ProductoSanitarioRef = a.instance.Producto.getValue();
					ps.TropaCategoriaGanadoRef = row.TropaCategoriaGanadoRef;

					ps.Aplicado = false;
					ps.CorralID = undefined;

					aplicacionSanitaria.Planes.push(ps);
				}
			}
		}
		 
		this.guardar(aplicacionSanitaria);
	}

	notificar(): void {
		this.messengerService.broadcast('Aplicacion', 'PlanSanitarioComponent', OBJETO_GUARDADO, undefined); 
	}

	guardarAplicacion(): void {
		if (!this.runValidaciones()) {
			return;
		}

		let aplicacionSanitaria: AplicacionSanitaria = undefined;

		if (this.edicion) {
			aplicacionSanitaria = this.aplicacionSanitaria;
			aplicacionSanitaria.Aplicaciones = []; // se vuelve a crear con los mismos Id
		} else {
			aplicacionSanitaria = new AplicacionSanitaria(); 
			aplicacionSanitaria.Grupo = Guid.create();
			aplicacionSanitaria.Aplicaciones = [];
		}

		aplicacionSanitaria.Observaciones = (this.RowExpand.expanded ? this.Observaciones.getText() : ''); // lo paso una sola vez a nivel de objeto, para no repetirlo en cada MovPS, eso lo hace CSLA.

		for (const i of this.selectedKeys) {
			let row = this.gridData.find(r => r.RowID === i);

			if (row) {
				for (const a of this.rowsAplicacion) { 
					let mps: MovProductoSanitario = new MovProductoSanitario();

					mps.CantidadUM = a.instance.Cantidad.getValue();
					mps.CorralRef = row.CorralRef;
					mps.FechaCompra = undefined;
					mps.FechaCreacion = new Date();
					mps.FechaMovimiento = new Date(a.instance.Fecha.getFecha().getFullYear(), a.instance.Fecha.getFecha().getMonth(), a.instance.Fecha.getFecha().getDate(), mps.FechaMovimiento.getHours(), mps.FechaMovimiento.getMinutes(),0,0);
					mps.Grupo = aplicacionSanitaria.Grupo;
					mps.MovProductoSanitarioID = a.instance.dataBaseId;
					mps.MovProductoSanitarioTipoRef = MovProductoSanitarioTipo.SalidaUso;
					mps.ProductoSanitarioRef = a.instance.Producto.getValue();
					mps.TropaCategoriaGanadoRef = row.TropaCategoriaGanadoRef;
					mps.CantidadCab = row.CabezasAplicadas;

					aplicacionSanitaria.Aplicaciones.push(mps);
				}
			}
		}

		this.guardar(aplicacionSanitaria);
	}

	guardar(aplicacionSanitaria: AplicacionSanitaria): void {
		this.guardando = true;

		let observable = undefined;

		if (this.edicion) {
			observable = this.aplicacionSanitariaService.update(aplicacionSanitaria.Grupo, aplicacionSanitaria);
		} else {
			observable = this.aplicacionSanitariaService.create(aplicacionSanitaria);
		} 

		if (observable) {
			observable.subscribe(
				data => {
					Utils.showSnackBarSucessMessage(this.snackBar, 'La información se guardó existosamente');
					if (this.edicion || this.aplicar) {
						this.cancelar();
					} else {
						this.resetAplicaciones();
					}
					this.notificar();
				},
				error => {
					Utils.showSnackBarErrorMessage(this.snackBar, 'No se pudo guardar la aplicación sanitaria');
				},
				() => {
					this.guardando = false;
					this.cd.detectChanges();
				}
			);
		}
	}
}

export class RowTropa {
	RowID: number;
	CodigoTropa: number;
	TropaNombre: string;
	Propietario: string;
	CategoriaGanado: string;
	CorralNombre: string;
	CorralNumero: number;
	Cabezas: number;
	CabezasAplicadas: number;
	ColorPrincipal: string;

	TropaCategoriaGanadoRef: Guid;
	CorralRef: number;

	constructor() {
		this.RowID = 0;
		this.CodigoTropa = 0;
		this.TropaNombre = '';
		this.Propietario = '';
		this.CategoriaGanado = '';
		this.CorralNombre = '';
		this.CorralNumero = 0;
		this.Cabezas = 0;
		this.CabezasAplicadas = undefined;
		this.ColorPrincipal = undefined;

		this.TropaCategoriaGanadoRef = undefined;
		this.CorralRef = undefined;
	}
}
