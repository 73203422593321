<div>
	<div class="row row-sigef">
		<my-col-datetimepicker [required]="true" [tabindex]="1" #FechaCompra [labelName]="'Fecha de Compra'"></my-col-datetimepicker>
	</div>
	<div class="row row-sigef">
		<my-col-dropdownlist [required]="true" [tabindex]="2" #Proveedor [filterable]="true" [parent]="component"  [key]="'Proveedor'" [labelName]="'Proveedor'"></my-col-dropdownlist>
	</div>
	<div class="row row-sigef">
		<my-col-input #NroRemito [required]="false" [tabindex]="3" [type]="2" [labelName]="'Nro de Remito/Factura'"></my-col-input>
	</div>
	<div class="row p-3">
		<kt-movalimento-compra-alimento-list [compra]="model"></kt-movalimento-compra-alimento-list>
	</div>
	<div class="kt-divider kt-divider-sigef"><span></span></div>

	<div class="ml-3 mr-3">
		<my-group-save-buttons [tabindex]="12" class="w-100" [saving]="saving" [targetName]="'Compra'"
							   (onSave)="onSave($event)" (onCancel)="onCancel($event)"></my-group-save-buttons>
	</div>
</div>
<my-message-dialog></my-message-dialog>
