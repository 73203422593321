import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { OffCanvasInterface } from '../../../../../interfaces/off-canvas.interface';
import { MessengerService } from '../../../../../services/messenger.service';
import { EditableForm } from '../../common_controls/common-controls.interface';
import { InfoDiaria } from '../../../../../models/info-diaria';
import { InfoDiariaService } from '../../../../../services/info-diaria.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MyColInputComponent, MyColTextAreaComponent, MyRowExpandComponent } from '../../common_controls';
import { Guid } from 'guid-typescript';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import Utils from '../../../../../utils';

@Component({
	selector: 'infodiaria',
	templateUrl: './infodiaria.component.html',
	styleUrls: ['./infodiaria.component.scss'],
	providers: [InfoDiariaService]
})
export class InfodiariaComponent extends EditableForm<InfoDiaria> implements OnInit, OffCanvasInterface {
	@ViewChild('ObservacionesAlimentacion', { static: true }) observaciones: MyColTextAreaComponent;
	@ViewChild('Precipitaciones', { static: true }) precipitaciones: MyColInputComponent;
	@ViewChild('ObservacionesAlimentacionExpand', { static: true }) observacionesExpand: MyRowExpandComponent;
	@Input() tabindex: number = 4700;
	
	infoDiariaModel: InfoDiaria;
	constructor(private infoDiariaService: InfoDiariaService,
		cd: ChangeDetectorRef,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		quickPanelService: QuickPanelService) {
		super(cd, infoDiariaService, snackBar, messengerService, quickPanelService);
	}

	setOffCanvasData(data: any, extras: any): void {
		setTimeout(() => {
			this.lstControls = [this.observaciones, this.precipitaciones];
			this.observaciones.focus();

			this.setModel(data);
			this.setExtras(extras);
		}, 0);
	}

	ngOnInit() {
		this.observacionesExpand.onClick();
	}
	 
	onInitSetModel(): void {

	}

	getKeyModel(obj: InfoDiaria): Guid {
		return obj.InfoDiariaID;
	}

	getNewModel(): InfoDiaria {
		return new InfoDiaria();
	}

	setModelPropsByControls(): void {
		this.model.ObservacionesAlimentacion = this.observaciones.getText();
		this.model.Precipitaciones = this.precipitaciones.getValue();
	}

	setModelControlValues() {
		if (this.model) {
			this.observaciones.setText(this.model.ObservacionesAlimentacion);
			this.precipitaciones.setValue(this.model.Precipitaciones);
			this.precipitaciones.setMaxValue(9999);
		}
	}

	setExtras(extras: any): void {

	} 

	informSave(sucess: boolean): void {
		if (sucess) {
			this.getMessengerInstance().broadcast('InfoDiariaComponent', 'SeleccionInfodiariaComponent', undefined, this.model);
		}
		super.informSave(sucess);
	}

	onInfoDiariaSave(e: any) {
		if (!this.runValidaciones())
			return false;

		if (Guid.parse(this.model.InfoDiariaID.toString()).isEmpty())
			this.model.FechaCreacion = new Date();

		this.model.FechaInfoDiaria = Utils.addDays(this.model.FechaInfoDiaria, 0);
		this.model.FechaInfoDiaria.setHours(10);
			
		super.onSave(e, false);
	}

	onInfoDiariaCancel(e: any) {
		super.onCancel(e);
	}
}
