<form class="kt-form col">
	<div class="form-group">
		<div class="kt-checkbox-list">
			<label *ngIf="!disabled">
				<input tabindex="{{tabindex}}" [checked]="expanded" kendoCheckBox (click)="onClick()" type="checkbox"> {{labelName}} 
			</label>
			<span class="sigef--grid--note col-form-label-title" *ngIf="disabled">{{labelName}}</span>
		</div>
		<span *ngIf="hint" class="form-text text-muted">{{hint}}</span>
	</div>
</form>
