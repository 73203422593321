import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';
import { Rol } from '../models/rol';
import { Observable } from 'rxjs';
import { NameValueList } from '../interfaces/nvl.interface';
import { environment } from '../environments/environment';

@Injectable()
export class RolService extends GenericoService<Rol> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'rol');
	}

	getLookupByRecibeNotifAdmin(recibeNotifAdmin: boolean): Observable<NameValueList[]> {
		return this._httpClient.post<NameValueList[]>(environment.apiUrl + `/${this._endpoint}/recibeNotifAdmin`, { RecibeNotifAdmin: recibeNotifAdmin }, { headers: this._postHeaders });
	}
}
