<div>
	<div class="row col">
		<my-col-input #DietaNombre selectFirstInput [type]="2" [labelName]="'Nombre'" [required]="true"></my-col-input>

		<my-col-colorpicker [palette]="'dieta'" #Color [labelName]="'Color'" [tooltipTitle]="'Color'"
							[tooltipMessage]="'El color se utiliza para una rápida identificación de la dieta.'">
		</my-col-colorpicker>
		<my-col-switch #ActivaSwitch [labelName]="'Dieta Activa'"></my-col-switch>
	</div>
	<div class="row col-md-12">
		<my-col-textarea #DietaDescripcion [labelName]="'Descripción'" [rows]="2" [cols]="60"></my-col-textarea>
	</div>  
</div>
