import { Component, OnInit, Input, ChangeDetectorRef, ViewRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { CommonControl } from '../common-controls.interface';
import { PaletteSettings } from '@progress/kendo-angular-inputs';
import { ColorService } from '../../../../../services/color.service';
import { Observable } from 'rxjs';
import { NameValueList } from '../../../../../interfaces/nvl.interface';
import { MessengerService } from '../../../../../services/messenger.service';

@Component({
	selector: 'my-col-colorpicker',
	templateUrl: './my-col-colorpicker.component.html',
	styleUrls: ['./my-col-colorpicker.component.scss'],
	providers: [ColorService]
})
export class MyColColorPickerComponent extends CommonControl implements OnInit {
	@Input() tooltipMessage: string = '';
	@Input() tooltipTitle: string = '';
	@Input() set palette(value: string) {
		switch (value.toLowerCase()) {
			case 'dieta':
				this.getPalette(Paletas.DIETA);
				break;
			case 'tropa':
				this.getPalette(Paletas.TROPA);
				break;
		}
	}

	id: number = -1;
	color: string = '';
	private nvlPalette: NameValueList[] = [];

	public paletteDefault: Array<string> = []; /* El array de colores se llena con valores de la tabla `Color` en función de la paleta requerida */

	public settings: PaletteSettings = { }; 

	constructor(private colorService: ColorService, private cd: ChangeDetectorRef, private messengerService: MessengerService) {
		super();
		this.Form = new UntypedFormGroup({
			'Color': new UntypedFormControl('')
		});
	}
	ngOnInit() {
		this.Form.reset();
		if (this.required) {
			this.Form.controls['Color'].setValidators(Validators.required);
		}
	}

	getColor(): any {
		return this.id;		
	}

	getColorValue(): any {
		return this.color;
	}
	
	private getPalette(paleta: Paletas): void {
		let endpoint: Observable<NameValueList[]> = undefined;

		switch (paleta) {
			case Paletas.DIETA:
				endpoint = this.colorService.getDietas();
				break;
			case Paletas.TROPA:
				endpoint = this.colorService.getTropas();
				break;
		}

		this.paletteDefault = [];

		if (endpoint) {
			endpoint.subscribe(
				data => {
					this.nvlPalette = data;

					for (const c of data) {
						this.paletteDefault.push(c.Value);
					}

					this.settings = {
						palette: this.paletteDefault,
						columns: 5,
						tileSize: 30
					};

					this.settings.palette = this.paletteDefault;
				},
				error => {

				},
				() => {
					if (this.cd && !((this.cd as ViewRef).destroyed)) {
						this.cd.detectChanges();
					}

					this.messengerService.broadcast('MyColColorPicker', 'TropaComponent', 'ColorPaletteLoaded', undefined);
				}
			);
		}
	}

	getPrincipalControl(): AbstractControl {
		return this.Form.controls['Color'];
	}

	setValue(value: any): void {
		this.id = 0;
		if (isNaN(+value)) { /* se setea por ID, pero el componente setea por color */ 
			let i = this.nvlPalette.find(c => c.Value.toUpperCase() === value.toUpperCase());
			if (i) {
				this.id = i.Key;
				this.color = value;
			}
		} else {
			let i = this.nvlPalette.find(c => c.Key === value);
			if (i) {
				this.color = i.Value.toUpperCase();
				this.id = value;
			}
		} 

		if (this.color && this.id) {
			this.Form.patchValue({ InputNumber: this.color });
		}
	}
}

export enum Paletas {
	DIETA = 1,
	TROPA
}
