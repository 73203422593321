<kendo-grid #grid
			[kendoGridBinding]="gridData"
			(keydown)="onKeydown(grid, $event)"
			kendoGridFocusable
			[sortable]="true"
			(edit)="editHandler($event)" (cancel)="cancelHandler($event)"
			(save)="saveHandler($event)" (remove)="removeHandler($event)"
			(add)="addHandler($event)"
			[rowClass]="rowCallback"
			[navigable]="true">

	<ng-template kendoGridToolbarTemplate>
		<button class="add-btn btn-sigef" kendoGridAddCommand>Agregar Producto Alimenticio</button>
		<button class="add-btn btn-sigef  k-button k-grid-add-command" style="float:right;" (click)="vistaDetallada = !vistaDetallada">
			<span *ngIf="vistaDetallada">Ocultar columnas adicionales</span>
			<span *ngIf="!vistaDetallada">Mostrar Columnas</span>
		</button>
	</ng-template>

	<kendo-grid-messages noRecords="{{'CRUD.COMMON.NO_RECORDS' | translate}}">

	</kendo-grid-messages>

	<!-- Orden Col -->
	<kendo-grid-column field="Orden" width="75" title="Orden" >
		<ng-template kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span class="sub" *ngIf="!dataItem.Agua">{{dataItem.Orden}}</span>
				<span class="sub" *ngIf="dataItem.Agua">-</span>
			</div>
		</ng-template>
		<ng-template kendoGridEditTemplate let-dataItem let-column="column" let-formGroup="formGroup">
			<div>
				<input #input type="text" mask="999999" class="k-textbox" [formControl]="formGroup.get(column.field)">
				<kt-popup-server-validation [submitted]="submitted" [input]="input" [formGroup]="formGroup" [column]="column"></kt-popup-server-validation>
			</div>
		</ng-template>
	</kendo-grid-column>

	<!-- AlimentoRef Col -->
	<kendo-grid-column field="AlimentoRef" width="250" title="Producto">
		<ng-template kendoGridCellTemplate let-dataItem>
			<strong>{{alimento(dataItem.AlimentoRef)?.AlimentoNombre}}</strong> <span *ngIf="!dataItem.Agua"> ({{alimento(dataItem.AlimentoRef)?.MateriaSecaPorc | parseNumberPorc : true}} MS)</span>
		</ng-template>
		<ng-template kendoGridEditTemplate
					 let-dataItem="dataItem"
					 let-column="column"
					 let-formGroup="formGroup">

			<div *ngIf="dataItem.AlimentoRef > -1">
				<kendo-combobox #ddl="popupAnchor"
								popupAnchor
								[data]="ingredientes"
								[filterable]="true"
								(filterChange)="handleAlimentoFilter($event)"
								[placeholder]="'Seleccione un Alimento'"
								textField="AlimentoNombre"
								valueField="AlimentoID"
								style="width: 100%"
								[clearButton]="false"
								[suggest]="true"
								[valuePrimitive]="true"
								[formControl]="formGroup.get('AlimentoRef')">
					<ng-template kendoComboBoxItemTemplate let-dataItem>
						{{ dataItem.AlimentoNombre }} ({{ dataItem.MateriaSecaPorc | parseNumberPorc : true }} MS)
					</ng-template>
				</kendo-combobox>
				<kt-popup-server-validation [submitted]="submitted" [input]="ddl.element" [formGroup]="formGroup" [column]="column"></kt-popup-server-validation>
			</div>
		</ng-template>
		<ng-template kendoGridFooterTemplate let-column="column">
			<div align="center">
				<span class="ms-total">Mat. Seca: {{totalMateriaSeca | parseNumberPorc : true }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>

	<!-- AlimentoPorcMS Col -->
	<kendo-grid-column field="AlimentoPorcMS" width="200">
		<ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
			<div align="center">
				Porcentaje Dieta
			</div>
		</ng-template>
		<ng-template kendoGridCellTemplate let-dataItem>
			<div class="input-group k-grid-ignore-click mx-auto" style="width: 80%">
				<div class="input-group-prepend">
					<button (click)="menosMS(dataItem)" style="min-width: 2.5rem" class="btn btn-decrement btn-sigef" type="button">
						<strong>-</strong>
					</button>
				</div>

				<input ktSelectOnClick value="{{ dataItem.AlimentoPorcMS | parseNumberPorc }}" type="text" (keyup)="onPorcChange($event, dataItem)" (focusout)="onPorcChange($event, dataItem)" style="text-align: center;" class="form-control" placeholder="">

				<div class="input-group-append">
					<button (click)="masMS(dataItem)" style="min-width: 2.5rem" class="btn btn-increment btn-sigef" type="button">
						<strong>+</strong>
					</button>
				</div>
			</div>
		</ng-template>
		<ng-template kendoGridEditTemplate>

		</ng-template>
		<ng-template kendoGridFooterTemplate let-column="column">
			<div align="center">
				<span [ngClass]="{'ms-100': totalMS === 1, 'ms-no-100': totalMS != 1 }">{{ totalMS | parseNumberPorc }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>

	<!-- TalCual Col (d) -->
	<kendo-grid-column >
		<ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
			<div align="center">
				% Inc tal cual
			</div>
		</ng-template>
		<ng-template kendoGridCellTemplate let-dataItem>
			<div align="center" style="font-weight: 700;">
				<span class="sub">{{ talcual(dataItem.AlimentoRef, dataItem.AlimentoPorcMS) | parseNumber }} %</span>
			</div>
		</ng-template>
		<ng-template kendoGridFooterTemplate let-column="column">
			<div align="center">
				<span class="sub" style="text-align: center;">{{ totalIncTalCual | parseNumberPorc }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>

	<!-- Gasto Col (d) -->
	<kendo-grid-column media="(min-width: 1280px)" *ngIf="vistaDetallada" title="Gasto (MS)" >
		<ng-template kendoGridCellTemplate let-dataItem>
			<span *ngIf="!dataItem.Agua" class="not-editable">{{ gasto(dataItem.AlimentoRef, dataItem.AlimentoPorcMS) | parseNumberMoney }}</span>
			<span *ngIf="dataItem.Agua" class="not-editable">-</span>
		</ng-template>
		<ng-template kendoGridFooterTemplate let-column="column">
			<span style="text-align: center;" class="sub">{{ totalGasto | parseNumberMoney }}</span>
		</ng-template>
	</kendo-grid-column>

	<!-- MCal Col (d) -->
	<kendo-grid-column media="(min-width: 1280px)" *ngIf="vistaDetallada" title="MCal" >
		<ng-template kendoGridCellTemplate let-dataItem>
			<span *ngIf="!dataItem.Agua" class="not-editable">{{ mcal(dataItem.AlimentoRef, dataItem.AlimentoPorcMS) | parseNumber }}</span>
			<span *ngIf="dataItem.Agua" class="not-editable">-</span>
		</ng-template>
		<ng-template kendoGridFooterTemplate let-column="column">
			<span style="text-align: center;" class="sub">{{ totalMCal | parseNumber }}</span>
		</ng-template>
	</kendo-grid-column>

	<!-- Proteina Col (d) -->
	<kendo-grid-column media="(min-width: 1280px)" *ngIf="vistaDetallada" title="Proteína" >
		<ng-template kendoGridCellTemplate let-dataItem>
			<span *ngIf="!dataItem.Agua" class="not-editable">{{proteina(dataItem.AlimentoRef, dataItem.AlimentoPorcMS) | parseNumberPorc }}</span>
			<span *ngIf="dataItem.Agua" class="not-editable">-</span>
		</ng-template>
		<ng-template kendoGridFooterTemplate let-column="column">
			<span class="sub" style="text-align: center;">{{ totalProteina | parseNumberPorc }}</span>
		</ng-template>
	</kendo-grid-column>

	<!-- Calcio Col (d) -->
	<kendo-grid-column media="(min-width: 1280px)" *ngIf="vistaDetallada" title="Calcio" >
		<ng-template kendoGridCellTemplate let-dataItem>
			<span *ngIf="!dataItem.Agua" class="not-editable">{{ calcio(dataItem.AlimentoRef, dataItem.AlimentoPorcMS) | parseNumberPorc }}</span>
			<span *ngIf="dataItem.Agua" class="not-editable">-</span>
		</ng-template>
		<ng-template kendoGridFooterTemplate let-column="column">
			<span class="sub" style="text-align: center;">{{ totalCalcio | parseNumberPorc }}</span>
		</ng-template>
	</kendo-grid-column>

	<!-- Fosforo Col (d) -->
	<kendo-grid-column media="(min-width: 1280px)" *ngIf="vistaDetallada" title="Fósforo" >
		<ng-template kendoGridCellTemplate let-dataItem>
			<span *ngIf="!dataItem.Agua" class="not-editable">{{ fosforo(dataItem.AlimentoRef, dataItem.AlimentoPorcMS) | parseNumberPorc }}</span>
			<span *ngIf="dataItem.Agua" class="not-editable">-</span>
		</ng-template>
		<ng-template kendoGridFooterTemplate let-column="column">
			<span class="sub" style="text-align: center;">{{ totalFosforo | parseNumberPorc}}</span>
		</ng-template>
	</kendo-grid-column>

	<!-- FDN Col (d) -->
	<kendo-grid-column media="(min-width: 1280px)" *ngIf="vistaDetallada" title="FDN">
		<ng-template kendoGridCellTemplate let-dataItem>
			<span *ngIf="!dataItem.Agua" class="not-editable">{{ fdn(dataItem.AlimentoRef, dataItem.AlimentoPorcMS) | parseNumberPorc }}</span>
			<span *ngIf="dataItem.Agua" class="not-editable">-</span>
		</ng-template>
		<ng-template kendoGridFooterTemplate let-column="column">
			<span class="sub" style="text-align: center;">{{ totalFDN | parseNumberPorc }}</span>
		</ng-template>
	</kendo-grid-column>

	<!-- Acciones Col -->
	<kendo-grid-command-column class="k-grid-ignore-click" title="Acciones" >
		<ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew">
			<button kendoGridEditCommand class="btn btn-sm"><i title="Editar" class="{{'Editar' | parseIconAccion }}"></i></button>
			<button kendoGridRemoveCommand class="btn btn-sm k-grid-ignore-click"><i title="Eliminar" class="{{'Eliminar' | parseIconAccion }}"></i></button>
			<button kendoGridSaveCommand class="btn btn-sm"><i title="Guardar" class="{{'Confirmar' | parseIconAccion }}"></i></button>
			<button kendoGridCancelCommand class="btn btn-sm"><i title="Cancelar" class="{{'Cancelar' | parseIconAccion }}"></i></button>
		</ng-template>
	</kendo-grid-command-column>
</kendo-grid>
<my-message-dialog></my-message-dialog>
