// Angular
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// Kendo
import { ButtonModule, ButtonsModule } from '@progress/kendo-angular-buttons';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule, DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TimePickerModule, DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GaugesModule } from '@progress/kendo-angular-gauges';
// NgxTranslate
import { TranslateModule } from '@ngx-translate/core';
// Sidebar
import { SidebarModule } from 'ng-sidebar';
// Ngb
import { NgbPopoverModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
// NgxPermissions
import { NgxPermissionsModule } from 'ngx-permissions';
// Partials 
import * as Partials from '../partials';
// Modules
import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipe.module';
import { PortletModule } from '../partials/sigef/portlet/portlet.module';

@NgModule({
    declarations: [
        Partials.AvatarPhotoComponent,
        Partials.AlertComponent,
        Partials.AlimentacionStocksListAlmacenComponent,
        Partials.CambiarDietaComponent,
        Partials.CargaAlimentoListComponent,
        Partials.CargaDescargaListComponent,
        Partials.CargaDietaAlimentoComponent,
        Partials.CargaListComponent,
        Partials.ConsumoAlimentoListComponent,
        Partials.ConsumoChartComponent,
        Partials.AlimentoChartComponent,
        Partials.CorralFiltroComponent,
        Partials.CorralTropaComponent,
        Partials.DescargaChartComponent,
        Partials.DescargaCorralComponent,
        Partials.DietaAlimentoComponent,
        Partials.DietaAlimentoDashboardComponent,
        Partials.DietaComponent,
        Partials.DietaCrudComponent,
        Partials.DietaOrdenComponent,
        Partials.FaenaComponent,
        Partials.FaenaListComponent,
        Partials.GridActionsComponent,
        Partials.HaciendaOperacionInternaComponent,
        Partials.InfodiariaComponent,
        Partials.IngresoHaciendaComponent,
        Partials.InlineServerValidationComponent,
        Partials.MessageInfoComponent,
        Partials.MovAlimentoComponent,
        Partials.MovalimentoCompraAlimentoComponent,
        Partials.MovalimentoCompraAlimentoListComponent,
        Partials.MovalimentoCompraComponent,
        Partials.MovproductosanitarioAplicacionComponent,
        Partials.MovproductosanitarioAplicacionRowComponent,
        Partials.MovProductoSanitarioComponent,
        Partials.MovProductoSanitarioCompraComponent,
        Partials.MovProductoSanitarioCompraProductoComponent,
        Partials.MovProductoSanitarioCompraProductoListComponent,
        Partials.MyColAutocompleteComponent,
        Partials.MyColButtonComponent,
        Partials.MyColCheckboxComponent,
        Partials.MyColColorPickerComponent,
        Partials.MyColDateRangeComponent,
        Partials.MyColDateTimePickerComponent,
        Partials.MyColDropDownListComponent,
        Partials.MyColInputComponent,
        Partials.MyColInputSearchComponent,
        Partials.MyColLabelInfoComponent,
        Partials.MyColMultiselectComponent,
        Partials.MyColMultiselectCorralComponent,
        Partials.MyColSwitchComponent,
        Partials.MyColTextAreaComponent,
        Partials.MyColTimePickerComponent,
        Partials.MyGroupCorralComponent,
        Partials.MyGroupCorralTropaComponent,
        Partials.MyGroupSaveButtonsComponent,
        Partials.MyGroupUpDownValueComponent,
        Partials.MyMessageDialogComponent,
        Partials.MyRowExpandComponent,
        Partials.NotificacionComponent,
        Partials.PesoHaciendaComponent,
        Partials.PopupServerValidationComponent,
        Partials.PrecioCompraVentaComponent,
        Partials.ProductoSanitarioComponent,
        Partials.ProductoSanitarioCrudComponent,
        Partials.ProductoSanitarioFormatoComponent,
        Partials.ProductoSanitarioFormatoListComponent,
        Partials.ProductoSanitarioListFormatoComponent,
        Partials.SeleccionInfodiariaComponent,
        Partials.SeleccionMixeroComponent,
        Partials.TropaArchivoComponent,
        Partials.TropaCambioCorralComponent,
        Partials.TropaCategoriaGanadoComponent,
        Partials.TropaCategoriaGanadoListComponent,
        Partials.TropaComponent,
        Partials.TropaConsumoComponent,
        Partials.TropaCorralComponent,
        Partials.TropaCorralNotificacionComponent,
        Partials.TropaCrudComponent,
        Partials.TropaEncierreComponent,
        Partials.TropaMovimientoComponent,
        Partials.TropaMovimientoComponent,
        Partials.TropaMovimientoListComponent,
        Partials.TropaMovimientoPopupComponent,
        Partials.TropaMovimientoReadonlyComponent,
        Partials.TropaReporteComponent,
        Partials.TropaReporteEdicionComponent,
        Partials.TropaSeguimientoCorralListComponent,
        Partials.TropaSeguimientoListComponent,
        Partials.TropaTransporteComponent,
        Partials.TropaTransporteDteComponent,
        Partials.TropaTransporteDteListComponent,
        Partials.TropaTransporteListComponent,
        Partials.UsuarioComponent,
        Partials.VentaCategoriaGanadoListComponent,
        Partials.VentaComponent,
        Partials.VentaCrudComponent,
        Partials.VentaRentabilidadComponent,
        Partials.VentaReporteComponent,
        Partials.VentaTransporteComponent,
        Partials.VentaTransporteDteComponent,
        Partials.VentaTransporteDteListComponent,
        Partials.VentaTransporteListComponent,
    ],
    imports: [
        CommonModule,
        PopupModule,
        InputsModule,
        ButtonModule,
        ButtonsModule,
        DropDownsModule,
        TimePickerModule,
        GridModule,
        DirectivesModule,
        TranslateModule.forChild(),
        NgxPermissionsModule.forChild(),
        ReactiveFormsModule,
        DropDownListModule,
        SidebarModule,
        DialogModule,
        MatInputModule,
        MatTabsModule,
        NgbModule,
        FormsModule,
        MatDialogModule,
        NgbPopoverModule,
        DragDropModule,
        ChartsModule,
        PipesModule,
        PortletModule,
        ExcelModule,
        DateInputsModule,
        GaugesModule
    ],
    exports: [
        CommonModule,
        PopupModule,
        InputsModule,
        ButtonModule,
        ButtonsModule,
        DropDownsModule,
        TimePickerModule,
        GridModule,
        MatTabsModule,
        NgbModule,
        NgbPopoverModule,
        DirectivesModule,
        SidebarModule,
        DialogModule,
        MatInputModule,
        FormsModule,
        MatDialogModule,
        DragDropModule,
        ChartsModule,
        ReactiveFormsModule,
        DropDownListModule,
        PipesModule,
        PortletModule,
        ExcelModule,
        DateInputsModule,
        NgxPermissionsModule,
        GaugesModule,
        Partials.AvatarPhotoComponent,
        Partials.AlimentacionStocksListAlmacenComponent,
        Partials.CambiarDietaComponent,
        Partials.CargaAlimentoListComponent,
        Partials.CargaDescargaListComponent,
        Partials.CargaDietaAlimentoComponent,
        Partials.CargaListComponent,
        Partials.ConsumoAlimentoListComponent,
        Partials.ConsumoChartComponent,
        Partials.AlimentoChartComponent,
        Partials.CorralFiltroComponent,
        Partials.CorralTropaComponent,
        Partials.DescargaChartComponent,
        Partials.DescargaCorralComponent,
        Partials.DietaAlimentoComponent,
        Partials.DietaAlimentoDashboardComponent,
        Partials.DietaComponent,
        Partials.DietaCrudComponent,
        Partials.DietaOrdenComponent,
        Partials.FaenaComponent,
        Partials.FaenaListComponent,
        Partials.GridActionsComponent,
        Partials.HaciendaOperacionInternaComponent,
        Partials.InfodiariaComponent,
        Partials.IngresoHaciendaComponent,
        Partials.InlineServerValidationComponent,
        Partials.MessageInfoComponent,
        Partials.MovAlimentoComponent,
        Partials.MovalimentoCompraAlimentoComponent,
        Partials.MovalimentoCompraAlimentoListComponent,
        Partials.MovalimentoCompraComponent,
        Partials.MovproductosanitarioAplicacionComponent,
        Partials.MovproductosanitarioAplicacionRowComponent,
        Partials.MovProductoSanitarioComponent,
        Partials.MovProductoSanitarioCompraComponent,
        Partials.MovProductoSanitarioCompraProductoComponent,
        Partials.MovProductoSanitarioCompraProductoListComponent,
        Partials.MyColAutocompleteComponent,
        Partials.MyColButtonComponent,
        Partials.MyColCheckboxComponent,
        Partials.MyColColorPickerComponent,
        Partials.MyColDateRangeComponent,
        Partials.MyColDateTimePickerComponent,
        Partials.MyColDropDownListComponent,
        Partials.MyColInputComponent,
        Partials.MyColInputSearchComponent,
        Partials.MyColLabelInfoComponent,
        Partials.MyColMultiselectComponent,
        Partials.MyColMultiselectCorralComponent,
        Partials.MyColSwitchComponent,
        Partials.MyColTextAreaComponent,
        Partials.MyColTimePickerComponent,
        Partials.MyGroupCorralComponent,
        Partials.MyGroupCorralTropaComponent,
        Partials.MyGroupSaveButtonsComponent,
        Partials.MyGroupUpDownValueComponent,
        Partials.MyMessageDialogComponent,
        Partials.MyRowExpandComponent,
        Partials.NotificacionComponent,
        Partials.PesoHaciendaComponent,
        Partials.PopupServerValidationComponent,
        Partials.PrecioCompraVentaComponent,
        Partials.ProductoSanitarioComponent,
        Partials.ProductoSanitarioCrudComponent,
        Partials.ProductoSanitarioFormatoComponent,
        Partials.ProductoSanitarioFormatoListComponent,
        Partials.ProductoSanitarioListFormatoComponent,
        Partials.SeleccionInfodiariaComponent,
        Partials.SeleccionMixeroComponent,
        Partials.TropaArchivoComponent,
        Partials.TropaCambioCorralComponent,
        Partials.TropaCategoriaGanadoComponent,
        Partials.TropaCategoriaGanadoListComponent,
        Partials.TropaComponent,
        Partials.TropaConsumoComponent,
        Partials.TropaCorralComponent,
        Partials.TropaCorralNotificacionComponent,
        Partials.TropaCrudComponent,
        Partials.TropaEncierreComponent,
        Partials.TropaMovimientoComponent,
        Partials.TropaMovimientoComponent,
        Partials.TropaMovimientoListComponent,
        Partials.TropaMovimientoPopupComponent,
        Partials.TropaMovimientoReadonlyComponent,
        Partials.TropaReporteComponent,
        Partials.TropaReporteEdicionComponent,
        Partials.TropaSeguimientoCorralListComponent,
        Partials.TropaSeguimientoListComponent,
        Partials.TropaTransporteComponent,
        Partials.TropaTransporteDteComponent,
        Partials.TropaTransporteDteListComponent,
        Partials.TropaTransporteListComponent,
        Partials.UsuarioComponent,
        Partials.VentaCategoriaGanadoListComponent,
        Partials.VentaComponent,
        Partials.VentaCrudComponent,
        Partials.VentaRentabilidadComponent,
        Partials.VentaReporteComponent,
        Partials.VentaTransporteComponent,
        Partials.VentaTransporteDteComponent,
        Partials.VentaTransporteDteListComponent,
        Partials.VentaTransporteListComponent,
        Partials.AlertComponent,
    ]
})
export class SharedModule { }
