<kendo-grid [data]="datos"
			(edit)="showABM($event)"
			(remove)="removeHandler($event)"
			(add)="showABM($event)"
			[loading]="buscando">
	<ng-template *ngIf="showAllColumns" kendoGridToolbarTemplate>
		<button tabindex="{{tabindex}}" kendoGridAddCommand class="add-btn btn-sigef">
			Agregar Categoría de Hacienda
		</button>
	</ng-template>
	<ng-template kendoGridNoRecordsTemplate>
		No hay hacienda para la tropa
	</ng-template>

	<kendo-grid-column field="CategoriaGanadoNombre" title="Categoría" width="100">
	</kendo-grid-column>
	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" field="Cantidad" title="Cantidad" width="80">
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span class="footer-item">{{ dataItem.Cantidad }}</span>
			</div>
		</ng-template>
		<ng-template class="text-center" kendoGridFooterTemplate let-column="column">
			<div align="center">
				<span class="footer-item">{{ totalCantidad }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" *ngIf="mostrarColPrecio" title="Precio Compra" width="80">
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span class="footer-item">{{ dataItem.PrecioCompra | parseNumberMoney }}</span>
			</div>
		</ng-template>
		<ng-template class="text-center" kendoGridFooterTemplate let-column="column">
			<div align="center">
				<span class="footer-item">{{ totalPrecio | parseNumberMoney }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" title="Peso Bruto" width="80" *ngIf="showAllColumns && mostrarColPeso">
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span class="footer-item">{{ dataItem.PesoCabezaKg | parseNumberKg }}</span>
			</div>
		</ng-template>
		<ng-template class="text-center" kendoGridFooterTemplate let-column="column">
			<div align="center">
				<span class="footer-item">{{ totalPesoVivo | parseNumberKg }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" title="Desbaste" width="80" *ngIf="showAllColumns && mostrarColPeso"> 
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span class="footer-item">{{ dataItem.DesbastePorc | parseNumberPorc }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" title="Peso Neto" width="80" *ngIf="showAllColumns && mostrarColPeso">
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span class="footer-item">{{ (round(dataItem.PesoCabezaKg) - (round(dataItem.PesoCabezaKg) * dataItem.DesbastePorc)) | parseNumberKg }}</span>
			</div>
		</ng-template>
		<ng-template class="text-center" kendoGridFooterTemplate let-column="column">
			<div align="center">
				<span class="footer-item">{{ totalPesoNeto | parseNumberKg }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column [headerStyle]="{'text-align': 'center'}" title="Aumento Diario" width="80"> 
		<ng-template class="text-center" kendoGridCellTemplate let-dataItem>
			<div align="center">
				<span class="footer-item">{{ dataItem.AumentoDiarioEstKg | parseNumberKg : false : false }}</span>
			</div>
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column field="CorralNombre" title="Corral" width="100">
	</kendo-grid-column>

	<kendo-grid-command-column class="k-grid-ignore-click" title="Acciones" width="120">
		<ng-template kendoGridCellTemplate>
			<button kendoGridEditCommand class="btn btn-sm"><i title="Editar" class="{{'Editar' | parseIconAccion }}"></i></button>
			<button kendoGridRemoveCommand *ngIf="showAllColumns" class="btn btn-sm k-grid-ignore-click"><i title="Eliminar" class="{{'Eliminar' | parseIconAccion }}"></i></button>
		</ng-template>
	</kendo-grid-command-column>
</kendo-grid>

<div class="mt-3" [ngClass]="{'d-none': !puedeEditar }">
	<my-col-input [extraClass]="'kt-p0'" [tabindex]="tabindex + 1" [decimals]="0" #Muertos [labelName]="'Cantidad de Muertos al Arribo'"></my-col-input>
</div>

<my-message-dialog></my-message-dialog>
