import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { CorralDescarga } from '../../../../../models/corral-descarga';
import { CorralService } from '../../../../../services/corral.service';
import { UsuarioService } from '../../../../../services/usuario.service';
import { MyColDropDownListComponent, MyColDateTimePickerComponent, MyColTextAreaComponent, MyColTimePickerComponent, MyColInputComponent, MyColInputSearchComponent, MyMessageDialogComponent } from '../../common_controls';
import { AlimentacionDescargasComponent } from '../../../pages/alimentacion/alimentacion-descargas/alimentacion-descargas.component';
import Utils, { Roles } from '../../../../../utils';
import { EditableForm } from '../../common_controls/common-controls.interface';
import { CorralDescargaService } from '../../../../../services/corral-descarga.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessengerService } from '../../../../../services/messenger.service';
import { QuickPanelService } from '../../../../../services/quick-panel.service';

@Component({
	selector: 'kt-descarga-corral',
	templateUrl: './descarga-corral.component.html',
	styleUrls: ['./descarga-corral.component.scss'],
	providers: [CorralService, UsuarioService]
})
export class DescargaCorralComponent extends EditableForm<CorralDescarga> implements OnInit {
	fechaDescarga: Date = undefined;
	remanenteVisible: boolean = false;
	cantDisponible: number;
	finalizarButtonText: string = 'Finalizar descargas';

	@ViewChild(MyColDropDownListComponent, { static: true }) Mixero: MyColDropDownListComponent;
	@ViewChild(MyColDateTimePickerComponent, { static: true }) Fecha: MyColDateTimePickerComponent;
	@ViewChild(MyColInputSearchComponent, { static: true }) Corral: MyColInputSearchComponent;
	@ViewChild(MyColTimePickerComponent, { static: true }) Hora: MyColTimePickerComponent;
	@ViewChild('KgRemanente', { static: true }) KgRemanente: MyColInputComponent;
	@ViewChild('KgCargado', { static: true }) KgCargado: MyColInputComponent;
	@ViewChild(MyColTextAreaComponent, { static: true }) Observaciones: MyColTextAreaComponent;

	constructor(private corralService: CorralService,
		private usuarioService: UsuarioService,
		corralDescargaService: CorralDescargaService,
		cd: ChangeDetectorRef,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		quickPanelService: QuickPanelService) {
		super(cd, corralDescargaService, snackBar, messengerService, quickPanelService);
	}

	ngOnInit() {
		this.lstControls = [this.Mixero, this.Fecha, this.Corral, this.Hora,
		this.KgRemanente, this.KgCargado, this.Observaciones];

		this.Mixero.initializeCustomControlLookUp(this.usuarioService.getLookupByRole(Roles.Mixero), this.cd, "Seleccione un Mixero...");
		this.Corral.initializeControlByCorral(this.corralService);
	}

	getKeyModel(obj: CorralDescarga): any { return obj.CorralDescargaID; };
	getNewModel(): CorralDescarga {
		let obj = new CorralDescarga();
		let parent = this.getParent();
		let carga = parent.mixerCarga;
		obj.MixerCargaRef = carga.MixerCargaID;
		obj.UsuarioRef = carga.UsuarioRef;
		obj.UsuarioNombre = carga.UsuarioNombre;
		obj.KgDisponibles = parent.cantDisponible;
		obj.FechaDescarga = parent.getFechaPropuestaDescarga();
		return obj;
	};

	getParent(): AlimentacionDescargasComponent {
		return <AlimentacionDescargasComponent>this.parent;
	}

	setModelControlValues() {
		this.getParent().setCantDisponible(this.model.CantidadRealKg);
		this.cantDisponible = this.getParent().cantDisponible;
		this.Fecha.setFecha(new Date(this.model.FechaDescarga));
		this.Hora.setHora(this.model.FechaDescarga);
		this.Mixero.setValue(this.model.UsuarioRef);
		this.Corral.setValue(this.model.CorralRef);
		this.KgCargado.setValue(this.model.CantidadRealKg);
		this.KgCargado.setMaxValue(this.cantDisponible);
		this.KgRemanente.setValue(Utils.checkNaNGetZero(this.model.Remanente));
		this.remanenteVisible = this.model.Remanente != null;
		this.Observaciones.setText(this.model.Observaciones);
	}

	continuarButtonText(): string {
		return (this.isNewModel) ? "Próxima descarga >>>" : "Guardar";
	}

	setModelPropsByControls(): void {
		this.model.KgDisponibles = this.cantDisponible;
		this.model.FechaDescarga = Utils.getDateWithTime(this.Fecha.getFecha(), this.Hora.getHora());
		let corral = this.Corral.getItem();
		this.model.CorralRef = corral.Key;
		this.model.CorralNombre = corral.Descripcion;
		let mixero = this.Mixero.getItem();
		this.model.UsuarioRef = mixero.Key;
		this.model.UsuarioNombre = mixero.Value;
		this.model.CantidadRealKg = this.KgCargado.getValue();
		if (this.remanenteVisible)
			this.model.Remanente = this.KgRemanente.getValue();
		this.model.Observaciones = this.Observaciones.getText();
	}

	runCustomValidaciones() {
		let fCarga = this.getParent().mixerCarga.FechaCarga;
		let fDescarga = Utils.getDateWithTime(this.Fecha.getFecha(), this.Hora.getHora());
		if (Utils.dateIsLessThanDate(fDescarga, fCarga)) {
			this.MessageDialog.showAtencionDialog(`La fecha de descarga (${Utils.getStringFullDateHour(fDescarga)}) no puede ser menor a la fecha de carga (${Utils.getStringFullDateHour(fCarga)})`);
			return false;
		}
		return true;
	}

	limpiarRemanente() {
		this.model.Remanente = undefined;
		this.remanenteVisible = false;
	}

	onInputValueChange(item: number, labelName: string) {
		if (labelName === 'Descargado' && item > 0)
			this.finalizarButtonText = 'Guardar y finalizar descargas';
		else
			this.finalizarButtonText = 'Finalizar descargas';
	}

	finalizarCustomButtonText(): string {
		return (this.isNewModel) ? this.finalizarButtonText : "Cancelar";
	}

	onFinalizarDescargas(e: any) {
		if (this.isNewModel && this.KgCargado.getValue() > 0) {
			super.onSave(e, true);
		}
		super.onCancel(e);
	}
}
