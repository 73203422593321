import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseDateHour'
})
export class ParseDateHourPipe implements PipeTransform {

  transform(e: any, ...args: any[]): string {
	  let d = new Date(e);

	  let fecha = [d.getDate().toString().padStart(2, '0'), (d.getMonth() + 1).toString().padStart(2, '0'), d.getFullYear()].join('/');
	  let hora = `${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`

	  return `${fecha} ${hora}`;
  }

}
