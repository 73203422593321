import { Pipe, PipeTransform } from '@angular/core';
import Utils from '../utils';

@Pipe({
    name: 'parseNumberMoney'
})
export class ParseNumberMoneyPipe implements PipeTransform {

	transform(e: any, noDecimals: boolean = false): string {
		return "$ " + Utils.formatNumberDecimal(e, noDecimals);
    }

}
