<div class="col" style="min-width: 320px; width: 320px;">
	<div class="form-group" [formGroup]="Form">
		<label class="pb-1" *ngIf="labelVisible">{{labelName}}</label>
		<div class="input-group">
			<kendo-multiselect tabindex="{{tabindex}}" #controlInput style="width: 100%" formControlName="Input" (valueChange)="onValueChange($event)" [data]="data" [textField]="textField" [valueField]="valueField"></kendo-multiselect>
		</div>
		<kt-inline-server-validation [ngClass]="{'d-none': !showValidation}" [input]="controlInput" [formGroup]="Form" [field]="'Description'"></kt-inline-server-validation>
	</div>
	<span *ngIf="hint" class="form-text text-muted">{{hint}}</span>
</div>
