import { Directive, ElementRef, OnInit, OnDestroy, AfterViewInit, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';
import { Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { NgControl } from '@angular/forms';

@Directive({
	selector: 'kendo-numerictextbox[factor]'
})
export class FactorDirective implements OnInit, OnDestroy, AfterViewInit {
	// https://stackblitz.com/github/lucasheight/kendo-angular

	destroy$: Subject<void> = new Subject();
	@Input("factor") fact: any = 1;
	propogateChange: Function = (_: any) => { };

	private isMinMax: boolean = false; // si es true, se valida el rango 0,1
	private newVal: number = 0;
	private _control: NgControl = undefined;

	getValue(): number {
		return this.newVal;
	}

	getControl(): NgControl {
		return this._control;
	}

	constructor(private el: NumericTextBoxComponent, private control: NgControl) {
		this._control = control;
	}

	ngOnInit(): void {
		if (this.fact === 'p') {
			this.fact = 100;
			this.isMinMax = true;
		}

		if (typeof this.fact !== "number") {
			throw "Factor must be a number";
		}

		if (this.fact === 0) {
			throw "Factor must be greater or less than zero";
		}

		this.el.registerOnChange = (fn) => { 
			this.propogateChange = fn;
		} 

		this.newVal = this.el.value || 0;
	}
	@Output() valueChange: EventEmitter<number> = new EventEmitter();
	ngAfterViewInit(): void {		
		this.el.onFocus.pipe(takeUntil(this.destroy$)).subscribe(s => {
			this.el.value = parseFloat((this.el.value * this.fact).toFixed(2)); 
			this.newVal = this.el.value / this.fact; // levantamos el que esta
			this.el.notifyValueChange();
			//console.log("onFocus()", this.newVal);
		});
		this.el.onBlur.pipe(takeUntil(this.destroy$)).subscribe(s => { 
			if (this.isMinMax) {
				if (this.newVal > 1) { this.newVal = 1; }
				if (this.newVal < 0) { this.newVal = 0; }
			}
			this.el.value = this.newVal; 
			this.el.notifyValueChange(); 
			this.control.control.setValue(this.newVal); // Agrego soporte a AngularForm, al setear el valor que se guarda en el model
			//console.log("onBlur()", this.newVal);
		});
		this.el.valueChange.pipe(takeUntil(this.destroy$)).subscribe(s => {
			this.newVal = this.el.value / this.fact;
			this.valueChange.emit(this.newVal);
			this.propogateChange(this.newVal);
			//console.log("valueChanged()", this.newVal);
		});
	}
	ngOnDestroy() {
		this.destroy$.next();
	}
	setValue(): void {
		this.el.blur();
		this.control.control.setValue(this.newVal);
	} 
}
