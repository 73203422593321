import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';  
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { ProductoSanitarioFormato } from '../models/producto-sanitario-formato';
import { NameValueList } from '../interfaces/nvl.interface';
import { ApiResponse } from '../interfaces/api-response.interface';

@Injectable()
export class ProductoSanitarioFormatoService extends GenericoService<ProductoSanitarioFormato> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'productosanitarioformato');
	}

	getList(): Observable<ProductoSanitarioFormato[]> { 
		throw new Error('Not Implemented');
	} 

	create(_obj: ProductoSanitarioFormato): Observable<any> {
		throw new Error('Not Implemented');
	}

	update(_id: any, _obj: ProductoSanitarioFormato): Observable<ApiResponse> {
		throw new Error('Not Implemented');
	}

	updateAndGetData(_id: any, _obj: ProductoSanitarioFormato): Observable<any> {
		throw new Error('Not Implemented');
	}

	delete(_id: any): Observable<ApiResponse> {
		throw new Error('Not Implemented');
	}

	getLookupByProductoSanitario(productoSanitarioRef: number): Observable<NameValueList[]> {
		return this._httpClient.post<NameValueList[]>(environment.apiUrl + `/${this._endpoint}/productosanitario`, { ProductoSanitarioRef: productoSanitarioRef }, { headers: this._postHeaders });
	}
}
