import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Dictionary } from 'lodash';
import { ReportConsumo } from '../../../../../models/report-consumo';
import Utils from '../../../../../utils';

@Component({
  selector: 'kt-alimento-chart',
  templateUrl: './alimento-chart.component.html',
	styleUrls: ['./alimento-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlimentoChartComponent implements OnInit {
	public loading: boolean = false;

	public alimentos: AlimentoSerie[] = [];

	public chartData: Dictionary<ReportConsumo[]> = {};

	@Input() public set data(data: Dictionary<ReportConsumo[]>) {
		this.loading = true;
		this.chartData = data;
		this.cd.reattach();

		this.alimentos = [];

		for (let key in this.chartData) {
			let value: ReportConsumo[] = this.chartData[key];

			for (const v of value) {
				let alimento = this.alimentos.find(e => e.Nombre == v.AlimentoNombre);
				if (!alimento) {
					this.alimentos.push(new AlimentoSerie(v.AlimentoNombre))
					alimento = this.alimentos.find(e => e.Nombre == v.AlimentoNombre);
				}

				alimento.Valores.push(v.Valor);
			}
		}

		this.loading = false;
		this.cd.detach();
		this.cd.detectChanges();
	}

	constructor(private cd: ChangeDetectorRef) { }

	ngOnInit() {

	}

	etiquetas(): string[] {
		let dias: string[] = [];

		for (let key in this.chartData) {
			dias.push(Utils.dateToDDMM(new Date(key)));
		}

		return dias;
	}

	titulo(): string {
		return `Consumo de alimentos entre fechas`;
	}
}

export class AlimentoSerie {
	public Nombre: string;
	public Valores: number[];

	constructor(nombre: string) {
		this.Nombre = nombre;
		this.Valores = [];
	}
}
