import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Guid } from 'guid-typescript';
import { map, filter } from 'rxjs/operators';

@Injectable()
export class GuidInterceptor implements HttpInterceptor {
	constructor() { }

	//intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
	//	this.convertGuid(request.body);
	//	return next.handle(request);
	//}

	//convertGuid(body) {
	//	if (body === null || body === undefined || typeof body !== 'object')
	//		return;
	//	for (const key of Object.keys(body)) {
	//		const value = body[key];
	//		if (value instanceof Guid) //Request
	//			body[key] = value.toString();
	//		else if(typeof value === 'object')
	//			this.convertGuid(value);
	//	}
	//}

	//https://medium.com/briebug-blog/3-ways-to-use-angular-http-interceptors-337e1a26fd97
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.convertGuid(request.body, true);
		return next.handle(request).pipe(
			// proceed when there is a response; ignore other events
			filter(event => event instanceof HttpResponse),
			map((event: HttpResponse<any>) => {
				const body = event.body;
				this.convertGuid(body, false);
				return event;
			}))
	}

	convertGuid(body, isRequest: boolean) {
		if (body === null || body === undefined || typeof body !== 'object')
			return;
		for (const key of Object.keys(body)) {
			const value = body[key];
			if (value) {
				//No cambiar el orden
				if (isRequest && value instanceof Guid) //Request
					body[key] = value.toString();
				else if (!isRequest && typeof value === 'string' && Guid.isGuid(value)) //Response
					body[key] = Guid.parse(value);
				else if (value instanceof Array)
					this.convertGuid(value, isRequest);
				else if (typeof value === 'object' && ! (value instanceof Guid)) //Ver como solucionar que no entren las fechas
					this.convertGuid(value, isRequest);
			}
		}
	}

}


