<div *ngIf="alertShowing" class="alert fade show alert-{{type}}" role="alert">
	<div class="alert-icon">
		<i class="fas fa-{{icon}}"></i>
	</div>
	<div class="alert-text">
		<ng-content></ng-content>
	</div>
	<div class="alert-close pointer text-white" (click)="closeAlert()" *ngIf="showCloseButton"> 
		<i title="Cancelar" class="{{'Cancelar' | parseIconAccion }}"></i> 
	</div>
</div>
