import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';
import { Compra } from '../models/compra';

@Injectable()
export class CompraService extends GenericoService<Compra> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'compra');
	}
}

