import { DTOInterface } from "../interfaces/dto.interface";
import { Guid } from "guid-typescript"; 
import { VentaTransporteDTE } from "./venta-transporte-dte";

export class VentaTransporte implements DTOInterface { 
	TransporteID: Guid;
	VentaRef: Guid;
	Fecha: Date;
	DTEProveedorTransporte: string;
	GastoFlete: number;

	DTEs: VentaTransporteDTE[];

	constructor() {
		this.clear();
	}

	clear(): void {
		this.TransporteID = Guid.createEmpty();
		this.VentaRef = Guid.createEmpty();
        this.Fecha = new Date(); 
		this.DTEProveedorTransporte = ''; 
		this.GastoFlete = 0;

		this.DTEs = [];
	}
}
