import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { MixerCarga } from '../../../../../models/mixer-carga';
import { MixerCargaService } from '../../../../../services/mixer-carga.service';
import Utils from '../../../../../utils';

@Component({
    selector: 'kt-carga-list',
    templateUrl: './carga-list.component.html',
    styleUrls: ['./carga-list.component.scss'],
    providers: [MixerCargaService]
})
export class CargaListComponent implements OnInit {
    public showGrid: boolean = false;
    public cargas: MixerCarga[];
    @Input() buscando: boolean = false;
    @Input() tipoReporte: number = 1;

    totalPropuesto: number = 0;
    totalCargado: number = 0;
    totalDescargado: number = 0;

    @Input() public set Cargas(data: MixerCarga[]) {
        this.totalPropuesto = 0;
        this.totalCargado = 0;
        this.totalDescargado = 0;
        this.cargas = data;

        if (this.cargas) {
            for (const item of this.cargas) {
                this.totalPropuesto += (+item.CantidadPropuestaKg);
                this.totalCargado += (+item.CantidadRealKg);
                this.totalDescargado += (+item.CantidadDescargada);
            }
            this.showGrid = true;
        }
       this.cd.detectChanges();
    }

    constructor(private cd: ChangeDetectorRef) { }

    ngOnInit() { }

	getExactitud(item: MixerCarga) {
		return Utils.getCargaExactitud(item.CantidadPropuestaKg, item.CantidadRealKg);
	}
}
