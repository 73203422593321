import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import Utils from '../../../../../utils';
import { CommonControl } from '../common-controls.interface';

@Component({
	selector: 'my-col-switch',
	templateUrl: './my-col-switch.component.html',
	styleUrls: ['./my-col-switch.component.scss']
})
export class MyColSwitchComponent extends CommonControl implements OnInit {
	@Input() hasInfo: boolean = false;
	@Input() onlyRow: boolean = false;
	@Input() tooltipMessage: string = '';
	@Input() tooltipTitle: string = '';

	constructor() {
		super();
		this.Form = new UntypedFormGroup({ 'Value': new UntypedFormControl('false', Validators.required) });
	}

	ngOnInit() { }

	getPrincipalControl(): AbstractControl {
		return this.Form.controls['Value'];
	}

	setValue(value: boolean) {
		this.Form.patchValue({ Value: value });
	}

	getValue(): boolean {
		return this.Form.value['Value'];
	}
}
