import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';  
import { Observable } from 'rxjs';
import { NameValueList } from '../interfaces/nvl.interface';
import { environment } from '../environments/environment.prod';
import { MovAlimentoTipo } from '../models/mov-alimento-tipo';


@Injectable()
export class MovAlimentoTipoService extends GenericoService<MovAlimentoTipo> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'movalimentotipo');
	}

	getEntradas(): Observable<NameValueList[]> {
		return this._httpClient.get<NameValueList[]>(environment.apiUrl + `/${this._endpoint}/entradas`, { headers: this._getHeaders });
	}

	getSalidas(): Observable<NameValueList[]> {
		return this._httpClient.get<NameValueList[]>(environment.apiUrl + `/${this._endpoint}/salidas`, { headers: this._getHeaders });
	}

	getPadres(): Observable<NameValueList[]> {
		return this._httpClient.get<NameValueList[]>(environment.apiUrl + `/${this._endpoint}/padres`, { headers: this._getHeaders });
	}
}
