<div class="col">
	<div class="form-group" [formGroup]="Form">
		<label class="pb-1">{{ labelName }}</label>
		<div>
			<kendo-datepicker formControlName="Fecha"
							  #MyControl
							  tabindex="{{tabindex}}"
							  [min]="minDate"
							  [max]="maxDate"
							  [format]="'dd/MM/yyyy'"
							  (valueChange)="valueChange($event)"
							  (blur)="onBlur()">
			</kendo-datepicker>
		</div>
	</div>
</div>
