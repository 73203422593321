import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { OffCanvasInterface } from '../../../../../interfaces/off-canvas.interface';
import { EditableForm } from '../../common_controls/common-controls.interface';
import { IOpenInDialog, IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessengerService } from '../../../../../services/messenger.service';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { MyColDropDownListComponent, MyColInputComponent, MyColTextAreaComponent } from '../../common_controls';
import Utils, { MovProductoSanitarioTipo } from '../../../../../utils';
import { MovProductoSanitario } from '../../../../../models/mov-producto-sanitario';
import { ProductoSanitarioService } from '../../../../../services/producto-sanitario.service';
import { ProductoSanitarioFormatoService } from '../../../../../services/producto-sanitario-formato.service'; 
import { ParseNumberPipe } from '../../../../../pipes/parse-number.pipe';
import { ProductoSanitario } from '../../../../../models/producto-sanitario';
import { ProductoSanitarioFormato } from '../../../../../models/producto-sanitario-formato';

@Component({
	selector: 'kt-movproductosanitario-compra-producto',
	templateUrl: './movproductosanitario-compra-producto.component.html',
	styleUrls: ['./movproductosanitario-compra-producto.component.scss'],
	providers: [ProductoSanitarioService, ProductoSanitarioFormatoService]
})
export class MovProductoSanitarioCompraProductoComponent extends EditableForm<MovProductoSanitario> implements AfterViewInit, OnInit, IOpenInDialog, OffCanvasInterface {

	conversion: number = 0; 
	modelIsNew: boolean = true;
	public componente: MovProductoSanitarioCompraProductoComponent = this; 
	public hint: string = '';
	private producto: ProductoSanitario = undefined;

	@Input() tabindex: number = 500;
	@ViewChild('Producto', { static: true }) Producto: MyColDropDownListComponent;
	@ViewChild('FormatoCompra', { static: true }) FormatoCompra: MyColDropDownListComponent;
	@ViewChild('Precio', { static: true }) Precio: MyColInputComponent;
	@ViewChild('Cantidad', { static: true }) Cantidad: MyColInputComponent; 
	@ViewChild(MyColTextAreaComponent, { static: true }) Observaciones: MyColTextAreaComponent;

	constructor(
		private productoSanitarioService: ProductoSanitarioService,
		private productoSanitarioFormatoService: ProductoSanitarioFormatoService,
		cd: ChangeDetectorRef,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		quickPanelService: QuickPanelService) {
		super(cd, undefined, snackBar, messengerService, quickPanelService);
	}

	setOffCanvasData(data: any, extras: any): void {
		setTimeout(() => {
			this.modelIsNew = (!data || data.MovProductoSanitarioID.isEmpty());
			this.lstControls = [this.Producto, this.FormatoCompra, this.Cantidad, this.Precio, this.Observaciones];
			this.addCustomValidators();

			this.setModel(data);
		}, 0);
	}

	onProductoSave(e: any) {
		if (!this.runValidaciones()) {
			return false;
		}

		this.model.ProductoSanitarioNombre = this.Producto.getItem().Value;
		this.model.ProductoSanitarioRef = this.Producto.getValue();
		this.model.CantidadFormatoCompra = this.Cantidad.getValue();
		this.model.CantidadUM = Utils.checkNaNGetZero((this.Cantidad.getValue() * this.conversion));
		this.model.PrecioTotal = this.Precio.getValue();
		this.model.Observaciones = this.Observaciones.getText();
		this.model.ProductoSanitarioFormatoRef = this.FormatoCompra.getItem().Key;

		this.model.ProductoSanitarioFormatoNombre = this.FormatoCompra.getItem().Value;
		this.model.FactorConversionUM = this.conversion;

		if (this.model.MovProductoSanitarioID.isEmpty()) {
			// nuevo
			// lo setea CSLA en los Pre-Hooks
			this.model.Grupo = undefined;
			this.model.FechaMovimiento = this.model.FechaCompra;
			this.model.FechaCreacion = new Date();
			this.model.MovProductoSanitarioTipoRef = MovProductoSanitarioTipo.EntradaCompra;
		}

		this.getMessengerInstance().broadcast(undefined, undefined, 'MovProductoSanitarioCompraProducto', this.model);
		this.getQuickPanelInstance().hideQuickPanel();
	}

	onProductoCancel(e: any) {
		this.getQuickPanelInstance().hideQuickPanel();
	}

	ngOnInit() {
		this.Producto.initializeCustomControlLookUp(this.productoSanitarioService.getLookup(), this.cd, 'Seleccione un Producto...');

		this.FormatoCompra.initializeCustomControlLookUp(this.productoSanitarioFormatoService.getLookup(), this.cd, 'Seleccione un Formato...');
		this.FormatoCompra.enableControl(false);

		this.Cantidad.setParent(this);
	}

	ngAfterViewInit() {

	}

	getNewModel(): MovProductoSanitario { return new MovProductoSanitario(); };

	onDropDownValueChanged(item, key): void {
		if (key === 'Producto') {
			if (this.Producto.getValue() > 0) {
				this.cargarFormatos();
			} else {
				this.hint = '';
				this.conversion = 0;
				this.FormatoCompra.enableControl(false);
			}
		}
		if (key === 'Formato') {
			this.hint = '';
			this.cargarFormato(this.producto.Formatos.find(e => e.ProductoSanitarioFormatoID === this.FormatoCompra.getValue()));
		}
	}

	private convertir(): void {
		this.hint = '';
		if (this.producto.UnidadMedidaNombre != 'Dosis') { // buscar una alternativa para no hardcodear esto
			this.hint = `Cantidad Total: ${new ParseNumberPipe().transform(Utils.checkNaNGetZero((this.Cantidad.getValue() * this.conversion)), true)} ${this.producto.UnidadMedidaNombre}`;
		}
		this.cd.detectChanges();
	}

	private cargarFormatos(cargarFormato: boolean = false): void {
		this.FormatoCompra.setValue(0);
		this.hint = '';
		this.FormatoCompra.initializeCustomControlLookUp(this.productoSanitarioFormatoService.getLookupByProductoSanitario(this.Producto.getValue() || 0), this.cd, 'Seleccione un Formato...');
		this.FormatoCompra.enableControl(true);  
		this.getProducto(this.Producto.getValue(), cargarFormato);
	}

	private getProducto(productoID, cargarFormato): void {
		this.productoSanitarioService.getById(productoID).subscribe(
			data => {
				this.producto = data;
				this.model.UnidadMedidaAbrev = data.UnidadMedidaAbrev;

				if (cargarFormato) {
					this.cargarFormato(this.producto.Formatos.find(e => e.ProductoSanitarioFormatoID === this.model.ProductoSanitarioFormatoRef));
				}
			},
			error => {

			},
			() => {
				this.cd.detectChanges();
			}
		);
	}

	private cargarFormato(formato: ProductoSanitarioFormato): void {
		if (formato) {
			this.conversion = formato.FactorConversionUM;

			this.FormatoCompra.enableControl(true);
			if (this.FormatoCompra.getValue() === 0) this.FormatoCompra.setValue(this.model.ProductoSanitarioFormatoRef);
			this.convertir();
		}
	}

	setModelControlValues() {
		if (this.model) {
			this.Producto.setValue(this.model.ProductoSanitarioRef);
			this.Cantidad.setValue(this.model.CantidadFormatoCompra);
			this.conversion = this.model.CantidadUM / this.model.CantidadFormatoCompra;
			this.Precio.setValue(this.model.PrecioTotal);
			this.Observaciones.setText(this.model.Observaciones);
			this.adicionalesVisible = !((this.model.Observaciones || '') === ''); 
			if (this.model.ProductoSanitarioFormatoRef > 0) { this.cargarFormatos(true); }
		}		
	}

	onInputValueChange(newValue, labelName): void { 
		this.convertir(); 
	}

	openInDialog(model: MovProductoSanitario, extras: any) {
		this.openedInDialog = true;
		this.setModel(model);
	}

	addCustomValidators(): void {

	}

	editResultDialog(formParent: IParentOpenInDialog): boolean {
		return false;
	}

	saveResultDialog(formParent: IParentOpenInDialog): boolean {
		if (formParent) {
			if (!this.runValidaciones()) {
				return false;
			}

			formParent.saveResultDialogModel(this.model);
		}
		return true;
	}
}
