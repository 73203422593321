import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { TropaTransporte } from '../../../../../models/tropa-transporte';
import { Tropa } from '../../../../../models/tropa';
import { MyMessageDialogComponent } from '../../common_controls';
import { TropaTransporteComponent } from '../tropa-transporte/tropa-transporte.component';
import { IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { EditableFormList } from '../../common_controls/common-controls.interface';

@Component({
	selector: 'kt-tropa-transporte-list',
	templateUrl: './tropa-transporte-list.component.html',
	styleUrls: ['./tropa-transporte-list.component.scss']
})
export class TropaTransporteListComponent extends EditableFormList<TropaTransporte> implements OnInit, IParentOpenInDialog {

	@ViewChild(MyMessageDialogComponent, { static: true }) public MessageDialog: MyMessageDialogComponent;

	@Input() readonly: boolean = false;
	@Input() tabindex: number = -1;

	private _tropa: Tropa;
	@Input()
	set tropa(item: Tropa) {
		this._tropa = item;
		this.datos = (this._tropa) ? this._tropa.Transportes : [];
		this.buscando = false;
		this.readonly = (this._tropa) ? !this._tropa.TropaID.isEmpty() : false;
		this.cd.detectChanges();
	}
	get tropa(): Tropa { return this._tropa; }

	constructor(cd: ChangeDetectorRef) { super(cd, undefined) }

	ngOnInit() { }

	getKeyModel(obj: TropaTransporte): any { return obj.TransporteID; }

	public removeHandler({ dataItem }) { this.confirmDelete(dataItem); }

	isNewModel: boolean = false;
	public showABM({ dataItem }) {
		this.isNewModel = dataItem == undefined;
		let title = ((this.isNewModel) ? "Agregar" : "Editar") + " Transporte";
		if (this.readonly) { title = 'Ver Transporte' }
		this.MessageDialog.showComponentDialog(title, TropaTransporteComponent, this, dataItem, { MaxDate: this._tropa.FechaIngreso, ReadOnly: this.readonly, HideSaveButton: this.readonly });
	}

	public saveResultDialogModel(model: TropaTransporte) {
		if (this.isNewModel) {
			this._tropa.Transportes.push(model);
		}
		else {
			let item = this._tropa.Transportes.find(x => x.TransporteID == model.TransporteID);
			if (item) { Object.assign(item, model); }
		}
		this.cd.detectChanges();
	}
}
