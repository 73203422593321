import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { GridComponent, DataBindingDirective, RowClassArgs } from '@progress/kendo-angular-grid';
import { DietaPlanificacionDescarga } from '../../../../../models/dieta-planificacion-descarga';
import { Dieta } from '../../../../../models/dieta';
import { MyMessageDialogComponent } from '../../common_controls';

const createFormGroup = dataItem => new UntypedFormGroup({
	'Orden': new UntypedFormControl(dataItem.Orden, Validators.compose([Validators.required])),
	'Porcentaje': new UntypedFormControl(dataItem.Porcentaje, Validators.compose([Validators.required]))
});

@Component({
	selector: 'kt-dieta-orden',
	templateUrl: './dieta-orden.component.html',
	styleUrls: ['./dieta-orden.component.scss']
})
export class DietaOrdenComponent implements OnInit {
	public gridData: any[] = [];

	@ViewChild(GridComponent, { static: true }) private grid;
	@ViewChild(DataBindingDirective, { static: true }) dataBinding: DataBindingDirective;
	@ViewChild(MyMessageDialogComponent, { static: true }) public MessageDialog: MyMessageDialogComponent;

	public formGroup: UntypedFormGroup;
	public editedRowIndex: number;
	public onAddRow: boolean;
	public dataItem: DietaPlanificacionDescarga;
	public deletedItem: DietaPlanificacionDescarga;
	public totalPorcentaje: number = 0;

	public _dieta: Dieta;

	public cargando: boolean;

	@Input() set dieta(model: Dieta) {
		this.gridData = [];
		this._dieta = model;
		if (this._dieta) {
			this.cargarLista();
		}
	}

	resetModel(): void {

	}

	constructor(private cd: ChangeDetectorRef) { }

	ngOnInit() {
		this.cargando = false;
		this.totalPorcentaje = 0;
	}

	onKeydown(sender: any, e: any) {
		if (e.key === 'Escape') {
			e.preventDefault();
			this.closeEditor();
			return;
		}

		if (e.key === 'Enter') {
			e.preventDefault();
			this.saveHandler({ sender: sender, rowIndex: this.editedRowIndex, formGroup: this.formGroup, isNew: this.onAddRow });
			return;
		}
	}

	cargarLista(): void {
		if (!this._dieta) { return; }

		this.grid.loading = true; this.cargando = true;
		this.gridData = [];
		let _data: any[] = this._dieta.PlanificacionDescargas;

		this.gridData = _data;
		this.dataBinding.skip = 0;
		this.grid.loading = false; this.cargando = false;

		this.resetGrid();
	}


	onPorcChange(event: any, dataItem: any): boolean {
		if (event.key == "Enter" || event.type == "focusout") {
			dataItem.Porcentaje = event.target.value;
			this.dataItem = dataItem;
			this.actualizar();
		}

		return true;
	}

	cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
		if (this.onAddRow) { return; }

		this.closeEditor(sender);
		this.formGroup = createFormGroup(dataItem);
		this.editedRowIndex = rowIndex;
		sender.editCell(rowIndex, columnIndex, this.formGroup);
		this.cd.detectChanges();
	}

	closeEditor(grid = this.grid, rowIndex = this.editedRowIndex) {
		grid.closeRow(rowIndex);
		this.editedRowIndex = undefined;
		this.formGroup = undefined;
		this.dataItem = undefined;
		this.deletedItem = undefined;
		this.onAddRow = false;
		this.cd.detectChanges();
	}

	addHandler({ sender, formGroup }) {
		sender = sender || this.grid;

		this.closeEditor(sender);

		let proximoOrdenNumero = 0;
		if (this.gridData) { 
			proximoOrdenNumero = Math.max.apply(Math, this.gridData.map(function (o) { return o.Orden; })) || 0; 
		}  
		proximoOrdenNumero++;

		if (isFinite(proximoOrdenNumero) == false) {
			proximoOrdenNumero = 1;
		}

		let porcRestante: number = 100;

		for (const d of this._dieta.PlanificacionDescargas) {
			porcRestante -= d.Porcentaje;
		}

		// crear directamnte uno con el % faltante
		this.dataItem = new DietaPlanificacionDescarga();
		this.dataItem.Porcentaje = porcRestante;
		this.dataItem.Orden = proximoOrdenNumero;
		this._dieta.PlanificacionDescargas.push(this.dataItem);
		this.actualizar(); 
	}

	total(): void {
		this.totalPorcentaje = 0;

		for (const d of this.gridData) {
			this.totalPorcentaje += Math.ceil(d.Porcentaje);
		}
	}

	cancelHandler({ sender, rowIndex }) {
		this.closeEditor(sender, rowIndex);
	}

	saveHandler({ sender, rowIndex, formGroup, isNew }) {
		if (formGroup.valid) {
			if (isNew) {
				this.create({ sender, rowIndex, formGroup, isNew });
			} else {
				this.update({ sender, rowIndex, formGroup, isNew });
			}
		} else {
			sender.editRow(rowIndex);
			this.cd.detectChanges();
		}
	}

	actualizar(): void {
		if (this.dataItem) {
			let ms: number = +(this.dataItem.Porcentaje);
			if (ms > 100) { this.dataItem.Porcentaje = 100; }
			if (ms < 0) { this.dataItem.Porcentaje = 0; }

			this.dataItem.Porcentaje = parseFloat((+this.dataItem.Porcentaje).toFixed(2)); 
		}

		this.resetGrid();
	}

	resetGrid(): void {
		this.closeEditor();

		this.grid.loading = true;

		this.gridData.sort(function (a, b) {
			// ordenar por Orden
			if (a.Orden > b.Orden) return 1;
			if (a.Orden < b.Orden) return -1;
		});

		this.grid.loading = false;

		this.total();

		this.cd.detectChanges();
	}

	update({ sender, rowIndex, formGroup, isNew }): void {
		this.assignValues(this.dataItem, formGroup.value);
		this.actualizar();
	}

	create({ sender, rowIndex, formGroup, isNew }): void {
		this.dataItem = new DietaPlanificacionDescarga();
		this.assignValues(this.dataItem, formGroup.value);
		this._dieta.PlanificacionDescargas.push(this.dataItem);
		this.actualizar();
	}

	menosMS(dataItem: any): void {
		this.dataItem = dataItem;
		this.dataItem.Porcentaje -= (+10);
		this.actualizar();
	}

	masMS(dataItem: any): void {
		this.dataItem = dataItem;
		this.dataItem.Porcentaje += (+10);
		this.actualizar();
	}

	editHandler({ sender, colIndex, rowIndex, dataItem }) {
		this.closeEditor(sender);

		this.dataItem = dataItem;
		this.formGroup = createFormGroup(dataItem);

		this.editedRowIndex = rowIndex;

		sender.editRow(rowIndex, this.formGroup);

		setTimeout(() => {
			colIndex = colIndex || 0;

			let element: HTMLElement = (<HTMLElement>document.querySelector(`.k-grid-edit-row > td:nth-child(${colIndex + 1}) input`));

			if (element) {
				element.focus();
			}
		});
	}

	editClick({ dataItem, rowIndex, columnIndex }: any): void {
		this.editHandler({
			dataItem: dataItem,
			rowIndex: rowIndex,
			colIndex: columnIndex,
			sender: this.grid
		});
	}

	removeHandler({ sender, dataItem }) {
		sender.cancelCell();

		// quitar de la lista
		this.deletedItem = dataItem;
		this.MessageDialog.showDialog("Por favor confirme", "Esta acción eliminará el registro. ¿Está seguro que desea continuar?",
			["SI", "NO"], "DeleteModel", this);
	}

	onConfirmResultMessageDialog(id: string, ok: boolean): void {
		if (ok) {
			if (id === "DeleteModel") {
				this.gridData.splice(this.gridData.indexOf(this.deletedItem), 1);
			}
		}

		this.resetGrid();
	}

	assignValues(target: any, source: any): void {
		Object.assign(target, source);
	}

	rowCallback = (context: RowClassArgs) => {
		return { inactiva: !context.dataItem.Activo };
	}
}
