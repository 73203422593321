import { Component, OnInit, ChangeDetectorRef, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MyColInputComponent } from '../col-input/my-col-input.component';   
import { MessengerService } from '../../../../../services/messenger.service'; 
import Utils from '../../../../../utils';
import { MyColLabelInfoComponent } from '../col-labelinfo/my-col-labelinfo.component';
import { ParseNumberKgPipe } from '../../../../../pipes/parse-number-kg.pipe';

@Component({
	selector: 'kt-ingreso-hacienda',
	templateUrl: './ingreso-hacienda.component.html',
	styleUrls: ['./ingreso-hacienda.component.scss']
})
export class IngresoHaciendaComponent implements OnInit, AfterViewInit {
	public pesoTropa: boolean = false;
	public precioTropa: boolean = false;

	@Input() public parent: any = this;
	@Input() public titlePrecio: string = 'Precio Compra';
	@Input() public titlePeso: string = 'Peso';
	@Input() public edicion: boolean = false;
	@Input() public tabindex: number = -1;

	_parent: IngresoHaciendaComponent = this;

	private calculando: boolean = false;

	@ViewChild('Precio', { static: true }) public PrecioInput: MyColInputComponent;
	@ViewChild('Peso', { static: true }) public PesoInput: MyColInputComponent;
	@ViewChild('Desbaste', { static: true }) public DesbasteInput: MyColInputComponent;
	@ViewChild('Neto', { static: true }) public NetoSpan: ElementRef;

	constructor(private cd: ChangeDetectorRef, private messengerService: MessengerService) { }

	ngOnInit() {

	}

	ngAfterViewInit() {
		this.PesoInput.setParent(this._parent);
		this.DesbasteInput.setParent(this._parent);

		this.initializeControl();
	}

	public initializeControl(): void {
		this.PrecioInput.setValue(0);
		this.PesoInput.setValue(0); 
		this.DesbasteInput.setValue(0);
		this.NetoSpan.nativeElement.innerHTML = new ParseNumberKgPipe().transform(0);

		this.precioTropa = false;
		this.pesoTropa = false;

		this.cd.detectChanges();
	}

	public getPrecioValue(): number {
		return Utils.checkNaNGetZero(this.PrecioInput.getValue());
	}

	public getPesoValue(): number {
		return Utils.checkNaNGetZero(this.PesoInput.getValue());
	}

	public getDesbasteValue(): number {
		return Utils.checkNaNGetZero(this.DesbasteInput.getValue());
	}

	actualizarPesoFinal() {
		this.calculando = true;

		let cabezaKg: number = this.PesoInput.getValue() || 0; 
		let desbaste: number = this.DesbasteInput.getValue() || 0;

		let c = Utils.checkNaNGetZero(cabezaKg - (cabezaKg * desbaste));

		if (c < 0) { // cuando se tipea, el evento se envia en numeros de 0 a 100, pero si da negativo es porque viene de 0 a 1
			cabezaKg = Utils.checkNaNGetZero(cabezaKg - (cabezaKg * (desbaste / 100)));
		} else {
			cabezaKg = c;
		}
		 
		// el total es por cabeza y sin desbaste
		this.NetoSpan.nativeElement.innerHTML = new ParseNumberKgPipe().transform(cabezaKg, true);

		this.cd.detectChanges();

		this.calculando = false;
	}

	onPesoChange(event: any): void {
		this.pesoTropa = event;
		if (!event) {
			this.PesoInput.setValue(0);
			this.DesbasteInput.setValue(0);
		}
		this.notify(event, 'peso');
		this.cd.detectChanges();
	}

	onPrecioChange(event: any): void {
		this.precioTropa = event;
		if (!event) {
			this.PrecioInput.setValue(0);
		}
		this.notify(event, 'precio');
		this.cd.detectChanges();
	}

	onInputValueChange(newValue, labelName): void { 
		if (!this.calculando) {
			setTimeout(() => {
				this.actualizarPesoFinal();
			});
		} 
	}

	private notify(value: boolean, reason: string): void {
		
		this.messengerService.broadcast('IngresoHaciendaComponent', 'TropaCrudComponent', reason, { value: value });
	}
}
