import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { TropaOrigenService } from '../../../../../services/tropa-origen.service';
import { ClienteService } from '../../../../../services/cliente.service';
import { ProveedorService } from '../../../../../services/proveedor.service';
import { TropaService } from '../../../../../services/tropa.service';
import { Tropa } from '../../../../../models/tropa';
import { Cliente } from '../../../../../models/cliente';
import { Proveedor } from '../../../../../models/proveedor';
import { TropaOrigen } from '../../../../../models/tropa-origen';
import { MyColTimePickerComponent, MyColDateTimePickerComponent, MyColInputComponent, MyColDropDownListComponent, MyColColorPickerComponent } from '../../common_controls';
import { ValidationControlInterface } from '../../common_controls/common-controls.interface';
import Utils, { ProveedorTipo } from '../../../../../utils';
import { ParseCodigoTropaPipe } from '../../../../../pipes/parse-codigo-tropa.pipe';
import { MessengerService } from '../../../../../services/messenger.service'; 
import { BroadcastMessage } from '../../../../../interfaces/message.interface';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'kt-tropa',
	templateUrl: './tropa.component.html',
	styleUrls: ['./tropa.component.scss'],
	providers: [
		TropaOrigenService,
		ClienteService,
		TropaService,
		ProveedorService
	]
})
export class TropaComponent implements OnInit, OnDestroy {
	private destroy$: Subject<void> = new Subject<void>();

	component: TropaComponent = this;

	codigo: string = new ParseCodigoTropaPipe().transform('--');
	@ViewChild('Nombre', { static: true }) Nombre: MyColInputComponent;
	@ViewChild('Color', { static: true }) Color: MyColColorPickerComponent;
	@ViewChild(MyColDateTimePickerComponent, { static: true }) Fecha: MyColDateTimePickerComponent;
	@ViewChild(MyColTimePickerComponent, { static: true }) Hora: MyColTimePickerComponent;
	@ViewChild('Cliente', { static: true }) Cliente: MyColDropDownListComponent;
	@ViewChild('Proveedor', { static: true }) Proveedor: MyColDropDownListComponent;
	@ViewChild('OrigenTropa', { static: true }) OrigenTropa: MyColDropDownListComponent;
	precioCompra: string = '';
	@ViewChild('Marca', { static: true }) Marca: MyColInputComponent;
	@ViewChild('Gastos', { static: true }) Gastos: MyColInputComponent;

	lstControls: ValidationControlInterface[];

	@Input() readonly: boolean = false;
	@Input() tabindex: number = -1;
	@Input() isnew: boolean = true;

	public _tropa: Tropa;
	@Input() set tropa(model: Tropa) {
		this._tropa = model;
		if (this._tropa)
			this.resetModel();
	}

	constructor(private cd: ChangeDetectorRef,
		private tropaOrigenService: TropaOrigenService,
		private clienteService: ClienteService,
		private messengerService: MessengerService,
		private proveedorService: ProveedorService) {
		this._tropa = undefined;
	}

	ngOnDestroy() {
		this.destroy$.next();
	}

	ngOnInit() {
		this.lstControls = [this.Fecha, this.Hora, this.Nombre, this.Color,
		this.Proveedor, this.OrigenTropa, this.Cliente, /*this.Muertos,*/ this.Marca, this.Gastos];

		this.Cliente.initializeControl(this.clienteService, this.cd, 'Seleccione un Propietario');
		this.Proveedor.initializeCustomControlLookUp(this.proveedorService.getLookupByTipo(ProveedorTipo.Hacienda), this.cd, 'Seleccione un Proveedor');		
		this.OrigenTropa.initializeControl(this.tropaOrigenService, this.cd, 'Seleccione un Origen');

		this.messengerService.messageBroadcasted$.pipe(takeUntil(this.destroy$)).subscribe(
			(data: BroadcastMessage) => {
				if (data) {
					if (data.Sender === 'MyColColorPicker' && data.Target === 'TropaComponent' && data.Reason === 'ColorPaletteLoaded') {
						if (this._tropa && this._tropa.CodigoTropa == -1) {
							this.Color.setValue(9);
						}
					}
				}
			}
		);
	}

	resetModel() {
		this.codigo = (this._tropa.CodigoTropa == -1) ? new ParseCodigoTropaPipe().transform('--') : new ParseCodigoTropaPipe().transform(this._tropa.CodigoTropa);
		this.Nombre.setValue(this._tropa.TropaNombre);
		this.Color.setValue(this._tropa.ColorRef); 
		this.Fecha.setFecha(new Date(this._tropa.FechaIngreso));
		this.Hora.setHora(this._tropa.FechaIngreso);
		this.Cliente.setValue(this._tropa.ClienteRef);
		this.Proveedor.setValue(this._tropa.ProveedorRef);
		this.OrigenTropa.setValue(this._tropa.TropaOrigenRef);
		this.precioCompra = Utils.formatNumberDecimal(this._tropa.PrecioCompra);
		this.Marca.setValue(this._tropa.MarcaIdentificatoria);
		this.Gastos.setValue(this._tropa.GastoComercializacion);

		this.Nombre.focus();
	}

	setModelValues(model: Tropa) {
		model.TropaNombre = this.Nombre.getValue();
		model.ColorRef = this.Color.getColor(); 
		model.FechaIngreso = Utils.getDateWithTime(this.Fecha.getFecha(), this.Hora.getHora());
		model.ClienteRef = this.Cliente.getValue();
		model.ProveedorRef = this.Proveedor.getValue();
		model.TropaOrigenRef = this.OrigenTropa.getValue();
		model.MarcaIdentificatoria = this.Marca.getValue();
		model.GastoComercializacion = this.Gastos.getValue();

		if (this.Cliente && this.Cliente.getItem()) { model.ClienteNombre = this.Cliente.getItem().Value; }
		if (this.Proveedor && this.Proveedor.getItem()) { model.ProveedorNombre = this.Proveedor.getItem().Value; }
		if (this.OrigenTropa && this.OrigenTropa.getItem()) { model.TropaOrigenNombre = this.OrigenTropa.getItem().Value; }
	}

	agregarDropDownListItem(text: string, key: string) {
		if (key == 'Hotelero') {
			let cliente: Cliente = new Cliente();
			cliente.ClienteNombre = text;
			this.Cliente.generateAndAddNewObject(cliente, (c) => c.ClienteID, this.clienteService, this.cd); 
		}
		else if (key == 'Proveedor') {
			let prov: Proveedor = new Proveedor();
			prov.ProveedorNombre = text;
			prov.ProveedorTipoRef = ProveedorTipo.Hacienda;
			this.Proveedor.generateAndAddNewObjectCustom(prov, (p) => p.ProveedorID, this.proveedorService,
				this.proveedorService.getLookupByTipo(ProveedorTipo.Hacienda), this.cd); 
		}
		else if (key == 'OrigenTropa') {
			let origen: TropaOrigen = new TropaOrigen();
			origen.TropaOrigenNombre = text;
			this.OrigenTropa.generateAndAddNewObject(origen, (o) => o.TropaOrigenID, this.tropaOrigenService, this.cd); 
		}
	}
}
