import { Pipe, PipeTransform } from '@angular/core'; 

@Pipe({
    name: 'parseCodigoTropa'
})
export class ParseCodigoTropaPipe implements PipeTransform {

	transform(e: any): string {
		return `T${e}`;
    }

}
