import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { UsuarioService } from '../../../../../services/usuario.service';
import Utils, { Roles } from '../../../../../utils';
import { Usuario } from '../../../../../models/usuario';
import { AutenticacionService } from '../../../../../services/autenticacion.service';
import { debug } from 'console';

@Component({
    selector: 'seleccion-mixero',
    templateUrl: './seleccion-mixero.component.html',
    styleUrls: ['./seleccion-mixero.component.scss'],
    providers: [UsuarioService]
})

export class SeleccionMixeroComponent implements OnInit {
    @Input() parent: any = undefined;
    @Input() labelName: string = 'Mixero';
    defaultMixero: number = undefined;
    mixeros: Usuario[] = [];
    userNameSelected: string = '';

	constructor(private usuarioService: UsuarioService, private cd: ChangeDetectorRef, private auth: AutenticacionService) { }

	ngOnInit() {
		this.setMixeroList();
    }

    setMixero(id: number): void {
        this.defaultMixero = id;
        if (this.mixeros) {
            let mixero = this.mixeros.find(o => o.UsuarioID === id);
            if (mixero) {
                this.userNameSelected = `${mixero.UsuarioNombre} ${mixero.UsuarioApellido}`;
                this.selectedChange(true, mixero);
            }
        }
	}

	setMixeroList(): void {
		if (this.auth.tieneRole(Roles[Roles.Mixero]) && !this.auth.tieneRole(Roles[Roles.Administrador]))
		{
			this.auth.getUserByToken().subscribe(data => {
				this.mixeros.push(data);
				this.setMixero(data.UsuarioID);
			},
			error => { }, () => {
				this.setDefaultMixero();
			});;
		}
		else
		{
			this.usuarioService.getListByRole(Roles.Mixero).subscribe(data => {
				this.mixeros = data;
			},
			error => { }, () => {
				this.setDefaultMixero();
			});
		}
	}

	setDefaultMixero() {
		if (this.defaultMixero)
			this.setMixero(this.defaultMixero);
		this.cd.detectChanges();
	}

    public selectedChange(selec, user: Usuario) {
		this.userNameSelected = `${user.UsuarioNombre} ${user.UsuarioApellido}`;
        if (!selec || !this.parent || !this.parent.mixeroSeleccionado) return;
        this.parent.mixeroSeleccionado(user);
	}
}
