<!-- begin::Offcanvas Toolbar Quick Actions -->
	<div #QuickPanel focusFirstInput ktOffcanvas [options]="offcanvasOptions"
		 id="kt_quick_panel" class="kt-quick-panel" [ngClass]="{'k-loading-image': loading}" [ngStyle]="{'overflow': 'hidden'}">

		<a class="kt-quick-panel__close" href="javascript:;" id="kt_quick_panel_close_btn">
			<i class="flaticon2-delete"></i>
		</a>

		<div class="kt-quick-panel__nav">
			<ul class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-sigef kt-notification-item-padding-x" kttabclickevent="" role="tablist">
				<li class="nav-item">
					<a class="nav-link active" href="javascript:;" role="tab">{{title}}</a>
				</li>
			</ul>
		</div>

		<div class="kt-quick-panel__content">
			<ng-template ComponentHost></ng-template>
		</div>
	</div>
<!-- end::Offcanvas Toolbar Quick Actions -->
