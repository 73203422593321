<div ngbDropdown placement="bottom-right" *ngIf="user" class="kt-header__topbar-item kt-header__topbar-item--user">
	<div ngbDropdownToggle class="kt-header__topbar-wrapper">
		<div class="kt-header__topbar-user">
			<span style="color: black;" class="kt-header__topbar-username kt-hidden-mobile" *ngIf="showHi">{{user.UsuarioNombre}} {{user.UsuarioApellido}}</span>
			<kt-avatar-photo *ngIf="showAvatar" alt="Imagen de Perfil" [title]="user.UsuarioNombre + ' ' + user.UsuarioApellido" [photoUrl]="user.ImagenPerfil" [name]="user.UsuarioNombre + ' ' + user.UsuarioApellido"></kt-avatar-photo>			
			<span *ngIf="showBadge" class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">{{user.UsuarioApellido|firstLetter}}</span>
		</div>
	</div>
	<div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
		<!--begin: Head -->
		<div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x" style="background-image: url(./assets/media/misc/bg-2.jpg)">
			<div class="kt-user-card__avatar">
				<kt-avatar-photo alt="Imagen de Perfil" [title]="user.UsuarioNombre + ' ' + user.UsuarioApellido" [photoUrl]="user.ImagenPerfil" [name]="user.UsuarioNombre + ' ' + user.UsuarioApellido"></kt-avatar-photo>
				<span [hidden]="true" class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">{{ user.UsuarioApellido | firstLetter }}</span>
			</div>
			<div class="kt-user-card__name">
				{{user.UsuarioNombre}} {{user.UsuarioApellido}}
			</div>
		</div>
		<!--end: Head -->

		<!--begin: Navigation -->
		<div class="kt-notification">
			<div class="kt-notification__custom">
				<a href="javascript:;" (click)="editarPerfil()" class="btn btn-sigef-secondary btn-upper btn-sm btn-bold mr-2">Editar perfil</a>
				<a href="javascript:;" (click)="logout()" class="btn btn-sigef-secondary btn-upper btn-sm btn-bold">Cerrar Sesión</a>
			</div>
		</div>
		<!--end: Navigation -->

		<!--begin: Navigation(alternative) -->
		<ul class="kt-nav kt-margin-b-10 kt-hidden">
			<li class="kt-nav__item kt-nav__item--custom kt-margin-t-15">
				<a (click)="logout()" class="btn btn-sigef-secondary btn-upper btn-sm btn-bold">Cerrar Sesión</a>
			</li>
		</ul>
		<!--end: Navigation(alternative) -->		
	</div>
</div>
