// Angular
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
// NgBootstrap
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap'; 
// Core module
import { CoreModule } from '../../core/core.module';
// Sigef partials
import {
    QuickPanelComponent,
    ScrollTopComponent,
    SplashScreenComponent,
    Subheader1Component,
    UserProfileComponent
} from './sigef';
// General
import { PortletModule } from './sigef/portlet/portlet.module';
// Errpr
import { ErrorComponent } from './sigef/error/error.component';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonModule, ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DirectivesModule } from '../../../directives/directives.module';
import { TimePickerModule } from '@progress/kendo-angular-dateinputs';
// Shared
import { SharedModule } from '../pages/shared.module';
// Widgets
import { ChartsModule } from '@progress/kendo-angular-charts';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    declarations: [
        ScrollTopComponent,

        // topbar components 
        QuickPanelComponent,
        ScrollTopComponent,
        SplashScreenComponent,
        Subheader1Component,
        UserProfileComponent,

        ErrorComponent
    ],
    exports: [
        PortletModule,

        ScrollTopComponent,

        // topbar components 
        QuickPanelComponent,
        ScrollTopComponent,
        SplashScreenComponent,
        Subheader1Component,
        UserProfileComponent,

        GridModule,
        ButtonModule,
        ButtonsModule,
        InputsModule,
        DropDownListModule,
		DropDownsModule,
		TimePickerModule,
        ReactiveFormsModule,
        MatTabsModule,
        NgbModule,

        DirectivesModule,
        DragDropModule,
        SharedModule,

		ErrorComponent
	],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        NgbModule, 
        InlineSVGModule,
        CoreModule,
        PortletModule,
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        MatDialogModule,
        GridModule,
        ButtonModule,
        ButtonsModule,
        InputsModule,
        DropDownListModule,
		DropDownsModule,
		TimePickerModule,
        ReactiveFormsModule,

        SharedModule,
        DragDropModule,
        DirectivesModule,
		ChartsModule,

		TranslateModule
    ]
})
export class PartialsModule {
}
