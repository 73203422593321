import { Component, OnInit, Input } from '@angular/core';
import { ProductoSanitario } from '../../../../../models/producto-sanitario';

@Component({
	selector: 'kt-producto-sanitario-list-formato',
	templateUrl: './producto-sanitario-list-formato.component.html',
	styleUrls: ['./producto-sanitario-list-formato.component.scss']
})
export class ProductoSanitarioListFormatoComponent implements OnInit {

	@Input() productoSanitario: ProductoSanitario;

	constructor() { }

	ngOnInit() {
	}

	descripcionColFactorDeConversion(): string {
			return `Equivalente en ${this.productoSanitario.UnidadMedidaNombre}`;

	};

}
