<div class="container-fluid">
	<div class="row">
		<kt-message-info [text]="'El orden y porcentaje de descarga se define para el caso que una dieta se desee repartir en dos o más partes en el mismo día. Por ejemplo: turno mañana 50% y turno tarde 50%.
				Esa información se mostrará como sugerido al mixero cuando deba descargar la dieta en el corral.'"></kt-message-info>
		<div class="col-sm-12 col-md-8 col-lg-7 col-xl-6 mt-4">
			<kendo-grid #grid
						[kendoGridBinding]="gridData"
						(keydown)="onKeydown(grid, $event)"
						[sortable]="true"
						(edit)="editHandler($event)" (cancel)="cancelHandler($event)"
						(save)="saveHandler($event)" (remove)="removeHandler($event)"
						(add)="addHandler($event)"
						(cellClick)="editClick($event)"
						[navigable]="true"
						[loading]="cargando">

				<ng-template kendoGridToolbarTemplate>
					<button class="add-btn btn-sigef" kendoGridAddCommand>Agregar Porcentaje de Descarga</button>
				</ng-template>

				<kendo-grid-messages noRecords="{{'CRUD.COMMON.NO_RECORDS' | translate}}">
				</kendo-grid-messages>


				<!-- Orden Col -->
				<kendo-grid-column field="Orden" title="Orden" width="75">
					<ng-template kendoGridCellTemplate let-dataItem>
						<div align="center">
							{{dataItem.Orden}}
						</div>
					</ng-template>
				</kendo-grid-column>

				<!-- Porcentaje Col -->
				<kendo-grid-column field="Porcentaje" width="200">
					<ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
						<div align="center">
							Porcentaje
						</div>
					</ng-template>
					<ng-template kendoGridCellTemplate let-dataItem>
						<div class="input-group k-grid-ignore-click mx-auto" style="width: 80%">
							<div class="input-group-prepend">
								<button (click)="menosMS(dataItem)" style="min-width: 2.5rem" class="btn btn-decrement btn-sigef" type="button">
									<strong>-</strong>
								</button>
							</div>

							<input ktSelectOnClick value="{{dataItem.Porcentaje | parseNumber : true}} %" type="text" (keyup)="onPorcChange($event, dataItem)" (focusout)="onPorcChange($event, dataItem)" style="text-align: center;" class="form-control" placeholder="">

							<div class="input-group-append">
								<button (click)="masMS(dataItem)" style="min-width: 2.5rem" class="btn btn-increment btn-sigef" type="button">
									<strong>+</strong>
								</button>
							</div>
						</div>
					</ng-template>
					<ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup" let-isNew="isNew">

					</ng-template>
					<ng-template kendoGridFooterTemplate let-column="column">
						<div align="center">
							<span [ngClass]="{'ms-100': totalPorcentaje === 100, 'ms-no-100': totalPorcentaje != 100 }">{{totalPorcentaje }} %</span>
						</div>
					</ng-template>
				</kendo-grid-column>

				<!-- Acciones Col -->
				<kendo-grid-command-column class="k-grid-ignore-click" title="Acciones">
					<ng-template kendoGridCellTemplate let-isNew="isNew">
						<button kendoGridEditCommand class="btn btn-sm"><i title="Editar" class="{{'Editar' | parseIconAccion }}"></i></button>
						<button kendoGridRemoveCommand class="btn btn-sm k-grid-ignore-click"><i title="Eliminar" class="{{'Eliminar' | parseIconAccion }}"></i></button>
						<button kendoGridSaveCommand class="btn btn-sm"><i title="Guardar" class="{{'Confirmar' | parseIconAccion }}"></i></button>
						<button kendoGridCancelCommand class="btn btn-sm"><i title="Cancelar" class="{{'Cancelar' | parseIconAccion }}"></i></button>
					</ng-template>
				</kendo-grid-command-column>
			</kendo-grid>
		</div>
	</div>
</div>
<my-message-dialog></my-message-dialog>
