import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpResponse, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import moment from 'moment';

@Injectable() export class UtcInterceptor implements HttpInterceptor {

	public re = new RegExp(/^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?/);

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.method === 'POST' || request.method === 'PUT') {
			this.shiftDates(request.body);
		}

		return next.handle(request).map(event => {
			if (event instanceof HttpResponse) {
				event = event.clone({ body: this.shiftDatesResponse(event.body) });
			}
			return event;
		});
	}

	shiftDates(body) {
		if (body === null || body === undefined) {
			return body;
		}

		if (typeof body !== 'object') {
			return body;
		}

		for (const key of Object.keys(body)) {
			const value = body[key];

			if (value instanceof Date || typeof (value) === 'string' && this.re.test(value) && !isNaN(Date.parse(value))) {
				let u = moment.utc(value).format();
				body[key] = u;
			} else if (typeof value === 'object') {
				this.shiftDates(value);
			}
		}
	}

	shiftDatesResponse(body) {
		if (body === null || body === undefined)
			return body;

		for (const key of Object.keys(body)) {
			const value = body[key];

			if (typeof (value) === 'string' && this.re.test(value) && !isNaN(Date.parse(value))) {
				let u = moment.utc(value);
				body[key] = u.local().format();
			} else if (typeof value === 'object') {
				this.shiftDatesResponse(value);
			}
		}
		
	}
}
