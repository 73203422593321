<div class="container">
	<div class="row login__row">
		<div class="col-12 mx-auto">
			<div class="card card-signin my-5">
				<div class="card-body">
					<div class="kt-login__title"></div>

					<kt-auth-notice></kt-auth-notice>

					<form class="form-signin">

						<h6 class="forgot-password forgot-password-desc">El usuario tiene mas de una empresa asociada a su cuenta</h6>

						<label class="pb-1 kt-sigef-login--label">Seleccionar empresa</label>
						<div class="empresas-list">
							<div *ngFor="let empresa of loginData.Empresas;" class="select-empresa d-flex align-items-center" (click)="login(empresa.EmpresaCodigo)" >
								<span>{{empresa.EmpresaNombre}}</span>
							</div>
						</div>
						<div [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}"></div>

						<div class="text-center pt-2" tabindex="-1">
							<button routerLink="/auth/login" [disabled]="loading" id="kt_login_signup_cancel" class="btn btn-sigef-secondary btn-sigef-login mx-2 ">{{ 'AUTH.GENERAL.BACK_BUTTON' | translate }}</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
