import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { TropaTransporte } from '../../../../../models/tropa-transporte';
import { MyColDateTimePickerComponent, MyColInputComponent } from '../../common_controls';
import { EditableForm } from '../../common_controls/common-controls.interface';
import { IOpenInDialog, IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { LayoutUtilsService } from '../../../../core/_base/crud';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessengerService } from '../../../../../services/messenger.service';
import { QuickPanelService } from '../../../../../services/quick-panel.service';

@Component({
	selector: 'kt-tropa-transporte',
	templateUrl: './tropa-transporte.component.html',
	styleUrls: ['./tropa-transporte.component.scss']
})
export class TropaTransporteComponent extends EditableForm<TropaTransporte> implements OnInit, IOpenInDialog {

	@ViewChild(MyColDateTimePickerComponent, { static: true }) Fecha: MyColDateTimePickerComponent;
	@ViewChild('Transportista', { static: true }) Transportista: MyColInputComponent;
	@ViewChild('GastoFlete', { static: true }) GastoFlete: MyColInputComponent;
	@Input() tabindex: number = 85000;

	public readonly: boolean = false;

	constructor(cd: ChangeDetectorRef,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		quickPanelService: QuickPanelService) {
		super(cd, undefined, snackBar, messengerService, quickPanelService);
	}

	ngOnInit() {
		this.lstControls = [this.Fecha, this.Transportista, this.GastoFlete];
	}

	getNewModel(): TropaTransporte { return new TropaTransporte(); };

	setModelControlValues() {
		if (this.model) {
			this.Fecha.setFecha(new Date(this.model.Fecha));
			this.GastoFlete.setValue(this.model.GastoFlete);
			this.Transportista.setValue(this.model.DTEProveedorTransporte);
		}

		this.Fecha.focus();
	}

	openInDialog(model: TropaTransporte, extras: any) {
		this.readonly = extras.ReadOnly || false;
		this.Fecha.maxDate = extras.MaxDate;
		this.openedInDialog = true;
		this.setModel(model);
	}

	editResultDialog(formParent: IParentOpenInDialog): boolean {
		return false;
	}

	saveResultDialog(formParent: IParentOpenInDialog): boolean {
		if (formParent) {
			if (!this.runValidaciones()) {
				return false;
			}
			this.model.Fecha = this.Fecha.getFecha();
			this.model.GastoFlete = this.GastoFlete.getValue();
			this.model.DTEProveedorTransporte = this.Transportista.getValue();
			formParent.saveResultDialogModel(this.model);			
		}
		return true;
	}
}
