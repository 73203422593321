import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'my-row-expand',
	templateUrl: './my-row-expand.component.html',
	styleUrls: ['./my-row-expand.component.scss']
})
export class MyRowExpandComponent implements OnInit {
	@Input() title: string = '';
	@Input() hint: string = '';
	@Input() labelName: string = '';
	@Input() expanded: boolean = false;
	@Input() disabled: boolean = false;
	@Input() tabindex: number = -1;

	@Output() onExpand = new EventEmitter();

	constructor() { }
	ngOnInit() { }

	onClick(): void {
		this.expanded = !this.expanded;
		this.onExpand.emit(this.expanded); 
	}
}
