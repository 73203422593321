import { Component, OnInit, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';
import { CorralInfo } from '../../../../../models/corral-info';
import { CorralService } from '../../../../../services/corral.service';
import { CorralInfoTropa } from '../../../../../models/corral-info-tropa';
import { EditableFormList } from '../../common_controls/common-controls.interface';
import { TropaSeguimientoCorralListComponent } from '../../tropa/tropa-seguimiento-corral-list/tropa-seguimiento-corral-list.component';
import { IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { Subscription } from 'rxjs';
import { MessengerService } from '../../../../../services/messenger.service';
import { BroadcastMessage } from '../../../../../interfaces/message.interface';
import { Router } from '@angular/router';
import { Routing, getRoute } from '../../../themes/sigef/routing';

@Component({
	selector: 'kt-corral-tropa',
	templateUrl: './corral-tropa.component.html',
	styleUrls: ['./corral-tropa.component.scss'],
	providers: [CorralService]
})
export class CorralTropaComponent extends EditableFormList<CorralInfoTropa> implements OnInit, OnDestroy, IParentOpenInDialog {
	saveResultDialogModel(model: any): void {
		// throw new Error("Method not implemented.");
	}

	private sub: Subscription;

	@Input() corral: CorralInfo;
	@Input() parent: TropaSeguimientoCorralListComponent;
	@Input() cliente: number = 0;


	constructor(cd: ChangeDetectorRef, private router: Router, private messengerService: MessengerService, private corralService: CorralService) { super(cd, undefined); }
	 
	ngOnInit() {
		this.buscar();

		this.sub = this.messengerService.messageBroadcasted$.subscribe((data: BroadcastMessage) => {
			if (data.Reason === "editar_tropa") {
				this.parent.abrirTropa(data.Payload.TropaID);
			}
		});
	}

	ngOnDestroy() {
		if (this.sub) { this.sub.unsubscribe(); }
	}


	loadList(): void {
		if (!this.corral) return;
		this.corralService.getInfoByCorral(this.corral.CorralID, this.cliente).subscribe(
			(data: CorralInfo[]) => {
				for (const d of data) {
					if (d.Activo && d.Tropas.length > 0) {
						for (const t of d.Tropas) {
							// como las tropas pueden estar repetidas en un corral (distinto Ganado), solo agregar una instancia de cada una
							//if (!this.datos.find(x => x.TropaRef.toString() === t.TropaRef.toString()))
								this.datos.push(t);
						}
					}
				}
			},
			error => { },
			() => {
				this.finalizarBusqueda();
			}
		);
	}

	abrirTropa(item: any): void { 
		this.router.navigate([getRoute(Routing.TROPAS_REPORTES, true, true), item.TropaRef.toString()]);
	}

	getHaciendaPorTropaEnCorral(dataItem: any): string[] {
		let hacienda: string[] = [];

		for (const d of this.datos) { 
			if (d.CorralID === dataItem.CorralID && d.TropaCategoriaGanadoRef.toString() === dataItem.TropaCategoriaGanadoRef.toString()) { 
				hacienda.push(`${d.CategoriaGanadoNombre}: ${d.Cantidad}`) 
			}
		}

		return hacienda;
	}

	getCabezasPorTropaEnCorral(dataItem: any): number {
		let totalCabezas: number = 0;

		for (const d of this.datos) {
			if (d.CorralID === dataItem.CorralID && d.TropaCategoriaGanadoRef.toString() === dataItem.TropaCategoriaGanadoRef.toString()) { 
				totalCabezas += d.Cantidad;
			}
		}

		return totalCabezas;
	}
}
