import { Component, OnInit, ViewChild, Input, Output, ChangeDetectorRef } from '@angular/core';
import { MyColInputComponent } from '../col-input/my-col-input.component';
import { MyColLabelInfoComponent } from '../col-labelinfo/my-col-labelinfo.component';
import Utils, { Operacion } from '../../../../../utils';
import { MessengerService } from '../../../../../services/messenger.service';
import { Guid } from 'guid-typescript';
import { TropaService } from '../../../../../services/tropa.service';
import { EstimadoTropaCategoria } from '../../../../../models/estimado-tropa-categoria';
import { ParseNumberKgPipe } from '../../../../../pipes/parse-number-kg.pipe';

@Component({
	selector: 'kt-peso-hacienda',
	templateUrl: './peso-hacienda.component.html',
	styleUrls: ['./peso-hacienda.component.scss'],
	providers: [TropaService]
})
export class PesoHaciendaComponent implements OnInit {
	public parent: any = this;

	@Input() public title: string = 'Peso Hacienda';
	@Input() public tabindex: number = -1;

	private calculando: boolean = false;
	private _cantidad: number = 0;
	private _corral: number = undefined;
	private modoTotal: boolean = false;

	@Input() public required: boolean = true; // required
	public loading: boolean = false;
	public generarPesada: boolean = false;

	@Input() set corral(value: number) {
		this._corral = value;

		setTimeout(() => {
			this.modoTotal = false;
			this.actualizarPesoFinal(false);
		});
	}

	@Input() set pesar(value: boolean) {
		this.generarPesada = value;
		this.cd.detectChanges();
	}

	@Input() set cantidad(value: number) {
		this._cantidad = value;

		setTimeout(() => {
			this.modoTotal = false;
			this.actualizarPesoFinal(false);
		});
	}

	@Input() set tropaCategoriaGanado(value: Guid) {
		if (value && value.toString()) {
			this.cargarPesoEstimado(value.toString());
		}
	}

	@ViewChild('Cabeza', { static: true }) public CabezaInput: MyColInputComponent;
	@ViewChild('Total', { static: true }) public TotalInput: MyColInputComponent;
	@ViewChild('Desbaste', { static: true }) public DesbasteInput: MyColInputComponent;
	@ViewChild('Final', { static: true }) public FinalInput: MyColLabelInfoComponent;

	constructor(private cd: ChangeDetectorRef, private tropaService: TropaService, private messengerService: MessengerService) { }

	ngOnInit() {

	}

	public initializeControl(): void {
		this.CabezaInput.setValue(0);
		this.TotalInput.setValue(0);
		this.DesbasteInput.setValue(0);
		this.FinalInput.infoText = new ParseNumberKgPipe().transform(0);

		this.DesbasteInput.setMaxValue(1);

		setTimeout(() => {
			this.modoTotal = true;
			this.actualizarPesoFinal(false);
		});

		this.cd.detectChanges();
	}

	public getPorCabezaValue(): number {
		return Utils.checkNaNGetZero(this.CabezaInput.getValue());
	}

	public getTotalValue(): number {
		return Utils.checkNaNGetZero(this.TotalInput.getValue());
	}

	public getDesbasteValue(): number {
		return Utils.checkNaNGetZero(this.DesbasteInput.getValue());
	}

	actualizarPesoFinal(notify: boolean) {
		this.calculando = true;
		this._cantidad = this._cantidad || 1;

		let cabezaKg: number = this.CabezaInput.getValue() || 0;
		let total: number = this.TotalInput.getValue() || 0;
		let desbaste: number = this.DesbasteInput.getValue() || 0;

		if (this.modoTotal) {
			cabezaKg = total / this._cantidad;
			this.CabezaInput.setValue(cabezaKg);
		} else {
			total = cabezaKg * this._cantidad;
			this.TotalInput.setValue(total);
		}


		cabezaKg = Math.round(cabezaKg);

		let c = cabezaKg - (cabezaKg * desbaste);

		if (c < 0) { // cuando se tipea, el evento se envia en numeros de 0 a 100, pero si da negativo es porque viene de 0 a 1
			cabezaKg = cabezaKg - (cabezaKg * (desbaste / 100));
		} else {
			cabezaKg = c;
		}

		// el total es por cabeza y sin desbaste
		this.FinalInput.infoText = new ParseNumberKgPipe().transform(cabezaKg, true);

		if (notify) {
			this.messengerService.broadcast('PesoHaciendaComponent', 'PrecioCompraVentaComponent', 'PesoCabezaChange', { value: Math.round(cabezaKg) });
		}

		this.calculando = false;
	}

	onInputValueChange(newValue, labelName): void {
		if (!this.calculando) {
			setTimeout(() => {
				this.modoTotal = (labelName === 'Total');
				this.actualizarPesoFinal(true);
			});
		}
	}

	private notify(value: boolean, reason: string): void {
		this.messengerService.broadcast('PesoHaciendaComponent', '', reason, { value: value });
	}

	private cargarPesoEstimado(tropaCategoriaGanadoRef: string): void {
		this.loading = true;

		let filtro: EstimadoTropaCategoria = new EstimadoTropaCategoria();
		filtro.TropaCategoriaGanadoRef = Guid.parse(tropaCategoriaGanadoRef);

		this.tropaService.getPesoEstimado(filtro).subscribe(
			data => {
				let pesoEstimadoPromedio: number = 0;

				if (!this._corral) {
					for (const p of data) {
						pesoEstimadoPromedio += p.PesoEstimadoKg; // hay que sacar el promedio porque este listado viene por N corrales donde este la tropa
					}

					pesoEstimadoPromedio = Utils.operacionRedondeada(pesoEstimadoPromedio, data.length, Operacion.Division);
				} else {
					let itm = data.find(r => r.CorralRef == this._corral);
					pesoEstimadoPromedio = itm ? itm.PesoEstimadoKg : 0;
				}

				this.CabezaInput.setValue(pesoEstimadoPromedio);

				setTimeout(() => {
					this.modoTotal = true;
					this.actualizarPesoFinal(false);
				});
			},
			error => {

			},
			() => {
				this.loading = false;
				this.cd.detectChanges();
			}
		);
	}
}
