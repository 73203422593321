import { OnInit, ViewChild, ChangeDetectorRef, OnDestroy, Directive } from '@angular/core';
import { EditableFormList } from '../common_controls/common-controls.interface';
import { DraggableCorral, DraggableItem, InputCambioCorralModel } from '../../../../models/draggable-corral';
import { CorralFiltroComponent } from './corral-filtro/corral-filtro.component';
import { CorralService } from '../../../../services/corral.service';
import { CorralInfo } from '../../../../models/corral-info';
import { CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { TropaCambioCorralComponent } from '../tropa';
import { IParentOpenInDialog } from '../common_controls/message-dialog/my-message-dialog.component';
import { InputTropaEncierreModel } from '../tropa/tropa-encierre/tropa-encierre.component';
import { MovTropaService } from '../../../../services/mov-tropa.service';
import { EventDrop } from '../common_controls/group-corral/my-group-corral.component';
import { Guid } from 'guid-typescript'; 
import { MessengerService } from '../../../../services/messenger.service';
import Utils from '../../../../utils';
import { QuickPanelService } from '../../../../services/quick-panel.service';

@Directive()
export abstract class HelpersDraggableCorral extends EditableFormList<DraggableCorral> implements OnInit, IParentOpenInDialog {
	component: HelpersDraggableCorral = this;
	@ViewChild(CorralFiltroComponent, { static: true }) filtroCorral: CorralFiltroComponent;

	constructor(protected corralService: CorralService,
		protected movTropaService: MovTropaService,
		cd: ChangeDetectorRef,
		private messengerService: MessengerService,
		private quickPanelService: QuickPanelService) {
		super(cd, undefined);
	}

	ngOnInit() {
		 
	}

	getMessengerInstance(): MessengerService {
		return this.messengerService;
	}

	getQuickPanelInstance(): QuickPanelService {
		return this.quickPanelService;
	}

	buscar() {
		if (!this.checkLoadList()) return;
		super.buscar();
	}

	loadList(): void {
		let dietaID = this.filtroCorral.Dieta.getValue() || 0;
		let corralID = 0;
		let tropaID = this.filtroCorral.Tropa.getValue() || Guid.createEmpty();
		let categoriaGanadoID = 0; //this.filtroCorral.CategoriaGanado.getValue() || 0;
		let corralesID = Utils.nameValueListToIntegerList(this.filtroCorral.Corrales.getValue()) || [0]; // 0 son todos 
		let clienteID = this.filtroCorral.Hotelero.getValue() || 0; 

		this.corralSetDatos(corralID, clienteID, tropaID, categoriaGanadoID, dietaID, corralesID);
	}

	corralSetDatos(corralID: number, clienteID: number, tropaID: Guid, categoriaGanadoID: number, dietaID: number, corralesID: number[] = [], reverse: boolean = false): void {
		this.corralService.getInfo(corralID, clienteID, tropaID, categoriaGanadoID, dietaID, true, corralesID).subscribe((data: CorralInfo[]) => {
			this.datos = [];
			for (const c of data) {
				if (c.Activo && ((this.soloCorralesConTropas() && c.Tropas.length > 0) || !this.soloCorralesConTropas())) {
					let nuevoCorral = new DraggableCorral();
					nuevoCorral.corral = c;
					nuevoCorral.esEncierre = false;

					for (const t of c.Tropas) {
						let agregado: boolean = false;

						// sumar si ya existe categoria y tropa
						for (const c of nuevoCorral.items) {
							if (c.tropa.CategoriaGanadoRef === t.CategoriaGanadoRef && c.tropa.TropaRef.equals(t.TropaRef)) {
								c.tropa.Cantidad += t.Cantidad;
								agregado = true;
							}
						}

						t.CorralID = nuevoCorral.corral.CorralID; // el corral de origen

						if (!agregado && t.Cantidad > 0) {
							nuevoCorral.items.push(new DraggableItem(t));
						}
					}

					nuevoCorral.items.sort((a, b) => (a.tropa.CodigoTropa > b.tropa.CodigoTropa) ? 1 : ((b.tropa.CodigoTropa > a.tropa.CodigoTropa) ? -1 : 0));
					this.datos.push(nuevoCorral);
				}
			}

			if (reverse)
				this.datos.reverse();

		}, error => {
			this.MessageDialog.showExceptionDialog('No se pudieron obtener los corrales.', error);
		}, () => {
			this.finalizarBusqueda();
		});
	}

	soloCorralesConTropas(): boolean { return false; }
	checkLoadList(): boolean { return true; }

	totalHacienda(): number {
		let c: number = 0;
		for (const d of this.datos) {
			c += d.total();
		}
		return c;
	}

	notDrop(e: any) { return false; }

	drop(e: EventDrop, esEncierreVenta: boolean) {
		let event: CdkDragDrop<string[]>;
		let corral: DraggableCorral;
		if (e instanceof EventDrop) {
			event = e.event;
			corral = e.corral;
		}
		else {
			event = e;
			corral = undefined;
		}
		if (event.previousContainer !== event.container) {
			this.eventDrop = event;
			this.corral = corral;

			this.containerOrigen = this.eventDrop.previousContainer;
			this.containerDestino = this.eventDrop.container;
			//Tener en cuenta que con el casteo se pierde el Objeto Guid de la Tropa
			this.droppedItem = (this.containerOrigen.data[this.eventDrop.previousIndex] as unknown as DraggableItem);
			this.corralOrigen = this.datos.find(x => x.corral.CorralID == this.droppedItem.tropa.CorralID);
			if (this.corral)
				this.corralDestino = this.datos.find(x => x.corral.CorralID == this.corral.CorralID);

			let tcg: any = '';

			if (this.droppedItem.tropa.TropaCategoriaGanadoRef instanceof Guid)
				tcg = this.droppedItem.tropa.TropaCategoriaGanadoRef.toString();
			else
				tcg = this.droppedItem.tropa.TropaCategoriaGanadoRef;

			if (tcg.value) { tcg = tcg.value; }

			this.quickPanelService.createOffCanvasComponent('Movimiento de Hacienda', TropaCambioCorralComponent, this.getNewInputModel(), { TropaCategoriaGanadoRef: tcg, EsEncierreVenta: esEncierreVenta, CorralVacio: (this.corralDestino && this.corralDestino.corral.Cabezas === 0), Width: 625 });			
		}
	}

	getNewInputModel(): InputCambioCorralModel {
		let model = new InputCambioCorralModel();
		model.Cantidad = this.droppedItem.tropa.Cantidad;
		model.MaxCantidad = this.droppedItem.tropa.Cantidad;
		if (this.corralOrigen && this.corralOrigen.corral) {
			model.DietaOrigen = this.corralOrigen.corral.DietaNombre || '';
			model.CorralOrigenRef = this.corralOrigen.corral.CorralID;
		}
		if (this.corralDestino && this.corralDestino.corral) {
			model.DietaDestino = this.corralDestino.corral.DietaNombre || '';
			model.CorralDestinoRef = this.corralDestino.corral.CorralID;
		}
		return model;
	}

	eventDrop: CdkDragDrop<string[]> = undefined;
	corral: any = undefined;
	containerOrigen: CdkDropList<string[]> = undefined;
	containerDestino: CdkDropList<string[]> = undefined;
	droppedItem: DraggableItem = undefined;
	corralOrigen: DraggableCorral = undefined;
	corralDestino: DraggableCorral = undefined;

	resetControls() {
		this.eventDrop = undefined;
		this.corral = undefined;
		this.containerOrigen = undefined;
		this.containerDestino = undefined;
		this.droppedItem = undefined;
		this.corralOrigen = undefined;
		this.corralDestino = undefined;
	}

	public saveResultDialogModel(model: any) {
		if (model instanceof InputCambioCorralModel)
			this.manejarCambioCorral(model); 
		else if (model instanceof InputTropaEncierreModel)
			this.manejarEncierreCampo(model);
	} 

	manejarCambioCorral(model: InputCambioCorralModel) {
		let cantidadIngresada = model.Cantidad;
		if (model.CambioCorralTipo === 'Tropa') {
			if (cantidadIngresada > this.droppedItem.tropa.Cantidad) {
				cantidadIngresada = this.droppedItem.tropa.Cantidad;
			}
			if (cantidadIngresada <= 0) return;
		}

		this.saveMovimiento(model, cantidadIngresada);
	}

	manejarEncierreCampo(model: InputTropaEncierreModel) { }

	//Overrides Cambio Corrales
	saveMovimiento(model: InputCambioCorralModel, cantidadIngresada: number) {
		this.realizarMovimientoTropaCorral(model, cantidadIngresada);
	}

	realizarMovimientoTropaCorral(model: InputCambioCorralModel, cantidadIngresada: number, stack: boolean = true) {
		let exist: boolean = false;

		if (stack) {
			exist = this.cambiarCantidadTropaCategoria(this.containerDestino, model, cantidadIngresada);
		}

		// quitar siempre la cantidad que se arrastra
		this.cambiarCantidadTropaCategoria(this.containerOrigen, model, cantidadIngresada * -1);

		//Tener en cuenta que con el casteo se pierde el Objeto Guid de la Tropa
		let viejoItem = (this.containerOrigen.data[this.eventDrop.previousIndex] as unknown as DraggableItem);
		if (!exist) {
			let data: any = this.containerOrigen.data[this.eventDrop.previousIndex];
			
			data.containerOrigen = undefined;
			data.containerDestino = undefined;

			var copyObj = JSON.parse(JSON.stringify(data));
			copyObj.containerOrigen = this.containerOrigen;
			copyObj.containerDestino = this.containerDestino;
			copyObj.tropa.Cantidad = cantidadIngresada;
			copyObj.tropa.DesbastePorc = model.DesbastePorc;
			copyObj.tropa.PesoCabezaKg = model.PesoCabezaKg;
			copyObj.cambioModel = model;

			if (this.corral) {
				copyObj.tropa.CorralID = this.corral.CorralID;
				copyObj.tropa.corral = this.corral;
			}

			this.containerDestino.data.push(copyObj);
		}
		if (viejoItem.tropa.Cantidad <= 0) {
			this.containerOrigen.data.splice(this.eventDrop.previousIndex, 1);
		}

		this.cd.detectChanges();
	}

	cambiarCantidadTropaCategoria(container: CdkDropList<string[]>, model: InputCambioCorralModel, cantidadSuma: number): boolean {
		let droppedItem = this.droppedItem;
		let exist: boolean = false;
		let ctrl = this; 
		container.data.forEach(function (item, index) {
			//Tener en cuenta que con el casteo se pierde el Objeto Guid de la Tropa
			let obj = (item as unknown as DraggableItem);
			if (obj.tropa.CategoriaGanadoRef === droppedItem.tropa.CategoriaGanadoRef && ctrl.guidEquals(obj.tropa.TropaRef, droppedItem.tropa.TropaRef)) {
				obj.tropa.Cantidad += cantidadSuma;
				obj.cambioModel = model;
				exist = true;
			}
		});
		return exist;
	}

	guidEquals(value1: any, value2: any): boolean {
		return this.getGuid(value1).equals(this.getGuid(value2))
	}

	getGuid(value: any): Guid {
		if (value instanceof Guid)
			return value;
		if (typeof value === 'string' && Guid.isGuid(value))
			return Guid.parse(value);
		if (typeof value === 'object')
			return this.getGuidFromObject(value);
	}

	getGuidFromObject(obj: object): Guid {
		for (const key of Object.keys(obj)) {
			if (key === 'value') {
				if (typeof obj[key] === 'object')
					return this.getGuidFromObject(obj[key]);
				else if (Guid.isGuid(obj[key]))
					return Guid.parse(obj[key]);
			}			
		}
	}
}
