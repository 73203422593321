import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';  
import { MovProductoSanitarioTipo } from '../models/mov-producto-sanitario-tipo';
import { Observable } from 'rxjs';
import { NameValueList } from '../interfaces/nvl.interface';
import { environment } from '../environments/environment';


@Injectable()
export class MovProductoSanitarioTipoService extends GenericoService<MovProductoSanitarioTipo> {
	constructor(private _httpClient: HttpClient) {
        super(_httpClient, 'movproductosanitariotipo');
	}

	getEntradas(): Observable<NameValueList[]> {
		return this._httpClient.get<NameValueList[]>(environment.apiUrl + `/${this._endpoint}/entradas`, { headers: this._getHeaders });
	}

	getSalidas(): Observable<NameValueList[]> {
		return this._httpClient.get<NameValueList[]>(environment.apiUrl + `/${this._endpoint}/salidas`, { headers: this._getHeaders });
	}

	getPadres(): Observable<NameValueList[]> {
		return this._httpClient.get<NameValueList[]>(environment.apiUrl + `/${this._endpoint}/padres`, { headers: this._getHeaders });
	}
}
