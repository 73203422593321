<div class="container">
	<div class="row login__row">
		<div class="col-12 mx-auto">
			<div class="card card-signin my-5">
				<div class="card-body">
					<div class="kt-login__title"></div>
					<form class="form-signin">
						<div class="form-label-group">
							<my-col-input [tabindex]="3" #Password [inputType]="'password'" [type]="2" [parent]="parent" [labelName]="'AUTH.INPUT.NEW_PASSWORD' | translate" [required]="true" [divClass]="''" [labelClass]="'kt-sigef-login--label'"></my-col-input>
						</div>
						<div class="form-label-group">
							<my-col-input [tabindex]="3" #ConfirmPassword [inputType]="'password'" [type]="2" [parent]="parent" [labelName]="'AUTH.INPUT.CONFIRM_PASSWORD' | translate" [required]="true" [divClass]="''" [labelClass]="'kt-sigef-login--label'"></my-col-input>
						</div>

						<div class="text-center pr-3 pt-2" tabindex="-1">
							<button tabindex="6" (click)="submit()" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}" id="kt_login_signin_submit" class="btn btn-sigef btn-sigef-login mx-2">{{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
