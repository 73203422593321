import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http'; 
import { Color } from '../models/color';
import { Observable } from 'rxjs';
import { NameValueList } from '../interfaces/nvl.interface';
import { environment } from '../environments/environment.prod';


@Injectable()
export class ColorService extends GenericoService<Color> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'color');
	}

	getTropas(): Observable<NameValueList[]> {
		return this._httpClient.get<NameValueList[]>(environment.apiUrl + `/${this._endpoint}/tropas`, { headers: this._getHeaders });
	}

	getDietas(): Observable<NameValueList[]> {
		return this._httpClient.get<NameValueList[]>(environment.apiUrl + `/${this._endpoint}/dietas`, { headers: this._getHeaders });
	}

}
