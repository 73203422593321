import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { EditableForm } from '../../common_controls/common-controls.interface';
import { ProductoSanitarioFormato } from '../../../../../models/producto-sanitario-formato';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessengerService } from '../../../../../services/messenger.service';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { MyColInputComponent, MyColSwitchComponent } from '../../common_controls';
import { IOpenInDialog, IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { OffCanvasInterface } from '../../../../../interfaces/off-canvas.interface';

@Component({
	selector: 'kt-producto-sanitario-formato',
	templateUrl: './producto-sanitario-formato.component.html',
	styleUrls: ['./producto-sanitario-formato.component.scss']
})
export class ProductoSanitarioFormatoComponent extends EditableForm<ProductoSanitarioFormato> implements OnInit, OffCanvasInterface {

	@ViewChild('ProductoSanitarioFormatoNombre', { static: true }) ProductoSanitarioFormatoNombre: MyColInputComponent;
	@ViewChild('FactorConversionUM', { static: true }) FactorConversionUM: MyColInputComponent;
	@ViewChild('Activo', { static: true }) Activo: MyColSwitchComponent;

	unidadMedidaNombre: string = 'Factor de conversión';

	@Input() public tabindex: number = 200;

	constructor(cd: ChangeDetectorRef,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		quickPanelService: QuickPanelService) {
		super(cd, undefined, snackBar, messengerService, quickPanelService);
	}

	ngOnInit() {
		this.lstControls = [this.ProductoSanitarioFormatoNombre, this.FactorConversionUM, this.Activo];
	}

	getNewModel(): ProductoSanitarioFormato { return new ProductoSanitarioFormato(); };

	setOffCanvasData(data: any, extras: any): void {
		setTimeout(() => {
			this.ProductoSanitarioFormatoNombre.focus();
			this.setModel(data);
			this.setExtras(extras);
		}, 0);
	}

	setModelControlValues() {
		if (this.model) {
			this.ProductoSanitarioFormatoNombre.setValue(this.model.ProductoSanitarioFormatoNombre);
			this.FactorConversionUM.setValue(this.model.FactorConversionUM);
			this.Activo.setValue(this.model.Activo)
		}
	}

	setExtras(extras: any) {
		if (extras.unidadMedidaNombre !== '') {
			this.unidadMedidaNombre += ` a <strong>${extras.unidadMedidaNombre}</strong>`;
		}
	}

	onFormatoSave(e: any) {
		if (!this.runValidaciones()) {
			return false;
		}
		this.model.ProductoSanitarioFormatoNombre = this.ProductoSanitarioFormatoNombre.getValue();
		this.model.FactorConversionUM = this.FactorConversionUM.getValue();
		this.model.Activo = this.Activo.getValue();

		this.getMessengerInstance().broadcast('ProductoSanitarioFormatoComponent', 'ProductoSanitarioListComponent', 'ProductoSanitarioFormato', this.model);
		this.getQuickPanelInstance().hideQuickPanel();
	}

	onFormatoCancel(e: any) {
		this.getQuickPanelInstance().hideQuickPanel();
	}

}
