<div *ngIf="loading">
	<div class="kt-portlet mb-0">
		<div class="kt-portlet__body kt-portlet__body--fit p-3">
			<div class="kt-spinner kt-spinner--sm kt-spinner--success kt-spinner--left kt-spinner--input">
				&nbsp;&nbsp; <span class="ml-5">Cargando Reporte...</span>
			</div>
		</div>
	</div>
</div>

<div class="kt-portlet" *ngIf="!loading && tropa">
	<div class="kt-portlet__body kt-portlet__body--fit">
		<div class="kt-invoice-2">

			<!-- Encabezado -->
			<div class="kt-invoice__head">
				<div class="kt-invoice__container">
					<div class="kt-invoice__brand">
						<h1 class="kt-invoice__title">
							TROPA {{ tropa.CodigoTropa  | parseCodigoTropa }} - {{ tropa.TropaNombre }}
							<br />
							<span class="kt-invoice__title kt-invoice__subtitle">Propietario: {{ tropa.ClienteNombre ?  tropa.ClienteNombre : 'Sin Propietario' }}</span>
							<br />
							<span class="kt-invoice__title kt-invoice__subtitle__timestamp">Fecha y hora: {{ generacion | parseDateHour }}</span>
						</h1>
						<div href="#" class="kt-invoice__logo">
							<a href="#"><img class="sigef--reporte--logo" src="./assets/media/logos/logo-4-b.png"></a>
							<span class="kt-invoice__desc">
							</span>
						</div>
					</div>
					<div class="kt-invoice__title">DATOS GENERALES</div>
					<div class="kt-invoice__items" style="margin-top: 15px">
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Fecha de Ingreso</span>
							<span class="kt-invoice__text">{{ tropa.FechaIngreso | parseDateHour }}</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Días Corral</span>
							<span *ngIf="detalles.length > 0" class="kt-invoice__text">{{ tropa.FechaIngreso | diasEnCorral }}</span>
							<span *ngIf="detalles.length == 0" class="kt-invoice__text">Sin Hacienda</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Marca Identificatoria</span>
							<span class="kt-invoice__text">{{ tropa.MarcaIdentificatoria ? tropa.MarcaIdentificatoria : 'Sin Marca Identificatoria'  }}</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Color Identificatorio en SIGEF</span>
							<span class="kt-invoice__text" style="max-width: 2rem; border: 1px solid black;" [ngStyle]="{'background-color': tropa.ColorPrincipal}">&nbsp;</span>
						</div>
					</div>
				</div>
			</div>

			<!-- Hacienda -->
			<div class="kt-invoice__body">
				<div class="kt-invoice__container">
					<div class="kt-invoice__title">HACIENDA</div>
					<div class="table-responsive">
						<table class="table">
							<thead>
								<tr>
									<th class="text-left">Categoría</th>
									<th class="text-left">Corral</th>
									<th class="text-center">Cantidad</th>
									<th *ngIf="paricion" class="text-center">Precio Estimado</th>
									<th class="text-center">Peso Neto</th>
									<th class="text-center">Peso Total</th>
									<th class="text-center">Aumento Diario Estimado</th>
								</tr>
							</thead>
							<tbody>
								<ng-container *ngIf="detalles.length > 0">
									<tr class="trDet" *ngFor="let cat of detalles">
										<td class="text-left">{{ cat.CategoriaNombre }}</td>
										<td class="kt-font-regular text-left">{{ cat.CorralNumero }} - {{ cat.CorralNombre }}</td>
										<td class="kt-font-regular text-center">{{ cat.Cantidad | parseNumber : true }}</td>
										<td *ngIf="paricion" class="kt-font-regular text-center">{{ paricion.PrecioVenta | parseNumberMoney }}</td>
										<td class="kt-font-regular text-center">{{ cat.PromedioPesoCabezaKg | parseNumberKg }}</td>
										<td class="kt-font-regular text-center">{{ operacionRedondeada(cat.Cantidad, cat.PromedioPesoCabezaKg, 4) | parseNumberKg }}</td>
										<td class="kt-font-regular text-center">{{ cat.AumentoDiarioEstKg | parseNumberKg : false : false  }}</td>
									</tr>
									<tr class="trDet">
										<td></td>
										<td></td>
										<td class="text-center">{{ totalCabezas }}</td>
										<td></td>
										<td class="text-center">{{ totalPeso | parseNumberKg }}</td>
										<td></td>
									</tr>
								</ng-container>
								<tr *ngIf="detalles.length == 0">
									<td class="kt-font-regular text-left" colspan="5">
										Sin Hacienda
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<!-- Consumo -->
			<div class="kt-invoice__body" *ngIf="detalles.length > 0">
				<div class="kt-invoice__container">
					<div class="kt-invoice__title">CONSUMO</div>
					<div class="table-responsive">
						<kt-consumo-chart class="w-100" [consumos]="consumos"></kt-consumo-chart>
					</div>
				</div>
			</div>

			<!-- Movimientos -->
			<div class="kt-invoice__body">
				<div class="kt-invoice__container">
					<div class="kt-invoice__title">MOVIMIENTOS</div>
					<div class="table-responsive">
						<table class="table">
							<thead>
								<tr>
									<th class="text-left">Fecha</th>
									<th class="text-center">Nro. de Trans.</th>
									<th class="text-left">Tipo Movimiento</th>
									<th class="text-center">Categoría</th>
									<th class="text-center">Cantidad</th>
									<th class="text-right">Corral</th>
								</tr>
							</thead>
							<tbody>
								<ng-container *ngFor="let mov of movimientos">
									<tr class="trDet">
										<td>{{ mov.FechaMovimiento | parseDateHour }}</td>
										<td class="kt-font-regular text-center">{{ mov.NroTransaccion == 0 ? '-' : mov.NroTransaccion | parseNumber : true }}</td>
										<td class="kt-font-regular text-left">
											<span class="{{mov.MultiplicaPor | parseIconMovimiento }}">&nbsp;&nbsp;</span>
											<a class="kt-link" title="Ver resumen de movimiento" href="javascript:;" (click)="verMovimiento(mov)">
												<span>{{mov.MovTropaTipoNombre}}</span>
											</a>
										</td>
										<td class="kt-font-regular text-center">{{ mov.CategoriaGanadoNombre }}</td>
										<td class="kt-font-regular text-center">{{ mov.Cantidad | parseNumber : true }}</td>
										<td class="kt-font-regular text-right">{{ mov.CorralNumero }} - {{ mov.CorralNombre }}</td>
									</tr>
									<tr *ngIf="mov.Observaciones">
										<td class="kt-font-regular text-left p-0 pb-3" colspan="5">
											Observaciones: {{ mov.Observaciones }}
										</td>
									</tr>
								</ng-container>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<!-- Toggle Información Compra -->
			<div class="kt-invoice__body p-0 pb-2 pointer no-print" *ngIf="tropa && tropa.CategoriaGanados.length > 0">
				<div class="kt-invoice__container" (click)="datosCompraExpandidos = !datosCompraExpandidos">
					<div class="kt-invoice__title">
						<i class="la la-angle-{{datosCompraExpandidos ? 'up' : 'down'}}"></i>&nbsp;INFORMACIÓN DE COMPRA
					</div>
				</div>
			</div>

			<!-- Encabezado -->
			<div class="kt-invoice__head pt-3" [ngClass]="{'d-none': !datosCompraExpandidos}">
				<div class="kt-invoice__container">
					<div class="kt-invoice__title">DATOS GENERALES</div>
					<div class="kt-invoice__items" style="margin-top: 0">
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Fecha y Hora de Ingreso</span>
							<span class="kt-invoice__text">{{ tropa.FechaIngreso | parseDateHour }}</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Proveedor</span>
							<span class="kt-invoice__text">{{ tropa.ProveedorNombre ? tropa.ProveedorNombre : 'Sin Proveedor' }}</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Origen</span>
							<span class="kt-invoice__text">{{ tropa.TropaOrigenNombre ? tropa.TropaOrigenNombre : 'Sin Origen' }}</span>
						</div>
					</div>
					<div class="kt-invoice__items">
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Gastos de Comercialización (Total de Tropa)</span>
							<span class="kt-invoice__text">{{ tropa.GastoComercializacion | parseNumberMoney }}</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle"></span>
							<span class="kt-invoice__text"></span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle"></span>
							<span class="kt-invoice__text"></span>
						</div>
					</div>
					<div class="kt-invoice__items">
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Precio total de compra</span>
							<span class="kt-invoice__text">{{ totalCompra | parseNumberMoney }}</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Peso total</span>
							<span class="kt-invoice__text">Bruto: {{ totalPesoCompra | parseNumberKg }} (Neto: {{ totalPesoCompraNeto | parseNumberKg }})</span>
						</div>
						<div class="kt-invoice__item">
							<span class="kt-invoice__subtitle">Cantidad de Muertos al Arribo</span>
							<span class="kt-invoice__text">{{ tropa.CantMuertosArribo | parseNumber : true }}</span>
						</div>
					</div>
				</div>
			</div>

			<!-- Hacienda -->
			<div class="kt-invoice__body" [ngClass]="{'d-none': !datosCompraExpandidos}">
				<div class="kt-invoice__container">
					<div class="kt-invoice__title">HACIENDA (AL MOMENTO DE LA COMPRA)</div>
					<div class="table-responsive">
						<table class="table">
							<thead>
								<tr>
									<th class="text-left">Categoría</th>
									<th class="text-center">Cantidad</th>
									<th class="text-center">Precio Compra</th>
									<th class="text-center">Peso Bruto</th>
									<th class="text-center">Desbaste</th>
									<th class="text-center">Peso Neto</th>
									<th class="text-center">Aumento Diario Est.</th>
									<th class="text-right">Corral Destino</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let cat of tropa.CategoriaGanados">
									<td class="text-left">{{ cat.CategoriaGanadoNombre }}</td>
									<td class="kt-font-regular text-center">{{ cat.Cantidad | parseNumber : true }}</td>
									<td class="kt-font-regular text-center">{{ cat.PrecioCompra | parseNumberMoney }}</td>
									<td class="kt-font-regular text-center">{{ cat.PesoCabezaKg | parseNumberKg }}</td>
									<td class="kt-font-regular text-center">{{ cat.DesbastePorc | parseNumberPorc }}</td>
									<td class="kt-font-regular text-center">{{ operacionRedondeada(round(cat.PesoCabezaKg), (round(cat.PesoCabezaKg) * cat.DesbastePorc), 2) | parseNumberKg }}</td>
									<td class="kt-font-regular text-center">{{ cat.AumentoDiarioEstKg | parseNumberKg : false : false  }}</td>
									<td class="kt-font-regular text-right">{{ cat.CorralNumero }} - {{ cat.CorralNombre }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<!-- Transportes -->
			<div class="kt-invoice__body" [ngClass]="{'d-none': !datosCompraExpandidos}">
				<div class="kt-invoice__container">
					<div class="kt-invoice__title">TRANSPORTE</div>
					<div class="table-responsive">
						<table class="table">
							<thead>
								<tr>
									<th class="text-left">Fecha</th>
									<th class="text-left">Transportista</th>
									<th class="text-center">($) Flete</th>
									<th class="text-left">DTEs</th>
									<th class="text-center">Cantidad Cabezas</th>
								</tr>
							</thead>
							<tbody *ngIf="tropa && tropa.Transportes && tropa.Transportes.length > 0">
								<tr *ngFor="let trans of tropa.Transportes">
									<td class="">{{trans.Fecha | date: 'dd/MM/yyyy'}}</td>
									<td class="kt-font-regular text-left">{{trans.DTEProveedorTransporte}}</td>
									<td class="kt-font-regular text-center">{{trans.GastoFlete | parseNumberMoney}}</td>
									<td class="kt-font-regular text-left">
										<ul class="nobull" style="padding: 0">
											<li *ngFor="let dte of trans.DTEs">
												{{dte.DTENro }}
											</li>
										</ul>
									</td>
									<td class="kt-font-regular text-center">
										<ul class="nobull" style="padding: 0">
											<li *ngFor="let dte of trans.DTEs">
												{{dte.DTECantidad | parseNumber : true }}
											</li>
										</ul>
									</td>
								</tr>
							</tbody>
							<tbody *ngIf="tropa && tropa.Transportes && tropa.Transportes.length == 0">
								<tr>
									<td colspan="8">
										<span style="font-weight: 300">No se han cargado datos de Transporte</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<!-- Toggle Información Venta -->
			<div class="kt-invoice__body p-0 pb-2 pointer no-print" *ngIf="ventas && ventas.length > 0">
				<div class="kt-invoice__container" (click)="datosVentaExpandidos = !datosVentaExpandidos">
					<div class="kt-invoice__title">
						<i class="la la-angle-{{datosVentaExpandidos ? 'up' : 'down'}}"></i>&nbsp;VENTAS 
					</div>
				</div>
			</div>

			<!-- Ventas -->
			<div class="kt-invoice__body" [ngClass]="{'d-none': !datosVentaExpandidos}">
				<div class="kt-invoice__container">
					<div class="kt-invoice__title">HACIENDA &amp; FAENA</div>
					<div class="table-responsive">
						<ng-container *ngFor="let venta of ventas">
							<table class="table" *ngFor="let cat of venta.Hacienda">
								<thead>
									<tr>
										<th class="text-left">Categoría</th>
										<th class="text-center">Cantidad</th>
										<!--<th class="text-center">Tropa</th>-->
										<!--<th class="text-center">Propietario</th>-->
										<th class="text-center">Días Corral</th>
										<th class="text-center">Precio Venta</th>
										<th class="faena text-center">DATOS FAENA</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td class="text-left">{{ cat.CategoriaGanadoNombre }}</td>
										<td class="kt-font-regular text-center">{{ cat.Cantidad | parseNumber : true }}</td>
										<!--<td class="kt-font-regular text-center">{{ cat.TropaNombre }}</td>-->
										<!--<td class="kt-font-regular text-center">{{ cat.ClienteNombre }}</td>-->
										<td class="kt-font-regular text-center">{{ cat.FechaIngreso | diasEnCorral : venta.Fecha }}</td>
										<td class="kt-font-regular text-center">{{ cat.PrecioVenta | parseNumberMoney }}</td>
										<td class="p-0" *ngIf="cat.Faenas && cat.Faenas.length > 0">
											<table class="table faena-table">
												<thead>
													<tr>
														<th class="text-center faena faena-header">Cantidad</th>
														<th class="text-center faena faena-header">Kilos Vivos</th>
														<th class="text-center faena faena-header">Kilos Carne</th>
														<th class="text-center faena faena-header">Precio Faena</th>
														<th class="text-center faena faena-header">Rinde (%)</th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let faena of cat.Faenas">
														<td class="kt-font-regular faena p-0 text-center">{{ faena.CantCabezas | parseNumber : true }}</td>
														<td class="kt-font-regular faena p-0 text-center">{{ faena.KilosVivo | parseNumberKg }}</td>
														<td class="kt-font-regular faena p-0 text-center">{{ faena.KilosCarne | parseNumberKg }}</td>
														<td class="kt-font-regular faena p-0 text-center">{{ faena.PrecioFaena | parseNumberMoney }}</td>
														<td class="kt-font-regular faena p-0 text-center">{{ (faena.KilosCarne / faena.KilosVivo) | parseNumberPorc }}</td>
													</tr>
												</tbody>
											</table>
										</td>
										<td colspan="5" class="text-left" *ngIf="cat.Faenas && cat.Faenas.length == 0">
											<span class="faena">No se han cargado datos de Faena</span>
										</td>
									</tr>
								</tbody>
							</table>
							<div class="kt-divider p-3"><span></span></div>
						</ng-container>
					</div>
				</div>
			</div>

			<!-- Acciones -->
			<div class="kt-invoice__actions">
				<div class="kt-invoice__container">
					<div>
						<button type="button" class="btn btn-sigef-secondary btn-bold" (click)="goBackHandler($event)">Volver</button>
						<button type="button" class="btn btn-sigef-secondary btn-bold ml-2" (click)="printHandler($event)">Imprimir</button>
					</div>

					<button type="button" class="btn btn-sigef btn-bold" *ngIf="puedeEditar" (click)="editHandler($event)">{{editarBoton}}</button>
				</div>
			</div>
		</div>
	</div>
</div>
