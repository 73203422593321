import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { CategoriaGanadoService } from '../../../../../services/categoria-ganado.service';
import { CorralService } from '../../../../../services/corral.service';
import { TropaCategoriaGanado } from '../../../../../models/tropa-categoria-ganado';
import { EditableForm } from '../../common_controls/common-controls.interface';
import { MyColDropDownListComponent, MyColInputSearchComponent, MyColInputComponent, MyColTextAreaComponent, PrecioCompraVentaComponent, PesoHaciendaComponent, IngresoHaciendaComponent } from '../../common_controls';
import { IOpenInDialog, IParentOpenInDialog } from '../../common_controls/message-dialog/my-message-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessengerService } from '../../../../../services/messenger.service';
import { OffCanvasInterface } from '../../../../../interfaces/off-canvas.interface';
import { QuickPanelService } from '../../../../../services/quick-panel.service';

@Component({
	selector: 'kt-tropa-categoria-ganado',
	templateUrl: './tropa-categoria-ganado.component.html',
	styleUrls: ['./tropa-categoria-ganado.component.scss'],
	providers: [CategoriaGanadoService, CorralService]
})
export class TropaCategoriaGanadoComponent extends EditableForm<TropaCategoriaGanado> implements AfterViewInit, OnInit, IOpenInDialog, OffCanvasInterface {
	@ViewChild(MyColDropDownListComponent, { static: true }) Categoria: MyColDropDownListComponent;
	@ViewChild('AumentoDiario', { static: true }) AumentoDiario: MyColInputComponent;
	@ViewChild(MyColInputSearchComponent, { static: true }) Corral: MyColInputSearchComponent;
	@ViewChild('Cantidad', { static: true }) Cantidad: MyColInputComponent;
	@ViewChild('CantidadVivo', { static: true }) CantidadVivo: MyColInputComponent;
	@ViewChild(MyColTextAreaComponent, { static: true }) Observaciones: MyColTextAreaComponent;

	@ViewChild(PrecioCompraVentaComponent, { static: true }) PrecioCompraVenta: PrecioCompraVentaComponent;
	@ViewChild(PesoHaciendaComponent, { static: true }) PesoHacienda: PesoHaciendaComponent;

	modelIsNew: boolean = true;
	public cantidad: number = 0;
	public categoriaRepetida: boolean = false;  // muestra la advertencia de que al editar el aumento diario, se modificará para la otra categoría. Ademas del mensaje de categoria repetida
	public mostrarPrecio: boolean = true;
	public mostrarPeso: boolean = true;
	public componente: TropaCategoriaGanadoComponent = this;
	public cantidadVivo: number = 0;
	public CantidadTropaLabel: string = 'Cantidad Tropa';
	public CantidadCategoriaLabel: string = 'Cantidad Categoría';

	private data: TropaCategoriaGanado[] = [];

	constructor(private categoriaGanadoService: CategoriaGanadoService,
		private corralService: CorralService,
		cd: ChangeDetectorRef,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		quickPanelService: QuickPanelService) {
		super(cd, undefined, snackBar, messengerService, quickPanelService);
	}

	setOffCanvasData(data: any, extras: any): void {
		setTimeout(() => {
			if (extras.ingresoHacienda) {
				let inHac: IngresoHaciendaComponent = (extras.ingresoHacienda as IngresoHaciendaComponent);
				if (inHac.pesoTropa) {
					this.PrecioCompraVenta._totalPesoTropa = inHac.PesoInput.getValue();
				}
			}
			this.mostrarPeso = extras.peso;
			this.mostrarPrecio = extras.precio;
			this.data = extras.data; // para informar al usuario que una categoria ya se agregó
			this.cantidadVivo = extras.cantidadVivo;

			this.cd.detectChanges();

			this.modelIsNew = (!data || data.TropaCategoriaGanadoID.isEmpty());
			if (!this.modelIsNew) {
				this.lstControls = [this.AumentoDiario, /*this.PrecioCompra*/];
			}
			else {
				this.lstControls = [this.Categoria, this.AumentoDiario, /*this.PrecioCompra,*/ this.Corral, this.Cantidad, this.Observaciones];
				this.Categoria.initializeControl(this.categoriaGanadoService, this.cd, "Seleccione una Categoría");
				this.Corral.initializeControlByCorral(this.corralService);
				this.addCustomValidators();
			}

			if (!this.mostrarPeso && !this.cantidadVivo) {
				this.CantidadVivo.focus();
			}
			else {
				this.CantidadVivo.setValue(this.cantidadVivo);
				this.Categoria.focus();
			}

			this.CantidadVivo.readonly = this.data.length > 0;
			this.CantidadVivo.disabled = this.data.length > 0;

			this.setModel(data);
		}, 0);
	}

	onGanadoSave(e: any) {
		if (!this.runValidaciones()) {
			return false;
		}
		this.model.AumentoDiarioEstKg = this.AumentoDiario.getValue();
		if (this.modelIsNew) {
			this.model.PrecioCompra = this.PrecioCompraVenta.getPorCabezaValue(); //this.PrecioCompra.getValue();
			let cat = this.Categoria.getItem();
			this.model.CategoriaGanadoRef = cat.Key;
			this.model.CategoriaGanadoNombre = cat.Value;
			let corral = this.Corral.getItem();
			this.model.CorralRef = corral.Key;
			this.model.CorralNombre = `${corral.Descripcion}`;
			this.model.Cantidad = this.Cantidad.getValue();
			this.model.PesoCabezaKg = this.PesoHacienda.getPorCabezaValue(); //this.PesoCabeza.getValue();
			this.model.DesbastePorc = this.PesoHacienda.getDesbasteValue(); //this.Desbaste.getValue();
			this.model.Observaciones = this.Observaciones.getText();
		}
		this.getMessengerInstance().broadcast(undefined, undefined, 'TropaCategoriaGanado', this.model);
		this.getQuickPanelInstance().hideQuickPanel();
	}

	onGanadoCancel(e: any) {
		this.getQuickPanelInstance().hideQuickPanel();
	}

	ngOnInit() { }

	ngAfterViewInit() {
		this.Cantidad.setParent(this);
		this.CantidadVivo.setParent(this);

		this.PrecioCompraVenta.initializeControl();
		this.PesoHacienda.initializeControl();

		this.PesoHacienda.CabezaInput.setValue(0);
		this.PesoHacienda.DesbasteInput.setValue(0);
		this.PrecioCompraVenta.CabezaInput.setValue(0);
	}

	getNewModel(): TropaCategoriaGanado { return new TropaCategoriaGanado(); };

	onDropDownValueChanged(item, key): void {
		this.revisarCategoriaDuplicada(item.Key);
	}

	setModelControlValues() {
		if (this.model) {
			this.cantidad = this.model.Cantidad;
			this.cd.detectChanges();
			this.PesoHacienda.CabezaInput.setValue(0);
			this.PesoHacienda.DesbasteInput.setValue(0);
			if (this.modelIsNew) {
				this.Categoria.setValue(this.model.CategoriaGanadoRef);
				this.Corral.setValue(this.model.CorralRef);
				this.Cantidad.setValue(this.model.Cantidad);
				this.PesoHacienda.CabezaInput.setValue(this.model.PesoCabezaKg);
				this.PesoHacienda.DesbasteInput.setValue(this.model.DesbastePorc);
				this.Observaciones.setText(this.model.Observaciones);
			}
			this.AumentoDiario.setValue(this.model.AumentoDiarioEstKg);
			this.PrecioCompraVenta.CabezaInput.setValue(this.model.PrecioCompra);

			this.revisarCategoriaDuplicada(this.model.CategoriaGanadoRef);
		}
	}

	revisarCategoriaDuplicada(categoriaID: number): void {
		this.categoriaRepetida = false;
		let editando: boolean = (this.model.CategoriaGanadoRef > 0);
		let mismaCategoria: number = 0;

		for (const cg of this.data) {
			if (cg.CategoriaGanadoRef === categoriaID) {
				mismaCategoria += 1;
			}
		}

		if (editando) {
			this.categoriaRepetida = (mismaCategoria >= 2);
		} else {
			this.categoriaRepetida = (mismaCategoria >= 1);
		}
	}

	onInputValueChange(newValue, labelName): void {
		if (labelName === this.CantidadCategoriaLabel) {
			this.cantidad = newValue;
			this.cd.detectChanges();
		}
		if (labelName === this.CantidadTropaLabel) {
			this.cantidadVivo = newValue;
			this.PrecioCompraVenta.cantidadTotalVivo = newValue;
			this.getMessengerInstance().broadcast(undefined, undefined, 'CantidadAnimalesVivo', newValue);
			this.cd.detectChanges();
		}
	}

	openInDialog(model: TropaCategoriaGanado, extras: any) {
		this.mostrarPeso = extras.peso;
		this.mostrarPrecio = extras.precio;
		this.cantidadVivo = extras.cantidadVivo;

		this.cd.detectChanges();

		this.openedInDialog = true;
		this.modelIsNew = (!model || model.TropaCategoriaGanadoID.isEmpty());
		if (!this.modelIsNew) {
			this.lstControls = [this.AumentoDiario, /*this.PrecioCompra*/];
		}
		else
		{
			this.lstControls = [this.Categoria, this.AumentoDiario, /*this.PrecioCompra,*/ this.Corral, this.Cantidad, this.Observaciones];
			this.Categoria.initializeControl(this.categoriaGanadoService, this.cd, 'Seleccione una Categoría');
			this.Corral.initializeControlByCorral(this.corralService);
			this.addCustomValidators();
		}

		this.setModel(model);
	}

	addCustomValidators(): void {
		// agrego los input dependiendo si están visibles para el usuario o no
		if (this.mostrarPrecio) {
			this.lstControls.push(this.PrecioCompraVenta.CabezaInput);
		}
		if (this.mostrarPeso) {
			this.lstControls.push(this.PesoHacienda.CabezaInput);
			this.lstControls.push(this.PesoHacienda.DesbasteInput);
		}
	}

	editResultDialog(formParent: IParentOpenInDialog): boolean {
		return false;
	}

	saveResultDialog(formParent: IParentOpenInDialog): boolean {
		if (formParent) {
			if (!this.runValidaciones()) {
				return false;
			}
			this.model.AumentoDiarioEstKg = this.AumentoDiario.getValue();
			this.model.PrecioCompra = this.PrecioCompraVenta.getPorCabezaValue(); //this.PrecioCompra.getValue();
			if (this.modelIsNew) {
				let cat = this.Categoria.getItem();
				this.model.CategoriaGanadoRef = cat.Key;
				this.model.CategoriaGanadoNombre = cat.Value;
				let corral = this.Corral.getItem();
				this.model.CorralRef = corral.Key;
				this.model.CorralNombre = corral.Descripcion;
				this.model.Cantidad = this.Cantidad.getValue();
				this.model.PesoCabezaKg = this.PesoHacienda.getPorCabezaValue(); //this.PesoCabeza.getValue();
				this.model.DesbastePorc = this.PesoHacienda.getDesbasteValue(); //this.Desbaste.getValue();
				this.model.Observaciones = this.Observaciones.getText();
			}
			formParent.saveResultDialogModel(this.model);
		}
		return true;
	}
}
