import { Injectable } from '@angular/core'; 
import { MessengerService } from './messenger.service';

@Injectable()
export class QuickPanelService { 
	constructor(private messengerService: MessengerService) { }

	// Creates and emit a new Offcanvas Component
	createOffCanvasComponent(title: string, component: any, dataItem: any, extras: any = undefined): void {
		this.messengerService.broadcast(undefined, 'QuickPanel', 'CreateComponent', {
			Title: title,
			Component: component,
			DataItem: dataItem,
			Extras: extras
		});
	}

	// 'Hide the QuickPanel' Shortcut
	hideQuickPanel(): void {
		this.messengerService.broadcast(undefined, 'QuickPanel', 'HidePanel', undefined);
	}

	// Broadcast the new loading status for the QuickPanel Component
	setQuickPanelLoadingStatus(loading: boolean): void {
		this.messengerService.broadcast(undefined, 'QuickPanel', (loading ? 'ShowLoadingStatus' : 'HideLoadingStatus'), undefined);
	}
}
