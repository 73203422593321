import { DTOInterface } from "../interfaces/dto.interface";

export class Proveedor implements DTOInterface { 
	ProveedorID: number;
	ProveedorNombre: string;
	ProveedorTipoRef: number;
	Activo: boolean;

	constructor() {
		this.clear();
	}

	clear(): void {
		this.ProveedorID = -1;
		this.ProveedorNombre = '';
		this.ProveedorTipoRef = 0;
		this.Activo = true;
	}
}
