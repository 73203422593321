import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
//import * as Partials from '../../../partials';
import { ClienteService } from '../../../../../services/cliente.service';
import { Router } from '@angular/router';
import { TropaService } from '../../../../../services/tropa.service';
import { TropaHistorica } from '../../../../../models/tropa-historica';
import Utils from '../../../../../utils';
import { Routing, getRoute } from '../../../themes/sigef/routing';
import { MyColDateRangeComponent, MyColDropDownListComponent, MyMessageDialogComponent } from '../../../partials/common_controls';


@Component({
	selector: 'kt-tropa-archivo',
	templateUrl: './tropa-archivo.component.html',
	styleUrls: ['./tropa-archivo.component.scss'],
	providers: [ClienteService, TropaService]
})
export class TropaArchivoComponent implements OnInit {
	@ViewChild('rangoFecha', { static: true }) RangoFecha: MyColDateRangeComponent;
	@ViewChild('Propietario', { static: true }) Propietario: MyColDropDownListComponent;
	@ViewChild(MyMessageDialogComponent, { static: true }) MessageDialog: MyMessageDialogComponent;

	public buscando: boolean = false;

	public desde: Date = undefined;
	public hasta: Date = undefined;
	public clienteSeleccionado: number = 0;
	public totalCabezas: number = 0;

	public datos: TropaHistorica[] = [];

	@Input() public cliente: number = 0;

	constructor(private clienteService: ClienteService,
		private tropaService: TropaService,
		private router: Router,
		private cd: ChangeDetectorRef) {

	}

	ngOnInit() {
		this.Propietario.initializeControl(this.clienteService, this.cd, 'Todos los Propietarios');

		this.buscando = true;
		this.tropaService.getUltimaFechaCierre().subscribe(
			data => {
				let dateCierre: Date = new Date(data);
				if (Utils.isDateValid(data) && dateCierre > Utils.getDateTimeMinValue()) {
					this.RangoFecha.setRango(Utils.addDays(dateCierre, -30), dateCierre);
				} else {
					this.RangoFecha.setRango(Utils.addDays(new Date(), -30), new Date());
				}
			},
			error => {

			},
			() => {
				this.buscar();
			}
		);
	}

	buscar(): void {
		this.buscando = true;
		let hoteleroID = this.cliente > 0 ? this.cliente : this.Propietario.getValue() || 0;		

		this.tropaService.getHistoricaList(hoteleroID, this.RangoFecha.getDesde(), this.RangoFecha.getHasta()).subscribe(
			data => {
				this.datos = data;
				this.totalCabezas = data.reduce((sum, current) => sum + current.Cabezas, 0);
			},
			error => { },
			() => {
				this.finalizarbuscando();
			}
		);
	}

	finalizarbuscando(): void {
		this.buscando = false;
		this.cd.detectChanges();
	}

	abrirTropa(item: any): void {
		this.router.navigate([getRoute(Routing.TROPAS_REPORTES), item.TropaID.toString()]);
	}

	verReporteResumido(item: any): void {
		this.router.navigate([getRoute(Routing.TROPAS_RENTABILIDAD), item.TropaID.toString()]);
	}
}
