import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { Subject } from 'rxjs';
import { Guid } from 'guid-typescript';
import { environment } from '../environments/environment';

@Injectable()
export class SignalRService {
	private hubConnection: HubConnection;

	corralAddUpdate: Subject<SignalRMessage> = new Subject<SignalRMessage>();
	corralDelete: Subject<SignalRMessage> = new Subject<SignalRMessage>();
	dietaAddUpdate: Subject<SignalRMessage> = new Subject<SignalRMessage>();
	dietaDelete: Subject<SignalRMessage> = new Subject<SignalRMessage>();
	mixerCargaAddUpdate: Subject<SignalRMessage> = new Subject<SignalRMessage>();
	mixerCargaDelete: Subject<SignalRMessage> = new Subject<SignalRMessage>();
	corralDescargaAddUpdate: Subject<SignalRMessage> = new Subject<SignalRMessage>();
	corralDescargaDelete: Subject<SignalRMessage> = new Subject<SignalRMessage>();
	corralRemanenteAddUpdate: Subject<SignalRMessage> = new Subject<SignalRMessage>();
	corralRemanenteDelete: Subject<SignalRMessage> = new Subject<SignalRMessage>();
	movTropaAddUpdate: Subject<SignalRMessage> = new Subject<SignalRMessage>();
	movTropaDelete: Subject<SignalRMessage> = new Subject<SignalRMessage>();

	constructor() {
		this.hubConnection = new HubConnectionBuilder()
			.withUrl(environment.signalRHub)
			.withAutomaticReconnect()
			.build();
		this.registerOnEvents();
	}

	connect() {
		this.hubConnection
			.start()
			.then(() => console.log('Connection started'))
			.catch(e => console.log(`Error al iniciar conexion de SignalR. Detalle ${e}`))
	}

	disconnect() {
		if (this.hubConnection) {
			this.hubConnection.stop();
		}
	}

	registerOnEvents() {
		this.hubConnection.on(HubClientMethods.ReceiveRootIntChange, (deviceId: string, type: string, id: number, corralRef: number, empresa: string) => {
			let localEmpresa: string = localStorage.getItem('empresa');
			//console.log(deviceId, type, id, localEmpresa, empresa);

			if (localEmpresa.toLocaleLowerCase() != empresa.toLocaleLowerCase())
				return;

			let msg = new SignalRMessage(id, corralRef);
			switch (type) {
				case HubMessages.CorralAddUpdate: {
					this.corralAddUpdate.next(msg);
					break;
				}
				case HubMessages.CorralDelete: {
					this.corralDelete.next(msg);
					break;
				}
				case HubMessages.DietaAddUpdate: {
					this.dietaAddUpdate.next(msg);
					break;
				}
				case HubMessages.DietaDelete: {
					this.dietaDelete.next(msg);
					break;
				}
			}
		});
		this.hubConnection.on(HubClientMethods.ReceiveRootGuidChange, (deviceId: string, type: string, id: Guid, corralRef: number, empresa: string) => {
			let localEmpresa: string = localStorage.getItem('empresa');
			//console.log(deviceId, type, id, localEmpresa, empresa);

			if (localEmpresa.toLocaleLowerCase() != empresa.toLocaleLowerCase())
				return;

			let msg = new SignalRMessage(id, corralRef);
			switch (type) {
				case HubMessages.MixerCargaAddUpdate: {
					this.mixerCargaAddUpdate.next(msg);
					break;
				}
				case HubMessages.MixerCargaDelete: {
					this.mixerCargaDelete.next(msg);
					break;
				}
				case HubMessages.CorralDescargaAddUpdate: {
					this.corralDescargaAddUpdate.next(msg);
					break;
				}
				case HubMessages.CorralDescargaDelete: {
					this.corralDescargaDelete.next(msg);
					break;
				}
				case HubMessages.CorralRemanenteAddUpdate: {
					this.corralRemanenteAddUpdate.next(msg);
					break;
				}
				case HubMessages.CorralRemanenteDelete: {
					this.corralRemanenteDelete.next(msg);
					break;
				}
				case HubMessages.MovTropaAddUpdate: {
					this.movTropaAddUpdate.next(msg);
					break;
				}
				case HubMessages.MovTropaDelete: {
					this.movTropaDelete.next(msg);
					break;
				}
			}
		});
	}
}



export enum HubMessages {
	DietaAddUpdate = "DietaAddUpdate",
	DietaDelete = "DietaDelete",
	CorralAddUpdate = "CorralAddUpdate",
	CorralDelete = "CorralDelete",
	MixerCargaAddUpdate = "MixerCargaAddUpdate",
	MixerCargaDelete = "MixerCargaDelete",
	CorralDescargaAddUpdate = "CorralDescargaAddUpdate",
	CorralDescargaDelete = "CorralDescargaDelete",
	CorralRemanenteAddUpdate = "CorralRemanenteAddUpdate",
	CorralRemanenteDelete = "CorralRemanenteDelete",
	MovTropaAddUpdate = "MovTropaAddUpdate",
	MovTropaDelete = "MovTropaDelete"
}

export enum HubClientMethods {
	ReceiveRootIntChange = "ReceiveRootIntChange",
	ReceiveRootGuidChange = "ReceiveRootGuidChange"
}

export class SignalRMessage {
	corralRef: number;
	id: any;

	constructor(id: any, corralRef: number) {
		this.id = id;
		this.corralRef = corralRef
	}
}
