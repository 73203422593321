import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';
import { Corral } from '../models/corral';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { CorralInfo } from '../models/corral-info';
import { CorralConsumoGraficoInterface } from '../interfaces/corral-consumo-grafico.interface';
import { CorralDescargaGraficoInterface } from '../interfaces/corral-descarga-grafico.interface';
import { Guid } from 'guid-typescript';

@Injectable()
export class CorralService extends GenericoService<Corral> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'corral');
	}

	getInfo(corralID: number, clienteID: number, tropaID: Guid, categoriaGanadoID: number, dietaID: number, soloActivos: boolean, corralesID: number[] = []): Observable<CorralInfo[]> { 
		corralesID.push(corralID); 
		let corrales: string = corralesID.join(',');

		if (corralesID.length == 1 && corralesID[0] > 0) {
			corralID = corralesID[0];
		}

		if (corrales === '0,0') {
			corrales = '0';
		}

		return this._httpClient.post<CorralInfo[]>(environment.apiUrl + `/${this._endpoint}/info`, { CorralID: corralID, ClienteID: clienteID, TropaID: tropaID, CategoriaGanadoID: categoriaGanadoID, DietaID: dietaID, SoloActivos: soloActivos, CorralesID: corrales }, { headers: this._postHeaders });
	}

	getInfoByClienteDieta(clienteID: number, dietaID: number): Observable<CorralInfo[]> {
		return this.getInfo(0, clienteID, Guid.createEmpty(), 0, dietaID, true);
	}

	getInfoByCorral(corralID: number, clienteID: number = 0): Observable<CorralInfo[]> {
		return this.getInfo(corralID, clienteID, Guid.createEmpty(), 0, 0, false);
	}

	getInfoByTropa(tropaID: Guid): Observable<CorralInfo[]> {
		return this.getInfo(0, 0, tropaID, 0, 0, false);
	}

	updateMS(corralID: number, metaDiaria: number): Observable<any> {
		return this._httpClient.post<any>(environment.apiUrl + `/${this._endpoint}/metaDiaria`, { CorralID: corralID, MetaDiaria: metaDiaria }, { headers: this._postHeaders });
	}

	getDescargaGraficoList(fechaDesde: Date, fechaHasta: Date, dietaRef: number): Observable<CorralDescargaGraficoInterface[]> {
		return this._httpClient.post<CorralDescargaGraficoInterface[]>(environment.apiUrl + `/${this._endpoint}/grafico/descarga`, { FechaDesde: fechaDesde, FechaHasta: fechaHasta, DietaRef: dietaRef }, { headers: this._postHeaders });
	}

	getConsumoGraficoList(fechaDesde: Date, fechaHasta: Date, dietaRef: number, cliente: number): Observable<CorralConsumoGraficoInterface[]> {
		return this._httpClient.post<CorralConsumoGraficoInterface[]>(environment.apiUrl + `/${this._endpoint}/grafico/consumo`, { FechaDesde: fechaDesde, FechaHasta: fechaHasta, DietaRef: dietaRef, ClienteRef: cliente }, { headers: this._postHeaders });
	}

	updateDieta(ids: number[], dietaRef: number): Observable<any> {
		return this._httpClient.post<any>(environment.apiUrl + `/${this._endpoint}/nuevaDieta`, { Corrales: ids, DietaRef: dietaRef }, { headers: this._postHeaders });
	}
}
