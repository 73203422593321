import { Guid } from "guid-typescript";

export class BroadcastMessage {
	Sender: any; // Componente, Clase que emite el mensaje (opcional)
	Target: any; // Destinatario del mensaje (opcional)
	Payload: any; // Mensaje 
	Reason: any; // Metadata para filtrar el mensaje cuando se recibe
} 

// constantes genericas para reusar, emitir y suscribir y evitar errores de tipeo.
export const OBJETO_GUARDADO = 1;
export const REFRESCAR = 2;
export const CERRAR = 3;
export const QUICKPANEL_CERRADO = 4;

export const QUICKPANEL_PACKAGE: Guid = Guid.parse('8AD280EA-23A4-4C94-B222-5E3259717A8C');
