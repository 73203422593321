import { Injectable } from '@angular/core';
import { GenericoService } from './generico.service';
import { HttpClient } from '@angular/common/http';
import { MovTropa } from '../models/mov-tropa';
import { MovTropaCorral } from '../models/mov-tropa-corral';
import { CorralEncierre } from '../models/corral-encierre';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { MovTropaDashboard } from '../models/mov-tropa-dashboard';
import { Guid } from 'guid-typescript';
import { Venta } from '../models/Venta';
import { MovTropaCambio } from '../models/mov-tropa-cambio';
import { MovTropaParicion } from '../models/mov-tropa-paricion';


@Injectable()
export class MovTropaService extends GenericoService<MovTropa> {
	constructor(private _httpClient: HttpClient) {
		super(_httpClient, 'movtropa');
	}

	createCorral(obj: MovTropaCorral): Observable<MovTropaCorral> {
		return this._httpClient.post<MovTropaCorral>(environment.apiUrl + `/${this._endpoint}/corral`, obj, { headers: this._postHeaders });
	}

	createEncierre(obj: CorralEncierre): Observable<any> {
		return this._httpClient.post<any>(environment.apiUrl + `/${this._endpoint}/encierre`, obj, { headers: this._postHeaders });
	}

	createCambio(obj: MovTropaCambio): Observable<MovTropaCambio> {
		return this._httpClient.post<MovTropaCambio>(environment.apiUrl + `/${this._endpoint}/cambio`, obj, { headers: this._postHeaders });
	}

	createParicion(obj: MovTropaParicion): Observable<MovTropaParicion> {
		return this._httpClient.post<MovTropaParicion>(environment.apiUrl + `/${this._endpoint}/paricion`, obj, { headers: this._postHeaders });
	}

	createVenta(obj: Venta): Observable<any> {
		return this._httpClient.post<any>(environment.apiUrl + `/${this._endpoint}/venta`, obj, { headers: this._postHeaders });
	}

	getDashboardList(corralID: number, tropaID: Guid, fechaDesde: Date, fechaHasta: Date, clienteRef: number, tipoMovimientoRef: number): Observable<MovTropaDashboard[]> {
		return this._httpClient.post<MovTropaDashboard[]>(environment.apiUrl + `/${this._endpoint}/dashboard`, { CorralID: corralID, TropaID: tropaID, FechaDesde: fechaDesde, FechaHasta: fechaHasta, ClienteRef: clienteRef, MovTropaTipoRef: tipoMovimientoRef }, { headers: this._postHeaders });
	}

	// Override del List
	getTableList(corralID: number, clienteID: number, tropaID: Guid, categoriaGanadoID: number, dietaID: number, ventaID: Guid, fechaDesde: Date, fechaHasta: Date, tipoMovimientoRef: number, nroTransaccion: number = 0): Observable<MovTropa[]> {
		return this._httpClient.post<MovTropa[]>(environment.apiUrl + `/${this._endpoint}/historial`, { CorralID: corralID, ClienteID: clienteID, TropaID: tropaID, CategoriaGanadoID: categoriaGanadoID, DietaID: dietaID, VentaID: ventaID, FechaDesde: fechaDesde, FechaHasta: fechaHasta, MovTropaTipoRef: tipoMovimientoRef, NroTransaccion: nroTransaccion }, { headers: this._postHeaders });
	}

	getTableListByTropa(tropaID: Guid): Observable<MovTropa[]> {
		return this.getTableList(0, 0, tropaID, 0, 0, undefined, undefined, undefined, 0, 0);
	}

	getTableReporteList(tropaID: Guid, fechaDesde: Date, fechaHasta: Date, tipoMovimientoRef: number, clienteRef: number, nroTransaccion: number = 0): Observable<MovTropa[]> {
		return this.getTableList(0, clienteRef, tropaID, 0, 0, undefined, fechaDesde, fechaHasta, tipoMovimientoRef, nroTransaccion);
	}

	getTableListByVenta(ventaID: Guid): Observable<MovTropa[]> {
		return this.getTableList(0, 0, undefined, 0, 0, ventaID, undefined, undefined, 0, 0);
	}

	getDietaMetaList(corrales: string, dietaRef: number, fechaDescarga: Date): Observable<any[]> {
		return this._httpClient.post<any[]>(environment.apiUrl + `/${this._endpoint}/totalCabezas`, { Corrales: corrales, DietaRef: dietaRef, FechaDescarga: fechaDescarga }, { headers: this._postHeaders });
	}
}
