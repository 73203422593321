import { Component, OnInit, Input } from '@angular/core';
import { AlimentoStock } from '../../../../../models/alimento-stock';

@Component({
  selector: 'kt-alimentacion-stocks-list-almacen',
  templateUrl: './alimentacion-stocks-list-almacen.component.html',
  styleUrls: ['./alimentacion-stocks-list-almacen.component.scss']
})
export class AlimentacionStocksListAlmacenComponent implements OnInit {

  @Input() alimento: AlimentoStock;

  constructor() { }

  ngOnInit() {
  }

}
