import { DTOInterface } from "../interfaces/dto.interface";

export class DietaPlanificacionDescarga implements DTOInterface { 
	public DietaPlanificacionDescargaID: number;
	public DietaRef: number;
	public Orden: number;
	public Porcentaje: number;


	constructor() {
		this.clear();
	}

	clear(): void {
		this.DietaPlanificacionDescargaID = -1;
		this.DietaRef = -1;
		this.Orden = 0;
		this.Porcentaje = 0;
	}
} 
