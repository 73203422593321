import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { MixerCargaAlimento } from '../../../../../models/mixer-carga-alimento';
import Utils from '../../../../../utils';

@Component({
	selector: 'kt-carga-alimento-list',
	templateUrl: './carga-alimento-list.component.html',
	styleUrls: ['./carga-alimento-list.component.scss'],
    providers: []
})
export class CargaAlimentoListComponent implements OnInit {
    public showGrid: boolean = false;
    public alimentos: MixerCargaAlimento[];
    @Input() buscando: boolean = false;

    @Input() public set Alimentos(data: MixerCargaAlimento[]) {
         this.alimentos = data;

        if (this.alimentos) {
            this.showGrid = true;
        }
        this.cd.detectChanges();
    }

    constructor(private cd: ChangeDetectorRef) { }

    ngOnInit() { }

	getExactitud(item: MixerCargaAlimento) {
		return Utils.getCargaExactitud(item.CantidadPropuestaKg, item.CantidadRealKg);
    }
}
