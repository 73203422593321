
<table class="table" *ngIf="notificacionesData && notificacionesData.length > 0" [ngClass]="{'d-none': edicion}">
	<tr *ngFor="let notificacion of notificacionesData; index as i" [ngClass]="{'table-secondary': esImpar(i)}">
		<td width="80%">
			{{notificacion.NotificacionTexto}}
		</td>
		<td [ngClass]="{'d-none': !admin}">
			<button (click)="setEditor(notificacion, $event, false)" class="btn btn-sm"><i title="Editar" class="{{ 'Editar' | parseIconAccion }}"></i></button>
			<button (click)="setEditor(notificacion, $event, true)" class="btn btn-sm"><i title="Eliminar" class="{{ 'Eliminar' | parseIconAccion }}"></i></button>
		</td>
	</tr>
</table>

<my-col-labelinfo *ngIf="notificacionesData && notificacionesData.length === 0" [labelName]="'No hay notificaciones para mostrar.'"></my-col-labelinfo>

<hr>

<div [ngClass]="{'d-none': !edicion}">
	<kt-notificacion></kt-notificacion>
</div>

<div class="ml-3 mr-3 mt-3 row" [ngClass]="{'d-none': !admin}">
	<button class="btn btn-sigef mr-1" [tabindex]="tabindex" (click)="setEditor(undefined, $event, false)" [hidden]="edicion">Nueva Notificación</button>
	<button class="btn btn-sigef mr-1" [tabindex]="tabindex + 1" (click)="guardar($event)" [hidden]="!edicion">Guardar Notificación</button>
	<button class="btn btn-sigef-secondary mr-1" [tabindex]="tabindex + 2" (click)="cancelar($event)">Volver</button>
</div>

<my-message-dialog></my-message-dialog>

