import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, OnDestroy, AfterViewInit, SimpleChanges } from '@angular/core';
import { MovTropa } from '../../../../../models/mov-tropa';
import { EditableFormList } from '../../common_controls/common-controls.interface';
import { IParentOpenInDialog, MyMessageDialogComponent } from '../../common_controls/message-dialog/my-message-dialog.component';
import { MessengerService } from '../../../../../services/messenger.service';
import { MovTropaService } from '../../../../../services/mov-tropa.service'; 
import { Venta } from '../../../../../models/Venta';
import { TropaMovimientoPopupComponent } from '../../tropa';
import { Subscription } from 'rxjs';
import { MovTropaFaena } from '../../../../../models/mov-tropa-faena';
import { Guid } from 'guid-typescript';
import { GridComponent } from '@progress/kendo-angular-grid';

@Component({
	selector: 'kt-venta-categoria-ganado-list',
	templateUrl: './venta-categoria-ganado-list.component.html',
	styleUrls: ['./venta-categoria-ganado-list.component.scss'],
	providers: [MovTropaService]
})
export class VentaCategoriaGanadoListComponent extends EditableFormList<MovTropa> implements OnInit, OnDestroy, AfterViewInit, IParentOpenInDialog {

	@ViewChild(MyMessageDialogComponent, { static: true }) public MessageDialog: MyMessageDialogComponent;
	@ViewChild(GridComponent, { static: true }) public Grid: GridComponent;

	public readonly: boolean = true;
	public buscando2: boolean = false;
	public model: Venta = undefined;

	private sub: Subscription = undefined;	

	@Input() public set venta(value: Venta) { 
		if (value) {
			this.readonly = !value.VentaID || value.VentaID.isEmpty();
		}

		this.model = value;
	}

	@Input() public tabindex: number = -1;
	@Input() public datos: MovTropa[];

	constructor(private movTropaService: MovTropaService, private messengerService: MessengerService, cd: ChangeDetectorRef) { super(cd, movTropaService) }

	ngOnInit() {
		this.initilizeForm(undefined);

		this.sub = this.messengerService.messageBroadcasted$.subscribe(
			data => {
				if (data && data.Reason && data.Reason === 'DeleteMovTropaFaena') {
					this.totales();
				}

				if (data && data.Reason && data.Reason === 'MovTropaFaena') {
					let mtf: MovTropaFaena = (data.Payload as MovTropaFaena);

					if (!mtf.MovTropaFaenaID) {
						mtf.MovTropaFaenaID = Guid.create(); // uno temporal por si se quiere volver a editar sin estar commiteado en la base.

						for (const d of this.datos) {
							if (d.MovTropaID.toString() === mtf.MovTropaRef.toString()) {
								d.Faenas.push(mtf);
							}
						}
					}

					this.totales();
				}
			}
		);
	}

	ngOnChanges(changes: SimpleChanges) { 
		if (this.datos && this.Grid) {
			// expandir las rows
			for (let i = 0; i < this.datos.length; i++) {
				this.Grid.expandRow(i);
			}
		}
	}

	ngAfterViewInit() {

	}

	ngOnDestroy() {
		if (this.sub) { this.sub.unsubscribe(); }
	}

	public editandoVenta(dataItem: any, index: number): boolean {
		return true;
	}

	getKeyModel(obj: MovTropa): any { return obj.MovTropaID; }

	finalizarBusqueda(): void {
		this.buscando2 = false; 
	} 

	public saveResultDialogModel(model: MovTropa) {
		this.saveModel(model);
	}

	totales(): void {
		for (const d of this.datos) {
			let precioVenta = 0;
			for (const f of d.Faenas) {
				precioVenta += (Math.round(f.KilosCarne) * f.PrecioFaena);
			}
			d.PrecioVenta = precioVenta;
		}
	}

	onUpdatePost(): void {		
		this.loadList();
	}

	onDeletePost(): void {
		super.onDeletePost();
	}
}
