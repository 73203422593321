import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ProveedorTipo, MovProductoSanitarioTipoAgrupador } from '../../../../../utils';
import { MyColDropDownListComponent, MyColDateTimePickerComponent, MyColInputComponent } from '../../common_controls';
import { ProveedorService } from '../../../../../services/proveedor.service';
import { CompraService } from '../../../../../services/compra.service';
import { Proveedor } from '../../../../../models/proveedor';
import { EditableForm } from '../../common_controls/common-controls.interface';
import { Compra } from '../../../../../models/compra';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessengerService } from '../../../../../services/messenger.service';
import { QuickPanelService } from '../../../../../services/quick-panel.service';
import { NameValueList } from '../../../../../interfaces/nvl.interface'; 

@Component({
	selector: 'kt-movproductosanitario-compra',
	templateUrl: './movproductosanitario-compra.component.html',
	styleUrls: ['./movproductosanitario-compra.component.scss'],
	providers: [ProveedorService, CompraService]
})
export class MovProductoSanitarioCompraComponent extends EditableForm<Compra> implements OnInit {

	@Input() set agrupador(value: MovProductoSanitarioTipoAgrupador) {
		this.movTipo = value;
		this.setLstControls();
		this.onInitSetModel();
	}

	@Input() edicion: boolean = false;
	@Output() onClose: EventEmitter<any> = new EventEmitter();

	@ViewChild('FechaCompra', { static: true }) FechaCompra: MyColDateTimePickerComponent;
	@ViewChild('Proveedor', { static: true }) Proveedor: MyColDropDownListComponent;
	@ViewChild('NroRemito', { static: true }) NroRemito: MyColInputComponent;

	public movTipo: MovProductoSanitarioTipoAgrupador = 0;
	public component: MovProductoSanitarioCompraComponent = this;

	constructor(private proveedorService: ProveedorService,
		compraService: CompraService,
		cd: ChangeDetectorRef,
		snackBar: MatSnackBar,
		messengerService: MessengerService,
		quickPanelService: QuickPanelService) {
		super(cd, compraService, snackBar, messengerService, quickPanelService);
	} 

	ngOnInit() {	
		this.Proveedor.initializeCustomControlLookUp(this.proveedorService.getLookupByTipo(ProveedorTipo.ProductosSanitarios), this.cd, 'Seleccione un Proveedor...');

		super.setModel(this.model);
	}

	getKeyModel(obj: Compra): number { return obj.CompraID; };
	getNewModel(): Compra { return new Compra(); };

	onInitSetModel() {
		this.adicionalesVisible = false;
		super.onInitSetModel();
	}

	setModelControlValues() {
		if (this.model) {
			this.Proveedor.setValue(this.model.ProveedorRef);
			let f = (this.model.FechaCompra) ? new Date(this.model.FechaCompra) : new Date();
			this.FechaCompra.setFecha(f);
			this.NroRemito.setValue(this.model.RemitoNro);
		}
	}

	setModelPropsByControls() {
		if (!this.model) { this.model = this.getNewModel() };
		
		this.model.ProveedorRef = this.Proveedor.getValue();
		this.model.FechaCompra = this.FechaCompra.getFecha();
		this.model.RemitoNro = this.NroRemito.getValue(); 
	}

	runCustomValidaciones() {		 
		if (this.model && this.model.MovProductoSanitarios.length === 0) {
			this.MessageDialog.showAtencionDialog('La compra debe tener al menos un producto.');
			return false;
		}  

		return true;
	}

	onCancel($event: any) {
		this.resetValidaciones();
		this.resetModel();
		this.Proveedor.setValue(0);
		super.setModel(undefined);
		this.adicionalesVisible = false;
		this.onClose.emit($event);
	}

	onSave(e, repeat: boolean = false): void {
		this.setLstControls();
		super.onSave(e, repeat);
	}

	informSave(sucess: boolean) {
		if (sucess) {
			this.onCancel('sucess');
		}

		super.informSave(sucess);
	}

	onDropDownValueChanged(item: NameValueList, key: string) { }

	agregarDropDownListItem(text: string, key: string) { 
		if (key == 'Proveedor') {
			let prov: Proveedor = new Proveedor();
			prov.ProveedorNombre = text;
			prov.ProveedorTipoRef = ProveedorTipo.ProductosSanitarios;
			this.Proveedor.generateAndAddNewObjectCustom(prov, (p) => p.ProveedorID, this.proveedorService,
				this.proveedorService.getLookupByTipo(ProveedorTipo.ProductosSanitarios), this.cd);
		}
	}

	setLstControls() {
		this.lstControls = [this.FechaCompra, this.Proveedor/*, this.NroRemito*/];	
	}
}
