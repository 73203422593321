import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kt-message-info',
  templateUrl: './message-info.component.html',
  styleUrls: ['./message-info.component.scss']
})
export class MessageInfoComponent implements OnInit {
	@Input() text: string = '';
	@Input() textFormatted: boolean = false;

  constructor() { }
  ngOnInit() { }

}
