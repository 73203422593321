import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { CommonControl } from '../common-controls.interface';

@Component({
	selector: 'my-col-multiselect',
	templateUrl: './my-col-multiselect.component.html',
	styleUrls: ['./my-col-multiselect.component.scss']
})
export class MyColMultiselectComponent extends CommonControl implements OnInit {
	@Input() placeholder: string = 'Seleccione una o más opciones';
	@Input() hint: string = '';
	@Input() valueField: any = 'ID';
	@Input() textField: any = 'Field'; 
	@Input() data: any[] = [];

	constructor(private cd: ChangeDetectorRef) {
		super();
		this.Form = new UntypedFormGroup({
			'Input': new UntypedFormControl(undefined),
			'Description': new UntypedFormControl(undefined)
		});
	}

	ngOnInit() {

	}

	setValue(value: any): void {
		this.Form.patchValue({ Input: value });
	}

	getValue(): any {
		return this.Form.value['Input'];
	}

	onValueChange(e: any): void {

	}
}
