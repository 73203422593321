import { Pipe, PipeTransform } from '@angular/core';
import Utils from '../utils';

@Pipe({
    name: 'parseNumber'
})
export class ParseNumberPipe implements PipeTransform {

	transform(e: any, noDecimals: boolean = false): string {
		return Utils.formatNumberDecimal(e, noDecimals);
    }

}
