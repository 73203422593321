import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ValidationControlInterface } from '../../common_controls/common-controls.interface';
import { Venta } from '../../../../../models/Venta';
import { MyColDateTimePickerComponent, MyColTimePickerComponent, MyColDropDownListComponent } from '../../common_controls';
import Utils from '../../../../../utils';
import { Comprador } from '../../../../../models/comprador';
import { VentaDestino } from '../../../../../models/venta-destino';
import { CompradorService } from '../../../../../services/comprador.service';
import { VentaDestinoService } from '../../../../../services/venta-destino.service';

@Component({
	selector: 'kt-venta',
	templateUrl: './venta.component.html',
	styleUrls: ['./venta.component.scss'],
	providers: [VentaDestinoService, CompradorService]
})
export class VentaComponent implements OnInit {
	component: VentaComponent = this;

	@ViewChild(MyColDateTimePickerComponent, { static: true }) Fecha: MyColDateTimePickerComponent;
	@ViewChild(MyColTimePickerComponent, { static: true }) Hora: MyColTimePickerComponent;
	@ViewChild('Comprador', { static: true }) Comprador: MyColDropDownListComponent;
	@ViewChild('VentaDestino', { static: true }) VentaDestino: MyColDropDownListComponent;

	lstControls: ValidationControlInterface[];

	@Input() tabindex: number = -1;

	public _venta: Venta;
	@Input() set venta(model: Venta) {
		this._venta = model;
		if (this._venta)
			this.resetModel();
	}

	constructor(private cd: ChangeDetectorRef,
		private ventaDestinoService: VentaDestinoService,
		private compradorService: CompradorService, ) {
		this._venta = undefined;
	}

	ngOnInit() {
		this.lstControls = [this.Fecha, this.Hora, this.Comprador, this.VentaDestino]; 

		this.Comprador.initializeControl(this.compradorService, this.cd, "Seleccione un Comprador...");
		this.VentaDestino.initializeControl(this.ventaDestinoService, this.cd, "Seleccione un Destino...");

	}

	resetModel() {
		this.Fecha.setFecha(new Date(this._venta.Fecha));
		this.Hora.setHora(this._venta.Fecha);
		this.Comprador.setValue(this._venta.CompradorRef);
		this.VentaDestino.setValue(this._venta.VentaDestinoRef);

		this.Fecha.focus();
	}

	setModelValues(model: Venta) {
		model.Fecha = Utils.getDateWithTime(this.Fecha.getFecha(), this.Hora.getHora());
		model.CompradorRef = this.Comprador.getValue() || 0;
		model.VentaDestinoRef = this.VentaDestino.getValue() || 0;

		if (this.Comprador && this.Comprador.getItem()) { model.CompradorNombre = this.Comprador.getItem().Value; }
		if (this.VentaDestino && this.VentaDestino.getItem()) { model.VentaDestinoNombre = this.VentaDestino.getItem().Value; }
	}

	agregarDropDownListItem(text: string, key: string) {
		if (key == "Comprador") {
			let comprador: Comprador = new Comprador();
			comprador.CompradorNombre = text;
			this.Comprador.generateAndAddNewObject(comprador, (c) => c.CompradorID, this.compradorService, this.cd);
		}
		else if (key == "VentaDestino") {
			let destino: VentaDestino = new VentaDestino();
			destino.VentaDestinoNombre = text; 
			this.VentaDestino.generateAndAddNewObject(destino, (p) => p.VentaDestinoID, this.ventaDestinoService, this.cd);
		}
	}
}

