<div class="row">
	<div class="col-xl-12 col-lg-12 order-lg-12 order-xl-12">
		<kt-portlet [class]="'kt-portlet--height-fluid'">
			<kt-portlet-body>
				<div class="row pl-2">
					<div class="d-inline-flex">
						<button [ngClass]="{'btn-label-sigef-o2': diasPreviosCantidad == 7}" type="button" (click)="preset(7)" class="btn btn-sm mr-2 btn-control btn-preset">7 días</button>
						<button [ngClass]="{'btn-label-sigef-o2': diasPreviosCantidad == 15}" type="button" (click)="preset(15)" class="btn btn-sm mr-2 btn-control btn-preset">15 días</button>
						<button [ngClass]="{'btn-label-sigef-o2': diasPreviosCantidad == 30}" type="button" (click)="preset(30)" class="btn btn-sm mr-2 btn-control btn-preset">30 días</button>
						<button [ngClass]="{'btn-label-sigef-o2': diasPreviosCantidad == 60}" type="button" (click)="preset(60)" class="btn btn-sm mr-2 btn-control btn-preset">60 días</button>
						<button [ngClass]="{'btn-label-sigef-o2': diasPreviosCantidad == 90}" type="button" (click)="preset(90)" class="btn btn-sm mr-2 btn-control btn-preset">90 días</button>
				 
						<my-col-dropdownlist #Dieta [extraClass]="'consumo-dropdown'" [labelVisible]="false"></my-col-dropdownlist>
						<my-col-button [labelVisible]="false" [buscando]="buscando" (click)="recargar()"></my-col-button>
					</div>
				</div>
			</kt-portlet-body>
		</kt-portlet>
	</div>
</div>

<div class="kt-portlet kt-portlet--tabs">
	<div class="kt-portlet__head">
		<div class="kt-portlet__head-toolbar">
			<ul class="nav nav-tabs nav-tabs-line nav-tabs-line-sigef nav-tabs-line-2x nav-tabs-line-right nav-tabs-bold" role="tablist">
				<li class="nav-item">
					<a class="nav-link menu--tab--consumo--icon" [ngClass]="{'active': vista === 'consumo'}" data-toggle="tab" href="javascript:;" (click)="cambiarVista('consumo')" role="tab">
						<span [ngClass]="{'menu--consumo--icon--verde':  vista === 'consumo', 'menu--consumo--icon--gris':  vista !== 'consumo'}" class="kt-menu__link__item menu--tab--icon"></span>
						<span [ngClass]="{'menu--tab--text': vista === 'consumo', 'menu--tab--text--inactive': vista !== 'consumo'}">Consumo MS por Cabeza</span>
					</a>
				</li>
				<li class="nav-item" [ngClass]="{'d-none': !mostrarDescarga}">
					<a class="nav-link menu--tab--descarga--icon" [ngClass]="{'active': vista === 'descarga'}" data-toggle="tab" href="javascript:;" (click)="cambiarVista('descarga')" role="tab">
						<span [ngClass]="{'menu--descarga--icon--verde':  vista === 'descarga', 'menu--descarga--icon--gris':  vista !== 'descarga'}" class="kt-menu__link__item menu--tab--icon"></span>
						<span [ngClass]="{'menu--tab--text': vista === 'descarga', 'menu--tab--text--inactive': vista !== 'descarga'}">Descargas por Corral</span>
					</a>
				</li>
			</ul>
		</div>
	</div>
	<div class="kt-portlet__body">
		<div class="tab-content">
			<div class="tab-pane active" id="kt_portlet_tabbed" role="tabpanel">
				<div *ngIf="vista == 'consumo'">
					<kt-consumo-chart class="w-100" [consumos]="consumos"></kt-consumo-chart>
				</div>
				<div *ngIf="vista == 'descarga'">
					<kt-descarga-chart class="w-100" [descargas]="descargas"></kt-descarga-chart>
				</div>
				<div *ngIf="vista == 'descarga'" >
					<kt-carga-list [Cargas]="cargas" [buscando]="buscando" [tipoReporte]="2"></kt-carga-list>
				</div>
			</div>
		</div>
	</div>
</div>
