<div col="col-md-12">
	<kendo-chart>
		<kendo-chart-tooltip format="{0} Kg"></kendo-chart-tooltip>
		<kendo-chart-legend position="bottom"></kendo-chart-legend>
		<kendo-chart-title font="12pt" align="left" text="Consumo de alimento (Kg) por día"></kendo-chart-title>

		<kendo-chart-category-axis>
			<kendo-chart-category-axis-item [categories]="etiquetas()"
											[title]="{ text: 'Días' }">
			</kendo-chart-category-axis-item>
		</kendo-chart-category-axis>
		<kendo-chart-series>
			<kendo-chart-series-item *ngFor="let serie of alimentos"
										type="line"
										[name]="serie.Nombre"
										[data]="serie.Valores">
				<kendo-chart-series-item-tooltip>
					<ng-template let-value="value">
						<strong>{{serie.Nombre}}:</strong> {{ value | parseNumberKg : false : false }}
					</ng-template>
				</kendo-chart-series-item-tooltip>
			</kendo-chart-series-item>
		</kendo-chart-series>
	</kendo-chart>
</div>
