import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'header-admin',
	templateUrl: './header-admin.component.html',
	styleUrls: ['./header-admin.component.scss']
})
export class HeaderAdminComponent implements OnInit {
	@Input() translate: string = '';

	@Output() onAdd = new EventEmitter();
	@Output() onRefresh = new EventEmitter();
	@Output() onEdit = new EventEmitter();
	@Output() onPrint = new EventEmitter();
	@Output() onConfirm = new EventEmitter();
	@Output() onCancel = new EventEmitter();
	@Output() onGoBack = new EventEmitter();

	@Input() public hideHeader: boolean = false;

	@Input() public showConfirm: boolean = false;
	@Input() public showCancel: boolean = false;
	@Input() public showAdd: boolean = true;
	@Input() public showRefresh: boolean = true;
	@Input() public showEdit: boolean = false;
	@Input() public showPrint: boolean = false;
	@Input() public showGoBack: boolean = false;

	@Input() public layout: string = 'header';

	constructor(private translateService: TranslateService) { }
	ngOnInit() { }

	title(): string {
		return this.translateService.instant(this.translate);
	}

	clickEdit(e): void {
		e.preventDefault();
		this.onEdit.emit(e);
	}

	clickPrint(e): void {
		e.preventDefault();
		this.onPrint.emit(e);
	}

	clickAdd(e): void {
		e.preventDefault();
		this.onAdd.emit(e);
	}

	clickRefresh(e): void {
		e.preventDefault();
		this.onRefresh.emit(e);
	}

	clickCancel(e): void {
		e.preventDefault();
		this.onCancel.emit(e);
	}

	clickConfirm(e): void {
		e.preventDefault();
		this.onConfirm.emit(e);
	}

	clickGoBack(e): void {
		e.preventDefault();
		this.onGoBack.emit(e);
	}
}
