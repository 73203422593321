import { Component, ChangeDetectorRef } from '@angular/core';
import { HelpersTropaEditDietaMS } from '../helpers-tropa';
import { CorralService } from '../../../../../services/corral.service';
import { DietaService } from '../../../../../services/dieta.service';
import { MessengerService } from '../../../../../services/messenger.service';
import { QuickPanelService } from '../../../../../services/quick-panel.service';

@Component({
	selector: 'kt-tropa-corral',
	templateUrl: './tropa-corral.component.html',
	styleUrls: ['./tropa-corral.component.scss'],
	providers: [CorralService, DietaService]
})
export class TropaCorralComponent extends HelpersTropaEditDietaMS {

	constructor(messengerService: MessengerService,
		dietaService: DietaService,
		corralService: CorralService,
		cd: ChangeDetectorRef,
		quickPanelService: QuickPanelService) {
		super(messengerService, dietaService, corralService, cd, quickPanelService);
	}
}
