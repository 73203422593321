import { DTOInterface } from "../interfaces/dto.interface";
import { Guid } from "guid-typescript";

export class MovTropaParicion implements DTOInterface {   
	TropaCategoriaGanadoRef: Guid;
	TropaRef: Guid;
	CategoriaGanadoRef: number;
	PrecioVenta: number;
	AumentoDiarioEstKg: number;
	CorralRef: number;
	FechaMovimiento: Date;
	Cantidad: number;
	Observaciones: string; 
	CorralDestinoRef: number;
	PesoCabezaKg: number;
	DesbastePorc: number;

	constructor() {
		this.clear();
	}

	clear(): void {
		this.TropaCategoriaGanadoRef = undefined;
		this.TropaRef = undefined;
		this.CategoriaGanadoRef = undefined;
		this.PrecioVenta = 0;
		this.AumentoDiarioEstKg = 0;
		this.CorralRef = -1;
		this.FechaMovimiento = new Date();
		this.Cantidad = 0;
		this.Observaciones = ''; 
		this.CorralDestinoRef = -1;
		this.PesoCabezaKg = 0;
		this.DesbastePorc = 0;
	}
}
