import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseDateMinValue'
})
export class ParseDateMinValuePipe implements PipeTransform {

	transform(e: any, reemplazo: string = '---' ): string {
		let d = new Date(e);
		let minValue = new Date(1500, 0, 1)

		if (d < minValue)
			return reemplazo;
		else
			return [d.getDate().toString().padStart(2, '0'), (d.getMonth() + 1).toString().padStart(2, '0'), d.getFullYear()].join('/');

  }
}
