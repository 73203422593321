import { DTOInterface } from "../interfaces/dto.interface";
import { Guid } from "guid-typescript";

export class CorralDescarga implements DTOInterface { 
	CorralDescargaID: Guid;
    CorralRef: number;
    FechaDescarga: Date;
	MixerCargaRef: Guid;
    Remanente: number;
    CantidadPropuestaKg: number;
	CantidadRealKg: number;
	KgDisponibles: number;
    UsuarioRef: number;
    Observaciones: string;

    CorralNombre: string;
	UsuarioNombre: string;
	CargaWeb: boolean;
	  
	constructor() {
		this.clear();
	}

	clear(): void {
		this.CorralDescargaID = Guid.createEmpty();
        this.CorralRef = -1;
        this.FechaDescarga = new Date();
		this.MixerCargaRef = Guid.createEmpty();
        this.Remanente = undefined;
        this.CantidadPropuestaKg = 0;
		this.CantidadRealKg = 0;
		this.KgDisponibles = 0;
        this.UsuarioRef = -1;
        this.Observaciones = '';

        this.CorralNombre = '';
		this.UsuarioNombre = '';
		this.CargaWeb = false;
	}
}
