<div>
	<div class="row-sigef">
		<my-col-input #ProductoSanitarioNombre selectFirstInput [tabindex]="tabindex" [type]="2" [labelName]="'Nombre'" [required]="true"></my-col-input>
		<my-col-dropdownlist #CategoriaPS [tabindex]="tabindex + 1" [labelName]="'Categoría'" [filterable]="true" [parent]="component" [key]="'CategoriaPS'" [required]="true"></my-col-dropdownlist>
		<my-col-switch #Activo [tabindex]="tabindex + 2" [labelName]="'Activo'"></my-col-switch>
	</div>
	<div class="row-sigef">
		<my-col-input #StockMinimo [tabindex]="tabindex + 3" [labelName]="'Stock Mínimo'" [allowCero]="false" [required]="true"></my-col-input>
		<my-col-dropdownlist #UnidadMedida [tabindex]="tabindex + 4" [labelName]="'Unidad de Medida'" [filterable]="true" [parent]="component" [key]="'UnidadMedida'" [required]="true"></my-col-dropdownlist>
	</div>
</div>
