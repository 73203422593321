<div [ngClass]="{'d-none': confirmacion }">
	<div class="">
		<div class="col w-100">
			<div class="row row-full-height">
				<div class="kt-portlet kt-portlet--mobile">
					<div class="kt-portlet__head">
						<div class="kt-portlet__head-label">
							<h3 class="kt-portlet__head-title">
								<i class="fas fa-info-circle"> </i>&nbsp; Datos de Tropa
							</h3>
						</div>
					</div>
					<div class="kt-portlet__body">
						<div>
							<kt-tropa [tabindex]="8000" [isnew]="puedeEditar" [readonly]="readonly" [tropa]="model"></kt-tropa>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="">
		<div class="col w-100">
			<div class="row row-full-height">
				<div class="kt-portlet kt-portlet--mobile">
					<div class="kt-portlet__head">
						<div class="kt-portlet__head-label">
							<h3 class="kt-portlet__head-title">
								<i class="fas fa-tags"> </i>&nbsp; Hacienda
							</h3>
						</div>
					</div>
					<div class="kt-portlet__body">
						<div>
							<kt-ingreso-hacienda [ngClass]="{'d-none': !puedeEditar }" [tabindex]="8100" style="width: 100%" [titlePrecio]="'Precio'" [edicion]="edicion" [parent]="parent" class="pb-3"></kt-ingreso-hacienda>
							<kt-tropa-categoria-ganado-list [puedeEditar]="puedeEditar" [tabindex]="8200" [tropa]="model" [edicion]="edicion"></kt-tropa-categoria-ganado-list>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="" [ngClass]="{'d-none': !puedeEditar }">
		<div class="col w-100">
			<div class="row row-full-height">
				<div class="kt-portlet kt-portlet--mobile">
					<div class="kt-portlet__head">
						<div class="kt-portlet__head-label">
							<h3 class="kt-portlet__head-title">
								<i class="fas fa-truck-loading"> </i>&nbsp; Datos de Transporte
							</h3>
						</div>
					</div>
					<div class="kt-portlet__body">
						<div>
							<kt-tropa-transporte-list [tabindex]="8300" [tropa]="model"></kt-tropa-transporte-list>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="" [ngClass]="{'d-none': isNewModel }">
		<div class="col w-100">
			<div class="row row-full-height">
				<div class="kt-portlet kt-portlet--mobile">
					<div class="kt-portlet__head">
						<div class="kt-portlet__head-label">
							<h3 class="kt-portlet__head-title">
								<i class="fas fa-history"> </i>&nbsp; Movimientos
							</h3>
						</div>
					</div>
					<div class="kt-portlet__body">
						<div>
							<kt-tropa-movimiento-list [tabindex]="8400" [tropa]="model"></kt-tropa-movimiento-list>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="">
		<div class="col w-100">
			<div class="row row-full-height">
				<div class="kt-portlet kt-portlet--mobile">
					<div class="kt-portlet__body">
						<div>
							<my-group-save-buttons [tabindex]="8500" class="w-100" [saving]="saving" [deleting]="deleting" [allowDelete]="!readonly && !isNewModel" [targetName]="'Tropa'"
												   (onSave)="onSave($event)" (onCancel)="onCancel($event)" (onDelete)="onDelete($event)"></my-group-save-buttons>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<kt-portlet [ngClass]="{'d-none': !confirmacion }" class="mr-lg-5 ml-lg-5 mr-md-5 ml-md-5">
	<kt-tropa-reporte></kt-tropa-reporte>
</kt-portlet>
 
<my-message-dialog></my-message-dialog>
